import React from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AlertDialogContent, {
  AlertDialogContentProps,
} from "../content/AlertDialogContent";

export interface AlertDialogProps
  extends AlertDialogContentProps,
    Omit<DialogProps, "title"> {}

function AlertDialog({
  variant,
  onClose,
  onAction,
  title,
  ...restProps
}: AlertDialogProps) {
  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      data-testid={`${variant}-alert-dialog`}
      {...restProps}
    >
      {!!onClose && (
        <IconButton
          sx={{
            position: "absolute",
            right: ({ spacing }) => spacing(2.5),
            top: ({ spacing }) => spacing(2.5),
          }}
          // @ts-ignore
          onClick={onClose as any}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogContent sx={{ p: 7 }}>
        <AlertDialogContent
          variant={variant}
          onClose={onClose}
          onAction={onAction}
          title={title}
          {...restProps}
        />
      </DialogContent>
    </Dialog>
  );
}

export default React.memo(AlertDialog);
