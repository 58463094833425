import React, { useCallback } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import Button from "@src/components/buttons/Button";
import { useDialog } from "@src/providers/DialogProvider";
import isEmpty from "lodash/isEmpty";
import { addAuthKeyToUrl } from "@src/helpers/authSession";
import { useValidateJoinCode } from "./useValidateJoinCode";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const FormSchema = z.object({
  code: z
    .string()
    .nonempty("Join code is required")
    .length(12, "Join code must be exactly 12 characters"),
});

type JoinCodeInputs = z.infer<typeof FormSchema>;

export const JoinCodeDialog = () => {
  const { isLoading, validateJoinCode } = useValidateJoinCode();
  const { hideDialog } = useDialog();

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm<JoinCodeInputs>({
    mode: "all",
    resolver: zodResolver(FormSchema),
    defaultValues: {
      code: "",
    },
  });

  const onSubmit = useCallback<SubmitHandler<JoinCodeInputs>>(
    async (data) => {
      const isValid = await validateJoinCode(data.code);
      if (!isValid) {
        setError("code", { type: "custom", message: "Invalid code" });
        return;
      }

      addAuthKeyToUrl("joinCode", data.code);
      reset();
      hideDialog("join-code-modal");
    },
    [hideDialog, reset, setError, validateJoinCode],
  );

  return (
    <Box sx={{ width: "100%" }} data-testid="join-code-dialog">
      <Typography variant="body2" sx={{ mb: 1 }}>
        Enter join code
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="code"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <Box>
              <TextField
                inputProps={{
                  "data-testid": "code-input",
                }}
                error={!isEmpty(errors["code"])}
                color="primary"
                label="Join code"
                fullWidth
                helperText={errors["code"]?.message}
                inputRef={ref}
                {...field}
                onChange={(e) => field.onChange(e.target.value.slice(0, 12))}
              />
            </Box>
          )}
        />
        <Box sx={{ mt: 2 }}>
          <Button
            sx={{ width: 100, height: 30 }}
            disabled={isLoading}
            loading={isLoading}
            data-testid="confirm-join-code"
            type="submit"
            color="primary"
            variant="contained"
          >
            Join session
          </Button>
        </Box>
      </form>
    </Box>
  );
};
