import { EventApi } from "@src/api/event-api";
import { useRouteParams } from "@src/hooks/useRouteParams";
import { useQuery, UseQueryOptions } from "react-query";
import { Event as ApiEvent } from "../../contracts/event/event";
import { Environment, useIsEnvironment } from "../EnvironmentProvider";

import { createEventQueryKey, Event } from "./common";

/**
 * Returns a query which queries for the provided event ID or uses the current
 * route ID if `null` or `undefined` provided.
 *
 * Example:
 *
 * ```tsx
 * // get an event by ID
 * useEvent("event1");
 *
 * // get the current route event ID (if on an event route)
 * useEvent();
 * ```
 */
export function useEvent(
  id?: string | null | undefined,
  options: UseQueryOptions<ApiEvent | null, unknown, Event | null> = {},
) {
  const { eventId: routeEventId } = useRouteParams();
  const eventId = id ?? routeEventId;
  const isTestEnvironment = useIsEnvironment(Environment.TEST);

  const enabled =
    typeof options.enabled === "boolean"
      ? options.enabled && !!eventId
      : !!eventId;

  const query = useQuery<ApiEvent | null, unknown, Event | null>(
    createEventQueryKey(eventId),
    () => EventApi.GetEvent(eventId as string),
    {
      retry: !isTestEnvironment ? 2 : false,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
      select: (event) =>
        event
          ? ({
              ...event,
              startDate: new Date(event.startDate),
              endDate: new Date(event.endDate),
              playbackLocked:
                typeof event?.eventInfo?.planFeatures?.canUsePlayback ===
                "boolean"
                  ? !event?.eventInfo?.planFeatures?.canUsePlayback
                  : false,
              goLiveLocked:
                typeof event?.eventInfo?.planFeatures?.canUseVirtualStage ===
                "boolean"
                  ? !event?.eventInfo?.planFeatures?.canUseVirtualStage
                  : false,
            } as Event)
          : null,
      ...options,
      enabled,
    },
  );

  return {
    ...query,
    data: query.data ?? null, // parts of the app are expecting this to be null
  } as typeof query;
}
