const CLOUNDINARY_BUCKET_URL =
  "https://res.cloudinary.com/introvoke/image/upload";

interface TransformImageArgs {
  url: string;
  width: number;
  height: number;
  extraParams?: string;
  quality?: number;
  alwaysFill?: boolean;
}

/**
 * optimally transforms the image url
 */
export const transformImageUrl = ({
  url,
  width,
  height,
  alwaysFill,
  extraParams,
  quality,
}: TransformImageArgs): string => {
  if (!url || !url.startsWith(CLOUNDINARY_BUCKET_URL)) return url ?? "";

  const standardParams = `h_${height},w_${width},${
    quality ? `q_${quality}` : "q_auto"
  }${extraParams ? `,${extraParams}` : ""}`;

  if (alwaysFill) {
    return addCloudinaryImageUrlParams(url, `c_fill,${standardParams}`);
  } else {
    return addCloudinaryImageUrlParams(
      url,
      `if_ils_gt_0.5/c_pad,${standardParams}/if_else/c_fill,${standardParams}/if_end`,
    );
  }
};

export const addCloudinaryImageUrlParams = (
  url: string,
  params: string,
): string => {
  if (!url.startsWith(CLOUNDINARY_BUCKET_URL)) {
    return url;
  }

  const imageUrlSuffix = url.replace(`${CLOUNDINARY_BUCKET_URL}/`, "");

  if (imageUrlSuffix.split("/").length > 2) {
    return url;
  }

  const [imageIdentifier] = imageUrlSuffix.split(".");

  return `${CLOUNDINARY_BUCKET_URL}/${params}/${imageIdentifier}.png`;
};

export const updateUiAvatarParams = (urlString: string, username: string) => {
  if (!urlString) return "";
  try {
    const url = new URL(urlString);

    if (url.hostname !== "ui-avatars.com") return urlString;

    const params = new URLSearchParams(url.search);
    params.delete("name");
    url.search = params.toString();

    return `https://ui-avatars.com/api/?${params.toString()}&name=${username.replace(
      / /g,
      "+",
    )}`;
  } catch (e) {
    return urlString;
  }
};
