import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { useDialog } from "@src/providers/DialogProvider";
import { PollResultsType, PollStatus } from "@src/providers/polls/PollProvider";
import React, { useCallback } from "react";
import { z } from "zod";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  StyledMuiDialogContent,
  StyledMuiDialogActions,
  SettingsWrapper,
  OptionItem,
} from "./CreatePollForm.styles";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const FormSchema = z.object({
  name: z.string().nonempty("Poll name is required"),
  options: z
    .array(
      z.object({
        name: z.string().nonempty("Poll option cannot be empty"),
      }),
    )
    .min(2, "Poll must have at least 2 options")
    .max(10, "Poll cannot have more than 10 options"),
  queuePoll: z.boolean(),
  delayResults: z.boolean(),
  resultsType: z.nativeEnum(PollResultsType),
});

type PollFormValues = z.infer<typeof FormSchema>;

export interface CreatePollFormProps {
  onSubmit?: (
    poll: Omit<PollFormValues, "resultsAsPercent"> & {
      status: PollStatus;
    },
  ) => void;
}

export const CreatePollForm = ({ onSubmit }: CreatePollFormProps) => {
  const { hideDialog } = useDialog();

  const { formState, handleSubmit, control, reset } = useForm<PollFormValues>({
    mode: "all",
    values: {
      name: "",
      options: [{ name: "" }, { name: "" }],
      queuePoll: false,
      delayResults: false,
      resultsType: PollResultsType.VOTES,
    },
    resolver: zodResolver(FormSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "options",
  });

  const handleClose = useCallback(() => {
    hideDialog("CreatePollDialog");
    reset();
  }, [hideDialog, reset]);

  const handleFormSubmit = useCallback<SubmitHandler<PollFormValues>>(
    (data) => {
      handleClose();
      onSubmit?.({
        ...data,
        status: PollStatus.ACTIVE,
      });
    },
    [handleClose, onSubmit],
  );

  const handlePendingFormSubmit = useCallback<SubmitHandler<PollFormValues>>(
    (data) => {
      handleClose();
      onSubmit?.({
        ...data,
        status: PollStatus.PENDING,
      });
    },
    [handleClose, onSubmit],
  );

  const disableSubmit = !formState.isValid;

  return (
    <Box data-testid="create-poll-form">
      <DialogTitle>
        <Typography id="create-poll-dialog-title" variant="h4" align="center">
          Create New Poll
        </Typography>
      </DialogTitle>
      <Controller
        control={control}
        name="name"
        render={({ field: { ref, ...field } }) => (
          <TextField
            {...field}
            error={!!formState.errors?.name}
            fullWidth
            helperText={formState.errors.name?.message}
            id="poll-name-input"
            data-testid="poll-name-input"
            inputRef={ref}
            variant="filled"
            name="name"
            required
            color="primary"
            label="What is your question?"
          />
        )}
      />
      <StyledMuiDialogContent>
        {fields.map((field, index, { length }) => (
          <OptionItem key={field.id}>
            <Box display="flex" alignItems="center">
              <Controller
                control={control}
                name={`options.${index}.name`}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    {...field}
                    id={`options-${index}`}
                    data-testid="poll-option-input"
                    variant="standard"
                    defaultValue={field.name} // make sure to include defaultValue
                    fullWidth
                    error={!!formState.errors.options?.[index]?.name}
                    helperText={
                      formState.errors.options?.[index]?.name?.message
                    }
                    InputProps={{
                      sx: {
                        paddingTop: 0.5,
                        paddingBottom: 0.5,
                        paddingRight: 1.5,
                      },
                      endAdornment: fields.length > 2 && (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={`delete option ${index + 1}`}
                            data-testid="poll-delete-option-button"
                            disabled={length <= 2}
                            onClick={() => remove(index)}
                            edge="end"
                            size="large"
                          >
                            <DeleteOutlineIcon sx={{ cursor: "pointer" }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                      placeholder: "Enter Option",
                    }}
                    inputRef={ref}
                    key={field.name}
                    required
                  />
                )}
              />
            </Box>
          </OptionItem>
        ))}

        <Button
          sx={{ width: "120px" }}
          variant="text"
          color="primary"
          data-testid="poll-add-option-button"
          disabled={fields.length >= 10}
          onClick={() => append({ name: "" })}
          startIcon={<AddIcon />}
        >
          Add Option
        </Button>
        <Typography
          variant="body1"
          sx={{ mt: 3, mb: 1 }}
          color="text.secondary"
        >
          Advanced Settings
        </Typography>
        <SettingsWrapper>
          <Tooltip title="This allows you to publish the poll results at your convenience instead of being available immediately upon voting">
            <FormControlLabel
              control={
                <Controller
                  name="delayResults"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      data-testid="poll-delay-checkbox"
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                      color="primary"
                    />
                  )}
                />
              }
              label="Delay showing poll results"
            />
          </Tooltip>

          <Tooltip title="This will hide the actual number of poll participants from attendees">
            <FormControlLabel
              control={
                <Controller
                  name="resultsType"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      data-testid="poll-hide-count-checkbox"
                      checked={field.value !== PollResultsType.VOTES}
                      onChange={(e) =>
                        field.onChange(
                          e.target.checked
                            ? PollResultsType.PERCENT
                            : PollResultsType.VOTES,
                        )
                      }
                      color="primary"
                    />
                  )}
                />
              }
              label="Hide total number of poll participants"
            />
          </Tooltip>
        </SettingsWrapper>
      </StyledMuiDialogContent>
      <StyledMuiDialogActions>
        <Button
          data-testid="poll-save-later-button"
          disabled={disableSubmit}
          onClick={handleSubmit(handlePendingFormSubmit)}
          color="primary"
        >
          Save for later
        </Button>
        <Button
          data-testid="poll-publish-button"
          color="primary"
          disabled={disableSubmit}
          onClick={handleSubmit(handleFormSubmit)}
          variant="contained"
        >
          Publish Now
        </Button>
      </StyledMuiDialogActions>
    </Box>
  );
};
