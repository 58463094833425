import React from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

export interface Props {
  /**
   * The optional toast title
   */
  title?: string;
  /**
   * The toast message
   */
  message: string;
}

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: theme.typography.body2.fontSize,
}));

const Message = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
}));

const ToastContent = ({ title, message }: Props) => {
  return (
    <>
      <Title>{title}</Title>
      <Message>{message}</Message>
    </>
  );
};

export default React.memo(ToastContent);
