import { DialogContent, DialogActions, styled, Box } from "@mui/material";

export const StyledMuiDialogContent = styled(DialogContent)(
  ({ theme: { spacing } }) => ({
    display: "flex",
    flexDirection: "column",
    padding: `${spacing(2)} ${spacing(0)}`,
  }),
);

export const StyledMuiDialogActions = styled(DialogActions)(
  ({ theme: { spacing } }) => ({
    padding: 0,
    marginTop: spacing(2),
  }),
);

export const SettingsWrapper = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  padding: spacing(1),
}));

export const OptionItem = styled(Box)(({ theme: { spacing } }) => ({
  marginBottom: spacing(2),
}));
