import React, { useCallback, useState, useEffect, memo } from "react";
import { styled } from "@mui/material";
import ChatIcon from "@mui/icons-material/ChatBubbleOutline";
import BlockUserIcon from "@mui/icons-material/Block";
import PromoteUserIcon from "@mui/icons-material/GroupAdd";
import { toast } from "react-toastify";

import { usePresenceList } from "../../providers/pubnub/usePresenceList";
import ParticipantList, {
  Props as ParticipantListProps,
  DEFAULT_MAX_LENGTH,
} from "../ParticipantList";

import {
  ChatLoader,
  PrivateChatProvider,
  usePrivateChats,
} from "../../providers/chat/PrivateChatProvider";
import { useEventChatContext } from "../../providers/chat/ChatProvider";
import { PrivateChat } from "../Chat/PrivateChat";
import { User, UserRole } from "../../contracts/user/user";
import { BlockUserDialog } from "../BlockUserDialog";
import { ParticipantType } from "../ParticipantList/Participant";
import PromoteUser from "../dialogs/content/PromoteUser";
import { useDialog } from "@src/providers/DialogProvider";
import { useIsSessionActive } from "../Presence/SocketClientProvider";
import { useIsRoute, Route } from "@src/hooks/useIsRoute";

export interface Props {
  user: User;
  hidePrivateChat: boolean;
  blockUser: (email: string) => Promise<void>;
  isSessionActive?: boolean;
}

const ChatContainer = styled("div")(() => ({
  height: "100%",
  width: "100%",
  display: "flex",
}));

const ParticipantsWithContext = memo(function (
  props: Props & {
    onCreatePrivateChat?: ParticipantListProps["createMenuItems"];
  },
) {
  const { openDialog } = useDialog();
  const isEvent = useIsRoute(Route.EVENT);
  const { onCreatePrivateChat, isSessionActive } = props;
  const [search, setSearch] = useState("");

  const [maxLength, setMaxLength] = useState(DEFAULT_MAX_LENGTH + 1);

  const { list: participants } = usePresenceList({
    search,
    maxLength,
  });
  const [pendingBlockUser, setPendingBlockUser] = useState<null | {
    name: string;
    email: string;
  }>(null);
  const [pendingPromoteViewer, setPendingPromoteViewer] =
    useState<null | ParticipantType>(null);

  const { user, blockUser } = props;
  const isHost = user.userRole === UserRole.Organizer;

  useEffect(() => {
    if (pendingPromoteViewer) {
      openDialog(
        "PromoteUser",
        <PromoteUser
          role={UserRole.Presenter}
          pendingPromoteViewer={pendingPromoteViewer}
          onClose={() => setPendingPromoteViewer(null)}
        />,
        {
          priority: "high",
          maxWidth: "xs",
          onClose: () => setPendingPromoteViewer(null),
          // TODO add a way to edit these properties dynamically from the dialog using editDialog/updateDialog
          hideCloseIcon: true,
          disableOutsideClick: true,
        },
      );
    }
  }, [openDialog, pendingPromoteViewer]);

  return (
    <>
      <ParticipantList
        participants={participants}
        searchPlaceholder="Search audience..."
        search={search}
        onSearchChange={setSearch}
        onMaxLengthChanged={(newMaxLength) => {
          // Add one so that ParticipantList can determine if more can be loaded
          setMaxLength(newMaxLength + 1);
        }}
        createMenuItems={(participant) => {
          if (user.uid === participant.userId) {
            // No menu for ourselves
            return [];
          }

          const privateChatMenu =
            (isSessionActive && onCreatePrivateChat?.(participant)) || [];

          const isParticipantHost = participant.userRole === UserRole.Organizer;
          if (!isHost || isParticipantHost) {
            // If current user is not host or other user is a host, then you can only chat
            return [...privateChatMenu];
          }

          return [
            ...privateChatMenu,

            // make unavailable in standalone hubs for now
            ...(!isEvent
              ? []
              : [
                  {
                    name: "block",
                    icon: <BlockUserIcon />,
                    tooltip: `Block ${participant.username || "participant"}`,
                    onClick: async () => {
                      const name = participant.username;
                      const email = participant.userId.split("|||")[1];
                      setPendingBlockUser({ name, email });
                    },
                  },
                ]),

            // make unavailable in standalone hubs for now
            ...(!isEvent ||
            participant.userRole !== UserRole.Viewer ||
            !isSessionActive
              ? []
              : [
                  {
                    name: "promote",
                    icon: <PromoteUserIcon />,
                    tooltip: `Invite ${
                      participant.username || "participant"
                    } to join the stage`,
                    "data-testid": `participant-menu-button-promote`,
                    onClick: () => setPendingPromoteViewer(participant),
                  },
                ]),
          ];
        }}
      />

      <BlockUserDialog
        show={!!pendingBlockUser}
        username={pendingBlockUser?.name || ""}
        onBlock={async () => {
          try {
            await blockUser(pendingBlockUser?.email as string);
            setPendingBlockUser(null);
          } catch (e) {
            console.error(e);
            toast.error("Error blocking user. Please try again");
          }
        }}
        onClose={() => setPendingBlockUser(null)}
      />
    </>
  );
});

const ParticipantsWithChat = (props: Props) => {
  const { user, isSessionActive } = props;
  const [isPrivateChat, setPrivateChat] = React.useState(false);

  const { activeChat: chatId, setActiveChat: setChat } = useEventChatContext();
  const { chats, handleUserInvite } = usePrivateChats();

  useEffect(() => {
    if (!chatId && isPrivateChat) {
      // When chatId goes null, then switch back to participant list
      setPrivateChat(false);
    }
  }, [chatId, isPrivateChat]);

  const onCreatePrivateChat = useCallback(
    ({ userId, username }) =>
      user.uid === userId
        ? []
        : [
            {
              name: "private-chat",
              icon: <ChatIcon />,
              tooltip: `Send Private Message to ${username || "participant"}`,
              "data-testid": `participant-menu-button-private-chat`,
              onClick: async () => {
                const chat = chats?.find(({ users }) => users.includes(userId));
                if (chat?.id) {
                  setChat(chat.id);
                } else {
                  const result = await handleUserInvite(userId);
                  result && setChat(result.chat.chatChannelId);
                }

                setPrivateChat(true);
              },
            },
          ],
    [chats, setChat, handleUserInvite, user.uid],
  );

  return !isPrivateChat ? (
    <ParticipantsWithContext
      {...props}
      onCreatePrivateChat={onCreatePrivateChat}
      isSessionActive={isSessionActive}
    />
  ) : (
    <ChatContainer>
      <ChatLoader fallback={null}>
        <PrivateChat hide={false} />
      </ChatLoader>
    </ChatContainer>
  );
};

const ParticipantsTabContent = (props: Props) => {
  const { hidePrivateChat } = props;
  const isSessionActive = useIsSessionActive();
  return hidePrivateChat || !isSessionActive ? (
    <ParticipantsWithContext {...props} isSessionActive={isSessionActive} />
  ) : (
    <PrivateChatProvider>
      <ParticipantsWithChat {...props} isSessionActive={isSessionActive} />
    </PrivateChatProvider>
  );
};

export default React.memo(ParticipantsTabContent);
