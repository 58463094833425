import { useMutation, UseMutationOptions } from "react-query";
import { Api } from "../../api/api";
import { toast } from "react-toastify";

export interface UseClearChatProps {
  networkingHubId: string;
  eventId?: string;
}
export type UseClearChatResult = {
  channel: string;
  success: boolean;
}[];
export interface UseClearChatOptions
  extends UseMutationOptions<
    UseClearChatResult,
    unknown,
    string[] | undefined,
    unknown
  > {}
/**
 * Clears the chat for the provided channels or all chats if `channels` is empty
 * @param networkingHubId The uid of the networking hub to which the circle belongs to
 * @param eventId The uid of the event to which the networking hub is attached if any
 */
export const useClearChat = (
  { networkingHubId, eventId }: UseClearChatProps,
  options?: UseClearChatOptions,
) => {
  const { onSuccess, onError } = options || {};
  const mutation = useMutation(
    (channels?: string[]) =>
      Api.NetworkingHubApi.ClearChat({ networkingHubId, eventId }, channels),
    {
      onSuccess: (data, variables, context) => {
        toast.success("Succesfully cleared chat");
        onSuccess?.(data, variables, context);
      },
      onError: (err, variables, context) => {
        toast.error("An Error occured while clearing the chat");
        onError?.(err, variables, context);
      },
    },
  );

  return mutation;
};
