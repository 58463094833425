import React from "react";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import Box from "@mui/material/Box";
import MuiInputLabel, {
  InputLabelProps as MuiInputLabelProps,
} from "@mui/material/InputLabel";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

export interface InputLabelProps extends Omit<MuiInputLabelProps, "variant"> {
  label: React.ReactNode;
  // TODO these should be tooltipProps
  tooltip?: string;
  htmlFor?: string;
  variant?: TypographyProps["variant"];
  variantMapping?: TypographyProps["variantMapping"];
  /**
   * Text that can be shown below the input label as a small description
   */
  description?: string | React.ReactNode;

  /**
   * Sets the flex direction (defaults to `vertical`)
   */
  direction?: "vertical" | "horizontal";

  /**
   * Whether or not to show the tooltip icon (defaults to `false`)
   *
   * `tooltip` must be set if enabled
   */
  showIcon?: boolean;

  /**
   * The input as a child of the label
   */
  children?: React.ReactNode;

  /**
   * The error message to show if the input has any errors
   */
  helperText?: string;

  /**
   * Makes the input fill the available width (defaults to false)
   */
  fullWidth?: boolean;

  /**
   * Allows for customization of the input label using the `sx` property
   */
  labelStyles?: TypographyProps["sx"];

  /**
   * Allows for customization of the input description using the `sx` property
   */
  descriptionStyles?: TypographyProps["sx"];
}

/**
 * An input label component that can be used to wrap inputs and provide labels, tooltips, icon, and error messages
 */
export const InputLabel: React.FC<InputLabelProps> = ({
  label,
  tooltip,
  description,
  variant = "body2",
  direction = "vertical",
  showIcon = false,
  htmlFor,

  // input
  color = "text.primary",
  children,
  error,
  helperText = "error",

  // styles
  sx = {},
  fullWidth = false,
  labelStyles = {},
  descriptionStyles = {},
  ...restProps
}) => {
  const isVertical = direction === "vertical";

  return (
    // container
    <Box
      sx={{
        mt: 2,
        width: fullWidth ? "100%" : "unset",
        display: "flex",
        flexDirection: "column",
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: isVertical ? "start" : "center",
          flexDirection: isVertical ? "column" : "row",
          gap: isVertical ? 0 : 2,
          width: "100%",
        }}
      >
        {/* label and description */}
        <div className="w-full">
          {/* label */}
          <Tooltip
            title={tooltip || ""}
            placement="top-start"
            componentsProps={{
              tooltip: {
                sx: {
                  // TODO MUI v5
                  fontSize: "1rem",
                  backgroundColor: "base.500",
                },
              },
            }}
          >
            <MuiInputLabel
              htmlFor={htmlFor}
              error={error}
              sx={{ whiteSpace: "unset" }}
              {...restProps}
            >
              <Typography
                component="span"
                variant={variant}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb:
                    typeof description === "string" || direction === "vertical"
                      ? 0.5
                      : 0,
                  gap: 0.5,
                  color: error ? "inherit" : color,
                  ...labelStyles,
                }}
              >
                {label}

                {/* icon */}
                {showIcon && tooltip && (
                  <InfoOutlined sx={{ fontSize: "1em" }} />
                )}
              </Typography>
            </MuiInputLabel>
          </Tooltip>

          {/* description */}
          {description &&
            (typeof description === "string" ? (
              <Typography
                variant="caption"
                component="p"
                sx={{
                  mb: isVertical ? 0.75 : 0,
                  ...descriptionStyles,
                }}
              >
                {description}
              </Typography>
            ) : (
              description
            ))}
        </div>

        {/* input */}
        {children}
      </Box>

      {/* error */}
      {error && (
        <Typography
          variant="caption"
          component="p"
          color="error"
          sx={{ mt: 0.5, textAlign: "start" }}
        >
          {helperText}
        </Typography>
      )}
    </Box>
  );
};
