import { intervalToDuration } from "date-fns";
import React, { useEffect, useState } from "react";

export interface Countdown {
  isPast: boolean;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}
export interface CountdownClockProps {
  date: Date;
  children: (countdown: Countdown) => React.ReactNode;
  backwards?: boolean;
}

export const CountdownClock = ({
  date,
  children,
  backwards,
}: CountdownClockProps) => {
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setNow(new Date());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [date, backwards]);

  const targetDate = now < date && backwards ? now : date;
  const duration = intervalToDuration({
    start: date,
    end: targetDate,
  });

  const durationToEventStart = date.getTime() - targetDate.getTime();
  const { hours, minutes, seconds } = duration;
  const days = Math.floor(Math.abs(durationToEventStart) / 1000 / 60 / 60 / 24);

  return (
    <>
      {children({
        isPast: Boolean(backwards && now > date),
        days,
        hours: hours || 0,
        minutes: minutes || 0,
        seconds: seconds || 0,
      })}
    </>
  );
};
