import constate from "constate";
import mapValues from "lodash/mapValues";
import groupBy from "lodash/groupBy";
import hash from "object-hash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { UseQueryResult, useQueries } from "react-query";
import {
  useRecoilState,
  useRecoilValue,
  selectorFamily,
  useRecoilCallback,
} from "recoil";
import invariant from "tiny-invariant";

import { Api } from "../../api/api";
import { getUserBaseAvatar } from "../../helpers/getUserBaseAvatar";
import { makeDataUri } from "../../helpers/makeDataUri";
import { useLatestCallback } from "../../hooks/useLatestCallback";
import {
  useNetworkingHub,
  useTemporaryRooms,
} from "@src/providers/NetworkingHubProvider";
import { useUser as useAppUser } from "../../providers/UserProvider";
import { useSocketClient } from "./SocketClientProvider";
import { emitAsPromise } from "./emitAsPromise";
import { useSyncSocketEvent } from "./useSyncSocketEvent";
import { useSyncSocketRoom } from "./useSyncSocketRoom";
import { useSyncSocketUser } from "./useSyncSocketUser";
import { useUserRole } from "../../providers/UserProvider";
import type { NetworkingHubRoom } from "../../contracts/networking-hub/NetworkingHubRoom";
import {
  FeatureFlag,
  useFeatureFlag,
} from "@src/providers/FeatureFlagsProvider";
import { useUnmount } from "react-use";

import {
  SocketUser,
  SocketUserMeta,
  usersAtom,
  usersMapAtom,
  roomUsersAtom,
  participantsAtom,
} from "./common";

export type { SocketUser, SocketUserMeta };

export enum SocketStatus {
  Ready = "ready",
  NotReady = "not_ready",
}

export interface IntrovokePresenceContextValue {
  socketStatus: SocketStatus;
  rooms: string[];
  connected: boolean;
  roomUsers: Record<string, SocketUser[]>;
  circle: NetworkingHubRoom | null;
  setRooms: (rooms: string[]) => void;
  isReady: boolean;
}

const mapUserId = (user: SocketUser): SocketUser => {
  if (user.meta.userId.includes("|||")) {
    return user;
  }
  return {
    ...user,
    meta: {
      ...user.meta,
      userId: `${user.meta.userId}|||${user.meta.email}`,
    },
  };
};

export const useUserAvatars = (
  userIds: { userId: string; email: string; avatar: string }[],
) => {
  const avatars: UseQueryResult<[string, string]>[] = useQueries(
    userIds.map(({ userId, email, avatar }) => {
      const isHttpAvatarLink = avatar.startsWith("http");

      return {
        enabled: false,
        queryKey: ["Avatar", userId, avatar],
        queryFn: async () => {
          return [userId, await Api.UserApi.getUserAvatar(userId)];
        },
        cacheTime: Infinity,
        initialData: [
          userId,
          isHttpAvatarLink ? avatar : getUserBaseAvatar(userId, email),
        ],
      };
    }),
    // useQueries has problem with types sadly
  ) as any;

  return Object.fromEntries(
    avatars
      .map((query) => query.data)
      .filter((entry): entry is [string, string] => Boolean(entry)),
  );
};

export const useUsers = () => useRecoilValue(usersAtom);

export const useParticipants = () => useRecoilValue(participantsAtom);

const selectSingleUserAtom = selectorFamily({
  key: "presenceSelectSingleUser",
  get:
    (userId: string | null | undefined) =>
    ({ get }) => {
      if (!userId) {
        return undefined;
      }
      const users = get(usersAtom);
      return users.find((user) => user.meta.userId === userId);
    },
});

export const useUser = (userId: string | null | undefined) =>
  useRecoilValue(selectSingleUserAtom(userId));

const selectIsUsersWithoutRoomAtom = selectorFamily({
  key: "presenceSelectIsUsersWithoutRoom",
  get:
    (userId: string | null | undefined) =>
    ({ get }) => {
      const users = get(usersAtom);
      return users.some(
        (user) => user.meta.userId !== userId && !user.meta.room,
      );
    },
});

export const useIsUsersWithoutRoom = (userId: string) =>
  useRecoilValue(selectIsUsersWithoutRoomAtom(userId));

export const useGetUser = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      (userId: string) => {
        const users = snapshot.getLoadable(usersAtom).valueMaybe();
        invariant(users);
        return users?.find((user) => user.meta.userId === userId);
      },
    [],
  );

export const useIntrovokePresence = ({
  eventId,
  disablePresence = false,
}: {
  eventId: string;
  disablePresence?: boolean;
}): IntrovokePresenceContextValue => {
  const isV2ChatServerEnabled = useFeatureFlag(FeatureFlag.CHAT_SERVER_V2);
  const client = useSocketClient();
  const user = useAppUser();
  const userRole = useUserRole();
  const { refetch: refetchRoomUsers } = useTemporaryRooms(eventId);

  const [usersMap, setUsersAtom] = useRecoilState(usersMapAtom);
  const [roomUsersMap, setRoomUsersAtom] = useRecoilState(roomUsersAtom);

  const [connected, setConnected] = useState(client.connected);

  // TODO: Make more efficient
  const avatarsById = useUserAvatars(
    Object.values(usersMap).map((updatedUser) => ({
      ...updatedUser.meta,
    })),
  );

  const mapUserAvatar = useCallback(
    (updatedUser: SocketUser) => {
      return {
        ...updatedUser,
        meta: {
          ...updatedUser.meta,
          avatar: avatarsById[updatedUser.meta.userId]
            ? makeDataUri(avatarsById[updatedUser.meta.userId])
            : updatedUser.meta.avatar,
        },
      };
    },
    [avatarsById],
  );

  useEffect(() => {
    if (connected) {
      return;
    }
    const id = setInterval(() => {
      setConnected(client.connected);
    }, 500);

    return () => {
      clearInterval(id);
    };
  }, [client, connected]);

  const setRoomUsers = useLatestCallback(
    (room: string, updatedUsers: SocketUser[]) => {
      setRoomUsersAtom((current) => ({
        ...current,
        [room]: updatedUsers.map(mapUserId),
      }));
    },
  );

  const setUsers = useLatestCallback(
    (updatedUsersMap: Record<string, SocketUser>) => {
      setUsersAtom(updatedUsersMap);

      // Get users in a rooms (with rooms not on current page)
      const roomsSet = new Set(rooms);
      const updatedRoomGroups = groupBy(
        Object.values(updatedUsersMap).filter(
          (user) => user.meta.room && !roomsSet.has(user.meta.room),
        ),
        "meta.room",
      );

      setRoomUsersAtom((current) => ({
        ...current,
        ...updatedRoomGroups,
      }));
    },
  );
  const [rooms, setRooms] = useState<string[]>([]);

  const subscribeRooms = useLatestCallback(async (updatedRooms: string[]) => {
    const result = (await emitAsPromise(client, "subscribe-rooms", {
      rooms: updatedRooms,
    })) as Record<string, SocketUser[]>;

    updatedRooms.forEach((room) => setRoomUsers(room, result[room] || []));
  });

  useEffect(() => {
    const handleConnect = () => {
      setConnected(true);
    };
    const handleDisconnect = () => {
      setConnected(false);
    };
    client.on("connect", handleConnect);
    client.on("disconnect", handleDisconnect);
    return () => {
      client.off("connect", handleConnect);
      client.off("disconnect", handleDisconnect);
    };
  }, [client]);

  const socketUser = useMemo(
    () => {
      if (!user?.uid) return null;
      const avatar = user.profilePicture.startsWith("http")
        ? user.profilePicture
        : `${hash(user.profilePicture)}-http`;

      return {
        avatar,
        email: user.email,
        userId: user.originalId,
        username: user.name,
        userRole: typeof userRole === "number" ? userRole : user.userRole,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      user?.email,
      user?.name,
      user?.profilePicture,
      user?.originalId,
      user?.userRole,
      user?.uid,
      userRole,
    ],
  );

  const eventSyncStatus = useSyncSocketEvent(client, connected, eventId);
  const userSyncStatus = useSyncSocketUser(
    client,
    connected,
    socketUser,
    "success" === eventSyncStatus,
  );
  const isReady = [eventSyncStatus, userSyncStatus].every(
    (status) => status === "success",
  );

  useEffect(() => {
    if (connected && isReady && !isV2ChatServerEnabled) {
      subscribeRooms(rooms);
    }
  }, [connected, isReady, isV2ChatServerEnabled, rooms, subscribeRooms]);

  useEffect(() => {
    if (!eventId) {
      return;
    }

    const handlePresenceUpdate = (data: { users: SocketUser[] }) => {
      if (Array.isArray(data.users)) {
        setUsers(
          data.users.reduce(
            (acc, updatedUser) =>
              Object.assign(acc, {
                [updatedUser.meta.userId]: mapUserId(updatedUser),
              }),
            {} as Record<string, SocketUser>,
          ),
        );
      }
    };

    const handleCirclePresenceUpdate = (data: {
      roomId: string;
      users: SocketUser[];
    }) => {
      // update local users when receiving a presence update
      if (Array.isArray(data.users) && typeof data.roomId === "string") {
        const usersMap = data.users.reduce(
          (acc, updatedUser) => ({
            ...acc,
            [updatedUser.meta.userId]: updatedUser,
          }),
          {},
        );
        setRoomUsers(data.roomId, Object.values(usersMap));
      }
      // also manually refetch all users from the server to get the latest state
      // and avoid updates overriding each other due to latency race conditions
      refetchRoomUsers();
    };

    if (!disablePresence) {
      client.on(`presence-${eventId}`, handlePresenceUpdate);
    }
    client.on(`circle-presence`, handleCirclePresenceUpdate);

    return () => {
      if (!disablePresence) {
        client.off(`presence-${eventId}`, handlePresenceUpdate);
      }
      client.off(`circle-presence`, handleCirclePresenceUpdate);
    };
  }, [
    client,
    eventId,
    disablePresence,
    setRoomUsers,
    setUsers,
    refetchRoomUsers,
  ]);

  useUnmount(() => {
    // clear all user presence to avoid keeping old presence when
    // switching between event and networking session
    setRoomUsersAtom({});
  });

  return {
    socketStatus:
      connected && isReady ? SocketStatus.Ready : SocketStatus.NotReady,
    rooms,
    setRooms,
    // TODO: Make more efficient
    roomUsers: useMemo(
      () =>
        mapValues(roomUsersMap, (updatedUsers) =>
          updatedUsers.map(mapUserAvatar),
        ),
      [mapUserAvatar, roomUsersMap],
    ),
    connected,
    isReady,
    circle: null,
  };
};

export const useIntrovokeNetworkingHubPresence = ({
  networkingHubId,
  circleId = null,
  disablePresence,
}: {
  networkingHubId: string;
  circleId?: string | null;
  disablePresence?: boolean;
}): IntrovokePresenceContextValue => {
  const client = useSocketClient();
  const presenceContext = useIntrovokePresence({
    eventId: networkingHubId,
    disablePresence,
  });

  const { data: networkingHub } = useNetworkingHub();
  const currentCircle = useMemo(() => {
    if (circleId) {
      const circle = networkingHub?.networkingHubRooms?.find(
        ({ id, name }) => id === circleId || name === circleId,
      );
      return circle || null;
    }
    return null;
  }, [networkingHub?.networkingHubRooms, circleId]);

  const roomSyncStatus = useSyncSocketRoom(
    client,
    presenceContext.connected,
    currentCircle,
    presenceContext.isReady,
  );

  return {
    ...presenceContext,
    circle: currentCircle,
    socketStatus:
      presenceContext.socketStatus === SocketStatus.Ready &&
      roomSyncStatus === "success"
        ? SocketStatus.Ready
        : SocketStatus.NotReady,
  };
};

export const [
  IntrovokePresenceProviderNexus,
  useSocketStatus,
  useRoomUsers,
  useRooms,
  useCircle,
  useIntrovokePresenceContext,
] = constate(
  (props: {
    value: IntrovokePresenceContextValue;
  }): IntrovokePresenceContextValue => props.value,
  (ctx) => ctx.socketStatus,
  (ctx) => ctx.roomUsers, // useRoomUsers
  ({ rooms, setRooms }) =>
    useMemo(() => ({ rooms, setRooms }), [rooms, setRooms]),
  ({ circle }) => circle,
  (props) => props,
);

export const IntrovokeNetworkingHubPresence: React.FC<{
  networkingHubId: string;
  circleId?: string;
  disablePresence?: boolean;
}> = ({ children, networkingHubId, circleId, disablePresence = false }) => {
  const context = useIntrovokeNetworkingHubPresence({
    networkingHubId,
    circleId,
    disablePresence,
  });

  return (
    <IntrovokePresenceProviderNexus value={context}>
      {children}
    </IntrovokePresenceProviderNexus>
  );
};

export const IntrovokePresence: React.FC<{
  id: string;
  disablePresence?: boolean;
}> = ({ children, id, disablePresence }) => {
  const context = useIntrovokePresence({ eventId: id, disablePresence });

  return (
    <IntrovokePresenceProviderNexus value={context}>
      {children}
    </IntrovokePresenceProviderNexus>
  );
};
