import React, { memo } from "react";
import Alert from "@mui/material/Alert";
import { useUser } from "@src/providers/UserProvider";
import { isOptimalBrowser } from "@src/helpers/browser";
import {
  FeatureFlag,
  useFeatureFlag,
} from "@src/providers/FeatureFlagsProvider";
import { UserRole } from "@src/contracts/user/user";

const BadBrowserAlert = () => {
  const { userRole } = useUser();

  const optimalBrowser = isOptimalBrowser();
  const disableBrowserCheck = useFeatureFlag(
    FeatureFlag.VS_DISABLE_BROWSER_CHECK,
  );
  const isBadBrowser =
    [UserRole.Organizer, UserRole.Presenter].includes(userRole) &&
    !disableBrowserCheck &&
    !optimalBrowser;

  if (!isBadBrowser) return null;

  return (
    <Alert
      style={{
        cursor: "pointer",
        alignItems: "center",
        justifyContent: "center",
      }}
      severity="warning"
      data-testid="bad-browser-warning"
    >
      {
        "Your browser is not compatible. If you wish to join virtually, please download the "
      }
      <a
        href="https://www.google.com/chrome/"
        target="_blank"
        rel="noopener noreferrer"
      >
        latest version of Chrome.
      </a>
    </Alert>
  );
};

export default memo(BadBrowserAlert);
