import React, { ReactNode } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import MuiButton, { ButtonProps } from "@mui/material/Button";

export interface Props extends ButtonProps {
  /**
   * Indicates that the button is loading and shows an indicator
   */
  loading?: boolean;
  /**
   * Placement of the loading indicator on the button, defaults to replacing text
   */
  loadingPosition?: "start" | "end";
  /**
   * React Node used for the loading indicator
   */
  loadingIndicator?: ReactNode;
}

const Button = ({
  children,
  loading,
  loadingPosition,
  loadingIndicator,
  disabled,
  startIcon,
  endIcon,
  ...props
}: Props) => {
  const { variant = "contained", ...buttonProps } = props;

  const indicator = loadingIndicator || (
    <CircularProgress size="1.2rem" sx={{ color: "inherit" }} />
  );

  return (
    <MuiButton
      {...buttonProps}
      variant={variant}
      disabled={disabled}
      startIcon={loadingPosition === "start" && loading ? indicator : startIcon}
      endIcon={loadingPosition === "end" && loading ? indicator : endIcon}
      sx={{
        // TODO MUI v5
        textTransform: "none",
        fontWeight: 700,
        ...props.sx,
      }}
    >
      {loading && typeof loadingPosition === "undefined"
        ? loadingIndicator || indicator
        : children}
    </MuiButton>
  );
};

export default React.memo(Button);
