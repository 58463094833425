import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDialog } from "@src/providers/DialogProvider";
import React, { memo } from "react";

export interface DeletePollDialogProps {
  onSubmit?: () => void;
}

export const DeletePollDialog: React.FC<DeletePollDialogProps> = memo(
  ({ onSubmit }) => {
    const { hideDialog } = useDialog();
    const handleSubmit = () => {
      hideDialog("DeletePollDialog");
      onSubmit?.();
    };

    const handleClose = () => {
      hideDialog("DeletePollDialog");
    };

    return (
      <div data-testid="delete-poll-dialog">
        <DialogTitle sx={{ p: 0, mb: 2 }} id="delete-poll-dialog-title">
          Delete Poll
        </DialogTitle>
        <DialogContent sx={{ p: 0, mb: 2 }}>
          <DialogContentText id="delete-poll-dialog-description">
            Do you wish to delete the poll and its results? This action cannot
            be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 0 }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            autoFocus
            data-testid="poll-delete-confirm-button"
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </div>
    );
  },
);
