import { Avatar, IconButton, styled, Typography } from "@mui/material";

export const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

export const StyledMuiAvatar = styled(Avatar)(({ theme: { spacing } }) => ({
  marginRight: spacing(1),
  height: 36,
  width: 36,
}));

export const AskerWrapper = styled("div")({
  marginRight: "auto",
  overflowX: "hidden",
});

export const UsernameText = styled(Typography)(({ theme: { spacing } }) => ({
  fontWeight: 600,
  marginRight: spacing(0.5),
}));

export const TimeText = styled(Typography)(
  ({ theme: { spacing, palette } }) => ({
    color: palette.text.secondary,
    marginTop: spacing(0.5),
    whiteSpace: "nowrap",
    flexShrink: 0,
  }),
);

export const TitleContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  marginRight: "auto",
  overflowX: "hidden",
});

export const Title = styled(Typography)(({ theme: { spacing } }) => ({
  fontWeight: 600,
  marginTop: spacing(1),
  marginBottom: spacing(1),
  overflow: "auto",
}));

export const StyledMuiIconButton = styled(IconButton)(
  ({ theme: { palette } }) => ({
    marginRight: 0,
    "&:hover": {
      backgroundColor: `${palette.primary.main}40`,
    },
  }),
);
