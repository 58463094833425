import { useMutation } from "react-query";
import { useSocketClient } from "@src/components/Presence/SocketClientProvider";
import { parseDataToType } from "@src/guards/parseDataToType";
import { emitAsPromise } from "@src/components/Presence/emitAsPromise";
import { Actions } from "@src/graphql/schemas/actions";

import { TemporaryRoom, TemporaryRoomType } from "./common";
import { toast } from "react-toastify";
import { useSetTemporaryRooms } from "./useTemporaryRooms";

interface CreateRoomArgs {
  name: string;
  slots: number;
  metadata: Record<string, string>;
}

/**
 * Returns a mutation to create a temporary networking hub room for use with random mingle and create circle.
 */
export function useCreateNetworkingHubRoom(
  networkingHubId: string | null | undefined,
) {
  const client = useSocketClient();
  const setTempRooms = useSetTemporaryRooms(networkingHubId);

  const mutation = useMutation<TemporaryRoom, unknown, CreateRoomArgs>(
    async (input: CreateRoomArgs) => {
      // TODO: Temporary fix to prevent app crash
      if (!input.metadata) {
        input.metadata = {};
      }

      const result = parseDataToType(
        await emitAsPromise(client, Actions.CREATE_ROOM, input),
        TemporaryRoomType,
      );

      return result as TemporaryRoom;
    },
    {
      onSuccess: (data: TemporaryRoom) => {
        setTempRooms((prev) => [...prev, data]);
      },
      onError: () => {
        toast.error("There was an error creating the circle");
      },
    },
  );

  return mutation;
}
