import { MetricsEventGroups } from "../common";

export const VirtualStage = {
  PLAYLIST_ADD: {
    event: "Playlist Add",
    group: MetricsEventGroups.VIRTUAL_STAGE,
  },
  PLAYLIST_PLAY: {
    event: "Playlist Play",
    group: MetricsEventGroups.VIRTUAL_STAGE,
  },
  FULLSCREEN: {
    event: "Fullscreen",
    group: MetricsEventGroups.VIRTUAL_STAGE,
  },
  COMPATIBILITY_CHECKER: {
    event: "Compatibility Checker",
    group: MetricsEventGroups.VIRTUAL_STAGE,
  },
  CC_ENABLED: {
    event: "CC Enabled",
    group: MetricsEventGroups.VIRTUAL_STAGE,
  },
  GO_LIVE: {
    event: "Go_Live",
  },
};
