import { Api } from "@src/api/api";
import { QueryKeys } from "@src/api/QueryKeys";
import { dbUser } from "@src/contracts/user/dbUser";
import {
  RegisteredAttendeeUserResponse,
  User,
  UserRole,
} from "@src/contracts/user/user";
import { getUserRoleCookie, saveUserRole } from "@src/helpers/cookie";
import { useLogoutUser } from "./useLogoutUser";
import { useQuery } from "react-query";
import invariant from "tiny-invariant";
import type { UserProps } from "../UserProvider";
import { useAuthParams } from "@src/providers/QueryParamsProvider";

interface AuthenticatedUserOptions {
  eventId?: string;
  networkingHubId?: string;
  authKey?: string;
}

interface AuthenticatedUserResponse {
  user?: UserProps;
  isLoading: boolean;
}

/**
 * for usage by the UserProvider only
 * this validate a user is authenticated via the API by using either the authKey or a joinCode
 * @returns {{ user: UserProps, isLoading: boolean }}
 */
export const useAuthenticatedUser = ({
  eventId,
  networkingHubId,
  authKey,
}: AuthenticatedUserOptions): AuthenticatedUserResponse => {
  const eventOrNetworkingHubId = eventId || networkingHubId;
  const logoutUser = useLogoutUser();
  const { userRole: cookieUserRole } = getUserRoleCookie(
    eventOrNetworkingHubId,
  );
  const { joinCode } = useAuthParams();

  const { data, isLoading } = useQuery<UserProps | undefined>(
    QueryKeys.userFromToken(authKey as string, eventOrNetworkingHubId),
    async () => {
      let user: User | dbUser | RegisteredAttendeeUserResponse | null = null;

      // if joinCode exists => use joinCode to authenticate user
      if (joinCode) {
        // TODO would be a good idea to have a single route for this
        if (eventId) {
          user = await Api.EventApi.GetUserJoinInformation(eventId, joinCode);
        } else if (networkingHubId) {
          user = await Api.NetworkingHubApi.GetUserJoinInformation(
            networkingHubId,
            joinCode,
          );
        }
      } else {
        // otherwise use authKey from params or cookies (checked above)
        invariant(authKey, "authKey must be string");
        if (eventOrNetworkingHubId) {
          user = await Api.UserApi.getUserWithToken(
            authKey,
            eventOrNetworkingHubId,
          );
        }
      }

      if (eventOrNetworkingHubId) {
        const { userRole: localRole } = getUserRoleCookie(
          eventOrNetworkingHubId,
        );

        // if invalid authKey (401 response doesn't trigger `onError`)
        if (!user) {
          throw new Error("Invalid or expired authKey");
        }

        const userRole =
          user?.authToken || authKey
            ? Math.min(UserRole.Viewer, localRole)
            : localRole;

        saveUserRole(
          userRole,
          eventOrNetworkingHubId,
          user?.authToken || (authKey as string),
        );
      }

      if (!user) return;

      return {
        userId: user.uid,
        userEmail: user.privateUserInfo?.email || user.email,
        userName: user.name,
        originalId: user.originalId,
        userProfile: user.profilePicture,
        userRole: Math.min(cookieUserRole, UserRole.Viewer),
        mode: "test",
      };
    },
    {
      enabled: !!((eventId || networkingHubId) && (authKey || joinCode)),
      retry: 1,
      refetchOnWindowFocus: false,
      onError: logoutUser,
    },
  );

  return {
    user: data,
    isLoading,
  };
};
