import React from "react";
import { AppRoutes } from "./AppRoutes";
import { useConfigProvider } from "./providers/config";
import { UserProvider } from "./providers/UserProvider";
import { initializeSentry } from "./helpers/logging";
import { useEmbedProvider } from "./providers/embed";
import { useMediaPermissionsProvider } from "./providers/MediaPersmissionsProvider";
import {
  MetricsInstanceIds,
  useInitializeMetrics,
} from "@introvoke/react/metrics";
import { TrackedMetricsEvents } from "@src/metrics";
import { ApiConfig } from "@src/api/api-config";
import { Environment, getEnvironment } from "./helpers/env";
import FullscreenProvider from "./providers/FullscreenProvider";
import { ThemeProvider } from "./providers/ThemeProvider";
import { FeatureFlagsProvider } from "./providers/FeatureFlagsProvider";
import DialogProvider from "./providers/DialogProvider";
import { FullPageLoader } from "./components/FullPageLoader";

import { SidebarProvider } from "./providers/SideBarTabsProvider";
import { useRecurringEventRedirect } from "./hooks/useRecurringEventRedirect";

const METRICS_ENVIRONMENTS: Environment[] = ["prod", "stg"];

// setup & register sentry global error reporting
initializeSentry();

export default function App() {
  useConfigProvider();
  useInitializeMetrics({
    projectApiKey: process.env.REACT_APP_AMPLITUDE_EMBED_API_KEY || "",
    instanceId: MetricsInstanceIds.EMBED,
    disabled: !METRICS_ENVIRONMENTS.includes(getEnvironment()),
    internalApiEndpoint: ApiConfig.GetPrefix(),
    trackedEvents: TrackedMetricsEvents,
  });
  useEmbedProvider();
  useMediaPermissionsProvider();

  const { isLoading } = useRecurringEventRedirect();

  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <UserProvider>
      <FullscreenProvider>
        <FeatureFlagsProvider fallback={<FullPageLoader />}>
          <ThemeProvider>
            <DialogProvider>
              <SidebarProvider>
                <AppRoutes />
              </SidebarProvider>
            </DialogProvider>
          </ThemeProvider>
        </FeatureFlagsProvider>
      </FullscreenProvider>
    </UserProvider>
  );
}
