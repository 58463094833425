/** @jsxImportSource @emotion/react */

import React, { memo, useMemo, useState } from "react";

import { EnterPasscodeModal } from "../SideBar/EnterPasscodeModal";
import { useUser } from "../../providers/UserProvider";
import { UserRole } from "../../contracts/user/user";
import { StageHeaderBaseButton } from "../Event/EventStage/StageHeaderBaseButton";
import { useConfigValue } from "@src/providers/config";

const EnterPasscodeModalButton = () => {
  const [showModal, setShowModal] = useState(false);
  const { nameOnlyEntry } = useConfigValue();
  const { userRole } = useUser();

  const buttonText =
    userRole === UserRole.Viewer
      ? `I’m a Host or Presenter`
      : userRole === UserRole.Presenter
      ? `I’m a Host`
      : "";

  return useMemo(
    () =>
      !nameOnlyEntry && buttonText.length ? (
        <>
          <StageHeaderBaseButton
            data-testid="enter-passcode-modal-btn"
            sx={{ mx: 2 }}
            onClick={() => setShowModal(true)}
          >
            {buttonText}
          </StageHeaderBaseButton>

          <EnterPasscodeModal
            onClose={() => setShowModal(false)}
            show={showModal}
          />
        </>
      ) : null,
    [buttonText, showModal, nameOnlyEntry],
  );
};

export default memo(EnterPasscodeModalButton);
