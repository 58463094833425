import { useMemo } from "react";

/**
 * Supported environments for the application
 */
export enum Environment {
  DEV = "dev",
  STAGING = "stg",
  PRODUCTION = "prod",
  TEST = "test",
}

/**
 * Gets the current environment for the application
 * @returns The current environment enum value
 */
export const getCurrentEnvironment = () => {
  if (process.env.NODE_ENV === "test") return Environment.TEST;

  if (process.env.REACT_APP_ENVIRONMENT === "stg") {
    return Environment.STAGING;
  } else if (
    process.env.NODE_ENV === "development" ||
    process.env.REACT_APP_ENVIRONMENT === "dev"
  ) {
    return Environment.DEV;
  }

  return Environment.PRODUCTION;
};

/**
 * Checks if the provided environment is the current environment
 * @param environment The environment to check
 * @returns `true` if the provided environment is the current one, `false` if not
 */
export const isEnvironment = (environment: Environment) =>
  getCurrentEnvironment() === environment;

/**
 * Hook used for getting the current environment
 * @returns The current environment value
 */
export const useEnvironment = () => useMemo(() => getCurrentEnvironment(), []);

/**
 * Hook used for checking whether the provided environment is the current one
 * @param environment The environment to check
 * @returns `true` if the provided environment is the current one, `false` if not
 */
export const useIsEnvironment = (environment: Environment) =>
  useMemo(() => isEnvironment(environment), [environment]);
