import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { UserContextValue } from "../UserProvider";

interface UseSetSentryUserOptions {
  contextValue?: UserContextValue;
}

/**
 * for usage by the UserProvider only
 * Keeps sentry upto date with the latest user session properties
 */
export const useSetSentryUser = ({ contextValue }: UseSetSentryUserOptions) => {
  useEffect(() => {
    if (!contextValue?.user?.originalId) {
      Sentry.setUser(null);
    } else {
      Sentry.setUser({
        id: contextValue.user?.originalId,
        email: contextValue.user?.email,
        name: contextValue.user?.name,
      });
    }
  }, [
    contextValue?.user?.originalId,
    contextValue?.user?.email,
    contextValue?.user?.name,
  ]);
};
