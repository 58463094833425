import { Api } from "@src/api/api";
import { RegistrationType } from "@src/contracts/event/event";
import { useRouteParams } from "@src/hooks/useRouteParams";
import { useCallback, useState } from "react";

interface ValidateJoinCodeReturnType {
  isLoading: boolean;
  validateJoinCode: (code: string) => Promise<boolean>;
}

export const useValidateJoinCode = (): ValidateJoinCodeReturnType => {
  const [isLoading, setIsLoading] = useState(false);
  const { eventId, networkingHubId } = useRouteParams();
  const registrationType = networkingHubId
    ? RegistrationType.NETWORKING_HUB
    : RegistrationType.EVENT;

  const validateJoinCode = useCallback(
    async (code: string) => {
      try {
        if (!eventId && !networkingHubId) {
          return false;
        }
        setIsLoading(true);

        const user =
          registrationType === RegistrationType.EVENT
            ? eventId
              ? await Api.EventApi.GetUserJoinInformation(
                  eventId,
                  code as string,
                )
              : null
            : networkingHubId
            ? await Api.NetworkingHubApi.GetUserJoinInformation(
                networkingHubId,
                code as string,
              )
            : null;
        const isValid = user?.privateUserInfo.email !== undefined;
        if (!isValid) {
          return false;
        }

        return true;
      } catch (e) {
        return false;
      } finally {
        setIsLoading(false);
      }
    },
    [eventId, networkingHubId, registrationType],
  );

  return {
    isLoading,
    validateJoinCode,
  };
};

export default useValidateJoinCode;
