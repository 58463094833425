import { emitAsPromise } from "@src/components/Presence/emitAsPromise";
import { User } from "@src/contracts/user/user";
import { Actions } from "@src/graphql/schemas/actions";
import { useCallback, useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { createPollsQueryKey, orderPolls } from "../helpers";
import { PollActionRollbackFunction } from "../types";
import {
  CreateEventPollInput,
  Poll,
  PollActionHookOptions,
  PollStatus,
  UserPoll,
} from "../types";

/**
 * Callback to add the newly created poll to the supplied local polls array
 */
const insertCreatedPoll = (data: Poll) => (prev?: Poll[] | null) => {
  const previousPolls = Array.isArray(prev) ? prev : [];
  const pollIdx = previousPolls.findIndex((poll) => poll.id === data.id);

  if (pollIdx >= 0) {
    // override optimistically created local poll
    const updated = [...previousPolls];
    updated[pollIdx] = data;
    return updated;
  }

  return orderPolls([...previousPolls, data]);
};

/**
 * Function to create the basic poll data to perform an optimistic create
 */
const createNewPollData = ({
  poll,
  user,
  eventId,
  circleId,
}: {
  poll: CreateEventPollInput;
  user: User;
  eventId?: string | null;
  circleId?: string | null;
}) => {
  const newPoll: UserPoll = {
    pending: false, // pending state in pubnub?
    isPending: true,
    id: poll.id as string,
    eventId: eventId as string,
    circleId: circleId as string,
    createdAt: Date.now(),
    updatedAt: Date.now(),
    name: poll.name,
    options: poll.options.map((option, idx) => ({
      id: String(idx),
      name: option.name,
      selected: false,
      votes: 0,
    })),
    status: poll.status as PollStatus,
    resultsPublished: !poll.delayResults,
    resultsType: poll.resultsType,
    hidden: false,
    owner: {
      avatar: user.profilePicture,
      email: user.email,
      userId: user.originalId,
      username: user.name,
      userRole: user.userRole,
    },
  };
  return newPoll;
};

export interface UseCreateEventPollOptions extends PollActionHookOptions {
  user: User;
}

export const useCreateEventPoll = ({
  client,
  eventId,
  circleId,
  user,
}: UseCreateEventPollOptions) => {
  const cache = useQueryClient();
  const queryKey = createPollsQueryKey(eventId, circleId);

  /**
   * Mutation that creates a new poll
   */
  const mutation = useMutation<
    UserPoll,
    unknown,
    CreateEventPollInput,
    PollActionRollbackFunction
  >(
    (input: CreateEventPollInput) =>
      emitAsPromise<Poll>(client, Actions.CREATE_EVENT_POLL, input),
    {
      onMutate: (poll) => {
        const previousPolls = cache.getQueryData<Poll[]>(queryKey);

        const newPoll = createNewPollData({
          poll,
          user,
          eventId,
          circleId,
        });

        // optimistically add created poll
        cache.setQueryData<Poll[]>(queryKey, insertCreatedPoll(newPoll));

        return () => cache.setQueryData(queryKey, previousPolls);
      },
      onError: (err, variables, rollback) => {
        // rollback on error (success is handled by the handlePollCreated)
        rollback?.();
        toast.error("There was an error creating the poll");
      },
    },
  );

  /**
   * Callback to run when a `poll-created` event is received from the client server
   */
  const handlePollCreated = useCallback(
    (data: Poll) => {
      cache.setQueryData<Poll[]>(
        createPollsQueryKey(data.eventId, data.circleId),
        insertCreatedPoll(data),
      );
    },
    [cache],
  );

  return useMemo(
    () => ({ mutation, handlePollCreated }),
    [handlePollCreated, mutation],
  );
};

export const __testable__ = {
  insertCreatedPoll,
  createNewPollData,
};
