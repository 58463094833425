import React from "react";
import { Box, Typography } from "@mui/material";
import { BaseButton } from "../buttons/BaseButton";

export interface ErrorPageProps {
  /**
   * Shows a refresh button to the user
   */
  showRefresh?: boolean;
  /**
   * A simple error message to display to the user. Will get overwritten by body if that is provided instead.
   */
  message?: string;
  /**
   * A custom error component to display to the user. Will override message if provided.
   */
  body?: React.ReactNode;
}

const DefaultErrorPage = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={2}
      p={3}
    >
      <Typography variant="h1">
        Oops! Something went wrong while starting your session.
      </Typography>
      <Typography variant="h2" color="text.secondary">
        Try refreshing the page. If the issue persists, please contact support.
      </Typography>
    </Box>
  );
};

const ErrorPage = ({ message, body, showRefresh = true }: ErrorPageProps) => {
  const error = body ?? message ?? <DefaultErrorPage />;

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      position="absolute"
      top={0}
      left={0}
      bottom={0}
      right={0}
      justifyContent="center"
      alignItems="center"
      p={3}
    >
      {typeof error === "string" ? (
        <Typography variant="h1">{error}</Typography>
      ) : (
        error
      )}
      {showRefresh ? (
        <BaseButton
          data-testid="error-page-refresh-btn"
          onClick={() => window.location.reload()}
          sx={{ mt: 4 }}
        >
          Refresh
        </BaseButton>
      ) : null}
    </Box>
  );
};

export default React.memo(ErrorPage);
