import React, { memo, ReactNode, useMemo } from "react";
import { styled, Theme, Typography } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandMore";
import ExpandMoreIcon from "@mui/icons-material/ExpandLess";
import { Virtuoso } from "react-virtuoso";

import { EmptyQAndAList } from "./EmptyQAndAList";
import { UserQuestion } from "../../providers/qAndA/QandAProvider";
import useIsShortScreen from "../../hooks/useIsShortScreen";

const TitleContainer = styled("div")(({ theme: { spacing, breakpoints } }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: spacing(1),
  paddingLeft: spacing(1.5),
  paddingRight: spacing(1.5),
  [breakpoints.down("sm")]: {
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
  },
}));

const SectionTitle = styled(Typography)(({ theme: { typography } }) => ({
  fontSize: typography.body2.fontSize,
}));

const ListContainer = styled("div")(() => ({
  overflow: "auto",
  flex: 1,
}));

const List = styled(Virtuoso)(() => ({
  overflow: "auto",
}));

type SectionHeaderProps = {
  title: string;
  count: number;
  expanded: boolean;
  enableBackground?: boolean;
  onClick?: () => void;
};

export const SectionHeader = memo(
  ({
    title,
    enableBackground,
    count,
    expanded,
    onClick,
  }: SectionHeaderProps) => {
    const isShortScreen = useIsShortScreen();

    const sx = useMemo(() => {
      if (enableBackground) {
        return ({ spacing, palette }: Theme) => ({
          backgroundColor: palette.base[100],
          marginBottom: 0,
          paddingBottom: spacing(1),
          cursor: "pointer",
          "&:hover": {
            backgroundColor: palette.secondary.main,
            color: palette.common.white,
          },
        });
      }
      if (isShortScreen) {
        return ({ palette }: Theme) => ({
          cursor: "pointer",
          "&:hover": {
            backgroundColor: palette.secondary.main,
            color: palette.common.white,
          },
        });
      }
    }, [enableBackground, isShortScreen]);

    return (
      <TitleContainer aria-label={`${title} section`} onClick={onClick} sx={sx}>
        <SectionTitle variant="h4">
          {count ? `${title} (${count})` : title}
        </SectionTitle>
        {onClick && (expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
      </TitleContainer>
    );
  },
);

interface QuestionListCollectionProps {
  title: string;
  enableBackground?: boolean;
  expanded: boolean;
  onHeaderClick?: () => void;
  questions: UserQuestion[];
  renderItem: (question: UserQuestion, isLastItem: boolean) => ReactNode;
}

const QuestionListCollection = ({
  title,
  enableBackground = false,
  expanded,
  onHeaderClick,
  questions,
  renderItem,
}: QuestionListCollectionProps) => {
  return (
    <>
      <SectionHeader
        title={title}
        enableBackground={enableBackground}
        count={questions.length}
        expanded={expanded}
        onClick={onHeaderClick}
      />
      {expanded && (
        <ListContainer
          sx={
            enableBackground
              ? ({ palette }) => ({
                  backgroundColor: palette.base[100],
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  marginBottom: 0,
                })
              : undefined
          }
        >
          {questions.length === 0 ? (
            <EmptyQAndAList />
          ) : (
            <List
              initialItemCount={
                // Need this for tests to render the list https://github.com/petyosi/react-virtuoso/issues/26
                process.env.NODE_ENV === "test" ? questions.length : undefined
              }
              totalCount={questions.length}
              itemContent={(index) => {
                const question = questions[index];
                const isLastItem = index + 1 >= questions.length;
                return renderItem(question, isLastItem);
              }}
            />
          )}
        </ListContainer>
      )}
    </>
  );
};
export default memo(QuestionListCollection);
