import React, { memo, useState } from "react";
import { VideoPlayerProps } from "./types";
import IvsVideoPlayer from "./IvsVideoPlayer/IvsVideoPlayer";
import NativeVideoPlayer from "./NativeVideoPlayer";
import { useDeepCompareEffect } from "react-use";
import { VideoJsPlayerOptions } from "video.js";

const IVS_STREAM_EXT = ".m3u8";

/**
 * A video player component that can be used to play multiple different
 * types of videos and live streams.
 *
 * Currently supports the following:
 * - IVS Streams/Videos (with `.m3u8` extensions)
 * - Native HTML video sources
 */
const VideoPlayer = ({
  src,
  autoplay,
  muted,
  controls,
  loop,
  videoJsOptions = {},
  ...rest
}: VideoPlayerProps) => {
  // Let's merge all the props into the VideoJS expected
  const currentOptions: VideoJsPlayerOptions = {
    src,
    autoplay: autoplay ? "any" : false,
    loop,
    muted,
    controls,
    ...videoJsOptions,
    // Disable PiP by default
    controlBar: {
      pictureInPictureToggle: false,
      ...(videoJsOptions?.controlBar ?? {}),
    },
  };
  const [mergedOptions, setMergedOptions] = useState(currentOptions);

  useDeepCompareEffect(() => {
    setMergedOptions(currentOptions);
  }, [currentOptions]);

  // Right now this seems to be the way we recognize an IVS media
  if (src?.includes(IVS_STREAM_EXT)) {
    return <IvsVideoPlayer options={mergedOptions} {...rest} />;
  }
  return <NativeVideoPlayer options={mergedOptions} {...rest} />;
};

export default memo(VideoPlayer);
