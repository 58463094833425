import {
  FeatureFlag,
  useFeatureFlag,
} from "@src/providers/FeatureFlagsProvider";
import { useEvent } from "@src/providers/EventProvider";

/**
 * Returns `true` if the closed caption feature is currently enabled for the event
 */
export const useClosedCaptionsFeatureEnabled = () => {
  const featureFlagEnabled = useFeatureFlag(FeatureFlag.EVENT_CLOSED_CAPTIONS);
  const { data: event } = useEvent();

  return (
    featureFlagEnabled &&
    !!event?.closeCaptioningSettings?.transcription?.enabled
  );
};

export default useClosedCaptionsFeatureEnabled;
