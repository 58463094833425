import stringify from "fast-safe-stringify";

export const emitAsPromise = <T extends any>(
  client: SocketIOClient.Socket,
  event: string,
  ...data: any[]
): Promise<T> => {
  return new Promise((resolve, reject) => {
    if (!client.connected) {
      reject(new Error("Client is not connected"));
      return;
    }
    client.emit(event, ...data, (err: unknown, result: unknown) => {
      if (err) {
        reject(err instanceof Error ? err : new Error(stringify(err)));
      } else resolve(result as T);
    });
  });
};
