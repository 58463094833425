import { useEffect, useRef, useState } from "react";
import { useDeepCompareEffect, useTimeoutFn } from "react-use";
import { QueryKey, useQueryClient } from "react-query";

import {
  SubscribeArgs,
  subscribe as gqlSubscribe,
  QueryArgs,
} from "@src/graphql";
import { useLatestCallback } from "../../hooks/useLatestCallback";
import { useGqlQuery, UseGqlQueryArgs } from "./useGqlQuery";
import { logGqlError } from "../helpers";
import useDeepMemo from "@src/hooks/useDeepMemo";

export const MAX_RETRIES = 5;
export const RETRY_TIMEOUT_MS = 5 * 1000;

export interface UseGqlSubscriptionArgs<TData, TError = unknown>
  extends Pick<
    UseGqlQueryArgs<TData>,
    "dataUpdater" | "pollingInterval" | "apiFallbackFn"
  > {
  /**
   * The query key for the subscription
   */
  queryKey: QueryKey;
  /**
   * The GQL Subscription query
   */
  query: SubscribeArgs["query"];
  /**
   * The GQL Subscription variables
   */
  variables: SubscribeArgs["variables"];
  /**
   * Flag that indicates if the subscription should be enabled. Defaults to `true`
   */
  enabled?: boolean;
  /**
   * Flag that indicates if the query fallback should be used if the subscription is disabled or unavailable
   * Defaults to `false` until all queries have fallbacks
   */
  queryFallback?: boolean;
  /**
   * The optional initial data.
   */
  initialData?: TData;
  /**
   * An optional GQL Query to run for the initial data
   * (This should be required to add query fallback)
   *
   * Optionally, this can be set to an empty string in favor of the `apiFallbackFn`
   * for subscriptions which don't have queries yet
   */
  initialQuery?: QueryArgs["query"];
  /**
   * The optional variables for the `initialQuery`. This will fallback to the same
   * `variables`.
   */
  initialVariables?: QueryArgs["variables"];
  /**
   * Optional callback for receiving errors
   */
  onError?: (err: TError) => void;
}

/**
 * Hook that listens for GraphQL Subscription events.
 *
 * - If the subscription is disabled:
 *  => neither the GQL Subscription nor polling will be used.
 *
 * - If the subscription is enabled but fails with `queryFallback`:
 *  => will try to fallback to polling and try to resubscribe up to 5 times in the meantime.
 *
 * - If the `initialQuery` fails at any point, the provided `apiFallbackFn` will be used instead if any.
 *
 * Example:
 * ```ts
 * const { data: eventStatus } = useGqlSubscription<EventStatusInfoFragment>({
    enabled: true,
    queryKey: QueryKeys.eventStatus(eventId),
    query: SUBSCRIBE_EVENT_STATUS,
    variables: { eventId },

    queryFallback: true, // allow fallback to polling if the subscription fails

    // either `initialQuery` or `apiFallbackFn` should be provided to use fallback polling
    initialQuery: GET_EVENT_STATUS,
    apiFallbackFn: () => Api.EventApi.GetEventStatus(eventId) as any,

    // optional data updater function to apply to the received data for both the query and the subscription
    // this can be really useful to make sure there are no discrepancies between them
    dataUpdater: (prevData, newData) => ({
      ...(newData || {}),
        eventId: newData?.eventId || eventId,
    }),
  });
 * ```
 */
const useGqlSubscription = <TData,>(args: UseGqlSubscriptionArgs<TData>) => {
  const {
    queryKey: rawQueryKey,
    dataUpdater,
    query,
    variables,
    enabled = true,
    queryFallback = false,
    initialData,
    initialQuery,
    initialVariables,
    onError: callerOnError,
    apiFallbackFn,
  } = args;
  const queryKey = useDeepMemo(() => rawQueryKey, [rawQueryKey]);
  const subscriptionRef = useRef<ReturnType<typeof gqlSubscribe> | null>(null);
  const [subscriptionError, setSubscriptionError] = useState<any>(null);

  // Retrying mechanism variables
  const [shouldRetry, setShouldRetry] = useState(false);
  // In order to rerender the component we need a variable to detect change
  const [retryCounter, setRetryCounter] = useState(0);

  const queryClient = useQueryClient();

  // use query fallback if no subscription or subscription error
  const shouldUseQueryFallback =
    enabled &&
    queryFallback &&
    (!subscriptionRef.current || subscriptionError !== null);

  const onData = useLatestCallback((data: TData) => {
    queryClient.setQueryData<TData>(
      queryKey,
      (prevData) => dataUpdater?.(prevData, data) ?? data,
    );
    setSubscriptionError(null);
  });

  const [, cancel, startRetry] = useTimeoutFn(() => {
    if (shouldRetry && retryCounter < MAX_RETRIES) {
      setRetryCounter(retryCounter + 1);
    }
  }, RETRY_TIMEOUT_MS);

  useEffect(() => {
    if (retryCounter >= MAX_RETRIES) {
      logGqlError(
        `[useGqlSubscription]: max number of retries exceeded for ${queryKey} subscription`,
      );
      cancel();
    }
  }, [cancel, queryKey, retryCounter]);

  const onError = useLatestCallback((error: any) => {
    // Start retrying
    setShouldRetry(true);
    // Trigger the counter change what will make the subscribe function rerender
    startRetry();

    // if subscription fails use query fallback
    setSubscriptionError(error);

    logGqlError(
      `Received GraphQL Subscription error for ${queryKey} subscription`,
      error,
    );

    callerOnError?.(error);
  });

  const queryData = useGqlQuery<TData>({
    queryKey,
    query: initialQuery || "",
    variables: initialVariables ?? variables,
    enabled: shouldUseQueryFallback,
    polling: shouldUseQueryFallback,
    staleTime: Infinity,
    retry: false,
    initialData,
    dataUpdater,
    apiFallbackFn,
  });

  const onStart = useLatestCallback(() => {
    setShouldRetry(false);
    setSubscriptionError(null);
    // TODO: Improve this to only fetch if we guarantee a successful connection
    queryData.refetch();
  });

  useDeepCompareEffect(() => {
    if (!enabled) {
      return;
    }

    let subscription = subscriptionRef.current;

    // unsubscribe from existing query before creating a new subscription
    subscription?.unsubscribe();

    subscription = gqlSubscribe({
      query,
      variables,
      onData,
      onError,
      onStart,
    });

    subscriptionRef.current = subscription;

    return () => {
      subscription?.unsubscribe();
    };
  }, [enabled, query, variables, onData, onError, retryCounter]);

  return queryData;
};

export default useGqlSubscription;
