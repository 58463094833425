import React from "react";
import { Controller, type Control } from "react-hook-form";

import MuiTextField, {
  type TextFieldProps as MuiTextFieldProps,
} from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { InputLabel, type InputLabelProps } from "@src/components/inputs";
import type { ObjectType } from "@introvoke/react/types/common";
import type { ObjectKeyPaths } from "@introvoke/react/types/";
import { autocompleteMapping, extractTextFromLabel } from "@src/helpers/utils";

export type TextFieldProps<TFieldValues extends ObjectType = ObjectType> = Omit<
  MuiTextFieldProps,
  "name" | "helperText" | "error"
> & {
  control: Control<TFieldValues>;
  name: ObjectKeyPaths<TFieldValues>;
  label: InputLabelProps["label"];
  LabelProps?: Partial<InputLabelProps>;
  maxLength?: number;
  showCharacterCount?: boolean;
};

const TextField = <TFieldValues extends ObjectType = ObjectType>({
  control,
  name,
  label,
  placeholder = "Enter your answer",
  showCharacterCount,
  maxLength,
  LabelProps = {},
  ...textFieldProps
}: TextFieldProps<TFieldValues>) => {
  const labelText = extractTextFromLabel(label);
  const autocomplete = autocompleteMapping[labelText] || "off";

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState, formState }) => (
        <InputLabel
          label={
            <>
              {label}
              {showCharacterCount && typeof maxLength === "number" && (
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{
                    fontWeight: 400,
                    alignSelf: "end",
                  }}
                >
                  {`${field.value.length}/${maxLength}`}
                </Typography>
              )}
            </>
          }
          fullWidth
          labelStyles={{
            fontWeight: 500,
            color: "base.500",
            width: "100%",
            justifyContent: "space-between",
          }}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          {...LabelProps}
          sx={{ mt: 0, ...(LabelProps?.sx || {}) }}
        >
          <MuiTextField
            size="small"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            placeholder={placeholder}
            autoComplete={autocomplete}
            disabled={formState.isLoading || formState.isSubmitting}
            {...textFieldProps}
            {...field}
            onChange={(e) => {
              const value = e.target.value.slice(
                0,
                typeof maxLength === "number" ? maxLength : undefined,
              );

              field.onChange(value);
            }}
            sx={{
              borderRadius: 4,
              legend: { display: "none" },
              fieldset: { top: 0, borderRadius: 4 },
              "& .MuiOutlinedInput-root": {
                borderRadius: 4,
              },
              "& input": {
                px: 1.5,
                py: 1,
                borderRadius: 4,
              },
            }}
          />
        </InputLabel>
      )}
    />
  );
};

export default React.memo(TextField) as typeof TextField;
