import React, {
  DependencyList,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { BoxProps, Fade } from "@mui/material";
import { sleep } from "@src/helpers/utils";
import { randomInteger } from "@src/helpers/numbers";
import LiveReactionsToolbar from "./LiveReactionsToolbar";
import { MOVE_ANIMATION_START_X } from "./common";
import { Container } from "./LiveReactions.styles";
import {
  LiveReactionMessage,
  useLiveReactions,
} from "@src/providers/EventProvider/useLiveReactions";

// Wait time between showing new reactions to avoid stacking on top of each other
const REACTIONS_SLEEP_MS = 200;
// Maximum amount of reactions to keep in the queue to avoid flooding
// which will result in delays causing reactions to show way later than they should
// 200ms * 100 reactions === 20000ms or 20 seconds
const MAX_REACTIONS_QUEUE_SIZE = 100;

interface Props extends BoxProps {
  hideToolbar?: boolean;
}

const createReactionEl = (reaction: LiveReactionMessage) => {
  // Generating a <span> element rather than an img as this will simply hide
  // the reaction if the source fails to load (transparent background when no img)
  const el = document.createElement("span");
  el.className = `sequel-live-reaction sequel-live-reaction--${reaction.reaction.toLowerCase()}`;
  el.style.animationName = `sequel-live-reaction--move-${randomInteger({
    min: 1,
    max: MOVE_ANIMATION_START_X.length,
  })}-anim`;
  let timer: NodeJS.Timeout;
  const destroy = () => {
    clearTimeout(timer);
    el.remove();
  };
  el.addEventListener("animationend", destroy);
  // set a failsafe setTimeout to kill it in the event animationend doesn't fire for some reason
  timer = setTimeout(() => destroy(), 5000);
  return el;
};

interface CreateReactionsEffectArgs {
  queueRef: MutableRefObject<LiveReactionMessage[]>;
  containerRef: MutableRefObject<HTMLDivElement | null>;
  maxReactions: number;
  reactions: LiveReactionMessage[];
}

export const createReactionsEffect = (
  args: CreateReactionsEffectArgs,
): [() => void, DependencyList] => [
  () => {
    const { queueRef, containerRef, maxReactions, reactions } = args;
    queueRef.current = [...queueRef.current, ...reactions];

    if (queueRef.current.length > maxReactions) {
      // Take the last max reactions, so if we have a length of 101, we get an
      // array which is the last 100 items
      queueRef.current = queueRef.current.slice(-maxReactions);
    }

    let stop = false;
    const showReactions = async () => {
      while (!stop && queueRef.current.length > 0) {
        const reaction = queueRef.current.shift() as LiveReactionMessage;
        containerRef.current?.appendChild(createReactionEl(reaction));
        // Sleep here to add delay between reactions
        await sleep(REACTIONS_SLEEP_MS);
      }
    };

    showReactions();
    return () => {
      stop = true;
    };
  },
  Object.values(args),
];

function LiveReactions({ hideToolbar, ...props }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const queueRef = useRef<LiveReactionMessage[]>([]);
  const [reactions, setReactions] = useState<LiveReactionMessage[]>([]);

  const onLiveReactions = useCallback((newReactions: LiveReactionMessage[]) => {
    setReactions(newReactions);
  }, []);

  const { enabled, liveReactions, publishLiveReaction } = useLiveReactions({
    onLiveReactions,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    ...createReactionsEffect({
      queueRef,
      containerRef,
      maxReactions: MAX_REACTIONS_QUEUE_SIZE,
      reactions,
    }),
  );

  if (!enabled) return null;

  return (
    <Container
      ref={containerRef}
      data-testid="live-reactions-container"
      {...props}
    >
      {/* Timeout same as VideoJS: https://github.com/videojs/video.js/blob/4238f5c1d88890547153e7e1de7bd0d1d8e0b236/src/css/components/menu/_menu-inline.scss#L2 */}
      <Fade in={!hideToolbar} timeout={1000 * 0.4}>
        <span>
          <LiveReactionsToolbar
            sx={{ zIndex: 1 }}
            liveReactions={liveReactions}
            onPublishReaction={publishLiveReaction}
          />
        </span>
      </Fade>
    </Container>
  );
}

export default React.memo(LiveReactions);
