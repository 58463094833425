import { GraphQlClient } from "@src/graphql/SocketClient/GraphQlClient";
import { SocketClient } from "@src/graphql/SocketClient/SocketClient";
import { isNetworkingHub } from "@src/helpers/utils";
import { useMemo, useRef } from "react";
import SocketIOClient from "socket.io-client";

interface UseCreateClientArgs {
  isV2ChatServerEnabled?: boolean;
  onSessionStarted: (sessionId: string) => void;
  onDisconnect: () => void;
}

const createClientMemo = (
  args: UseCreateClientArgs & {
    prevClientRef: React.MutableRefObject<SocketClient | null>;
  },
): [() => SocketClient, React.DependencyList] => {
  return [
    () => {
      const {
        isV2ChatServerEnabled,
        onSessionStarted,
        onDisconnect,
        prevClientRef,
      } = args;

      const isHub = isNetworkingHub();

      const newClient = isV2ChatServerEnabled
        ? new GraphQlClient({
            retry: true,
            autoConnect: true,
            presence: isHub,
            onSessionStarted,
            onDisconnect,
            debug: false,
          })
        : (SocketIOClient(
            process.env.REACT_APP_SOCKET_URL || "https://chat.introvoke.net",
            {
              reconnection: true,
              transports: ["websocket", "polling"],
              reconnectionAttempts: Infinity,
              reconnectionDelay: 500,
            },
          ) as SocketClient);

      // disconnect existing client if any
      const prevClient = prevClientRef.current;
      // this is disconnecting but not closing the websocket
      // it may be causing a problem where socket.io no longer works because it has too many open websockets
      // TODO we need a disconnect from both our chat-socket-servers
      prevClient?.close();
      prevClient?.removeAllListeners();
      prevClientRef.current = newClient;

      return newClient;
    },
    Object.values(args),
  ];
};

/**
 * Creates a new socket client ONLY when the feature flag is toggled,
 * otherwise the same client is reused for the duration of the session,
 * even when switching between event and networkingHub
 */
export const useCreateClient = (args: UseCreateClientArgs) => {
  const prevClientRef = useRef<SocketClient | null>(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(...createClientMemo({ ...args, prevClientRef }));
};

export const __testable__ = {
  createClientMemo,
};
