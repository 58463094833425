import { useEffect } from "react";
import { ClientEffectArgs } from "../common";

interface UseClientConnectArgs extends ClientEffectArgs {
  startSession: () => Promise<string | null | undefined>;
}

/**
 * This hook should only be called once in the `SocketClientProvider`.
 *
 * It attaches listeners  to run when the chat v1 client is connected. It ignores chat v2
 * since the v2 code is handled by `useClientStartSession`.
 */
export const useClientConnect = (args: UseClientConnectArgs) => {
  const { client, isV2ChatServerEnabled, startSession } = args;

  useEffect(() => {
    const handleConnect = () => {
      if (!isV2ChatServerEnabled) {
        startSession();
      }
    };

    client.on("connect", handleConnect);

    return () => {
      client.off("connect", handleConnect);
    };
  }, [client, isV2ChatServerEnabled, startSession]);
};
