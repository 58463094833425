import React, { memo } from "react";

import { Box, Typography } from "@mui/material";

export interface TabTitleProps {
  children: React.ReactNode;
  compact?: boolean;
}

export const TabTitle = memo(({ children, compact }: TabTitleProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        backgroundColor: theme.palette.common.white,
        padding: compact ? 1 : 2,
      })}
    >
      {typeof children === "string" ? (
        <Typography variant="h4" color="secondary" noWrap>
          {children}
        </Typography>
      ) : (
        children
      )}
    </Box>
  );
});
