import { Actions } from "@src/graphql/schemas/actions";
import { GraphQlClient } from "@src/graphql/SocketClient/GraphQlClient";
import { HEARTBEAT_MS } from "@src/graphql/SocketClient/common";
import { useQuery } from "react-query";
import { ClientEffectArgs } from "../common";
import { emitAsPromise } from "../emitAsPromise";

/**
 * Hook that emits a `heartbeat` event to the GQL socket server every `HEARTBEAT_MS`
 * to keep the user's session alive
 */
export const useClientHeartbeat = ({
  client,
  isV2ChatServerEnabled,
}: ClientEffectArgs) => {
  const query = useQuery(
    ["GraphQlClient", "heartbeat"],
    async () => {
      // don't emit when no sessionId yet
      if (
        client.connected &&
        client instanceof GraphQlClient &&
        (client as GraphQlClient)?.getSessionId()
      ) {
        return await emitAsPromise(client, Actions.HEARTBEAT);
      }
    },
    {
      enabled: !!isV2ChatServerEnabled,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchInterval: HEARTBEAT_MS,
      refetchIntervalInBackground: true,
    },
  );

  return query;
};
