import { useCallback } from "react";
import { useQuery, useQueryClient } from "react-query";
import { atom, useSetRecoilState, useRecoilValue } from "recoil";
import { fetchHereNow } from "./helpers";

import type { PubNubContextValues, PubnubState } from "./PubNubProvider";

import { usePresenceQueryKey } from "./usePresenceQueryKey";

export const participantCountAtom = atom({
  key: "participantCount",
  default: 1,
});

export const useParticipantCount = () => useRecoilValue(participantCountAtom);
export const useSetParticipantCount = () =>
  useSetRecoilState(participantCountAtom);

interface PubNubHereNowProps
  extends Pick<
    PubNubContextValues,
    "pubnub" | "topicId" | "userInfoRef" | "presentUserIdsRef"
  > {
  presenceChannelId: string | null;
}

export const usePubNubHereNow = ({
  presenceChannelId,
  pubnub,
  topicId,
  userInfoRef,
  presentUserIdsRef,
}: PubNubHereNowProps) => {
  const cacheClient = useQueryClient();

  const queryKey = usePresenceQueryKey(topicId);

  const getFetchHereNow = useCallback(
    () =>
      fetchHereNow({
        pubnub,
        presenceChannelId,
        fallbackData: cacheClient.getQueryData<PubnubState[]>(queryKey) || [],
      }),
    [cacheClient, presenceChannelId, pubnub, queryKey],
  );

  const onSuccess = useCallback(
    (participants: PubnubState[]) => {
      presentUserIdsRef.current.clear();
      participants.forEach((userInfo) => {
        // Assign user info
        userInfoRef.current[userInfo.userId] = userInfo;
        // Add to presence list
        presentUserIdsRef.current.add(userInfo.userId);
      });
    },
    [userInfoRef, presentUserIdsRef],
  );

  const query = useQuery<PubnubState[]>(queryKey, getFetchHereNow, {
    enabled: !!presenceChannelId?.length,
    onSuccess,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  return { query };
};
