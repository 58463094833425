import axios from "axios";

import { Chat } from "../contracts/chat/Chat";
import { ChatUserProfile } from "../contracts/chat/ChatUserProfile";
import { ApiConfig } from "./api-config";

export const CreateChat = async (
  userId: string,
  invitedUserId: string,
): Promise<{
  chat: Chat;
  profiles: ChatUserProfile[];
}> => {
  const configURL = `${ApiConfig.GetPrefix()}/api/chats`;
  const response = await axios.post(
    configURL,
    { userId: invitedUserId },
    {
      ...ApiConfig.GetHeaderWithToken({
        "X-User-Id": userId,
      }),
    },
  );
  return response.data;
};

export const GetChats = async (userId: string): Promise<Chat[]> => {
  const configURL = `${ApiConfig.GetPrefix()}/api/chats`;
  const response = await axios.get(configURL, {
    ...ApiConfig.GetHeaderWithToken({
      "X-User-Id": userId,
    }),
  });
  return response.data;
};

export const GetUserChatProfiles = async (
  userIds: string[],
): Promise<(ChatUserProfile | null)[]> => {
  const configURL = `${ApiConfig.GetPrefix()}/api/chats/profiles`;
  const response = await axios.post(configURL, userIds, {
    ...ApiConfig.GetHeaderWithToken(),
  });
  return response.data;
};
