import React from "react";
import Dropzone from "react-dropzone";
import request from "superagent";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";

import { BaseButton } from "../buttons/BaseButton";
import UserAvatar from "../UserAvatar";

const CLOUDINARY_UPLOAD_PRESET = "7ab83715-e714-471d-b1a6-e14ccc6f1109";
const CLOUDINARY_UPLOAD_URL =
  "https://api.cloudinary.com/v1_1/introvoke/image/upload";

interface Props {
  currentImage?: string;
  iconButtonOnly?: boolean;
  imageUploaded: (imageUrl: string) => void;
  uploading?: (finished: boolean) => void;
  username?: string;
}

const PictureUpload = (props: Props) => {
  const { currentImage, iconButtonOnly } = props;
  const [uploading, setUploading] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);

  const handleImageUpload = (file: any) => {
    setDisabled(true);
    props.uploading?.(false);
    setUploading(true);

    const upload = request
      .post(CLOUDINARY_UPLOAD_URL)
      .field("upload_preset", CLOUDINARY_UPLOAD_PRESET)
      .field("file", file);

    upload.end((err: any, response: any) => {
      setDisabled(false);
      props.uploading?.(true);
      setUploading(true);

      if (err) {
        console.error(err);
        setUploading(false);
      }

      if (response.body.secure_url !== "") {
        setUploading(false);
        props.imageUploaded(response.body.secure_url);
      }
    });
  };

  const onImageDrop = (files: any) => {
    handleImageUpload(files[0]);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <UserAvatar
        userId={undefined}
        image={currentImage}
        username={props.username ?? ""}
        isAnonymous={false}
        sx={{
          width: "100px",
          height: "100px",
          backgroundColor: "transparent",
          fontSize: "4rem",
        }}
      />

      <Box
        sx={
          !iconButtonOnly
            ? undefined
            : {
                position: "absolute",
                right: 0,
                bottom: 0,
              }
        }
      >
        <div className="FileUpload stream-page">
          <Dropzone onDrop={onImageDrop}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input disabled={disabled} {...getInputProps()} />
                {iconButtonOnly ? (
                  <IconButton
                    aria-label="Upload Profile Image"
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
                    size="large"
                  >
                    <CameraAltOutlinedIcon htmlColor="#fff" />
                  </IconButton>
                ) : (
                  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                  <BaseButton
                    disabled={disabled}
                    variant="outlined"
                    color="primary"
                    endIcon={uploading && <CircularProgress size={20} />}
                    sx={{
                      borderRadius: 2,
                      marginTop: 1,
                    }}
                  >
                    Change Image
                  </BaseButton>
                )}
              </div>
            )}
          </Dropzone>
        </div>
      </Box>
    </Box>
  );
};

export default React.memo(PictureUpload);
