import { ChannelConfig, usePubNubState } from "../pubnub/PubNubProvider";
import { Announcement, AnnouncementMessage } from "./ChatProvider";
import { useLatestCallback } from "../../hooks/useLatestCallback";
import { uuidv4 } from "../../models/fakedata";
import { UserRole } from "../../contracts/user/user";
import { TIME_SINCE_LAST_ANNOUNCEMENT } from "./timeSinceLastAnnouncement";
import { usePubNubHistory } from "./usePubNubHistory";
import { deleteNestedChannelMessage } from "./deleteNestedChannelMessage";
import { ANNOUNCEMENTS_CHANNEL_SUFFIX } from "./helpers";
import { useUser } from "../UserProvider";

type AnnouncementChannel = {
  announcementChannelId: string | null;
  announcementMessages: AnnouncementMessage[];
  sendAnnouncement: (announcement: Announcement) => void;
  deleteAnnouncement: (announcement: AnnouncementMessage) => void;
};

/**
 * Configures a channel to send announcements.
 * Announcements are used to send notifications to users who have subscribed to the channel.
 *
 * The channel must have `hasAnnouncements` set to true in its `ChannelConfig`
 */
export const useAnnouncementChannel = (
  channelConfig: ChannelConfig | null,
): AnnouncementChannel => {
  const [pubnub] = usePubNubState();
  const user = useUser();

  const announcementChannelId = channelConfig?.hasAnnouncements
    ? `${channelConfig?.channel}${ANNOUNCEMENTS_CHANNEL_SUFFIX}`
    : null;

  const announcementMessages = usePubNubHistory<AnnouncementMessage>({
    channelId: announcementChannelId,
    mapMessageData: (message) => ({
      message: message.message,
      timestamp: message.announcementTimestamp,
      type: message.announcementType,
    }),
    // Do not pull announcement history for unregistered users
    enabled: user.userRole < UserRole.Unregistered,
  });

  /**
   * Sends an announcement object to other PubNub users in the announcements channel
   */
  const sendAnnouncement = useLatestCallback((announcement: Announcement) => {
    if (!announcementChannelId) {
      return;
    }

    const announcementMessage = {
      message: {
        name: announcement.title,
        comment: announcement.subtitle,
        timestamp: new Date().toISOString(),
        uid: uuidv4(),
        userUid: user.uid,
      },
    };

    pubnub.publish({
      channel: announcementChannelId,
      message: {
        id: announcement.id,
        to: announcement.to,
        type: "announcement",
        message: announcementMessage,
        announcementType: announcement.type,
        announcementTimestamp: new Date().toISOString(),
        // default to 60 seconds between same announcements to avoid spamming
        timeSinceLast:
          announcement.timeSinceLast ?? TIME_SINCE_LAST_ANNOUNCEMENT,
        hideSelf: announcement.hideSelf ?? true,
      },
    });
  });

  /**
   * Permanently deletes an announcement from the announcement channel
   */
  const deleteAnnouncement = useLatestCallback((message: AnnouncementMessage) =>
    deleteNestedChannelMessage({
      pubnub,
      channelId: announcementChannelId,
      message,
      options: {
        error: "Error deleting announcement message",
      },
    }),
  );

  return {
    announcementChannelId,
    announcementMessages,
    sendAnnouncement,
    deleteAnnouncement,
  };
};
