import { emitAsPromise } from "@src/components/Presence/emitAsPromise";
import { useIntrovokePresenceContext } from "@src/components/Presence/IntrovokePresence";
import { Actions } from "@src/graphql/schemas/actions";
import { useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { QuestionApprovedStatus } from "../types";
import { QuestionActionHookOptions, UserQuestion } from "../types";
import { createQuestionsQueryKey, sortQuestions } from "../helpers";
import { User } from "@src/contracts/user/user";

export interface UseListEventQuestionOptions extends QuestionActionHookOptions {
  isOwner: (question: UserQuestion) => boolean;
  user: User; // userId is required for listing questions
}

export const useListEventQuestions = ({
  client,
  eventId,
  circleId,
  user,
  isOwner,
}: UseListEventQuestionOptions) => {
  const queryKey = createQuestionsQueryKey(eventId, circleId);
  const queryClient = useQueryClient();
  // use is ready to only wait for event and user to be set and avoid refetches when jumping between circles
  // circleId is already sent when listing polls to always get the questions for the current circle and avoid waits
  const { isReady } = useIntrovokePresenceContext();

  /**
   * Mutation to retrieve the existing questions from the client server (mostly to keep the same types)
   */
  const mutation = useMutation(
    () =>
      emitAsPromise<UserQuestion[]>(client, Actions.LIST_EVENT_QUESTIONS, {
        eventId,
        userId: user?.originalId, // socket server user originalId
        circleId: circleId || null,
      }),
    {
      onMutate: () => {
        // set to null to manually indicate loading state since isFetching is not updating properly
        queryClient.setQueryData(queryKey, null);
      },
      onError: () => {
        toast.error("There was an error loading the questions");
      },
    },
  );

  /**
   * Query that uses the mutation to retrieve the existing questions from the client server
   */
  const query = useQuery<UserQuestion[] | null>(
    queryKey,
    async () => {
      const questions = await mutation.mutateAsync();

      if (!questions) return [];

      // filter out questions and fill in necessary data
      const mappedQuestions = questions.reduce((questions, question) => {
        // filter out rejected questions
        if (question.approved === QuestionApprovedStatus.REJECTED)
          return questions;

        // for each question check if the user is the owner
        const questionWithOwner = {
          ...question,
          isOwner: isOwner(question),
        };

        return [...questions, questionWithOwner];
      }, [] as UserQuestion[]);

      // return sorted questions
      return sortQuestions(mappedQuestions);
    },
    {
      // remove env check to enable questions
      enabled: Boolean(eventId) && isReady,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  return useMemo(
    () => ({
      query,
      mutation,
    }),
    [query, mutation],
  );
};
