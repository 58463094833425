import type { ClientCapabilityState, Company } from "../company/company";
import type { EventLocationTarget, EventType } from "../enums/event-type";
import type { HostType } from "../enums/host-type";
import type { Stream } from "../media/stream";

export enum RegistrationType {
  EVENT = "event",
  NETWORKING_HUB = "networking hub",
}
export interface Registration {
  enabled?: boolean;
  terms?: string;
  privacy?: string;
  customUrl?: string;
  customQuestions?: RegistrationQuestion[];
  generalAccessCookiesEnabled?: boolean;
  outsideOfAppEnabled?: boolean;
  isEmailInputEnabled?: boolean;
  redirectAfterRegistrationUrl?: string;
  /**
   * PROD-4369: if enabled, registrations should succeed only when registrants are not using public email domains (gmail, yahoo, etc.)
   * Should use a separate endpoint to update this field instead of the full EventUpdate endpoint
   */
  blockedEmails?: {
    allowOnlyBusinessEmails?: boolean;
  };
}

export enum RegistrationQuestionAnswerType {
  TEXT_LONG = "Long Text",
  SELECT = "Select",
  CHECKBOX = "Checkbox",
  UTM_FIELD = "UTM Field",
  HIDDEN_FIELD = "Hidden Field",
  /** @deprecated never used */
  TEXT_SHORT = "Short Text",
  /** @deprecated never used */
  NUMBER = "Number",
  /** @deprecated never used */
  MULTIPLE_ANSWER = "Multiple Answer",
}

export enum PredefinedRegistrationQuestionIds {
  COUNTRY = "pre-defined-country",
  US_STATES = "pre-defined-us-states",
  UTM_CAMPAIGN = "pre-defined-utm-campaign",
  UTM_CONTENT = "pre-defined-utm-content",
  UTM_MEDIUM = "pre-defined-utm-medium",
  UTM_SOURCE = "pre-defined-utm-source",
  UTM_TERM = "pre-defined-utm-term",
}

export const PredefinedUTMQuestionIdToFieldMap = {
  [PredefinedRegistrationQuestionIds.UTM_CAMPAIGN]: "utm_campaign",
  [PredefinedRegistrationQuestionIds.UTM_CONTENT]: "utm_content",
  [PredefinedRegistrationQuestionIds.UTM_MEDIUM]: "utm_medium",
  [PredefinedRegistrationQuestionIds.UTM_SOURCE]: "utm_source",
  [PredefinedRegistrationQuestionIds.UTM_TERM]: "utm_term",
} as const;

export const isUTMFieldQuestion = (
  question: RegistrationQuestion,
): question is RegistrationQuestion & {
  type: RegistrationQuestionAnswerType.UTM_FIELD;
  id:
    | PredefinedRegistrationQuestionIds.UTM_CAMPAIGN
    | PredefinedRegistrationQuestionIds.UTM_CONTENT
    | PredefinedRegistrationQuestionIds.UTM_MEDIUM
    | PredefinedRegistrationQuestionIds.UTM_SOURCE
    | PredefinedRegistrationQuestionIds.UTM_TERM;
} => {
  return (
    question.type === RegistrationQuestionAnswerType.UTM_FIELD &&
    (question.id === PredefinedRegistrationQuestionIds.UTM_CAMPAIGN ||
      question.id === PredefinedRegistrationQuestionIds.UTM_CONTENT ||
      question.id === PredefinedRegistrationQuestionIds.UTM_MEDIUM ||
      question.id === PredefinedRegistrationQuestionIds.UTM_SOURCE ||
      question.id === PredefinedRegistrationQuestionIds.UTM_TERM)
  );
};

export const MAX_TEXT_LONG_LENGTH = 250;

export type SelectRegistrationQuestionOption = {
  value: string;
  label: string;
};

export type RegistrationQuestion = {
  id: string | PredefinedRegistrationQuestionIds;
  question: string;
  isRequired?: boolean;
  /** @deprecated Questions are locked if they already have responses */
  isLocked?: boolean;
} & (
  | { type: RegistrationQuestionAnswerType.TEXT_LONG }
  | { type: RegistrationQuestionAnswerType.TEXT_SHORT }
  | { type: RegistrationQuestionAnswerType.NUMBER }
  | { type: RegistrationQuestionAnswerType.CHECKBOX }
  | { type: RegistrationQuestionAnswerType.UTM_FIELD }
  | { type: RegistrationQuestionAnswerType.HIDDEN_FIELD; value: string }
  | {
      type:
        | RegistrationQuestionAnswerType.SELECT
        | RegistrationQuestionAnswerType.MULTIPLE_ANSWER;
      options: SelectRegistrationQuestionOption[];
    }
);

export interface RegistrationQuestionAnswer {
  questionId: string;
  answer: any;
}

export interface RegisteredAttendeeResponse {
  uid: string;
  name: string;
  email: string;
  join_url: string;
  joinCode: string;
  authToken?: string;
  metadata?: any;
}

export interface Event {
  uid: string;
  autoSelectLatestReplay?: boolean;
  organizerUid: string;
  creatorUid: string;
  groupUid?: string;
  groupName?: string;
  name: string;
  description?: string;
  location?: string;
  picture?: string;
  thumbnail?: string;
  duration?: number;
  hashtags?: string[];
  startDate: Date;
  endDate: Date;
  timezone: string;
  isEventCancelled: boolean;
  inPersonLink?: string;
  type: EventType;
  target: EventLocationTarget;
  isVendorEvent?: boolean;
  hideParticipants?: boolean;
  isRegistrationModeEnabled: boolean;
  isAttendeeRegistrationModeEnabled: boolean;
  registration?: Registration;
  extraTab?: {
    name: string;
    channelId: string;
  };
  whitelabel?: {
    networkingStarted?: {
      image?: string;
      buttonText?: string;
    };
    hideChat?: boolean;
    showPoweredBy?: boolean;
  };

  networkingHub?: string;
  disableNetworking?: boolean;

  liveEventManagement: EventStatus;

  networkingGroups: NetworkingGroup[];

  streamInfo: {
    streamId: string;
    rtmpForceId?: string;
    virtualMeeting: string;
    streamPath?: string;
    youTubeEndpoint?: string;
    videoLoopUrl?: string;
    rtmpForcePath?: string;
    ownStack?: string;

    privateStreamInfo?: {
      publishUrl: string;
    };
  };

  eventInfo: {
    privateEventInfo?: {
      reminders: string[];
      registrations: string[];
      liveViews: string[];
      onDemandViews: string[];
      reminderUsers?: RegisteredUser[];
      registeredUsers?: RegisteredUser[];
      liveUsers?: RegisteredUser[];
      onDemandUsers?: RegisteredUser[];
    };
    hosts: Host[];
    admins: string[];
    planFeatures?: ClientCapabilityState & {
      maxHosts?: number;
      maxUsers?: number;
    };
  };

  simuliveMediaId?: string;
  simuliveUrl?: string;

  organizerProfile?: Company;
  userIsRegistered: boolean;
  userIsAlwaysPresenter?: boolean;
  totalViews?: number;
  liveViewers?: LiveViewer[];
  rawStream?: Stream;

  ivsStreamInfo?: {
    introvokeStreamKey: string;
    streamPath: string;

    privateInfo?: {
      arn: string;
      channelArn: string;
      injestEndpoint: string;
      streamKey: string;
    };
  };

  presenters?: string[];
  organizers?: string[];
  allowAnonymousAccess?: boolean;
  betaOptIn?: boolean;
  hasLiveSupport?: boolean;
  closeCaptioningSettings?: CloseCaptioningSettings;

  recurring?: {
    lastIterationEventId?: string | null;
    originalEventId: string;
  };

  vsVersion: string;
}

enum Language {
  English = "en",
}

export type TranscriptionSettings = {
  enabled: boolean;
  language?: Language;
};

export type TranslationSettings = {
  enabled: boolean;
  languages: Language[];
};

export type CloseCaptioningSettings = {
  transcription: TranscriptionSettings;
  translation?: TranslationSettings;
};

export interface NetworkingGroup {
  name: string;
  userIds: string[];
}

export interface LiveViewer {
  userid: string;
  userName: string;
  profilePicture: string;
}

export interface RegisteredUser {
  name: string;
  email: string;
}

export interface Host {
  uid: string;
  userUid?: string;

  name: string;
  company: string;
  title?: string;

  privateHost?: {
    email: string;
  };

  timestamp?: number;
  hostType: HostType;

  picture?: string;

  isApproved?: boolean;
}

export type EventStatus = {
  isEventLive: boolean;
  eventLivestreamStartTime?: string;
  eventLivestreamEndTime?: string;
  isNetworkingLive: boolean;
  isVotingOn: boolean;
  isAnonymousQnaOn?: boolean;
  isApproveQnaOn?: boolean;
  isChatReactionsOn?: boolean;
  isLiveReactionsOn?: boolean;
  networkingStart: string;
  returnToEvent: boolean;
  blockedEmails?: string[];
} & ReplayConfig;

export type ReplayConfig = {
  replayEnabled: boolean;
  replayUrl?: string | null;
  streamPlaceholderMessage?: string | null;
};

export interface CanJoinEventAsRole {
  canJoin: boolean;
  isGraceUser?: boolean;
}
export interface CanJoinEventResponse {
  host: CanJoinEventAsRole;
  presenter: CanJoinEventAsRole;
  attendee: CanJoinEventAsRole;
}
