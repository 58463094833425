import React from "react";
import invariant from "tiny-invariant";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useEvent, useHasEventEnded } from "@src/providers/EventProvider";
import { useGetTimeInfo } from "../hooks/useGetTimeInfo";
import { useIsReplayEnabled } from "@src/providers/EventStateProvider";

interface Props {
  isStreamLive?: boolean;
}

const StatusContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

const LiveBadge = styled(Typography)(({ theme: { spacing } }) => ({
  flexShrink: 0,
  fontSize: "0.7em",
  fontWeight: "bold",
  textTransform: "uppercase",
  padding: spacing(0.25),
  paddingLeft: spacing(1),
  paddingRight: spacing(1),
  borderWidth: 1,
  borderRadius: spacing(0.5),
  borderStyle: "solid",
}));

const TimeInfo = styled(Typography)(({ theme: { spacing, palette } }) => ({
  marginLeft: spacing(1),
  // Next 2 styles are for ellipsis start
  direction: "rtl",
  textAlign: "left",
  color: palette.common.black,
}));

const EventInfoCountDown = ({ isStreamLive }: Props) => {
  const { data: event } = useEvent();
  const timeInfo = useGetTimeInfo(!!isStreamLive);
  const hasEventEnded = useHasEventEnded();
  const replayEnabled = useIsReplayEnabled();

  invariant(event, "Event is required");

  return (
    <Stack
      flex={1}
      padding={1}
      spacing={0.5}
      justifyContent="center"
      overflow="hidden"
    >
      <Typography
        fontSize="1.6rem"
        fontWeight="600"
        color="common.black"
        noWrap
      >
        {event.name}
      </Typography>
      <Stack
        spacing={1}
        alignItems={{ xs: "center", sm: "flex-start" }}
        direction="column"
      >
        <Stack direction="row" spacing={1}>
          <StatusContainer>
            <LiveBadge
              sx={({ palette }) => ({
                borderColor: isStreamLive
                  ? palette.red?.[400]
                  : palette.common.black,
                color: isStreamLive
                  ? palette.common.white
                  : palette.common.black,
                backgroundColor: isStreamLive
                  ? palette.red?.[400]
                  : "transparent",
              })}
            >
              {isStreamLive ? "Live" : "Not Live"}
            </LiveBadge>
            {hasEventEnded && replayEnabled ? null : (
              <TimeInfo noWrap>{timeInfo}</TimeInfo>
            )}
          </StatusContainer>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EventInfoCountDown;
