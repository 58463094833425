/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { BaseButton, BaseButtonProps } from "../../buttons/BaseButton";

export const StageHeaderBaseButton = (props: BaseButtonProps) => {
  return (
    <BaseButton
      color="secondary"
      css={css`
        overflow: hidden;
        text-overflow: clip;
        display: flex;
        white-space: nowrap;
      `}
      {...props}
    />
  );
};
