import React, { memo, useState } from "react";

import { styled, Theme, useMediaQuery } from "@mui/material";

import { User } from "../../contracts/user/user";
import QuestionListCollection from "./QuestionListCollection";
import { UserQuestion } from "../../providers/qAndA/QandAProvider";
import useIsShortScreen from "../../hooks/useIsShortScreen";
import QuestionListItem from "./QuestionListItem";

import { MenuItemType } from "../Menu/Menu";
import { ParticipantNameOption } from "../../contracts/enums/participant-name-options";

const Container = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  minHeight: 0,
  alignItems: "stretch",
}));

interface QandATabContentProps {
  user: User;
  answeredQuestions?: UserQuestion[];
  unansweredQuestions?: UserQuestion[];
  createMenuItems: (question: UserQuestion) => MenuItemType[];
  handleVoteEventQuestion: (id: string) => void;
  showParticipantNames?: ParticipantNameOption;
}

export const QandATabContent = memo(
  ({
    user,
    answeredQuestions = [],
    unansweredQuestions = [],
    showParticipantNames,
    createMenuItems,
    handleVoteEventQuestion,
  }: QandATabContentProps) => {
    const isShortScreen = useIsShortScreen();
    // We break at md to a mobile style view due to limited space for stage + chat
    const isMobile = useMediaQuery<Theme>((theme) =>
      theme.breakpoints.down("md"),
    );

    const [showAnswered, setShowAnswered] = useState<boolean>(false);
    const toggleShowAnswered = () => setShowAnswered(!showAnswered);

    const isLimitedView = isMobile || isShortScreen;

    return (
      <Container>
        <QuestionListCollection
          title="Unanswered"
          enableBackground={false}
          expanded={isLimitedView ? !showAnswered : true}
          onHeaderClick={isLimitedView ? toggleShowAnswered : undefined}
          questions={unansweredQuestions}
          renderItem={(question) => (
            <QuestionListItem
              key={question.id}
              id={question.id}
              user={user}
              question={question}
              showParticipantNames={showParticipantNames}
              menuItems={createMenuItems(question)}
              onVote={handleVoteEventQuestion.bind(null, question.id)}
              votes={question.votes}
            />
          )}
        />

        <QuestionListCollection
          title="Answered"
          enableBackground={true}
          expanded={showAnswered}
          onHeaderClick={toggleShowAnswered}
          questions={answeredQuestions}
          renderItem={(question) => (
            <QuestionListItem
              key={question.id}
              id={question.id}
              user={user}
              question={question}
              showParticipantNames={showParticipantNames}
              menuItems={createMenuItems(question)}
              votes={question.votes}
            />
          )}
        />
      </Container>
    );
  },
);
