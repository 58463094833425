import React from "react";
import { Container, ChildrenWrapper, Text } from "./EmptyPollsList.styles";

export const EmptyPollsList: React.FC = ({ children }) => {
  return (
    <Container>
      {children && <ChildrenWrapper>{children}</ChildrenWrapper>}
      <Text>No Active Polls</Text>
    </Container>
  );
};
