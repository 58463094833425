import { SocketUserMeta } from "@src/components/Presence/IntrovokePresence";
import { User } from "@src/contracts/user/user";
import { SocketClient } from "@src/graphql/SocketClient/SocketClient";

export enum PollStatus {
  PENDING = "pending",
  ACTIVE = "active",
  CLOSED = "closed",
  ARCHIVED = "archived",
}

export enum PollResultsType {
  VOTES = "votes",
  PERCENT = "percent",
}

export interface Poll {
  /**
   * Not quite sure what this is
   */
  pending?: boolean;
  id: string;
  eventId: string;
  circleId?: string;
  createdAt: number;
  updatedAt: number;
  hidden?: boolean;
  owner: SocketUserMeta;
  name: string;
  options: PollOption[];
  status: PollStatus;
  resultsPublished: boolean;
  resultsType: PollResultsType;
}

export interface PollOption {
  id: string;
  name: string;
  votes: number;
  selected: boolean;
}

/**
 * The poll object that is displayed to the user. It extends the Poll object but
 * contains additional properties which are not stored on the Poll object itself.
 */
export interface UserPoll extends Poll {
  /**
   * Wether the poll is currently waiting for an update to be processed by the server
   */
  isPending?: boolean;
}

export interface PollActionHookOptions {
  client: SocketClient;
  eventId: string | null;
  circleId?: string;
  isApproveQnaEnabled?: boolean;
  user?: User;
}

export type PollActionRollbackFunction = () => Poll[] | UserPoll[] | undefined;

export type CreateEventPollInput = Pick<Poll, "name" | "resultsType"> &
  Pick<Partial<Poll>, "id" | "status"> & {
    name: string;
    options: {
      name: string;
    }[];
    delayResults: boolean;
  };

export interface VoteEventPollOptionInput {
  poll: string;
  option: string;
}

export interface SelectEventPollInput {
  poll: string;
}

export interface UpdateEventPollStatusInput {
  poll: string;
  status: PollStatus;
}

export interface PublishEventPollResultsInput {
  poll: string;
  publish?: boolean;
}
