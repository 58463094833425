import React, { ReactNode } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useField } from "formik";

type Props = {
  disabledOptionLabel: string;
  enabledContent: ReactNode;
  enabledOptionLabel: string;
  fieldId: string;
};

export default function PasscodeToggleButtons({
  disabledOptionLabel,
  enabledContent,
  enabledOptionLabel,
  fieldId,
}: Props) {
  const [field, , helpers] = useField<boolean>(fieldId);

  return (
    <Box sx={{ my: 2 }}>
      <ToggleButtonGroup
        aria-label={enabledOptionLabel}
        sx={{ mb: 2 }}
        exclusive
        onChange={(
          _: React.MouseEvent<HTMLElement>,
          newValue: boolean | null,
        ) => {
          helpers.setValue(!!newValue);
        }}
        value={field.value}
      >
        <ToggleButton
          data-testid="passcode-off-btn"
          value={false}
          aria-label={disabledOptionLabel}
        >
          <Typography color="secondary" noWrap>
            {disabledOptionLabel}
          </Typography>
        </ToggleButton>
        <ToggleButton
          data-testid="passcode-on-btn"
          value={true}
          aria-label={enabledOptionLabel}
        >
          <Typography color="secondary" noWrap>
            {enabledOptionLabel}
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>

      {field.value ? enabledContent : null}
    </Box>
  );
}
