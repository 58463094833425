/** @jsxImportSource @emotion/react */
import React, { memo, MemoExoticComponent, ReactElement } from "react";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";

import { ClassNames } from "@emotion/react";
import { useTheme } from "@mui/material/styles";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Popover from "@mui/material/Popover";
import StopCircleOutlined from "@mui/icons-material/StopCircleOutlined";

import { BaseButtonProps } from "../../../buttons/BaseButton";
import OutlineButton from "@src/components/buttons/OutlineButton";
import ContainedButton from "@src/components/buttons/ContainedButton";

type EndNetworkingButtonProps = {
  buttonLocationId: string;
  Component?: MemoExoticComponent<
    (props: BaseButtonProps) => ReactElement<any, any> | null
  >;
  loading: boolean;
  disabled: boolean;
  onConfirm: (returnToEvent: boolean) => void;
  returnToEventId?: string;
};

const EndNetworkingButton = ({
  Component,
  loading,
  disabled,
  ...props
}: EndNetworkingButtonProps) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: `end-networking-menu-${props.buttonLocationId}`,
  });
  const theme = useTheme();

  const ButtonTag = Component ? (
    <Component {...bindTrigger(popupState)} />
  ) : (
    <OutlineButton
      loading={loading}
      disabled={disabled}
      textColor={theme.palette.danger.main}
      {...bindTrigger(popupState)}
      startIcon={<StopCircleOutlined />}
    >
      Stop Networking
    </OutlineButton>
  );

  return (
    <ClassNames>
      {({ css }) => (
        <>
          {ButtonTag}

          <Popover
            classes={{
              root: css`
                margin-top: 8px;
              `,
              paper: css`
                padding: 8px;
              `,
            }}
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <CardContent
              sx={{
                fontWeight: 700,
              }}
            >
              Move all attendees back to virtual stage?
            </CardContent>
            <CardActions
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <OutlineButton
                backgroundColor={theme.palette.common.white}
                textColor={theme.palette.common.black}
                onClick={popupState.close}
              >
                Cancel
              </OutlineButton>

              <ContainedButton
                onClick={() => {
                  popupState.close();
                  props.onConfirm?.(false);
                }}
                backgroundColor={theme.palette.danger.main}
                size="medium"
              >
                Stop Networking
              </ContainedButton>

              {props.returnToEventId?.length && (
                <ContainedButton
                  onClick={() => {
                    popupState.close();
                    props.onConfirm(true);
                  }}
                  backgroundColor={theme.palette.danger.main}
                  size="medium"
                >
                  Stop Networking and Move Attendees to Virtual Stage
                </ContainedButton>
              )}
            </CardActions>
          </Popover>
        </>
      )}
    </ClassNames>
  );
};

export default memo(EndNetworkingButton);
