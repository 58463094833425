import {
  GeneralAccessCookie,
  GENERAL_ACCESS_COOKIE,
  getCookie,
  getUserRoleCookie,
} from "@src/helpers/cookie";
import { useQuery } from "react-query";
import { QueryKeys } from "@src/api/QueryKeys";
import { Api } from "@src/api/api";
import { useAuthParams } from "@src/providers/QueryParamsProvider";

interface GetAuthKeyOptions {
  eventId?: string;
  networkingHubId?: string;
  hasParamsUserInfo: boolean;
}

/**
 * for usage by the UserProvider only
 * this will return the relevant authKey found in either cookies or query params
 * @returns jwtToken authKey for authenticating api calls
 */
export const useGetAuthKey = ({
  eventId,
  networkingHubId,
  hasParamsUserInfo,
}: GetAuthKeyOptions) => {
  const { joinCode, authKey } = useAuthParams();
  const eventOrNetworkingHubId = eventId || networkingHubId;

  // get event authKey from cookies if any
  const { authKey: cookieAuthKey } = getUserRoleCookie(eventOrNetworkingHubId);

  // get generalAccessKey from cookies if any
  const { authKey: generalAccessAuthKey, accessId: generalAccessId } =
    getCookie<GeneralAccessCookie>(GENERAL_ACCESS_COOKIE, {});

  // check if generalAccessKey can be used to access this event
  const { data: hasGeneralAccess } = useQuery(
    QueryKeys.hasGeneralAccess(eventId),
    async () => {
      const result = await Api.EventApi.HasGeneralAccess(
        eventId as string,
        generalAccessId,
      );
      return result.hasGeneralAccess;
    },
    {
      enabled: !!(eventId && generalAccessAuthKey && generalAccessId),
      retry: 1,
      refetchOnWindowFocus: false,
    },
  );

  // if no authKey, user details or joinCode in params
  if (!joinCode && !authKey && !hasParamsUserInfo) {
    // if access codes are enabled and the user has a generalAccessCookie
    if (
      hasGeneralAccess &&
      generalAccessAuthKey &&
      generalAccessAuthKey?.length > 20
    ) {
      return generalAccessAuthKey;
    }

    // if cookie exists and access codes are disabled
    else if (cookieAuthKey && cookieAuthKey?.length > 20) {
      return cookieAuthKey;
    }
  }

  return authKey;
};
