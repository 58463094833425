import { useNetworkingHub } from "../../providers/NetworkingHubProvider";
import useCommonController, { CommonController } from "./useCommonController";
import { UserRole } from "../../contracts/user/user";

interface NetworkingHubController extends CommonController {
  /**
   * Flag that indicates if the hub has live support
   */
  showLiveSupport: boolean;
}

const useHubController = (): NetworkingHubController => {
  const commonController = useCommonController();
  const { user } = commonController;

  const { data: networkingHub } = useNetworkingHub();

  const hasLiveSupport =
    networkingHub?.eventInfo.planFeatures?.hasLiveSupport ?? false;
  const showLiveSupport =
    user.userRole === UserRole.Organizer && hasLiveSupport;

  return {
    ...commonController,
    showLiveSupport,
  };
};

export default useHubController;
