import { DocumentNode } from "graphql";
import { API as AmplifyApi, graphqlOperation } from "aws-amplify";
import { GraphQLSubscription } from "@aws-amplify/api";
import { getToken } from "./auth";
import { getFirstValue } from "./helpers";

export type Subscription = ReturnType<typeof subscribe>;

export interface SubscribeArgs {
  /**
   * The GQL Subscription query
   */
  query: string | DocumentNode;
  /**
   * The GQL Subscription variables
   */
  variables?: Record<string, any>;
  /**
   * The callback when data is received with the subscription
   */
  onData: (d: any) => void;
  /**
   * The callback when errors occur with the subscription
   */
  onError?: (e: any) => void;
  /**
   * The callback when connection is established
   */
  onStart?: () => void;
}
/**
 * Method that creates a Graph QL subscription
 */
export const subscribe = <T>(args: SubscribeArgs) => {
  const { query, variables, onData, onError, onStart } = args;
  const token = getToken();

  const observable = AmplifyApi.graphql<GraphQLSubscription<T>>(
    graphqlOperation(query, variables, token as string),
  );
  const subscription = observable.subscribe({
    // NOTE: The type says there is a `start` callback but it doesn't exist in the zen-observable source code
    next: ({ value: update }) => {
      const data = getFirstValue<T>(update.data as unknown as T);
      onData(data);
    },
    error: (error: any) => {
      /**
       * Error shape:
       * {
       *   provider: AWSAppSyncRealTimeProvider,
       *   error: {
       *     errors: Array<{
       *       message: string
       *     }>
       *   }
       * }
       */
      onError?.(
        typeof error === "object" && "error" in error ? error.error : error,
      );
    },
  });

  if (!subscription.closed) {
    onStart?.();
  }

  return subscription;
};
