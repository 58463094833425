import { useCallback } from "react";
import invariant from "tiny-invariant";

import { Api } from "../../../api/api";
import { Util } from "../../../helpers/utils";
import { VideoType } from "../../../models/eventType";
import { useEvent } from "../../../providers/EventProvider";
import { useUser } from "../../../providers/UserProvider";
import {
  FeatureFlag,
  useFeatureFlag,
} from "@src/providers/FeatureFlagsProvider";

export const useUpdateLengthOfWatch = (videoType: VideoType) => {
  const user = useUser();
  const { data: event } = useEvent();
  invariant(event, "");
  const useNewTelemetryEndpoint = useFeatureFlag(
    FeatureFlag.ENABLE_NEW_TELEMETRY_ENDPOINT,
  );
  return useCallback(() => {
    // TODO hook Api.AnalyticsApi.UpdateLengthOfWatch
    Api.AnalyticsApi.UpdateLengthOfWatch(
      event.uid,
      event.streamInfo.streamId,
      user.sessionId,
      Util.GetUTCDate(),
      videoType,
      user.uid, // TODO this was fakeUserId, check if it is still same and expected value
      user.name,
      user.userRole,
      user.profilePicture,
      user.location,
      undefined,
      undefined,
      useNewTelemetryEndpoint,
    );
  }, [
    event.uid,
    event.streamInfo.streamId,
    user.sessionId,
    user.uid,
    user.name,
    user.userRole,
    user.profilePicture,
    user.location,
    videoType,
    useNewTelemetryEndpoint,
  ]);
};
