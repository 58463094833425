import React from "react";
import { SettingsGroup } from "../../SideBar/SettingsSection";
import { NetworkingHub } from "../../../contracts/networking-hub/NetworkingHub";
import { ClearChatSettings } from "./ClearChatSettings";
import { User } from "../../../contracts/user/user";

export interface NetworkingHubSettingsProps {
  user: User;
  networkingHub: NetworkingHub;
  eventId?: string;
}
export const NetworkingHubSettings = React.memo(
  ({ user, networkingHub, eventId }: NetworkingHubSettingsProps) => {
    return (
      <SettingsGroup title="Chat+ Settings">
        <ClearChatSettings
          user={user}
          circles={networkingHub.networkingHubRooms || []}
          networkingHubId={networkingHub.uid}
          eventId={eventId}
        />
      </SettingsGroup>
    );
  },
);
