import { useQuery, UseQueryOptions } from "react-query";
import { QueryKeys } from "@src/api/QueryKeys";
import { EventApi } from "@src/api/event-api";
import * as NetworkingHubApi from "@src/api/networking-hub-api";
import { User, UserRole } from "@src/contracts/user/user";

interface UseApiRoleArgs {
  user: User;
  eventId?: string;
  networkingHubId?: string;
  mode?: string;
}

/**
 * Query to fetch the role of the user from the API
 */
export const useApiRoleQuery = (
  { user, eventId, networkingHubId, mode }: UseApiRoleArgs,
  options: UseQueryOptions<UserRole, unknown, UserRole> = {},
) => {
  const { uid: userId, email: userEmail } = user;
  const queryKey = QueryKeys.userRole({
    userId: user.uid,
    userEmail: user.email,
    eventId,
    networkingHubId,
  });

  const query = useQuery<UserRole, unknown, UserRole>(
    queryKey,
    async () => {
      let eventUserRole = UserRole.None;
      let networkingHubUserRole = UserRole.None;

      // only update user roles from API if they are not "unregistered"
      if (eventId) {
        eventUserRole = await EventApi.GetUserRole(eventId, userEmail, userId);
      }

      if (networkingHubId) {
        networkingHubUserRole = await NetworkingHubApi.GetUserRole(
          networkingHubId,
          userEmail,
          mode || userId,
        );
      }

      return Math.min(
        eventUserRole,
        networkingHubUserRole,
        UserRole.Unregistered,
      );
      // ^?
    },
    {
      enabled: !!(
        (eventId || networkingHubId) &&
        userEmail &&
        (mode || userId)
      ),
      retry: true,
      initialData: UserRole.Unregistered,
      ...options,
    },
  );

  return query;
};
