import {
  Box,
  InputBaseProps,
  InputLabel,
  styled,
  Typography,
} from "@mui/material";
import { useField } from "formik";
import React, { CSSProperties } from "react";
import { Select, SelectItemProps } from "../inputs/Select";

export const InputLabelStyled = styled(InputLabel)(
  ({ theme: { palette } }) => ({
    color: palette.text.primary,
    "&$focused:not($error)": {
      color: palette.text.primary,
    },
    "&.MuiInputLabel-shrink": {
      transform: "translate(0, 1.5px) scale(0.8)",
      letterSpacing: 1,
    },
  }),
);

interface SelectInputLiveFeedbackProps {
  label: string;
  id: string;
  name: string;
  placeholder?: string;
  options: SelectItemProps[];
  style?: CSSProperties;
  inputProps?: InputBaseProps["inputProps"];
  testId?: string;
}
export const SelectInputLiveFeedback = ({
  label,
  testId,
  ...props
}: SelectInputLiveFeedbackProps) => {
  const [field, meta] = useField(props);
  // Show inline feedback if EITHER
  // - or, the has been visited (touched === true)
  const [didFocus, setDidFocus] = React.useState(false);
  const handleFocus = () => setDidFocus(true);
  const showFeedback = !!didFocus || meta.touched;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          mt: 1,
        }}
      >
        <InputLabelStyled
          sx={{
            display: "flex",
            textAlign: "left",
            my: 1,
            alignSelf: "start",
            fontWeight: "bold",
            textOverflow: "unset",
            whiteSpace: "unset",
          }}
          htmlFor={props.id}
        >
          {label}
        </InputLabelStyled>
        {showFeedback ? (
          <Typography
            aria-live="polite"
            id={`${props.id}-feedback`}
            color={meta.error ? "error" : "success.main"}
            sx={{
              my: 1,
            }}
          >
            {meta.error ? meta.error : "✓"}
          </Typography>
        ) : null}
      </Box>
      <Box
        sx={{
          width: "100%",
        }}
        className={` ${showFeedback ? (meta.error ? "invalid" : "valid") : ""}`}
      >
        <Select
          sx={{ width: "100%", borderRadius: "5px" }}
          title={props.name}
          label={field.name}
          onFocus={handleFocus}
          placeholder="Select..."
          {...field}
          {...props}
        />
      </Box>
    </>
  );
};
