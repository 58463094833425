import { useField } from "formik";
import React, { memo } from "react";

import PictureUpload from "../Profile/PictureUpload";

type Props = {
  fieldId: string;
  iconButtonOnly: boolean;
};

function ProfileImageUpload({ fieldId }: Props) {
  const [field, , helpers] = useField(fieldId);

  return (
    <PictureUpload
      currentImage={field.value}
      iconButtonOnly
      imageUploaded={(url) => helpers.setValue(url)}
    />
  );
}

export default memo(ProfileImageUpload);
