/** @jsxImportSource @emotion/react */

import {
  bindPopper,
  bindToggle,
  usePopupState,
} from "material-ui-popup-state/hooks";
import React, { memo, useMemo, useState } from "react";

import { css } from "@emotion/react";
import {
  Avatar,
  Box,
  ClickAwayListener,
  Divider,
  Fab,
  Paper,
  Popper,
  SvgIcon,
  Theme,
  Tooltip,
  useMediaQuery,
} from "@mui/material";

import { useLatestCallback } from "../../hooks/useLatestCallback";
import { TabTitle } from "../TabTitle";
import { CircleIcon, JoinCircleIcon, MoreIcon } from "../icons";
import { CircleUserList } from "./CircleUserList";
import { NetworkingCircleUsersDialog } from "./NetworkingCircleUsersDialog";
import { UserRole } from "../../contracts/user/user";
import { ParticipantNameOption } from "../../contracts/enums/participant-name-options";
import { useUser } from "../../providers/UserProvider";
import { transformImageUrl } from "@src/helpers/image";

enum SlotPosition {
  Top = 0,
  TopRight = 1,
  BottomRight = 2,
  Bottom = 3,
  BottomLeft = 4,
  TopLeft = 5,
}

const renderingOrder = [
  SlotPosition.Top,
  SlotPosition.TopRight,
  SlotPosition.TopLeft,
  SlotPosition.BottomLeft,
  SlotPosition.BottomRight,
];

const cache = new Map<string, { top: string; left: string }>();

const getCirclePosition = (index: number) => {
  const key = `${index}`;

  const cached = cache.get(key);
  if (cached) {
    return cached;
  }

  const count = 6;
  const initialRotation = -90;
  var angle = (2 * Math.PI) / count;
  var initialRot = (initialRotation * Math.PI) / 180;

  const top = 50 * Math.sin(index * angle + initialRot) + 50;
  const left = 50 * Math.cos(index * angle + initialRot) + 50;

  const result = {
    top: `${top}%`,
    left: `${left}%`,
  };

  cache.set(key, result);
  return result;
};

export interface NetworkingCircleSlotsProps {
  onJoin?: () => void;
  profiles: {
    userId: string;
    avatar: string;
    username: string;
    email: string;
    userRole?: number;
  }[];
  maxCount: number;
  roomIndex: string;
  logo?: string;
  active?: boolean;
  showParticipantNames?: ParticipantNameOption;
  circleOwnerEmail?: string[];
}

export const MoreButton = memo(
  ({
    users,
    id,
    position,
  }: {
    id: string;
    users: { userId: string; avatar: string; username: string }[];
    position: SlotPosition;
  }) => {
    const popupState = usePopupState({
      variant: "popper",
      popupId: `room-${id}-users-popper`,
    });
    const [isDialogOpen, setDialogOpen] = useState(false);

    const { onClick, ...fabProps } = bindToggle(popupState);

    const isMobile = useMediaQuery<Theme>((theme) =>
      theme.breakpoints.down("lg"),
    );

    const handleCloseDialog = useLatestCallback(() => {
      setDialogOpen(false);
    });

    return (
      <li
        css={{
          display: "block",
          position: "absolute",
          width: `40px`,
          height: `40px`,
          ...getCirclePosition(position),
          transform: "translate(-50%, -50%)",
          padding: 0,
          margin: 0,
        }}
      >
        <Fab
          aria-label="more"
          css={css`
            width: 100%;
            height: 100%;
            color: white;
            background-color: #9592a9;
            :hover {
              background-color: #767583;
            }
          `}
          {...fabProps}
          onClick={(event) => {
            event.stopPropagation();
            isMobile ? setDialogOpen(true) : onClick(event);
          }}
        >
          <SvgIcon
            css={css`
              width: 50%;
              height: 50%;
            `}
          >
            <MoreIcon />
          </SvgIcon>
        </Fab>
        {!isMobile ? (
          <Popper
            {...bindPopper(popupState)} // In order to make sure the popper up above the Virtual Stage component
            sx={(theme) => ({
              zIndex: theme.zIndex.drawer,
            })}
          >
            <ClickAwayListener onClickAway={popupState.close}>
              <Paper
                css={css`
                  max-height: 50vh;
                  display: flex;
                  flex-direction: column;
                `}
              >
                <TabTitle>{`Attendees (${users.length})`}</TabTitle>
                <Divider />
                <CircleUserList
                  users={users}
                  css={(theme) => css`
                    padding: ${theme.spacing(1)} ${theme.spacing(2)};
                    overflow-y: auto;
                    flex: 1;
                  `}
                />
              </Paper>
            </ClickAwayListener>
          </Popper>
        ) : (
          <NetworkingCircleUsersDialog
            onClose={handleCloseDialog}
            open={isDialogOpen}
            users={users}
          />
        )}
      </li>
    );
  },
);

export const NetworkingCircleSlots = memo(
  ({
    onJoin,
    profiles,
    maxCount,
    roomIndex: roomId,
    logo,
    active = false,
    showParticipantNames,
    circleOwnerEmail,
  }: NetworkingCircleSlotsProps) => {
    const isFull = profiles.length >= maxCount;

    const user = useUser();

    const filteredProfiles = useMemo(() => {
      if (
        !showParticipantNames ||
        showParticipantNames === ParticipantNameOption.ALL
      ) {
        // No Filtering
        return profiles;
      }

      return profiles
        .map((profile) =>
          // if current user
          user.uid === profile.userId ||
          // Or in host/owner mode and user is host or owner
          (showParticipantNames === ParticipantNameOption.HOSTS_AND_OWNERS &&
            (profile.userRole === UserRole.Organizer ||
              circleOwnerEmail?.some(
                (email) =>
                  email && (email === profile.email || email === user.email),
              )))
            ? // Then return profile as is
              {
                ...profile,
                isCircleOwner: !!circleOwnerEmail?.includes(profile.email),
              }
            : // else show anonymous
              {
                userId: profile.userId,
                userRole: profile.userRole,
                username: "Anonymous User",
                avatar: "",
                isCircleOwner: false,
              },
        )
        .sort((a, b) => {
          if (!!a.isCircleOwner !== !!b.isCircleOwner) {
            return a.isCircleOwner ? -1 : 1;
          }
          return (
            (typeof a.userRole === "number" ? a.userRole : 99) -
            (typeof b.userRole === "number" ? b.userRole : 99)
          );
        }) as typeof profiles;
    }, [
      profiles,
      showParticipantNames,
      circleOwnerEmail,
      user.uid,
      user.email,
    ]);

    const profilesToRender = useMemo(() => {
      return filteredProfiles
        .slice(0, renderingOrder.length)
        .map((profile, index) => ({
          profile,
          position: renderingOrder[index],
        }));
    }, [filteredProfiles]);

    const renderJoinButton = (index: number) => {
      return (
        <li
          key={`id-${roomId}-${index}`}
          id={`id-${roomId}-${index}`}
          css={() => ({
            display: "block",
            position: "absolute",
            width: `40px`,
            height: `40px`,
            ...getCirclePosition(index),
            transform: "translate(-50%, -50%)",
            padding: 0,
            margin: 0,
          })}
        >
          <Tooltip
            title={isFull ? "Circle Full" : "Join Circle"}
            placement="bottom"
          >
            <Box
              css={css`
                width: 100%;
                height: 100%;
              `}
            >
              <Fab
                aria-label="join"
                disabled={isFull}
                onClick={(event) => {
                  event.stopPropagation();
                  onJoin?.();
                }}
                css={({ palette, customTheme }) => [
                  css`
                    width: 100%;
                    height: 100%;
                    color: ${palette.primary.main};
                    border: 1px solid ${palette.primary.main};
                    background-color: white;
                    box-shadow: none;
                    :hover {
                      background-color: ${palette.primary.main};
                      color: white;
                      box-shadow: 0 8px 25px -8px ${palette.primary.main};
                    }

                    &.Mui-disabled {
                      color: #b0b0b0;
                      background: white;
                      border-color: #b0b0b0;
                    }
                  `,
                  active &&
                    css`
                      color: ${customTheme?.networkingHub?.circleGraphics
                        ?.backgroundColor || palette.primary.main};
                      border: 1px solid
                        ${customTheme?.networkingHub?.circleGraphics
                          ?.backgroundColor || palette.primary.main};
                      :hover {
                        background-color: ${customTheme?.networkingHub
                          ?.circleGraphics?.backgroundColor ||
                        palette.primary.main};
                        box-shadow: 0 8px 25px -8px ${customTheme?.networkingHub?.circleGraphics?.backgroundColor || palette.primary.main};
                      }
                    `,
                ]}
                color="primary"
              >
                <SvgIcon
                  css={() => css`
                    width: 50%;
                    height: 50%;
                  `}
                >
                  <JoinCircleIcon />
                </SvgIcon>
              </Fab>
            </Box>
          </Tooltip>
        </li>
      );
    };

    return (
      <ul
        css={({ palette, customTheme }: Theme) => [
          {
            boxShadow: "0px 0px 50px 10px rgba(169, 166, 185, 0.25)",
            margin: "auto",
            width: "135px",
            height: "135px",
            padding: 0,
            position: "relative",
            borderRadius: "50%",
            listStyle: "none",
            backgroundColor: palette.common.white,
          },
          logo && {
            background: `url(${logo}) no-repeat center`,
            backgroundSize: "60%;",
          },
          active && {
            boxShadow: `0px 0px 50px 10px ${
              customTheme?.networkingHub?.circleGraphics?.backgroundColor ||
              palette.primary.main
            }40`,
          },
        ]}
      >
        {!logo && (
          <CircleIcon
            css={(theme) => css`
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              width: 40px;
              height: 40px;
              color: #979797;
            `}
          />
        )}
        {profilesToRender.map(({ profile, position }, index, array) => (
          <React.Fragment key={`real-id-${roomId}-${index}`}>
            {index + 1 < array.length ||
            filteredProfiles.length < renderingOrder.length + 1 ? (
              <li
                id={`real-id-${roomId}-${index}`}
                css={{
                  display: "block",
                  position: "absolute",
                  width: `40px`,
                  height: `40px`,
                  ...getCirclePosition(position),
                  transform: "translate(-50%, -50%)",
                  padding: 0,
                  margin: 0,
                }}
              >
                <Tooltip title={profile.username} placement="bottom">
                  <Avatar
                    src={transformImageUrl({
                      url: profile.avatar,
                      width: 72,
                      height: 72,
                    })}
                  />
                </Tooltip>
              </li>
            ) : (
              <MoreButton
                users={filteredProfiles}
                id={roomId}
                position={position}
              />
            )}
          </React.Fragment>
        ))}
        {renderJoinButton(SlotPosition.Bottom)}
      </ul>
    );
  },
);
