import * as t from "io-ts";
import { log } from "../helpers/logging";

export const parseDataToType = <T>(data: unknown, type: t.Type<T>): T => {
  const result = type.decode(data);

  if (result._tag === "Right") {
    return result.right;
  }

  const errorMessage = `Value ${JSON.stringify(data)} is not parsable to type ${
    type.name
  }`;

  // tests should fail when types we use do not match
  if (process.env.NODE_ENV === "test") {
    throw new Error(errorMessage);
  }

  // TODO add app logging (sentry) here so every type mismatch is tracked
  log(errorMessage);

  return data as T;
};
