import { UserRole } from "@src/contracts/user/user";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { userRoleAtom } from "../atoms";

/**
 * Hook which returns the currently active role for the user
 * @returns The UserRole for the user
 */
export const useUserRole = () => {
  const { role, temporaryRole } = useRecoilValue(userRoleAtom);
  return temporaryRole !== null ? temporaryRole : role;
};

/**
 * Hook which returns the active user's API role.
 *
 * This is not guaranteed to be the same as the `useUserRole` due to
 * promotions and/or role elevation which is client-side only.
 *
 * @returns The UserRole for the user from the API
 */
export const useUserApiRole = () => {
  const { apiRole } = useRecoilValue(userRoleAtom);
  return apiRole;
};

/**
 * Hook to set the current user's role.
 *
 * This WILL NOT update the user's role through the API.
 *
 * If a temporary role is active, will only update the temporary role,
 * maintaining the state of the actual role.
 *
 * @returns A callback which can be used to set the user's role
 */
export const useSetUserRole = () =>
  useRecoilCallback(({ set }) => (role: UserRole) => {
    set(userRoleAtom, (current) => ({
      ...current,
      // If the app is attempting to update roles (ex: promotion/demotion)
      // while a temporary role is active, we should only update the temporary
      // role to avoid persisting in these cases
      role: current.temporaryRole !== null ? current.role : role,
      temporaryRole: current.temporaryRole === null ? null : role,
    }));
  });
