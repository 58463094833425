/** @jsxImportSource @emotion/react */

import React, { memo } from "react";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";

import { ClassNames, useTheme } from "@emotion/react";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Popover from "@mui/material/Popover";
import ContainedButton from "@src/components/buttons/ContainedButton";
import OutlineButton from "@src/components/buttons/OutlineButton";

export const LeaveStageButton = memo((props: { onConfirm?: () => void }) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: `mingle-menu`,
  });

  const theme = useTheme();

  return (
    <ClassNames>
      {({ css }) => (
        <>
          <ContainedButton
            {...bindTrigger(popupState)}
            backgroundColor={theme.palette.danger.main}
            size="medium"
          >
            Return to Audience
          </ContainedButton>
          <Popover
            classes={{
              root: css`
                margin-top: 8px;
              `,
              paper: css`
                padding: 8px;
              `,
            }}
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <CardContent
              classes={{
                root: css`
                  font-weight: 700;
                `,
              }}
            >
              Are you sure you want to return to the audience?
            </CardContent>
            <CardActions
              css={css`
                display: flex;
                justify-content: flex-end;
              `}
            >
              <OutlineButton
                backgroundColor={theme.palette.common.white}
                textColor={theme.palette.common.black}
                onClick={popupState.close}
              >
                Cancel
              </OutlineButton>

              <ContainedButton
                onClick={() => {
                  popupState.close();
                  props.onConfirm?.();
                }}
                backgroundColor={theme.palette.danger.main}
                size="medium"
              >
                Yes, Return to Audience
              </ContainedButton>
            </CardActions>
          </Popover>
        </>
      )}
    </ClassNames>
  );
});
