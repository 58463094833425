/** @jsxImportSource @emotion/react */

import React from "react";
import { Theme } from "@mui/material";
import { css } from "@emotion/react";

import { EventStreamingStatus } from "./EventStateProvider";

const statusMap: Record<
  EventStreamingStatus,
  {
    color?: string;
    label: string;
  }
> = {
  [EventStreamingStatus.Live]: {
    color: "#ef3b3b",
    label: "Live",
  },
  [EventStreamingStatus.NotLive]: {
    color: "#7a8dab",
    label: "Not Live",
  },
  [EventStreamingStatus.Loading]: {
    label: "...",
  },
  [EventStreamingStatus.Networking]: {
    label: "Networking",
  },
};

export const EventStatusBadge = ({
  status,
}: {
  status: EventStreamingStatus;
}) => {
  return (
    <div
      css={(theme: Theme) => css`
        display: inline-block;
        margin-right: 12px;
        padding: 4px 16px;
        border-radius: 4px;
        background-color: ${statusMap[status].color ||
        theme.palette.primary.main};
        font-weight: 500;
        color: white;
        text-align: center;
      `}
    >
      {statusMap[status].label}
    </div>
  );
};
