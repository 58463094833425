import { emitAsPromise } from "@src/components/Presence/emitAsPromise";
import { Actions } from "@src/graphql/schemas/actions";
import { useCallback, useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  Question,
  QuestionActionHookOptions,
  QuestionEditEventInput,
  UserQuestion,
} from "../types";
import { createQuestionsQueryKey, sortQuestions } from "../helpers";

/**
 * Callback to update the edited question in the supplied local questions array
 */
const updateEditedQuestion =
  (data: Question) => (prev?: UserQuestion[] | null) => {
    const previousQuestions = Array.isArray(prev) ? prev : [];
    return sortQuestions(
      previousQuestions.map((question) => {
        if (question.id !== data.id) {
          return question;
        }

        return { ...question, name: data.name };
      }),
    );
  };

export interface UseEditEventQuestionOptions
  extends QuestionActionHookOptions {}

export const useEditEventQuestion = ({
  client,
}: UseEditEventQuestionOptions) => {
  // does not include optimistic updates since not implemented
  const cache = useQueryClient();
  // const queryKey = createQuestionsQueryKey(eventId, circleId);

  /**
   * Mutation to edit an existing question
   */
  const mutation = useMutation(
    (question: QuestionEditEventInput) =>
      emitAsPromise<Question>(client, Actions.EDIT_EVENT_QUESTION, question),
    {
      onError: () => {
        toast.error("There was an error editing the question");
      },
    },
  );

  /**
   * Callback to run when a `question-edited` event is received from the client server
   */
  const handleQuestionEdited = useCallback(
    (data: Question) => {
      cache.setQueryData<UserQuestion[]>(
        createQuestionsQueryKey(data.eventId, data.circleId),
        updateEditedQuestion(data),
      );
    },
    [cache],
  );

  return useMemo(
    () => ({ mutation, handleQuestionEdited }),
    [handleQuestionEdited, mutation],
  );
};

export const __testable__ = {
  updateEditedQuestion,
};
