import React, { useMemo } from "react";
import Avatar, { AvatarProps } from "@mui/material/Avatar";
import { transformImageUrl } from "@src/helpers/image";
import { getInitials } from "@src/helpers/utils";
import { stringToColor } from "@src/helpers/stringToColor";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

interface UserAvatarProps extends AvatarProps {
  userId?: string;
  image?: string;
  username: string;
  isAnonymous?: boolean;
  size?: number;
}
const UI_AVATAR_REGEX = /^https?:\/\/ui-avatars\.com/i;

const UserAvatar = ({
  image,
  username,
  userId,
  isAnonymous,
  size,
  ...props
}: UserAvatarProps) => {
  const avatarImg = useMemo(
    () => (!image || UI_AVATAR_REGEX.test(image) ? undefined : image),
    [image],
  );

  const initials = useMemo(() => {
    if (isAnonymous) return "";
    if (username) return getInitials(username);
    if (image && UI_AVATAR_REGEX.test(image)) {
      const u = new URL(image);
      const name = u.searchParams.get("name");
      return getInitials(name || "");
    }
    return null;
  }, [image, username, isAnonymous]);

  const avatarColors = useMemo(() => {
    if (isAnonymous) return undefined;
    if (!image) {
      // If no avatar, generate a color from their id
      return {
        backgroundColor: `#${stringToColor(userId ?? "sequel")}`,
      };
    }
    if (UI_AVATAR_REGEX.test(image)) {
      // If this is a ui-avatar, pull out the color value to avoid rate limit errors
      const u = new URL(image);
      const bg = u.searchParams.get("background");
      const font = u.searchParams.get("color");
      return {
        backgroundColor: bg ? `#${bg}` : undefined,
        color: font ? `#${font}` : undefined,
      };
    }
    // Use the picture the user uploaded
    return undefined;
  }, [userId, image, isAnonymous]);

  return (
    <Avatar
      alt={isAnonymous ? undefined : username}
      src={transformImageUrl({
        url: avatarImg || "",
        width: size ?? 72,
        height: size ?? 72,
      })}
      style={avatarColors}
      {...props}
    >
      {isAnonymous ? (
        <AccountCircleIcon
          sx={{ width: "100%", height: "100%" }}
          color="primary"
        />
      ) : (
        initials
      )}
    </Avatar>
  );
};

export default UserAvatar;
