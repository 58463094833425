import React from "react";

import {
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from "@mui/material";

interface SwitchProps extends MuiSwitchProps {
  /**
   * the name of the input element
   */
  name: string;
  /**
   * Callback used when the value of the switch is changed
   */
  onChange?: (e: any) => any;
}

export const Switch: React.FC<SwitchProps> = React.memo(
  ({ name, onChange, color, sx = {}, ...restProps }) => {
    return (
      <MuiSwitch
        name={name}
        role="switch"
        id={`switch-${name}`}
        color={color || "primary"}
        onChange={onChange}
        sx={{
          // get rid of the extra margin on the MUI switch
          mr: "-9px",
          "& .MuiSwitch-track": {
            backgroundColor: "common.black",
          },
          "& .MuiSwitch-thumb": {
            color: "base.50",
          },
          "& .MuiSwitch-switchBase.Mui-checked": {
            "& .MuiSwitch-track": {
              backgroundColor: "primary.main",
            },
            "& .MuiSwitch-thumb": {
              color: "primary.main",
            },
          },
          ...sx,
        }}
        {...restProps}
      />
    );
  },
);
