import React, { useMemo } from "react";
import { Form, FormikProvider } from "formik";
import { Box, Button, ButtonGroup, styled, Tooltip } from "@mui/material";

import { Event, ReplayConfig } from "../../contracts/event/event";
import { EventReplaysProvider } from "../../providers/EventReplaysProvider";
import {
  ReplayFormConfig,
  useEditReplayFormController,
} from "./useEditReplayFormController";
import { CustomTextPlaceholder } from "./CustomTextPlaceholder";
import { ShowCountdownContent } from "./ShowCountdownContent";
import VideoReplaysTab from "./VideoReplaysTab";
import { SubmitButton } from "./ReplayFormActions";
import { ButtonTabs } from "./common";
import {
  FeatureFlag,
  useFeatureFlag,
} from "@src/providers/FeatureFlagsProvider";

const StyledForm = styled(Form)(() => ({
  alignItems: "center",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  width: "100%",
}));

interface Props {
  event: Event;
  replayConfig: ReplayConfig;
  onFormSave?: () => void;
}

export default function EditReplayContent({
  event,
  replayConfig,
  onFormSave,
}: Props) {
  // countdowns only appear for upcoming events
  const showCountdownTab = event.startDate > new Date();

  const countdownRefactorEnabled = useFeatureFlag(
    FeatureFlag.COUNTDOWN_REFACTOR,
  );

  const initialValues = useMemo<ReplayFormConfig>(() => {
    const replayEnabled =
      countdownRefactorEnabled || (replayConfig.replayEnabled ?? true);
    const replayUrl = replayConfig.replayUrl;
    const streamPlaceholderMessage = replayConfig.streamPlaceholderMessage;

    const showReplay = replayEnabled && replayUrl?.length;
    const showCountdown =
      showCountdownTab && !replayConfig.streamPlaceholderMessage?.length;

    const activeTab =
      !showReplay && streamPlaceholderMessage?.length // use custom text if no replay and custom text is set
        ? ButtonTabs.ShowCustomText
        : !showReplay && showCountdown // use countdown if no replay and start date in the future
        ? ButtonTabs.ShowCountdown
        : ButtonTabs.SelectReplay; // default to select replay

    return {
      activeTab: countdownRefactorEnabled ? ButtonTabs.SelectReplay : activeTab,
      replayEnabled,
      replayUrl,
      showCountdown,
      streamPlaceholderMessage,
    };
  }, [replayConfig, countdownRefactorEnabled, showCountdownTab]);

  const { form } = useEditReplayFormController(
    event?.uid,
    initialValues,
    onFormSave,
  );

  const selectedTab = form.values.activeTab;

  const renderTabButton = (label: string, tabId: ButtonTabs) => (
    <Button
      onClick={() => onSelectTab(tabId)}
      variant={selectedTab === tabId ? "contained" : "outlined"}
    >
      {label}
    </Button>
  );

  const onSelectTab = (tabId: ButtonTabs) => {
    form.resetForm();
    form.setFieldValue("replayEnabled", tabId === ButtonTabs.SelectReplay);
    form.setFieldValue("showCountdown", tabId === ButtonTabs.ShowCountdown);
    form.setFieldValue("activeTab", tabId);
  };

  return (
    <EventReplaysProvider>
      <FormikProvider value={form}>
        <StyledForm>
          {!countdownRefactorEnabled && (
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              <Tooltip title="Specify which replay to show attendees after event. custom replay can be added in the Admin Dashboard">
                {renderTabButton(
                  "Select On-Demand Replay",
                  ButtonTabs.SelectReplay,
                )}
              </Tooltip>

              <Tooltip title="Customize message text shown to attendees while event is not live.">
                {renderTabButton(
                  "Display Custom Text",
                  ButtonTabs.ShowCustomText,
                )}
              </Tooltip>

              {showCountdownTab && (
                <Tooltip title="Show attendees a live countdown timer until event starts.">
                  {renderTabButton("Show Countdown", ButtonTabs.ShowCountdown)}
                </Tooltip>
              )}
            </ButtonGroup>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              p: 3,
              width: "100%",
              flexGrow: 1,
            }}
          >
            <TabContents tab={selectedTab} />
            {!countdownRefactorEnabled && (
              <SubmitButton event={event} selectedTab={selectedTab} />
            )}
          </Box>
        </StyledForm>
      </FormikProvider>
    </EventReplaysProvider>
  );
}

function TabContents({ tab }: { tab: number }) {
  switch (tab) {
    case ButtonTabs.SelectReplay:
      return <VideoReplaysTab selectedTab={tab} />;
    case ButtonTabs.ShowCustomText:
      return <CustomTextPlaceholder />;
    case ButtonTabs.ShowCountdown:
      return <ShowCountdownContent />;
    default:
      return null;
  }
}
