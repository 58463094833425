import { emitAsPromise } from "@src/components/Presence/emitAsPromise";
import { Actions } from "@src/graphql/schemas/actions";
import { useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { createPollsQueryKey } from "../helpers";
import { PollActionRollbackFunction } from "../types";
import {
  Poll,
  PollActionHookOptions,
  UserPoll,
  VoteEventPollOptionInput,
} from "../types";

/**
 * Callback used when optimistically updating the poll locally when the user votes in the poll
 */
export const updatePollOptionAsSelected =
  (input: VoteEventPollOptionInput) => (prev?: Poll[] | null) => {
    const previousPolls = Array.isArray(prev) ? prev : [];
    return previousPolls.map((poll) => {
      if (poll.id === input.poll && "options" in poll) {
        return {
          ...poll,
          isPending: true,
          options: poll.options.map((option) => {
            const selected = option.id === input.option;
            return {
              ...option,
              selected,
              votes: selected
                ? option.votes + 1
                : option.selected
                ? option.votes - 1
                : option.votes,
            };
          }),
        };
      }
      return poll;
    });
  };

export interface UseVoteEventPollOptionOptions extends PollActionHookOptions {}

export const useVoteEventPollOption = ({
  client,
  eventId,
  circleId,
}: UseVoteEventPollOptionOptions) => {
  const cache = useQueryClient();
  const queryKey = createPollsQueryKey(eventId, circleId);

  /**
   * Mutation to vote in an existing poll
   */
  const mutation = useMutation<
    Poll,
    unknown,
    VoteEventPollOptionInput,
    PollActionRollbackFunction
  >(
    (input: VoteEventPollOptionInput) =>
      emitAsPromise<Poll>(client, Actions.VOTE_EVENT_POLL_OPTION, input),
    {
      onMutate: (input) => {
        const previousPolls = cache.getQueryData<UserPoll[]>(queryKey);

        cache.setQueryData(queryKey, updatePollOptionAsSelected(input));

        return () => cache.setQueryData(queryKey, previousPolls);
      },
      onError: (err, variables, rollback) => {
        // rollback on error (success is already handled in handlePollUpdated)
        rollback?.();
        toast.error("There was an error voting in the poll");
      },
    },
  );

  return useMemo(() => ({ mutation }), [mutation]);
};
