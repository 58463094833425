import React, { memo } from "react";

import { Box, Typography } from "@mui/material";

import { CountdownClock } from "./Clock";

export const TimeCounter: React.FC<{ startDate: Date }> = ({ startDate }) => {
  return (
    <CountdownClock date={startDate}>
      {({ days, hours, minutes, seconds }) => (
        <div>
          <CounterBox label="Days" value={days} />
          <CounterBox label="Hours" value={hours} />
          <CounterBox label="Minutes" value={minutes} />
          <CounterBox label="Seconds" value={seconds} />
        </div>
      )}
    </CountdownClock>
  );
};

type CounterBoxProps = {
  label: string;
  value?: number;
};

export const CounterBox = memo(({ label, value = 0 }: CounterBoxProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginRight: 1,
        "&:last-of-type": {
          marginRight: 0,
        },
        padding: 2,
        bgcolor: "grey.50",
        borderRadius: 1,
        width: "100%",
        maxWidth: 100,
        minWidth: 70,
      })}
    >
      <Typography
        fontWeight="600"
        mb={0.5}
        sx={(theme) => ({
          // using an array to allow for fallback font
          fontSize: ["2rem", "clamp(2rem, 3.5vw, 3.25rem)"],
          color:
            theme.customTheme?.virtualStage?.countdown?.textColor ||
            theme.palette.primary.main,
        })}
      >
        {value}
      </Typography>
      <Typography variant="caption" fontWeight="500">
        {label}
      </Typography>
    </Box>
  );
});
