import { useEvent } from "@src/providers/EventProvider";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

interface UseRecurringEventRedirectResponse {
  isLoading: boolean;
}
/**
 * @returns a hook that redirects to the latest event in a recurring event series
 */
export const useRecurringEventRedirect =
  (): UseRecurringEventRedirectResponse => {
    const navigate = useNavigate();
    const [, pathName, pathId] = window.location.pathname.split("/");

    /** forced event id. so that in post event emails we can ensure the user sees the replay for the specific even in the series */
    const forceEventIdFromParams = useMemo(() => {
      const searchParams = new URLSearchParams(window.location.search);
      return searchParams.get("viewReplay");
    }, []);

    /** only check if its an event and there is no forced event id */
    /** dont check networking hubs */
    /** dont check forceEventIdFromParams as it taken as gospel as the event we want to display */
    const shouldCheck =
      pathName === "event" && pathId.length > 0 && !forceEventIdFromParams;

    const { data: event, isLoading } = useEvent(pathId, {
      enabled: shouldCheck,
    });

    const eventId = useMemo(() => {
      // always use this if present
      if (forceEventIdFromParams) return forceEventIdFromParams;
      // if the eventID from the path (EMBEDDED event) is the original recurring event.
      if (event?.recurring?.originalEventId === pathId) {
        // jump to the newest event in the series
        return event.recurring.lastIterationEventId || pathId;
      }
      // use pathid if not recurring
      return pathId;
    }, [event, pathId, forceEventIdFromParams]);

    useEffect(() => {
      const searchParams = window?.location?.search ?? "";
      // if we have a forced event id and its not the event we're already on
      if (forceEventIdFromParams && pathId !== forceEventIdFromParams) {
        navigate(`/event/${forceEventIdFromParams}${searchParams}`);
        return;
      }
      if (
        // if the target event differs from the current path
        pathId !== eventId &&
        // as its not null
        !!eventId &&
        // and we havent already jumped to the forced event
        forceEventIdFromParams !== pathId
      ) {
        // go
        navigate(`/event/${eventId}${searchParams}`);
      }
    }, [eventId, pathId, navigate, forceEventIdFromParams]);

    // when loading or we're not on the intended event. show loading and dont render the rest of the app.
    if (isLoading || pathId !== eventId) {
      return { isLoading: true };
    }

    // if we're on the right event and its not loading. render the app.
    return { isLoading: false };
  };
