import { useEventOrNetworkingHub } from "@src/hooks/useEventOrNetworkingHub";
import { Routes } from "@src/hooks/useIsRoute";
import {
  EventScheduledTimelineStatus,
  useEventScheduledTimelineStatus,
} from "@src/providers/EventStateProvider";
import { UserRole } from "@src/contracts/user/user";
import { useUser } from "@src/providers/UserProvider";
import { useCallback } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useHasEventEnded } from "@src/providers/EventProvider";

// TODO: need to add validation here based on the event status and https://introvoke.atlassian.net/browse/PROD-4494
// maybe also move other limit checks here
export const useJoinEvent = () => {
  const { userRole } = useUser();
  const {
    data: eventOrNetworkingHub,
    isNetworkingHub,
    isEvent,
  } = useEventOrNetworkingHub();

  const navigate = useNavigate();
  const location = useLocation();
  const [queryParams] = useSearchParams(location.search);

  const timelineStatus = useEventScheduledTimelineStatus();
  const hasEventEnded = useHasEventEnded();

  const isHostOrPresenter =
    userRole === UserRole.Presenter || userRole === UserRole.Organizer;

  const canEnterDirectly =
    isNetworkingHub || isHostOrPresenter || hasEventEnded || true; // true should be coming from the backend wait time

  const checkCanJoinEvent = useCallback(
    (joinCode: string | undefined): joinCode is string =>
      !!joinCode && canEnterDirectly,
    [canEnterDirectly],
  );

  const handleJoinEvent = useCallback(
    (joinCode: string) => {
      if (!checkCanJoinEvent(joinCode)) {
        return;
      }

      if (isNetworkingHub || canEnterDirectly) {
        queryParams.set("joinCode", joinCode);
        return navigate(
          {
            pathname: Routes.base.home(
              eventOrNetworkingHub?.uid ?? "",
              isEvent,
            ),
            search: queryParams.toString(),
          },
          { replace: true },
        );
      }

      if (timelineStatus === EventScheduledTimelineStatus.BEFORE) {
        return navigate(
          {
            pathname: Routes.registration.eventNotStarted(
              eventOrNetworkingHub?.uid ?? "",
            ),
            search: location.search,
          },
          { replace: true },
        );
      }
    },
    [
      canEnterDirectly,
      checkCanJoinEvent,
      eventOrNetworkingHub?.uid,
      isEvent,
      isNetworkingHub,
      location.search,
      navigate,
      queryParams,
      timelineStatus,
    ],
  );

  return {
    checkCanJoinEvent,
    handleJoinEvent,
  };
};
