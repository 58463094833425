import { useQuery } from "react-query";
import { usePubNubState } from "../pubnub/PubNubProvider";
import { getQueryKey } from "./ChatProvider";
import { isObjectWithProps } from "../../guards/isObjectWithProps";
import { isValidUser } from "./isValidUser";

const EMPTY_LIST = Object.freeze([]);

/**
 * Hook that retrieves initial and cached PubNub chat history for the given channel ID
 */
export const usePubNubHistory = <T extends Record<string, any>>(options: {
  channelId?: string | null;
  count?: number;
  mapMessageData: (message: any) => any;
  enabled?: boolean;
}) => {
  const { channelId, count, mapMessageData, enabled = true } = options;
  const [pubnub] = usePubNubState();
  const { data: history } = useQuery<T[]>(
    getQueryKey(channelId),
    async () => {
      if (!channelId) {
        return EMPTY_LIST as unknown as T[];
      }

      const response = await pubnub.fetchMessages({
        channels: [channelId],
        count: count || 100,
        includeMeta: true,
        includeMessageActions: true,
        stringifiedTimeToken: true,
      });

      if (!response.channels[channelId]) {
        return EMPTY_LIST as unknown as T[];
      }

      const messages = response.channels[channelId].flatMap<any>(
        ({ message, timetoken, meta, actions, channel }) => {
          if (
            !isObjectWithProps(meta, "user") ||
            !isValidUser(meta.user) ||
            actions?.deleted
          ) {
            return [];
          }

          return [
            {
              user: meta.user,
              timetoken: timetoken as string,
              channel,
              actions: actions || {},
              ...mapMessageData(message),
            },
          ];
        },
      );

      return messages;
    },
    {
      enabled: enabled && !!channelId,
      refetchOnWindowFocus: false,
      notifyOnChangeProps: ["data"],
      staleTime: Infinity,
    },
  );

  return history || (EMPTY_LIST as unknown as T[]);
};
