import mapValues from "lodash/mapValues";

import { SerializedStyles } from "@emotion/react";

export const mapClasses = <T extends Record<keyof any, SerializedStyles>>(
  styles: T,
  transform: (arg: SerializedStyles) => string,
): Record<keyof T, string> => {
  return mapValues(styles, transform);
};
