import React from "react";
import { useHandleJoinNetworking } from "@src/components/Event/EventStage/useHandleJoinNetworking";
import { SvgIcon } from "@mui/material";
import { NetworkingHubIcon } from "@src/components/icons";
import OutlineButton from "@src/components/buttons/OutlineButton";

interface Props {
  disabled?: boolean;
}

const JoinNetworkingHubButton = ({ disabled }: Props) => {
  const handleNetworkingJoin = useHandleJoinNetworking();

  return (
    <OutlineButton
      data-testid="join-networking-hub-btn"
      onClick={handleNetworkingJoin}
      size="medium"
      disabled={disabled}
      startIcon={
        <SvgIcon>
          <NetworkingHubIcon />
        </SvgIcon>
      }
    >
      Join Networking
    </OutlineButton>
  );
};

export default JoinNetworkingHubButton;
