import { emitAsPromise } from "@src/components/Presence/emitAsPromise";
import { Actions } from "@src/graphql/schemas/actions";
import { useCallback, useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  Question,
  QuestionActionHookOptions,
  QuestionActionRollbackFunction,
} from "../types";
import { createQuestionsQueryKey } from "../helpers";

/**
 * Callback to remove the deleted question from the supplied local questions array
 */
export const removeDeletedQuestion =
  (data: Question) =>
  (prev?: Question[] | null): Question[] => {
    const previousQuestions = Array.isArray(prev) ? prev : [];
    return previousQuestions.filter((question) => question.id !== data.id);
  };

export interface UseDeleteEventQuestionOptions
  extends Omit<QuestionActionHookOptions, "user"> {
  removeLocalQuestion: (questionId: string) => void;
}

export const useDeleteEventQuestion = ({
  client,
  eventId,
  circleId,
  removeLocalQuestion,
}: UseDeleteEventQuestionOptions) => {
  const cache = useQueryClient();
  const queryKey = createQuestionsQueryKey(eventId, circleId);

  /**
   * Mutation to delete an existing question
   */
  const mutation = useMutation<
    Question,
    unknown,
    string,
    QuestionActionRollbackFunction
  >(
    (questionId: string) =>
      emitAsPromise<Question>(
        client,
        Actions.DELETE_EVENT_QUESTION,
        questionId,
      ),
    {
      onMutate: (questionId) => {
        const previousQuestions =
          cache.getQueryData<Question[]>(queryKey) || [];

        cache.setQueryData<Question[]>(
          queryKey,
          removeDeletedQuestion({ id: questionId } as Question),
        );

        return () => cache.setQueryData(queryKey, previousQuestions);
      },
      onError: (err, variables, rollback) => {
        // rollback on error (success is handled by the handleQuestionDeleted)
        rollback?.();
        toast.error("There was an error deleting the question");
      },
    },
  );

  /**
   * Callback to run when a `question-deleted` event is received from the client server
   */
  const handleQuestionDeleted = useCallback(
    (data: Question) => {
      removeLocalQuestion(data.id);
      cache.setQueryData(
        createQuestionsQueryKey(data.eventId, data.circleId),
        removeDeletedQuestion(data),
      );
    },
    [cache, removeLocalQuestion],
  );

  return useMemo(
    () => ({
      mutation,
      handleQuestionDeleted,
    }),
    [handleQuestionDeleted, mutation],
  );
};
