import { UserRole } from "@src/contracts/user/user";
import axios from "axios";
import { ViewerLocation } from "../contracts/analytics/event-analytics-contracts";
import { VideoType } from "../models/eventType";
import { ApiConfig } from "./api-config";

const UpdateEventPageVideoView = async (
  eventId: string,
  videoId: string,
  viewType: VideoType,
  fakeUserId = "",
  userName: string,
  userRole: UserRole,
  profilePicture: string,
  location: ViewerLocation,
  circleName?: string,
  circleId?: string,
): Promise<void> => {
  if (
    eventId !== null &&
    eventId !== undefined &&
    videoId !== null &&
    videoId !== undefined
  ) {
    const configURL =
      ApiConfig.GetAnalyticsPrefix() +
      "/api/analytics/event/videoView/" +
      eventId;
    const payload = {
      videoId,
      viewType,
      userId: fakeUserId,
      userName,
      userRole,
      profilePicture,
      userLocation: location,
      circleName,
      circleId,
    };
    await postAnalytics(configURL, payload);
  }
};

const UpdateLengthOfWatch = async (
  eventId: string,
  videoId: string,
  sessionId: string,
  videoTimestamp: string,
  videoType: VideoType,
  fakeUserId = "",
  userName: string,
  userRole: UserRole,
  profilePicture: string,
  location: ViewerLocation,
  circleName?: string,
  circleId?: string,
  useNewTelemetryEndpoint: boolean = false,
): Promise<void> => {
  if (
    eventId !== null &&
    eventId !== undefined &&
    videoId !== null &&
    videoId !== undefined
  ) {
    let configURL;

    if (useNewTelemetryEndpoint) {
      configURL = ApiConfig.GetPrefix() + "/api/w/" + eventId;
    } else {
      configURL =
        ApiConfig.GetAnalyticsPrefix() +
        "/api/analytics/event/lengthOfWatch/" +
        eventId;
    }

    const payload = {
      playSession: sessionId,
      videoTimestamp,
      videoType,
      userId: fakeUserId,
      userName,
      userRole,
      profilePicture,
      userLocation: location,
      circleName,
      circleId,
    };

    await postAnalytics(configURL, payload);
  }
};

const postAnalytics = async (configUrl: string, payload: any = null) => {
  try {
    await axios.post(configUrl, payload, ApiConfig.GetHeader());
  } catch (e) {
    // Prevent any analytics errors to crash the website
  }
};

export const AnalyticsApi = {
  // The moment a user watched ondemand or live video
  UpdateEventPageVideoView,
  // A timestamp sent when a user is watching
  UpdateLengthOfWatch,
};
