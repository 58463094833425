import { useMutation } from "react-query";

import { emitAsPromise } from "./emitAsPromise";
import { UserRole } from "../../contracts/user/user";
import { Actions } from "@src/graphql/schemas/actions";
import type { SocketClient } from "@src/graphql/SocketClient/SocketClient";
import { useDeepCompareEffect } from "react-use";
import {
  FeatureFlag,
  useFeatureFlag,
} from "../../providers/FeatureFlagsProvider";
import { useClientSessionId } from "./SocketClientProvider";

export interface UserProfile {
  userId: string;
  username: string;
  userRole: UserRole;
  email: string;
  avatar: string;
}

export const useSyncSocketUser = (
  client: SocketClient,
  connected: boolean,
  user: UserProfile | null,
  isEventSet: boolean,
) => {
  const isV2ChatServerEnabled = useFeatureFlag(FeatureFlag.CHAT_SERVER_V2);
  const eventName = isV2ChatServerEnabled ? Actions.SET_USER : "set user";

  // this is important to retrigger sync if sessionId changes
  const sessionId = useClientSessionId();

  const { status, reset, mutateAsync } = useMutation(
    ({ userId, username, userRole, email, avatar }: UserProfile) =>
      emitAsPromise(client, eventName, {
        userId: String(userId),
        username: String(username),
        userRole:
          typeof userRole === "number" ? userRole : UserRole.Unregistered,
        email: String(email),
        avatar: String(avatar),
      }),
  );

  useDeepCompareEffect(() => {
    if (connected && isEventSet && user && user.userId && user.email) {
      mutateAsync(user);
    } else {
      reset();
    }
  }, [connected, mutateAsync, isEventSet, reset, user, sessionId]);

  return status;
};
