import React from "react";
import { Controller, type Control, type FieldPath } from "react-hook-form";

import type { ObjectType } from "@introvoke/react/types/common";

import { InputLabel, type InputLabelProps } from "@src/components/inputs";
import {
  Select as DefaultSelect,
  type SelectProps as DefaultSelectProps,
  type SelectItemProps,
} from "@src/components/inputs/Select";

export type SelectProps<TFieldValues extends ObjectType = ObjectType> = Omit<
  DefaultSelectProps,
  "placeholder" | "value"
> & {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
  label: InputLabelProps["label"];
  placeholder?: string;
  options?: SelectItemProps[];
};

const Select = <TFieldValues extends ObjectType = ObjectType>({
  control,
  name,
  label,
  options,
  placeholder = "Select...",
  sx,
  ...selectProps
}: SelectProps<TFieldValues>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, ...field }, fieldState, formState }) => (
        <InputLabel
          label={label}
          fullWidth
          sx={{ mt: 0, ...sx }}
          labelStyles={{
            fontWeight: 500,
            color: "base.500",
          }}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        >
          <DefaultSelect
            label={field.name}
            placeholder={placeholder}
            options={options}
            {...field}
            {...selectProps}
            disabled={formState.isLoading || formState.isSubmitting}
            sx={{
              width: "100%",
              borderRadius: 4,
            }}
          />
        </InputLabel>
      )}
    />
  );
};

export default React.memo(Select) as typeof Select;
