import { IconButton, styled } from "@mui/material";

export const StyledReactionImageButton = styled(IconButton)({
  transition: "transform 150ms ease-in-out",
  "&:hover": {
    transform: "translateY(-2px)",
  },
});

export const StyledReactionStrip = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 0.5,
  background: theme.palette.background.paper,
  padding: `${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
  borderRadius: 50,
  minHeight: theme.spacing(4.5),
}));

export const StyledReactionPickerButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  bottom: theme.spacing(-1),
  right: theme.spacing(-1),
  backgroundColor: theme.palette.common.white,
  borderRadius: 50,
  width: theme.spacing(3),
  height: theme.spacing(3),
}));
