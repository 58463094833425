import React, { memo } from "react";

import { Chip, styled } from "@mui/material";
import { UserRole } from "../../contracts/user/user";

const RoleChip = styled(Chip)(({ theme }) => ({
  borderRadius: 4,
  borderBottomLeftRadius: 0,
  color: theme.palette.common.white,
  fontSize: theme.typography.caption.fontSize,
  fontWeight: 600,
  height: "1.6em",
  "& .MuiChip-label": {
    padding: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

const getRoleLabel = (userRole?: UserRole): string => {
  switch (userRole) {
    case UserRole.Organizer:
      return "Host";
    case UserRole.Presenter:
      return "Presenter";
    default:
      return "";
  }
};

const ParticipantRoleTag = ({ userRole }: { userRole?: UserRole }) => {
  const label = getRoleLabel(userRole);
  return label.length ? (
    <RoleChip
      sx={{
        backgroundColor: label === "Host" ? "primary.main" : "success.main",
      }}
      label={label}
    />
  ) : null;
};

export default memo(ParticipantRoleTag);
