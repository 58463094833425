import { emitAsPromise } from "@src/components/Presence/emitAsPromise";
import { Actions } from "@src/graphql/schemas/actions";
import { useCallback, useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  Question,
  QuestionActionHookOptions,
  QuestionApproveEventInput,
  QuestionActionRollbackFunction,
  UserQuestion,
} from "../types";
import { createQuestionsQueryKey, sortQuestions } from "../helpers";

/**
 * Callback to update the approved question in the supplied local questions array
 */
export const updateApprovedQuestion =
  (data: QuestionApproveEventInput) => (prev?: UserQuestion[] | null) => {
    const previousQuestions = Array.isArray(prev) ? prev : [];
    return sortQuestions(
      previousQuestions.map((question) => {
        if (question.id !== data.id) {
          return question;
        }
        return {
          ...question,
          approved: data.approved,
          isPending: !!data.isPending,
        };
      }),
    );
  };

export interface UseApproveEventQuestionOptions
  extends QuestionActionHookOptions {}

export const useApproveEventQuestion = ({
  client,
  eventId,
  circleId,
}: UseApproveEventQuestionOptions) => {
  const cache = useQueryClient();
  const queryKey = createQuestionsQueryKey(eventId, circleId);

  /**
   * Mutation to approve or reject a `QuestionApprovedStatus.PENDING` question
   */
  const mutation = useMutation<
    Question,
    unknown,
    QuestionApproveEventInput,
    QuestionActionRollbackFunction
  >(
    (question: QuestionApproveEventInput) =>
      emitAsPromise<Question>(client, Actions.APPROVE_EVENT_QUESTION, question),
    {
      onMutate: (question) => {
        const previousQuestions =
          cache.getQueryData<UserQuestion[]>(queryKey) || [];

        cache.setQueryData(
          queryKey,
          updateApprovedQuestion({ ...question, isPending: true }),
        );

        return () => cache.setQueryData(queryKey, previousQuestions);
      },
      onError: (err, variables, rollback) => {
        // rollback on error (success is handled by the handleQuestionApproved)
        rollback?.();
        toast.error("There was an error approving the question");
      },
    },
  );

  /**
   * Callback to run when a `question-approved` event is received from the client server
   */
  const handleQuestionApproved = useCallback(
    (data: Question) => {
      cache.setQueryData<UserQuestion[]>(
        createQuestionsQueryKey(data.eventId, data.circleId),
        updateApprovedQuestion(data),
      );
    },
    [cache],
  );

  return useMemo(
    () => ({
      mutation,
      handleQuestionApproved,
    }),
    [handleQuestionApproved, mutation],
  );
};

export const __testable__ = {
  updateApprovedQuestion,
};
