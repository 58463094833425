import { createLogger, Logger } from "@src/helpers/logging";
import { query as gqlQuery, QueryResponse } from "../query";
import {
  Actions,
  ActionTransformer,
  messageTransformers,
  RUN_ACTION_QUERY,
  OnPublishSubscriptionData,
  START_SESSION_QUERY,
} from "../schemas/actions";
import { SocketSubscription } from "./SocketSubscription";
import { SocketClient, SocketClientOptions } from "./SocketClient";
import type { UserProfile } from "@src/components/Presence/useSyncSocketUser";
import { logGqlError } from "@src/graphql/helpers";
import { RETRY_DELAY_MS } from "./common";

type Callback = (err: unknown, res?: unknown) => void;

export interface GraphQlClientOptions extends SocketClientOptions {
  /**
   * Flag indicating if the client should attempt to resubscribe if connection to the room is lost
   * @default false
   */
  retry?: boolean;
  /**
   * The delay in milliseconds before resubscribing to the room when an error occurs
   * @default RETRY_DELAY_MS=1000
   */
  retryDelay?: number;
  /**
   * Whether to listen for presence updates
   * @default false
   */
  presence?: boolean;
  /**
   * Whether to enable logging events to the console (errors are always shown).
   * @default false
   */
  debug?: boolean;
  /**
   * Callback to call when session is started. This is required to sync user, room and event with the new session
   */
  onSessionStarted?: (sessionId: string) => void;
  /**
   * Callback to call when the client is disconnected
   */
  onDisconnect?: () => void;
}

/**
 * @augments SocketClient
 */
export class GraphQlClient extends SocketClient {
  private retry: boolean = false;
  private retryDelay: number = RETRY_DELAY_MS;

  private presence: boolean = false;

  private debug: boolean = false;
  private logger: Logger;

  /**
   * The user information stored in the socket session
   */
  private user: UserProfile | null = null;
  /**
   * The event or networking hub id the user is connected to
   */
  private eventId: string | null = null;
  /**
   * The networking hub circle the user is connected to (not really used)
   */
  private circleId: string | null = null;

  /**
   * The current sessionId of the user which is sent on all `runAction` requests
   */
  private sessionId: string | null = null;

  // SUBSCRIPTIONS TO UPDATES
  /**
   * All the subscription managers attached to the client
   *
   * _NOTE_: we don't have circle specific updates and all circle updates
   * are on the main networking hub instead, with the circleId the update belongs to
   */
  private socketSubscriptions: SocketSubscription[] = [];
  /**
   * Subscribes to general updates on the main event/hub itself (question-created,... )
   */
  private eventSubscription: SocketSubscription;
  /**
   * Subscribes to presence updates on the main event/hub itself (circle-presence, ...)
   */
  private presenceSubscription: SocketSubscription;
  /**
   * Subscribes to updates sent specifically to the client's user (promote, ...)
   */
  private userSubscription: SocketSubscription;

  /**
   * Callback to call when session is started. This is required to sync user, room and event with the new session
   */
  private onSessionStarted: ((sessionId: string) => void) | undefined;
  /**
   * Callback to call when the client is disconnected. This is required to sync user, room and event with the new session
   */
  private onDisconnect: (() => void) | undefined;

  constructor(options: GraphQlClientOptions = {}) {
    super();

    // set default values
    this.retry = !!options?.retry;
    this.retryDelay = options?.retryDelay ?? this.retryDelay;
    this.presence = !!options.presence;
    this.debug = !!options.debug;
    this.onSessionStarted = options.onSessionStarted;
    this.onDisconnect = options.onDisconnect;

    const autoConnect = options.autoConnect ?? true;

    this.logger = createLogger("GraphQlClient", { enabled: this.debug });

    // create subscription managers based on passed options
    // _NOTE_: These subscriptions will not automatically establish a subscription
    // until their `subscriptionKey` is initialized.
    this.eventSubscription = new SocketSubscription({
      retry: this.retry,
      debug: this.debug,
      roomPrefix: "event-",
      subscriptionKey: this.eventId,
      onMessage: this.onMessage,
    });

    this.userSubscription = new SocketSubscription({
      retry: this.retry,
      debug: this.debug,
      subscriptionKey: this.user?.userId,
      onMessage: this.onMessage,
    });

    this.presenceSubscription = new SocketSubscription({
      enabled: !!this.presence,
      retry: this.retry,
      debug: this.debug,
      roomPrefix: "circle-presence-",
      subscriptionKey: this.eventId,
      onMessage: this.onMessage,
    });

    this.socketSubscriptions = [
      this.eventSubscription,
      this.userSubscription,
      this.presenceSubscription,
    ];

    if (autoConnect) {
      this.connect();
    }
  }

  /*************************
   *** Inherited members ***
   *************************/

  /** Connects the client and all attached subscriptions */
  connect(): SocketIOClient.Socket {
    if (this.connected) return this;
    this.logger.log("connecting client...");
    this.connected = true;
    this.socketSubscriptions.forEach((manager) => manager.onConnect());
    this.logger.log("client connected");
    return this;
  }

  /** Disconnects the client and all attached subscriptions */
  disconnect(): SocketIOClient.Socket {
    if (this.disconnected) return this;
    this.logger.log("disconnecting client...");
    this.connected = false;
    this.socketSubscriptions.forEach((manager) => manager.onDisconnect());
    this.onDisconnect?.();
    this.logger.log("client disconnected");
    return this;
  }

  /** Check `client.disconnect()` */
  close(): SocketIOClient.Socket {
    return this.disconnect();
  }

  /** @inheritdoc */
  emit = (event: string, ...args: any[]): SocketIOClient.Socket => {
    // don't emit when disconnected
    if (this.disconnected) return this;

    if (!this.sessionId) {
      this.logger.error("cannot emit without sessionId", event, args);
      return this;
    }

    this.logger.log("emit", event, args);

    const callback =
      typeof args[args.length - 1] === "function"
        ? (args[args.length - 1] as Callback)
        : null;

    // We know the data is going to be the first arg or none
    const data =
      args.length > 1 || (args.length === 1 && !callback) ? args[0] : undefined;

    // if the message action has a tranformer => pass it to run action to apply it
    const transformer = messageTransformers[event] ?? null;

    this.runAction(event, data, callback, transformer);

    return this;
  };

  /*********************
   *** Class methods ***
   *********************/

  /**
   * Returns the current sessionId of the user
   */
  getSessionId() {
    return this.sessionId;
  }

  /**
   * Enables/disables the presence subscription
   */
  setPresence(enabled: boolean) {
    if (this.disconnected) return;

    this.presence = enabled;
    this.presenceSubscription.setEnabled(enabled);
    this.presenceSubscription.checkSubscription();
  }

  /**
   * Updates local values and checks if subscriptions need to be updated as a result
   *
   * _NOTE_: The data here would be after the transformation has been applied
   * to make sure that local values are in line with socket server values
   */
  private updateLocalValues(event: string, data: any) {
    if (event === Actions.SET_EVENT) {
      this.eventId = (data as any)?.eventId || null;
      this.eventSubscription.setSubscriptionKey(this.eventId);
      this.presenceSubscription.setSubscriptionKey(this.eventId);
    } else if (event === Actions.SET_CIRCLE) {
      this.circleId = (data as any)?.id || null;
    } else if (event === Actions.SET_USER) {
      this.user = data || null;
      const userId =
        this.user?.userId && this.user?.email
          ? `${this.user?.userId}|||${this.user?.email}`
          : null;
      this.userSubscription.setSubscriptionKey(userId);
    }

    // check if subscriptions need to be updated after local property updates
    this.checkSubscriptions();
  }

  private async handleError(err: Error | QueryResponse | string) {
    let errorMessage = "";

    // get the correct error message
    if (typeof err === "string") {
      errorMessage = err;
    } else if (err instanceof Error) {
      errorMessage = err.message;
    } else if ((err as QueryResponse)?.errors) {
      errorMessage = (err as QueryResponse)?.errors?.[0]?.message || "";
    }

    // handle errors based on message
    if (errorMessage.includes("expired")) {
      await this.startSession(this.sessionId);
    }
  }

  /**
   * Call the `runAction` GraphQL query with the provided options
   *
   * @param inputEvent The event name. Setting this to null will skip running the action
   * @param inputData The input data
   * @param callback The callback when the request is complete
   * @param transformer The tranformer function to apply to the data before sending the request
   */
  async runAction<T = any>(
    inputEvent: string,
    inputData: T,
    callback?: Callback | null,
    transformer?: ActionTransformer<T> | null,
  ) {
    const { event = inputEvent, data = inputData } = transformer
      ? transformer(inputData)
      : {};

    if (event === null) {
      this.logger.log("Skipping event", inputEvent, inputData);
      return callback?.(null, true);
    }

    const input = data ? JSON.stringify(data) : data;
    const variables = { name: event, input: input || "null" }; // NOTE: Use string null since server uses JSON.parse

    this.logger.log("Making gql request...", {
      name: event,
      input: data,
    });

    try {
      const res = await gqlQuery({
        query: RUN_ACTION_QUERY,
        variables,
        headers: {
          session: this.sessionId,
        },
      });

      if (res) {
        // if we have response
        try {
          this.logger.log("Success gql response for:", event, res);

          // update local values if necessary
          this.updateLocalValues(event, data);

          // parse the received response and run callback if any
          const parsedData = res.data ? JSON.parse(res.data) : res.data;
          callback?.(null, parsedData);
        } catch (err) {
          this.logger.error(
            "GqlParseError: Error parsing GqlResponse for:",
            event,
            res,
          );
          logGqlError(
            `GqlParseError: Error parsing GqlResponse for: ${event} ${res}`,
            err as Error,
          );
          callback?.(err, null);
        }
      }
    } catch (err) {
      this.logger.error(`GqlResponseError for:  ${event}`, err);
      logGqlError(`GqlResponseError for: ${event}`, err as Error);
      callback?.(err);
      this.handleError(err as Error);
    }
  }

  /**
   * Starts a user session on the GraphQL client. This is required for the user to be able to
   * take actions such as creating questions, voting...
   *
   * If the provided sessionId is valid and not expired, the existing user session will be reused.
   * Otherwise, a new session will is created.
   *
   * @param sessionId The current sessionId if it already exists
   *
   * @throws `Error` when the response is invalid or query fails
   */
  async startSession(sessionId?: string | null): Promise<string> {
    this.logger.log(`Starting session with id: ${sessionId}`);

    try {
      const res = await gqlQuery<string>({
        query: START_SESSION_QUERY,
        variables: {
          session: sessionId,
        },
      });

      if (!res?.data) {
        throw new Error("SessionId must be sent in the response");
      }

      // if we have response
      this.logger.log("Successfully started session:", res.data);

      // update local sessionId to be sent upon subsequent requests
      const sessionStartedId = res.data as string;
      this.sessionId = sessionStartedId;

      this.onSessionStarted?.(sessionStartedId);

      return sessionStartedId;
    } catch (err) {
      this.logger.warn("GqlResponseError for startSession:", err);
      logGqlError("GqlResponseError for startSession:", err as Error);
      throw err;
    }
  }

  /**
   * Checks if the subscriptions need to be updated based on their `subscriptionKey`
   */
  private checkSubscriptions() {
    if (this.disconnected) {
      return;
    }
    this.socketSubscriptions.forEach((manager) => manager.checkSubscription());
  }

  /**
   * General listener function triggered when a new message is received on any of the subscriptions
   *
   * @param message The message received on the subscription
   */
  onMessage = (message: OnPublishSubscriptionData) => {
    // ignore messages when disconnected
    if (this.disconnected) return;

    this.logger.log("onMessage", message);
    try {
      // parse received message since the server responds by a JSON string or "null"
      const data = JSON.parse(message.input);

      const { eventName, eventBody } = data;

      // if the event has event listeners
      const eventListeners = this.listeners(eventName);

      this.logger.log(
        `Processing ${eventListeners.length} listeners for event:`,
        eventName,
      );

      eventListeners.forEach((listener) => {
        try {
          listener(eventBody);
        } catch (err) {
          this.logger.error(
            "Error calling event listener for:",
            eventName,
            eventBody,
            listener,
          );
          logGqlError(
            `Error calling event listener for: ${eventName} with body: ${eventBody}`,
            err as Error,
          );
        }
      });

      this.logger.log("Done processing listeners for event:", eventName);
    } catch (err) {
      this.logger.error(
        "GqlParseError: Error parsing graphql response:",
        message,
      );
      logGqlError(
        `GqlParseError: Error parsing graphql response ${message}`,
        err as Error,
      );
    }
  };
}
