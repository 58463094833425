import { Registration } from "@src/contracts/event/event";
import { useMemo } from "react";

export enum RegistrationModes {
  SEQUEL_REGISTRATION = "Sequel Registration",
  THIRD_PARTY_REGISTRATION = "Third Party Registration",
  NO_REGISTRATION = "No Registration",
  ACCESS_CODES = "Access Codes",
}

type ObjectWithRegistrationProperties =
  | {
      isRegistrationModeEnabled?: boolean;
      registration?: Registration;
      [x: string]: any;
    }
  | undefined
  | null;

/**
 * @returns the currently active registration mode for the event or networking hub
 */
export const getRegistrationMode = (
  eventOrNetworkingHub: ObjectWithRegistrationProperties,
) => {
  if (eventOrNetworkingHub?.isRegistrationModeEnabled)
    return RegistrationModes.ACCESS_CODES;
  if (eventOrNetworkingHub?.registration?.outsideOfAppEnabled)
    return RegistrationModes.THIRD_PARTY_REGISTRATION;
  if (eventOrNetworkingHub?.registration?.enabled)
    return RegistrationModes.SEQUEL_REGISTRATION;
  return RegistrationModes.NO_REGISTRATION;
};

/**
 * @returns the currently active registration mode and booleans derived from it for convenience.
 * Use the memoized hook below in react contexts and this function directly in non-react contexts.
 */
export const checkRegistrationModes = (
  eventOrNetworkingHub: ObjectWithRegistrationProperties,
) => {
  const mode = getRegistrationMode(eventOrNetworkingHub);
  return {
    mode,
    isAccessCodes: mode === RegistrationModes.ACCESS_CODES,
    isNoRegistration: mode === RegistrationModes.NO_REGISTRATION,
    isSequelRegistration: mode === RegistrationModes.SEQUEL_REGISTRATION,
    isThirdPartyRegistration:
      mode === RegistrationModes.THIRD_PARTY_REGISTRATION,
  };
};

/**
 * @returns the currently active registration mode and booleans derived from it for convenience
 */
export const useRegistrationModes = (
  eventOrNetworkingHub: ObjectWithRegistrationProperties,
) => {
  return useMemo(
    () => checkRegistrationModes(eventOrNetworkingHub),
    [eventOrNetworkingHub],
  );
};
