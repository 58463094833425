import React from "react";
import { Box, Divider, styled } from "@mui/material";

import { SettingsSection } from "../SideBar/SettingsSection";
import { useSettingsTabController } from "./useSettingsTabController";

const Container = styled("div")(({ theme: { spacing, breakpoints } }) => ({
  overflow: "auto",
  overflowX: "hidden",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  minHeight: 0,
  padding: spacing(1),
  paddingLeft: spacing(1.5),
  paddingRight: spacing(1.5),
  [breakpoints.down("sm")]: {
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
  },
}));

const DividerStyle = styled(Divider)(({ theme: { spacing } }) => ({
  marginTop: spacing(1),
  width: "100%",
}));

interface SettingsTabProps {}
const SettingsTab: React.FC<SettingsTabProps> = () => {
  const { sections } = useSettingsTabController();

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1"
      minHeight="0"
      alignItems="stretch"
    >
      <DividerStyle />
      <Container>
        {sections.map((section) => (
          <SettingsSection
            key={section.title}
            title={section.title}
            content={section.content}
          />
        ))}
      </Container>
    </Box>
  );
};

export default React.memo(SettingsTab);
