import React from "react";
import { Avatar, Typography, styled } from "@mui/material";
import QnAIcon from "@mui/icons-material/HelpOutline";

const Container = styled("div")(({ theme: { spacing } }) => ({
  paddingLeft: spacing(2),
  paddingRight: spacing(2),
}));

const IconContainer = styled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: spacing(4),
  marginBottom: spacing(4),
}));

const AvatarStyled = styled(Avatar)(({ theme: { palette } }) => ({
  width: 72,
  height: 72,
  color: palette.common.white,
}));

const Text = styled(Typography)(({ theme: { spacing } }) => ({
  marginTop: spacing(4),
  marginBottom: spacing(4),
}));

export const EmptyQAndAList: React.FC = () => (
  <Container>
    <IconContainer>
      <AvatarStyled>
        <QnAIcon fontSize="large" />
      </AvatarStyled>
    </IconContainer>
    <Text align="center">No questions yet</Text>
  </Container>
);
