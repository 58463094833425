import { styled, Box } from "@mui/material";

export const ReactionImage = styled("img")(({ theme }) => ({
  border: "none",
  outline: "none",
  backgroundColor: "transparent",
  marginRight: theme.spacing(1),
  "&:last-of-type": {
    marginRight: 0,
  },
}));

export const Container = styled(Box)(({ theme }) => ({
  pointerEvents: "auto",
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
  flex: 1,
  borderRadius: 20,
  padding: theme.spacing(1),
  paddingTop: theme.spacing(0.5),
  paddingBottom: 0,
  background: "transparent",

  "& .sequel-reaction-image": {
    width: 20,
    height: 20,
  },

  [theme.breakpoints.down("sm")]: {
    paddingBottom: theme.spacing(4),
  },
}));
