import React, { useCallback } from "react";
import {
  UserQuestion,
  QuestionCreateInput,
  QuestionAnswerEventInput,
  QuestionApprovedStatus,
  QuestionApproveEventInput,
} from "../../../providers/qAndA/QandAProvider";
import { MenuItemType } from "../../Menu";

import DeleteIcon from "@mui/icons-material/DeleteOutline";
import QuestionAnswerIcon from "@mui/icons-material/CommentOutlined";
import QuestionEditAnswerIcon from "@mui/icons-material/Comment";
import MarkAnsweredIcon from "@mui/icons-material/Spellcheck";
import MarkUnansweredIcon from "@mui/icons-material/Clear";
import QuestionApproveIcon from "@mui/icons-material/Check";
import RejectQuestionIcon from "@mui/icons-material/Block";

interface UseCreateMenuItemsProps {
  isOrganizer?: boolean;
  isApproveQnaEnabled?: boolean;
  showQuestionAnswerDialog: (question?: UserQuestion | undefined) => void;
  showQuestionDeleteDialog: (questionId: string) => void;
  handleCreateEventQuestion: (data: QuestionCreateInput) => void;
  /**
   * Leave answer and answered empty for toggling behavior
   */
  handleAnswerEventQuestion: (
    data: QuestionAnswerEventInput,
  ) => Promise<unknown>;
  handleApproveEventQuestion: (data: QuestionApproveEventInput) => void;
}
export const useCreateMenuItems = ({
  isOrganizer,
  isApproveQnaEnabled,
  handleApproveEventQuestion,
  handleAnswerEventQuestion,
  showQuestionAnswerDialog,
  showQuestionDeleteDialog,
}: UseCreateMenuItemsProps) => {
  const createMenuItems = useCallback(
    (question: UserQuestion) => {
      const preApprovalActions = [
        {
          name: "approve-question",
          "data-testid": "question-menu-button-approve",
          icon: <QuestionApproveIcon />,
          tooltip:
            "Approving a question will make it visible to attendees and allow you to answer by text and more",
          onClick: handleApproveEventQuestion.bind(null, {
            id: question.id,
            approved: QuestionApprovedStatus.APPROVED,
          }),
        },
        {
          name: "reject-question",
          "data-testid": "question-menu-button-approve",
          icon: <RejectQuestionIcon />,
          tooltip: "Rejecting a question will hide it from all attendees",
          onClick: handleApproveEventQuestion.bind(null, {
            id: question.id,
            approved: QuestionApprovedStatus.REJECTED,
          }),
        },
      ];

      const postApprovalActions = [
        {
          name: "answer-text",
          "data-testid": "question-menu-button-answer",
          icon: question?.answer ? (
            <QuestionEditAnswerIcon />
          ) : (
            <QuestionAnswerIcon />
          ),
          tooltip: question?.answer ? "Edit text answer" : "Answer by text",
          onClick: showQuestionAnswerDialog.bind(null, question),
        },
        {
          name: "mark-answered",
          "data-testid": "question-menu-button-mark-answered",
          icon: question?.answered ? (
            <MarkUnansweredIcon />
          ) : (
            <MarkAnsweredIcon />
          ),
          tooltip: question?.answered
            ? "Mark as unanswered"
            : "Mark as answered",
          onClick: handleAnswerEventQuestion.bind(null, {
            ...question,
            answered: !question.answered,
          }),
        },
      ];

      const organizerActions = isOrganizer
        ? isApproveQnaEnabled &&
          question.approved === QuestionApprovedStatus.PENDING
          ? preApprovalActions
          : postApprovalActions
        : [];

      const ownerActions =
        // don't add delete button if organizer and approve qna is enabled
        (isOrganizer &&
          !(
            isApproveQnaEnabled &&
            question.approved === QuestionApprovedStatus.PENDING
          )) ||
        question?.isOwner
          ? [
              {
                name: "delete-question",
                "data-testid": "question-menu-button-delete",
                icon: <DeleteIcon />,
                tooltip: "Delete and hide question",
                onClick: showQuestionDeleteDialog.bind(null, question.id),
              },
            ]
          : [];

      return [...organizerActions, ...ownerActions] as MenuItemType[];
    },
    [
      isOrganizer,
      isApproveQnaEnabled,
      handleAnswerEventQuestion,
      handleApproveEventQuestion,
      showQuestionAnswerDialog,
      showQuestionDeleteDialog,
    ],
  );

  return createMenuItems;
};
