import React from "react";
import invariant from "tiny-invariant";

import { TabTitle } from "../TabTitle";
import { useEvent } from "../../providers/EventProvider";
import EditReplayContent from "./EditReplayContent";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { ReplayConfig } from "@src/contracts/event/event";
import {
  FeatureFlag,
  useFeatureFlag,
} from "@src/providers/FeatureFlagsProvider";

export interface EditReplayModalProps {
  onClose?: () => void;
  replayConfig: ReplayConfig;
}

const Modal = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "min(100vh, 500px)",
  maxHeight: "min(100vh, 500px)",
}));

export const EditReplayModal: React.FC<EditReplayModalProps> = ({
  onClose,
  replayConfig,
}) => {
  const { data: event } = useEvent();
  invariant(event, "useEvent called without an existing event Uid");
  const countdownRefactorEnabled = useFeatureFlag(
    FeatureFlag.COUNTDOWN_REFACTOR,
  );

  return (
    <Modal>
      {!countdownRefactorEnabled && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <TabTitle>{"Edit Session Ended Experience"}</TabTitle>
        </Box>
      )}
      {
        <EditReplayContent
          event={event}
          replayConfig={replayConfig}
          onFormSave={onClose}
        />
      }
    </Modal>
  );
};
