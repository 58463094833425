import React, { useCallback } from "react";
import { User, UserRole } from "@src/contracts/user/user";
import { useQuestionContext } from "@src/providers/qAndA/QandAProvider";
import { QandATabSettings } from "../Q&A/QandATabSettings";
import { SettingsGroup } from "../SideBar/SettingsSection";
import { InputLabel, Switch } from "../inputs";
import { useChatReactions } from "@src/providers/chat/useChatReactions";

export interface ChatSettingsProps {
  user: User;
}

const ChatSettings = ({ user }: ChatSettingsProps) => {
  const {
    isAnonymousQnaEnabled,
    isApproveQnaEnabled,
    handleEnableApproveQna,
    handleEnableAnonymousQna,
  } = useQuestionContext();

  const {
    featureEnabled: isChatReactionsFeatureEnabled,
    isChatReactionsOn,
    enableChatReactions,
  } = useChatReactions();

  const isOrganizer = user.userRole === UserRole.Organizer;

  const handleEnableChatReactions = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      enableChatReactions(e.target.checked);
    },
    [enableChatReactions],
  );

  // for now hide all chat settings if a user is not a host
  if (!isOrganizer) {
    return null;
  }

  return (
    <SettingsGroup title="Chat+ Settings" data-testid="chat-settings">
      <QandATabSettings
        user={user}
        isAnonymousQnaEnabled={isAnonymousQnaEnabled}
        isApproveQnaEnabled={isApproveQnaEnabled}
        enableAnonymousQna={handleEnableAnonymousQna}
        enableApproveQna={handleEnableApproveQna}
      />
      {/* chat reactions settings */}
      {isChatReactionsFeatureEnabled && (
        <InputLabel
          label="Chat Reactions"
          direction="horizontal"
          fullWidth
          description="Allow users to react to chat messages with predetermined reactions"
          data-testid="label-isChatReactionsOn"
        >
          <Switch
            name="isChatReactionsOn"
            checked={isChatReactionsOn}
            onChange={handleEnableChatReactions}
            data-testid="switch-isChatReactionsOn"
          />
        </InputLabel>
      )}
    </SettingsGroup>
  );
};

export default React.memo(ChatSettings);
