import React, { memo } from "react";
import { Box, Typography } from "@mui/material";

export const ShowCountdownContent = memo(() => {
  return (
    <Box flexGrow={1} margin={2}>
      <Typography>
        Show attendees a countdown timer until event starts.
      </Typography>
    </Box>
  );
});
