import type { ChannelConfig } from "../pubnub/PubNubProvider";

type ChatTypeConfig =
  | {
      type: "circle";
      event: string;
      circle: string;
    }
  | {
      type: "event" | "event-presenters" | "presence";
      event: string;
    }
  | {
      type: "extraTab" | "private";
      channel: string;
    }
  | {
      type: "live-reactions";
      event: string;
    };

export const getChannelConfig = (params: ChatTypeConfig): ChannelConfig => {
  switch (params.type) {
    case "circle":
      return {
        type: "circle",
        channel: `${params.event}-${params.circle}-circle-chat`,
        pinnable: true,
      };
    case "event":
      return {
        type: "event",
        channel: `${params.event}-chat`,
        pinnable: true,
        hasAnnouncements: true,
      };
    case "event-presenters":
      return {
        type: "event-presenters",
        channel: `${params.event}-presenters-chat`,
        pinnable: true,
        hasAnnouncements: true,
      };
    case "extraTab":
      return {
        type: "extraTab",
        channel: params.channel,
      };
    case "private":
      return {
        type: "private",
        channel: params.channel,
      };
    case "presence":
      return {
        type: "presence",
        channel: `${params.event}-presence`,
      };
    case "live-reactions": {
      return {
        type: "live-reactions",
        channel: `${params.event}-live-reactions`,
      };
    }
  }
};
