import gql from "graphql-tag";

export const SUBSCRIBE_EVENT_CLOSE_CAPTIONS = gql`
  subscription OnEventCloseCaption($eventId: String!, $language: String!) {
    onEventCloseCaption(eventId: $eventId, language: $language) {
      eventId
      captions {
        startTime
        endTime
        text
        partial
      }
      language
    }
  }
`;
