import {
  DependencyList,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDeepCompareEffect, useLatest } from "react-use";

import {
  isChrome,
  isIOS,
  isMobileBrowser,
  isOpera,
} from "@src/helpers/browser";
import { VideoPlayerInstance } from "@src/components/VideoPlayer/types";

import { getManifestStreamTime, showIOSCaption } from "../helpers/iosCaption";
import { CloseCaption } from "@src/types/graphQl";
import {
  MediaPlayer as IvsPlayer,
  MediaPlayer,
} from "../IvsVideoPlayer/ivsTypes";

const CAPTION_UPDATE_INTERVAL_MS = 100;
const CAPTIONS_MAX_DISPLAY_TIME_S = 5;

interface UseClosedCaptionsArgs {
  /**
   * The initial language which will be selected, if not provided captions will be
   * considered off
   */
  initialLanguage?: string;
  /**
   * A list of languages which are currently supported
   */
  supportedLanguages: string[];
  /**
   * A list of captions that will be added internally to a queue and displayed
   * at the appropriate time based on player position and start/end times of
   * the provided captions.
   *
   * _NOTE_: Captions which contain a language that the user has not selected
   * will not be shown.
   */
  captions: CloseCaption[];
  /**
   * Disables showing captions and allowing the user to choose captions
   */
  disabled?: boolean;
  /**
   * The video player ref
   */
  playerRef: MutableRefObject<VideoPlayerInstance | undefined>;
  /**
   * Callback invoked with the currently selected language once the user
   * selects a language for the captions in the player. The language will be
   * `undefined` when the user chooses to turn off closed captioning.
   */
  onLanguageChange?: (language: string | undefined) => void;
}

interface UseLiveCaptionsReturn {
  /**
   * Clears all currently displayed captions as well as all future captions if present
   */
  clearCaptions: () => void;
}

interface CreateIosMobileEffectArgs {
  src: string | undefined;
  isPlaying: boolean;
  ivsPlayer: IvsPlayer | undefined;
  setIosStartOffset: Dispatch<SetStateAction<number | undefined>>;
}

/**
 * Factory function which returns an effect and dependency array.
 *
 * The effect will attempt to retrieve the stream start time for the given `src`
 * This is needed on IOS due to limitations of the platform. For reference, please see:
 * https://github.com/aws-samples/amazon-ivs-auto-captions-web-demo/blob/main/web-ui/player-app/src/helpers/iosCaption.js
 */
const createIosMobileEffect = (
  args: CreateIosMobileEffectArgs,
): [() => void, DependencyList] => [
  () => {
    const { src, ivsPlayer, isPlaying, setIosStartOffset } = args;
    async function getStartOffset() {
      const offset = await getManifestStreamTime(
        src as string,
        ivsPlayer as IvsPlayer,
      );
      setIosStartOffset(offset);
    }
    if (!src || !ivsPlayer) return;
    if (isPlaying && isIOS() && (isMobileBrowser() || isChrome())) {
      getStartOffset();
    }
  },
  Object.values(args),
];

interface CreateNewCueAndAddToTrackCallbackArgs {
  textTrackRef: MutableRefObject<TextTrack | undefined>;
  removeCues: () => void;
}

/**
 * Factory function which returns a callback effect and dependency array.
 *
 * The callback will attempt to add a new cue to the current track on the supplied ref.
 * It will also initiate a removal of any active cues on the track to ensure only one
 * cue is displayed at a time.
 */
const createNewCueAndAddToTrackCallback = (
  args: CreateNewCueAndAddToTrackCallbackArgs,
): [
  (startTime: number, endTime: number, text: string) => void,
  DependencyList,
] => [
  (startTime, endTime, text) => {
    const { textTrackRef, removeCues } = args;
    if (!textTrackRef.current?.cues) return;
    const track = textTrackRef.current;
    const newCue = new VTTCue(startTime, endTime, text);
    removeCues();
    track.addCue(newCue);
  },
  Object.values(args),
];

interface CreateRemoveCuesArgs {
  textTrackRef: MutableRefObject<TextTrack | undefined>;
}

/**
 * Factory function which returns a callback effect and dependency array.
 *
 * The callback will attempt to remove all the currently active cues on the given
 * track.
 */
const createRemoveCues = (
  args: CreateRemoveCuesArgs,
): [() => void, DependencyList] => [
  () => {
    const { textTrackRef } = args;
    if (!textTrackRef?.current?.cues) return;
    const track = textTrackRef.current;
    const cues = track.cues;
    while (cues && cues.length > 0) {
      track.removeCue(cues[0]);
    }
  },
  Object.values(args),
];

interface CreateRemoveTranscriptionsFromQueueArgs {
  setTranscriptionsQueue: Dispatch<SetStateAction<CloseCaption[]>>;
}

/**
 * Factory function which returns a callback effect and dependency array.
 *
 * The callback will remove all transcriptions in the queue which have an end time
 * that is greater than the current player position.
 */
const createRemoveTranscriptionsFromQueue = (
  args: CreateRemoveTranscriptionsFromQueueArgs,
): [
  (playerPosition: number, timeCorrection: number) => void,
  DependencyList,
] => [
  (playerPosition, timeCorrection) => {
    const syncPosition = playerPosition - timeCorrection;
    args.setTranscriptionsQueue((oldQueue) =>
      oldQueue.filter(({ endTime }) => endTime > syncPosition),
    );
  },
  Object.values(args),
];

interface CreateShiftTranscriptionQueueArgs {
  setTranscriptionsQueue: Dispatch<SetStateAction<CloseCaption[]>>;
}

/**
 * Factory function which returns a callback effect and dependency array.
 *
 * The callback will shift off the first item in the transcriptions queue,
 * removing it from the current queue. This is effectively a dequeue operation.
 */
const createShiftTranscriptionQueue = (
  args: CreateShiftTranscriptionQueueArgs,
): [() => void, DependencyList] => [
  () => {
    args.setTranscriptionsQueue((oldQueue) => {
      const newQueue = [...oldQueue];
      newQueue.shift();
      return newQueue;
    });
  },
  Object.values(args),
];

interface CreateUpdateCaptionsArgs {
  iosStartOffset: number | undefined;
  transcriptionsQueue: CloseCaption[];
  disabled?: boolean;
  ivsPlayer: MediaPlayer | undefined;
  showCaption: (s: number, e: number, t: string) => void;
  shiftTranscriptionsQueue: () => void;
  removeTranscriptionsFromQueue: (p: number, e: number) => void;
}

/**
 * Factory function which returns a function that is used to update the currently
 * displayed caption.
 *
 * Internally checks the current player time and attempts to show the caption
 * which aligns with the start/end time of the player.
 */
const createUpdateCaptions = (args: CreateUpdateCaptionsArgs) => () => {
  const {
    transcriptionsQueue,
    iosStartOffset,
    disabled,
    ivsPlayer,
    showCaption,
    shiftTranscriptionsQueue,
    removeTranscriptionsFromQueue,
  } = args;
  if (!transcriptionsQueue.length || disabled || !ivsPlayer) return;

  const newCaption = transcriptionsQueue[0];

  if (isIOS() && (isMobileBrowser() || isChrome())) {
    showIOSCaption(
      iosStartOffset,
      newCaption,
      ivsPlayer,
      showCaption,
      shiftTranscriptionsQueue,
    );
    return;
  }

  // calculate start and end times
  const playerPosition = ivsPlayer.getPosition();
  const buffered = ivsPlayer.getBuffered();
  // The ivs video player does not seek to live when it recovers from buffering
  // so we also need to calculate how much we buffered locally to find the correct
  // start time for captions
  const liveWithBufferedDifference = playerPosition - buffered.end;

  // NOTE: getStartOffset is an unsupported player API. As such, it may be changed or deprecated without notice
  // @ts-ignore
  const playerStartOffset = ivsPlayer.getStartOffset?.() ?? 0;

  let playerLiveLatency;
  if (isOpera()) {
    // TODO: Test in opera to figure out why AWS example has these values
    playerLiveLatency = ivsPlayer.isLiveLowLatency() ? 2 : 4;
  } else {
    playerLiveLatency = ivsPlayer.getLiveLatency();
  }

  const timeCorrection = -(
    playerStartOffset +
    playerLiveLatency +
    liveWithBufferedDifference
  );
  const endTimeCorrection = timeCorrection + CAPTIONS_MAX_DISPLAY_TIME_S;

  const startTime = newCaption.startTime + timeCorrection;
  const endTime = newCaption.endTime + endTimeCorrection;

  if (startTime <= playerPosition && endTime >= playerPosition) {
    showCaption(startTime, endTime, newCaption.text);
  } else if (endTime <= playerPosition) {
    removeTranscriptionsFromQueue(playerPosition, endTimeCorrection);
  }
};

/**
 * Hooks which internally handles adding live closed captions to a video player.
 *
 * Code to calculate correct cue positioning based on https://github.com/aws-samples/amazon-ivs-auto-captions-web-demo
 */
export const useLiveClosedCaptions = ({
  initialLanguage,
  supportedLanguages,
  captions,
  disabled,
  playerRef,
  onLanguageChange,
}: UseClosedCaptionsArgs): UseLiveCaptionsReturn => {
  const textTrackRef = useRef<TextTrack | undefined>();
  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage);
  const [src, setSrc] = useState<string>();
  const [iosStartOffset, setIosStartOffset] = useState<number>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [transcriptionsQueue, setTranscriptionsQueue] = useState<
    CloseCaption[]
  >([]);

  const player = playerRef.current;
  const ivsPlayer = useMemo(() => {
    try {
      return player?.getIVSPlayer?.();
    } catch (e) {
      // sometimes this throws when the IVS tech isn't ready yet
    }
    return undefined;
  }, [player]);

  useDeepCompareEffect(() => {
    setTranscriptionsQueue((oldQueue) =>
      [...oldQueue, ...captions].sort((a, b) => a.startTime - b.startTime),
    );
  }, [captions]);

  // Effect to initialize the stream start time offset when IOS and mobile/chrome
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    ...createIosMobileEffect({
      src,
      setIosStartOffset,
      isPlaying,
      ivsPlayer,
    }),
  );

  // Callback which will remove all currently active cues on a text track
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const removeCues = useCallback(
    ...createRemoveCues({
      textTrackRef,
    }),
  );

  // Callback which will add new cues to our current text track
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const createNewCueAndAddToTrack = useCallback(
    ...createNewCueAndAddToTrackCallback({
      textTrackRef,
      removeCues,
    }),
  );

  // Callback which will remove all transcriptions from the queue which fall behind current player position
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const removeTranscriptionsFromQueue = useCallback(
    ...createRemoveTranscriptionsFromQueue({
      setTranscriptionsQueue,
    }),
  );

  // Callback which shifts off the first item from the transcriptions queue
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const shiftTranscriptionsQueue = useCallback(
    ...createShiftTranscriptionQueue({
      setTranscriptionsQueue,
    }),
  );

  const showCaption = useCallback(
    (startTime: number, endTime: number, text: string) => {
      createNewCueAndAddToTrack(startTime, endTime, text);
      shiftTranscriptionsQueue();
    },
    [createNewCueAndAddToTrack, shiftTranscriptionsQueue],
  );

  // Updater function which will show the current transcription caption
  const updateCaptions = createUpdateCaptions({
    iosStartOffset,
    disabled,
    ivsPlayer,
    transcriptionsQueue,
    showCaption,
    removeTranscriptionsFromQueue,
    shiftTranscriptionsQueue,
  });

  const latestUpdate = useLatest(updateCaptions);
  const enabled = !!ivsPlayer && !disabled && supportedLanguages.length > 0;
  useEffect(() => {
    if (!enabled) return;
    const interval = window.setInterval(
      () => latestUpdate.current(),
      CAPTION_UPDATE_INTERVAL_MS,
    );
    return () => {
      window.clearInterval(interval);
    };
  }, [enabled, latestUpdate]);

  const clearCaptions = useCallback(() => {
    setTranscriptionsQueue([]);
    removeCues();
  }, [removeCues]);

  // Clear current captions which are not part of the current language on language changes
  useEffect(() => {
    removeCues();
    setTranscriptionsQueue((queue) =>
      queue.filter(({ language }) => language !== currentLanguage),
    );
  }, [currentLanguage, removeCues]);

  const latestLanguageChange = useLatest(onLanguageChange);
  const playerId = playerRef.current?.id();
  useDeepCompareEffect(() => {
    const prevPlayer = playerRef.current;
    // Do not attempt to add handlers to a disposed player
    if (!enabled || !prevPlayer || prevPlayer?.isDisposed()) return;
    function onCaptionChange(this: TextTrackList) {
      textTrackRef.current = ((this as any).tracks_ as TextTrack[]).find(
        (track) => track.kind === "captions" && track.mode === "showing",
      );
      const lang = textTrackRef.current?.language;
      latestLanguageChange.current?.(lang);
      setCurrentLanguage(lang);
    }

    const onPlaying = () => {
      setIsPlaying(true);
      setSrc(player?.src());
    };

    const onNotPlaying = () => {
      setIsPlaying(false);
      setSrc(player?.src());
    };

    const cleanup = () => {
      prevPlayer?.textTracks().removeEventListener("change", onCaptionChange);
      prevPlayer?.off("playing", onPlaying);
      prevPlayer?.off("play", onPlaying);
      prevPlayer?.off("error", onNotPlaying);
      prevPlayer?.off("ended", onNotPlaying);
      prevPlayer?.off("pause", onNotPlaying);
      prevPlayer?.off("dispose", onNotPlaying);
      tracks.forEach((t) => prevPlayer?.removeRemoteTextTrack(t));
      if (textTrackRef.current) {
        textTrackRef.current.mode = "disabled";
      }
      textTrackRef.current = undefined;
    };

    prevPlayer.on("playing", onPlaying);
    prevPlayer.on("play", onPlaying);
    prevPlayer.on("error", onNotPlaying);
    prevPlayer.on("ended", onNotPlaying);
    prevPlayer.on("pause", onNotPlaying);
    prevPlayer.on("dispose", cleanup);
    prevPlayer.textTracks().addEventListener("change", onCaptionChange);
    // Immediately fire to get the currently selected tracks
    setTimeout(onCaptionChange.bind(prevPlayer.textTracks()), 0);

    const tracks = supportedLanguages.map((lang) => {
      const newTrack = prevPlayer.addRemoteTextTrack(
        {
          kind: "captions",
          language: lang,
          default: initialLanguage === lang,
        },
        true,
      );
      if (newTrack?.track) {
        newTrack.track.mode = initialLanguage === lang ? "showing" : "disabled";
      }
      return newTrack;
    });

    setIsPlaying(!prevPlayer?.paused?.());

    return () => {
      cleanup();
    };
    // trigger on player ID changes only since that indicates the player has changed
  }, [playerId, supportedLanguages, enabled]);

  return {
    clearCaptions,
  };
};

export default useLiveClosedCaptions;

export const __testable__ = {
  CAPTIONS_MAX_DISPLAY_TIME_S,
  createIosMobileEffect,
  createNewCueAndAddToTrackCallback,
  createRemoveCues,
  createRemoveTranscriptionsFromQueue,
  createShiftTranscriptionQueue,
  createUpdateCaptions,
};
