import useGqlSubscription from "@src/graphql/hooks/useGqlSubscription";
import { EventCloseCaption } from "@src/types/graphQl";
import { useEvent } from "@src/providers/EventProvider";

import { SUBSCRIBE_EVENT_CLOSE_CAPTIONS } from "./graphql";
import { createLiveClosedCaptionKey } from "./queryKeys";

interface UseClosedCaptionsSubscriptionArgs {
  /**
   * The language for the captions
   */
  language: string | null | undefined;
  /**
   * Flag indicating whether the subscription should be disabled, defaults to `false`
   */
  disabled?: boolean;
}

/**
 * Starts a subscription which listens for live closed captions for the given language
 */
export const useClosedCaptionsSubscription = ({
  language,
  disabled,
}: UseClosedCaptionsSubscriptionArgs) => {
  const { data: event } = useEvent();
  const enabled = Boolean(!disabled && event?.uid && language);
  const { data } = useGqlSubscription<EventCloseCaption>({
    queryKey: createLiveClosedCaptionKey(
      event?.uid as string,
      language as string,
    ),
    query: SUBSCRIBE_EVENT_CLOSE_CAPTIONS,
    variables: {
      eventId: event?.uid as string,
      language,
    },
    enabled,
  });
  return data?.captions ?? [];
};

export default useClosedCaptionsSubscription;
