import {
  DependencyList,
  MutableRefObject,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useEvent, useEventStatus } from "../EventProvider";
import { useUser } from "../UserProvider";
import { useLatest } from "react-use";
import { getChannelConfig } from "../chat/getChannelConfig";
import useChannelListeners from "../pubnub/useChannelListeners";
import { ChannelTypeEnum } from "../pubnub/types";
import { usePubNub } from "../pubnub/PubNubProvider";
import { useTheme } from "@mui/material/styles";
import throttle from "lodash/throttle";
import { LiveReaction } from "@src/contracts/customization/theme";
import { useSpamCallback } from "@src/hooks/useSpamCallback";
import { FeatureFlag, useFeatureFlag } from "../FeatureFlagsProvider";
import { useIsLiveReactionsEnabled } from "../EventStateProvider";
import { EventStatus } from "@src/contracts/event/event";
import { Api } from "@src/api/api";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query";
import { QueryKeys } from "@src/api/QueryKeys";
import { toast } from "react-toastify";
import { useRouteParams } from "@src/hooks/useRouteParams";

export interface UseEnableLiveReactionsOptions
  extends UseMutationOptions<
    EventStatus,
    unknown,
    boolean,
    { previousEventStatus?: EventStatus }
  > {}

export interface UseEnableLiveReactionsValue {
  /**
   * Whether live reactions are currently enabled in the `EventStatus` object
   */
  isLiveReactionsOn: boolean;
  /**
   * Enables live reactions on the `EventStatus` object
   */
  enableLiveReactions: (enabled: boolean) => Promise<EventStatus>;
  /**
   * The `react-query` `mutation` object responsible for enabling live reactions
   */
  mutation: UseMutationResult<
    EventStatus,
    unknown,
    boolean,
    {
      previousEventStatus?: EventStatus | undefined;
    }
  >;
}

/**
 * Hook to retrieve and enable/disable live reactions on the `EventStatus` object
 */
export const useEnableLiveReactions = (
  eventId: string,
  options: UseEnableLiveReactionsOptions = {},
): UseEnableLiveReactionsValue => {
  const { data: status } = useEventStatus();
  const isLiveReactionsOn = status?.isLiveReactionsOn ?? true;

  const { onError, ...rest } = options;

  const queryClient = useQueryClient();
  const queryKey = QueryKeys.eventStatus(eventId);

  const mutation = useMutation<
    EventStatus,
    unknown,
    boolean,
    { previousEventStatus?: EventStatus }
  >((enabled: boolean) => Api.EventApi.EnableLiveReactions(eventId, enabled), {
    // Optimistically update event status value
    onMutate: (enabled: boolean) => {
      // cancel existing queries
      queryClient.cancelQueries(queryKey);

      // update local event status
      const previousEventStatus =
        queryClient.getQueryData<EventStatus>(queryKey);

      const updatedEventStatus = {
        ...previousEventStatus,
        isLiveReactionsOn: enabled,
      } as EventStatus;

      // optimistically perform local update
      queryClient.setQueryData<EventStatus>(queryKey, updatedEventStatus);

      return { previousEventStatus };
    },
    // Rollback on error
    onError: (err, variables, context) => {
      // reset optimistic update
      queryClient.setQueryData<EventStatus | undefined>(
        queryKey,
        context?.previousEventStatus,
      );
      onError?.(err, variables, context);
    },
    onSettled: () => {
      // invalidate the event status query
      queryClient.invalidateQueries(queryKey);
    },
    ...rest,
  });

  const enableLiveReactions = useCallback(
    (enabled: boolean) => mutation.mutateAsync(enabled),
    [mutation],
  );

  return {
    mutation,
    isLiveReactionsOn,
    enableLiveReactions,
  };
};

export interface UseLiveReactionsToggleValue
  extends Pick<UseEnableLiveReactionsValue, "enableLiveReactions"> {
  /**
   * Whether live reactions are enabled. Only true if live reactions are enabled in the theme, feature flag and `EventStatus`.
   */
  enabled: boolean;
}

export const useLiveReactionsToggle = (): UseLiveReactionsToggleValue => {
  const { customTheme } = useTheme();
  const { eventId } = useRouteParams();

  // only for events
  const { isLiveReactionsOn, enableLiveReactions } = useEnableLiveReactions(
    eventId as string,
    {
      onSuccess: (status, enabled) => {
        toast.success(
          `Live reactions are now ${enabled ? "enabled" : "disabled"}`,
        );
      },
      onError: () => {
        toast.error("An error occurred toggling live reactions");
      },
    },
  );

  const enabled = !!(isLiveReactionsOn && customTheme?.liveReactions?.enabled);

  return {
    enabled,
    enableLiveReactions,
  };
};

export const useIsLiveReactionsFeatureEnabled = () => {
  const { customTheme } = useTheme();
  const flagEnabled = !!useFeatureFlag(FeatureFlag.EVENT_LIVE_REACTIONS);

  return flagEnabled && !!customTheme?.liveReactions?.enabled;
};

export interface LiveReactionMessage {
  type: ChannelTypeEnum;
  reaction: string;
  count: number;
}

export interface LiveReactionMeta {
  user: {
    uid: string;
  };
}

interface UseLiveReactionsSubscription {
  /**
   * An array containing the currently available live reactions
   */
  liveReactions: LiveReaction[];

  /**
   * Flag indicating if the live reactions feature is enabled
   */
  enabled: boolean;
  /**
   * A function which publishes live reactions
   */
  publishLiveReaction: (reaction: string) => void;
}

interface UseLiveReactionsArgs {
  /**
   * Callback invoked with a list of the latest live reactions
   */
  onLiveReactions?: (reactions: LiveReactionMessage[]) => void;
}

const createThrottleReactionsCb = (args: {
  latestOnReactions: MutableRefObject<
    ((reactions: LiveReactionMessage[]) => void) | undefined
  >;
  bufferRef: MutableRefObject<LiveReactionMessage[]>;
}): [() => void, DependencyList] => [
  throttle(() => {
    // We use a buffer to batch reactions to avoid spam updates
    // as the messages come in one at a time
    args.latestOnReactions.current?.(args.bufferRef.current);
    args.bufferRef.current = [];
  }, 200),
  Object.values(args),
];

const createHandleLiveReactions = (args: {
  userId: string;
  bufferRef: MutableRefObject<LiveReactionMessage[]>;
  mountTimeRef: MutableRefObject<number>;
  throttledUpdate: () => void;
}): [
  (event: {
    message: LiveReactionMessage;
    userMetadata?: LiveReactionMeta;
    timetoken: string;
  }) => void,
  DependencyList,
] => [
  (event: {
    message: LiveReactionMessage;
    userMetadata?: LiveReactionMeta;
    timetoken: string;
  }) => {
    // We are tricking the user and showing their messages no matter what
    if (event.userMetadata?.user?.uid === args.userId) return;
    // TODO: Pubnub is caching messages and sending them when we reconnect
    // the subscription, so we need to take the current mount time of the hook
    // and ensure we don't get old messages
    // Oh, and they send the timetoken in 17-digit precision so we need to convert to milliseconds...
    const millis = Math.floor(Number(event.timetoken) / 1e4);
    if (millis < args.mountTimeRef.current) return;
    args.bufferRef.current.push(event.message);
    args.throttledUpdate();
  },
  Object.values(args),
];

const usePublishLiveReaction = ({
  channel,
  userId,
  latestOnReactions,
}: {
  channel: string;
  userId: string | undefined;
  latestOnReactions: MutableRefObject<
    ((reactions: LiveReactionMessage[]) => void) | undefined
  >;
}) => {
  const publishBuffer = useRef<Record<string, number>>({});
  const pubnub = usePubNub();

  // Wrap w/ spam detection to avoid flooding the channel
  // we will batch these and send in a single publish per reaction w/ counts
  const pubnubPublish = useSpamCallback(
    () => {
      Object.entries(publishBuffer.current).forEach(([reaction, count]) => {
        pubnub?.publish({
          channel,
          message: {
            type: ChannelTypeEnum.LIVE_REACTION,
            reaction,
            count,
          },
          meta: {
            user: {
              uid: userId,
            },
          },
        });
      });
      publishBuffer.current = {};
    },
    { throttleMs: 500 },
  );

  return useCallback(
    (reaction: string) => {
      // Immediately call the onLiveReactions callback to
      // trick the user so it seems their reactions are non-stop
      // and instant
      latestOnReactions.current?.([
        {
          type: ChannelTypeEnum.LIVE_REACTION,
          reaction,
          count: 1,
        },
      ]);
      publishBuffer.current[reaction] =
        (publishBuffer.current[reaction] ?? 0) + 1;
      pubnubPublish();
    },
    [pubnubPublish, latestOnReactions],
  );
};

export const useLiveReactions = (
  args: UseLiveReactionsArgs = {},
): UseLiveReactionsSubscription => {
  const user = useUser();
  const { onLiveReactions } = args;
  const { data: event } = useEvent();
  const enabled = useIsLiveReactionsEnabled();
  const { customTheme } = useTheme();
  const bufferRef = useRef<LiveReactionMessage[]>([]);
  const mountTimeRef = useRef(Date.now() - 1000);

  const latestOnReactions = useLatest(onLiveReactions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledUpdate = useCallback(
    ...createThrottleReactionsCb({
      latestOnReactions,
      bufferRef,
    }),
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onNewReactions = useCallback(
    ...createHandleLiveReactions({
      userId: user.uid,
      bufferRef,
      mountTimeRef,
      throttledUpdate,
    }),
  );

  const listeners = useMemo(
    () => ({
      message: onNewReactions,
    }),
    [onNewReactions],
  );

  const channel = getChannelConfig({
    type: "live-reactions",
    event: event?.uid as string,
  }).channel;

  useChannelListeners({
    disabled: !event?.uid || !enabled || !onLiveReactions,
    channel,
    listeners,
  });

  const publishLiveReaction = usePublishLiveReaction({
    channel,
    userId: user?.uid,
    latestOnReactions,
  });

  return {
    liveReactions: customTheme?.liveReactions?.reactions ?? [],
    enabled,
    publishLiveReaction,
  };
};

export const __testable__ = {
  createHandleLiveReactions,
  createThrottleReactionsCb,
};
