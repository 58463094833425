import React, { useEffect } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { CircularProgress, Dialog, DialogContent, Box } from "@mui/material";

import { CodeInput, useValidateCode } from "../Forms/CodeInput";
import { TabTitle } from "../TabTitle";
import { UserRole } from "../../contracts/user/user";
import { BaseButton } from "../buttons/BaseButton";
import { RegistrationType } from "../../contracts/event/event";
import { useSetUserRole } from "../../providers/UserProvider";
import { useCanJoin } from "../accessGuards/useCanJoin";
import { useEvent } from "../../providers/EventProvider";
import { validateEnvironment } from "@src/helpers/validateEnvironment";
import ConnectionErrors, {
  connectionErrorOpenOptions,
} from "../dialogs/content/ConnectionErrors";
import { useDialog } from "@src/providers/DialogProvider";
import { useRouteParams } from "@src/hooks/useRouteParams";
export interface PasscodeModalProps {
  show: boolean;
  onClose: () => void;
}

export const EnterPasscodeModal: React.FC<PasscodeModalProps> = ({
  show,
  onClose,
}) => {
  const { openDialog } = useDialog();
  const { data: event } = useEvent();
  const setUserRole = useSetUserRole();
  const { eventId, networkingHubId } = useRouteParams();
  const { checkCanJoin, handleJoinAsHostBlocked } = useCanJoin({
    eventId,
    networkingHubId: event?.networkingHub,
  });

  const { refetch: refetchCheckCanJoin } = checkCanJoin;

  const registrationType = networkingHubId
    ? RegistrationType.NETWORKING_HUB
    : RegistrationType.EVENT;

  const uid = eventId || networkingHubId;

  const { clearValidatedRole, validateCode, validatedUserRole } =
    useValidateCode(registrationType);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: { code: "" },
    validationSchema: Yup.object({
      code: Yup.string().when(
        "showCodeInput",
        (showCodeInput: boolean, schema: any) => {
          return Yup.string().test(
            "code",
            "${message}", // eslint-disable-line no-template-curly-in-string
            async (value, testContext) => validateCode(testContext, uid, value),
          );
        },
      ),
    }),
    onSubmit: async (values, { setFieldError }) => {
      // check if user's environment satisfies requirements
      const { errors, hasErrors } = await validateEnvironment(
        validatedUserRole,
      );

      if (hasErrors) {
        setFieldError("code", "Please resolve network or permission issues"); // disable button
        openDialog(
          "ConnectionErrors",
          <ConnectionErrors errors={errors} />,
          connectionErrorOpenOptions,
        );
        return;
      }

      if (validatedUserRole === UserRole.Organizer) {
        const { data } = await refetchCheckCanJoin();
        const canJoinAsHost = data?.host.canJoin;
        if (canJoinAsHost === false) {
          // show error message
          setFieldError("code", "The maximum host limit has been reached");
          handleJoinAsHostBlocked({ showModal: false });
          return;
        }
      }
      setUserRole(validatedUserRole);
      onClose();
    },
  });
  const { errors, isValidating, isSubmitting } = form;

  const submissionDisabled =
    !form.dirty || isValidating || isSubmitting || !!errors.code;
  const loading = isValidating || isSubmitting;

  const loadingIndicator = loading ? (
    <div
      style={{
        position: "relative",
        left: "-20px",
        display: "inline-flex",
        top: ".25em",
      }}
    >
      <CircularProgress color="secondary" size={20} />
    </div>
  ) : null;

  let submitButtonText = loading ? "Validating Code" : "Join Backstage";
  if (validatedUserRole === UserRole.Organizer) {
    submitButtonText += ` as Host`;
  } else if (validatedUserRole === UserRole.Presenter) {
    submitButtonText += ` as Presenter`;
  }

  useEffect(() => {
    form.setFieldValue("code", "");
    clearValidatedRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <Dialog open={show} onClose={onClose}>
      <DialogContent>
        <FormikProvider value={form}>
          <Form>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
            >
              <TabTitle>{"Enter Secret Code"}</TabTitle>

              <CodeInput
                name="code"
                id="code"
                label="Type your 6-digit passcode here:"
                type={registrationType}
              />

              <BaseButton
                type="submit"
                variant="contained"
                color="primary"
                disabled={submissionDisabled}
                style={{
                  borderRadius: "40px",
                  color: "white",
                  padding: "1em",
                  width: "80%",
                }}
                onClick={() => form.handleSubmit}
              >
                <>
                  {loadingIndicator}
                  {submitButtonText}
                </>
              </BaseButton>
            </Box>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
};
