/** @jsxImportSource @emotion/react */
import React from "react";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";

import { ClassNames } from "@emotion/react";
import { CardActions, CardContent, Popover } from "@mui/material";

import { DangerButton } from "../../buttons/DangerButton";
import { BaseButton } from "../../buttons/BaseButton";

export const EndEventButton = (props: {
  hasNetworking?: boolean;
  onConfirm?: (startNetworking: boolean) => void;
}) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: `mingle-menu`,
  });

  return (
    <ClassNames>
      {({ css }) => (
        <>
          <DangerButton {...bindTrigger(popupState)}>End Session</DangerButton>

          <Popover
            classes={{
              root: css`
                margin-top: 20px;
              `,
              paper: css`
                padding: 8px;
              `,
            }}
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <CardContent
              classes={{
                root: css`
                  font-weight: 700;
                `,
              }}
            >
              Are you sure you want to end this session? You can't undo this
              action.
            </CardContent>
            <CardActions
              css={css`
                display: flex;
                justify-content: flex-end;
              `}
            >
              <BaseButton color="inherit" onClick={popupState.close}>
                Cancel
              </BaseButton>

              <DangerButton
                onClick={() => {
                  popupState.close();
                  props.onConfirm?.(false);
                }}
              >
                End Session
              </DangerButton>

              {props.hasNetworking && (
                <DangerButton
                  onClick={() => {
                    popupState.close();
                    props.onConfirm?.(true);
                  }}
                >
                  End Session and Start Networking
                </DangerButton>
              )}
            </CardActions>
          </Popover>
        </>
      )}
    </ClassNames>
  );
};
