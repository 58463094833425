import React from "react";
import {
  styled,
  Menu,
  MenuProps,
  Divider,
  MenuItem as MuiMenuItem,
} from "@mui/material";

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: theme.spacing(1.5),
    borderBottomRightRadius: 0,
    background: "white",
    "& .MuiMenuItem-root": {
      "&:hover": {
        backgroundColor: `${theme.palette.primary.main}0a`,
      },
      "&:active": {
        backgroundColor: `${theme.palette.primary.main}40`,
      },
    },
  },
}));

export interface MenuItem {
  label: string;
  color?: "danger";
  onClick: () => void;
}

interface ChatItemMenuProps extends Omit<MenuProps, "children"> {
  items: MenuItem[];
}

export const ChatItemMenu = React.memo(function ChatItemMenu({
  items,
  ...props
}: ChatItemMenuProps) {
  if (!items.length) return null;

  return (
    <StyledMenu
      data-testid="chat-item-menu"
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      {...props}
    >
      {items.map(({ label, color, onClick }, index) => (
        <div key={label}>
          {index !== 0 && <Divider />}
          <MuiMenuItem
            data-testid={`chat-message-menu-item-${label}`}
            onClick={(event) => {
              onClick();
              props.onClose?.(event, "backdropClick");
            }}
            sx={{
              color: color === "danger" ? "error.main" : undefined,
            }}
          >
            {label}
          </MuiMenuItem>
        </div>
      ))}
    </StyledMenu>
  );
});
