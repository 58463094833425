import React, { memo } from "react";

import { css } from "@emotion/react";
import { Box, Dialog, Divider, IconButton, SvgIcon } from "@mui/material";

import { TabTitle } from "../TabTitle";
import { CloseIcon } from "../icons";
import { CircleUserList } from "./CircleUserList";

interface NetworkingCircleUsersDialogProps {
  onClose: () => void;
  open: boolean;
  users: { userId: string; avatar: string; username: string }[];
}

export const NetworkingCircleUsersDialog = memo(
  ({ onClose, open, users }: NetworkingCircleUsersDialogProps) => (
    <Dialog
      onClose={onClose}
      onClick={(event) => {
        event.stopPropagation();
      }}
      open={open}
      css={() => css`
        .MuiDialog-paper {
          max-width: 720px;
        }
      `}
      fullWidth
      scroll="body"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <TabTitle>{`Attendees (${users.length})`}</TabTitle>
        <IconButton
          color="secondary"
          onClick={(event) => {
            event.stopPropagation();
            onClose();
          }}
          size="large"
        >
          <SvgIcon>
            <CloseIcon />
          </SvgIcon>
        </IconButton>
      </Box>
      <Divider />
      <Box>
        <CircleUserList
          users={users}
          css={(theme) => css`
            padding: 0 ${theme.spacing(2)};
          `}
        />
      </Box>
    </Dialog>
  ),
);
