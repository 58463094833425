import React, { useCallback } from "react";
import { useDialog } from "./useDialog";
import { StyledIconButton, Wrapper, StyledMuiDialog } from "./Dialog.styles";
import { DialogProps as MuiDialogProps } from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { DialogQueueItem } from "./DialogContext";

/**
 * _NOTE_ All dialogs to be used with the `DialogProvider` **should** extend this interface
 */
export interface DialogContentProps {
  /**
   * The `id` of the dialog. This is automatically passed by the `DialogProvider` and is used for closing the dialog.
   *
   * It is the same `id` passed to `openDialog`.
   */
  dialogId?: string;
}

const Dialog = () => {
  const { isOpen, hideDialog, currentDialog } = useDialog();

  const {
    id,
    content,
    disableOutsideClick = false,
    hideCloseIcon = false,
    onClose,
    // remove these so they don't get added to the DOM
    internalId,
    priority,
    ...restProps
  } = currentDialog || ({} as DialogQueueItem);

  const handleClose = useCallback<NonNullable<MuiDialogProps["onClose"]>>(
    (event, reason) => {
      // if clicked outside and outside clicking disabled => don't close
      if (
        (reason === "backdropClick" || reason === "escapeKeyDown") &&
        disableOutsideClick
      )
        return;

      onClose?.(event, reason);
      hideDialog(id as string);
    },
    [disableOutsideClick, onClose, hideDialog, id],
  );

  if (!currentDialog) return null;

  return (
    <StyledMuiDialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="main-dialog"
      {...restProps}
    >
      <Wrapper>
        {!hideCloseIcon && (
          <StyledIconButton
            data-testid="main-dialog-close-icon"
            aria-label="upload picture"
            onClick={handleClose as any}
          >
            <CloseIcon />
          </StyledIconButton>
        )}
        {content}
      </Wrapper>
    </StyledMuiDialog>
  );
};

export default Dialog;
