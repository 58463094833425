import { Alert, Box, styled } from "@mui/material";

export const Container = styled(Box)(() => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  alignItems: "stretch",
  minHeight: 0,
}));

export const MutedAlert = styled(Alert)(() => ({
  cursor: "pointer",
  alignItems: "center",
  justifyContent: "center",
}));
