import { ApiConfig } from "@src/api/api-config";
import { UserRole } from "@src/contracts/user/user";
import {
  isCookieStorageEnabled,
  isLocalStorageEnabled,
} from "@src/helpers/browser";

export enum Errors {
  API_UNAVAILABLE = "We are unable to establish a connection to the server at this time. Please check your network connection and try again. If the issue persists, please contact your network administrator.",
  LOCAL_STORAGE_DISABLED = "Local storage is disabled.",
  COOKIES_DISABLED = "Third party cookies are disabled.",
  COOKIES_OR_LOCAL_STORAGE_DISABLED = "For an optimal experience, please enable third party cookies and browser storage.",
}

export const VALIDATE_ENVIRONMENT_DEBOUNCE_MS = 2000;

/**
 * Function that validates the user's environment based on their role
 *
 * Checks include:
 * - `isApiAvailable` (all users)
 * - `isLocalStorageEnabled` (hosts and presenters)
 * - `isCookiesEnabled` (hosts and presenters)
 */
export const validateEnvironment = async (
  role: UserRole = UserRole.Viewer,
): Promise<{
  errors: string[];
  /** boolean value derived from `errors` for convenience */
  hasErrors: boolean;
  isApiAvailable?: boolean;
  isLocalStorageEnabled?: boolean;
  isCookiesEnabled?: boolean;
}> => {
  const errors: string[] = [];

  const result = {
    isApiAvailable: await ApiConfig.isApiAvailable(),
    isLocalStorageEnabled: isLocalStorageEnabled(),
    isCookiesEnabled: isCookieStorageEnabled(),
  };

  /* CHECKS FOR ALL USERS */
  // check if the api is available
  if (!result.isApiAvailable) {
    errors.push(Errors.API_UNAVAILABLE);
  }

  /* CHECKS FOR HOST AND PRESENTERS */
  if ([UserRole.Organizer, UserRole.Presenter].includes(role)) {
    // check if localStorage is enabled
    if (!result.isLocalStorageEnabled) {
      errors.push(Errors.LOCAL_STORAGE_DISABLED);
    }

    // check if cookies or localstorage are enabled
    if (!result.isCookiesEnabled && !result.isLocalStorageEnabled) {
      errors.push(Errors.COOKIES_OR_LOCAL_STORAGE_DISABLED);
    }
  }

  return {
    ...result,
    errors,
    hasErrors: !!errors.length,
  };
};
