import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { User } from "../../contracts/user/user";
import { useEvent } from "../../providers/EventProvider";
import { MeetingIcon } from "../icons";
import { useCanJoin } from "./useCanJoin";

const ErrorPage = () => (
  <Dialog open fullScreen>
    <DialogContent
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <MeetingIcon />
      <DialogTitle
        variant="h1"
        sx={{
          mt: 4,
          mb: 2,
        }}
      >
        Sorry! This event is currently at capacity.
      </DialogTitle>
      <DialogContentText variant="h3">
        Please check back later
      </DialogContentText>
    </DialogContent>
  </Dialog>
);

interface Props {
  eventId?: string;
  networkingHubId?: string;
  user: User;
}

const UserLimitGuard = ({
  children,
  eventId,
  networkingHubId,
  user,
}: React.PropsWithChildren<Props>) => {
  const { data: event } = useEvent();
  const { checkCanJoin, showUserLimitGuard } = useCanJoin({
    eventId,
    networkingHubId: networkingHubId || event?.networkingHub,
  });

  // render error page if user can't join
  if (
    checkCanJoin.data?.attendee?.canJoin === false &&
    showUserLimitGuard.show
  ) {
    return <ErrorPage />;
  }

  return <>{children}</>;
};

export default React.memo(UserLimitGuard);
