import orderBy from "lodash/orderBy";
import { UserQuestion } from "./types";

export const sortQuestions = (questions: UserQuestion[]): UserQuestion[] => {
  return orderBy(questions, ["votes", "createdAt"], ["desc", "desc"]);
};

export const createQuestionsQueryKey = (
  eventId?: string | null,
  circleId?: string,
) => ["event-question", eventId, circleId];
