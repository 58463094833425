import React, { memo, useCallback, useMemo, useState } from "react";
import { useUpdateEffect } from "react-use";

import { SocketUserMeta } from "../../Presence/IntrovokePresence";
import { PollListItemOptions } from "../PollListItemOptions";
import { PollListItemResults } from "../PollListItemResults";
import { extractUidFromUserId } from "../../../helpers/utils";
import {
  PollResultsType,
  PollStatus,
} from "../../../providers/polls/PollProvider";
import { ParticipantNameOption } from "../../../contracts/enums/participant-name-options";
import { PollListItemHeader } from "../PollListItemHeader/PollListItemHeader";
import {
  ActionButton,
  ButtonsWrapper,
  StyledContainer,
  StyledDescription,
  StyledInfoText,
} from "./PollListItem.styles";
import PollToastVoted from "../PollToastVoted";

export interface PollOption {
  id: string;
  name: string;
  votes: number;
  selected: boolean;
}

export interface PollListItemProps {
  id: string;
  createdAt: number;
  updatedAt: number;
  name: string;
  description?: string;
  owner: SocketUserMeta;
  hidden?: boolean;
  options: PollOption[];
  status: PollStatus;
  resultsPublished: boolean;
  resultsType: PollResultsType;
  user: string;
  isOrganizer: boolean;
  isUpdatePending?: boolean;
  showParticipantNames: ParticipantNameOption;
  navigateToTab: (tab: PollStatus) => void;
  onVote?: (data: { poll: string; option: string }) => void;
  onDelete?: (data: { poll: string }) => void;
  onUpdatePollStatus?: (data: { poll: string; status: PollStatus }) => void;
  onPublishPollResults: (data: { poll: string; publish?: boolean }) => void;
}

export const PollListItem: React.FC<PollListItemProps> = memo(
  ({
    id,
    createdAt,
    updatedAt,
    status,
    name,
    description,
    owner,
    options,
    resultsPublished,
    resultsType,
    user,
    isOrganizer,
    isUpdatePending,
    showParticipantNames,
    navigateToTab,
    onVote,
    onDelete,
    onUpdatePollStatus,
    onPublishPollResults,
  }) => {
    const [showVotedToast, setVotedToast] = useState(false);
    const hasVoted = useMemo(
      () => options.some((option) => option.selected),
      [options],
    );
    const userId = extractUidFromUserId(user);
    const isOwner = userId === owner.userId;
    const isPending = status === PollStatus.PENDING;
    const isOpen = status === PollStatus.ACTIVE;
    const isClosed = status === PollStatus.CLOSED;
    const isArchived = status === PollStatus.ARCHIVED;

    const [showResults, setShowResults] = React.useState(
      isArchived ||
        (!isPending &&
          ((resultsPublished && (hasVoted || isClosed)) || isOwner)),
    );

    useUpdateEffect(() => {
      if (isArchived || (resultsPublished && (hasVoted || isClosed))) {
        // When the poll is archived or results are published, then show results
        setShowResults(true);
      }
    }, [resultsPublished, hasVoted, isClosed, isArchived]);

    const handleVote = useCallback(
      (option: string) => {
        onVote?.({
          poll: id,
          option,
        });
        setVotedToast(true);
        setTimeout(() => {
          setVotedToast(false);
        }, 5000);

        if (resultsPublished || isOwner || isOrganizer) {
          setShowResults(true);
        }
      },
      [id, onVote, resultsPublished, isOwner, isOrganizer],
    );

    const openPoll = useCallback(() => {
      onUpdatePollStatus?.({ poll: id, status: PollStatus.ACTIVE });
      setShowResults(isOwner);
      navigateToTab(PollStatus.ACTIVE);
    }, [id, onUpdatePollStatus, isOwner, navigateToTab]);

    const closePoll = useCallback(() => {
      onUpdatePollStatus?.({ poll: id, status: PollStatus.CLOSED });
      setShowResults(true);
    }, [id, onUpdatePollStatus]);

    const archivePoll = useCallback(() => {
      onUpdatePollStatus?.({ poll: id, status: PollStatus.ARCHIVED });
      setShowResults(true);
    }, [id, onUpdatePollStatus]);

    const publishResults = useCallback(() => {
      onPublishPollResults?.({ poll: id });
      setShowResults(true);
    }, [id, onPublishPollResults]);

    if (!isOpen && !isClosed && !isOrganizer && !isOwner) {
      // Poll is not active and user doesn't have permissions to see it
      return null;
    }

    const hideUserInfo = showParticipantNames === ParticipantNameOption.NONE;

    return (
      <StyledContainer
        data-testid="polls-list-item"
        isPending={isUpdatePending}
      >
        <PollToastVoted
          open={showVotedToast}
          isOrganizer={isOrganizer}
          resultsPublished={resultsPublished}
        />
        <PollListItemHeader
          id={id}
          name={name}
          createdAt={createdAt}
          updatedAt={updatedAt}
          hasVoted={hasVoted}
          isArchived={isArchived}
          isOwner={isOwner}
          isOrganizer={isOrganizer}
          isOpen={isOpen}
          isClosed={isClosed}
          isPending={isPending}
          resultsPublished={resultsPublished}
          showResults={showResults}
          owner={owner}
          onDelete={onDelete}
          hideUserInfo={hideUserInfo}
          navigateToTab={navigateToTab}
          archivePoll={archivePoll}
          openPoll={openPoll}
          closePoll={closePoll}
          setShowResults={setShowResults}
        />

        {description && <StyledDescription>{description}</StyledDescription>}

        {!showResults && (
          <>
            <PollListItemOptions
              options={options}
              status={status}
              hasVoted={hasVoted}
              isHost={isOrganizer}
              onVote={handleVote}
              onOpenPoll={openPoll}
            />

            {isOpen && !(isOwner || isOrganizer) && resultsPublished && (
              // @ts-ignore
              <StyledInfoText align="right" variant="caption" component="div">
                Vote to see results
              </StyledInfoText>
            )}
          </>
        )}

        {showResults && (
          <div data-testid="poll-results-chart">
            <PollListItemResults options={options} resultsType={resultsType} />

            <ButtonsWrapper>
              {!resultsPublished && (isOwner || isOrganizer) && (
                <ActionButton
                  variant="contained"
                  color="primary"
                  onClick={publishResults}
                >
                  Publish Results
                </ActionButton>
              )}
            </ButtonsWrapper>
          </div>
        )}
      </StyledContainer>
    );
  },
);
