/** @jsxImportSource @emotion/react */

import React from "react";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";

import { ClassNames } from "@emotion/react";
import { CardActions, CardContent, Popover } from "@mui/material";
import { BaseButton } from "../../buttons/BaseButton";
import { ConfirmButton } from "../../buttons/ConfirmButton";
import FeatureLockedDialog from "../../dialogs/FeatureLockedDialog/FeatureLockedDialog";

export const StartEventButton = (props: {
  onConfirm?: () => void;
  goLiveLocked: boolean;
  disabled?: boolean;
}) => {
  const { onConfirm, goLiveLocked = false, disabled } = props;

  const popupState = usePopupState({
    variant: "popover",
    popupId: `mingle-menu`,
  });

  return (
    <ClassNames>
      {({ css }) => (
        <>
          <ConfirmButton disabled={disabled} {...bindTrigger(popupState)}>
            Go Live
          </ConfirmButton>
          {goLiveLocked ? (
            <FeatureLockedDialog
              {...bindPopover(popupState)}
              title="Unable To Go Live"
            />
          ) : (
            <Popover
              classes={{
                root: css`
                  margin-top: 20px;
                `,
                paper: css`
                  padding: 8px;
                `,
              }}
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <CardContent
                classes={{
                  root: css`
                    font-weight: 700;
                  `,
                }}
              >
                Are you sure you want to go live?
              </CardContent>
              <CardActions
                css={css`
                  display: flex;
                  justify-content: flex-end;
                `}
              >
                <BaseButton color="secondary" onClick={popupState.close}>
                  Cancel
                </BaseButton>

                <ConfirmButton
                  onClick={() => {
                    popupState.close();
                    onConfirm?.();
                  }}
                >
                  Yes, Go Live
                </ConfirmButton>
              </CardActions>
            </Popover>
          )}
        </>
      )}
    </ClassNames>
  );
};
