import { emitAsPromise } from "@src/components/Presence/emitAsPromise";
import { Actions } from "@src/graphql/schemas/actions";
import { useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { createPollsQueryKey, orderPolls } from "../helpers";
import {
  Poll,
  PollActionHookOptions,
  PollActionRollbackFunction,
  UpdateEventPollStatusInput,
  UserPoll,
} from "../types";

/**
 * Callback used when optimistically updating the poll locally when the poll's status is updated
 */
const updateStatusChangedPoll =
  (data: UpdateEventPollStatusInput) => (prev?: Poll[] | null) => {
    const previousPolls = Array.isArray(prev) ? prev : [];
    return orderPolls(
      previousPolls.map((poll) => {
        if (poll.id !== data.poll) return poll;

        return {
          ...poll,
          isPending: true,
          status: data.status,
        };
      }),
    );
  };

export interface UseUpdateEventPollStatusOptions
  extends PollActionHookOptions {}

export const useUpdateEventPollStatus = ({
  client,
  eventId,
  circleId,
}: UseUpdateEventPollStatusOptions) => {
  const cache = useQueryClient();
  const queryKey = createPollsQueryKey(eventId, circleId);

  /**
   * Mutation to update the status of an existing poll
   */
  const mutation = useMutation<
    Poll,
    unknown,
    UpdateEventPollStatusInput,
    PollActionRollbackFunction
  >(
    (input: UpdateEventPollStatusInput) =>
      emitAsPromise<Poll>(client, Actions.UPDATE_EVENT_POLL_STATUS, input),
    {
      onMutate: (poll) => {
        const previousPolls = cache.getQueryData<Poll[]>(queryKey);

        // optimistically update the poll's status
        cache.setQueryData<UserPoll[]>(queryKey, updateStatusChangedPoll(poll));

        return () => cache.setQueryData(queryKey, previousPolls);
      },
      onError: (err, variables, rollback) => {
        // rollback on error (success is handled by the handlePollUpdated)
        rollback?.();
        toast.error("There was an error updating the poll's status");
      },
    },
  );

  return useMemo(() => ({ mutation }), [mutation]);
};

export const __testable__ = {
  updateStatusChangedPoll,
};
