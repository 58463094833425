/** @jsxImportSource @emotion/react */

import React from "react";

import { ClassNames, css, Theme } from "@emotion/react";
import Button, { ButtonProps } from "@mui/material/Button";

import { mapClasses } from "../Event/EventStage/mapClasses";

const dangerButtonClasses = (theme: Theme) => ({
  contained: css`
    border-radius: 10px;
    background: ${theme.palette.danger.main};

    :hover {
      background: ${theme.palette.danger.main};
      box-shadow: 0 8px 25px -8px ${theme.palette.danger.main};
    }
  `,

  label: css`
    white-space: nowrap;
  `,
});

type Props = ButtonProps & {
  children?: React.ReactNode;
  onClick?: (event: React.SyntheticEvent<any, Event>) => void;
  startIcon?: React.ReactNode;
  className?: string;
};

export const DangerButton = (props: Props) => {
  return (
    <ClassNames>
      {({ css, theme }) => (
        <Button
          classes={mapClasses(dangerButtonClasses(theme), css)}
          sx={{
            borderRadius: "10px",
            background: theme.palette.danger.main,

            "&:hover": {
              background: theme.palette.danger.main,
              boxShadow: `0 8px 25px -8px ${theme.palette.danger.main}`,
            },
          }}
          variant="contained"
          color="primary"
          className={props.className}
          {...props}
        />
      )}
    </ClassNames>
  );
};
