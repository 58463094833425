import { Box, Fade, Typography } from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface PollToastVotedProps {
  open: boolean;
  resultsPublished: boolean;
  isOrganizer: boolean;
}

const PollToastVoted = ({
  open,
  resultsPublished,
  isOrganizer = false,
}: PollToastVotedProps) => {
  return (
    <Fade in={open}>
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 2,
          background: "white",
          zIndex: "drawer",
          boxShadow: "0px 2px 10px -2px rgb(0 0 0 / 10%)",
          width: "100%",
        }}
      >
        <CheckCircleIcon fontSize="large" sx={{ color: "success.main" }} />
        <Typography variant="body2" sx={{ p: 1 }}>
          Thanks for voting
        </Typography>
        <Typography>
          {!isOrganizer &&
            !resultsPublished &&
            "Results will be available once the host releases them"}
        </Typography>
      </Box>
    </Fade>
  );
};

export default PollToastVoted;
