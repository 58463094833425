import constate from "constate";
import * as t from "io-ts";
import React, { useEffect, useMemo, useState } from "react";
import { useMutation, UseQueryResult, UseMutationResult } from "react-query";
import { useLocation } from "react-router-dom";

import { parseDataToType } from "../../guards/parseDataToType";
import { useLatestCallback } from "../../hooks/useLatestCallback";
import { useNetworkingHub } from "../../providers/NetworkingHubProvider";
import { useCircle } from "../Presence/IntrovokePresence";
import { useSocketClient } from "../Presence/SocketClientProvider";
import { emitAsPromise } from "../Presence/emitAsPromise";
import {
  NetworkingHubInviteDialog,
  NetworkingHubInviteDialogProps,
} from "./NetworkingHubInviteDialog";
import { useNavigate } from "react-router-dom";
import { Actions } from "@src/graphql/schemas/actions";
import { toast } from "react-toastify";

export const CircleInvitePayloadType = t.union([
  t.type({
    type: t.literal("invite"),
    username: t.string,
    userId: t.string,
    avatar: t.string,
    expiresAt: t.number,
    circle: t.union([t.undefined, t.string]),
  }),
  t.type({
    type: t.literal("invite-rejected"),
  }),
  t.type({
    type: t.literal("invite-canceled"),
  }),
  t.type({
    type: t.literal("invite-accepted"),
    circleId: t.string,
    expiresAt: t.union([t.undefined, t.number]),
  }),
]);

export const RandomInviteSuccess = t.type({
  status: t.literal("invited"),
  private: t.union([t.undefined, t.boolean]),
  username: t.string,
  userId: t.string,
  avatar: t.string,
  expiresAt: t.number,
});

export const RandomInviteNotAvailableErrorType = t.type({
  status: t.union([
    t.literal("no-available-users"),
    t.literal("error-random-user"),
  ]),
  private: t.union([t.undefined, t.boolean]),
});
export interface RandomInviteNotAvailableError
  extends t.TypeOf<typeof RandomInviteNotAvailableErrorType> {}

export const UserNotAvailableErrorType = t.type({
  status: t.literal("user-not-available"),
  private: t.union([t.undefined, t.boolean]),
});
export interface UserNotAvailableError
  extends t.TypeOf<typeof UserNotAvailableErrorType> {}

export const RandomInviteResult = t.union([
  RandomInviteSuccess,
  RandomInviteNotAvailableErrorType,
  UserNotAvailableErrorType,
]);

export const CirclePayloadType = t.union([
  t.type({
    circleId: t.string,
  }),
  t.null,
]);

export type CircleInvite = CircleInvitePayload | CircleInviteError;

export interface CircleInvitePayload {
  status: "invite-received" | "invite-send";
  type: "incoming" | "outgoing" | "rejected" | "canceled";
  private?: boolean;
  username: string;
  userId: string;
  avatar: string;
  invitedAt: number;
  expiresAt: number;
  circle?: {
    id: string;
    name: string;
  };
}

export type CircleInviteError =
  | RandomInviteNotAvailableError
  | UserNotAvailableError;

const updateInviteStatus =
  (type: CircleInvitePayload["type"]) =>
  (invite: CircleInvite | null): CircleInvitePayload | null => {
    if (!invite) {
      return null;
    }
    if (
      invite?.status === "invite-received" ||
      invite?.status === "invite-send"
    ) {
      return {
        ...invite,
        type,
      };
    }
    return null;
  };

interface Circle {
  id: string;
  name: string;
}

export const useNetworkingHubInvite = ({
  client,
  networkingHubId,
  circles,
  currentCircle,
  timeLimit,
}: {
  client: SocketIOClient.Socket;
  networkingHubId: string;
  circles: Circle[];
  currentCircle?: string | null;
  timeLimit?: number;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [invite, setInvite] = useState<CircleInvite | null>(null);

  const inviteResponseQuery = useMutation(
    async (params: {
      status: "accept" | "reject";
      userId: string;
      expiresAt?: number;
    }) => {
      const response = await emitAsPromise(
        client,
        Actions.CIRCLE_INVITE_RESPONSE,
        params,
      );

      return parseDataToType(response, t.literal("OK"));
    },
    {
      onError: (err, params) => {
        toast.error(`There was an error ${params.status}ing the invite`);
      },
    },
  );
  const executeInviteResponse = inviteResponseQuery.mutateAsync;

  const inviteQuery = useMutation(
    async () => {
      const response = await emitAsPromise(
        client,
        Actions.CIRCLE_INVITE_RANDOM,
      );
      return parseDataToType(response, t.literal("OK"));
    },
    {
      onError: () => {
        setInvite((prev) => ({
          status: "error-random-user",
          private: prev?.private,
        }));
      },
    },
  );
  const executeInvite = inviteQuery.mutateAsync;

  const removeInvite = useLatestCallback(async () => {
    setInvite(null);
    inviteQuery.reset();
  });

  const handleInvite = useLatestCallback(async () => {
    removeInvite();
    await executeInvite();
  });

  const { mutateAsync: handleInviteResponse } = useMutation(
    async (action: "accept" | "reject") => {
      if (
        invite?.status !== "invite-send" &&
        invite?.status !== "invite-received"
      ) {
        return;
      }
      if (invite?.type === "canceled" || invite?.type === "rejected") {
        return;
      }

      await executeInviteResponse({
        userId: invite.userId,
        status: action,
        ...(timeLimit && {
          expiresAt: Date.now() + timeLimit,
        }),
      });
    },
    {
      onError: (err, action) => {
        // remove invite if failed to reject but keep if failed to accept or failed to cancel
        if (action === "reject" && invite?.status !== "invite-send")
          removeInvite();
      },
      onSuccess: () => {
        removeInvite();
      },
    },
  );

  const handleCircleInviteResult = useLatestCallback(async (data: unknown) => {
    const invitedUser = parseDataToType(data, RandomInviteResult);
    if (invitedUser?.status === "invited") {
      setInvite({
        ...invitedUser,
        status: "invite-send",
        type: "outgoing",
        invitedAt: Date.now(),
      });
    }
    if (
      invitedUser?.status === "no-available-users" ||
      invitedUser?.status === "user-not-available"
    ) {
      setInvite((prev) => ({
        ...invitedUser,
        private: prev?.private,
      }));
    }
  });

  const handleCircleInvite = useLatestCallback(async (data: unknown) => {
    const payload = parseDataToType(data, CircleInvitePayloadType);

    if (payload.type === "invite") {
      setInvite({
        ...payload,
        status: "invite-received",
        type: "incoming",
        invitedAt: Date.now(),
        circle:
          typeof payload.circle === "string"
            ? circles.find((circle) => circle.id === payload.circle)
            : undefined,
      });
    }

    if (
      invite?.status === "invite-received" ||
      invite?.status === "invite-send"
    ) {
      if (payload.type === "invite-accepted") {
        const dontNavigateWhen =
          invite.status === "invite-send" && invite.private && currentCircle;
        if (!dontNavigateWhen) {
          await removeInvite();
          navigate(
            `/networkingHub/${encodeURIComponent(
              networkingHubId,
            )}/room/${encodeURIComponent(payload.circleId)}` + location.search,
          );
        }
      }

      if (payload.type === "invite-rejected") {
        if (invite?.type === "incoming") {
          setInvite(updateInviteStatus("canceled"));
        } else {
          setInvite(updateInviteStatus("rejected"));
        }
      }
    }
  });

  useEffect(() => {
    if (
      !invite ||
      invite.status === "no-available-users" ||
      invite.status === "error-random-user" ||
      invite.status === "user-not-available"
    ) {
      return;
    }

    const inviteWithoutError = invite as CircleInvitePayload;

    if (inviteWithoutError.type === "canceled") {
      return;
    }
    const id = setTimeout(() => {
      if (inviteWithoutError.type === "incoming") {
        removeInvite();
      } else {
        setInvite(updateInviteStatus("rejected"));
      }
    }, inviteWithoutError.expiresAt - Date.now());

    return () => clearTimeout(id);
  }, [invite, removeInvite]);

  useEffect(() => {
    client.on("circle-invite", handleCircleInvite);
    client.on("circle-invite-result", handleCircleInviteResult);
    client.on("circle-invite-response", handleCircleInvite);

    return () => {
      client.off("circle-invite", handleCircleInvite);
      client.off("circle-invite-result", handleCircleInviteResult);
      client.off("circle-invite-response", handleCircleInvite);
    };
  }, [client, handleCircleInvite, handleCircleInviteResult, networkingHubId]);

  return {
    invite,
    inviteQuery,
    handleInvite,
    handleInviteResponse,
    handleRemoveInvite: removeInvite,
    inviteResponseQuery,
  };
};

function useExtractedQuery<T>(
  query: UseQueryResult<T, any> | UseMutationResult<T, any, any, any>,
) {
  const { data, error, isError, isIdle, isLoading, isSuccess, status } = query;
  return useMemo(() => {
    return {
      data,
      error,
      isError,
      isIdle,
      isLoading,
      isSuccess,
      status,
    };
  }, [data, error, isError, isIdle, isLoading, isSuccess, status]);
}

export const [
  NetworkingHubInviteProvider,
  useInvite,
  useHandleInvite,
  useHandleInviteResponse,
  useHandleRemoveInvite,
  useInviteQuery,
  useInviteResponseQuery,
] = constate(
  (props: ReturnType<typeof useNetworkingHubInvite>) => props,
  (props) => props.invite,
  (props) => props.handleInvite,
  (props) => props.handleInviteResponse,
  (props) => props.handleRemoveInvite,
  (props) => useExtractedQuery(props.inviteQuery),
  (props) => useExtractedQuery(props.inviteResponseQuery),
);

export const NetworkingHubInviteProviderNexus: React.FC<{
  networkingHubId: string;
}> = ({ children, networkingHubId }) => {
  const currentCircle = useCircle();
  const { data: networkingHub } = useNetworkingHub();
  const circles: Circle[] = networkingHub?.networkingHubRooms || [];
  const client = useSocketClient();
  const props = useNetworkingHubInvite({
    client,
    networkingHubId,
    circles,
    currentCircle: currentCircle?.id,
    timeLimit: networkingHub?.whitelabel?.privateConversationTimeLimit,
  });

  const hasPendingInvite = Boolean(props.invite) || !props.inviteQuery.isIdle;
  const hasExistingCircleInvite =
    props.invite?.status !== "invite-received" &&
    currentCircle &&
    props.invite?.private;

  const dialogProps: NetworkingHubInviteDialogProps = {
    show: hasPendingInvite && !hasExistingCircleInvite,
    invite: props.invite,
    onConfirm: useLatestCallback(() => {
      props.handleInviteResponse("accept");
    }),
    onClose: useLatestCallback(() => {
      props.handleInviteResponse("reject");
    }),
    onRetry: useLatestCallback(() => {
      props.handleInvite();
    }),
    isLoading: props.inviteResponseQuery.isLoading,
  };

  return (
    <NetworkingHubInviteProvider {...props}>
      {children}
      <NetworkingHubInviteDialog {...dialogProps} />
    </NetworkingHubInviteProvider>
  );
};
