import { useMemo } from "react";
import invariant from "tiny-invariant";
import { useUserRole } from "./useUserRole";
import { useUserContext } from "../UserProvider";

export const useUser = () => {
  const { user } = useUserContext();
  invariant(user, "useUser called before user was set");
  const userRole = useUserRole();

  return useMemo(() => {
    return {
      ...user,
      userRole,
    };
  }, [user, userRole]);
};
