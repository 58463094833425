import { UserRole } from "@src/contracts/user/user";
import SplayTree from "splaytree";

export type ChannelType =
  | "presence"
  | "private"
  | "event"
  | "circle"
  | "extraTab"
  | "event-presenters"
  | "q-&-a"
  | "live-reactions";

/**
 * Currently supported pubnub channel types.
 *
 * This should be the value of `message.type` from
 * pubnub messages
 */
export enum ChannelTypeEnum {
  PRESENCE = "presence",
  PRIVATE = "private",
  EVENT = "event",
  CIRCLE = "circle",
  EXTRA_TAB = "extraTab",
  EVENT_PRESENTERS = "event-presenters",
  Q_A = "q-&-a",
  LIVE_REACTION = "live-reaction",
}

export interface PubnubChannel {
  type: ChannelType;
  id: string;
  newMessages: number;
}

export interface ChannelConfig {
  type: ChannelType;
  channel: string;
  pinnable?: boolean;
  hasAnnouncements?: boolean;
}

export interface PubnubUser {
  uid: string;
  profilePicture: string;
  name: string;
  role: UserRole;
  email: string;
}

export interface PubnubState {
  userId: string;
  username: string;
  userRole?: UserRole;
  avatar: string;
  present: boolean;
}

export interface Message {
  message: {
    comment: string;
    name: string;
    timestamp: string;
    uid: string;
    userUid: string;
  };
  user: PubnubUser;
  timetoken?: string;
}

export type PresentUser = Partial<PubnubState> & Pick<PubnubState, "userId">;

export interface PresentUsers {
  [userId: string]: PubnubState | PresentUser;
}

export interface PresentUserIds extends SplayTree<string> {
  [Symbol.iterator]: any;
}
