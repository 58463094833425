import React, { useEffect, useState } from "react";

import { usePreviousDistinct } from "@react-hookz/web";

import { useEvent, useHasEventEnded } from "@src/providers/EventProvider";
import {
  FeatureFlag,
  useFeatureFlag,
} from "@src/providers/FeatureFlagsProvider";

import useEventController from "./useEventController";
import useHubController from "./useHubController";

import EventSideBar from "./EventSideBar";
import { NoRegistrationSideBar } from "./NoRegistrationSideBar";
import { SequelRegistrationSideBar } from "./SequelRegistrationSideBar";
import SequelRegistrationSideBarV2 from "./SequelRegistrationV2/SequelRegistrationSidebar";

import type { Registration } from "../../contracts/event/event";
import { type User, UserRole } from "../../contracts/user/user";
import { useIsReplayEnabled } from "@src/providers/EventStateProvider";
import { cn } from "@introvoke/react/styles";

export enum SideBarType {
  LIVE_EVENT = "live-event",
  NETWORKING_HUB = "networking-hub",
}

interface Props {
  /**
   * The type of side bar to display when registered
   */
  type: SideBarType;
  /**
   * The user information
   */
  user: User;
  /**
   * The information for registration
   */
  registration?: Registration;
  /**
   * The event ID. Note: this appears to have some magic if not supplied during Registration.
   */
  eventId?: string;
}

const StageSideBar = ({ user }: Pick<Props, "user">) => {
  const { showPoweredBy, showLiveSupport } = useEventController();
  const hasEventEnded = useHasEventEnded();
  const prevEnded = usePreviousDistinct(hasEventEnded);
  const [show, setShow] = useState(!hasEventEnded);

  const hidePostEventSidebar = useFeatureFlag(
    FeatureFlag.ENABLE_HIDE_SIDEBAR_POST_EVENT,
  );

  useEffect(() => {
    // If the event goes from live -> ended, keep sidebar for users in audience
    // when they refresh they will no longer have the sidebar
    if (typeof prevEnded !== "undefined" && hasEventEnded === !prevEnded) {
      setShow(true);
    } else {
      setShow(!hasEventEnded);
    }
  }, [hasEventEnded, prevEnded]);

  if (hidePostEventSidebar && !show) {
    // No event to render or event has ended, show no sidebar
    return null;
  }

  return (
    <EventSideBar
      showPoweredBy={showPoweredBy}
      showAudienceView
      showLiveSupport={showLiveSupport}
    />
  );
};

const HubSideBar = ({ user }: Pick<Props, "user">) => {
  const { showPoweredBy, showLiveSupport } = useHubController();

  return (
    <EventSideBar
      showPoweredBy={showPoweredBy}
      showLiveSupport={showLiveSupport}
    />
  );
};

const StyledSidebarContainer: React.FC<{ hide?: boolean }> = ({
  hide,
  ...props
}) => (
  <div
    {...props}
    className={cn(
      "z-10 flex h-full w-full flex-1 basis-full overflow-hidden bg-white md:max-w-[400px] md:border md:border-l-gray-100",
      hide && "hidden",
    )}
  />
);

const SideBar = (props: Props) => {
  const { user, registration, eventId, type } = props;

  const isRegistrationV2Enabled = useFeatureFlag(
    FeatureFlag.EMBED_REGISTRATION_V2,
  );

  const eventHasEnded = useHasEventEnded();
  const replayEnabled = useIsReplayEnabled();
  const { data: event } = useEvent();

  if (
    user.userRole === UserRole.Unregistered ||
    user.userRole === UserRole.None
  ) {
    // no registration
    if (!registration?.enabled) {
      return (
        <StyledSidebarContainer
          hide={
            !replayEnabled && eventHasEnded && !event?.isRegistrationModeEnabled
          }
        >
          <NoRegistrationSideBar eventId={eventId} />
        </StyledSidebarContainer>
      );
    }

    // registration
    return (
      <StyledSidebarContainer hide={eventHasEnded && !replayEnabled}>
        {isRegistrationV2Enabled ? (
          <SequelRegistrationSideBarV2 />
        ) : (
          <SequelRegistrationSideBar />
        )}
      </StyledSidebarContainer>
    );
  }

  return type === SideBarType.LIVE_EVENT ? (
    <StageSideBar user={user} />
  ) : (
    <HubSideBar user={user} />
  );
};

export default React.memo(SideBar);
