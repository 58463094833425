import { useEvent } from "../../providers/EventProvider";
import useCommonController, { CommonController } from "./useCommonController";
import { UserRole } from "../../contracts/user/user";

interface EventController extends CommonController {
  /**
   * Flag that indicates if the event has live support
   */
  showLiveSupport: boolean;
}

const useEventController = (): EventController => {
  const commonController = useCommonController();
  const { user } = commonController;

  const { data: event } = useEvent();

  const showLiveSupport =
    (user.userRole === UserRole.Organizer &&
      event?.eventInfo.planFeatures?.hasLiveSupport) ??
    false;

  return {
    ...commonController,
    showLiveSupport,
  };
};

export default useEventController;
