import type { ViewerLocation } from "../analytics/event-analytics-contracts";
import type { RegisteredAttendeeResponse } from "../event/event";
import type { dbUser } from "./dbUser";

export interface User
  extends Omit<
    dbUser,
    | "_id"
    | "accessInfo"
    | "createdDate"
    | "identity"
    | "location"
    | "privateUserInfo"
    | "stripeCustomerId"
    | "stripeCustomerId"
    | "stripeId"
    | "stripeId"
    | "userOptions"
  > {
  userRole: UserRole;
  room?: string;
  updated?: boolean;
  isOrganizer?: boolean;
  isSuperAdmin?: boolean;
  location: ViewerLocation;
  sessionId: string;
}

export interface UserUpdate
  extends Pick<
    User,
    | "aboutMe"
    | "companyId"
    | "companyName"
    | "email"
    | "name"
    | "originalId"
    | "profilePicture"
    | "profileSet"
    | "title"
    | "uid"
    | "updated"
  > {
  /**
   * The event the user is currently attending
   */
  eventId?: string;
  /**
   * The networking hub the user is currently attending
   */
  networkingHubId?: string;
}

export interface RegisteredAttendeeUserResponse
  extends RegisteredAttendeeResponse,
    dbUser {}

export enum UserRole {
  /** A user should never have this role */
  Hidden = -1,
  Organizer = 0,
  Presenter = 1,
  Viewer = 2,
  /** the default user role when a user first opens an event or NH */
  Unregistered = 3,
  /** the role returned by the API if the user doesn't have a role or access passcode verification failed */
  None = 9,
}

export const UserRoleMap: Record<UserRole, string> = {
  [UserRole.Hidden]: "hidden",
  [UserRole.Organizer]: "host",
  [UserRole.Presenter]: "presenter",
  [UserRole.Viewer]: "attendee",
  [UserRole.Unregistered]: "unregistered",
  [UserRole.None]: "none",
};
