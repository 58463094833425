import {
  Subscription as GqlSubscription,
  subscribe as gqlSubscribe,
} from "../subscribe";
import type { GraphQlClientOptions } from "./GraphQlClient";
import { RETRY_DELAY_MS } from "./common";
import {
  OnPublishSubscriptionData,
  ON_PUBLISH_SUBSCRIPTION,
} from "../schemas/actions";
import { createLogger, Logger } from "@src/helpers/logging";
import { logGqlError } from "@src/graphql/helpers";

const createLoggerKey = (room?: string | null) => [
  "SocketSubscription",
  String(room),
];

export interface SocketSubscriptionOptions
  extends Pick<GraphQlClientOptions, "retry" | "retryDelay" | "debug"> {
  /**
   * Whether the subscription is enabled
   * @default true
   */
  enabled?: boolean;
  /**
   * A prefix to append to the `subscriptionKey` when generating the room string
   * @default null
   */
  roomPrefix?: string | null;
  /**
   * The id of the room on which the subscription will listen for updates
   * @default null
   */
  subscriptionKey?: string | null;
  /**
   * The `onMessage` of the `GraphQlClient` to be run once a message is received on the subscription.
   * All subscriptions will run the same `client.onMessage` since they are only abstracting away the subscription logic from the client.
   */
  onMessage: (message: OnPublishSubscriptionData) => void;
}

export class SocketSubscription {
  private enabled: boolean = true;
  private debug: boolean = false;
  private logger: Logger;

  private retry: boolean = false;
  private retryDelay: number = RETRY_DELAY_MS;
  private pendingRetry: NodeJS.Timeout | null = null;

  /**
   * The full room string on which the subscription will listen for updates.
   * It is the combination of `${roomPrefix}${subscriptionKey}` if `subscriptionKey` is defined and null otherwise
   */
  private room: string | null = null;
  private roomPrefix: string | null = null;

  private subscriptionKey: string | null = null;
  /**
   * The actual graphql subscription that is established and will listen on the room
   */
  private subscription: GqlSubscription | null = null;

  constructor(options: SocketSubscriptionOptions) {
    this.roomPrefix = options.roomPrefix ?? null;
    this.subscriptionKey = options.subscriptionKey ?? null;
    this.room = this.getRoom();

    this.retry = !!options.retry;
    this.retryDelay = options.retryDelay ?? this.retryDelay;

    this.enabled = options.enabled ?? true;
    this.debug = !!options.debug;
    this.logger = createLogger(createLoggerKey(this.room), {
      enabled: this.debug,
    });

    this.onMessage = options.onMessage;
  }

  private onMessage: SocketSubscriptionOptions["onMessage"] = (
    message: OnPublishSubscriptionData,
  ) => {};

  getRoom() {
    return this.subscriptionKey
      ? `${this.roomPrefix ? this.roomPrefix : ""}${this.subscriptionKey}`
      : null;
  }

  isActive() {
    return !!this.subscription;
  }

  isEnabled() {
    return this.enabled;
  }

  setEnabled(enabled: boolean) {
    this.enabled = enabled;
    this.checkSubscription();
  }

  setSubscriptionKey(subscriptionKey: string | null) {
    this.subscriptionKey = subscriptionKey;
    this.checkSubscription();
  }

  /**
   * Function that is called by the `GraphQlClient` when it is connecting.
   * Tries to establish the subscription
   */
  onConnect() {
    if (!this.enabled) return;
    this.subscribeToUpdates();
  }

  /**
   * Function that is called by the `GraphQlClient` when it is disconnecting.
   * Tries to disconnect the subscription
   */
  onDisconnect() {
    this.setEnabled(false);
    this.unsubscribeFromUpdates();
  }

  /**
   * Function that is triggered when an error occurs while subscribing and `this.retry=true`
   */
  private onRetry = () => {
    this.clearRetry();

    if (!this.enabled || !this.retry || this.subscription) {
      return;
    }

    this.subscribeToUpdates();
  };

  /**
   * General error handler that is triggered when an error occurs while listening on any of the active subscription
   *
   * @param error Error from the GraphQL subscription
   */
  private onError = (error: unknown) => {
    this.logger.error("onError:", error);

    logGqlError("Socket Subscription Error", error as any);

    // TODO: Use more intelligence on whether we should unsubscribe or not
    this.unsubscribeFromUpdates();

    if (this.retry) {
      this.retrySubscription();
    }
  };

  /**
   * Checks if the subscription should be updated or disabled based on local values.
   * Used to retriggers the subscription if the `subscriptionKey` has been updated.
   *
   * Currently needs to be triggered manually but we could run this check on an interval
   */
  checkSubscription() {
    // if subscription has been disabled
    if (!this.enabled) return this.unsubscribeFromUpdates();

    const room = this.getRoom();

    // if room has not been updated => ignore
    if (this.room === room) return;

    // if room has been updated
    this.room = room;
    this.logger = createLogger(createLoggerKey(room), {
      enabled: this.debug,
    });

    // if new room is null => unsubscribe
    if (!room) return this.unsubscribeFromUpdates();

    // subscribe to new room
    this.subscribeToUpdates();
  }

  /**
   * Cancels the existing subscription if any and unsubscribes from updates
   */
  unsubscribeFromUpdates() {
    this.clearRetry();

    if (!this.subscription) {
      return;
    }

    this.subscription.unsubscribe();
    this.subscription = null;

    this.logger.log("Unsubscribed from updates");
  }

  /**
   * Subscribes to updates on the room if the subscription is enabled and `this.room` is defined
   */
  subscribeToUpdates() {
    if (!this.enabled || !this.room) return;

    this.clearRetry();
    this.unsubscribeFromUpdates();

    this.subscription = gqlSubscribe({
      query: ON_PUBLISH_SUBSCRIPTION,
      variables: { room: this.room },
      onData: this.onMessage,
      onError: this.onError,
    });

    this.logger.log("Subscribed to updates...");
  }

  /**
   * Clears any pending retries attempting to resubscribe
   */
  private clearRetry() {
    if (!this.pendingRetry) {
      return;
    }

    clearTimeout(this.pendingRetry);
    this.pendingRetry = null;
  }

  /**
   * Retries subscribing to the subscription
   */
  private retrySubscription() {
    if (!this.enabled || !this.retry) return;
    this.pendingRetry = setTimeout(this.onRetry, this.retryDelay);
  }
}

export const __testable__ = {
  createLoggerKey,
};
