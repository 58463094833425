/** @jsxImportSource @emotion/react */

import React from "react";

import { EventStageContent } from "./components/EventStageContent";
import Box from "@mui/material/Box";
import { NetworkingDialog } from "@src/components/Event/EventStage/NetworkingDialog";
import { StageDevTools } from "@src/components/Event/EventStage/StageDevTools";

export const EventStage = () => {
  return (
    <>
      {process.env.NODE_ENV === "development" && <StageDevTools />}
      <NetworkingDialog />
      <Box
        data-testid="event-content-container"
        sx={{
          display: "flex",
          position: "relative",
          flexDirection: "column",
          justifyContent: "space-between",
          overflow: "auto",
          flex: 1,
          minHeight: 0,
          minWidth: 0,
        }}
      >
        <EventStageContent />
      </Box>
    </>
  );
};
