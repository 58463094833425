import axios from "axios";

import { NetworkingHub } from "../contracts/networking-hub/NetworkingHub";
import { ApiConfig } from "./api-config";
import {
  UserRole,
  RegisteredAttendeeUserResponse,
} from "../contracts/user/user";
import { RegistrationQuestionAnswer } from "../contracts/event/event";
import type {
  UseClearChatProps,
  UseClearChatResult,
} from "../providers/chat/useClearChat";

export const GetNetworkingHubV2 = async (
  id: string,
): Promise<NetworkingHub> => {
  const configURL = `${ApiConfig.GetPrefix()}/api/v2/networking-hub/${id}`;
  const response = await axios.get(configURL, ApiConfig.GetHeaderWithToken());
  return response.data;
};

export const GetUserRole = async (
  networkingHubId: string,
  userEmail: string,
  userId: string,
): Promise<UserRole> => {
  const configURL =
    ApiConfig.GetPrefix() +
    `/api/v2/networking-hub/${networkingHubId}/userRole`;
  const payload = {
    email: userEmail,
    userId,
  };

  const response = await axios.post(
    configURL,
    payload,
    ApiConfig.GetHeaderWithToken(),
  );
  const data: UserRole = response.data;
  return data;
};

export const VerifyPasscode = async (
  networkingHubId: string,
  passcode: string | number,
): Promise<number> => {
  const configURL = `${ApiConfig.GetPrefix()}/api/v2/networking-hub/${networkingHubId}/verifyPasscode`;
  const response = await axios.post(
    configURL,
    { passcode: `${passcode}` },
    ApiConfig.GetHeaderWithToken(),
  );
  const data: UserRole = response.data;
  return data;
};

export interface RegistrantNew {
  name: string;
  firstName?: string;
  lastName?: string;
  email: string;
  customQuestionsAnswers?: RegistrationQuestionAnswer[];
}

export const RegisterForNetworkingHub = async (
  networkingHubId: string,
  registrant: RegistrantNew,
) => {
  const configURL = `${ApiConfig.GetPrefix()}/api/v3/networking-hub/${networkingHubId}/registrant`;
  const response = await axios.post<RegisteredAttendeeUserResponse>(
    configURL,
    registrant,
    ApiConfig.GetHeaderWithToken(),
  );
  return response;
};

export const GetUserJoinInformation = async (
  networkingHubId: string,
  joinCode: string,
): Promise<RegisteredAttendeeUserResponse> => {
  const configUrl = `${ApiConfig.GetPrefix()}/api/v1/networking/${networkingHubId}/join/${joinCode}`;
  const response = await axios.get(configUrl, ApiConfig.GetHeaderWithToken());
  const data: RegisteredAttendeeUserResponse = response.data;
  return data;
};

export const ClearChat = async (
  { networkingHubId, eventId }: UseClearChatProps,
  channels?: string[],
): Promise<UseClearChatResult> => {
  const configURL = `${ApiConfig.GetPrefix()}/api/v2/networking-hub/${networkingHubId}/clearChat`;
  const body = {
    channels,
  };

  const response = await axios.post(
    configURL,
    body,
    ApiConfig.GetHeaderWithToken(),
  );
  const data: {
    channel: string;
    success: boolean;
  }[] = response.data;
  return data;
};

export const ResendEmail = async (
  networkingHubId: string,
  email: string,
): Promise<any> => {
  const configURL = `${ApiConfig.GetPrefix()}/api/v1/networking/${networkingHubId}/registrant/resendEmail`;

  const body = {
    email,
  };
  const response = await axios.post(
    configURL,
    body,
    ApiConfig.GetHeaderWithToken(),
  );

  return response;
};

interface RecordingStartResponse {
  isStarting: boolean;
}

export const CircleRecordingStart = async (
  networkingHubId: string,
  circleId: string,
): Promise<RecordingStartResponse> => {
  const response = await axios.post<RecordingStartResponse>(
    `${ApiConfig.GetPrefix()}/api/v3/networking-hub/${networkingHubId}/circle/${circleId}/recording/start`,
    null,
    ApiConfig.GetHeaderWithToken(),
  );
  return response.data;
};

interface RecordingStopResponse {
  isStopping: boolean;
}

export const CircleRecordingStop = async (
  networkingHubId: string,
  circleId: string,
): Promise<RecordingStopResponse> => {
  const response = await axios.post<RecordingStopResponse>(
    `${ApiConfig.GetPrefix()}/api/v3/networking-hub/${networkingHubId}/circle/${circleId}/recording/stop`,
    null,
    ApiConfig.GetHeaderWithToken(),
  );
  return response.data;
};

interface RecordingStatusResponse {
  isRecording: boolean;
}

export const CircleRecordingStatus = async (
  networkingHubId: string,
  circleId: string,
): Promise<RecordingStatusResponse> => {
  const response = await axios.get<RecordingStatusResponse>(
    `${ApiConfig.GetPrefix()}/api/v3/networking-hub/${networkingHubId}/circle/${circleId}/recording/status?r=${Math.random()}`,
    ApiConfig.GetHeaderWithToken(),
  );
  return response.data;
};
