import { emitAsPromise } from "@src/components/Presence/emitAsPromise";
import { Actions } from "@src/graphql/schemas/actions";
import { useCallback, useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { createPollsQueryKey } from "../helpers";
import {
  Poll,
  PollActionHookOptions,
  SelectEventPollInput,
  PollActionRollbackFunction,
} from "../types";

/**
 * Callback to remove the deleted poll from the supplied local polls array
 */
export const removeDeletedPoll =
  (data: Poll) =>
  (prev?: Poll[] | null): Poll[] => {
    const previousPolls = Array.isArray(prev) ? prev : [];
    return previousPolls.filter((poll) => poll.id !== data.id);
  };

export interface UseDeleteEventPollOptions extends PollActionHookOptions {}

/**
 * Hook to permanently delete an event poll
 */
export const useDeleteEventPoll = ({
  client,
  eventId,
  circleId,
}: UseDeleteEventPollOptions) => {
  const cache = useQueryClient();
  const queryKey = createPollsQueryKey(eventId, circleId);

  /**
   * Mutation that permanently deletes an existing poll
   */
  const mutation = useMutation<
    Poll,
    unknown,
    SelectEventPollInput,
    PollActionRollbackFunction
  >(
    (input: SelectEventPollInput) =>
      emitAsPromise<Poll>(client, Actions.DELETE_EVENT_POLL, input),
    {
      onMutate: (poll) => {
        const previousPolls = cache.getQueryData<Poll[]>(queryKey);

        // optimistically remove deleted poll from cache
        cache.setQueryData<Poll[]>(
          queryKey,
          removeDeletedPoll({ id: poll.poll } as Poll),
        );

        return () => cache.setQueryData(queryKey, previousPolls);
      },
      onError: (err, variables, rollback) => {
        // rollback on error (success is handled by handlePollDeleted)
        rollback?.();
        toast.error("There was an error deleting the poll");
      },
    },
  );

  /**
   * Callback to run when a `poll-deleted` or `poll-hidden` event is received from the client server
   */
  const handlePollDeleted = useCallback(
    (data: Poll) => {
      cache.setQueryData<Poll[]>(
        createPollsQueryKey(data.eventId, data.circleId),
        removeDeletedPoll(data),
      );
    },
    [cache],
  );

  return useMemo(
    () => ({ mutation, handlePollDeleted }),
    [handlePollDeleted, mutation],
  );
};

export const __testable__ = {
  removeDeletedPoll,
};
