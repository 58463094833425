import React from "react";

import BackspaceIcon from "@mui/icons-material/Backspace";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment, TextField } from "@mui/material";

interface SearchRoomTextFieldProps {
  fullWidth?: boolean;
  value: string;
  onChange?: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  onClear?: () => void;
}

export const SearchRoomTextField = ({
  fullWidth,
  value,
  onChange,
  onClear,
}: SearchRoomTextFieldProps) => (
  <TextField
    fullWidth={fullWidth}
    placeholder="Search circle or user"
    value={value}
    onChange={onChange}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
      endAdornment: (
        <InputAdornment position="end">
          <IconButton onClick={onClear} sx={{ mr: -1 }}>
            <BackspaceIcon />
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
);
