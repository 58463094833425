import { useCallback, useEffect, useRef, useState } from "react";
import throttle from "lodash/throttle";
import { useLatest, useUnmount } from "react-use";

interface UseSpamCallbackOptions {
  /**
   * The number of times the function is called to be considered spammed.
   *
   * Works in conjunction with `timeoutMs` to determine if threshold is met
   * within the timeout.
   *
   * Defaults to `5`
   */
  threshold?: number;
  /**
   * The timeout in milliseconds to check whether the function is being spammed.
   *
   * Works in conjunction with `threshold` to determine if the function has been
   * called more times than the threshold within the given timeout.
   *
   * Defaults to `2000` milliseconds
   */
  timeoutMs?: number;
  /**
   * The time in milliseconds before the `isSpamming` flag is reset to false
   */
  resetMs?: number;
  /**
   * If provided will add throttling on the provided function with the given
   * time in milliseconds, defaults to `0`.
   *
   * _NOTE_: This does not affect the spam detection in any way. This is a convenience
   * option that allows for throttling the calls to the provided function. Spam
   * detection will still run as expected and will not be throttled.
   */
  throttleMs?: number;
  /**
   * Disables the spam checks and throttling, calling the function as normal
   */
  disabled?: boolean;
}

/**
 * Returns a function that will perform spam detection and prevent calling
 * the provided function when spamming is detected based on the given options.
 *
 * When it is detected that spamming is occurring, any calls to the returned function
 * will be ignored until the reset timeout has been reached.
 *
 * Optionally you can supply a `throttleMs` option which will throttle calls to
 * the provided function. Spam detection still works as normal regardless whether the
 * function is throttled or not.
 *
 * Example Usage:
 * ```typescript
 * const myFunction = (text: string) => console.log(text);
 * // Using defaults, no throttling
 * const wrappedFn1 = useSpamCallback(myFunction)
 *
 * // Customizing the spam detection
 * const wrappedFn2 = useSpamCallback(myFunction, {
 *   // If the provided function is called more than 10 times in 5,000 milliseconds
 *   // it will be considered to be spammed. Any additional calls to the function
 *   // will be ignored for 10,000 milliseconds
 *   threshold: 10,
 *   timeoutMs: 5000,
 *   resetMs: 10000,
 * });
 *
 * // Add throttling so our function is only called on a specific interval
 * const wrappedFn3 = useSpamCallback(myFunction, {
 *   // Our provided function will only be called once every 1,000 milliseconds
 *   // however our `wrappedFn3` still contains spam detection so if spamming
 *   // is detected the function will not be called
 *   throttleMs: 1000,
 * });
 * ```
 *
 * @param fn The function to call when spamming is not detected
 * @param options Options to control spam detection
 * @returns A function which wraps the provided function with spam detection
 */
export function useSpamCallback<F extends (...args: never[]) => unknown>(
  fn: F,
  options: UseSpamCallbackOptions = {},
) {
  const {
    threshold = 5,
    timeoutMs = 2000,
    resetMs = 5000,
    throttleMs = 0,
    disabled,
  } = options;
  const fnRef = useRef(fn);
  const [isSpamming, setIsSpamming] = useState(false);
  const spammingRef = useRef(isSpamming);
  const countRef = useRef(0);
  const timerRef = useRef<number | undefined>();
  const resetRef = useRef<number | undefined>();

  // Use a ref w/ the function to allow for calling w/ a non-memoized fn
  // similar to useCallback
  fnRef.current = fn;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledFn = useCallback(throttle(fnRef.current, throttleMs), [
    throttleMs,
  ]);

  // Keep a ref to spamming to avoid re-renders downstream when it changes
  spammingRef.current = isSpamming;
  const wrappedFn = useCallback(
    (...args: Parameters<F>) => {
      if (disabled) {
        fnRef.current(...args);
        return;
      }
      if (spammingRef.current) return;
      // Set a timeout if we don't have one
      if (!countRef.current) {
        timerRef.current = window.setTimeout(() => {
          setIsSpamming(countRef.current > threshold);
          countRef.current = 0;
        }, timeoutMs);
      }
      countRef.current += 1;
      throttledFn(...args);
    },
    [threshold, timeoutMs, disabled, throttledFn],
  );

  /**
   * This effect runs when certain options change that
   * affect our ability to determine whether the function is
   * currently being spammed. These values should not update
   * but in the off-chance they are, let's reset our state
   */
  useEffect(() => {
    setIsSpamming(false);
    countRef.current = 0;
    window.clearTimeout(timerRef.current);
    window.clearTimeout(resetRef.current);
  }, [threshold, timeoutMs, resetMs]);

  /**
   * This effect handles setting a timeout to reset our spam
   * flag once spamming is `true`
   */
  const latestResetMs = useLatest(resetMs);
  useEffect(() => {
    window.clearTimeout(resetRef.current);
    countRef.current = 0;
    if (!isSpamming) return;
    resetRef.current = window.setTimeout(() => {
      setIsSpamming(false);
    }, latestResetMs.current);
  }, [isSpamming, latestResetMs]);

  /**
   * When unmounting the component we need to clear any timeouts
   * to avoid leaks
   */
  useUnmount(() => {
    window.clearTimeout(timerRef.current);
    window.clearTimeout(resetRef.current);
  });

  return wrappedFn;
}

export default useSpamCallback;
