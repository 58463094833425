import React from "react";
import { EditReplayModal } from "@src/components/EventReplayManagement/EditReplayModal";
import { useDialog } from "@src/providers/DialogProvider";
import { useEventState } from "@src/providers/EventStateProvider";
import OutlineButton from "@src/components/buttons/OutlineButton";

const EditEventReplayButton = () => {
  const { openDialog, hideDialog } = useDialog();
  const eventState = useEventState();

  return (
    <>
      <OutlineButton
        data-testid="manage-replay-btn"
        onClick={() => {
          openDialog(
            "edit-replay-dialog",
            <EditReplayModal
              replayConfig={{
                replayEnabled: eventState?.replayEnabled,
                replayUrl: eventState?.replayUrl,
                streamPlaceholderMessage: eventState?.streamPlaceholderMessage,
              }}
              onClose={() => {
                hideDialog("edit-replay-dialog");
              }}
            />,
          );
        }}
      >
        Manage Replay
      </OutlineButton>
    </>
  );
};

export default EditEventReplayButton;
