export enum ParticipantNameOption {
  /**
   * All participants have their name displayed
   */
  ALL = "all",
  /**
   * Only hosts and circle owners have their name displayed
   */
  HOSTS_AND_OWNERS = "hostsAndOwners",
  /**
   * Nobody has their name displayed
   */
  NONE = "none",
}
