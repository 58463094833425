import React, { useCallback, useState } from "react";
import { Grow, GrowProps, Popover, Tooltip } from "@mui/material";
import AddReactionOutlinedIcon from "@mui/icons-material/AddReactionOutlined";
import MoodOutlinedIcon from "@mui/icons-material/MoodOutlined";
import { ChatReaction, Reaction } from "@src/contracts/customization/reactions";
import { useChatReactions } from "@src/providers/chat/useChatReactions";
import {
  StyledReactionStrip,
  StyledReactionImageButton,
  StyledReactionPickerButton,
} from "./ReactionPicker.styles";

export interface ReactionImageProps {
  /**
   * The `ChatReaction` object used to get the image src and label
   */
  reaction: ChatReaction;
  /**
   * Whether the image is shown within the `ReactionPicker` or `ReactionCountItem` components.
   * Used to determine the size of the image.
   */
  variant: "count" | "picker";
}

export const ReactionImage = ({ reaction, variant }: ReactionImageProps) => {
  const [isValid, setIsValid] = useState(true);

  const width = variant === "picker" ? 20 : 16;

  // return generic emoji icon if image fails to load
  if (!isValid)
    return (
      <MoodOutlinedIcon
        width={width}
        color="action"
        data-testid={`reaction-image-fallback-${reaction.reaction}`}
      />
    );

  return (
    <img
      src={reaction.image}
      alt={reaction.label || reaction.reaction}
      width={width}
      data-testid={`reaction-image-${reaction.reaction}`}
      onError={() => setIsValid(false)}
    />
  );
};

export interface ReactionStripProps {
  /**
   * The array of ChatReaction objects to show in the ReactionStrip
   */
  reactions: ChatReaction[];
  /**
   * Callback called when a reaction is selected
   */
  onSelect: (reaction: Reaction) => void;
}
export const ReactionStrip = ({ reactions, onSelect }: ReactionStripProps) => {
  return (
    <StyledReactionStrip data-testid="reaction-picker-strip">
      {reactions.map((reaction) => (
        <Tooltip
          title={reaction.label || reaction.reaction}
          placement="top"
          key={reaction.reaction}
        >
          <StyledReactionImageButton
            size="small"
            color="primary"
            onClick={() => onSelect(reaction.reaction)}
            data-testid={`reaction-image-button-${reaction.reaction}`}
          >
            <ReactionImage reaction={reaction} variant="picker" />
          </StyledReactionImageButton>
        </Tooltip>
      ))}
    </StyledReactionStrip>
  );
};

export interface ReactionPickerProps extends Pick<GrowProps, "in"> {
  /**
   * Callback called when a reaction is selected
   */
  onSelect: (key: Reaction) => void;
  /**
   * Callback called when the reaction picker is closed including when a reaction is selected
   */
  onClose: () => void;
}

const ReactionPicker = ({
  in: show,
  onSelect,
  onClose,
}: ReactionPickerProps) => {
  const [showPicker, setShowPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { chatReactions } = useChatReactions();

  const handleClose = useCallback(() => {
    onClose();
    setShowPicker(false);
  }, [onClose]);

  const handleSelect = useCallback(
    (reaction: Reaction) => {
      onSelect(reaction);
      handleClose();
    },
    [onSelect, handleClose],
  );

  return (
    <>
      <Grow in={show}>
        <StyledReactionPickerButton
          data-testid="reaction-picker-button"
          size="small"
          onClick={(e) => {
            setShowPicker(!showPicker);
            setAnchorEl(e.currentTarget);
          }}
        >
          <AddReactionOutlinedIcon />
        </StyledReactionPickerButton>
      </Grow>
      <Popover
        open={showPicker}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{
          "& > .MuiPaper-root": {
            borderRadius: 50,
          },
        }}
      >
        <ReactionStrip reactions={chatReactions} onSelect={handleSelect} />
      </Popover>
    </>
  );
};

export default React.memo(ReactionPicker);
