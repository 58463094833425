import React, { useCallback, useMemo, useState } from "react";

import { Dialog, DialogTitle, DialogActions } from "@mui/material";

import Button from "../../buttons/Button";
import { InputLabel } from "../../inputs/InputLabel";

import { useClearChat } from "../../../providers/chat/useClearChat";
import { Select, SelectItemProps } from "../../inputs/Select";
import { NetworkingHubRoom } from "../../../contracts/networking-hub/NetworkingHubRoom";
import { UserRole, User } from "../../../contracts/user/user";
import { getChannelConfig } from "../../../providers/chat/getChannelConfig";

export interface ClearChatSettingsProps {
  user: User;
  networkingHubId: string;
  circles: NetworkingHubRoom[];
  eventId?: string;
}
export const ClearChatSettings = React.memo(
  ({
    user,
    circles = [],
    networkingHubId,
    eventId,
  }: ClearChatSettingsProps) => {
    const [showDialog, setShowDialog] = useState(false);
    const [dialogText, setDialogText] = useState("");
    const [chatsToDelete, setChatsToDelete] = useState<string[] | undefined>(
      [],
    );
    const isOrganizer = user.userRole === UserRole.Organizer;

    const options = useMemo<SelectItemProps[]>(() => {
      let circleChats = circles.reduce<SelectItemProps[]>(
        (opt, curr) =>
          // if circle owner or host and the circle is not temporary
          (isOrganizer || curr.ownerEmail?.includes(user.email)) && curr.isFixed
            ? opt.concat({
                label: curr.name,
                value: getChannelConfig({
                  event: networkingHubId,
                  type: "circle",
                  circle: curr.id,
                }).channel,
              })
            : opt,
        [],
      );

      circleChats =
        circleChats.length > 0
          ? [
              {
                isHeader: true,
                label: "Circle chats",
              },
              ...circleChats,
            ]
          : circleChats;

      const hubChats: SelectItemProps[] = [
        {
          isHeader: true,
          label: "Main chats",
        },
        {
          label: "Hub",
          value: getChannelConfig({
            type: "event",
            event: networkingHubId,
          }).channel,
        },
      ];

      return isOrganizer ? [...hubChats, ...circleChats] : circleChats;
    }, [circles, isOrganizer, networkingHubId, user.email]);

    const { mutateAsync, isLoading } = useClearChat({
      networkingHubId,
      eventId,
    });

    const closeHandler = useCallback(() => setShowDialog(false), []);

    const onClickClear = useCallback(
      ({ target: { value } }) => {
        const chatName = value
          ? options.find((opt) => opt.value === value)?.label
          : "";

        setDialogText(
          value
            ? `Are you sure you want to clear the chat messages for the circle "${chatName}"?`
            : "Are you sure you want to clear the chat messages for all chats?",
        );
        setChatsToDelete(value ? [value] : undefined);
        setShowDialog(true);
      },
      [options],
    );

    const onClickConfirm = useCallback(async () => {
      closeHandler();
      mutateAsync(chatsToDelete);
    }, [chatsToDelete, closeHandler, mutateAsync]);

    return (
      <>
        <InputLabel
          label="Clear Chat"
          tooltip={
            isOrganizer
              ? "Hosts can clear all chats, including the host and hub chats."
              : "Circle owners can clear the chats of only the circles they own. Note that hosts will still be able to clear all chats."
          }
        >
          <Select
            placeholder="Clear specific chat"
            value={""}
            options={options}
            disabled={isLoading}
            loading={isLoading}
            onChange={onClickClear}
            fullWidth
            disablePlaceholder
            data-testid="clear-chat-select"
          />
          {isOrganizer && (
            <Button
              variant="outlined"
              color="error"
              onClick={onClickClear}
              disabled={isLoading}
              loadingPosition="end"
              loading={isLoading}
              sx={{ mt: 1.5 }}
              fullWidth
            >
              Clear All Chats
            </Button>
          )}
        </InputLabel>

        <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
          <DialogTitle>{dialogText}</DialogTitle>
          <DialogActions>
            <Button variant="text" color="secondary" onClick={closeHandler}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={onClickConfirm}
              disabled={isLoading}
            >
              {`Yes, clear ${chatsToDelete ? "chat" : "all"}`}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  },
);
