import React from "react";
import {
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
  Collapse,
  styled,
} from "@mui/material";
import CloseDesktopIcon from "@mui/icons-material/ChevronRight";
import CloseMobileIcon from "@mui/icons-material/ExpandMore";

import { useIsUserInStage } from "../../providers/EventStateProvider";
import poweredByImage from "../../static/logos/sequel-logo-small-black.svg";
import useScreenHeight from "../../hooks/useScreenHeight";
import EventStreamPreview from "./EventStreamPreview";
import { useConfigValue } from "@src/providers/config";

const DESKTOP_WIDTH = 300;
const createMobileHeight = (windowHeight: number) =>
  `min(${windowHeight}px, max(200px, ${windowHeight * 0.6}px))`;

const Container = styled("div")(({ theme: { transitions, breakpoints } }) => ({
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",

  [breakpoints.up("md")]: {
    transition: transitions.create("width", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen,
    }),
  },
  [breakpoints.down("md")]: {
    transition: transitions.create("height", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen,
    }),
  },
}));

const Content = styled("div")(({ theme: { palette, breakpoints } }) => ({
  display: "flex",
  flexDirection: "column",
  borderColor: palette.divider,
  [breakpoints.up("md")]: {
    width: DESKTOP_WIDTH,
    height: "100%",
    borderLeftStyle: "solid",
    borderLeftWidth: 1,
  },
  [breakpoints.down("md")]: {
    width: "100%",
  },
}));

const Header = styled("div")(({ theme: { spacing, breakpoints } }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  paddingTop: spacing(1),
  [breakpoints.down("md")]: {
    paddingTop: 0,
  },
}));

const Footer = styled("div")(({ theme: { spacing, palette } }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  borderColor: palette.divider,
  borderTopStyle: "solid",
  borderTopWidth: 1,
  padding: spacing(1),
}));

const ChildContainer = styled("div")({
  display: "flex",
  flex: 1,
  overflow: "hidden",
});

interface Props {
  /**
   * Flag on whether to show the drawer or not
   */
  visible: boolean;

  /**
   * The header label
   */
  header?: string;

  /**
   * Flag to show the powered by Introvoke logo
   */
  showPoweredBy: boolean;

  /**
   * Flag that enables audience view
   */
  showAudienceView?: boolean;

  /**
   * Callback when the user closes the content
   */
  onClose: () => void;
}

const Drawer = (props: React.PropsWithChildren<Props>) => {
  const {
    onClose,
    header,
    showPoweredBy,
    showAudienceView,
    visible,
    children,
  } = props;
  const config = useConfigValue();

  const isUserInStage = useIsUserInStage();
  const windowHeight = useScreenHeight();

  const { breakpoints } = useTheme();
  const isLargeScreen = useMediaQuery(breakpoints.up("md"));

  // TODO:
  const showPreview = isLargeScreen && isUserInStage;

  return (
    <Container
      sx={({ breakpoints }) => ({
        [breakpoints.up("md")]: {
          width: visible ? DESKTOP_WIDTH : 0,
        },
        [breakpoints.down("md")]: {
          height: visible
            ? config.hybridMode
              ? "100%"
              : createMobileHeight(windowHeight)
            : 0,
        },
      })}
    >
      <Content
        data-testid="sidebar-content"
        sx={({ breakpoints }) => ({
          [breakpoints.down("md")]: {
            height: config.hybridMode
              ? "100%"
              : createMobileHeight(windowHeight),
          },
        })}
      >
        {/* Livestream preview */}
        {!!showAudienceView && (
          <Collapse in={showPreview} unmountOnExit>
            <EventStreamPreview />
          </Collapse>
        )}

        {/* Header */}
        <Header>
          <IconButton
            aria-label="close"
            onClick={onClose}
            data-testid="sidebar-close-button"
            sx={({ palette }) => ({
              color: palette.base?.[400],
              padding: 1,
            })}
            size="large"
          >
            {isLargeScreen ? <CloseDesktopIcon /> : <CloseMobileIcon />}
          </IconButton>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
            noWrap
          >
            {header}
          </Typography>
        </Header>
        <ChildContainer>{children}</ChildContainer>

        {showPoweredBy && (
          <Footer>
            <a
              href="https://www.sequel.io?utm_source=powered-by&utm_medium=chat-logo"
              target="_blank"
              rel="noopener noreferrer"
              className="flex h-[12px] w-fit items-center gap-1 text-xs text-gray-500"
            >
              powered by
              <img
                src={poweredByImage}
                alt="Powered by Sequel"
                className="h-full w-auto"
              />
            </a>
          </Footer>
        )}
      </Content>
    </Container>
  );
};

export default React.memo(Drawer);
