import { emitAsPromise } from "@src/components/Presence/emitAsPromise";
import { Actions } from "@src/graphql/schemas/actions";
import { useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { createPollsQueryKey, orderPolls } from "../helpers";
import {
  Poll,
  PollActionHookOptions,
  PublishEventPollResultsInput,
  PollActionRollbackFunction,
  UserPoll,
} from "../types";

/**
 * Callback used when optimistically updating the poll locally when the poll's results are published
 */
const updatePublishedResultsPoll =
  (data: PublishEventPollResultsInput) => (prev?: Poll[] | null) => {
    const previousPolls = Array.isArray(prev) ? prev : [];
    return orderPolls(
      previousPolls.map((poll) => {
        if (poll.id !== data.poll) return poll;

        return {
          ...poll,
          isPending: true,
          resultsPublished: true,
        };
      }),
    );
  };

export interface UsePublishEventPollResultsOptions
  extends PollActionHookOptions {}

export const usePublishEventPollResults = ({
  client,
  eventId,
  circleId,
}: UsePublishEventPollResultsOptions) => {
  const cache = useQueryClient();
  const queryKey = createPollsQueryKey(eventId, circleId);

  /**
   * Mutation to publish the results of an existing poll
   */
  const mutation = useMutation<
    Poll,
    unknown,
    PublishEventPollResultsInput,
    PollActionRollbackFunction
  >(
    (input: PublishEventPollResultsInput) =>
      emitAsPromise<Poll>(client, Actions.PUBLISH_EVENT_POLL_RESULTS, input),
    {
      onMutate: (input) => {
        const previousPolls = cache.getQueryData<UserPoll[]>(queryKey);

        // optimistically publish the poll results
        cache.setQueryData<UserPoll[]>(
          queryKey,
          updatePublishedResultsPoll(input),
        );

        return () => cache.setQueryData(queryKey, previousPolls);
      },
      onError: (err, variables, rollback) => {
        // rollback on error (success is already handled in handlePollUpdated)
        rollback?.();
        toast.error("There was an error publishing the poll's results");
      },
    },
  );

  return useMemo(() => ({ mutation }), [mutation]);
};
