import { Event as ApiEvent } from "../../contracts/event/event";

export interface Event extends ApiEvent {
  goLiveLocked: boolean;
  playbackLocked: boolean;
}

export const createEventQueryKey = (eventId: string | undefined | null) => [
  "Event",
  eventId ?? null,
];
