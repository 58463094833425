import orderBy from "lodash/orderBy";
import React, { memo, useEffect, useMemo, useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ChevronLeft from "@mui/icons-material/ChevronLeft";

import Button from "../buttons/Button";
import { User, UserRole } from "../../contracts/user/user";
import { useUser } from "../../providers/UserProvider";
import { useEventChatContext } from "../../providers/chat/ChatProvider";
import { usePrivateChats } from "../../providers/chat/PrivateChatProvider";
import { useChatChannelNewMessageCount } from "../../providers/chat/useChatChannelNewMessageCount";
import { ParticipantView } from "../Participants/ParticipantView";
import ParticipantList from "../ParticipantList";
import Participant from "../ParticipantList/Participant";
import { SocketUser } from "../Presence/IntrovokePresence";
import { Chat } from "./Chat";
import { useChatChannelRecentMessage } from "../../providers/chat/useChatChannelRecentMessage";
import { usePresenceList } from "../../providers/pubnub/usePresenceList";
import { PubnubState } from "../../providers/pubnub/PubNubProvider";
import {
  ParticipantType,
  Props as ParticipantProps,
} from "../ParticipantList/Participant";
import { MessageBox } from "./MessageBox";
import { ParticipantNameOption } from "../../contracts/enums/participant-name-options";

export interface ChatUser {
  userId: string;
  username: string;
  avatar: string;
}

export interface Props {
  chats: {
    users: string[];
    id: string;
  }[];
}

type PropsUnion =
  | {
      variant: "chat";
      chatId: string;
      onOpen?: (chatId: string) => void;
      user?: ChatUser;
    }
  | {
      variant: "invite";
      onInvite: (userId: string) => void;
      user: ChatUser;
    };

export const ChatListItem = memo((props: PropsUnion) => {
  const { user } = props;

  const count = useChatChannelNewMessageCount(
    props.variant === "chat" ? props.chatId : null,
  );

  const recentMessage = useChatChannelRecentMessage(
    props.variant === "chat"
      ? {
          type: "private",
          channel: props.chatId,
        }
      : null,
  );

  const handleClick = () => {
    if (props.variant === "chat") {
      props.onOpen?.(props.chatId);
    } else {
      props.onInvite?.(props.user.userId);
    }
  };

  return user ? (
    <ParticipantView
      avatar={user.avatar}
      username={user.username}
      onClick={handleClick}
      badge={count}
      recentMessage={recentMessage}
    />
  ) : null;
});

const WrappedParticipant = memo(function WrappedParticipant(
  props: ParticipantProps & { chatId?: string | undefined },
) {
  const { chatId, ...participantProps } = props;

  const count = useChatChannelNewMessageCount(chatId ?? null);

  const recentMessage = useChatChannelRecentMessage(
    chatId ? { type: "private", channel: chatId } : null,
  );

  return (
    <Participant
      {...participantProps}
      caption={recentMessage?.message?.comment?.slice(0, 55)}
      time={recentMessage?.message?.timestamp}
      badgeCount={count}
    />
  );
});

export const ChatWrapper = memo(
  ({
    channel,
    onClose,
    user,
    title,
  }: {
    channel: string;
    onClose?: () => void;
    user: User;
    title: string;
  }) => {
    const { clear } = useEventChatContext();

    useEffect(() => {
      clear(channel);
      return () => {
        clear(channel);
      };
    }, [channel, clear]);

    return (
      <Chat
        hidePollPreviews={true}
        welcomeOwner={""}
        welcomeMessage={
          <MessageBox
            senderId="introvoke"
            userId={user.uid}
            username={""}
            hideAvatar
            timestamp=""
            isOwn={false}
            message={`Welcome to private chat with ${title}`}
          />
        }
        title={
          <Box
            display="flex"
            alignItems="center"
            flex="1"
            overflow="hidden"
            mx={0.5}
          >
            <Button
              aria-label="back"
              onClick={onClose}
              variant="text"
              color="secondary"
              startIcon={<ChevronLeft />}
              sx={{
                mr: 0.5,
              }}
            >
              Back
            </Button>
            <Typography
              title={title}
              variant="h4"
              noWrap
              flex="1"
              textAlign="center"
            >
              {title}
            </Typography>
          </Box>
        }
        label={`Send message to ${title}`}
        channelConfig={{
          type: "private",
          channel,
        }}
        user={user}
        showTimestamp={true}
      />
    );
  },
);

type ListEntry =
  | {
      type: "chat";
      chatId: string;
      user: ChatUser;
    }
  | {
      type: "invite";
      user: ChatUser;
    };

interface ChatListProps {
  users: SocketUser[];
  onSelect?: (type: "invite" | "open", chat: string) => void;
  search: string;
  setSearch: (search: string) => void;
  chats: { id: string; users: string[] }[];
  profiles: Record<string, ChatUser>;
  showParticipantNames?: ParticipantNameOption;
}
export const ChatList = memo(
  ({
    users,
    onSelect,
    chats,
    profiles,
    search,
    setSearch,
    showParticipantNames = ParticipantNameOption.ALL,
  }: ChatListProps) => {
    const localUser = useUser();
    const chatsFilteredBySearch: ListEntry[] = useMemo(() => {
      if (search) {
        return users.reduce((acc, user) => {
          const otherUserId = user.meta.userId;
          const chat = chats.find((chat) => chat.users.includes(otherUserId));

          // TODO: Allow private message within circle
          if (chat) {
            // If we already have a chat, then show that in the results
            acc.push({
              type: "chat",
              chatId: chat.id,
              user: {
                userId: otherUserId,
                username: user.meta.username,
                avatar: user.meta.avatar,
              },
            });
          } else if (
            (showParticipantNames === ParticipantNameOption.HOSTS_AND_OWNERS &&
              localUser.userRole === UserRole.Organizer) ||
            showParticipantNames !== ParticipantNameOption.NONE
          ) {
            acc.push({
              type: "invite",
              user: {
                userId: otherUserId,
                username: user.meta.username,
                avatar: user.meta.avatar,
              },
            });
          }

          return acc;
        }, [] as ListEntry[]);
      }

      return chats.flatMap((entry) =>
        profiles[entry.users[0]]
          ? [
              {
                type: "chat",
                chatId: entry.id,
                user: profiles[entry.users[0]],
              },
            ]
          : [],
      );
    }, [
      showParticipantNames,
      localUser.userRole,
      chats,
      profiles,
      search,
      users,
    ]);

    const chatsOrderedByname = useMemo(() => {
      return orderBy(chatsFilteredBySearch, "user.username", "asc");
    }, [chatsFilteredBySearch]);

    const participants = chatsOrderedByname.map((entry) => entry.user);

    const chatsByUserId = Object.fromEntries(
      chatsFilteredBySearch.map((chat) => [chat.user.userId, chat]),
    );

    const onParticipantSelected = (participant: ParticipantType) => {
      const { userId } = participant;
      const chat = chatsByUserId[userId];
      if (!chat) {
        return null as any;
      }

      if (chat.type === "chat") {
        onSelect?.("open", chat.chatId);
      } else {
        onSelect?.("invite", userId);
      }
    };

    const renderParticipant = (participant: ParticipantType) => {
      const chat = chatsByUserId[participant.userId];
      if (!chat) {
        return null;
      }
      return (
        <WrappedParticipant
          {...participant}
          chatId={chat.type === "chat" ? chat.chatId : undefined}
        />
      );
    };

    return (
      <ParticipantList
        participants={participants}
        searchPlaceholder="Search names to start a conversation..."
        search={search}
        onSearchChange={setSearch}
        renderParticipant={renderParticipant}
        onParticipantSelected={onParticipantSelected}
      />
    );
  },
);

interface PrivateChatProps {
  hide?: boolean;
  showParticipantNames?: ParticipantNameOption;
}

const mapPubNubToSocketUser = (user: PubnubState): SocketUser =>
  ({
    meta: { ...user, email: user.userId.split("|||")[1] },
  }) as SocketUser;

export const PrivateChat = memo(
  ({ hide, showParticipantNames }: PrivateChatProps) => {
    const [search, setSearch] = useState("");
    const { activeChat: chatId, setActiveChat: setChat } =
      useEventChatContext();
    const { chats, handleUserInvite, profiles } = usePrivateChats();
    const user = useUser();
    const { list: users } = usePresenceList({ search, searchOnly: true });

    const otherUsers = useMemo(
      () =>
        (users || [])
          ?.filter((u) => u.userId !== user.uid)
          .map(mapPubNubToSocketUser),
      [user.uid, users],
    );
    const chat = useMemo(
      () => chats?.find((chat) => chat.id === chatId),
      [chatId, chats],
    );

    if (!chats || hide) {
      return null;
    }

    return !chat ? (
      <ChatList
        profiles={profiles}
        chats={chats}
        users={otherUsers}
        search={search}
        setSearch={setSearch}
        showParticipantNames={showParticipantNames}
        onSelect={async (type, id) => {
          if (type === "open") {
            setChat(id);
          }
          if (type === "invite") {
            const result = await handleUserInvite(id);
            if (result) {
              setChat(result.chat.chatChannelId);
            }
          }
        }}
      />
    ) : (
      <ChatWrapper
        title={profiles[chat.users[0]]?.username}
        channel={chat.id}
        onClose={() => setChat(null)}
        user={user}
      />
    );
  },
);
