import React from "react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || "local";

export const isSentryEnabled = () =>
  process.env.NODE_ENV === "production" &&
  (ENVIRONMENT === "prod" || ENVIRONMENT === "stg" || ENVIRONMENT === "dev");

const IGNORED_ERROR_MESSAGES_PATTERNS = [
  // https://github.com/jitsi/lib-jitsi-meet/issues/1873#issuecomment-1030668733
  /No SSRC lines found in remote SDP for remote/i,
  // https://github.com/jitsi/lib-jitsi-meet/issues/1873#issuecomment-1030668733
  /remote track removal failed - track not found/i,
  // https://bugs.chromium.org/p/chromium/issues/detail?id=809574
  /ResizeObserver loop limit exceeded/i,
  // https://github.com/juggle/resize-observer/issues/103
  /ResizeObserver loop completed with undelivered notifications/i,
  // TODO: PubNub throws this generic error whenever an error occurs
  // In sentry logs it does not give us a stack trace to figure out what happened,
  // but this is usually 400 error codes on PATCH requests based on what I've seen
  // We need to investigate and expose the actual errors as messages so we can
  // decide to take action, but for now we can suppress as there is nothing
  // we can do until then
  /PubNub call failed, check status for details/i,
  // Jitsi spams this when a user leaves and it's not actually an error
  /No participant for id/,
  // If the websocket closes from an error or any reason other than a disconnect call
  // jitsi lib will raise this and we can't really do anything about it: https://github.com/jitsi/strophejs/blob/master/src/websocket.js#L358
  // we should be triggering sentry errors from our own apps
  // most of the current errors in sentry look to be related to network issues/disconnects
  /Strophe: Websocket closed unexpectedly/i,
  // Another jitsi error related to communications, we should rely on the VS to alert us w/ errors
  /Jingle error/i,
  // Indicates issues getting media, this is internal jitsi lib and can be ignored
  /no peer media info available for/i,
  // IVS player will attempt to call getBatter and doesn't handle this error internally or allows it to log
  /Failed to execute 'getBattery' on 'Navigator': Illegal invocation/i,
  // Outlook error raised due to safe links crawler: https://github.com/getsentry/sentry-javascript/issues/3440
  /Non-Error promise rejection captured with value: Object Not Found Matching Id:/i,
  // We handle failures on lazy loaded components via the error boundary
  // when we hit the boundary the user is presented w/ a message to refresh the page
  // which is the only solution to these errors as lazy loading failures are due
  // to network issues, outdated cache on the user's machine (they stayed on the page during updates)
  /Lazy element type must resolve to a class or function/i,
  // Ping timeout when jitsi lib attempts to ping the strophe server
  // nothing we can do with this as it is coming from the lib, the VS will handle reconnect
  /Ping timeout/i,
  // Thrown by jitsi jingle from the jitsi lib for some reason
  // throws an object like: { session: JingleSessionPC[session=JVB,initiator=false,sid=ft1oomuue5qpq] }
  /Non-Error exception captured with keys: session/i,
  // Get User Media aborted (virtual stage)
  /The operation could not be performed and was aborted/i,
  // This can occur when attempting to fetch just about anything
  /NetworkError when attempting to fetch resource/i,
  // User denies the video/audio permissions
  /User denied permission to use device(s)/i,
  // Jitsi lib unable to get the devices from the browser, could be a permissions issue
  /Cannot read properties of undefined \(reading 'enumerateDevices'\)/i,
  // VideoJS will throw an error if the users connection is interrupted when live stream playing
  // it should internally handle it
  /Invalid target for.*must be a DOM node or evented object/,
  // IVS player tech, invalid state is handled internally in it
  /InvalidStateError: The object is in an invalid state/i,
  // User aborts a request themselves
  /The fetching process for the media resource was aborted by the user agent at the user's request/i,
  // Jitsi lib internal error attempting to start the track, we handle this scenario in the virtual stage
  /Could not start video source/i,
  // Internal atcb_action error
  /Cannot read properties of null reading 'classList'/i,
];

// Script URLs to ignore if they raise errors
const IGNORE_URLS = [
  // From: https://gist.github.com/impressiver/5092952
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Safari Extensions
  /safari-extension:/i,
  // Puppeteer scripts used for testing
  /pptr:\/\//i,
];

// Ignore these breadcrumbs as they do not provide much value
const IGNORE_URL_BREADCRUMBS = [
  // Pubnub subscribe long polling
  /pndsn\.com/i,
  // Amplitude logging
  /amplitude\.com/i,
];

export function initializeSentry() {
  Sentry.init({
    dsn: "https://c89d4336db644b4a8a60b54e539d6b24@o462298.ingest.sentry.io/5802989",
    enabled: isSentryEnabled(),
    environment: ENVIRONMENT,
    release: process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    beforeBreadcrumb(breadcrumb, hint) {
      // Ignore HTTP breadcrumbs like pubnub unless they error
      if (
        breadcrumb.type === "http" &&
        breadcrumb.data?.status_code >= 200 &&
        breadcrumb.data?.status_code < 300 &&
        IGNORE_URL_BREADCRUMBS.some((pattern) =>
          pattern.test(breadcrumb.data?.url),
        )
      ) {
        return null;
      }
      return breadcrumb;
    },
    ignoreErrors: IGNORED_ERROR_MESSAGES_PATTERNS,
    denyUrls: IGNORE_URLS,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: ENVIRONMENT === "prod" ? 0.25 : 1.0,
  });
}

export async function log(message: string) {
  console.log(message);
  if (isSentryEnabled()) {
    Sentry.captureMessage(message);
  }
}

export const logWarn = (message: string) => {
  console.warn(message);
  if (isSentryEnabled()) {
    Sentry.captureMessage(message, "warning");
  }
};

export function logError(messageOrError: string | Error) {
  console.error(messageOrError);
  if (isSentryEnabled()) {
    Sentry.captureMessage(
      typeof messageOrError === "object" && !(messageOrError instanceof Error)
        ? JSON.stringify(messageOrError)
        : String(messageOrError),
      "error",
    );
  }
}

export function logException(error: Error) {
  console.error(error);
  if (isSentryEnabled()) {
    Sentry.captureException(error);
  }
}
export function setUser(user: any) {
  if (isSentryEnabled()) {
    Sentry.setUser(user);
  } else {
    // console.log(`setting user: ${JSON.stringify(user)}`);
  }
}

export function breadcrumb(message: string, category: string, crumb: any) {
  console.log(`BREADCRUMB: ${message}: ${JSON.stringify(crumb)}`);
  if (isSentryEnabled()) {
    Sentry.addBreadcrumb({
      category: category,
      message: message,
      ...crumb,
    });
  }
}

/**
 * Helper function to format an error with a custom message to a sentry error string
 */
export const formatSentryError = (
  message: string,
  error?: string | Error | null,
) =>
  error
    ? `${message} - ${
        typeof error === "object" && !(error instanceof Error)
          ? JSON.stringify(error)
          : String(error)
      }`
    : message;

/**
 * Helper function that formats and logs to sentry error with the provided severity level
 */
export const logFormatted = (
  message: string,
  error: string | Error,
  level: "info" | "warning" | "error" = "error",
) => {
  const formatted = formatSentryError(message, error);

  switch (level) {
    case "warning": {
      logWarn(formatted);
      break;
    }
    case "error": {
      logError(formatted);
      break;
    }
    default: {
      log(formatted);
    }
  }
};

export interface Logger {
  debug: typeof console.debug;
  log: typeof console.log;
  info: typeof console.info;
  warn: typeof console.warn;
  error: typeof console.error;
}
export interface LoggerOptions {
  /**
   * Optional flag to manually enable/disable the logger, regardless of environment.
   * Note that errors will always be logged, regardless of whether the logger is enabled or not
   */
  enabled?: boolean;
}

/**
 * Creates a simple logger with a similar api to the console.log family, to be used for debugging.
 *
 * It is enabled by default in the dev environment and disabled in other environments (Check the options param for overrides).
 *
 * @param tags Used to generate the name the logger will use when logging
 * @param options Used to pass custom options and overrides to the logger
 *
 */
export const createLogger = (
  tags: string | string[],
  options: LoggerOptions = {},
): Logger => {
  const enabled = options.enabled ?? process.env.NODE_ENV === "development";

  const tag = `[${(Array.isArray(tags) ? tags : [tags]).join(":")}]`;

  return {
    debug: (...args: Parameters<typeof console.debug>) =>
      enabled && console.debug(tag, ...args),
    log: (...args: Parameters<typeof console.log>) =>
      enabled && console.log(tag, ...args),
    info: (...args: Parameters<typeof console.info>) =>
      enabled && console.info(tag, ...args),
    warn: (...args: Parameters<typeof console.warn>) =>
      enabled && console.warn(tag, ...args),
    error: (...args: Parameters<typeof console.error>) =>
      console.error(tag, ...args),
  };
};
