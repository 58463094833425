/** @jsxImportSource @emotion/react */

import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  Suspense,
} from "react";
import { useQuery } from "react-query";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import invariant from "tiny-invariant";
import { css } from "@emotion/react";

import { Api } from "../../api/api";
import { NetworkingHubRoom } from "../../contracts/networking-hub/NetworkingHubRoom";
import { User, UserRole } from "../../contracts/user/user";
import { NavigationHelper } from "../../helpers/navigation";
import { useLatestCallback } from "../../hooks/useLatestCallback";
import { CircleSortOrder, useConfigValue } from "../../providers/config";
import {
  useCircleToken,
  useNetworkingHub,
} from "../../providers/NetworkingHubProvider";
import { RequiredKeys } from "../../types/RequiredKeys";
import { FullPageLoader } from "../FullPageLoader";
import NetworkingHubContent from "../NetworkingHub/NetworkingHubContent";
import { NetworkingStoppedDialog } from "../NetworkingHub/NetworkingStoppedDialog";
import { RootLayout } from "../RootLayout";
import SideBar, { SideBarType } from "../SideBar";
import { NetworkingHubHeader } from "./EventStage/NetworkingHubHeader";
import { useDialogControls } from "../../hooks/useDialogControls";
import { QueryKeys } from "../../api/QueryKeys";
import { NetworkingHub as NetworkingHubType } from "../../contracts/networking-hub/NetworkingHub";
import { ParticipantNameOption } from "../../contracts/enums/participant-name-options";
import { useUser } from "../../providers/UserProvider";
import FeatureLockedDialog from "../dialogs/FeatureLockedDialog";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import { isOptimalBrowser } from "../../helpers/browser";
import {
  FeatureFlag,
  useFeatureFlag,
} from "../../providers/FeatureFlagsProvider";
import lazyLoader from "@src/helpers/lazyLoader";
import { useDialog } from "@src/providers/DialogProvider";
import { JoinCodeDialog } from "../SideBar/JoinCodeDialog";
import { useRouteParams } from "@src/hooks/useRouteParams";
import { useCreateNetworkingHubRoom } from "@src/providers/NetworkingHubProvider/useCreateNetworkingHubRoom";

const Circle = lazyLoader(
  () => import("@src/components/CircleSession/Circle"),
  "Circle",
);

const PreparationContent = lazyLoader(
  () =>
    import(
      "@src/components/Event/widgets/PreparationContent/PreparationContent"
    ),
  "PreparationContent",
);

export interface NetworkingHubProps {
  fallback?: React.ReactNode;
}

export const NetworkingHub = memo((props: NetworkingHubProps) => {
  const { eventId } = useRouteParams();
  const { data: networkingHub, isLoading, isError } = useNetworkingHub();
  const user = useUser();
  const params = useParams();

  const roomId = params.roomId || params.circleId;

  const { sortCirclesBy, fixedRoomsFilter } = useConfigValue();

  if (isLoading) {
    return <FullPageLoader />;
  }

  if (!networkingHub || isError) {
    return <>{props.fallback || null}</>;
  }

  return (
    <>
      <NetworkingHubView
        {...props}
        user={user}
        networkingRooms={networkingHub.networkingHubRooms || []}
        hubId={networkingHub.uid}
        roomId={roomId}
        fixedFilteredRooms={fixedRoomsFilter}
        sortCirclesBy={sortCirclesBy}
        hidePagination={networkingHub.whitelabel?.hidePagination}
        privateConversationTimeLimit={
          networkingHub.whitelabel?.privateConversationTimeLimit
        }
        hideRandomUserMingle={networkingHub?.whitelabel?.hideRandomUserMingle}
        showParticipantNames={networkingHub?.whitelabel?.showParticipantNames}
        hideCreateCircle={networkingHub?.whitelabel?.hideCreateCircle}
        returnToEventId={eventId}
        networkingHub={networkingHub}
      />

      {!!networkingHub?.hubLocked && (
        <FeatureLockedDialog
          open={networkingHub?.hubLocked}
          title="Networking Hub Disabled"
          hideAction
        />
      )}
    </>
  );
});

export interface NetworkingHubViewProps {
  user: User;
  cleanUI?: boolean;
  fallback?: React.ReactNode;
  fixedFilteredRooms?: string[];
  sortCirclesBy: CircleSortOrder;
  networkingRooms: RequiredKeys<NetworkingHubRoom, "id">[];
  hubId: string;
  roomId?: string;
  hidePagination?: boolean;
  privateConversationTimeLimit?: number;
  hideRandomUserMingle?: boolean;
  showParticipantNames?: ParticipantNameOption;
  hideCreateCircle?: boolean;
  returnToEventId?: string;
  networkingHub?: NetworkingHubType | null;
}

const NetworkingHubView = memo((props: NetworkingHubViewProps) => {
  const { openDialog } = useDialog();
  const { cleanUI } = useConfigValue();
  const { mutateAsync: handleCreateRoom } = useCreateNetworkingHubRoom(
    props.networkingHub?.uid,
  );
  const deviceSetupStepFeatureEnabled = useFeatureFlag(
    FeatureFlag.ENABLE_DEVICE_SETUP_STEP,
  );

  const scrollRef = useRef<HTMLDivElement>(null);

  const [openBrowserAlert, setOpenBrowserAlert] = useState(true);
  const [FTUECompleted, setFTUECompleted] = useState(false);

  const showPreparationContent =
    deviceSetupStepFeatureEnabled && !FTUECompleted;

  useEffect(() => {
    if (props.networkingHub?.registration?.outsideOfAppEnabled) {
      openDialog("join-code-dialog", <JoinCodeDialog />, {
        priority: "critical",
        disableOutsideClick: true,
        hideCloseIcon: true,
      });
    }
  }, [openDialog, props.networkingHub?.registration?.outsideOfAppEnabled]);

  const location = useLocation();
  const navigate = useNavigate();

  const currentRoom = props.networkingRooms.find(
    (room) => room.id === props.roomId || room.name === props.roomId,
  );

  // We allow the route to contain the circle name but we should
  // use the ID in child components
  const circleId = currentRoom?.id;

  const {
    isLoading: tokensLoading,
    accessToken,
    dailyToken,
    configJitsiServer,
    configRoomServer,
  } = useCircleToken(props.hubId, circleId);

  useEffect(() => {
    if (!currentRoom?.expiresAt) {
      return;
    }
    const id = setTimeout(() => {
      navigate({
        pathname: `/networkingHub/${props.hubId}`,
        search: location.search,
      });
    }, currentRoom.expiresAt - Date.now());

    return () => clearTimeout(id);
  }, [currentRoom?.expiresAt, navigate, location.search, props.hubId]);

  const joinMingleRoom = useCallback(
    async (data: {
      name: string;
      slots: number;
      metadata: Record<string, string>;
    }) => {
      const { id } = await handleCreateRoom(data);

      navigate({
        pathname: `/networkingHub/${props.hubId}/room/${id}`,
        search: location.search,
      });
    },
    [handleCreateRoom, navigate, location.search, props.hubId],
  );

  const { data: eventStatus } = useQuery(
    QueryKeys.networkingStatus(props.returnToEventId),
    () => {
      invariant(props.returnToEventId, "Event must exist");
      return Api.EventApi.GetEventStatus(props.returnToEventId);
    },
    {
      refetchIntervalInBackground: true,
      refetchInterval: 5000,
      enabled: Boolean(props.returnToEventId?.length),
      cacheTime: 0,
      staleTime: 0,
    },
  );
  const isNetworkingActive = eventStatus ? eventStatus.isNetworkingLive : true;

  const showReturnToVirtualStage =
    !isNetworkingActive && !!eventStatus?.returnToEvent;
  const dialogProps = useDialogControls(showReturnToVirtualStage);

  const handleReturnToEvent = useLatestCallback(async () => {
    if (props.returnToEventId) {
      NavigationHelper.navigate(
        undefined,
        `/event/${props.returnToEventId}`,
        window.location.search,
      );
    }
  });

  const handleStopNetworking = useLatestCallback(
    async (returnToEvent: boolean) => {
      if (props.returnToEventId) {
        await Api.EventApi.StopNetworking(props.returnToEventId, returnToEvent);
      }
    },
  );

  const showParticipantNames =
    props.showParticipantNames === ParticipantNameOption.HOSTS_AND_OWNERS &&
    props.user.userRole === UserRole.Organizer
      ? ParticipantNameOption.ALL
      : props.showParticipantNames;

  const handleLeaveCircle = useCallback(() => {
    navigate({
      pathname: `/networkingHub/${props.hubId}`,
      search: location.search,
    });
  }, [navigate, location.search, props.hubId]);

  const onFTUECompleted = useCallback(() => {
    setFTUECompleted(true);
  }, []);

  const optimalBrowser = isOptimalBrowser();

  const layoutContent = useMemo<JSX.Element | undefined>(() => {
    if (!props.networkingRooms) {
      return;
    }

    if (showPreparationContent) {
      const { roomId } = props;

      let headerText;
      let permissionDeniedActionText;
      let requestPermissionText;
      let enterActionText;

      if (roomId) {
        headerText = "Join the meeting";
        permissionDeniedActionText = "Enter meeting without mic/cam";
        requestPermissionText =
          "Grant camera and microphone access to join the meeting.";
        enterActionText = "Enter meeting";
      } else {
        headerText = "Join the Networking Hub";
        permissionDeniedActionText = "Enter Networking Hub without mic/cam";
        requestPermissionText =
          "Grant camera and microphone access to join the Networking Hub.";
        enterActionText = "Enter Networking Hub";
      }

      return (
        <Suspense
          fallback={
            <Box
              display="flex"
              flex="1"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          }
        >
          <PreparationContent
            headerText={headerText}
            permissionDeniedActionText={permissionDeniedActionText}
            requestPermissionText={requestPermissionText}
            enterActionText={enterActionText}
            jwt={accessToken}
            jitsiServer={configJitsiServer}
            roomServer={configRoomServer}
            onReady={onFTUECompleted}
          />
        </Suspense>
      );
    }

    const optimalBrowserContent = !optimalBrowser ? (
      <Box sx={{ width: "100%" }}>
        <Collapse in={openBrowserAlert}>
          <Alert
            onClose={() => {
              setOpenBrowserAlert(false);
            }}
            sx={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            severity="warning"
          >
            {
              "Your browser is not optimal. For the best experience, please download the "
            }
            <a
              href="https://www.google.com/chrome/"
              target="_blank"
              rel="noopener noreferrer"
            >
              latest version of Chrome.
            </a>
          </Alert>
        </Collapse>
      </Box>
    ) : null;

    const headerContent = (
      <NetworkingHubHeader
        onReturnToEvent={
          props.returnToEventId ? handleReturnToEvent : undefined
        }
        onStopNetworking={handleStopNetworking}
        isNetworkingActive={isNetworkingActive}
      />
    );

    const footerContent = (
      <NetworkingHubContent
        parentScrollRef={scrollRef}
        room={circleId}
        cleanUI={cleanUI}
        networkingRooms={props.networkingRooms}
        filteredRooms={props.fixedFilteredRooms}
        sortCirclesBy={props.sortCirclesBy}
        user={props.user}
        // TODO: This cascades down as the event ID... Need to fix all this later
        eventId={props.hubId}
        onCreateRoom={joinMingleRoom}
        hidePagination={props.hidePagination}
        hideRandomUserMingle={props.hideRandomUserMingle}
        showParticipantNames={showParticipantNames}
        hideCreateCircle={props.hideCreateCircle}
      />
    );

    return (
      <>
        {optimalBrowserContent}
        <NetworkingStoppedDialog
          onConfirm={handleReturnToEvent}
          {...dialogProps}
        />
        <Suspense
          fallback={
            <div className="flex flex-1 items-center justify-center">
              <CircularProgress />
            </div>
          }
        >
          {headerContent}
          <Circle
            hubId={props.hubId}
            circleId={circleId}
            tokensLoading={tokensLoading}
            accessToken={accessToken}
            dailyToken={dailyToken}
            configJitsiServer={configJitsiServer}
            configRoomServer={configRoomServer}
            onLeave={handleLeaveCircle}
          />
          {footerContent}
        </Suspense>
      </>
    );
  }, [
    props,
    showPreparationContent,
    optimalBrowser,
    openBrowserAlert,
    handleReturnToEvent,
    handleStopNetworking,
    isNetworkingActive,
    circleId,
    cleanUI,
    joinMingleRoom,
    showParticipantNames,
    dialogProps,
    tokensLoading,
    accessToken,
    dailyToken,
    configJitsiServer,
    configRoomServer,
    handleLeaveCircle,
    onFTUECompleted,
  ]);

  if (!props.networkingRooms) {
    return <FullPageLoader />;
  }

  return (
    <RootLayout
      content={
        <div
          ref={scrollRef}
          css={css`
            display: flex;
            flex-direction: column;
            position: relative;
            min-height: 0;
            overflow: auto;
            height: 100%;
            flex: 1;
            ${props.user.userRole === UserRole.Unregistered
              ? `filter: blur(6px);`
              : ""}
          `}
        >
          {layoutContent}
        </div>
      }
      sidebar={
        !cleanUI && (
          <SideBar
            type={SideBarType.NETWORKING_HUB}
            user={props.user}
            // TODO: This cascades down as the event ID... Need to fix all this later
            eventId={props.hubId}
            registration={props.networkingHub?.registration}
          />
        )
      }
    />
  );
});

export default NetworkingHub;
