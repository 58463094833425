import React, { memo } from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";

import UserAvatar from "../UserAvatar";

export const CircleUserList = memo(
  ({
    users,
    className,
  }: {
    users: { userId: string; avatar: string; username: string }[];
    className?: string;
  }) => {
    return (
      <List className={className}>
        {users.map((user) => (
          <ListItem key={user.userId}>
            <ListItemAvatar>
              <UserAvatar
                username={user.username}
                image={user.avatar}
                size={72}
                userId={user.userId}
              />
            </ListItemAvatar>
            <ListItemText primary={user.username} />
          </ListItem>
        ))}
      </List>
    );
  },
);
