import React, { memo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { PollStatus } from "../../providers/polls/PollProvider";
import EqualizerIcon from "@mui/icons-material/Equalizer";

export interface PollOption {
  id: string;
  name: string;
  selected: boolean;
}

export interface PollOptionsViewProps {
  options: PollOption[];
  status: PollStatus;
  hasVoted: boolean;
  isHost: boolean;
  onVote?: (id: string) => void;
  onOpenPoll: () => void;
}

export const PollListItemOptions: React.FC<PollOptionsViewProps> = memo(
  ({ options, status, isHost, hasVoted, onVote, onOpenPoll }) => {
    const activeSelected =
      options.find((option) => option.selected === true)?.id || null;
    const [selected, setSelected] = useState<null | string>(activeSelected);

    const isPending = status === PollStatus.PENDING;
    const isClosed = status === PollStatus.CLOSED;
    const isPollDisabled = isPending || hasVoted || (isClosed && !isHost);

    return (
      <Box
        data-testid="poll-list-item-options"
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "auto",
          pt: 2,
          alignItems: "flex-end",
        }}
      >
        {options.map((option) => {
          const isSelected = selected === option.id;

          return (
            <Button
              key={option.id}
              onClick={() => setSelected(option.id)}
              variant="outlined"
              fullWidth
              sx={{
                px: 1.5,
                py: 1,
                mb: 1,
                textAlign: "start",
                display: "flex",
                justifyContent: "flex-start",
                whiteSpace: "break-spaces",
                backgroundColor: isSelected ? "primary.main" : "base.100",
                color: isSelected ? "common.white" : "common.black",
                border: "none",
                fontWeight: "normal",
                "&:hover": {
                  border: "none",
                  backgroundColor: isSelected ? "primary.main" : "base.100",
                },
                "&.Mui-disabled": {
                  border: "none",
                },
              }}
              disabled={isPollDisabled}
              startIcon={
                <Box
                  sx={{
                    backgroundColor: "common.white",
                    height: "22px",
                    width: "22px",
                    borderRadius: "11px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "primary.main",
                  }}
                >
                  {isSelected ? <CheckIcon fontSize="small" /> : null}
                </Box>
              }
            >
              {option.name}
            </Button>
          );
        })}

        {isPending && isHost && (
          <Button
            sx={{
              mb: 1,
              width: "40%",
            }}
            variant="contained"
            color="primary"
            onClick={onOpenPoll}
          >
            Open Poll
          </Button>
        )}
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {!isPollDisabled && selected && (
            <Button
              sx={{
                width: "40%",
              }}
              color="primary"
              disabled={selected === null}
              onClick={() => selected !== null && setSelected(null)}
            >
              Cancel
            </Button>
          )}
          {!isPollDisabled && (
            <Button
              sx={{
                ml: 1,
                width: "40%",
              }}
              variant="contained"
              color="primary"
              disabled={selected === null}
              onClick={() => selected !== null && onVote?.(selected)}
            >
              Vote
            </Button>
          )}
        </Box>

        {hasVoted && (
          <Box sx={{ mb: 2, mt: 2 }}>
            <Typography variant="h4">Thanks for voting</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <EqualizerIcon color="primary" sx={{ mr: 1 }} />
              <Typography variant="body2">
                Results have not been published yet
              </Typography>
            </Box>
          </Box>
        )}

        {!hasVoted && isClosed && (
          <Typography align="center" variant="caption">
            Poll Closed
          </Typography>
        )}
      </Box>
    );
  },
);
