import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { Theme, alpha } from "@mui/material/styles";
import { Tooltip } from "@src/components/Tooltip";

import React, { useCallback } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import {
  UserQuestion,
  QuestionAnswerEventInput,
} from "../../../providers/qAndA/QandAProvider";

const FormSchema = z.object({
  questionId: z.string(),
  answer: z
    .string()
    .max(250, "Answer cannot be more than 250 characters")
    .optional(),
  markAnswered: z.boolean().optional(),
});

type QuestionAnswerFormValues = z.infer<typeof FormSchema>;

export interface QuestionAnswerDialogProps {
  show: boolean;
  question: UserQuestion;
  handleClose?: () => void;
  onSubmit?: (question: QuestionAnswerEventInput) => void;
}
export const QuestionAnswerDialog: React.FC<QuestionAnswerDialogProps> =
  React.memo(({ show, question = {}, handleClose, onSubmit }) => {
    const { control, handleSubmit, formState } =
      useForm<QuestionAnswerFormValues>({
        mode: "all",
        values: {
          questionId: "id" in question && question.id ? question.id : "",
          answer: question.answer,
          markAnswered: question.answered,
        },
        resolver: zodResolver(FormSchema),
      });

    const handleFormSubmit = useCallback<
      SubmitHandler<QuestionAnswerFormValues>
    >(
      (data) => {
        handleClose?.();

        if (question?.id) {
          const answeredState = {
            id: question.id,
            answer: data.answer,
            answered: data.markAnswered,
          };

          // if data is changed => submit
          if (formState.isDirty) onSubmit?.(answeredState);
        }
      },
      [formState.isDirty, handleClose, onSubmit, question.id],
    );

    const hasErrors = !formState.isValid;

    return (
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="answer-question-dialog-title"
        aria-describedby="answer-question-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="answer-question-dialog-title">
          Answer Question
        </DialogTitle>
        <DialogContent>
          {/* asked by */}
          <DialogContentText
            variant="body2"
            color="textSecondary"
            sx={({ typography }) => ({
              marginBottom: 0,
              fontSize: typography.body2.fontSize,
              fontWeight: "normal",
              paddingBottom: ".5em",
            })}
          >
            {`${question?.owner?.username} asked `}
          </DialogContentText>

          {/* question */}
          <DialogContentText
            id="answer-question-dialog-description"
            color="textPrimary"
            variant="h4"
            sx={({ palette }) => ({
              borderLeft: `3px solid ${palette.primary.main}`,
              padding: 1,
              marginBottom: 3,
              backgroundColor: palette.grey[100],
            })}
          >
            {`"${question?.name}"`}
          </DialogContentText>

          {/* answer form */}
          <InputLabel
            sx={({ typography }) => ({
              fontSize: typography.body2.fontSize,
              paddingBottom: ".5em",
            })}
          >
            Your Answer
          </InputLabel>
          <Controller
            control={control}
            name="answer"
            render={({ field: { ref, ...field } }) => (
              <TextField
                {...field}
                inputRef={ref}
                id="answer"
                name="answer"
                required
                autoFocus
                fullWidth
                multiline
                error={!!formState.errors?.answer}
                helperText={formState.errors?.answer?.message}
                InputProps={{
                  sx: ({ palette, typography, transitions }: Theme) => ({
                    border: "1px solid",
                    borderColor: palette.common.white,
                    borderRadius: 2,
                    backgroundColor: "#F2F2F2",
                    minHeight: "fit-content",
                    fontSize: typography.body1.fontSize,
                    overflow: "hidden",
                    padding: "1em",
                    alignItems: "start",
                    transition: transitions.create([
                      "border-color",
                      "box-shadow",
                    ]),
                  }),
                  disableUnderline: true,
                  placeholder: "Type Answer...",
                }}
                sx={({ palette }) => ({
                  "& .MuiInput-root": {
                    "&.Mui-focused": {
                      boxShadow: `${alpha(
                        palette.primary.main,
                        0.25,
                      )} 0 0 0 2px`,
                      borderColor: palette.primary.main,
                    },
                  },
                })}
              />
            )}
          />
          <Tooltip
            description="Marking a question as answered will move it to the answered tab"
            placement="right"
          >
            <FormControlLabel
              control={
                <Controller
                  name="markAnswered"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                      color="primary"
                    />
                  )}
                />
              }
              label="Mark question as answered"
            />
          </Tooltip>
        </DialogContent>
        <DialogActions sx={{ paddingLeft: 3, paddingRight: 3 }}>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            data-test-id="btn-answer-question"
            color="secondary"
            variant="contained"
            disabled={formState.isSubmitting || hasErrors}
            onClick={handleSubmit(handleFormSubmit)}
          >
            {question.answer ? "Edit Answer" : "Answer"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  });
