import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { useEvent, useHasEventEnded } from "@src/providers/EventProvider";
import { format } from "date-fns";
import { useIsReplayEnabled } from "@src/providers/EventStateProvider";

const EventInfoStatic = () => {
  const { data: event } = useEvent();
  const hasEventEnded = useHasEventEnded();
  const replayEnabled = useIsReplayEnabled();

  if (!event) return null;

  return (
    <Stack
      padding={1}
      spacing={0.5}
      justifyContent="flex-start"
      overflow="hidden"
      paddingTop={{ xs: 0, lg: 1 }}
    >
      <Typography
        fontSize={{ xs: "1.4rem", md: "1.5rem", lg: "1.6rem" }}
        fontWeight="600"
        color="common.white"
        noWrap
      >
        {event.name}
      </Typography>
      {hasEventEnded && replayEnabled ? null : (
        <Stack
          spacing={1}
          alignItems="center"
          direction={{ xs: "column", lg: "row" }}
        >
          <Stack direction="row" spacing={1}>
            <CalendarTodayOutlinedIcon
              sx={{ color: "common.white", fontSize: "1.5rem" }}
            />

            <Typography
              fontSize={{ xs: "1rem", md: "1.1rem", lg: "1.2rem" }}
              color="common.white"
              paddingTop="1px"
              noWrap
            >
              {format(event.startDate, "EEEE, MMMM d, yyyy")} |{" "}
              {event.startDate.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default EventInfoStatic;
