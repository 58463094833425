import { useTheme } from "@mui/material/styles";
import React from "react";
import Button, { Props } from "./Button";

interface OutlineButtonProps extends Props {
  backgroundColor?: string;
  textColor?: string;
}

const OutlineButton = (props: OutlineButtonProps) => {
  const { backgroundColor, textColor, sx, children, ...buttonProps } = props;
  const theme = useTheme();
  return (
    <Button
      {...buttonProps}
      variant="contained"
      loadingPosition="start"
      sx={{
        fontWeight: 500,
        color: textColor || "primary.main",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: textColor || "primary.main",
        backgroundColor: backgroundColor || "common.white",
        "&:hover": {
          color: textColor || "primary.main",
          backgroundColor: backgroundColor || "common.white",
          boxShadow: textColor
            ? `0 8px 25px -8px ${textColor || theme.palette.primary.main}`
            : undefined,
        },
        "&:disabled": {
          color: textColor || "primary.main",
          backgroundColor: backgroundColor || "common.white",
        },
        ...props.sx,
      }}
    >
      {children}
    </Button>
  );
};

export default OutlineButton;
