import Box from "@mui/material/Box";
import { InputBaseProps } from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useField } from "formik";
import React, { CSSProperties } from "react";

export const InputLabelStyled = styled(InputLabel)(
  ({ theme: { palette } }) => ({
    color: palette.text.primary,
    "&$focused:not($error)": {
      color: palette.text.primary,
    },
    "&.MuiInputLabel-shrink": {
      transform: "translate(0, 1.5px) scale(0.8)",
      letterSpacing: 1,
    },
  }),
);

interface TextInputLiveFeedbackProps {
  label: string;
  id: string;
  name: string;
  placeholder?: string;
  style?: CSSProperties;
  inputProps?: InputBaseProps["inputProps"];
  testId?: string;
}
export const TextInputLiveFeedback = ({
  label,
  testId,
  ...props
}: TextInputLiveFeedbackProps) => {
  const [field, meta] = useField(props);
  // Show inline feedback if EITHER
  // - the input is focused AND value is longer than 2 characters
  // - or, the has been visited (touched === true)
  const [didFocus, setDidFocus] = React.useState(false);
  const handleFocus = () => setDidFocus(true);
  const showFeedback =
    (!!didFocus && field.value.trim().length > 2) || meta.touched;

  return (
    <div className="w-full">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          mt: 1,
        }}
      >
        <InputLabelStyled
          sx={{
            display: "flex",
            textAlign: "left",
            my: 1,
            alignSelf: "start",
            fontWeight: "bold",
            textOverflow: "unset",
            whiteSpace: "unset",
          }}
          htmlFor={props.id}
        >
          {label}
        </InputLabelStyled>
        {showFeedback && !meta.error ? (
          <Typography
            aria-live="polite"
            id={`${props.id}-feedback`}
            color={"success.main"}
            sx={{ my: 1 }}
          >
            ✓
          </Typography>
        ) : null}
      </Box>
      <Box
        sx={{
          width: "100%",
        }}
        className={` ${showFeedback ? (meta.error ? "invalid" : "valid") : ""}`}
      >
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          onFocus={handleFocus}
          inputProps={{ "data-testid": testId }}
          sx={{
            legend: { display: "none" },
            fieldset: { top: "0", borderRadius: "5px" },
          }}
          {...field}
          {...props}
        />
      </Box>
      {showFeedback && meta.error ? (
        <Typography
          aria-live="polite"
          id={`${props.id}-feedback`}
          color={"error"}
          sx={{ my: 1 }}
        >
          {meta.error}
        </Typography>
      ) : null}
    </div>
  );
};
