import { useEvent } from "../EventProvider";
import { useNetworkingHub } from "../NetworkingHubProvider";
import { useCustomTheme } from "./useCustomTheme";

/**
 * Returns `true` if our branding should be shown based on one of the following conditions:
 * - Company level customization
 * - Event or Hub  customization
 * - Legacy event or hub whitelabel settings
 *
 * _NOTE_: When a hub is linked to an event, the event whitelabel settings will be used
 */
export function useShowPoweredBy() {
  const { data: hub } = useNetworkingHub();
  const { data: event } = useEvent();
  const { data: theme } = useCustomTheme();

  const legacyHideEventPoweredBy = event?.whitelabel?.showPoweredBy === false;
  const legacyHideHubPoweredBy = hub?.whitelabel?.showPoweredBy === false;
  // We need to respect the deprecated showPoweredBy if present and false
  // hubs linked to events will use the event whitelabel settings
  if (legacyHideEventPoweredBy || legacyHideHubPoweredBy) {
    return false;
  }

  // Powered by for new events/companies should be set on the theme
  const themePoweredBy = theme?.poweredByIntrovokeLabel?.enabled;

  // Default to always showing powered by if not set
  return typeof themePoweredBy === "undefined" || themePoweredBy === null
    ? true
    : Boolean(themePoweredBy);
}
