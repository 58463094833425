export enum CircleSortOrder {
  /**
   * Sorts the circles with decreasing order of activity
   */
  Active = "mostactive",
  /**
   * Sorts the circles in order of creation date
   */
  Default = "default",
}

/**
 * The query params related to the behavioral configuration of the app
 */
export interface Config {
  /**
   * Flag to start the application in test mode. This disables certain features such as pubnub,
   * query retries...
   */
  test: boolean;
  /**
   * Flag to allow entry with a name only
   */
  nameOnlyEntry: boolean;
  /**
   * Flag to hide the sidebar portion of the app and only show the Virtual Stage/Hub portion of the app
   */
  cleanUI: boolean;
  /**
   * Flag to hide the Virtual Stage/Hub portion of the app and only show the sidebar
   */
  hybridMode: boolean;
  /**
   * Flag used to filter the rooms visible to the user. This can be used to always show
   * the same rooms to the user or different rooms to different users based on this property
   */
  fixedRoomsFilter: string[];
  /**
   * Determines how the circles should be sorted
   */
  sortCirclesBy: CircleSortOrder;
  /*
   * determines if networking is labeled as a meeting or a session
   */
  meeting: boolean;
  /*
   * mode
   */
  mode?: string;
}

export const DEFAULT_CONFIG: Config = {
  test: false,
  nameOnlyEntry: false,
  cleanUI: false,
  hybridMode: false,
  fixedRoomsFilter: [],
  sortCirclesBy: CircleSortOrder.Default,
  meeting: false,
};
