import React from "react";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SvgIcon from "@mui/material/SvgIcon";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { SendIcon } from "../icons";
import {
  QuestionApprovedStatus,
  QuestionCreateInput,
} from "../../providers/qAndA/QandAProvider";
import { Tooltip } from "@src/components/Tooltip";
import { nanoid } from "nanoid";

const CheckboxStyled = styled(Checkbox)(({ theme: { palette } }) => ({
  marginRight: -12,
  color: palette.divider,
}));

const InputLabelContainer = styled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  marginTop: spacing(0.5),
}));

const CreateQuestionContainer = styled("form")(
  ({ theme: { spacing, breakpoints } }) => ({
    padding: spacing(0.5),
    paddingLeft: spacing(1.5),
    paddingRight: spacing(1.5),
    [breakpoints.down("sm")]: {
      paddingLeft: spacing(1),
      paddingRight: spacing(1),
    },
    marginTop: spacing(0.5),
  }),
);

const IconButtonStyled = styled(IconButton)(
  ({ theme: { spacing, palette } }) => ({
    padding: spacing(1),
    "&:hover": {
      backgroundColor: `${palette.primary.main}40`,
    },
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.26)",
      backgroundColor: "transparent",
    },
  }),
);

const FormSchema = z.object({
  question: z
    .string({
      // always return an empty string if error message is not specified
      // we don't want to bombard the user every time they type a question
      errorMap: (issue) => ({ message: issue.message || "" }),
    })
    .min(5),
  isAnonymous: z.boolean().optional(),
});

type QuestionForm = z.infer<typeof FormSchema>;

interface CreateQuestionGroupProps {
  submitQuestion: (data: QuestionCreateInput) => void;
  isAnonymousQnaEnabled?: boolean;
  isApproveQnaEnabled?: boolean;
}
export const CreateQuestionGroup: React.FC<CreateQuestionGroupProps> = ({
  submitQuestion,
  isAnonymousQnaEnabled = false,
  isApproveQnaEnabled = false,
}) => {
  const { formState, handleSubmit, reset, control } = useForm<QuestionForm>({
    mode: "all",
    defaultValues: {
      question: "",
      isAnonymous: false,
    },
    resolver: zodResolver(FormSchema),
  });

  return (
    <CreateQuestionContainer
      onSubmit={handleSubmit(({ question, isAnonymous }: QuestionForm) => {
        isAnonymous = isAnonymousQnaEnabled && !!isAnonymous;
        // approve question by default if approve qna is turned off
        const approved: QuestionApprovedStatus = isApproveQnaEnabled
          ? QuestionApprovedStatus.PENDING
          : QuestionApprovedStatus.APPROVED;
        submitQuestion({
          // create ids locally for optimistic updates
          // could create a fake localId field, but we already create ids locally for anonymous questions
          id: nanoid(),
          name: question,
          isAnonymous,
          approved,
        });
        reset({ question: "", isAnonymous });
      })}
    >
      <Controller
        control={control}
        name="question"
        render={({ field: { ref, ...field } }) => (
          <TextField
            {...field}
            id="question"
            error={!!formState.errors?.question}
            fullWidth
            helperText={formState.errors.question?.message}
            inputRef={ref}
            InputProps={{
              sx: ({ typography, palette }) => ({
                borderRadius: 2,
                fontSize: typography.body1.fontSize,
                minHeight: "1em",
                padding: 0,
                paddingTop: 0.5,
                paddingBottom: 0.5,
                backgroundColor: palette.grey[100],
                borderColor: palette.divider,
                borderStyle: "solid",
                borderWidth: 1,
                "&:hover": {
                  backgroundColor: palette.grey[100],
                  borderColor: palette.primary.main,
                },
              }),
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButtonStyled
                    aria-label="submit question"
                    color="secondary"
                    disabled={formState.isSubmitting || !formState.isValid}
                    type="submit"
                    size="large"
                  >
                    <SvgIcon>
                      <SendIcon />
                    </SvgIcon>
                  </IconButtonStyled>
                </InputAdornment>
              ),
            }}
            inputProps={{
              sx: {
                minHeight: "1em",
                padding: 1,
              },
            }}
            placeholder="My question is..."
            name="question"
            required
            variant="filled"
          />
        )}
      />

      {isAnonymousQnaEnabled && (
        <InputLabelContainer>
          <Tooltip
            placement="bottom"
            description="Checking this box will allow you to ask questions anonymously by hiding your name from other participants"
          >
            <InputLabel
              sx={{
                color: "inherit",
                marginBottom: 0,
              }}
            >
              Ask anonymously
            </InputLabel>
          </Tooltip>
          <Controller
            name="isAnonymous"
            control={control}
            render={({ field }) => (
              <CheckboxStyled
                {...field}
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                color="primary"
              />
            )}
          />
        </InputLabelContainer>
      )}
    </CreateQuestionContainer>
  );
};
