import { useEffect } from "react";

interface GlobalIntervalObject {
  callbacks: Array<() => void>;
  intervalId?: number;
  start(): void;
  stop(): void;
  addListener(cb: () => void): void;
  removeListener(cb: () => void): void;
}

const GlobalInterval: GlobalIntervalObject = {
  callbacks: [],
  intervalId: undefined,
  start() {
    if (this.callbacks.length > 0 && this.intervalId === undefined) {
      this.intervalId = window.setInterval(() => {
        this.callbacks.forEach((cb) => cb());
      }, 1000);
    }
  },
  stop() {
    if (this.callbacks.length === 0 && this.intervalId !== undefined) {
      window.clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
  },
  addListener(cb: () => void) {
    this.callbacks.push(cb);
    this.start();
  },
  removeListener(cb: () => void) {
    const index = this.callbacks.indexOf(cb);
    if (index !== -1) {
      this.callbacks.splice(index, 1);
      this.stop();
    }
  },
};

interface UseGlobalIntervalArgs {
  // callback invoked on interval
  callback: () => void;
  // disable calling the provided callback
  disabled?: boolean;
}

const useGlobalInterval = ({ callback, disabled }: UseGlobalIntervalArgs) => {
  useEffect(() => {
    if (disabled) return;
    GlobalInterval.addListener(callback);
    return () => {
      GlobalInterval.removeListener(callback);
    };
  }, [disabled, callback]);
};

export default useGlobalInterval;
