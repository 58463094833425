import React, {
  type ReactNode,
  type ElementType,
  type ElementRef,
  type ForwardedRef,
  useRef,
  useImperativeHandle,
} from "react";

import { useTruncated } from "@src/hooks/useTruncated";
import { useEventListener } from "@react-hookz/web";
import throttle from "lodash/throttle";
import { cn } from "@introvoke/react/styles/utils";
import type { OverridableComponentPropsWithRef } from "@introvoke/react/types/components";
import { useShowPoweredBy } from "@src/providers/customization/useShowPoweredBy";
import {
  FeaturedIcon,
  FeaturedIconProps,
} from "@introvoke/react/components/FeaturedIcon";
import poweredByImage from "@src/static/logos/sequel-logo-small-black.svg";

type RegistrationSidebarViewProps = {
  icon?: FeaturedIconProps["icon"];
  iconColor?: FeaturedIconProps["color"];
  title: ReactNode;
  description: ReactNode;
  content?: ReactNode;
  actions?: ReactNode;
};

const RegistrationSidebarView = <T extends ElementType = "div">(
  {
    as,
    icon,
    iconColor = "primary",
    title,
    description,
    content,
    actions,
    className,
    ...restProps
  }: OverridableComponentPropsWithRef<RegistrationSidebarViewProps, T>,
  ref: ForwardedRef<ElementRef<T>>,
) => {
  const Component = as ?? "div";

  const componentRef = useRef<ElementRef<"div">>(null);

  const hasContent = !!content;
  const hasActions = !!actions;
  const showPoweredBy = useShowPoweredBy();

  const { truncated, check: checkTruncated } = useTruncated(
    componentRef,
    "Height",
  );

  useEventListener(
    componentRef.current,
    "scroll",
    throttle(checkTruncated, 2500, { leading: true, trailing: false }),
  );

  useImperativeHandle(ref, () => componentRef.current as ElementRef<T>, []);

  return (
    <Component
      {...restProps}
      className={cn(
        "relative flex h-full w-full flex-col justify-start overflow-hidden overflow-y-auto bg-white py-9",
        (hasActions || showPoweredBy) && "pb-0",
        className,
      )}
      ref={componentRef}
    >
      {/* header */}
      <div className="mb-8 flex w-full flex-col gap-2 px-9">
        {icon && (
          <FeaturedIcon icon={icon} color={iconColor} variant="square" />
        )}
        <h1 className="z-10 text-2xl font-bold">{title}</h1>
        <p className="z-10 text-sm font-normal text-gray-400">{description}</p>
      </div>
      {/* content */}
      {hasContent && (
        <div className="z-10 flex w-full flex-col px-9">{content}</div>
      )}
      {/* actions */}
      {hasActions && (
        <div
          className={cn(
            "sticky z-10 flex w-full flex-col gap-2 bg-white px-9",
            hasContent && "mt-4",
            truncated && "border-t border-gray-200 pt-4 shadow-reverse-200",
            showPoweredBy ? "bottom-7.5 pb-0" : "bottom-0 pb-4",
          )}
        >
          {actions}
        </div>
      )}
      {showPoweredBy && (
        <div
          className={cn(
            "z-10 flex w-full items-center justify-center bg-white px-9 py-2",
            hasActions ? "sticky bottom-0" : hasContent ? "pt-4" : "pt-0",
          )}
        >
          <a
            href="https://www.sequel.io?utm_source=powered-by&utm_medium=chat-logo"
            target="_blank"
            rel="noopener noreferrer"
            className="flex h-[12px] w-fit items-center gap-1 text-xs text-gray-500"
          >
            powered by
            <img
              src={poweredByImage}
              alt="Powered by Sequel"
              className="h-full w-auto"
            />
          </a>
        </div>
      )}
    </Component>
  );
};

const ForwardedRegistrationSidebarView = React.forwardRef(
  RegistrationSidebarView,
);

export default React.memo(
  ForwardedRegistrationSidebarView,
) as typeof RegistrationSidebarView;
