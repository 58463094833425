import isString from "lodash/isString";

import { isObjectWithProps } from "../../guards/isObjectWithProps";
import type { User } from "../chat/ChatProvider";

export const isValidUser = (data: any): data is User => {
  return (
    isObjectWithProps(data, "uid", "profilePicture", "name") &&
    isString(data.uid) &&
    isString(data.name) &&
    isString(data.profilePicture)
  );
};
