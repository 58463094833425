import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import invariant from "tiny-invariant";
import difference from "lodash/difference";
import uniqBy from "lodash/uniqBy";
import PubNub, { PubnubStatus, GetStateResponse, PubnubConfig } from "pubnub";
import SplayTree from "splaytree";

import { useConfigValue } from "../config";
import { UserRole } from "../../contracts/user/user";
import { useUser } from "../UserProvider";
import { getChannelConfig } from "../chat/getChannelConfig";
import { usePresenceListener } from "./usePresenceListener";
import { usePubNubHereNow } from "./usePubNubHereNow";
import { v4 } from "uuid";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import {
  ChannelTypeEnum,
  type ChannelConfig,
  type PresentUserIds,
  type PresentUsers,
  type PubnubChannel,
  type PubnubState,
  type ChannelType,
  type Message,
  type PresentUser,
  type PubnubUser,
} from "./types";
import { FeatureFlag, useFeatureFlag } from "../FeatureFlagsProvider";
import { IntrovokeChatProvider } from "../chat/ChatProvider";

export type {
  ChannelConfig,
  PresentUserIds,
  PresentUsers,
  PubnubChannel,
  PubnubState,
  ChannelType,
  Message,
  PresentUser,
  PubnubUser,
};

export { ChannelTypeEnum };

export interface PubNubContextValues {
  presenceChannelId: string | null;
  pubnub: PubNub;
  subscribe: (config: ChannelConfig) => void;
  topicId: string;
  unsubscribe: (config: string | ChannelConfig) => void;
  channels: PubnubChannel[];
  userInfoRef: React.MutableRefObject<PresentUsers>;
  presentUserIdsRef: React.MutableRefObject<PresentUserIds>;
  updateUserInfo: (userId: string) => void;
}

// TODO: Move these to ENV variables
const PUBNUB_PUBLISH_KEY = "pub-c-47352a9a-9cad-4e69-927b-a213f1900012";
const PUBNUB_SUBSCRIBE_KEY = "sub-c-0a619b34-9a16-11ea-8d30-d29256d12d3d";
// test key
// publishKey: 'pub-c-97d4e3a7-4cb3-45a9-a6c7-66d87193c1a1',
// subscribeKey: 'sub-c-c53f0b3a-a830-11ea-baa3-a65cc700836a',

// Pubnub times are given in 17-digit precision Unix time (UTC):https://www.pubnub.com/docs/chat/features/messages#receive-messages
export const pubNubTimeTokenToMillis = (timetoken: number | string): number => {
  const msTime: number =
    typeof timetoken === "string" ? parseInt(timetoken) : timetoken;
  return Math.round(msTime / 10000);
};

const PubNubContext = React.createContext<PubNubContextValues | null>(null);

export const pubnubAtom = atom<PubNub | null>({
  key: "pubnub",
  default: null,
  dangerouslyAllowMutability: true, // needed to add listeners to the pubnub object / modify properties
});

const pubNubConfigAtom = atom<PubnubConfig | undefined>({
  key: "pubNubConfigAtom",
  default: undefined,
  dangerouslyAllowMutability: true, // needed to modify later
});

/**
 * Returns the currently active pubnub configurations
 */
export const usePubNubConfig = () => useRecoilState(pubNubConfigAtom);

/**
 * Returns the current PubNub instance object
 */
export const usePubNub = () => useRecoilValue(pubnubAtom);

export const usePubNubState = (): [PubNub, (pubnub: PubNub | null) => void] => {
  const user = useUser();
  const { test } = useConfigValue();
  const [pubnubState, setPubnubState] = useRecoilState(pubnubAtom);
  const [, setPubNubConfig] = usePubNubConfig();

  const setPubnub = useCallback(
    (pubnub: PubNub | null) => setPubnubState(pubnub),
    [setPubnubState],
  );

  const useOriginalId = useFeatureFlag(FeatureFlag.USE_ORIGINAL_ID_PUBNUB);

  // Unregistered users have a random UUID, let's give them
  // the same UUID to avoid creating too many MAU's
  // as the announcements require a UUID to connect and publish when failing to join...
  const userId =
    user.userRole < UserRole.Unregistered
      ? useOriginalId
        ? user.originalId
        : user.uid
      : "00000000-0000-0000-0000-000000000000";
  const pubnub = useMemo(() => {
    if (pubnubState) {
      return pubnubState;
    }

    const config = {
      publishKey: test ? "test-key" : PUBNUB_PUBLISH_KEY,
      subscribeKey: test ? "test-key" : PUBNUB_SUBSCRIBE_KEY,
      uuid: test ? "test-user" : userId,
      restore: true,
    };
    const pubnubInstance = new PubNub(config);

    setPubnubState(pubnubInstance);
    setPubNubConfig(config);

    return pubnubInstance;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPubnubState, test, userId]);

  /**
   * Update the PubNub UUID when we modify user uid
   */
  useEffect(() => {
    const id = test ? "test-user" : userId;
    if (id !== pubnub.getUUID()) {
      pubnub.setUUID(id);
    }
  }, [pubnub, test, userId]);

  return [pubnub, setPubnub];
};

export const PubNubProvider: React.FC<{ topicId: string }> = memo(
  ({ children, topicId }) => {
    const user = useUser();
    const { test } = useConfigValue();
    const deviceIdRef = useRef(v4());

    const [channels, setChannels] = useState<PubnubChannel[]>([]);
    const subscribedChannelIdsRef = useRef<string[]>([]);
    const subscribedChannelIds = subscribedChannelIdsRef.current;
    const channelIds = useMemo(
      () => channels.map((channel) => channel.id),
      [channels],
    );

    const [pubnub, setPubnub] = usePubNubState();

    const pubnubUser = useMemo(
      () => ({
        uid: test ? "test-user" : user.uid,
        profilePicture: user.profilePicture,
        name: test ? "test-user" : user.name,
        role: test ? UserRole.Viewer : user.userRole,
        email: test ? "test-user@introvoke.com" : user.email,
      }),
      [
        test,
        user.email,
        user.name,
        user.profilePicture,
        user.uid,
        user.userRole,
      ],
    );

    const unsubscribe = useCallback((config: string | ChannelConfig) => {
      const channelId = typeof config !== "string" ? config.channel : config;

      setChannels((previousChannels) =>
        previousChannels.filter((channel) => channel.id !== channelId),
      );
    }, []);

    const subscribe = useCallback(
      (config: ChannelConfig) => {
        setChannels((previousChannels) => {
          return uniqBy(
            [
              ...previousChannels,
              {
                id: config.channel,
                type: config.type,
                newMessages: 0,
              },
            ],
            "id",
          );
        });
        return () => unsubscribe(config);
      },
      [unsubscribe],
    );

    const presenceChannel = useMemo(
      () =>
        getChannelConfig({
          type: "presence",
          event: topicId,
        }),
      [topicId],
    );

    const userInfoRef = useRef<PresentUsers>({});
    const presentUserIdsRef =
      useRef() as React.MutableRefObject<PresentUserIds>;
    if (!presentUserIdsRef.current) {
      // NOTE: Use Intl Collator over String.localeCompare for performance reasons: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare#performance
      const localeCompare = new Intl.Collator(undefined, {
        sensitivity: "base",
      }).compare;
      const comparator = (aUserId: string, bUserId: string) => {
        if (aUserId === bUserId) {
          return 0;
        }

        const aUser = userInfoRef.current[aUserId];
        const bUser = userInfoRef.current[bUserId];

        const validUserCompare = aUser && bUser ? 0 : aUser ? -1 : 1;
        if (validUserCompare !== 0) {
          return validUserCompare;
        }

        const roleCompare =
          (typeof aUser.userRole === "number" ? aUser.userRole : 99) -
          (typeof bUser.userRole === "number" ? bUser.userRole : 99);
        if (roleCompare !== 0) {
          return roleCompare;
        }

        // Sort alphabetically by username or who has a username first
        const nameCompare =
          aUser.username && bUser.username
            ? localeCompare(aUser.username, bUser.username)
            : aUser.username
            ? -1
            : bUser.username
            ? 1
            : 0;
        if (nameCompare !== 0) {
          return nameCompare;
        }

        // Sort by UUID for deterministic ordering
        const uuidCompare = aUserId < bUserId ? -1 : 1;
        return uuidCompare;
      };

      const tree = new SplayTree<string>(comparator);

      presentUserIdsRef.current = Object.assign(tree, {
        *[Symbol.iterator]() {
          // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Symbol/iterator
          let n = tree.minNode() as ReturnType<typeof tree.minNode> | null;
          while (n) {
            yield n.key;
            n = tree.next(n);
          }
        },
      });
    }

    const presenceListener = usePresenceListener({
      userInfoRef,
      presentUserIdsRef,
    });

    // prevent Unregistered users from being 'present' in the event
    const disablePresence =
      !topicId ||
      !user.uid ||
      !user.name ||
      user.userRole === UserRole.Unregistered;

    const presenceChannelId = useMemo(() => {
      const presenceChannels = channels.filter(
        (channel) => channel.type === "presence",
      );
      if (presenceChannels[0]) {
        return presenceChannels[0].id;
      }
      return null;
    }, [channels]);

    usePubNubHereNow({
      presenceChannelId,
      pubnub,
      topicId,
      userInfoRef,
      presentUserIdsRef,
    });

    // hide presence and subscribe/subscribe based on 'hideParticipants' event config
    useEffect(() => {
      if (disablePresence) {
        return;
      }

      pubnub.addListener(presenceListener);
      subscribe(presenceChannel);
      return () => {
        pubnub.removeListener(presenceListener);
        unsubscribe(presenceChannel);
      };
    }, [
      disablePresence,
      presenceChannel,
      presenceListener,
      pubnub,
      subscribe,
      unsubscribe,
    ]);

    /**
     * When subscribed channels change:
     *  take the difference and subscribe to new channels while unsubscribing from old channels
     */
    useEffect(() => {
      // subscribe to these channels without presence monitoring
      const toSubscribeIds = difference(
        channelIds,
        subscribedChannelIds,
      ).filter((channel) => !channel.includes("presence"));

      // only subscribe to these channels 'withPresence'
      const toSubscribeWithPresenceIds = difference(
        channelIds,
        subscribedChannelIds,
      ).filter((channel) => channel.includes("presence"));

      // unsubscribe from these channels, as they are no longer in our channels list
      const toUnsubscribeIds = difference(subscribedChannelIds, channelIds);

      if (toSubscribeIds.length) {
        pubnub.subscribe({ channels: toSubscribeIds });
      }

      if (toSubscribeWithPresenceIds.length) {
        pubnub.subscribe({
          channels: toSubscribeWithPresenceIds,
          withPresence: true,
        });
      }

      if (toUnsubscribeIds.length) {
        pubnub.unsubscribe({ channels: toUnsubscribeIds });
      }

      subscribedChannelIdsRef.current = channelIds;
    }, [channelIds, pubnub, subscribedChannelIds]);

    /**
     * Update the PubNub user when we modify user qualities
     */
    useEffect(() => {
      if (!test) {
        pubnub.setState(
          {
            channels: channelIds,
            state: {
              username: user.name,
              userRole: user.userRole,
              avatar: user.profilePicture,
              deviceId: deviceIdRef.current,
            },
          },
          (event) => {},
        );

        if (user.name || user.profilePicture || user.email) {
          pubnub.objects.setUUIDMetadata({
            data: {
              name: user.name || undefined,
              profileUrl: user.profilePicture || undefined,
              email: user.email || undefined,
            },
          });
        }
      }
    }, [
      pubnub,
      test,
      channelIds,
      user.userRole,
      user.name,
      user.profilePicture,
      user.email,
    ]);

    useEffect(() => {
      return () => {
        // make sure to unsubscribe before stopping if haven't already
        pubnub.removeListener(presenceListener);
        unsubscribe(presenceChannel);
        // Stop pubnub on unmount
        pubnub?.stop();
        setPubnub(null);
      };
    }, [presenceChannel, presenceListener, pubnub, setPubnub, unsubscribe]);

    // There are race conditions with PubNub HereNow on mount and the
    // presence subscriptions that can cause missed user state. This
    // function allows us to retrieve missing state data for users
    const updateUserInfo = useCallback(
      (userId: string) => {
        pubnub.getState(
          { uuid: userId, channels: [presenceChannelId || ""] },
          (status: PubnubStatus, response: GetStateResponse) => {
            if (presenceChannelId && response?.channels?.[presenceChannelId]) {
              const shouldReadd = presentUserIdsRef.current.contains(userId);
              if (shouldReadd) {
                // NOTE: If already present and we have new state data, we need
                // to delete user before modifying their properties so sort order
                // is handled correctly
                presentUserIdsRef.current.remove(userId);
              }

              userInfoRef.current[userId] = {
                ...userInfoRef.current[userId],
                // copy latest data from PubNub into user Info
                ...response.channels[presenceChannelId],
                userId,
              } as PubnubState;

              if (shouldReadd) {
                presentUserIdsRef.current.add(userId);
              }
            }
          },
        );
      },
      [userInfoRef, presentUserIdsRef, presenceChannelId, pubnub],
    );

    const contextValue = useMemo(() => {
      return {
        presenceChannelId,
        pubnub,
        topicId,
        subscribe,
        unsubscribe,
        channels,
        userInfoRef,
        presentUserIdsRef,
        updateUserInfo,
      };
    }, [
      channels,
      topicId,
      presenceChannelId,
      pubnub,
      subscribe,
      unsubscribe,
      updateUserInfo,
    ]);

    return (
      <PubNubContext.Provider value={contextValue}>
        <IntrovokeChatProvider pubnub={pubnub} user={pubnubUser}>
          {children}
        </IntrovokeChatProvider>
      </PubNubContext.Provider>
    );
  },
);

export const usePubNubContext = () => {
  const ctx = useContext(PubNubContext);

  invariant(ctx, "usePubNubContext called outside of PubNubContext");

  return ctx;
};
