import { extractIdsFromUrl } from "@src/helpers/utils";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export interface RouteParams {
  eventId?: string;
  networkingHubId?: string;
}

/**
 * Returns the known dynamic route parameters.
 *
 * Currently only supports event and hub route ID's
 */
export function useRouteParams() {
  const { pathname } = useLocation();
  return useMemo<RouteParams>(
    () => extractIdsFromUrl(),
    // eslint-disable-next-line
    [pathname],
  );
}
