import React, { useMemo } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import MuiSelect, { SelectProps as MuiSelectProps } from "@mui/material/Select";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";

const LoadingIndicator = () => (
  <CircularProgress size="1.2rem" sx={{ color: "inherit", mr: 1.75 }} />
);
export interface SelectItemProps extends MenuItemProps {
  label: string;
  isHeader?: boolean;
}
export interface SelectProps extends MuiSelectProps {
  /**
   * The options to be displayed in the dropdown
   */
  options: SelectItemProps[];
  /**
   * The default placeholder value displayed in the dropdown
   */
  placeholder: string;
  /**
   * The value of the selected option
   */
  value: any;
  /**
   * Disables the placeholder
   */
  disablePlaceholder?: boolean;
  /**
   * Callback to handle the onChange of the select
   */
  onChange?: (value: any) => void;
  /**
   * Props to be passed down to the select menu items
   */
  itemProps?: MenuItemProps;
  /**
   * Indicates that the select is loading and shows an indicator
   */
  loading?: boolean;
  /**
   * React Element type for the loading indicator
   */
  loadingIndicator?: React.ElementType;
}
export const Select = React.memo(
  ({
    value,
    disablePlaceholder = false,
    placeholder = "None",
    options = [],
    onChange,
    sx = {},
    itemProps = {},
    loading,
    loadingIndicator,
    ...restProps
  }: SelectProps) => {
    const { sx: itemsSx, ...restItemsProps } = itemProps;
    const defaultOptions = useMemo<SelectItemProps[]>(
      () => [
        {
          label: placeholder,
          value: "",
          disabled: disablePlaceholder,
        },
        ...options,
      ],
      [disablePlaceholder, options, placeholder],
    );

    return (
      <MuiSelect
        onChange={onChange}
        value={value || ""}
        displayEmpty
        size="small"
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            top: -1,
            borderColor: "rgba(0, 0, 0, 0.23)",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "text.primary",
          },
          // fix placeholder still being expected
          "& legend": {
            display: "none",
          },
          ...sx,
        }}
        IconComponent={
          loading ? loadingIndicator || LoadingIndicator : undefined
        }
        {...restProps}
      >
        {defaultOptions.map(({ value, label, disabled, isHeader }, i) =>
          isHeader ? (
            <ListSubheader
              key={label}
              sx={{
                lineHeight: 3,
                fontSize: 11,
              }}
            >
              {label}
            </ListSubheader>
          ) : (
            <MenuItem
              key={String(value)}
              value={value}
              disabled={disabled}
              sx={{
                fontWeight: 600,
                ...itemsSx,
              }}
              {...restItemsProps}
            >
              {label}
            </MenuItem>
          ),
        )}
      </MuiSelect>
    );
  },
);
