import invariant from "tiny-invariant";
import React, {
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useQueryClient } from "react-query";

import { useSocketClient } from "../../components/Presence/SocketClientProvider";
import { useUser } from "../UserProvider";
import {
  CreateEventPollInput,
  Poll,
  PollStatus,
  SelectEventPollInput,
} from "./types";
import { useCreateEventPoll } from "./hooks/useCreateEventPoll";
import { useHideEventPoll } from "./hooks/useHideEventPoll";
import { useDeleteEventPoll } from "./hooks/useDeleteEventPoll";
import { useVoteEventPollOption } from "./hooks/useVoteEventPollOptions";
import { useUpdateEventPollStatus } from "./hooks/useUpdateEventPollStatus";
import { usePublishEventPollResults } from "./hooks/usePublishEventPollResults";
import { useListEventPolls } from "./hooks/useListEventPolls";
import { useUpdateEventPoll } from "./hooks/useUpdateEventPoll";
import { useUnmount } from "react-use";
import { createPollsQueryKey } from "./helpers";

export interface PollContextValue {
  handleCreateEventPoll: (data: CreateEventPollInput) => Promise<unknown>;
  handleListEventPolls: () => Promise<Poll[]>;
  handleVoteEventPollOption: (data: {
    poll: string;
    option: string;
  }) => Promise<unknown>;
  handleHideEventPoll: (input: SelectEventPollInput) => Promise<unknown>;
  handleDeleteEventPoll: (input: SelectEventPollInput) => Promise<unknown>;
  handleUpdatePollStatus: (data: {
    poll: string; // The poll ID
    status: PollStatus;
  }) => Promise<unknown>;
  handlePublishPollResults: (data: {
    poll: string; // The poll ID
    publish?: boolean;
  }) => Promise<unknown>;
  polls: Poll[];
  pendingPolls: Poll[];
  activePolls: Poll[];
  closedPolls: Poll[];
  archivedPolls: Poll[];
  isLoading: boolean;
  isError: boolean;
}

export const PollContext = createContext<PollContextValue | null>(null);

export const usePollContext = () => {
  const ctx = useContext(PollContext);

  invariant(ctx, "usePollContext used outside of PollContext");

  return ctx;
};

interface Props {
  /**
   * The current Event or Networking Hub ID
   */
  eventId: string;

  /**
   * The current circle ID if in a circle
   */
  circleId?: string;
}

export const PollProvider: React.FC<Props> = memo(
  ({ children, eventId, circleId }) => {
    const cache = useQueryClient();
    const client = useSocketClient();
    const user = useUser();
    const queryClient = useQueryClient();

    const {
      mutation: { mutateAsync: onCreateEventPoll },
      handlePollCreated,
    } = useCreateEventPoll({ client, eventId, circleId, user });

    const {
      mutation: { mutateAsync: onVoteEventPollOption },
    } = useVoteEventPollOption({ client, eventId, circleId });

    // Deleting and hiding polls are essentially the same
    // Hiding polls soft-deletes the poll while deleting permanently deletes them
    const {
      mutation: { mutateAsync: onHideEventPoll },
      handlePollHidden,
    } = useHideEventPoll({ client, eventId, circleId });

    const {
      mutation: { mutateAsync: onDeleteEventPoll },
      handlePollDeleted,
    } = useDeleteEventPoll({ client, eventId, circleId });

    const {
      mutation: { mutateAsync: onUpdatePollStatus },
    } = useUpdateEventPollStatus({ client, eventId, circleId });

    const {
      mutation: { mutateAsync: onPublishPollResults },
    } = usePublishEventPollResults({ client, eventId, circleId });

    const {
      mutation: { mutateAsync: onListEventPolls },
      allPolls,
      query,
    } = useListEventPolls({ client, eventId, circleId, user });

    const { handlePollUpdated } = useUpdateEventPoll({
      client,
      eventId,
      circleId,
    });

    useEffect(() => {
      client.on("poll-created", handlePollCreated);
      client.on("poll-deleted", handlePollDeleted);
      client.on("poll-hidden", handlePollDeleted);
      client.on("poll-updated", handlePollUpdated);

      return () => {
        client.off("poll-created", handlePollCreated);
        client.off("poll-deleted", handlePollDeleted);
        client.off("poll-hidden", handlePollDeleted);
        client.off("poll-updated", handlePollUpdated);
      };
    }, [
      cache,
      client,
      handlePollCreated,
      handlePollDeleted,
      handlePollHidden,
      handlePollUpdated,
    ]);

    useUnmount(() => {
      // reset queries when switching between hubs/events
      queryClient.resetQueries(createPollsQueryKey(eventId, circleId));
    });

    const contextValue = useMemo(
      () => ({
        ...allPolls,
        isLoading: !query.data, // manually calculate isLoading since isFetching is not updating properly
        isError: query.isError,
        handleDeleteEventPoll: onDeleteEventPoll,
        handleCreateEventPoll: onCreateEventPoll,
        handleListEventPolls: onListEventPolls,
        handleVoteEventPollOption: onVoteEventPollOption,
        handleHideEventPoll: onHideEventPoll,
        handleUpdatePollStatus: onUpdatePollStatus,
        handlePublishPollResults: onPublishPollResults,
      }),
      [
        query.data,
        query.isError,
        allPolls,
        onDeleteEventPoll,
        onCreateEventPoll,
        onListEventPolls,
        onVoteEventPollOption,
        onHideEventPoll,
        onUpdatePollStatus,
        onPublishPollResults,
      ],
    );

    return (
      <PollContext.Provider value={contextValue}>
        {children}
      </PollContext.Provider>
    );
  },
);

export const PollConsumer = ({
  children,
}: {
  children: (value: PollContextValue) => JSX.Element;
}) => {
  const ctx = usePollContext();
  return children(ctx);
};

export * from "./types";
export * from "./helpers";
