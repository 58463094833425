import { useCallback } from "react";
import invariant from "tiny-invariant";

import { NavigationHelper } from "../../../helpers/navigation";
import { appendReturnToEvent } from "../../../helpers/utils";
import { useEvent } from "../../../providers/EventProvider";

export const useHandleJoinNetworking = (): (() => void) => {
  const { data: event } = useEvent();
  invariant(event, "Event is required");

  return useCallback(
    () =>
      NavigationHelper.navigate(
        undefined,
        `/networkingHub/${
          event.networkingHub || event.organizerProfile!.personalLink
        }`,
        appendReturnToEvent(window.location.search, event.uid),
      ),
    [event.networkingHub, event.organizerProfile, event.uid],
  );
};
