import { logFormatted } from "@src/helpers/logging";

export const IGNORE_MESSAGES_PATTERNS = [
  // Ignore all these errors
  /Request aborted/i,
  // This is a timeout in axios which is used by amplify package, we get other errors later
  // so no reason to report this message: https://github.com/axios/axios/issues/2103
  /timeout of 0ms exceeded/i,
];

export const WARNINGS_MESSAGES_PATTERNS = [
  // Log these errors as warnings instead of errors since they're most likely user related issues
  /Connection closed/i,
  /Connection handshake/i,
  /Network error/i,
  /max number of retries exceeded/i,
  /is not valid or it has expired/i,
  /Subscription timeout/i,
  /Timeout Disconnect/i,
  // This is a known issue w/ the load balancer timing out: https://sequelio.slack.com/archives/C025B75NXD0/p1670519955347179
  // set as a warning until we can fix this, doesn't affect most people and it recovers fine
  /Task timed out after/i,
];

/**
 * Helper function to get the first value of an object. This is useful for graphQL
 * since most queries,mutations, and subscriptions have a single field in the
 * main query.
 */
export const getFirstValue = <T>(obj: T | undefined | null) =>
  (obj &&
    // NOTE: Fancy way of getting the first key and preserving the generic type
    (obj as T)[
      // @ts-ignore
      (Object.keys(obj as T) as Array<keyof Omit<T, "__typename">>)[0]
    ]) ??
  null;

const matchFactory = (patterns: RegExp[]) => (message?: string) =>
  patterns.some((matcher) => !!message?.match(matcher));

export const isWarning = matchFactory(WARNINGS_MESSAGES_PATTERNS);
export const isIgnored = matchFactory(IGNORE_MESSAGES_PATTERNS);

export const logGqlError = (message: string, error?: any) => {
  const firstError =
    error && "errors" in error
      ? error.errors[0]
      : Array.isArray(error)
      ? error[0]
      : error;

  if (isIgnored(message) || isIgnored(firstError?.message)) return;

  const level =
    isWarning(message) || isWarning(firstError?.message) ? "warning" : "error";

  logFormatted(message, error, level);
};
