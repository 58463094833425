import React, {
  ReactNode,
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import invariant from "tiny-invariant";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { usePreviousDistinct } from "react-use";

import { useEventChatContext } from "../chat/ChatProvider";
import { Tab } from "../../components/SideBar/useCommonController";
import { useConfigValue } from "../config";
import { SideBarTabs } from "@src/components/SideBar/types";
import { useEventSideBarTabs } from "./useEventSideBarTabs";
import { useNetworkingHubSideBarTabs } from "./useNetworkingHubSideBarTabs";
import { useMountEffect } from "@react-hookz/web";
import { useUser } from "../UserProvider";

export type SidebarTabsItem = Tab & {
  /**
   * The compact version of the badge count. Example: 1.2k
   */
  badgeCountCompact?: string;
};

export interface SideBarTabsContextValue {
  currentTab: SideBarTabs | null;
  setCurrentTab: (tabKey: SideBarTabs | null) => void;
  tabs: SidebarTabsItem[];
}

type TabBadgeDictionary = Record<string, { current: number; initial: number }>;

/**
 * Some NumberFormat options aren't supported on Safari 14.1, so this is a basic fallback mechanism
 * https://introvoke.atlassian.net/browse/PROD-2095
 */
const format = (num: number) => {
  if (num < 1000) {
    return `${Math.floor(num)}`;
  } else if (num < 1000000) {
    return `${Math.floor(num / 1000)}K`;
  } else if (num < 1000000000) {
    return `${Math.floor(num / 1000000)}M`;
  } else {
    // We should never have this large, but handle it just in case
    return `${Math.floor(num / 1000000000)}G`;
  }
};

const createBadgeFormatter = () => {
  try {
    return Intl.NumberFormat("en", { notation: "compact" });
  } catch (err) {
    console.warn("Error creating NumberFormat", err);
  }

  return { format };
};

export interface SidebarContextType {
  openedTabId: SideBarTabs | null;
  setOpenedTabId: (tabId: SideBarTabs | null) => void;
}

const SidebarContext = createContext<SidebarContextType | null>(null);

interface SidebarProviderProps {
  children: ReactNode;
}

export const SidebarProvider = ({ children }: SidebarProviderProps) => {
  const [openedTabId, setOpenedTabId] = useState<SideBarTabs | null>(null);

  const value = useMemo(
    () => ({
      openedTabId,
      setOpenedTabId,
    }),
    [openedTabId],
  );

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
};
export const useSidebarContext = (): SidebarContextType => {
  const context = useContext(SidebarContext);

  if (!context) {
    throw new Error("useSidebarContext must be used within SidebarProvider");
  }

  return context;
};

const badgeFormatter = createBadgeFormatter();

/**
 * Returns the badge number in a compact format. Example: `"1.2k"` or `undefined` if the number is `undefined | 0`
 */
export const formatBadgeCount = (num?: number) =>
  num && typeof num === "number" ? badgeFormatter.format(num) : undefined;

// create a dictionary mapping to track unread item count on tabs
// only tabs where we desire to track the badge count have an entry
const getBadgeCountDictionary = (
  tabs: SidebarTabsItem[],
  existingCounts: TabBadgeDictionary = {},
): TabBadgeDictionary =>
  tabs.reduce<TabBadgeDictionary>((prev, { badgeCount, key }) => {
    if (typeof badgeCount === "number") {
      // if initial count exists and we're adding to the total, increment the badge count
      const totalCountUpdated =
        prev[key]?.initial >= 0 &&
        prev[key].initial !== badgeCount &&
        prev[key].initial < badgeCount; // don't show badges for item deletion events

      if (totalCountUpdated) {
        prev[key] = {
          current: Math.max(prev[key].current + 1, 0),
          initial: badgeCount,
        };
      } else {
        // preserve existing badge count if it exists, start at zero (no badge) on first load
        prev[key] = {
          current: prev[key]?.current || 0,
          initial: badgeCount,
        };
      }
    }
    return prev;
  }, existingCounts);

// reset to first tab if the desired tab is unavailable
const selectAvailableTab = (
  tab: SideBarTabs,
  tabs: SidebarTabsItem[],
): SideBarTabs =>
  tabs.map(({ key }) => key).includes(tab) ? tab : tabs[0].key;

const SideBarTabsContext = createContext<SideBarTabsContextValue | null>(null);

export const SideBarTabsProvider: React.FC<{
  tabs: Tab[];
}> = memo(({ children, tabs }) => {
  const user = useUser();
  const { breakpoints } = useTheme();
  const isLargeScreen = useMediaQuery(breakpoints.up("md"), { noSsr: true }); // Need noSsr to be the correct value on first render
  const config = useConfigValue();
  const defaultTab = isLargeScreen || config.hybridMode ? tabs[0].key : null;

  const [currentTab, setCurrentTab] = useState(defaultTab);
  const [tabBadgeCounts, setTabBadgeCounts] = useState(
    getBadgeCountDictionary(tabs),
  );

  /**
   * some tabs like chat are expecting user to be defined when rendering.
   * setting the current tab to this tab on mount will cause
   * it to be mounted before the user is defined and throw an error
   */
  useMountEffect(() => {
    if (user.email) {
      setCurrentTab(defaultTab);
    }
  });

  const lastTab = usePreviousDistinct(currentTab);

  const { activeChat, setActiveChat } = useEventChatContext();

  const prevChatRef = useRef(activeChat || "event");
  prevChatRef.current = activeChat || prevChatRef.current;

  useEffect(() => {
    // if tabs change, then make sure our selected tab is visible, otherwise fallback to last selected tab (or first tab)
    if (currentTab && !tabs.some(({ key }) => key === currentTab)) {
      setCurrentTab(selectAvailableTab(lastTab || tabs[0].key, tabs));
    }
  }, [tabs]); // eslint-disable-line react-hooks/exhaustive-deps

  // when the current tab changes, clear its badge count
  useEffect(
    () => {
      const baseBadgeCounts = getBadgeCountDictionary(tabs, tabBadgeCounts);
      const newCounts: TabBadgeDictionary = !currentTab
        ? { ...baseBadgeCounts } // NOTE: Needs to be a new object to trigger a rerender
        : {
            ...baseBadgeCounts,
            [currentTab]: {
              ...baseBadgeCounts[currentTab],
              // don't reset for chat
              current:
                currentTab !== SideBarTabs.CHAT
                  ? 0
                  : baseBadgeCounts[currentTab]?.initial,
            },
          };

      setTabBadgeCounts(newCounts);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTab, tabs],
  );

  useEffect(
    () => {
      // Ensure we are properly handling chat counts when tabs change
      if (currentTab !== SideBarTabs.CHAT) {
        // if current tab is not chat tab => clear active chat channel
        setActiveChat(null);
      } else if (activeChat === null) {
        // When switching back to chat tab => reinstate previously active chat channel
        setActiveChat(prevChatRef.current);
      }
    },
    // do not uncomment this as it breaks private chat 💩
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTab],
  );

  const contextValue: SideBarTabsContextValue = useMemo(() => {
    const tabsWithBadges = tabs.map((tab) => {
      const badgeCount =
        "persistentBadgeCount" in tab && tab.persistentBadgeCount
          ? tab.badgeCount
          : tabBadgeCounts[tab.key]?.initial || 0;
      return {
        ...tab,
        badgeCountCompact: formatBadgeCount(badgeCount),
        badgeCount,
      };
    });

    return {
      currentTab,
      setCurrentTab,
      tabs: tabsWithBadges,
    };
  }, [currentTab, setCurrentTab, tabBadgeCounts, tabs]);

  return (
    <SideBarTabsContext.Provider value={contextValue}>
      {children}
    </SideBarTabsContext.Provider>
  );
});

export const EventSideBarTabsProvider = memo(
  ({ children }: React.PropsWithChildren<unknown>) => {
    const tabs = useEventSideBarTabs();
    return tabs ? (
      <SideBarTabsProvider tabs={tabs}>{children}</SideBarTabsProvider>
    ) : null;
  },
);

export const NetworkingHubSideBarTabsProvider = memo(
  ({ children }: React.PropsWithChildren<unknown>) => {
    const tabs = useNetworkingHubSideBarTabs();
    return tabs ? (
      <SideBarTabsProvider tabs={tabs}>{children}</SideBarTabsProvider>
    ) : null;
  },
);

const useSideBarTabsContext = () => {
  const ctx = useContext(SideBarTabsContext);

  invariant(ctx, "useSideBarTabsContext called outside of SideBarTabsProvider");

  return ctx;
};

export const useSideBarTabs = () => {
  const { currentTab, setCurrentTab, tabs } = useSideBarTabsContext();

  return useMemo(() => {
    return { currentTab, setCurrentTab, tabs };
  }, [currentTab, setCurrentTab, tabs]);
};
