import { useNetworkingHub } from "@src/providers/NetworkingHubProvider";
import useCommonController from "../useCommonController";
import { ParticipantNameOption } from "@src/contracts/enums/participant-name-options";
import { UserRole } from "@src/contracts/user/user";

export const useShouldShowNetworkingHubParticipantNames = () => {
  const { user } = useCommonController();
  const { data: networkingHub } = useNetworkingHub();

  // When user is a host, then they get to see all names
  return (
    (networkingHub?.whitelabel?.showParticipantNames ===
      ParticipantNameOption.HOSTS_AND_OWNERS &&
    user.userRole === UserRole.Organizer
      ? ParticipantNameOption.ALL
      : networkingHub?.whitelabel?.showParticipantNames) ||
    // Default to all
    ParticipantNameOption.ALL
  );
};
