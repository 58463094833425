import { UserRole } from "@src/contracts/user/user";
import { v4 as uuidV4 } from "uuid";
import type { UserProps } from "./UserProvider";

export const defaultUser: UserProps = {
  userId: uuidV4(),
  originalId: "",
  userEmail: null,
  userName: "",
  userRole: UserRole.Unregistered,
  userProfile: "",
};
