import React from "react";
import { Event } from "../../contracts/event/event";
import { User } from "../../contracts/user/user";
import GeneralSettings from "./GeneralSettings";
import ChatSettings from "./ChatSettings";

export interface EventSettingsProps {
  event: Event;
  user: User;
}
export const EventSettings: React.FC<EventSettingsProps> = React.memo(
  ({ user }) => {
    return (
      <>
        <GeneralSettings user={user} />
        <ChatSettings user={user} />
      </>
    );
  },
);
