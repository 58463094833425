import { styled, Box } from "@mui/material";
import { MOVE_ANIMATION_START_X } from "./common";

/**
 * Generates CSS keyframes for move animations, creating the following effects:
 * - Slight fade-in to fade-out
 * - Moves upward at a constant rate
 * - Moves side-to-side at a constant rate
 * - Scales down at a constant rate
 * @param start A number which is used to determine the starting X location of the transform where 0 results in `translateX(0%)`
 * @returns The CSS keyframes as an object
 */
const generateKeyframeTransforms = (start: number) => ({
  "0%": {
    opacity: 0.4,
    transform: `translate(${start * 100}%, 0%) scale(1)`,
  },
  "25%": {
    opacity: 0.8,
    transform: `translate(${start * 100 * 0.4}%, -200%) scale(0.8)`,
  },
  "50%": {
    opacity: 1,
    transform: `translate(${start * 100}%, -400%) scale(0.6)`,
  },
  "75%": {
    opacity: 0.6,
    transform: `translate(${start * 100 * 0.4}%, -600%) scale(0.4)`,
  },
  "100%": {
    opacity: 0,
    transform: `translate(${start * 100}%, -800%) scale(0.2)`,
  },
});

const generateKeyframeAnimations = () =>
  Array(MOVE_ANIMATION_START_X.length)
    .fill(0)
    .reduce(
      (acc, _, idx) => ({
        ...acc,
        [`@keyframes sequel-live-reaction--move-${idx + 1}-anim`]: {
          ...generateKeyframeTransforms(MOVE_ANIMATION_START_X[idx]),
        },
      }),
      {},
    );

export const Container = styled(Box)(({ theme }) => ({
  position: "absolute",
  display: "flex",
  alignItems: "flex-end",
  zIndex: 0,
  justifyContent: "center",
  overflow: "hidden",
  width: "100%",
  height: "100%",
  pointerEvents: "none",
  ...generateKeyframeAnimations(),

  "> .sequel-live-reaction": {
    overflow: "hidden",
    backgroundRepeat: "no-repeat",
    backgroundColor: "transparent",
    backgroundSize: "contain",
    animationDuration: "3s",
    animationTimingFunction: "linear",
    transformOrigin: "center",
    width: 48,
    height: 48,
    position: "absolute",
    bottom: 0,
    right: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      width: 42,
      height: 42,
    },
    [theme.breakpoints.down("sm")]: {
      width: 36,
      height: 36,
      right: theme.spacing(4),
    },
    // Takes the current set of live reactions and generates a background
    // image class for each reaction
    ...(theme.customTheme?.liveReactions?.reactions ?? []).reduce(
      (acc, reaction) => {
        return {
          ...acc,
          [`&.sequel-live-reaction--${reaction.reaction}`]: {
            backgroundImage: `url("${reaction.image}")`,
          },
        };
      },
      {},
    ),
  },
}));
