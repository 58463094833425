import { useUnmount } from "react-use";
import { emitAsPromise } from "./emitAsPromise";
import { Actions } from "@src/graphql/schemas/actions";
import { SocketClient } from "@src/graphql/SocketClient/SocketClient";
import useImmediateEffect from "@src/hooks/useImmediateEffect";
import {
  FeatureFlag,
  useFeatureFlag,
} from "@src/providers/FeatureFlagsProvider";
import { useClientSessionId } from "./SocketClientProvider";
import { useMutation, useQueryClient } from "react-query";
import { useSyncedSocketEventId } from "./useSyncSocketEvent";
import { createTemporaryRoomsQueryKey } from "@src/providers/NetworkingHubProvider/common";

type CircleInput = {
  id: string;
  name: string;
} | null;

/**
 * Emits a `set-room` event to the socket server to set the circleId on the user's session.
 * The circleId affects the presence of the user as well as other chat related actions
 * such as creating questions and polls.
 */
export const useSyncSocketRoom = (
  client: SocketClient,
  connected: boolean,
  circle: CircleInput,
  isReady: boolean,
) => {
  const isV2ChatServerEnabled = useFeatureFlag(FeatureFlag.CHAT_SERVER_V2);
  const eventName = isV2ChatServerEnabled ? Actions.SET_CIRCLE : "set room";

  // this is important to retrigger sync if sessionId changes
  const sessionId = useClientSessionId();
  const queryClient = useQueryClient();
  const syncedRoom = useSyncedSocketRoomId();
  const syncedEvent = useSyncedSocketEventId();

  const { status, mutate } = useMutation(
    (circle: CircleInput) => emitAsPromise(client, eventName, circle),
    {
      onMutate: () => {
        // clear locally saved socket session circle
        queryClient.setQueryData(Actions.SET_CIRCLE, null);
      },
      onSuccess: (data, variables) => {
        // save socket session circle locally in cache to be used in other parts of the app
        queryClient.setQueryData(Actions.SET_CIRCLE, variables?.id);
      },
    },
  );

  useImmediateEffect(
    () => {
      if (connected && isReady) {
        mutate(circle);
      }
    },
    [client, connected, isReady, circle?.id, eventName, sessionId, mutate],
    true,
  );

  useUnmount(() => {
    // reset existing socket fetches to avoid leftover data when switching between rooms, hubs and events
    queryClient.resetQueries(
      createTemporaryRoomsQueryKey(syncedEvent as string),
    );

    // only set circle to null if the user was in a circle before unmounting
    if (!syncedRoom) return;
    mutate(null);
  });

  return status;
};

export const useSyncedSocketRoomId = () => {
  const queryClient = useQueryClient();
  return queryClient.getQueryData<string | null>(Actions.SET_CIRCLE);
};
