import "video.js/dist/video-js.min.css";
import "./index.css";

import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { Providers } from "./Providers";
import {
  deleteAllCookies,
  transferAuthCookiesToLocalStorage,
} from "./helpers/cookie";

(window as any).DEBUG = "IntrovokeStage";

transferAuthCookiesToLocalStorage();

/**
 * for the new VS to be able to logout by clearing cookies in embed to stop the redirect from happening.
 * there is no login state in the new app it is all controlled by the auth=KEY param
 */
const url = new URL(window.location.href);
if (url.searchParams.has("logout")) {
  deleteAllCookies();
  window.location.replace(window.location.pathname);
}

ReactDOM.render(
  <Providers>
    <App />
  </Providers>,
  document.getElementById("root"),
);
