/** @jsxImportSource @emotion/react */

import React from "react";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";

import { ClassNames } from "@emotion/react";
import { useTheme } from "@mui/material/styles";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Popover from "@mui/material/Popover";
import FeatureLockedDialog from "../../../dialogs/FeatureLockedDialog/FeatureLockedDialog";
import ContainedButton from "@src/components/buttons/ContainedButton";
import OutlineButton from "@src/components/buttons/OutlineButton";

export const StartEventButton = (props: {
  onConfirm?: () => void;
  goLiveLocked: boolean;
  disabled?: boolean;
  loading?: boolean;
}) => {
  const { onConfirm, goLiveLocked = false, disabled, loading } = props;

  const theme = useTheme();
  const popupState = usePopupState({
    variant: "popover",
    popupId: `mingle-menu`,
  });

  return (
    <ClassNames>
      {({ css }) => (
        <>
          <ContainedButton
            data-testid="go-live-btn"
            disabled={disabled}
            loading={loading}
            {...bindTrigger(popupState)}
            backgroundColor="#20ac3a"
            size="medium"
          >
            Go Live
          </ContainedButton>
          {goLiveLocked ? (
            <FeatureLockedDialog
              {...bindPopover(popupState)}
              title="Unable To Go Live"
            />
          ) : (
            <Popover
              classes={{
                root: css`
                  margin-top: 8px;
                `,
                paper: css`
                  padding: 8px;
                `,
              }}
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <CardContent
                classes={{
                  root: css`
                    font-weight: 700;
                  `,
                }}
              >
                Are you sure you want to go live?
              </CardContent>
              <CardActions
                css={css`
                  display: flex;
                  justify-content: flex-end;
                `}
              >
                <OutlineButton
                  backgroundColor={theme.palette.common.white}
                  textColor={theme.palette.common.black}
                  onClick={popupState.close}
                >
                  Cancel
                </OutlineButton>

                <ContainedButton
                  onClick={() => {
                    popupState.close();
                    onConfirm?.();
                  }}
                  backgroundColor="#20ac3a"
                  size="medium"
                >
                  Yes, Go Live
                </ContainedButton>
              </CardActions>
            </Popover>
          )}
        </>
      )}
    </ClassNames>
  );
};
