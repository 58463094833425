import React, { memo, useState } from "react";

import { User } from "../../../contracts/user/user";
import {
  Poll,
  PollResultsType,
  PollStatus,
} from "../../../providers/polls/PollProvider";
import { CreatePollButton } from "../CreatePollButton";
import { EmptyPollsList } from "../EmptyPollsList/EmptyPollsList";
import PollList from "../PollList/PollList";
import { ParticipantNameOption } from "../../../contracts/enums/participant-name-options";
import { TabContext } from "@mui/lab";
import {
  CreatePollWrapper,
  Wrapper,
  StyledMuiTabList,
  StyledMuiTab,
  StyledMuiTabPanel,
} from "./PollsTabs.styles";
import { useIsSessionActive } from "@src/components/Presence/SocketClientProvider";
import AlertDialogContent from "@src/components/dialogs/content/AlertDialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

const ErrorFallback = () => {
  return (
    <AlertDialogContent
      sx={{ p: 2 }}
      hideAction
      variant="warning"
      iconSize="small"
      title={
        <Typography
          mt={2}
          id="alert-dialog-title"
          variant="h4"
          textAlign="center"
        >
          Oops! Looks Like There's a Problem
        </Typography>
      }
      body={
        <Typography textAlign="center" variant="body1" fontWeight="normal">
          Please make sure that websockets are enabled in order to engage with
          chat, polls and Q&A.
        </Typography>
      }
    />
  );
};

interface PollsTabsProps {
  user: User;
  /**
   * Flag indicating if the person is a host or circle owner for the current circle
   */
  isOrganizer: boolean;
  /**
   * Option for showing participant names
   *
   * @see {@link ParticipantNameOption}
   */
  showParticipantNames: ParticipantNameOption;
  isLoading: boolean;
  isError: boolean;
  pendingPolls: Poll[];
  activePolls: Poll[];
  closedPolls: Poll[];
  archivedPolls: Poll[];
  handleVoteEventPollOption?: (data: { poll: string; option: string }) => void;
  handleCreateEventPoll?: (data: {
    name: string;
    options: { name: string }[];
    status: PollStatus;
    delayResults: boolean;
    resultsType: PollResultsType;
  }) => void;
  handleDeleteEventPoll?: (data: { poll: string }) => void;
  handleUpdatePollStatus: (data: { poll: string; status: PollStatus }) => void;
  handlePublishPollResults: (data: { poll: string; publish?: boolean }) => void;
}

export const PollsTabs = memo((props: PollsTabsProps) => {
  const [activeSection, setActiveSection] = useState<PollStatus>(
    PollStatus.ACTIVE,
  );

  const handleChange = (event: React.SyntheticEvent, newValue: PollStatus) => {
    setActiveSection(newValue);
  };

  const isSessionActive = useIsSessionActive();
  const {
    isLoading,
    isError,
    pendingPolls,
    activePolls,
    closedPolls,
    archivedPolls,
    handleVoteEventPollOption,
    handleCreateEventPoll,
    handleDeleteEventPoll,
    handleUpdatePollStatus,
    handlePublishPollResults,
    user,
    isOrganizer,
    showParticipantNames,
  } = props;
  const hasPolls = isOrganizer
    ? !!(
        activePolls.length ||
        closedPolls.length ||
        pendingPolls.length ||
        archivedPolls.length
      )
    : !!(activePolls.length || closedPolls.length);

  const activePollsCount =
    (activePolls.length ?? 0) + (closedPolls.length ?? 0);

  if (!isSessionActive || isError) {
    return (
      <Wrapper>
        <ErrorFallback />
      </Wrapper>
    );
  }

  if (isLoading) {
    return (
      <Wrapper>
        <CircularProgress sx={{ alignSelf: "center", mt: 8 }} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {!hasPolls ? (
        <EmptyPollsList>
          {isOrganizer && (
            <CreatePollButton
              onSubmit={handleCreateEventPoll}
              navigateToTab={setActiveSection}
            />
          )}
        </EmptyPollsList>
      ) : (
        <TabContext value={activeSection}>
          {isOrganizer && (
            <StyledMuiTabList onChange={handleChange}>
              <StyledMuiTab
                label={`Active (${activePollsCount})`}
                value={PollStatus.ACTIVE}
                data-testid={`poll-tab-${PollStatus.ACTIVE}`}
              />
              <StyledMuiTab
                label={`Saved ${
                  pendingPolls ? `(${pendingPolls.length})` : ""
                }`}
                value={PollStatus.PENDING}
                data-testid={`poll-tab-${PollStatus.PENDING}`}
              />
              <StyledMuiTab
                label={`Archived ${
                  archivedPolls ? `(${archivedPolls.length})` : ""
                }`}
                value={PollStatus.ARCHIVED}
                data-testid={`poll-tab-${PollStatus.ARCHIVED}`}
              />
            </StyledMuiTabList>
          )}

          {isOrganizer && (
            <CreatePollWrapper>
              <CreatePollButton
                onSubmit={handleCreateEventPoll}
                navigateToTab={setActiveSection}
              />
            </CreatePollWrapper>
          )}
          <StyledMuiTabPanel value={PollStatus.ACTIVE}>
            <PollList
              {...{
                polls: [...activePolls, ...closedPolls],
                noPollsText: "No Active Polls",
                navigateToTab: setActiveSection,
                user,
                isOrganizer,
                showParticipantNames,
                handleVoteEventPollOption,
                handleDeleteEventPoll,
                handleUpdatePollStatus,
                handlePublishPollResults,
              }}
            />
          </StyledMuiTabPanel>
          <StyledMuiTabPanel value={PollStatus.PENDING}>
            <PollList
              {...{
                header: "Queued Polls",
                noPollsText: "No Saved Polls",
                navigateToTab: setActiveSection,
                polls: pendingPolls,
                user,
                isOrganizer,
                showParticipantNames,
                handleVoteEventPollOption,
                handleDeleteEventPoll,
                handleUpdatePollStatus,
                handlePublishPollResults,
              }}
            />
          </StyledMuiTabPanel>
          <StyledMuiTabPanel value={PollStatus.ARCHIVED}>
            <PollList
              {...{
                header: "Archived Polls",
                noPollsText: "No Archived Polls",
                navigateToTab: setActiveSection,
                polls: archivedPolls,

                user,
                isOrganizer,
                showParticipantNames,
                handleVoteEventPollOption,
                handleDeleteEventPoll,
                handleUpdatePollStatus,
                handlePublishPollResults,
              }}
            />
          </StyledMuiTabPanel>
        </TabContext>
      )}
    </Wrapper>
  );
});
