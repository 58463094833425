import React from "react";
import { User, UserRole } from "../../contracts/user/user";
import { EventStatus } from "../../contracts/event/event";
import { InputLabel, Switch } from "../inputs";
import {
  FeatureFlag,
  useFeatureFlag,
} from "../../providers/FeatureFlagsProvider";

interface QandATabSettingsProps {
  user: User;
  isAnonymousQnaEnabled: boolean;
  isApproveQnaEnabled: boolean;
  enableAnonymousQna: (e: any) => Promise<EventStatus>;
  enableApproveQna: (e: any) => Promise<EventStatus>;
}
export const QandATabSettings: React.FC<QandATabSettingsProps> = ({
  user,
  isAnonymousQnaEnabled,
  isApproveQnaEnabled,
  enableAnonymousQna,
  enableApproveQna,
}) => {
  const isApproveQnaFlagEnabled = useFeatureFlag(FeatureFlag.APPROVE_QNA);
  const isOrganizer = user.userRole === UserRole.Organizer;

  return isOrganizer ? (
    <>
      <InputLabel
        label="Anonymous Q&A"
        tooltip="Hides the user's profile details such as name and avatar for all attendees, including hosts."
        direction="horizontal"
        fullWidth
        description="Allows users to submit questions anonymously"
      >
        <Switch
          name="isAnonymousQnaOn"
          checked={isAnonymousQnaEnabled}
          onChange={enableAnonymousQna}
        />
      </InputLabel>
      {isApproveQnaFlagEnabled && (
        <InputLabel
          label="Approve Q&A"
          tooltip="Forces all questions to be approved by a host before they are visible to attendees. Question moderation tools will not be visible until the question is approved."
          direction="horizontal"
          fullWidth
          description="Forces all questions to be approved before being visible to attendees"
        >
          <Switch
            name="isApproveQnaOn"
            checked={isApproveQnaEnabled}
            onChange={enableApproveQna}
          />
        </InputLabel>
      )}
    </>
  ) : null;
};
