import isEqual from "lodash/isEqual";
import type { AnnouncementMessage } from "./ChatProvider";

/** default time since last announcement in seconds */
export const TIME_SINCE_LAST_ANNOUNCEMENT = 60;
/**
 * @param announcement The announcement message received from PubNub
 * @param announcements The user's current list of locally stashed reveived announcements
 * @returns The time in seconds since the last announcement with the same signature if found in the user's list
 */
export const timeSinceLastAnnouncement = (
  announcement: AnnouncementMessage,
  announcements: AnnouncementMessage[],
) => {
  // find previous announcement with the same signature
  const lastAnnouncement = announcements
    .reverse()
    .find(
      (a) =>
        a.type === announcement.type &&
        a.message.message.name === announcement.message.message.name &&
        a.message.message.comment === announcement.message.message.comment &&
        isEqual(a.to, announcement.to),
    );

  if (lastAnnouncement?.timestamp) {
    // if the same announcement was found => return the time difference
    const timeNow = new Date(new Date().toISOString()).valueOf();
    const timeLast = new Date(lastAnnouncement.timestamp).valueOf();
    return (timeNow - timeLast) / 1000;
  }

  // if no similar announcement was found => return default time difference
  return TIME_SINCE_LAST_ANNOUNCEMENT;
};
