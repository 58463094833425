import React, { memo } from "react";
import { Typography, styled } from "@mui/material";
import PollsIcon from "@mui/icons-material/BarChart";

import { Poll } from "../../providers/polls/PollProvider";
import { useSideBarTabs } from "../../providers/SideBarTabsProvider";
import Button from "@src/components/buttons/Button";
import { SideBarTabs } from "../SideBar/types";

const PollBoxContainer = styled("div")(({ theme }) => ({
  width: "100%",
  alignItems: "center",
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: theme.spacing(1.5),
  borderTopLeftRadius: 0,
  display: "flex",
  padding: theme.spacing(1),
  overflowWrap: "break-word",
  wordBreak: "break-word",
}));

const PollTitle = styled(Typography)(({ theme }) => ({
  flex: 1,
  fontWeight: 600,
  overflow: "hidden",
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(1),
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
}));

interface PollPreviewBoxProps extends Poll {}

export const PollPreviewBox = memo(
  ({ hidden, name, options }: PollPreviewBoxProps) => {
    const { setCurrentTab } = useSideBarTabs();

    const hasVoted = React.useMemo(
      () => options.some(({ selected }) => selected),
      [options],
    );

    return hidden ? null : (
      <PollBoxContainer>
        <PollsIcon
          sx={{
            transform: "rotate(90deg)", // orient poll icon lines horizontally to match designs
            color: "primary.main",
            fontSize: "2rem",
          }}
        />
        <PollTitle>{name}</PollTitle>

        <Button
          color="primary"
          onClick={() => setCurrentTab(SideBarTabs.POLLS)}
          size="small"
          variant="contained"
        >
          {hasVoted ? "See Results" : "Vote Now"}
        </Button>
      </PollBoxContainer>
    );
  },
);
