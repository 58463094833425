import React, { MouseEvent, useCallback, useMemo, useState } from "react";
import { Collapse, IconButton, Typography, styled, Box } from "@mui/material";

import { PinnedMessage } from "../../providers/chat/ChatProvider";
import { MessageBox } from "./MessageBox";
import { User, UserRole } from "../../contracts/user/user";
import { ParticipantNameOption } from "../../contracts/enums/participant-name-options";
import OpenIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/KeyboardArrowUp";
import PinIcon from "@mui/icons-material/PushPin";

import { ChatItemMenu } from "./ChatItemMenu";
import { MappedMessage } from "./ChatComments";

interface Props {
  pinnedMessages: PinnedMessage[];
  user: User;
  showTimestamp?: boolean;
  ownerEmails?: string[];
  showParticipantNames?: ParticipantNameOption;
  onUnpin: (pinnedMessage: PinnedMessage) => void;
}

const PinnedMessageContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.base[100],
  paddingTop: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  overflow: "auto",
  maxHeight: "100%",
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

const PinnedMessages = (props: Props) => {
  const {
    user,
    pinnedMessages,
    showTimestamp,
    ownerEmails,
    showParticipantNames,
    onUnpin,
  } = props;
  const [isPinnedMessageOpen, setIsPinnedMessageOpen] = useState(true);
  const isHost = user.userRole === UserRole.Organizer;
  const isCircleOwner = useMemo(
    () => ownerEmails?.includes(user.email),
    [user.email, ownerEmails],
  );

  const onTogglePreview = useCallback(() => {
    setIsPinnedMessageOpen((s) => !s);
  }, [setIsPinnedMessageOpen]);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenMenu = useCallback(
    (
      event: MouseEvent<HTMLButtonElement>,
      message: MappedMessage | undefined,
    ) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const showMenu = isHost || isCircleOwner;

  if (!pinnedMessages.length) {
    return null;
  }

  return (
    <PinnedMessageContainer data-testid="pinned-messages-container">
      <Box
        sx={{
          backgroundColor: "base.100",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          aria-label="close preview"
          onClick={onTogglePreview}
          sx={{
            color: "base.400",
            padding: 1,
          }}
          size="large"
        >
          {isPinnedMessageOpen ? <CloseIcon /> : <OpenIcon />}
        </IconButton>
        <Typography variant="h4">
          <PinIcon color="primary" fontSize="inherit" />
          Pinned
        </Typography>
      </Box>
      <Collapse in={isPinnedMessageOpen}>
        {pinnedMessages.map((pinnedMessage) => {
          const { timetoken, message, user: messageUser } = pinnedMessage;
          const isMessageAnonymous =
            showParticipantNames === ParticipantNameOption.NONE ||
            (showParticipantNames === ParticipantNameOption.HOSTS_AND_OWNERS &&
              pinnedMessage.message.user.role !== UserRole.Organizer &&
              !ownerEmails?.includes(messageUser.email));

          return (
            <React.Fragment key={timetoken}>
              <Box pt={0.5} data-testid={`pinned-message-${timetoken}`}>
                <MessageBox
                  senderId={message.user.uid}
                  userId={user.uid}
                  avatar={message.user.profilePicture}
                  isOwn={false}
                  isPinned
                  message={message.message.comment}
                  showTimestamp={showTimestamp}
                  timestamp={message.message.timestamp}
                  username={message.user.name}
                  userRole={message.user.role}
                  isAnonymous={isMessageAnonymous}
                  showParticipantNames={showParticipantNames}
                  onMenuClick={showMenu ? handleOpenMenu : undefined}
                />
              </Box>
              <ChatItemMenu
                open={!!anchorEl}
                onClose={handleClose}
                anchorEl={anchorEl}
                items={
                  props.user.userRole !== UserRole.Organizer &&
                  !ownerEmails?.includes(props.user.email)
                    ? []
                    : [
                        {
                          label: "Unpin",
                          onClick: () => onUnpin(pinnedMessage),
                        },
                      ]
                }
              />
            </React.Fragment>
          );
        })}
      </Collapse>
    </PinnedMessageContainer>
  );
};

export default React.memo(PinnedMessages);
