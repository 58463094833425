export enum EventType {
  pitch,
  panel,
  conference,
}

export enum VideoType {
  OnDemand,
  Live,
  Upcoming,
}
