import noop from "lodash/noop";
import React, {
  createContext,
  DetailedHTMLProps,
  HTMLAttributes,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { useFullscreen as useReactFullscreen } from "../hooks";
import invariant from "tiny-invariant";

interface Fullscreen {
  isFullscreen: boolean;
  toggleFullscreen: () => void;
}

const FullscreenContext = createContext<Fullscreen>({
  isFullscreen: false,
  toggleFullscreen: noop,
});

interface Props
  extends Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    "ref"
  > {}

/**
 * Provider which allows for toggling the VirtualStage component to/from
 * fullscreen view.
 *
 * Use the `useFullscreen` hook to get the current fullscreen state and toggle.
 */
export function FullscreenProvider(props: Props) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [toggled, setToggled] = useState(false);
  const isFullscreen = useReactFullscreen(wrapperRef, toggled, {
    onClose: () => setToggled(false),
  });

  const toggleFullscreen = useCallback(() => {
    setToggled((s) => !s);
  }, []);

  return (
    <FullscreenContext.Provider value={{ isFullscreen, toggleFullscreen }}>
      {/* wrapper element to apply the fullscreen to */}
      <div {...props} ref={wrapperRef} />
    </FullscreenContext.Provider>
  );
}

/**
 * Returns the current state for the FullscreenProvider context
 */
export function useFullscreen() {
  const ctx = useContext(FullscreenContext);
  invariant(
    ctx,
    "`useFullscreen` must be used within the `FullscreenProvider`",
  );
  return ctx;
}

export default FullscreenProvider;
