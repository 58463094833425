import React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button, { Props as ButtonProps } from "@src/components/buttons/Button";

export const StyledButton = styled(
  ({ hasReacted, ...props }: ButtonProps & { hasReacted: boolean }) => (
    <Button {...props} />
  ),
)<{ hasReacted: boolean }>(({ theme, hasReacted }) => ({
  paddingTop: theme.spacing(0.4),
  paddingBottom: theme.spacing(0.4),
  paddingLeft: theme.spacing(0.75),
  paddingRight: theme.spacing(1),
  minWidth: theme.spacing(6),
  backgroundColor: hasReacted
    ? alpha(theme.palette.primary.light, 0.25)
    : theme.palette.common.white,
  borderRadius: 50,
  borderColor: hasReacted
    ? alpha(theme.palette.primary.light, 0.25)
    : undefined,
  "& .MuiButton-startIcon": {
    marginLeft: theme.spacing(0.25),
    marginRight: theme.spacing(0.75),
  },
  fontSize: "0.875rem",
  fontWeight: theme.typography.caption.fontWeight as any,
  lineHeight: theme.typography.caption.lineHeight as any,
  "&:hover": {
    backgroundColor: hasReacted
      ? theme.palette.common.white
      : alpha(theme.palette.primary.light, 0.25),
  },
  // animation
  "@keyframes count-item-animation": {
    "0%": {
      transform: "translateY(0px)",
    },
    "50%": {
      transform: "translateY(-3px)",
    },
    "100%": {
      transform: "translateY(0px)",
    },
  },
  "& > span.count-item-animate": {
    animation:
      "count-item-animation 200ms cubic-bezier(0.68, -0.55, 0.265, 1.55)",
  },
}));

export const StyledReactionCountsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(0.5),
  marginTop: theme.spacing(0.75),
}));
