import { logWarn } from "../../helpers/logging";
import type { PubNubContextValues, PubnubState } from "./PubNubProvider";

interface FetchHereNowOptions
  extends Pick<PubNubContextValues, "pubnub" | "presenceChannelId"> {
  fallbackData?: PubnubState[];
}
export const fetchHereNow = async ({
  pubnub,
  presenceChannelId,
  fallbackData = [],
}: FetchHereNowOptions): Promise<PubnubState[]> => {
  if (!presenceChannelId?.length) {
    return [];
  }

  try {
    const response = await pubnub.hereNow({
      channels: [presenceChannelId],
      includeState: true,
    });

    const participants =
      response?.channels?.[presenceChannelId]?.occupants?.map(
        ({ uuid, state }): PubnubState => ({
          userId: uuid ?? "",
          username: state?.username ?? "",
          userRole: state?.userRole ?? undefined,
          avatar: state?.avatar ?? "",
          present: true,
        }),
      ) || [];

    return participants;
  } catch (status) {
    logWarn(`Error fetching PubNub presence: ${status}`);
    return fallbackData;
  }
};
