/** @jsxImportSource @emotion/react */

import React from "react";

import { css } from "@emotion/react";
import { Stack } from "@mui/material";

export const HeaderLayout = (props: {
  badge: React.ReactNode;
  name: string;
  actions: React.ReactNode;
  clock: React.ReactNode;
  sticky?: boolean;
}) => {
  return (
    <div
      css={(theme) => [
        css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px 16px;
          font-weight: 600;
          flex-wrap: wrap;
        `,
        props.sticky &&
          css`
            position: sticky;
          `,
      ]}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          flex: 1;
          flex-shrink: 0;
          flex-wrap: wrap;
          min-width: 100px;
          margin-right: 10px;
        `}
      >
        {props.badge}

        <span
          css={css`
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: 10px;
            min-width: 50px;
            flex: 1;
          `}
          title={props.name}
        >
          {props.name}
        </span>
        <span
          css={css`
            color: #66627d;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          `}
        >
          {props.clock}
        </span>
      </div>

      <Stack
        direction="row"
        flexWrap="wrap"
        sx={{
          marginTop: 1,
          "> button": { marginRight: 1, marginBottom: 1 },
          "> button:last-child": { marginRight: 0 },
        }}
      >
        {props.actions}
      </Stack>
    </div>
  );
};
