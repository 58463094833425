import { useCallback } from "react";
import {
  atom,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { useDeepCompareEffect } from "react-use";
import { CircleSortOrder, Config, DEFAULT_CONFIG } from "./common";
import useDeepMemo from "@src/hooks/useDeepMemo";
import merge from "lodash/merge";
import isString from "lodash/isString";
import { useConfigParams } from "../QueryParamsProvider";

/**
 * Atom that keeps track of the app's behavioral configuration values
 */
export const configAtom = atom<Config>({
  key: "appConfig",
  default: DEFAULT_CONFIG,
});

/**
 * Resets the app's behavioral configuration back to its original state
 * @see DEFAULT_CONFIG
 */
export const useResetConfig = () => useResetRecoilState(configAtom);

/**
 * Returns a setter for partially updating the app's behavioral configuration.
 * Doesn't subscribe the component to changes.
 */
export const useSetConfig = () => {
  const setter = useSetRecoilState(configAtom);

  return useCallback(
    (updates: Partial<Config>) =>
      setter((prev) => ({
        ...prev,
        ...updates,
        fixedRoomsFilter: updates.fixedRoomsFilter
          ? updates.fixedRoomsFilter
          : prev.fixedRoomsFilter || [],
        sortCirclesBy: updates.sortCirclesBy
          ? updates.sortCirclesBy
          : prev.sortCirclesBy ?? CircleSortOrder.Default,
      })),
    [setter],
  );
};

/**
 * Returns the app's behavioral configuration state and subscribes the component to changes
 */
export const useConfigValue = () => useRecoilValue(configAtom);

/**
 * Returns the app's behavioral configuration state and the setter to update the values and
 * subscribes the component to changes
 */
export const useConfig = (): [Config, ReturnType<typeof useSetConfig>] => {
  const value = useConfigValue();
  const setter = useSetConfig();

  return [value, setter];
};

/**
 * Returns config query parameter strings into expected config
 */
export const useConfigFromQuery = (): Config => {
  const params = useConfigParams();
  return useDeepMemo<Config>(
    () => ({
      test: !!params.test,
      cleanUI: !!params.cleanUI,
      hybridMode: !!params.hybridMode,
      nameOnlyEntry: !!params.nameOnlyEntry,
      fixedRoomsFilter: isString(params.filter) ? params.filter.split(",") : [],
      sortCirclesBy:
        isString(params.sort) && params.sort === "mostactive"
          ? CircleSortOrder.Active
          : CircleSortOrder.Default,
      meeting: !!params.meeting,
      mode: params.mode,
    }),
    [params],
  );
};

/**
 * Hook that should be called only once at the top of `App.tsx` to
 * initialize the app's behavioral configuration state
 *
 * It initializes the config values from all necessary sources such as query params
 * and optionally takes in props for overrides.
 */
export const useConfigProvider = (configProps: Partial<Config> = {}) => {
  const configQuery = useConfigFromQuery();
  const setConfig = useSetConfig();

  const config = useDeepMemo<Config>(
    () => merge(configQuery, configProps),
    [configQuery, configProps],
  );

  useDeepCompareEffect(() => {
    setConfig(config);
  }, [config]);
};
