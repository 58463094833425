import { useCallback, useEffect, useMemo, useState } from "react";
import escapeRegExp from "lodash/escapeRegExp";
import isEqual from "fast-deep-equal";

import { PresentUser, PubnubState, usePubNubContext } from "./PubNubProvider";
import { User as MessageUser } from "../chat/ChatProvider";
import { UserRole } from "../../contracts/user/user";

const VIEW_LIST_LENGTH = 30;
const REFRESH_INTERVAL_MS = 2000;

interface PresenceListArgs {
  /**
   * A search string to find present users
   */
  search?: string;
  /**
   * The maximum length of the returned list
   */
  maxLength?: number;
  /**
   * The list refresh interval
   */
  refreshInterval?: number;
  /**
   * Flag to indicate that we only need search and don't need a list of users otherwise
   */
  searchOnly?: boolean;
}

export const usePresenceList = (args?: PresenceListArgs) => {
  const {
    search,
    maxLength = VIEW_LIST_LENGTH,
    refreshInterval = REFRESH_INTERVAL_MS,
    searchOnly = false,
  } = args || {};
  const {
    presentUserIdsRef,
    userInfoRef: allPubNubUsers,
    updateUserInfo,
  } = usePubNubContext();
  const [usersToDisplay, setUsersToDisplay] = useState<PubnubState[]>([]);

  useEffect(() => {
    if (search) {
      // We have a search string, so see if we can find a matches
      const searchRegExp = new RegExp(escapeRegExp(search), "i");
      const matchingUsers = [] as PubnubState[];
      for (let userId of presentUserIdsRef.current) {
        const user = allPubNubUsers.current[userId];
        if (!user?.username?.match(searchRegExp)) {
          continue;
        }

        // Found match, so add it to the list
        matchingUsers.push(user as PubnubState);
        if (matchingUsers.length >= maxLength) {
          break;
        }
      }
      setUsersToDisplay(matchingUsers);
      return;
    } else if (searchOnly) {
      setUsersToDisplay([]);
      return;
    }

    const updateUsers = () => {
      // Find the first present users
      const presentUsers = [] as PubnubState[];
      for (let userId of presentUserIdsRef.current) {
        const user = allPubNubUsers.current[userId];
        if (!user.username) {
          // Fetch missing user state from PubNub asynchronously
          updateUserInfo(userId);
          // Don't display user they don't have a name
          continue;
        }

        // Have valid user, so add them to the new list
        presentUsers.push(user as PubnubState);
        if (presentUsers.length >= maxLength) {
          break;
        }
      }

      // TODO: validate if we need this to update if ordering changes
      // as deep equals does not take array ordering into account
      // This will always show when new users join/leave so we should have
      // no issues, keeping a todo here to help us debug later if needed
      setUsersToDisplay((prev) =>
        isEqual(presentUsers, prev) ? prev : presentUsers,
      );
    };

    // Update users right now and on an interval
    updateUsers();
    const interval = setInterval(updateUsers, refreshInterval);

    return () => clearInterval(interval);
  }, [
    allPubNubUsers,
    search,
    searchOnly,
    maxLength,
    refreshInterval,
    presentUserIdsRef,
    updateUserInfo,
  ]);

  return useMemo(() => ({ list: usersToDisplay }), [usersToDisplay]);
};

const mapPubNubToMessageUser = (
  user: PubnubState | PresentUser,
): MessageUser => ({
  uid: user.userId,
  profilePicture: user.avatar || "",
  name: user.username || "",
  role: user.userRole ?? UserRole.Unregistered,
  email: user.userId.split("|||")[1],
});

export const useGetPresentUser = () => {
  const { userInfoRef, presentUserIdsRef } = usePubNubContext();

  const getPresentUser = useCallback(
    (userId: string) => {
      if (!presentUserIdsRef.current.contains(userId)) {
        return null;
      }

      return userInfoRef.current[userId]
        ? mapPubNubToMessageUser(userInfoRef.current[userId])
        : null;
    },
    [userInfoRef, presentUserIdsRef],
  );

  return getPresentUser;
};
