import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { useEventManagement } from "../EventProvider";
import { EventStatus } from "../../contracts/event/event";
import { QueryKeys } from "../../api/QueryKeys";
import { useCallback } from "react";
import { Api } from "../../api/api";

interface UseEnableApproveQnaOptions
  extends UseMutationOptions<
    EventStatus,
    unknown,
    boolean,
    {
      previousEventStatus?: EventStatus;
    }
  > {}
export interface UseEnableApproveQnaValue {
  isApproveQnaEnabled: boolean;
  handleEnableApproveQna: (e: any) => Promise<EventStatus>;
}
export const useEnableApproveQna = (
  eventId: string,
  options: UseEnableApproveQnaOptions,
): UseEnableApproveQnaValue => {
  const { status } = useEventManagement();

  const isApproveQnaEnabled = !!status?.isApproveQnaOn;

  const { onSuccess, onError, ...rest } = options;
  const queryClient = useQueryClient();

  const mutation = useMutation<
    EventStatus,
    unknown,
    boolean,
    { previousEventStatus?: EventStatus }
  >(
    async (enabled: boolean) => Api.EventApi.EnableApproveQna(eventId, enabled),
    {
      onMutate: (enabled: boolean) => {
        const eventStatusQueryKey = QueryKeys.eventStatus(eventId);
        queryClient.cancelQueries(eventStatusQueryKey);

        const previousEventStatus =
          queryClient.getQueryData<EventStatus>(eventStatusQueryKey);

        const updatedEventStatus = {
          ...previousEventStatus,
          isApproveQnaOn: enabled,
        } as EventStatus;

        // optimistically update local data
        queryClient.setQueryData(eventStatusQueryKey, updatedEventStatus);

        return { previousEventStatus };
      },
      onSuccess: (data, variables, context) => {
        // invalidate the event status query
        queryClient.invalidateQueries(QueryKeys.eventStatus(eventId));
        onSuccess?.(data, variables, context);
      },
      onError: (err, variables, context) => {
        // reset optimistic update
        queryClient.setQueryData(
          QueryKeys.eventStatus(eventId),
          context?.previousEventStatus,
        );
        onError?.(err, variables, context);
      },
      ...rest,
    },
  );

  const handleEnableApproveQna = useCallback(
    (e) => mutation.mutateAsync(e.target.checked),
    [mutation],
  );

  return {
    isApproveQnaEnabled,
    handleEnableApproveQna,
  };
};
