import React, { memo } from "react";

import { styled, Typography } from "@mui/material";

export type TabHeaderIconProps = {
  badge?: boolean;
  count?: number;
  testId?: string;
} & ({ icon: React.ReactNode } | { text: string });

const Container = styled("div")(() => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
}));

const Text = styled("span")(() => ({
  color: "white",
  fontWeight: "bold",
  paddingRight: "10px",
}));

const Badge = styled("div")(({ theme: { customTheme, palette } }) => ({
  alignItems: "center",
  justifyContent: "center",
  backgroundColor:
    customTheme?.chat?.badge?.backgroundColor || palette.primary.main,
  borderRadius: 10,
  color: customTheme?.chat?.badge?.textColor || palette.common.white,
  display: "inline-flex",
  fontSize: ".9rem",
  height: 20,
  lineHeight: "1em",
  marginLeft: "0.5em",
  minWidth: 20,
  paddingLeft: "0.5em",
  paddingRight: "0.5em",
}));

export const TabHeader = memo(
  ({ badge = false, count, testId, ...props }: TabHeaderIconProps) => {
    const countTestId = testId ? `${testId}-count` : undefined;
    const dot =
      typeof count === "number" &&
      count > 0 &&
      (badge ? (
        <Badge data-testid={countTestId}>{count}</Badge>
      ) : (
        <Text data-testid={countTestId}>{count}</Text>
      ));

    return "icon" in props ? (
      <>
        {dot}
        {props.icon}
      </>
    ) : "text" in props ? (
      <Container>
        <Typography sx={{ fontWeight: 400 }}>{props.text}</Typography>
        {dot}
      </Container>
    ) : null;
  },
);
