import { ApiConfig } from "@src/api/api-config";
import { getToken } from "@src/graphql";
import { Actions, RUN_ACTION_QUERY } from "@src/graphql/schemas/actions";
import type { GraphQlClient } from "@src/graphql/SocketClient/GraphQlClient";
import { useEffect } from "react";
import { ClientEffectArgs } from "../common";

interface UseClientDisconnectEffectArgs extends ClientEffectArgs {
  onDisconnect: () => void;
}

/**
 * This hook should only be called once in the `SocketClientProvider`.
 *
 * It attaches listeners to run when the client is disconnected. It has special
 * handling for both v1 and v2 socket clients.
 */
const createClientDisconnectEffect = (
  args: UseClientDisconnectEffectArgs,
): [React.EffectCallback, React.DependencyList] => {
  return [
    () => {
      const { client, isV2ChatServerEnabled, onDisconnect } = args;

      const handleDisconnect = (reason: string) => {
        console.warn(
          `Disconnected from SocketClient (Chat v${
            isV2ChatServerEnabled ? "2" : "1"
          })`,
          reason,
        );
        onDisconnect();
        if (reason === "io server disconnect") {
          console.log("Attempting to reconnect");
          client.connect();
        }
      };
      client.on(Actions.DISCONNECT, handleDisconnect);

      const emitDisconnect = async () => {
        if (!isV2ChatServerEnabled) return;

        const gqlConfig = ApiConfig.GetAppSyncConfig();
        const commonOptions: RequestInit = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: getToken() || "",
            session: (client as GraphQlClient).getSessionId() || "",
          },
          keepalive: true,
        };

        // emit unset circle
        fetch(gqlConfig.aws_appsync_graphqlEndpoint, {
          ...commonOptions,
          body: JSON.stringify({
            query: RUN_ACTION_QUERY,
            variables: { name: Actions.SET_CIRCLE, input: "null" },
          }),
        }).catch(() => {
          //ignore
        });
        // emit disconnect to clean up presence
        fetch(gqlConfig.aws_appsync_graphqlEndpoint, {
          ...commonOptions,
          body: JSON.stringify({
            query: RUN_ACTION_QUERY,
            variables: { name: Actions.DISCONNECT, input: "null" },
          }),
        }).catch(() => {
          //ignore
        });
      };

      // Both of these have issues and pagehide/visibilitychange are both better alternatives
      // however at the moment we can't use those since emitting disconnect will destroy the session
      // and we don't have a way of restoring the session
      // TODO: Need to revisit this if we can get rid of disconnect completely
      window.addEventListener("beforeunload", emitDisconnect); // unload does not fire on safari
      window.addEventListener("unload", emitDisconnect); // beforeunload might not fire for firefox

      return () => {
        client.off("disconnect", handleDisconnect);
        client.removeAllListeners();
        client.disconnect();

        window.removeEventListener("beforeunload", emitDisconnect);
        window.removeEventListener("unload", emitDisconnect);
      };
    },
    Object.values(args),
  ];
};

export const useClientDisconnect = (args: UseClientDisconnectEffectArgs) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(...createClientDisconnectEffect(args));
};

export const __testable__ = {
  createClientDisconnectEffect,
};
