import React, { Suspense } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import { UserRole } from "@src/contracts/user/user";
import { useUser } from "@src/providers/UserProvider";
import { useEventState } from "@src/providers/EventStateProvider";
import {
  FeatureFlag,
  useFeatureFlag,
} from "@src/providers/FeatureFlagsProvider";
import lazyLoader from "@src/helpers/lazyLoader";

import { ViewerLayout } from "../ViewerLayout";
import { ViewerLayout as ViewerLayoutLegacy } from "../ViewerLayoutLegacy";

const SpeakerContent = lazyLoader(
  () => import("../blocks/SpeakerContent/SpeakerContent"),
  "SpeakerContent",
);

const isViewerRole = (role: UserRole) =>
  role === UserRole.Viewer || role === UserRole.Unregistered;
const isStageRole = (role: UserRole) =>
  role === UserRole.Organizer || role === UserRole.Presenter;

export const EventStageContent = () => {
  const { userRole } = useUser();
  const { hasEnteredStage, userToken } = useEventState();
  const showCountdownRefactor = useFeatureFlag(FeatureFlag.COUNTDOWN_REFACTOR);

  const showViewer = isViewerRole(userRole);
  const showStage = isStageRole(userRole);
  const shouldShowStage = hasEnteredStage && !!userToken;

  if (showStage && shouldShowStage) {
    return (
      <Suspense
        fallback={
          <Box
            display="flex"
            flex="1"
            alignItems="center"
            justifyContent="center"
            bgcolor="common.white"
          >
            <CircularProgress />
          </Box>
        }
      >
        <SpeakerContent />
      </Suspense>
    );
  } else if (showViewer || (showStage && !shouldShowStage)) {
    return showCountdownRefactor ? <ViewerLayout /> : <ViewerLayoutLegacy />;
  }

  // TODO: I have never seen this throw is this even possible?
  throw new Error("Unknown state");
};
