import { emitAsPromise } from "@src/components/Presence/emitAsPromise";
import { Actions } from "@src/graphql/schemas/actions";
import { useCallback, useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  Question,
  QuestionActionHookOptions,
  QuestionActionRollbackFunction,
  UserQuestion,
} from "../types";
import { createQuestionsQueryKey, sortQuestions } from "../helpers";

/**
 * Callback to update the voted question in the supplied local questions array
 */
const updateQuestionAsVoted =
  (questionId: string) => (prev?: UserQuestion[] | null) => {
    const previousQuestions = Array.isArray(prev) ? prev : [];
    return previousQuestions.map((question) => {
      if (question.id !== questionId) return question;

      const hasVoted = question.voted;
      const votes = hasVoted
        ? Math.max(question.votes - 1, 0) // remove vote if unvoting
        : question.votes + 1; // add vote if voting

      return {
        ...question,
        votes,
        voted: !hasVoted,
        isPending: true,
      };
    });
  };

/**
 * Helper callback that is run in the `question-votes-changed` callback function
 */
const handleQuestionVotesChangedCallback =
  (data: UserQuestion) => (prev?: UserQuestion[] | null) => {
    const previousQuestions = Array.isArray(prev) ? prev : [];
    return sortQuestions(
      previousQuestions.map((question) => {
        if (question.id !== data.id) {
          return question;
        }

        return {
          ...question,
          votes: data.votes,
          isPending: !!data.isPending,
        };
      }),
    );
  };

export interface UseVoteEventQuestionOptions
  extends QuestionActionHookOptions {}

export const useVoteEventQuestion = ({
  client,
  eventId,
  circleId,
}: UseVoteEventQuestionOptions) => {
  const cache = useQueryClient();
  const queryKey = createQuestionsQueryKey(eventId, circleId);

  const mutation = useMutation<
    Question,
    unknown,
    string,
    QuestionActionRollbackFunction
  >(
    (questionId: string) =>
      emitAsPromise(client, Actions.VOTE_EVENT_QUESTION, questionId),
    {
      onMutate: (questionId: string) => {
        const previousQuestions =
          cache.getQueryData<UserQuestion[]>(queryKey) || [];

        cache.setQueryData(queryKey, updateQuestionAsVoted(questionId));

        return () => cache.setQueryData(queryKey, previousQuestions);
      },
      onError: (err, variables, rollback) => {
        // rollback on error (success is handled by the handleQuestionVotesChanged)
        rollback?.();
        toast.error("There was an error upvoting the question");
      },
    },
  );

  /**
   * Callback to run when a `question-votes-changed` event is received from the client server
   */
  const handleQuestionVotesChanged = useCallback(
    (data: UserQuestion) => {
      cache.setQueryData<UserQuestion[]>(
        createQuestionsQueryKey(data.eventId, data.circleId),
        handleQuestionVotesChangedCallback(data),
      );
    },
    [cache],
  );

  return useMemo(
    () => ({
      mutation,
      handleQuestionVotesChanged,
    }),
    [handleQuestionVotesChanged, mutation],
  );
};

export const __testable__ = {
  updateQuestionAsVoted,
  handleQuestionVotesChangedCallback,
};
