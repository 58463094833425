import React from "react";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import Grow from "@mui/material/Grow";
import { styled } from "@mui/material/styles";

import { UserRole } from "../../contracts/user/user";
import Menu, { Props as MenuItemProps } from "../Menu";
import TimeAgo from "../TimeAgo";
import UserAvatar from "../UserAvatar";

export interface ParticipantType {
  username: string;
  avatar: string;
  userId: string;
  userRole?: UserRole;
  caption?: string | null | undefined;
  time?: string | number | Date;
  badgeCount?: number;
}

export interface Props extends ParticipantType {
  onSelected?: () => void;
  menuItems?: MenuItemProps["menuItems"] | null;
}

const BadgeStyled = styled(Badge)(({ theme: { palette, customTheme } }) => ({
  "> .MuiBadge-badge": {
    top: 2,
    left: 2,
    backgroundColor:
      customTheme?.chat?.badge?.backgroundColor || palette.primary.main,
    color: customTheme?.chat?.badge?.textColor || palette.common.white,
  },
}));

const ContentContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  overflowX: "hidden",
});

const NameContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

const AvatarStyled = styled(UserAvatar)(
  ({ theme: { breakpoints, spacing } }) => ({
    height: 36,
    width: 36,
    marginRight: spacing(1.5),
    [breakpoints.down("sm")]: {
      marginRight: spacing(1),
    },
  }),
);

const TimeContainer = styled(Typography)(({ theme: { palette, spacing } }) => ({
  color: palette.primary.main,
  fontWeight: 600,
  marginLeft: spacing(1),
  flexShrink: 0,
}));

const Caption = styled(Typography)(({ theme: { palette, spacing } }) => ({
  color: palette.text.secondary,
  paddingTop: spacing(0.5),
}));

const Container = styled("div")(
  ({ theme: { palette, spacing, breakpoints } }) => ({
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
    borderColor: palette.divider,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    padding: spacing(1.5),
    [breakpoints.down("sm")]: {
      padding: spacing(1),
    },
  }),
);

const GrowStyled = styled(Grow)(() => ({
  position: "absolute",
  right: 0,
}));

const Participant = (props: Props) => {
  const {
    username,
    avatar,
    userRole,
    caption,
    time,
    badgeCount,
    onSelected,
    menuItems,
  } = props;

  const clickable = !!onSelected;
  const [hovered, setHovered] = React.useState(false);

  const roleName =
    userRole === UserRole.Organizer
      ? "Host"
      : userRole === UserRole.Presenter
      ? "Presenter"
      : null;

  return (
    <Container
      sx={
        clickable
          ? ({ palette }) => ({
              "&:hover": {
                backgroundColor: `${palette.primary.main}0a`,
              },

              "&:active": {
                backgroundColor: `${palette.primary.main}40`,
              },
            })
          : undefined
      }
      onClick={onSelected}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {badgeCount ? (
        <BadgeStyled
          badgeContent={badgeCount}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <AvatarStyled username={username} image={avatar} />
        </BadgeStyled>
      ) : (
        <AvatarStyled username={username} image={avatar} />
      )}
      <ContentContainer>
        <NameContainer>
          <Typography noWrap>{username}</Typography>

          {time && (
            <TimeContainer noWrap variant="caption">
              <TimeAgo date={time} />
            </TimeContainer>
          )}
        </NameContainer>

        {roleName && !caption && (
          <Typography
            variant="caption"
            sx={{
              textTransform: "uppercase",
              paddingTop: 0.5,
            }}
          >
            {roleName}
          </Typography>
        )}
        {caption && <Caption variant="caption">{caption}</Caption>}
      </ContentContainer>

      {!!menuItems?.length && (
        <GrowStyled in={hovered}>
          <div>
            <Menu menuItems={menuItems} />
          </div>
        </GrowStyled>
      )}
    </Container>
  );
};

export default React.memo(Participant);
