import React from "react";
import { ToastContainer as ToastifyContainer } from "react-toastify";
import { styled } from "@mui/styles";

import "react-toastify/dist/ReactToastify.css";

const StyledContainer = styled(ToastifyContainer)(({ theme }) => ({
  "--toastify-color-info": theme.palette.secondary.main,
  "--toastify-icon-color-info": "var(--toastify-color-info)",
  "--toastify-color-progress-info": "var(--toastify-color-info)",
  "--toastify-color-success": theme.palette.success.main,
  "--toastify-icon-color-success": "var(--toastify-color-success)",
  "--toastify-color-progress-success": "var(--toastify-color-success)",
  // TODO: Better warning color
  "--toastify-color-warning": undefined,
  "--toastify-icon-color-warning": "var(--toastify-color-warning)",
  "--toastify-color-progress-warning": "var(--toastify-color-warning)",
  "--toastify-color-error": theme.palette.danger.main,
  "--toastify-icon-color-error": "var(--toastify-color-error)",
  "--toastify-color-progress-error": "var(--toastify-color-error)",
  "--toastify-color-progress-light": theme.palette.primary.main,

  // NOTE: width is 100vw on mobile
  "--toastify-toast-width": "clamp(300px, 50%, 480px)",
  "--toastify-text-color-light": theme.palette.text.primary,
  "--toastify-font-family": theme.typography.fontFamily,

  // TODO: Update close button color to match text color
}));

export const ToastProvider = () => (
  <StyledContainer position="bottom-left" limit={3} />
);
