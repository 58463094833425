import React, { useImperativeHandle } from "react";
import { useNavigate } from "react-router-dom";
import { navigationHelperRef } from "../helpers/navigation";

export const NavigationHelperProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();

  useImperativeHandle(navigationHelperRef, () => navigate);

  return <>{children}</>;
};
