import queryString from "query-string";
import { createRef } from "react";
import { useNavigate } from "react-router-dom";

export const navigationHelperRef = createRef<ReturnType<typeof useNavigate>>();

export interface NavigationHelperOptions {
  forceUserId?: string;
  reload?: boolean;
}

export class NavigationHelper {
  public static navigate(
    event: any,
    location: string,
    href: string = "",
    options: NavigationHelperOptions | boolean = {},
  ) {
    if (event) {
      event.preventDefault();
    }
    const { forceUserId }: NavigationHelperOptions =
      typeof options === "boolean"
        ? {
            reload: options,
          }
        : options;

    const query = queryString.parse(href);
    if (forceUserId) {
      query.userId = forceUserId;
    }
    const target = queryString.stringifyUrl({ url: location, query });

    navigationHelperRef.current?.(target);
  }
}
