import React, { memo } from "react";

import { useEventState } from "../../../providers/EventStateProvider";
import { CounterBox } from "./TimeCounter";

import { Countdown } from "./Clock";
import { Box, Typography } from "@mui/material";
import { useEvent } from "../../../providers/EventProvider";

const getStatusMessage = ({
  isEventLive,
  placeholder,
  isPastEndDate,
  replayEnabled,
}: {
  isEventLive: boolean;
  placeholder: string | null | undefined;
  isPastEndDate: boolean;
  replayEnabled: boolean;
}) => {
  // If we have a placeholder it always wins
  if (placeholder) {
    return placeholder;
  }

  // If the event is live show we are live
  if (isEventLive) {
    return "Event has started!";
  }

  // Below follows the same logic as showing the stage contents for the ViewerLayout component
  // If a replay has been enabled, and we are past the event end date, show that a video is available to watch
  if (replayEnabled && isPastEndDate) {
    return "On-demand video available, sign in to watch";
    // If the event is past it's end date, show it has ended
  } else if (isPastEndDate) {
    return "The event has ended";
  }

  // We either are waiting for the event to start (< startDate) or we are between the event start and end times
  return "";
};

function CountdownMessage({
  days,
  hours,
  minutes,
  seconds,
  isPast,
}: Countdown) {
  const { data: event } = useEvent();
  const { streamPlaceholderMessage, isEventLive, replayEnabled } =
    useEventState();
  const showTimer = !isPast && !streamPlaceholderMessage && !isEventLive;

  // Stream placeholder takes precedence. This is the text they choose after selecting `End Session`
  // If no placeholder, set to `Event has started!` if the event is live.
  const placeholder = getStatusMessage({
    isEventLive,
    placeholder: streamPlaceholderMessage,
    isPastEndDate: !!event?.endDate && event?.endDate.getTime() < Date.now(),
    replayEnabled,
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          mb: showTimer ? 1 : 0,
        }}
      >
        <CountdownText isPast={isPast} placeholderOverride={placeholder} />
        {isEventLive && (
          <Typography
            component="span"
            sx={{
              background: "#ef3b3b",
              borderRadius: 1,
              color: "white",
              ml: 0.75,
              py: "1px",
              px: 0.5,
            }}
          >
            Live
          </Typography>
        )}
      </Box>
      {showTimer ? (
        <Box display="flex" justifyContent="center">
          <CounterBox label="Days" value={days} />
          <CounterBox label="Hours" value={hours} />
          <CounterBox label="Minutes" value={minutes} />
          <CounterBox label="Seconds" value={seconds} />
        </Box>
      ) : null}
    </>
  );
}

export default memo(CountdownMessage);

type CountdownTextProps = {
  isPast: boolean;
  placeholderOverride?: string;
};

const CountdownText = memo(
  ({ isPast, placeholderOverride }: CountdownTextProps) => {
    const defaultText = isPast
      ? `Event will go live soon, stay tuned!`
      : `Event has not started yet, stay tuned!`;
    return (
      <Typography data-testid="stay-tuned-message">
        {placeholderOverride || defaultText}
      </Typography>
    );
  },
);
