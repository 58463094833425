import { IMetricsEvent } from "@introvoke/react/metrics";

export type ObjectType = Record<string, unknown>;

export enum MetricsEventGroups {
  CHAT = "Chat",
  NETWORKING_HUB = "Networking_Hub",
  VIRTUAL_STAGE = "Virtual_Stage",
}

export type MetricsEvent<TData extends ObjectType = ObjectType> = IMetricsEvent<
  MetricsEventGroups,
  TData
>;
