/** @jsxImportSource @emotion/react */

import React, { memo, useEffect, useMemo, useState } from "react";

import { css } from "@emotion/react";
import { styled, Tab, Tabs } from "@mui/material";

import { UserRole } from "../../contracts/user/user";
import { useUser } from "../../providers/UserProvider";
import {
  useChatsCount,
  useEventChatContext,
} from "../../providers/chat/ChatProvider";
import { getChannelConfig } from "../../providers/chat/getChannelConfig";
import { useAllChatChannelNewMessageCounts } from "../../providers/chat/useChatChannelNewMessageCount";
import { Chat } from "../Chat/Chat";
import { useCircle } from "../Presence/IntrovokePresence";
import { usePrevious } from "./usePrevious";
import {
  ChatLoader,
  PrivateChatProvider,
} from "../../providers/chat/PrivateChatProvider";
import { PrivateChat } from "../Chat/PrivateChat";
import { TabHeader } from "./TabHeader";
import { ChannelConfig } from "../../providers/pubnub/PubNubProvider";
import { ParticipantNameOption } from "../../contracts/enums/participant-name-options";
import { useNetworkingHub } from "../../providers/NetworkingHubProvider";
import { useIsSessionActive } from "../Presence/SocketClientProvider";

const TabsStyled = styled(Tabs)(({ theme: { palette, spacing } }) => ({
  borderBottom: `1px solid ${palette.divider}`,
  ".MuiTabs-flexContainer": {
    backgroundColor: palette.common.white,
    height: "100%",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  "	.MuiTabs-indicator": {
    display: "none",
  },
}));

const TabStyled = styled(Tab)(({ theme: { palette, spacing } }) => ({
  borderRadius: spacing(4),
  minHeight: 16,
  minWidth: 48,
  opacity: 1,
  padding: spacing(1),
  "&.Mui-selected": {
    backgroundColor: palette.secondary.main,
    color: palette.common.white,
  },
}));

type ChatType = "event" | "circle" | "extraTab" | "presenter" | "private";

interface ChatTabConfig {
  title: string;
  label: string;
  disableInput?: boolean;
  channelConfig: ChannelConfig;
  ownerEmails?: string[];
  showParticipantNames?: ParticipantNameOption;
  testId: string;
}

const isEntryValueDefined = <T extends any>(
  param: [string, T | null | undefined],
): param is [string, T] => {
  return param[1] !== null && param[1] !== undefined;
};

interface ChatTabsProps {
  extraTab?: { channelId: string; name: string };
  hideMainChat?: boolean;
  hidePrivateChat?: boolean;
  subjectId: string;
  welcomeOwner: string;
  welcomeAvatar?: string;
  showParticipantNames?: ParticipantNameOption;
}

export const ChatTabs = memo(
  ({
    extraTab,
    hideMainChat = false,
    hidePrivateChat = false,
    subjectId,
    welcomeOwner,
    welcomeAvatar,
    showParticipantNames,
  }: ChatTabsProps) => {
    const isSessionActive = useIsSessionActive();
    const { subscribe, unsubscribe, clear, setActiveChat, activeChat } =
      useEventChatContext();

    const user = useUser();
    const { data: networkingHub } = useNetworkingHub();
    const circle = useCircle();
    const prevRoom = usePrevious(circle);
    const [tab, setTab] = useState<ChatType>("event");
    const { newPrivateChatCount } = useChatsCount();

    useEffect(() => {
      if (!circle) {
        if (tab !== "extraTab") {
          setTab("event");
          setActiveChat(tabs.event?.channelConfig.channel || "");
        }
      } else if (prevRoom !== circle) {
        setTab("circle");
        setActiveChat(tabs.circle?.channelConfig.channel || "");
      }
      // eslint-disable-next-line
    }, [circle, prevRoom]);

    useEffect(() => {
      if (prevRoom !== circle) {
        setTab("circle");
        setActiveChat(tabs.circle?.channelConfig.channel || "");
      }
      // eslint-disable-next-line
    }, [circle, prevRoom]);

    const isPresenter =
      user.userRole === UserRole.Presenter ||
      user.userRole === UserRole.Organizer;

    const tabs: Record<ChatType, ChatTabConfig | null> = useMemo(() => {
      // If current user is HOST and only participants are anonymous, then they can see everyone, otherwise use regular setting
      const showNamesInChat =
        showParticipantNames === ParticipantNameOption.HOSTS_AND_OWNERS &&
        user.userRole === UserRole.Organizer
          ? ParticipantNameOption.ALL
          : showParticipantNames;

      const currentCircle = networkingHub?.networkingHubRooms.find(
        ({ id }) => id === circle?.id,
      );

      const isNetworkingHub = !!networkingHub?.uid;

      return {
        presenter:
          isPresenter && subjectId && !isNetworkingHub
            ? {
                title: "On Stage",
                label: "You are posting to Presenters Chat",
                channelConfig: getChannelConfig({
                  type: "event-presenters",
                  event: subjectId,
                }),
                showParticipantNames: showNamesInChat,
                testId: "chat-tabs-onstage",
              }
            : null,
        circle:
          subjectId && circle?.id
            ? {
                title: "Circle Chat",
                label: "You are posting to Circle Chat",
                channelConfig: getChannelConfig({
                  type: "circle",
                  event: subjectId,
                  circle: circle.id,
                }),
                ownerEmails: currentCircle?.ownerEmail,
                showParticipantNames:
                  showNamesInChat === ParticipantNameOption.HOSTS_AND_OWNERS &&
                  currentCircle?.ownerEmail?.includes(user.email)
                    ? ParticipantNameOption.ALL
                    : showNamesInChat,
                testId: "chat-tabs-circle-chat",
              }
            : null,
        event:
          !hideMainChat && subjectId
            ? {
                title: isNetworkingHub ? "Hub" : "Event",
                label: `You are posting to the ${
                  isNetworkingHub ? "Hub" : "Event"
                } Chat`,
                channelConfig: getChannelConfig({
                  type: "event",
                  event: subjectId,
                }),
                showParticipantNames: showNamesInChat,
                testId: "chat-tabs-hub",
              }
            : null,
        private:
          !hideMainChat && !hidePrivateChat && subjectId && isSessionActive
            ? {
                title: "Private",
                label: "You are posting to Private Chat",
                channelConfig: getChannelConfig({
                  type: "private",
                  channel: subjectId,
                }),
                showParticipantNames: ParticipantNameOption.ALL,
                testId: "chat-tabs-private",
              }
            : null,
        extraTab: extraTab
          ? {
              title: extraTab.name,
              label: `You are posting to ${extraTab.name}`,
              channelConfig: getChannelConfig({
                type: "extraTab",
                channel: extraTab.channelId,
              }),
              disableInput: user.userRole !== UserRole.Organizer,
              showParticipantNames: showNamesInChat,
              testId: "chat-tabs-extra",
            }
          : null,
      };
    }, [
      circle,
      extraTab,
      hideMainChat,
      networkingHub?.uid,
      isPresenter,
      subjectId,
      user.userRole,
      user.email,
      hidePrivateChat,
      showParticipantNames,
      networkingHub?.networkingHubRooms,
      isSessionActive,
    ]);

    // watch new messages count to display unread messages as tab badge
    const newMessageCounts = useAllChatChannelNewMessageCounts(
      Object.entries(tabs)
        .filter(isEntryValueDefined)
        .map((value) => value[1].channelConfig.channel),
    );

    useEffect(() => {
      const unsubscribeChats = Object.entries(tabs)
        .filter(isEntryValueDefined)
        .map(([key, { channelConfig }]) => {
          subscribe(channelConfig as any);
          return () => {
            unsubscribe(channelConfig as any);
          };
        });
      return () =>
        unsubscribeChats.forEach((unsubscribeChat) => unsubscribeChat());
    }, [subscribe, tabs, unsubscribe]);

    const availableTabs = useMemo(
      () => Object.entries(tabs).filter(isEntryValueDefined),
      [tabs],
    );

    const currentTab: string | null = useMemo(() => {
      const value = tabs[tab];
      if (value) {
        return tab;
      }
      if (availableTabs.length === 0) {
        // TODO log error to sentry
        return null;
      }
      return availableTabs[0][0];
    }, [tab, tabs, availableTabs]);

    useEffect(() => {
      // clear out new message count for current channel when user selects it.
      const selectedChannelId = tabs[tab]?.channelConfig?.channel;
      if (
        typeof selectedChannelId === "string" &&
        currentTab &&
        activeChat !== null
      ) {
        clear(selectedChannelId);
      }
    }, [clear, currentTab, tab, tabs, activeChat]);

    const shouldShowPills = availableTabs.length > 1;

    return (
      <div
        css={css`
          background-color: white;
          display: flex;
          flex: 1;
          flex-direction: column;
          height: 100%;
        `}
      >
        {currentTab && (
          <>
            {shouldShowPills && (
              <TabsStyled
                value={currentTab}
                onChange={(_event, value) => {
                  setTab(value);
                  setActiveChat(
                    Object.entries(tabs).find((o) => o[0] === value)?.[1]
                      ?.channelConfig.channel || "",
                  );
                }}
                indicatorColor="primary"
                textColor="inherit"
                aria-label="icon tabs example"
              >
                {Object.entries(tabs)
                  .filter(isEntryValueDefined)
                  .map(([key, { channelConfig, title, testId }]) => {
                    const badgeContent =
                      key === "private"
                        ? newPrivateChatCount
                        : newMessageCounts[channelConfig.channel];
                    const hideBadge =
                      key === "private"
                        ? newPrivateChatCount <= 0
                        : !newMessageCounts[channelConfig.channel];

                    return (
                      <TabStyled
                        key={key}
                        sx={{ textTransform: "none" }}
                        data-testid={testId}
                        label={
                          <TabHeader
                            badge={!hideBadge}
                            count={badgeContent}
                            text={title}
                            testId={testId}
                          />
                        }
                        value={key}
                      />
                    );
                  })}
              </TabsStyled>
            )}

            {Object.entries(tabs)
              .filter(isEntryValueDefined)
              .map(
                ([
                  key,
                  {
                    label,
                    channelConfig,
                    disableInput,
                    ownerEmails,
                    showParticipantNames,
                  },
                ]) => {
                  return key === "private" ? (
                    <PrivateChatProvider key={key}>
                      <ChatLoader fallback={null}>
                        <PrivateChat
                          hide={currentTab !== key}
                          showParticipantNames={showParticipantNames}
                        />
                      </ChatLoader>
                    </PrivateChatProvider>
                  ) : (
                    currentTab === key && (
                      <Chat
                        key={key}
                        welcomeMessage="Welcome to the chat!"
                        welcomeOwner={welcomeOwner}
                        welcomeAvatar={welcomeAvatar}
                        channelConfig={channelConfig}
                        user={user}
                        disableInput={disableInput}
                        ownerEmails={ownerEmails}
                        showParticipantNames={showParticipantNames}
                      />
                    )
                  );
                },
              )}
          </>
        )}
      </div>
    );
  },
);
