import React, { FormHTMLAttributes, type ForwardedRef } from "react";
import { FormProvider, FormProviderProps } from "react-hook-form";
import { UseAnyFormReturn } from "../../hooks/useFormWithPersistentValidation";
import { ObjectType } from "@introvoke/react/types/common";
import { typedForwardRef } from "@introvoke/react/utils/components";
import TextField from "./TextField";
import Checkbox from "./Checkbox";
import Select from "./Select";

interface FormProps<
  TFormValues extends ObjectType = ObjectType,
  TContext = any,
  TTransformedValues extends ObjectType | undefined = undefined,
> extends FormHTMLAttributes<HTMLFormElement> {
  form: Omit<
    FormProviderProps<TFormValues, TContext, TTransformedValues>,
    | "children"
    | keyof UseAnyFormReturn<TFormValues, TContext, TTransformedValues>
  > &
    UseAnyFormReturn<TFormValues, TContext, TTransformedValues>;
}

const UnwrappedForm = <
  TFormValues extends ObjectType = ObjectType,
  TContext = any,
  TTransformedValues extends ObjectType | undefined = undefined,
>(
  {
    form,
    onSubmit,
    ...htmlFormProps
  }: FormProps<TFormValues, TContext, TTransformedValues>,
  ref: ForwardedRef<HTMLFormElement>,
) => (
  <FormProvider {...form}>
    <form
      {...htmlFormProps}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit?.(e);
      }}
      ref={ref}
    />
  </FormProvider>
);

const ForwardedForm = typedForwardRef(UnwrappedForm);

type FormComponentType = typeof ForwardedForm & {
  Checkbox: typeof Checkbox;
  Select: typeof Select;
  TextField: typeof TextField;
};

const MemoizedForm = React.memo(ForwardedForm) as unknown as FormComponentType;

// TODO: these could actually be extended to use useFormContext and inherit the control
MemoizedForm.Checkbox = Checkbox;
MemoizedForm.Select = Select;
MemoizedForm.TextField = TextField;

export default MemoizedForm;
