import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import * as Sentry from "@sentry/react";

import { SocketClientProvider } from "./components/Presence/SocketClientProvider";
import { NavigationHelperProvider } from "./providers/NavigationHelperProvider";
import { BaseThemeProvider } from "./providers/ThemeProvider";
import { ToastProvider } from "./providers/ToastProvider";
import ErrorPage from "./components/ErrorPage";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const Providers: React.FC = Sentry.withProfiler(({ children }) => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <BaseThemeProvider>
            <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog={false}>
              <SocketClientProvider>
                <NavigationHelperProvider>{children}</NavigationHelperProvider>
              </SocketClientProvider>
              <ToastProvider />
              <ReactQueryDevtools />
            </Sentry.ErrorBoundary>
          </BaseThemeProvider>
        </RecoilRoot>
      </QueryClientProvider>
    </BrowserRouter>
  );
});
