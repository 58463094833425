import Bowser from "bowser";
import Cookies from "js-cookie";

/**
 * Enum representing various browsers by name
 */
export enum Browser {
  CHROME = "Chrome",
  EDGE = "Edge",
  FIREFOX = "Firefox",
  OPERA = "Opera",
  INTERNET_EXPLORER = "Internet Explorer",
  SAFARI = "Safari",
  UNKNOWN = "Unknown",
}

export enum OperatingSystem {
  WINDOWS = "Windows",
  MAC_OS = "MacOS",
  LINUX = "Linux",
  IOS = "iOS",
  ANDROID = "Android",
  CHROME_OS = "ChromeOS",
}

const BowserNameToOs: Record<string, OperatingSystem> = {
  Windows: OperatingSystem.WINDOWS,
  macOS: OperatingSystem.MAC_OS,
  iOS: OperatingSystem.IOS,
  Linux: OperatingSystem.LINUX,
  Android: OperatingSystem.ANDROID,
  ChromeOS: OperatingSystem.CHROME_OS,
};

const BowserNameToBrowser: Record<string, Browser> = {
  Chrome: Browser.CHROME,
  Chromium: Browser.CHROME,
  Edge: Browser.EDGE,
  "Microsoft Edge": Browser.EDGE,
  Firefox: Browser.FIREFOX,
  Opera: Browser.OPERA,
  "Internet Explorer": Browser.INTERNET_EXPLORER,
  Safari: Browser.SAFARI,
};

const getBowser = () => Bowser.getParser(navigator.userAgent);

/**
 * Returns `true` if the current browser is a chrome/chromium based browser
 */
export const isBrowserChromiumBased = () => {
  const userAgent = navigator.userAgent;
  return (
    // https://stackoverflow.com/a/62797156
    !!(window as any).chrome ||
    // If it doesn't have chrome or it has edge, it is not chromium based
    Boolean(userAgent.match(/Chrome/) && !userAgent.match(/Edge/))
  );
};

/**
 * Returns the current `Browser` name value
 */
export const getBrowserName = (): Browser => {
  const bowser = getBowser();
  const name = bowser.getBrowserName();
  if (BowserNameToBrowser[name]) {
    return BowserNameToBrowser[name];
  }

  // If no known name, try to detect a chromium based browser (like Brave)
  return isBrowserChromiumBased() ? Browser.CHROME : Browser.UNKNOWN;
};

/**
 * Returns the platform the browser is currently running on
 */
export const getBrowserPlatform = () => {
  const bowser = getBowser();
  return bowser.getPlatformType();
};

/**
 * Returns the current version of the browser
 */
export const getBrowserVersion = () => {
  const bowser = getBowser();
  return bowser.getBrowserVersion();
};

/**
 * Returns `true` if the current browser is Firefox
 */
export const isFirefox = () => getBrowserName() === Browser.FIREFOX;

/**
 * Returns `true` if the current browser is Chrome
 */
export const isChrome = () => getBrowserName() === Browser.CHROME;

/**
 * Returns `true` if the current browser is Safari
 */
export const isSafari = () => getBrowserName() === Browser.SAFARI;

/**
 * Returns `true` if the current browser is Opera
 */
export const isOpera = () => getBrowserName() === Browser.OPERA;

export const isDesktop = () => getBrowserPlatform() === "desktop";

/**
 * Returns the current operating system name
 */
export const getOperatingSystem = () => {
  const bowser = getBowser();
  const os = bowser.getOSName();
  return os ? BowserNameToOs[os] ?? os : undefined;
};

/**
 * Returns `true` if the current browser is running in MacOS
 */
export const isMacOS = () => getOperatingSystem() === OperatingSystem.MAC_OS;

/**
 * Returns `true` if the current browser is running in iOS
 */
export const isIOS = () => getOperatingSystem() === OperatingSystem.IOS;

/**
 * Returns `true` if the current browser is running in Windows
 */
export const isWindows = () => getOperatingSystem() === OperatingSystem.WINDOWS;

/**
 * Returns `true` if the current browser is running in Android
 */
export const isAndroid = () => getOperatingSystem() === OperatingSystem.ANDROID;

/**
 * Returns `true` if the current browser is running in Windows
 */
export const isLinux = () => getOperatingSystem() === OperatingSystem.LINUX;

/**
 * Returns whether or not the user's browser is optimized for video conferencing
 */
export const isOptimalBrowser = () => {
  const bowser = getBowser();
  return !!bowser.satisfies({
    chrome: ">95",
    chromium: ">95",
    "microsoft edge": ">95",
  });
};

/**
 * Returns `true` if the browser is considered a mobile browser
 */
export const isMobileBrowser = () => {
  const platform = getBrowserPlatform().toLowerCase();
  return platform === "mobile" || platform === "tablet";
};

/**
 * Returns `true` if the environment the app is running in supports accessing local storage
 */
export const isLocalStorageEnabled = () => {
  try {
    window.localStorage.setItem("sequel:supported", "true");
    window.localStorage.removeItem("sequel:supported");
  } catch (e) {
    // If caught the env is missing the ability to set items in storage
    return false;
  }
  return true;
};

/**
 * Returns `true` if the environment the app is running in supports accessing session storage
 */
export const isSessionStorageEnabled = () => {
  try {
    window.sessionStorage.setItem("sequel:supported", "true");
    window.sessionStorage.removeItem("sequel:supported");
  } catch (e) {
    // If caught the env is missing the ability to set items in storage
    return false;
  }
  return true;
};

export const isCookieStorageEnabled = () => {
  try {
    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/cookieEnabled
    if (!navigator.cookieEnabled) {
      // Indicates the environment is specifically blocking cookies or they are not supported
      return false;
    }

    // There are cases where 3rd party cookies are disabled and return true in iframes from the boolean above
    // so in this case we need to manually set a cookie and test if it was actually set
    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/cookieEnabled#value
    Cookies.set("sequel:supported", "true", {
      sameSite: "none",
      secure: true,
    });
    if (!Cookies.get("sequel:supported")) {
      return false;
    }
    Cookies.remove("sequel:supported");
  } catch (e) {
    // Cookie access should never throw but you never know
    return false;
  }

  return true;
};
