import React from "react";

import { useTheme } from "@mui/material/styles";
import { TabProps } from "@mui/material/Tab";
import { Theme as ApiTheme } from "../../contracts/customization/theme";

import { useUser } from "../../providers/UserProvider";
import { useChatsCount } from "../../providers/chat/ChatProvider";
import { useEventManagement } from "../../providers/EventProvider";
import { useParticipantCount } from "../../providers/pubnub/usePubNubHereNow";
import { useGetPresentUser } from "../../providers/pubnub/usePresenceList";
import { useSocketClient } from "../Presence/SocketClientProvider";
import { SideBarTabs } from "./types";
import { useShowPoweredBy } from "@src/providers/customization";

export interface Tab {
  /**
   * Unique tab key
   */
  key: SideBarTabs;
  /**
   * Tab label
   */
  label: string;
  /**
   * Tab icon
   */
  icon: TabProps["icon"];
  /**
   * The value for the badge indicator
   */
  badgeCount?: number;
  /**
   * Flag indicating if the badge count should be persisted or
   * if it should be dynamically cleared on tab focus
   */
  persistentBadgeCount?: boolean;
  /**
   * The content to render for this tab
   */
  content: React.ReactNode;
}

export interface CommonController {
  user: ReturnType<typeof useUser>;
  participantCount: number;
  theme: ApiTheme | null | undefined;
  showPoweredBy: boolean;
  chatCount: number;
  getPresentUser: ReturnType<typeof useGetPresentUser>;
  blockUser: (email: string) => Promise<void>;
  socketClient: ReturnType<typeof useSocketClient>;
}

/**
 * Common side bar controller hook
 */
const useCommonController = (): CommonController => {
  const user = useUser();
  const getPresentUser = useGetPresentUser();
  const participantCount = useParticipantCount();
  const { totalNewChatCount } = useChatsCount();
  const { customTheme } = useTheme();
  const socketClient = useSocketClient();

  const { blockEmails } = useEventManagement();

  const blockUser = React.useCallback(
    async (email: string) => {
      await blockEmails([email]);
    },
    [blockEmails],
  );

  const showPoweredBy = useShowPoweredBy();

  return {
    user,
    theme: customTheme,
    participantCount,
    chatCount: totalNewChatCount,
    showPoweredBy,
    getPresentUser,
    blockUser,
    socketClient,
  };
};

export default useCommonController;
