import { Actions } from "@src/graphql/schemas/actions";
import type { SocketClient } from "@src/graphql/SocketClient/SocketClient";
import { useDeepCompareEffect, useUnmount } from "react-use";
import { useMutation, useQueryClient } from "react-query";

import { emitAsPromise } from "./emitAsPromise";
import { extractIdsFromUrl } from "@src/helpers/utils";
import {
  FeatureFlag,
  useFeatureFlag,
} from "@src/providers/FeatureFlagsProvider";
import type { GraphQlClient } from "@src/graphql/SocketClient/GraphQlClient";
import { useClientSessionId, useIsSessionActive } from "./SocketClientProvider";
import { createTemporaryRoomsQueryKey } from "@src/providers/NetworkingHubProvider/common";
import { useTemporaryRooms } from "@src/providers/NetworkingHubProvider";

interface CreateDeepCompareEffectArgs {
  isV2ChatServerEnabled?: boolean;
  client: SocketClient;
  connected: boolean;
  eventId: string | null;
  sessionId?: string | null;
  isSessionActive: boolean;
  sync: () => void;
  reset: () => void;
}

const createDeepCompareEffect = (
  args: CreateDeepCompareEffectArgs,
): [() => void, React.DependencyList] => {
  return [
    () => {
      const {
        isV2ChatServerEnabled,
        client,
        connected,
        eventId,
        sessionId,
        isSessionActive,
        sync,
        reset,
      } = args;
      const gqlClient = client as GraphQlClient;

      const { networkingHubId } = extractIdsFromUrl();

      if (
        !connected ||
        !client.connected ||
        !eventId ||
        !sessionId ||
        !isSessionActive
      ) {
        return reset();
      }

      // sync event
      sync();

      // if v2 chat server is enabled => check if presence needs to be synced
      if (isV2ChatServerEnabled) {
        // enable/disable presence if networkingHub/event on the same GQL client.
        // This is important especially when switching between event and networking hub
        gqlClient.setPresence(!!networkingHubId);
      }
    },
    Object.values(args),
  ];
};

/**
 * Emits a `set-event` event to the socket server to set the eventId on the user's session.
 * The `eventId` is required for the user to be able to use any socket related actions
 * such as create questions and polls.
 */
export const useSyncSocketEvent = (
  client: SocketClient,
  connected: boolean,
  eventId: string | null,
) => {
  const isV2ChatServerEnabled = useFeatureFlag(FeatureFlag.CHAT_SERVER_V2);
  const eventName = isV2ChatServerEnabled ? Actions.SET_EVENT : "set event";

  // this is important to retrigger sync if sessionId changes
  const sessionId = useClientSessionId();
  const isSessionActive = useIsSessionActive();
  const queryClient = useQueryClient();
  const { refetch: refetchRoomUsers } = useTemporaryRooms(eventId);

  const { status, reset, mutateAsync } = useMutation(
    async () => {
      if (client.connected) await emitAsPromise(client, eventName, eventId);
    },
    {
      onMutate: async () => {
        // manually cancel existing socket fetches
        await queryClient.cancelQueries(
          createTemporaryRoomsQueryKey(eventId as string),
        );
      },
      onSuccess: async () => {
        // save socket session eventId locally in cache to be used in other parts of the app
        queryClient.setQueryData(Actions.SET_EVENT, eventId);
        await refetchRoomUsers();
      },
    },
  );

  useDeepCompareEffect(
    ...createDeepCompareEffect({
      isV2ChatServerEnabled,
      client,
      connected,
      eventId,
      sessionId,
      isSessionActive,
      sync: mutateAsync,
      reset,
    }),
  );

  useUnmount(() => {
    // reset existing socket fetches to avoid leftover data when switching between events and hubs
    queryClient.resetQueries(createTemporaryRoomsQueryKey(eventId as string));
  });

  return status;
};

/**
 * Hook that returns the latest eventId which was set in the user's session on the socket server
 */
export const useSyncedSocketEventId = () => {
  const queryClient = useQueryClient();
  return queryClient.getQueryData<string | null>(Actions.SET_EVENT);
};

export const __testable__ = {
  createDeepCompareEffect,
};
