import { Amplify } from "aws-amplify";
import { ApiConfig } from "../api/api-config";
import { getAuthToken } from "@src/helpers/authSession";
import { extractIdsFromUrl } from "@src/helpers/utils";
import { getUserRoleCookie } from "@src/helpers/cookie";

/**
 * The custom headers to be sent with the GraphQL request
 */
export interface GraphQlHeaders {
  session?: string | null;
}

const gqlConfig = ApiConfig.GetAppSyncConfig();

Amplify.configure({
  ...gqlConfig,
  disableOffline: true,
});

export const getToken = () => {
  const { networkingHubId, eventId } = extractIdsFromUrl();

  // There is a race condition somewhere related to loading the
  // auth token, for now check if in the auth session
  // and if not try to get from cookies
  return (
    getAuthToken() ||
    getUserRoleCookie(eventId).authKey ||
    getUserRoleCookie(networkingHubId).authKey ||
    // TODO: REMOVE THIS!!! This is temporary until user params is fixed
    process.env.REACT_APP_AWS_APPSYNC_API_KEY ||
    undefined
  );
};
