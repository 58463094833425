import Box from "@mui/material/Box";
import React, { PropsWithChildren } from "react";

export const AbsoluteCenterPage = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
      }}
    >
      {children}
    </Box>
  );
};

export default AbsoluteCenterPage;
