import React, { memo, useCallback, useMemo, useRef, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

import * as Yup from "yup";
import "add-to-calendar-button/assets/css/atcb.css";
import { Form, FormikProvider, useFormik } from "formik";

import { useEvent, useHasEventEnded } from "../../providers/EventProvider";
import { useNetworkingHub } from "../../providers/NetworkingHubProvider";
import { useUser } from "../../providers/UserProvider";
import { useIsRoute, Route } from "@src/hooks/useIsRoute";
import { useDialog } from "@src/providers/DialogProvider";
import { useIsReplayEnabled } from "@src/providers/EventStateProvider";
import { PostMessageEvent, useEmitEvent } from "@src/providers/embed";

import { Api } from "../../api/api";
import { RegistrantNew } from "../../api/event-api";

import { addAuthKeyToUrl } from "@src/helpers/authSession";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";

import Button from "@src/components/buttons/Button";

import AddToCalendarButton from "../Event/EventStage/AddToCalendarButton";
import { TextInputLiveFeedback } from "../Forms/TextInputLiveFeedback";
import { TabTitle } from "../TabTitle";
import CustomQuestions from "./CustomQuestions";
import { JoinCodeDialog } from "./JoinCodeDialog";

import {
  RegisteredAttendeeResponse,
  RegistrationQuestion,
  RegistrationQuestionAnswer,
  MAX_TEXT_LONG_LENGTH,
} from "../../contracts/event/event";
import { User } from "@src/contracts/user/user";

interface QuestionsToFormik {
  initialValues: {
    [x: string]: string;
  };
  validation: {
    [x: string]: any;
  };
}

type FormValues = User;

const ERROR_MESSAGES = {
  INVALID_EVENT: "Event is not valid. Please check your link.",
  EMAIL_ALREADY_REGISTERED:
    "This email is already registered, please check your email.",
  REGISTRATION_FAILED: "An error occured while registering. Please try again",
};

/**
 * @param {Object} formValues
 * The key-value pair object of the fields in the form
 *
 * @param {Object} customQuestionsValues
 * The initial key-value pair object of the customQuestions to extract from the form
 * => use the keys from this object to extract their values from the current form
 */
const getCustomQuestionsFromForm = (
  formValues: any,
  customQuestionsValues: any,
): RegistrationQuestionAnswer[] => {
  const fieldNames = Object.keys(customQuestionsValues);
  return fieldNames.map((question) => ({
    questionId: question,
    answer: formValues[question],
  }));
};

const ListItemStyled = styled(ListItem)({
  display: "list-item",
  paddingTop: 2,
  paddingBottom: 2,
  paddingLeft: 0,
});

const TextButtonStyled = styled((props: TypographyProps) => (
  <Typography component="span" color="primary" {...props} />
))({
  fontWeight: "bold",
  cursor: "pointer",
  "&:hover": { textDecoration: "underline" },
});

interface RegisteredViewProps {
  joinCode: string;
}

const RegisteredView = memo(({ joinCode }: RegisteredViewProps) => {
  const theme = useTheme();
  return (
    <Stack
      textAlign="center"
      spacing={2}
      alignItems="center"
      width="100%"
      p={3}
    >
      <Box sx={{ position: "absolute", top: "-10vh" }}>
        <ConfettiExplosion
          width={Math.min(window.innerWidth, 800)}
          colors={[
            theme.palette.primary.main,
            theme.palette.primary.light,
            theme.palette.primary.dark,
          ]}
        />
      </Box>
      <TabTitle>
        <Typography
          variant="h1"
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            width: "100%",
            textAlign: "center",
          }}
        >
          {"You're registered!"}
        </Typography>
      </TabTitle>

      <Typography>
        Check your inbox for a calendar invite or join directly by clicking
        below.
      </Typography>
      <Stack spacing={1.5} alignItems="stretch">
        <Button
          variant="contained"
          color="primary"
          data-testid="goto-event-btn"
          size="large"
          sx={{ marginTop: 5 }}
          onClick={() => {
            if (joinCode) {
              addAuthKeyToUrl("joinCode", joinCode);
            }
          }}
        >
          Join session
        </Button>
        <AddToCalendarButton joinCode={joinCode} />
      </Stack>
    </Stack>
  );
});

interface ResendEmailSectionProps {
  isNetworkingHub: boolean;
  email: string;
}

const ResendEmailSection = memo(
  ({ isNetworkingHub, email }: ResendEmailSectionProps) => {
    const [emailResent, setEmailResent] = useState({
      email,
      didResend: false,
      resentText: "",
      isSending: false,
    });
    const { data: event } = useEvent();
    const { data: networkingHub } = useNetworkingHub();

    const { openDialog } = useDialog();
    const { mutate: triggerResendEmail } = useMutation(
      async () => {
        setEmailResent({
          ...emailResent,
          isSending: true,
        });

        if (isNetworkingHub) {
          return Api.NetworkingHubApi.ResendEmail(
            networkingHub?.uid as string,
            emailResent.email,
          );
        } else {
          return Api.EventApi.ResendEmail(
            event?.uid as string,
            emailResent.email,
          );
        }
      },
      {
        onSuccess: () => {
          // notify user
          toast.success("The email has been resent, please check your inbox.");
          setEmailResent({
            ...emailResent,
            didResend: true,
            resentText: "The email has been resent, please check your inbox.",
            isSending: false,
          });
        },
        onError: () => {
          setEmailResent({
            ...emailResent,
            didResend: false,
            resentText: "There was a problem sending the email.",
            isSending: false,
          });
        },
      },
    );

    return (
      <Box
        data-testid="pre-registration-help"
        sx={{
          width: "100%",
          backgroundColor: "#F5F5F5",
          borderRadius: 4,
          padding: 2,
          marginLeft: 1,
          marginTop: "auto",
          position: "sticky",
          bottom: 0,
        }}
      >
        <Typography variant="h6" sx={{ mb: 1 }}>
          Hey there 👋, need any help?
        </Typography>

        <List sx={{ listStyleType: "disc", marginLeft: 3 }}>
          {!emailResent.didResend ? (
            <ListItemStyled>
              Trouble finding your registration email?{" "}
              {!emailResent.isSending ? (
                <>
                  Click{" "}
                  <Box
                    component="span"
                    onClick={() => triggerResendEmail()}
                    sx={{
                      display: "inline",
                      color: "blue.400",
                      cursor: "pointer",
                    }}
                  >
                    HERE
                  </Box>{" "}
                  to resend.
                </>
              ) : (
                <>
                  <CircularProgress size={12} sx={{ ml: 1, mr: 0.5 }} />
                  Sending...
                </>
              )}
            </ListItemStyled>
          ) : (
            <ListItemStyled>{emailResent.resentText}</ListItemStyled>
          )}
          <ListItemStyled>
            You can also join by entering your unique
            <Box
              sx={{
                display: "inline",
                ml: 0.5,
                color: "blue.400",
                cursor: "pointer",
              }}
              onClick={() => openDialog("join-code-modal", <JoinCodeDialog />)}
            >
              JOIN CODE.
            </Box>
          </ListItemStyled>
        </List>
      </Box>
    );
  },
);

interface RegistrationFormProps {
  entityType: "event" | "networking-hub";
  onRegistered: (joinCode: string, email: string) => void;
}

const RegistrationForm = memo(
  ({ entityType, onRegistered }: RegistrationFormProps) => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { data: event } = useEvent();
    const { data: networkingHub } = useNetworkingHub();
    const formRef = useRef<HTMLFormElement>(null);
    const { openDialog } = useDialog();

    const theme = useTheme();

    const eventHasEnded = useHasEventEnded();
    const customQuestions = useMemo(
      () =>
        (event?.registration?.enabled &&
          event?.registration?.customQuestions) ||
        (networkingHub?.registration?.enabled &&
          networkingHub?.registration?.customQuestions) ||
        [],
      [event, networkingHub],
    );
    const isNetworkingHub = entityType === "networking-hub";

    const submitButtonText = loading
      ? "Registering"
      : eventHasEnded
      ? "Watch now"
      : "Register";
    const title = eventHasEnded
      ? "Register to watch the event"
      : `Register for this ${isNetworkingHub ? "networking hub" : "event"}`;
    const description = eventHasEnded
      ? "Fill in the form below to register and watch the event.  You will receive a confirmation by email."
      : `Fill in the form below to register for this ${
          isNetworkingHub ? "networking hub" : "event"
        }. You will
receive a confirmation by email.`;

    // TODO add type and limit checking
    const formCustomQuestions = useMemo(
      () =>
        customQuestions.reduce<QuestionsToFormik>(
          (questionsToFormik, curr: RegistrationQuestion) => {
            questionsToFormik.initialValues[curr.id] = "";
            questionsToFormik.validation[curr.id] = curr.isRequired
              ? Yup.string()
                  .required("Answer is required")
                  .max(MAX_TEXT_LONG_LENGTH)
              : Yup.string().max(MAX_TEXT_LONG_LENGTH);
            return questionsToFormik;
          },
          { initialValues: {}, validation: {} },
        ),
      [customQuestions],
    );

    const handleSubmit = useCallback(
      async (values: FormValues) => {
        setErrorMessage("");
        setLoading(true);
        const { name, email, ...restFields } = values;

        const newRegistration: RegistrantNew = {
          name: values.name,
          email: values.email,
          customQuestionsAnswers: getCustomQuestionsFromForm(
            restFields,
            formCustomQuestions.initialValues,
          ),
        };

        let result;
        try {
          if (isNetworkingHub) {
            result = await Api.NetworkingHubApi.RegisterForNetworkingHub(
              networkingHub?.uid as string,
              newRegistration,
            );
          } else {
            result = await Api.EventApi.RegisterForEvent(
              event?.uid as string,
              newRegistration,
            );
          }
        } catch (e) {
          setErrorMessage(ERROR_MESSAGES.REGISTRATION_FAILED);
          return setLoading(false);
        }

        if (result?.status === 204) {
          setErrorMessage(ERROR_MESSAGES.EMAIL_ALREADY_REGISTERED);

          formRef.current!.scrollTop = formRef.current?.scrollHeight || 0;
        } else {
          const data = result?.data as RegisteredAttendeeResponse;
          if (eventHasEnded) {
            // immediately navigate to watch the replay
            addAuthKeyToUrl("joinCode", data?.joinCode as string);
          } else {
            onRegistered(data?.joinCode ?? "", data?.email ?? "");
          }
        }
        setLoading(false);
      },
      [
        event?.uid,
        networkingHub?.uid,
        isNetworkingHub,
        formCustomQuestions.initialValues,
        eventHasEnded,
        onRegistered,
      ],
    );

    const user = useUser();
    const form = useFormik<FormValues>({
      enableReinitialize: true,
      initialValues: {
        ...user,
        ...formCustomQuestions.initialValues,
      },
      validationSchema: Yup.object({
        name: Yup.string()
          .min(3, "Must be at least 3 characters")
          .required("Full name is required"),
        email: Yup.string().email("Invalid Email").required("Email required"),
        ...formCustomQuestions.validation,
      }),
      onSubmit: handleSubmit,
    });

    const { errors, isValidating, isSubmitting, values } = form;

    // check if the form has any errors
    const hasFormErrors = Object.values(errors).some((error) => error);

    const submissionDisabled =
      !form.dirty || isValidating || isSubmitting || hasFormErrors;

    const showHelpSection =
      errorMessage === ERROR_MESSAGES.EMAIL_ALREADY_REGISTERED;

    const termsOfService =
      event?.registration?.terms || networkingHub?.registration?.terms;
    const privacyPolicy =
      event?.registration?.privacy || networkingHub?.registration?.privacy;

    const hasTerms = !!(termsOfService || privacyPolicy);

    return (
      <FormikProvider value={form}>
        <Form
          className="flex h-full w-full flex-col overflow-hidden overflow-y-auto bg-white"
          ref={formRef}
        >
          <div className="w-full p-6">
            <Stack textAlign="center" spacing={2} alignItems="center">
              <TabTitle compact>
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  {title}
                </Typography>
              </TabTitle>

              <Typography>{description}</Typography>
            </Stack>

            <TextInputLiveFeedback
              label="Full Name"
              id="name"
              name="name"
              testId="name-input"
              placeholder="Enter your name"
              style={{
                marginBottom: theme.spacing(0),
              }}
            />
            <TextInputLiveFeedback
              label="Email Address"
              id="email"
              name="email"
              testId="email-input"
              placeholder="Enter your email address"
              style={{
                marginBottom: theme.spacing(0),
              }}
            />

            <CustomQuestions
              customQuestions={customQuestions}
              formValues={form.values}
            />

            {errorMessage ? (
              <Typography variant="body2" color="error" sx={{ mb: 1 }}>
                {errorMessage}
              </Typography>
            ) : null}

            {hasTerms && (
              <Typography
                style={{
                  color: theme.typography.caption.color,
                  fontSize: "12px",
                  marginBottom: theme.spacing(1),
                }}
              >
                By registering, I agree to the{" "}
                {termsOfService && (
                  <Link href={termsOfService} target="_blank">
                    Terms of Service
                  </Link>
                )}
                {privacyPolicy && termsOfService && " and "}
                {privacyPolicy && (
                  <Link href={privacyPolicy} target="_blank">
                    Privacy Policy
                  </Link>
                )}
              </Typography>
            )}

            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              loadingPosition="start"
              data-testid="register-btn"
              loading={loading}
              disabled={submissionDisabled}
              onClick={() => form.handleSubmit}
              sx={{ marginTop: theme.spacing(3) }}
            >
              {submitButtonText}
            </Button>
            {!showHelpSection && (
              <Typography mt={2}>
                {"Already have a Join Code? Click "}
                <TextButtonStyled
                  onClick={() =>
                    openDialog("join-code-modal", <JoinCodeDialog />)
                  }
                >
                  HERE
                </TextButtonStyled>
                {"."}
              </Typography>
            )}
          </div>
          {showHelpSection && (
            <ResendEmailSection
              email={values.email}
              isNetworkingHub={isNetworkingHub}
            />
          )}
        </Form>
      </FormikProvider>
    );
  },
);

/**
 * Renders the sidebar for our own 1st-party Sequel registration form
 */
export const SequelRegistrationSideBar = memo(() => {
  const [success, setSuccess] = useState(false);
  const isNetworkingHub = useIsRoute(Route.NETWORKING_HUB);
  const [joinCode, setJoinCode] = useState("");
  const [email, setEmail] = useState("");

  const hasEventEnded = useHasEventEnded();
  const replayEnabled = useIsReplayEnabled();

  const handleOnRegistered = useCallback((code: string, email: string) => {
    setJoinCode(code);
    setEmail(email);
    setSuccess(true);
  }, []);

  useEmitEvent(
    {
      event: email
        ? PostMessageEvent.USER_REGISTERED
        : PostMessageEvent.USER_UPDATED,
      data: {
        email,
        eventId: "",
        joinCode: joinCode,
      },
    },
    [email],
  );

  if (hasEventEnded && !replayEnabled) return null;

  return success ? (
    <RegisteredView joinCode={joinCode} />
  ) : (
    <RegistrationForm
      entityType={isNetworkingHub ? "networking-hub" : "event"}
      onRegistered={handleOnRegistered}
    />
  );
});
