import axios from "axios";
import { ApiConfig } from "./api-config";

const CreateNewRoom = async (eventUid: string) => {
  const options = {
    name: eventUid,
    privacy: "private",
    properties: {
      enable_mesh_sfu: true,
      enable_knocking: true,
      enable_people_ui: true,
      enable_terse_logging: true,
    },
  };

  const response = await axios.post(
    "https://api.daily.co/v1/rooms",
    options,
    ApiConfig.GetDailyToken(),
  );

  return response.data;
};

const GetNewRoom = async (eventUid: string) => {
  const response = await axios.get(
    `https://api.daily.co/v1/rooms/${eventUid}`,
    ApiConfig.GetDailyToken(),
  );

  if (response.status !== 200) {
    return false;
  }
  return true;
};

const GetDailyMeetingToken = async (
  roomName: string,
  isOwner: boolean,
  userName: string,
) => {
  const response = await axios.post(
    "https://api.daily.co/v1/meeting-tokens",
    {
      properties: {
        room_name: roomName,
        user_name: userName,
        is_owner: isOwner,
        enable_recording_ui: isOwner,
        enable_recording: isOwner ? "cloud" : undefined,
      },
    },
    ApiConfig.GetDailyToken(),
  );
  const { token, error } = await response.data;
  if (error) {
    throw new Error(error);
  }
  return token;
};

export const DailyApi = {
  CreateNewRoom,
  GetDailyMeetingToken,
  GetNewRoom,
};
