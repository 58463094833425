import Picker from "@emoji-mart/react";
import React, {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  styled,
  Box,
  Typography,
} from "@mui/material";

import { useLatestCallback } from "../../hooks/useLatestCallback";
import EmojiIcon from "../Event/EmojiIcon";
import { SendIcon } from "../icons";
import { useClickAway, useLatest } from "react-use";
import { useParticipantCount } from "@src/providers/pubnub/usePubNubHereNow";
import {
  ChatTimeouts,
  CHAT_CHARACTER_LIMIT,
  EventSizes,
} from "@src/providers/chat/ChatProvider";

const calculateTimeout = (participantCount: number) => {
  if (participantCount > EventSizes.LARGE) {
    return ChatTimeouts.SLOW;
  } else if (
    participantCount <= EventSizes.LARGE &&
    participantCount > EventSizes.MEDIUM
  ) {
    return ChatTimeouts.MEDIUM;
  }

  return ChatTimeouts.STANDARD;
};

const InputButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  " button": {
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: `${theme.palette.secondary.main}40`,
    },

    "&:disabled": {
      color: theme.palette.text.disabled,
    },
  },
}));

const ChatInputContainer = styled("div")(({ theme }) => ({
  position: "relative",
  margin: theme.spacing(1),
  marginLeft: theme.spacing(1.5),
  marginRight: theme.spacing(1.5),
  [theme.breakpoints.down("sm")]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export const EmojiPicker = memo(
  ({
    onSelectEmoji,
    show = false,
    onClose,
  }: {
    show?: boolean;
    onSelectEmoji?: (emoji: string) => void;
    onClose?: () => void;
  }) => {
    const handleSelectEmoji = useLatestCallback((emoji: any) => {
      if (typeof emoji.native === "string") {
        onSelectEmoji?.(emoji.native);
      }
      onClose?.();
    });

    if (!show) return null;

    return (
      <Box
        sx={{
          position: "absolute",
          bottom: "100%",
          right: 0,
        }}
      >
        <Picker
          set="native"
          autoFocus
          perLine={7}
          onEmojiSelect={handleSelectEmoji}
          onClickOutside={onClose}
        />
      </Box>
    );
  },
);

export const ChatInput = ({
  label,
  onSubmit,
  disableInput,
  disableSendButton,
}: {
  onSubmit: (message: string) => void;
  label?: string;
  endAdornment?: React.ReactNode;
  disableInput?: boolean;
  disableSendButton?: boolean;
}) => {
  const participantCount = useParticipantCount();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement>();
  const shouldFocusRef = useRef(true);

  const [showEmoji, setShowEmoji] = useState(false);
  const [value, setValue] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  useClickAway(containerRef, () => {
    // Do not pull focus away if they are clicking around
    shouldFocusRef.current = false;
  });

  const handleShowEmoji = useCallback((event: MouseEvent<HTMLElement>) => {
    // PROD-4528
    // Do not remove, onOutsideClick inside Picker component event fired from click that show emoji picker
    // it causes to close emoji picker immediately. This strange behavior happens only when host tutorial modal was shown
    event.stopPropagation();
    setShowEmoji((visible: boolean) => !visible);
  }, []);

  const handleSelectEmoji = useCallback((emoji: string) => {
    setValue((prev) => `${prev.length ? `${prev} ` : ""}${emoji} `);
  }, []);

  const handleClose = useCallback(() => {
    setShowEmoji(false);
  }, []);

  const chatDelayTimeout = calculateTimeout(participantCount);
  const handleRefresh = useCallback(() => {
    setIsDisabled(true);
    shouldFocusRef.current = true;
    setTimeout(() => {
      setIsDisabled(false);
    }, chatDelayTimeout);
  }, [chatDelayTimeout]);

  useEffect(() => {
    if (!shouldFocusRef.current || isDisabled) return;
    try {
      // re-focus the input if they are waiting patiently
      inputRef.current?.focus();
    } catch (e) {
      // ignore
    }
  }, [isDisabled]);

  const latestSubmit = useLatest(onSubmit);
  const latestText = useLatest(value);
  const handleSubmit = useCallback(() => {
    const normalized = latestText.current.trim();
    if (!normalized || normalized.length > CHAT_CHARACTER_LIMIT) {
      return;
    }
    latestSubmit.current(normalized);
    setValue("");
    handleRefresh();
  }, [latestText, latestSubmit, handleRefresh]);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit();
      }
    },
    [handleSubmit],
  );
  const handleChange = useCallback(
    ({
      target: { value },
    }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setValue(value);
    },
    [],
  );

  const sendDisabled =
    disableSendButton || !value?.length || value.length > CHAT_CHARACTER_LIMIT;

  return (
    <ChatInputContainer ref={containerRef}>
      {value?.length > CHAT_CHARACTER_LIMIT && (
        <Typography data-testid="chat-character-limit-error" color="error">
          Exceeded Character Limit of {CHAT_CHARACTER_LIMIT}
        </Typography>
      )}
      <Box position="relative">
        <EmojiPicker
          show={showEmoji}
          onSelectEmoji={handleSelectEmoji}
          onClose={handleClose}
        />
        <TextField
          value={value}
          inputRef={inputRef}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          autoComplete="off"
          size="small"
          multiline
          fullWidth
          disabled={disableInput || isDisabled}
          placeholder="Join the conversation..."
          label={label}
          InputLabelProps={{
            shrink: true,
            sx: ({ palette }) => ({
              "&.MuiInputLabel-root": {
                color: palette.text.primary,
              },
              "&.MuiInputLabel-shrink": {
                transform: "translate(0, 1.5px) scale(0.8)",
                letterSpacing: 1,
              },
            }),
          }}
          inputProps={{
            "data-testid": "chat-message-box",
          }}
          InputProps={{
            sx: ({ palette, spacing }) => ({
              "&.MuiInputBase-root": {
                backgroundColor: palette.grey[100],
                transition: "0.3s",
                borderRadius: 4,
                paddingTop: "6px",
                borderColor: palette.divider,
                borderStyle: "solid",
                borderWidth: 1,

                "&:hover": {
                  borderColor: palette.primary.main,
                },
              },
              ".MuiInputBase-input": {
                padding: spacing(1),
              },
            }),
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                <InputButtonContainer>
                  <IconButton
                    aria-label="show emoji list"
                    disabled={disableInput}
                    onClick={handleShowEmoji}
                    size="large"
                    color="secondary"
                  >
                    <SvgIcon color="secondary">
                      <EmojiIcon isActive={showEmoji} />
                    </SvgIcon>
                  </IconButton>

                  <IconButton
                    aria-label="send"
                    onClick={handleSubmit}
                    disabled={sendDisabled}
                    color="secondary"
                    size="large"
                    data-testid="chat-message-send-btn"
                  >
                    <SvgIcon>
                      <SendIcon />
                    </SvgIcon>
                  </IconButton>
                </InputButtonContainer>
              </InputAdornment>
            ),
          }}
          maxRows={4}
        />
      </Box>
    </ChatInputContainer>
  );
};
