import { useMemo } from "react";
import { useLocation } from "react-router-dom";

type RouteMap = Record<string, string | ((...args: any) => string)>;

/**
 * The currently known routes
 */
export const Route = {
  EVENT: "/event",
  NETWORKING_HUB: "/networkingHub",
  COMPATIBILITY_CHECKER: "/compatibility-checker",
} as const satisfies RouteMap;

// NOTE: All added routes must use as const to get proper type inference
export const EventRoutes = {
  root: Route.EVENT,
  event: (eventId: string) => `${Route.EVENT}/${eventId}` as const,
  watch: (eventId: string) => `${Route.EVENT}/${eventId}/watch` as const,
  greenroom: (eventId: string) =>
    `${Route.EVENT}/${eventId}/greenroom` as const,
} as const satisfies RouteMap;

export const NetworkingHubRoutes = {
  root: Route.NETWORKING_HUB,
  networkingHub: (networkingHubId: string) =>
    `${Route.NETWORKING_HUB}/${networkingHubId}` as const,
} as const satisfies RouteMap;

export const BaseRoutes = {
  home: (uid: string, isEvent = true) =>
    isEvent ? EventRoutes.event(uid) : NetworkingHubRoutes.networkingHub(uid),
} as const satisfies RouteMap;

export const RegistrationRoutes = {
  register: (uid: string, isEvent = true) =>
    isEvent
      ? (`${EventRoutes.event(uid)}/registration` as const)
      : (`${NetworkingHubRoutes.networkingHub(uid)}/registration` as const),
  success: (uid: string, isEvent = true) =>
    isEvent
      ? (`${EventRoutes.event(uid)}/registration/success` as const)
      : (`${NetworkingHubRoutes.networkingHub(
          uid,
        )}/registration/success` as const),
  alreadyRegistered: (uid: string, isEvent = true) =>
    isEvent
      ? (`${EventRoutes.event(uid)}/registration/registered` as const)
      : (`${NetworkingHubRoutes.networkingHub(
          uid,
        )}/registration/registered` as const),
  enterJoinCode: (uid: string, isEvent = true) =>
    isEvent
      ? (`${EventRoutes.event(uid)}/registration/enter-join-code` as const)
      : (`${NetworkingHubRoutes.networkingHub(
          uid,
        )}/registration/enter-join-code` as const),
  eventNotStarted: (uid: string) =>
    `${EventRoutes.event(uid)}/registration/not-started` as const,
} as const satisfies RouteMap;

export const Routes = {
  root: Route,
  base: BaseRoutes,
  event: EventRoutes,
  networkingHub: NetworkingHubRoutes,
  registration: RegistrationRoutes,
} as const satisfies Record<string, RouteMap>;

export type ValidRoute = {
  // for each key in the Routes object above 👆
  [K in keyof typeof Routes]: {
    // for each nested key in Routes[key]
    // if the type is a function, get the return type otherwise return the string type
    [NestedKey in keyof (typeof Routes)[K]]: (typeof Routes)[K][NestedKey] extends (
      ...args: any[]
    ) => any
      ? ReturnType<(typeof Routes)[K][NestedKey]>
      : (typeof Routes)[K][NestedKey];
  }[keyof (typeof Routes)[K]];
}[keyof typeof Routes];

/**
 * Simple hook which checks if the current route is the provided known route
 */
export function useIsRoute(route: ValidRoute) {
  const { pathname } = useLocation();
  return useMemo(() => pathname.includes(route), [pathname, route]);
}
