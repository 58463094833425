import { DocumentNode, GraphQLError } from "graphql";
import { API as AmplifyApi, graphqlOperation } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { getToken, GraphQlHeaders } from "./auth";
import { getFirstValue } from "./helpers";

export interface QueryArgs {
  /**
   * The GQL Query
   */
  query: string | DocumentNode;
  /**
   * The GQL Query variables
   */
  variables?: Record<string, any>;
  headers?: GraphQlHeaders;
}

export interface QueryResponse<T = unknown> {
  data?: T;
  errors?: GraphQLError[] | null;
}

/**
 * Method that makes a Graph QL query request
 *
 * @throws an object in the shape of `QueryResponse` when the request encounters an error
 *
 * _NOTE_: This function actually throws an object for errors and does not return them.
 * The thrown error will have the exact shape as `QueryResponse`
 *
 * Example:
 * ```ts
 * try {
 *   const res = query(args);
 *   // if we're here this means that the request was successful
 *   const {
 *     data, // the shape of the data we expect
 *     errors // `null`
 *   } = res;
 * }
 * catch (err) {
 *   // if we're here this means that the request failed
 *   // this function doesn't throw a normal JS error and throws the result object instead
 *   const {
 *     data, // either `null` or `{}`
 *     errors // an array of `GraphQLError`s
 *   } = err; // notice that we're destructuring the error object
 * }
 * ```
 */
export const query = async <T>(args: QueryArgs) => {
  const { query, variables, headers } = args;
  const token = getToken();

  const result = (await AmplifyApi.graphql<GraphQLQuery<T>>(
    // NOTE: we don't need to use graphqlOperation but TypeScript doesn't
    // properly resolve DocumentNode if we don't use it
    graphqlOperation(query, variables, token as string),
    headers as Record<string, string>,
  )) as QueryResponse<T>;

  const errors = result.errors ?? null;

  const data = getFirstValue<T>(result.data);

  return {
    data,
    errors,
  };
};
