import React, { memo } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grow from "@mui/material/Grow";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import { UpVoteFilledIcon, UpVoteOutlinedIcon } from "../icons";
import ExpandIcon from "@mui/icons-material/ExpandMore";
import CollapseIcon from "@mui/icons-material/ExpandLess";
import {
  QuestionApprovedStatus,
  UserQuestion,
} from "../../providers/qAndA/QandAProvider";
import TimeAgo from "../TimeAgo";
import Menu, { MenuItemType } from "../Menu";
import { ParticipantNameOption } from "../../contracts/enums/participant-name-options";
import { User, UserRole } from "../../contracts/user/user";
import { useNetworkingHub } from "../../providers/NetworkingHubProvider";
import { useCircle } from "../Presence/IntrovokePresence";
import UserAvatar from "../UserAvatar";

const Container = styled("div")(({ theme: { spacing, breakpoints } }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  alignItems: "end",
  minHeight: 0,
  transition: "all 150ms ease-in-out",
  "& .container-hover": {
    boxShadow: "0px 2px 10px -2px rgb(0 0 0 / 8%)",
  },
  "&:hover .container-hover": {
    boxShadow: "0px 2px 10px -2px rgb(0 0 0 / 10%)",
  },
  paddingTop: spacing(0.5),
  paddingBottom: spacing(2.5),
  paddingLeft: spacing(1.5),
  paddingRight: spacing(1.5),
  [breakpoints.down("sm")]: {
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
  },
}));

const QuestionContainer = styled("div")(
  ({ theme: { spacing, breakpoints, palette } }) => ({
    zIndex: 2,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    backgroundColor: palette.background.paper,
    padding: spacing(1.5),
    [breakpoints.down("sm")]: {
      padding: spacing(1),
    },
    borderRadius: 10,
  }),
);

const Header = styled("div")({
  display: "flex",
  alignItems: "center",
});

const AvatarStyled = styled(UserAvatar)(
  ({ theme: { spacing, breakpoints } }) => ({
    width: 34,
    height: 34,
    marginRight: spacing(1.5),
    [breakpoints.down("sm")]: {
      marginRight: spacing(1),
    },
  }),
);

const AskedByText = styled(Typography)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  gap: spacing(0.5),
  width: "100%",
  overflow: "hidden",
}));

const AskedByName = styled(Typography)({
  display: "inline",
  fontWeight: 600,
});

const AskedByTime = styled("span")(({ theme: { typography, palette } }) => ({
  color: palette.text.secondary,
  fontSize: typography.caption.fontSize,
}));

const GrowStyled = styled(Grow)(({ theme: { spacing } }) => ({
  position: "absolute",
  right: spacing(1),
}));

const VotesLabel = styled("div")(({ theme: { typography, spacing } }) => ({
  paddingBottom: 2,
  display: "flex",
  alignItems: "baseline",
  gap: spacing(1),
  fontSize: typography.caption.fontSize,
  fontWeight: 600,
}));

const AnswerContainer = styled("div")(
  ({ theme: { palette, spacing, breakpoints } }) => ({
    zIndex: 1,
    width: "95%",
    marginLeft: "auto",
    padding: spacing(1.5),
    paddingTop: spacing(2.5),
    borderRadius: 10,
    borderTop: "none !important",
    backgroundColor: palette.background.default,
    [breakpoints.down("sm")]: {
      padding: spacing(1),
      paddingTop: spacing(2),
    },
  }),
);

const AskedByLabel = memo(
  ({ timestamp, username }: { timestamp: number; username: string }) => (
    <AskedByText>
      <AskedByName noWrap>{username}</AskedByName>
      <AskedByTime>
        <TimeAgo date={timestamp} />
      </AskedByTime>
    </AskedByText>
  ),
);

interface QuestionListItemProps {
  id: number | string;
  user: User;
  votes: number;
  onVote?: () => void;
  question: UserQuestion;
  showParticipantNames?: ParticipantNameOption;
  menuItems?: MenuItemType[];
}

const QuestionListItem: React.FC<QuestionListItemProps> = ({
  votes,
  onVote,
  question,
  showParticipantNames = ParticipantNameOption.ALL,
  menuItems = [],
  user: currentUser,
}) => {
  const [hovered, setHovered] = React.useState(false);
  const [showAnswer, setShowAnswer] = React.useState(false);

  const circle = useCircle();
  const { data: networkingHub } = useNetworkingHub();
  const currentCircle = networkingHub?.networkingHubRooms.find(
    ({ id }) => id === circle?.id,
  );
  const isCurrentUserCircleOwner = currentCircle?.ownerEmail?.includes(
    currentUser.email,
  );

  const hideUserInfo =
    showParticipantNames === ParticipantNameOption.NONE ||
    // NOTE: Only check local user so it isn't confusing for regular attendees that owners and hosts have their names displayed
    (showParticipantNames === ParticipantNameOption.HOSTS_AND_OWNERS &&
      currentUser.userRole !== UserRole.Organizer &&
      !isCurrentUserCircleOwner);

  const triggerHover = (isHovered: boolean) => {
    if (!question.isPending) {
      setHovered(isHovered);
    }
  };

  return (
    <Container
      onMouseOver={() => triggerHover(true)}
      onMouseLeave={() => triggerHover(false)}
      sx={{
        opacity: question.isPending ? 0.5 : 1,
        "& *": {
          pointerEvents: question.isPending ? "none" : "auto",
          userSelect: question.isPending ? "none" : "auto",
        },
      }}
    >
      <QuestionContainer
        sx={({ palette }) => ({
          border: `1px solid ${
            question.answered ? palette.base[200] : palette.base[100]
          }`,
        })}
        className="container-hover"
      >
        {/* header */}
        <Header>
          <AvatarStyled
            isAnonymous={hideUserInfo}
            userId={question.owner.userId}
            image={question.owner.avatar}
            username={question.owner.username}
            size={68}
          />

          {/* Username and Time ago */}
          <AskedByLabel
            timestamp={question.createdAt}
            username={hideUserInfo ? "Anonymous" : question.owner.username}
          />

          {/* menu items */}
          {!!menuItems?.length && (
            <GrowStyled in={hovered}>
              <div>
                <Menu menuItems={menuItems} />
              </div>
            </GrowStyled>
          )}
        </Header>

        {/* question */}
        <Box
          sx={{
            marginTop: 1.5,
            marginBottom: 1.5,
          }}
        >
          <Typography variant="body1">{question.name}</Typography>
        </Box>

        {/* votes */}
        {question.approved === QuestionApprovedStatus.APPROVED && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                gap: 0.5,
              }}
            >
              {/* icon */}
              {!question.answered && (
                <IconButton
                  sx={({ palette }) => ({
                    color: question.voted
                      ? palette.primary.main
                      : palette.action.active,
                  })}
                  aria-label="vote for question"
                  disabled={!onVote}
                  onClick={onVote}
                  size="small"
                  edge="start"
                >
                  <SvgIcon sx={{ fontSize: "16px" }}>
                    {question.voted ? (
                      <UpVoteFilledIcon />
                    ) : (
                      <UpVoteOutlinedIcon />
                    )}
                  </SvgIcon>
                </IconButton>
              )}

              {/* count */}
              <VotesLabel>
                <Typography sx={{ lineHeight: "unset" }} variant="h4">
                  {votes || 0}
                </Typography>
                <Typography alignSelf={"center"}>{` Vote${
                  votes !== 1 ? "s" : ""
                }`}</Typography>
              </VotesLabel>
            </Box>

            {question.answer && (
              <Button
                sx={{
                  "&.MuiButton-root": {
                    textTransform: "none",
                    minWidth: 120,
                    paddingBottom: 0,
                  },
                }}
                endIcon={showAnswer ? <CollapseIcon /> : <ExpandIcon />}
                onClick={() => setShowAnswer(!showAnswer)}
              >
                {showAnswer ? "Hide answer" : "Show answer"}
              </Button>
            )}
          </Box>
        )}
      </QuestionContainer>

      {/* answer */}
      {question?.answer && (
        <Collapse
          in={showAnswer}
          sx={{
            transform: "translateY(-12px)",
            width: "100%",
          }}
        >
          <AnswerContainer
            sx={({ palette }) => ({
              border: `1px solid ${
                question.answered ? palette.base[200] : palette.base[100]
              }`,
            })}
            className={`container-hover`}
          >
            <Typography color="textSecondary" variant="body2">
              {question.answer}
            </Typography>
          </AnswerContainer>
        </Collapse>
      )}
    </Container>
  );
};

export default memo(QuestionListItem);
