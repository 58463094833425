import React, { useState } from "react";
import { Box, BoxProps, IconButton, Tooltip } from "@mui/material";
import { LiveReaction } from "@src/contracts/customization/theme";
import MoodOutlined from "@mui/icons-material/MoodOutlined";

import { Container, ReactionImage } from "./LiveReactionsToolbar.styles";

interface Props extends Pick<BoxProps, "sx" | "style" | "className"> {
  liveReactions: LiveReaction[];
  onPublishReaction: (reaction: string) => void;
}

function LiveReactionImage({ src, alt }: { src: string; alt?: string }) {
  const [error, setError] = useState(false);

  if (error) {
    return (
      <MoodOutlined
        className="sequel-reaction-image"
        data-testid="live-reaction-toolbar-image-fallback"
      />
    );
  }

  return (
    <ReactionImage
      className="sequel-reaction-image"
      data-testid="live-reaction-toolbar-image"
      src={src}
      alt={alt}
      onError={() => setError(true)}
    />
  );
}

function LiveReactionsToolbar({
  liveReactions,
  onPublishReaction,
  ...props
}: Props) {
  if (!liveReactions.length) return null;

  return (
    <Box
      {...props}
      data-testid="live-reactions-toolbar"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Container>
        {liveReactions?.map((r) => (
          <IconButton
            sx={{ position: "relative", width: 36, height: 36 }}
            data-testid={`live-reactions-${r.reaction}-btn`}
            onClick={() => onPublishReaction(r.reaction)}
            key={r.reaction}
            title={r.label}
          >
            <Tooltip disableInteractive title={r.label ?? r.reaction}>
              <span>
                <LiveReactionImage src={r.image} alt={r.label ?? r.reaction} />
              </span>
            </Tooltip>
          </IconButton>
        ))}
      </Container>
    </Box>
  );
}

export default React.memo(LiveReactionsToolbar);
