import throttle from "lodash/throttle";
import { RefCallback, useCallback, useEffect, useRef, useState } from "react";
import { useLatest } from "react-use";
import ResizeObserver from "resize-observer-polyfill";

interface UseAutoScrollArgs {
  /**
   * The direction to scroll, defaults to `vertical`
   */
  direction?: "vertical" | "horizontal";
  /**
   * The distance in pixels from the bottom of the list used to
   * determine when auto scrolling is active, defaults to `50`
   */
  threshold?: number;
}

interface ScrollFunctionArgs {
  /**
   * Set to `true` to force scrolling and ignore threshold, defaults to `false`
   */
  force?: boolean;
  /**
   * The scrolling behavior, defaults to `auto`
   */
  behavior?: ScrollBehavior;
}

interface UseAutoScrollReturn<TElement extends HTMLElement> {
  /**
   * The ref that should be added to the element which will be auto-scrolled
   */
  ref: RefCallback<TElement>;
  /**
   * Scrolls to the start of the list
   */
  scrollToStart: (args?: ScrollFunctionArgs) => void;
  /**
   * Scrolls to the end of the list
   */
  scrollToEnd: (args?: ScrollFunctionArgs) => void;
  /**
   * Flag indicating whether auto-scrolling is currently active or not.
   *
   * Auto-scrolling is not active when the list is scrolled outside of the threshold.
   */
  isActive: boolean;
}

const isInThreshold = (element: HTMLElement, threshold: number) =>
  Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) <=
  threshold;

const scrollTo = (
  element: HTMLElement | null | undefined,
  location: "start" | "end",
  direction: "vertical" | "horizontal",
  scrollBehavior: ScrollBehavior,
) => {
  if (location === "start") {
    element?.scrollTo({
      top: direction === "vertical" ? 0 : undefined,
      left: direction === "horizontal" ? 0 : undefined,
      behavior: scrollBehavior,
    });
  } else {
    element?.scrollTo({
      top: direction === "vertical" ? element.scrollHeight : undefined,
      left: direction === "horizontal" ? element.scrollWidth : undefined,
      behavior: scrollBehavior,
    });
  }
};

/**
 * Allows for auto-scrolling lists when the children change.
 *
 * When the user scrolls the list outside of the threshold
 * bounds, the auto-scrolling feature will be disabled until the list is scrolled
 * back to within the threshold.
 *
 * Example usage:
 *
 * ```tsx
 * const MyComponent = ({ children }) => {
 *   const messages = useChatMessages("channel-1");
 *   const { ref, isActive } = useAutoScroll(children);
 *
 *   useEffect(() => {
 *     console.log("Auto-scrolling is active?", isActive);
 *   }, [isActive]);
 *
 *   return (
 *     <div ref={ref} style={{ width: 50, height: 100, overflow: "auto" }}>
 *       {messages.map(({ id, text }) => <div key={id}>{text}</div>)}
 *     </div>
 *   );
 * }
 * ```
 */
export function useAutoScroll<TElement extends HTMLElement = HTMLElement>(
  children: any,
  { direction = "vertical", threshold = 50 }: UseAutoScrollArgs = {},
): UseAutoScrollReturn<TElement> {
  const [element, ref] = useState<TElement | null>(null);
  const canScrollRef = useRef(true);
  const [isActive, setIsActive] = useState(true);

  const scrollToStart = useCallback(
    ({ behavior = "auto" }: ScrollFunctionArgs = {}) => {
      scrollTo(element, "start", direction, behavior);
    },
    [element, direction],
  );

  const scrollToEnd = useCallback(
    ({ behavior = "auto" }: ScrollFunctionArgs = {}) => {
      scrollTo(element, "end", direction, behavior);
    },
    [element, direction],
  );

  const latestScrollEnd = useLatest(scrollToEnd);
  const autoScroll = useCallback(() => {
    canScrollRef.current && latestScrollEnd.current();
  }, [latestScrollEnd]);

  useEffect(() => {
    if (!element) return;
    const onScroll = throttle(
      () => {
        const enabled = isInThreshold(element, threshold);
        if (enabled !== canScrollRef.current) {
          canScrollRef.current = enabled;
          setIsActive(enabled);
        }
      },
      100,
      { leading: true, trailing: true },
    );
    element.addEventListener("scroll", onScroll, { passive: true });
    return () => {
      element.removeEventListener("scroll", onScroll);
    };
  }, [element, threshold]);

  // On any child updates trigger scroll to end
  useEffect(() => {
    autoScroll();
  }, [children, autoScroll]);

  // Add a resize observer to scroll on element resizes
  useEffect(() => {
    if (!element) return;
    const resizeObserver = new ResizeObserver(autoScroll);
    resizeObserver.observe(element);
    return () => {
      resizeObserver.disconnect();
    };
  }, [element, autoScroll]);

  return {
    ref: ref as RefCallback<TElement>,
    scrollToStart,
    scrollToEnd,
    isActive,
  };
}

export default useAutoScroll;
