import { FormikErrors, useFormik } from "formik";
import { useMutation, useQueryClient } from "react-query";

import { EventApi } from "../../api/event-api";
import { QueryKeys } from "../../api/QueryKeys";
import { ReplayConfig } from "../../contracts/event/event";
import { ButtonTabs } from "./common";

export type ReplayFormConfig = {
  showCountdown: boolean;
  activeTab: ButtonTabs;
} & ReplayConfig;

export const useEditReplayFormController = (
  eventId: string,
  initialValues: ReplayFormConfig,
  onSuccess?: () => void,
) => {
  const queryClient = useQueryClient();

  const { mutateAsync, mutate, ...query } = useMutation(
    async ({
      replayEnabled,
      replayUrl,
      showCountdown,
      streamPlaceholderMessage,
    }: ReplayFormConfig) => {
      const toSave: ReplayConfig = {
        replayEnabled,
        replayUrl,
        streamPlaceholderMessage: showCountdown ? "" : streamPlaceholderMessage,
      };
      return EventApi.UpdateReplay(eventId, toSave);
    },
    {
      onSuccess,
      onSettled: () => {
        queryClient.invalidateQueries(QueryKeys.event(eventId));
        queryClient.invalidateQueries(QueryKeys.eventStatus(eventId));
        queryClient.invalidateQueries(QueryKeys.replays(eventId));
      },
    },
  );

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validateOnMount: true,
    validate: (values) => {
      const errors: FormikErrors<ReplayFormConfig> = {};
      const { replayUrl, streamPlaceholderMessage, activeTab } = values;

      if (activeTab === ButtonTabs.SelectReplay && !replayUrl) {
        errors.replayUrl = "No replay selected";
      }

      if (
        activeTab === ButtonTabs.ShowCustomText &&
        !streamPlaceholderMessage
      ) {
        errors.streamPlaceholderMessage = "Placeholder message cannot be empty";
      }

      return errors;
    },
    onSubmit: async (values, actions) => {
      await mutateAsync(values).finally(() => {
        actions.setSubmitting(false);
      });
    },
  });

  return { form, query };
};
