import { Box, styled } from "@mui/material";

export const StyledVideContainer = styled(Box)(() => ({
  flex: 1,
  position: "relative",
}));

export const StyledVideo = styled("video", {
  skipSx: true,
  skipVariantsResolver: true,
})(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  height: "100%",
  overflow: "hidden",
}));
