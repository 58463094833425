import React, { memo } from "react";
import { useField } from "formik";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";

import { useEventReplaysContext } from "../../providers/EventReplaysProvider";
import ReplayListItem from "./ReplayListItem";
import VideoPlayer from "../VideoPlayer/VideoPlayer";

export const LoadingMessage = memo(() => {
  return (
    <Typography
      display="flex"
      flex="1"
      textAlign="center"
      alignItems="center"
      justifyContent="center"
      mb={1}
    >
      <CircularProgress sx={{ mr: 1 }} size="1em" />
      <Box>Loading available replays...</Box>
    </Typography>
  );
});
const VideoPreview = memo(() => {
  const [selectedReplay] = useField<string>("replayUrl");
  const currentVodPath = selectedReplay.value;

  // TODO: This may need a trigger to re-render the video, not sure though...

  return (
    <VideoPlayer
      autoplay
      muted={false}
      controls
      src={currentVodPath}
      loop
      videoJsOptions={{ fluid: true }}
    />
  );
});

const ReplaysPreviewList = () => {
  const { loadingReplays, replayUrls } = useEventReplaysContext();
  const [selectedReplay] = useField<string>("replayUrl");

  return (
    <Grid container spacing={3}>
      <Grid item sm={6} md={4} sx={{ maxHeight: "300px", overflowY: "auto" }}>
        {/* Show loading message even if we have replays so we indicate 
            we are still searching for latest replay when needed        */}
        {loadingReplays && <LoadingMessage />}
        {replayUrls.map((urlInfo, index) => (
          <ReplayListItem key={urlInfo.url} index={index} urlInfo={urlInfo} />
        ))}
      </Grid>
      <Grid item sm={6} md={8}>
        <Box style={{ display: "flex", height: "100%" }}>
          {typeof selectedReplay.value === "string" ? (
            <VideoPreview />
          ) : (
            <Typography>Select a replay video to show viewers.</Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default memo(ReplaysPreviewList);
