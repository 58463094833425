import { useCallback } from "react";
import { SubmitHandler } from "react-hook-form";
import { z } from "zod";

import { toast } from "react-toastify";
import {
  useFormWithPersistentValidation,
  type UseFormWithPersistentValidationReturn,
} from "@src/hooks/useFormWithPersistentValidation";
import { useValidateJoinCode } from "@src/providers/registration";
import { useJoinEvent } from "./useJoinEvent";

export const JoinCodeSchema = z
  .string()
  .nonempty("Join code is required")
  .length(12, "Join code must be 12 characters");

const JoinCodeFormSchema = z.object({
  joinCode: JoinCodeSchema,
});

export type JoinCodeFormValues = z.infer<typeof JoinCodeFormSchema>;

export const useJoinCodeForm = () => {
  const form = useFormWithPersistentValidation<JoinCodeFormValues>(
    { schema: JoinCodeFormSchema },
    {
      defaultValues: {
        joinCode: "",
      },
    },
  );

  const onSubmit = useSubmitHandler(form);

  // do not memoize this since form is mutable and has a stable reference
  return {
    ...form,
    onSubmit,
  };
};

const useSubmitHandler = (
  form: UseFormWithPersistentValidationReturn<{ joinCode: string }>,
) => {
  const { clearPersistentErrors, setPersistentError, reset, handleSubmit } =
    form;

  const { handleJoinEvent } = useJoinEvent();

  const { mutateAsync: validateJoinCode } = useValidateJoinCode({
    onError: () => {
      toast.error(
        "An error occurred while validating your Join Code. Please try again.",
      );
    },
  });

  const submitHandler = useCallback<SubmitHandler<JoinCodeFormValues>>(
    async (values) => {
      try {
        clearPersistentErrors();

        const result = await validateJoinCode(values);

        if (!result) {
          return setPersistentError("joinCode", {
            message: "Invalid Join Code",
            value: values.joinCode,
          });
        }

        handleJoinEvent(values.joinCode);
      } catch (err) {
        console.error("JoinCode validation error:", values, err);
      } finally {
        // reset defaults to current form
        reset(values);
      }
    },
    [
      clearPersistentErrors,
      handleJoinEvent,
      reset,
      setPersistentError,
      validateJoinCode,
    ],
  );

  return useCallback<React.FormEventHandler<HTMLFormElement>>(
    (e) => handleSubmit(submitHandler)(e),
    [handleSubmit, submitHandler],
  );
};
