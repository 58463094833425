import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
} from "@mui/material";

const DeleteButton = styled(Button)(({ theme: { palette } }) => ({
  backgroundColor: palette.error.main,
  color: palette.error.contrastText,
  "&:hover": {
    backgroundColor: palette.error.dark,
  },
}));

export interface QuestionDeleteDialogProps {
  show: boolean;
  questionId: string;
  handleClose?: () => void;
  onDelete?: (questionId: string) => void;
}

export const QuestionDeleteDialog = React.memo(
  ({ show, questionId, onDelete, handleClose }: QuestionDeleteDialogProps) => (
    <Dialog
      open={show}
      aria-labelledby="delete-question-dialog-title"
      aria-describedby="delete-question-dialog-description"
      data-testid="delete-question-dialog"
      onClose={handleClose}
    >
      <DialogTitle id="delete-question-dialog-title">
        Delete Question
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="delete-question-dialog-description"
          variant="body2"
          color="textSecondary"
        >
          Are you sure you want to permanently delete this question?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <DeleteButton
          onClick={() => {
            handleClose?.();
            if (questionId) onDelete?.(questionId);
          }}
          variant="contained"
        >
          Delete
        </DeleteButton>
      </DialogActions>
    </Dialog>
  ),
);
