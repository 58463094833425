import axios from "axios";
import { Theme } from "../contracts/customization/theme";

import {
  CanJoinEventResponse,
  Event,
  EventStatus,
  Host,
  RegistrationQuestionAnswer,
  ReplayConfig,
} from "../contracts/event/event";
import { ReplayUrl } from "../contracts/replay/replay-url";
import {
  UserRole,
  RegisteredAttendeeUserResponse,
} from "../contracts/user/user";
import { ApiConfig } from "./api-config";
const EVENT_V3_API = "v3/events";
const DEFAULT_TIMEOUT = 5000;

const GetEvent = async (eventUid: string) => {
  const configURL = `${ApiConfig.GetPrefix()}/api/${EVENT_V3_API}/${eventUid}`;
  const response = await axios.get(configURL, ApiConfig.GetHeaderWithToken());
  const data: Event = response.data;
  return data;
};

const GetConfigUrl = async (
  eventUid?: string,
  networkingHubId?: string,
): Promise<string> => {
  const configURL = `${ApiConfig.GetPrefix()}/api/${EVENT_V3_API}/config/url`;
  const response = await axios.post(
    configURL,
    { eventUid, networkingHubId },
    ApiConfig.GetHeaderWithToken(),
  );
  const data: string = response.data;
  return data;
};

const GetVttUrl = async (m3u8Url: string): Promise<string> => {
  const configURL = `${ApiConfig.GetPrefix()}/api/client/getCCForM3u8`;
  const response = await axios.put(configURL, { m3u8Url });
  const data = response.data?.success?.vttUrl;
  return data;
};

const AddUpdateHost = async (eventUid: string, host: Host): Promise<Event> => {
  const configURL =
    ApiConfig.GetPrefix() + "/api/v2event/" + eventUid + "/addHost";
  const response = await axios.post(
    configURL,
    host,
    ApiConfig.GetHeaderWithToken(),
  );
  const data: Event = response.data;
  return data;
};

const StartNetworking = async (eventUid: string): Promise<boolean> => {
  const configURL =
    ApiConfig.GetPrefix() + "/api/v2event/" + eventUid + "/startNetworking";
  const response = await axios.post(
    configURL,
    {},
    ApiConfig.GetHeaderWithToken(),
  );
  const data: boolean = response.data;
  return data;
};

/**
 * Stop ongoing networking
 * @param eventUid Event Uid to stop networking on
 * @param returnToEvent Whether or not to send attendees back to original event stage
 * @returns boolean for api database update success
 */
const StopNetworking = async (
  eventUid: string,
  returnToEvent: boolean = false,
): Promise<boolean> => {
  const configURL =
    ApiConfig.GetPrefix() + "/api/v2event/" + eventUid + "/stopNetworking";
  const response = await axios.post(
    configURL,
    { returnToEvent },
    ApiConfig.GetHeaderWithToken(),
  );
  const data: boolean = response.data;
  return data;
};

const StartStreamLegacy = async (eventUid: string): Promise<boolean> => {
  const configURL =
    ApiConfig.GetPrefix() + "/api/v2event/" + eventUid + "/startStream";
  const response = await axios.post(
    configURL,
    {},
    {
      timeout: DEFAULT_TIMEOUT,
      ...ApiConfig.GetHeaderWithToken(),
    },
  );
  const data: boolean = response.data;
  return data;
};

const StopStreamLegacy = async (eventUid: string): Promise<boolean> => {
  const configURL =
    ApiConfig.GetPrefix() + "/api/v2event/" + eventUid + "/stopStream";
  const response = await axios.post(
    configURL,
    {},
    {
      timeout: DEFAULT_TIMEOUT,
      ...ApiConfig.GetHeaderWithToken(),
    },
  );
  const data: boolean = response.data;
  return data;
};

const StartStream = async (eventId: string) => {
  const configURL =
    ApiConfig.GetPrefix() + "/api/v3/events/" + eventId + "/startStream";
  const response = await axios.post(
    configURL,
    {},
    {
      timeout: DEFAULT_TIMEOUT,
      ...ApiConfig.GetHeaderWithToken(),
    },
  );
  const data: {
    isStreaming: boolean;
  } = response.data;
  return data;
};

const StopStream = async (eventId: string) => {
  const configURL =
    ApiConfig.GetPrefix() + "/api/v3/events/" + eventId + "/stopStream";
  const response = await axios.post(
    configURL,
    {},
    {
      timeout: DEFAULT_TIMEOUT,
      ...ApiConfig.GetHeaderWithToken(),
    },
  );
  const data: {
    isStreaming: boolean;
  } = response.data;
  return data;
};

const StreamStatus = async (eventId: string) => {
  const configURL =
    ApiConfig.GetPrefix() + "/api/v3/events/" + eventId + "/streamStatus";
  const response = await axios.get(configURL, {
    timeout: DEFAULT_TIMEOUT,
    ...ApiConfig.GetHeaderWithToken(),
    params: {
      _t: Date.now(),
    },
  });
  const data: {
    isStreaming: boolean;
  } = response.data;
  return data;
};

export interface RegistrantNew {
  name: string;
  email: string;
  customQuestionsAnswers?: RegistrationQuestionAnswer[];
  formUrl?: string;
}
const RegisterForEvent = async (
  eventUid: string,
  registrant: RegistrantNew,
) => {
  const configURL = `${ApiConfig.GetPrefix()}/api/${EVENT_V3_API}/${eventUid}/registrant`;

  const response = await axios.post<RegisteredAttendeeUserResponse>(
    configURL,
    registrant,
    ApiConfig.GetHeaderWithToken(),
  );

  return response;
};

const VerifyPasscode = async (
  eventUid: string,
  passcode: string | number,
): Promise<number> => {
  const configURL = `${ApiConfig.GetPrefix()}/api/${EVENT_V3_API}/${eventUid}/verifyPasscode`;
  const response = await axios.post(
    configURL,
    { passcode: `${passcode}` },
    ApiConfig.GetHeaderWithToken(),
  );
  const data: Exclude<UserRole, UserRole.Unregistered> | 9 = response.data;
  return data;
};

const GetUserRole = async (
  eventUid: string,
  userEmail: string,
  userId: string,
): Promise<UserRole> => {
  const configURL =
    ApiConfig.GetPrefix() + "/api/v2event/" + eventUid + "/userRole";
  const payload = {
    email: userEmail,
    userId,
  };

  const response = await axios.post(
    configURL,
    payload,
    ApiConfig.GetHeaderWithToken(),
  );
  const data: UserRole = response.data;
  return data;
};

const GetEventStatus = async (eventId: string): Promise<EventStatus> => {
  const configURL = `${ApiConfig.GetPrefix()}/api/v2event/${eventId}/status`;

  const response = await axios.get(configURL, ApiConfig.GetHeaderWithToken());
  return response.data;
};

const UpdateReplay = async (
  eventUid: string,
  replayConfig: ReplayConfig,
): Promise<boolean> => {
  const configURL =
    ApiConfig.GetPrefix() + "/api/v3/events/" + eventUid + "/updateReplay";
  const response = await axios.post(
    configURL,
    replayConfig,
    ApiConfig.GetHeaderWithToken(),
  );
  const data: boolean = response.data;
  return data;
};

/**
 *  Gets available replay info
 *  Includes custom replay uploads and recordings from live broadcasts
 * @param eventId : string
 * @returns ReplayUrl[]
 */
export const getReplayOptions = async (
  eventId: string,
): Promise<ReplayUrl[]> => {
  // Get presigned URL for direct upload to S3 from users browser
  const configURL =
    ApiConfig.GetPrefix() + "/api/v3/events/" + eventId + "/availableReplays";
  const signedUrlResponse = await axios.get(
    configURL,
    await ApiConfig.GetHeaderWithToken(),
  );
  const replays: ReplayUrl[] = signedUrlResponse.data;

  return replays;
};

export const BlockEmails = async (
  eventId: string,
  emails: string[],
): Promise<EventStatus> => {
  const configURL =
    ApiConfig.GetPrefix() + "/api/v3/events/" + eventId + "/blockedEmails";
  const response = await axios.post(
    configURL,
    { emails },
    ApiConfig.GetHeaderWithToken(),
  );
  const data: EventStatus = response.data;
  return data;
};

export const UnblockEmails = async (
  eventId: string,
  emails: string[],
): Promise<EventStatus> => {
  const configURL =
    ApiConfig.GetPrefix() + "/api/v3/events/" + eventId + "/blockedEmails";
  const response = await axios.delete(configURL, {
    data: { emails },
    ...ApiConfig.GetHeaderWithToken(),
  });
  const data: EventStatus = response.data;
  return data;
};

export const EnableAnonymousQna = async (
  eventId: string,
  enabled?: boolean,
): Promise<EventStatus> => {
  const configURL = `${ApiConfig.GetPrefix()}/api/v3/events/${eventId}/enableAnonymousQna`;
  const response = await axios.patch(
    configURL,
    { enabled },
    ApiConfig.GetHeaderWithToken(),
  );
  const data: EventStatus = response.data;
  return data;
};

export const EnableApproveQna = async (
  eventId: string,
  enabled?: boolean,
): Promise<EventStatus> => {
  const configURL = `${ApiConfig.GetPrefix()}/api/v3/events/${eventId}/enableApproveQna`;
  const response = await axios.patch(
    configURL,
    { enabled },
    ApiConfig.GetHeaderWithToken(),
  );
  const data: EventStatus = response.data;
  return data;
};

export const EnableChatReactions = async (
  eventId: string,
  enabled: boolean,
): Promise<EventStatus> => {
  const configURL = `${ApiConfig.GetPrefix()}/api/v3/events/${eventId}/enableChatReactions`;
  const response = await axios.patch(
    configURL,
    { enabled },
    ApiConfig.GetHeaderWithToken(),
  );
  const data: EventStatus = response.data;
  return data;
};

export const EnableLiveReactions = async (
  eventId: string,
  enabled: boolean,
): Promise<EventStatus> => {
  const configURL = `${ApiConfig.GetPrefix()}/api/v3/events/${eventId}/enableLiveReactions`;
  const response = await axios.patch(
    configURL,
    { enabled },
    ApiConfig.GetHeaderWithToken(),
  );
  const data: EventStatus = response.data;
  return data;
};

export const ResendEmail = async (
  eventUid: string,
  email: string,
): Promise<any> => {
  const configURL = `${ApiConfig.GetPrefix()}/api/${EVENT_V3_API}/${eventUid}/registrant/resendEmail`;

  const body = {
    email,
  };
  const response = await axios.post(
    configURL,
    body,
    ApiConfig.GetHeaderWithToken(),
  );

  return response;
};

/**
 * Gets the event's theme if available. If not, returns the company default theme.
 *
 * Please note that themes are based on the root default theme with overrides applied.
 *
 * See the customization API for more information.
 */
export const GetTheme = async (eventId: string): Promise<Theme> => {
  const configURL = `${ApiConfig.GetPrefix()}/api/v3/events/${eventId}/theme`;
  const response = await axios.get(configURL, {
    ...ApiConfig.GetHeaderWithToken(),
    timeout: 3000, // 3 second timeout to avoid waiting too long
  });
  return response.data;
};

/**
 * Checks if the event has general access enabled
 *
 * Currently simply checks if the event has access codes enabled and the companyId
 * stored in the general access cookie matches the companyId of the event
 *
 * @param eventId The uid of the event
 * @param accessId Currently the companyId of the event (`event.organizerUid`)
 */
export const HasGeneralAccess = async (
  eventId: string,
  accessId: string,
): Promise<{
  hasGeneralAccess: boolean;
}> => {
  const configUrl = `${ApiConfig.GetPrefix()}/api/v3/events/${eventId}/hasGeneralAccess?accessId=${accessId}`;
  const response = await axios.get(configUrl, ApiConfig.GetHeaderWithToken());
  const data: { hasGeneralAccess: boolean } = response.data;
  return data;
};

export const GetUserJoinInformation = async (
  eventId: string,
  joinCode: string,
): Promise<RegisteredAttendeeUserResponse> => {
  const configUrl = `${ApiConfig.GetPrefix()}/api/v3/events/${eventId}/join/${joinCode}`;
  const response = await axios.get(configUrl, ApiConfig.GetHeaderWithToken());
  const data: RegisteredAttendeeUserResponse = response.data;
  return data;
};

/**
 * checks if the event's maxUser limit has been reached
 * @param asHost set to `true` if a user is host to also check if the maxHost limit has been reached
 */
export const CheckCanJoin = async (
  eventId: string,
  userId: string,
): Promise<CanJoinEventResponse> => {
  const configUrl = `${ApiConfig.GetPrefix()}/api/v3/events/${eventId}/checkCanJoin?userId=${userId}`;
  const response = await axios.get(configUrl, ApiConfig.GetHeaderWithToken());
  const data: CanJoinEventResponse = response.data;
  return data;
};

export const EventApi = {
  GetEvent,
  GetVttUrl,
  AddUpdateHost,
  HasGeneralAccess,
  StartNetworking,
  StopNetworking,
  StartStreamLegacy,
  StopStreamLegacy,
  StartStream,
  StopStream,
  StreamStatus,
  RegisterForEvent,
  GetUserRole,
  GetUserJoinInformation,
  GetEventStatus,
  UpdateReplay,
  VerifyPasscode,
  BlockEmails,
  UnblockEmails,
  EnableAnonymousQna,
  EnableApproveQna,
  EnableChatReactions,
  EnableLiveReactions,
  ResendEmail,
  GetTheme,
  CheckCanJoin,
  GetConfigUrl,
};
