import isEqual from "fast-deep-equal/es6";
import { DependencyList, useRef } from "react";

export function useDeepMemo<T>(factory: () => T, deps: DependencyList): T {
  const ref = useRef<
    | undefined
    | {
        value: T;
        deps: DependencyList;
      }
  >(undefined);
  if (!ref.current || !isEqual(ref.current.deps, deps)) {
    ref.current = {
      value: factory(),
      deps,
    };
  }
  return ref.current.value;
}

export default useDeepMemo;
