import { useCallback, useRef, useState } from "react";
import {
  useMountEffect,
  useResizeObserver,
  useSyncedRef,
} from "@react-hookz/web";
import throttle from "lodash/throttle";

const getSizes = <T extends HTMLElement>(
  target: T | null,
  offset: "Height" | "Width",
) => {
  const clientSizeSelector = `client${offset}` as const;
  const scrollSizeSelector = `scroll${offset}` as const;

  const clientSize = target?.[clientSizeSelector] || 0;
  const scrollSize = target?.[scrollSizeSelector] || 0;

  return { clientSize, scrollSize };
};

/**
 * Hook that checks if the passed element's width or height is truncated based on the window size.
 * i.e. if part of the element is either hidden (not scrollable) or not visible (scrollable) in its container.
 *
 * It ignores changes to the element's width height directly.
 *
 * TODO: needs more testing
 * TODO: @jeff move to common components repo
 */
export const useTruncated = <T extends HTMLElement>(
  ref: React.RefObject<T | null>,
  offset: "Height" | "Width",
  expandedByDefault = false,
) => {
  const [isExpanded, setIsExpanded] = useState(expandedByDefault);
  const [truncated, setTruncated] = useState(false);
  const latestExpanded = useRef(isExpanded);
  const prevSizes = useRef(getSizes(ref.current, offset));

  const handleResize = useSyncedRef(
    throttle(
      (target: T | null) => {
        if (!target) {
          setTruncated(false);
          setIsExpanded(false);
        }

        const expanded = latestExpanded.current;

        const { clientSize, scrollSize } = getSizes(target, offset);

        if (
          clientSize < scrollSize ||
          (clientSize === scrollSize && expanded)
        ) {
          setTruncated(true);
        } else {
          setTruncated(false);
        }

        prevSizes.current = { clientSize, scrollSize };
      },
      100,
      { leading: true, trailing: true },
    ),
  );

  const check = useCallback(
    () => handleResize.current(ref.current),
    [handleResize, ref],
  );

  // also run check on initial mount
  useMountEffect(check);

  // watch for resizes on the window, because expanding/collapsing could also trigger resize if watching element
  useResizeObserver(document.body, check);

  const setExpanded = useCallback((expanded: boolean) => {
    setIsExpanded(expanded);
    latestExpanded.current = expanded;
  }, []);

  return {
    truncated,
    expanded: isExpanded,
    setExpanded,
    check,
  };
};
