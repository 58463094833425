import React, { useEffect, useRef } from "react";
import { useCanJoin } from "@src/components/accessGuards";
import ConnectionErrors, {
  connectionErrorOpenOptions,
} from "@src/components/dialogs/content/ConnectionErrors";
import { UserRole } from "@src/contracts/user/user";
import { CanJoinEventResponse } from "@src/contracts/event/event";
import {
  validateEnvironment,
  VALIDATE_ENVIRONMENT_DEBOUNCE_MS,
} from "@src/helpers/validateEnvironment";
import { useDialog } from "../../DialogProvider";
import { ModalProviderProps } from "../../DialogProvider/DialogContext";
import { useDebounce } from "react-use";

interface CheckCanJoinArgs
  extends Pick<
    ReturnType<typeof useCanJoin>,
    "handleJoinAsHostBlocked" | "handleJoinAsAttendeeBlocked"
  > {
  eventId?: string;
  userRole: UserRole;
  canJoin?: CanJoinEventResponse;
}

/**
 * Factory function which returns an effect and dependency array
 *
 * The effect will check if the user is can join the event based on their role
 * and block them from joining if they are not allowed.
 *
 * It does not handle role demotions and leaves it up to the caller for better UX
 */
const createCheckCanJoinEffect = (
  args: CheckCanJoinArgs,
): [React.EffectCallback, React.DependencyList] => {
  return [
    () => {
      const {
        userRole,
        eventId,
        canJoin,
        handleJoinAsAttendeeBlocked,
        handleJoinAsHostBlocked,
      } = args;

      // if standalone hub => skip checking if user can join
      if (!eventId) return;

      // check can join based on role
      const canJoinAsHost = canJoin?.host?.canJoin;
      const canJoinAsAttendee = canJoin?.attendee?.canJoin;

      // if host
      if (userRole === UserRole.Organizer && canJoinAsHost === false) {
        return handleJoinAsHostBlocked(); // moved role downgrade to HostGuard for better UX
      }

      // if attendee
      if (userRole === UserRole.Viewer && canJoinAsAttendee === false) {
        return handleJoinAsAttendeeBlocked();
      }
    },
    Object.values(args),
  ];
};

interface createEnvironmentCheckDebounceArgs {
  lastRoleRef: React.MutableRefObject<UserRole>;
  currentRole: UserRole;
  openDialog: ModalProviderProps["openDialog"];
}

/**
 * Factory function which returns an debounced function, debounce delay and dependency array
 *
 * The debounced function will check the latest role of the user
 * and validate the user's environment if necessary and show a dialog if there are any errors
 */
const createEnvironmentCheckDebounce = (
  args: createEnvironmentCheckDebounceArgs,
): Parameters<typeof useDebounce> => {
  return [
    async () => {
      const { lastRoleRef, currentRole, openDialog } = args;

      if (lastRoleRef.current === currentRole) return;

      lastRoleRef.current = currentRole;

      const { errors, hasErrors } = await validateEnvironment(currentRole);
      if (hasErrors) {
        openDialog(
          "ConnectionErrors",
          <ConnectionErrors errors={errors} />,
          connectionErrorOpenOptions,
        );
      }
    },
    VALIDATE_ENVIRONMENT_DEBOUNCE_MS,
    Object.values(args),
  ];
};

interface UseRoleChecksArgs {
  eventId?: string;
  userRole: UserRole;
}

/**
 * This should ONLY be called once in the `UserRoleProvider`
 */
export const useRoleChecks = ({ eventId, userRole }: UseRoleChecksArgs) => {
  const { openDialog } = useDialog();
  const lastRoleRef = useRef<UserRole>(UserRole.Unregistered);

  const {
    checkCanJoin: { data: canJoin },
    handleJoinAsHostBlocked,
    handleJoinAsAttendeeBlocked,
  } = useCanJoin({
    eventId: eventId as string,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    // check if the user is allowed to be in the event with the role they have
    ...createCheckCanJoinEffect({
      eventId,
      canJoin,
      userRole,
      handleJoinAsAttendeeBlocked,
      handleJoinAsHostBlocked,
    }),
  );

  // validate user's environment based on role
  // use debounce since role changes quite a few times on initial load
  // keep track of last role to avoid retriggering for the same role
  useDebounce(
    ...createEnvironmentCheckDebounce({
      lastRoleRef,
      currentRole: userRole,
      openDialog,
    }),
  );
};

export const __testable__ = {
  createCheckCanJoinEffect,
  createEnvironmentCheckDebounce,
};
