import { useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";
import { createPollsQueryKey, orderPolls } from "../helpers";
import { Poll, PollActionHookOptions } from "../types";

/**
 * Callback to update the modified poll in the supplied local polls array
 */
const updatePoll = (data: Poll) => (prev?: Poll[] | null) => {
  const previousPolls = Array.isArray(prev) ? prev : [];
  return orderPolls(
    previousPolls.map((poll) => {
      if (poll.id !== data.id) {
        return poll;
      }

      const selectedVotes = new Set(
        "options" in poll
          ? poll.options.filter((opt) => opt.selected).map((opt) => opt.id)
          : [],
      );

      return {
        ...poll,
        ...data,
        isPending: false,
        options: data.options.map((option) => ({
          ...option,
          selected: selectedVotes.has(option.id),
        })),
      };
    }),
  );
};

export interface UseUpdateEventPollOptions extends PollActionHookOptions {}

export const useUpdateEventPoll = (options: UseUpdateEventPollOptions) => {
  const cache = useQueryClient();

  const handlePollUpdated = useCallback(
    (data: Poll) => {
      cache.setQueryData<Poll[]>(
        createPollsQueryKey(data.eventId, data.circleId),
        updatePoll(data),
      );
    },
    [cache],
  );

  return useMemo(() => ({ handlePollUpdated }), [handlePollUpdated]);
};
