import React, { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { z } from "zod";
import { canSubmitForm } from "@src/hooks/useFormWithPersistentValidation";
import { useEventOrNetworkingHub } from "@src/hooks/useEventOrNetworkingHub";
import { Routes } from "@src/hooks/useIsRoute";
import { getValidatedResult } from "@src/hooks/useValidatedResult";
import { useResendRegistrationEmail } from "@src/providers/registration";
import { JoinCodeFormValues, useJoinCodeForm } from "../hooks/useJoinCodeForm";

import { IconWrapper } from "@introvoke/react/components";
import PasscodeIcon from "@introvoke/react/icons/Passcode";
import ArrowLeftIcon from "@introvoke/react/icons/ArrowLeft";
import CheckIcon from "@introvoke/react/icons/Check";

import Button from "@src/components/buttons/Button";
import TextField from "@src/components/Forms/TextField";
import Form from "@src/components/Forms/Form";

import RegistrationSidebarView from "./RegistrationSidebarView";

const AlreadyRegisteredView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: eventOrNetworkingHub, isEvent } = useEventOrNetworkingHub();
  const {
    mutate: resendConfirmationEmail,
    isLoading: isResendingConfirmationEmail,
    isSuccess: didResendConfirmationEmail,
  } = useResendRegistrationEmail({
    onSuccess: () => {
      toast.success("Successfully resent confirmation email");
    },
    onError: () => {
      toast.error("An error occurred while resending the confirmation email");
    },
  });

  const form = useJoinCodeForm();
  const { control, formState, onSubmit } = form;

  const isSubmitDisabled = !canSubmitForm({ formState });

  const email = getValidatedResult(
    (location.state as any)?.email,
    z.string().nonempty().email(),
  );

  const handleClickReturn = useCallback(() => {
    navigate(
      {
        pathname: Routes.registration.register(
          eventOrNetworkingHub?.uid ?? "",
          isEvent,
        ),
        search: location.search,
      },
      { replace: true },
    );
  }, [eventOrNetworkingHub?.uid, isEvent, location.search, navigate]);

  useEffect(() => {
    // kick out if trying to access directly instead of being redirected from registration
    if (!email) {
      handleClickReturn();
    }
  }, [email, handleClickReturn]);

  const disableResendButton =
    formState.isSubmitting ||
    isResendingConfirmationEmail ||
    didResendConfirmationEmail;

  const resendButtonLabel = didResendConfirmationEmail
    ? "Confirmation email resent"
    : isResendingConfirmationEmail
    ? "Resending confirmation email..."
    : "Resend confirmation email";

  return (
    <RegistrationSidebarView
      as={Form<JoinCodeFormValues>}
      form={form}
      onSubmit={onSubmit}
      icon={PasscodeIcon}
      iconColor="primary"
      title="You're already registered"
      description="To continue, please enter your join code. If you can't find it, don't worry, we can send it to your email."
      content={
        <TextField
          control={control}
          name="joinCode"
          label="Join Code"
          placeholder="Enter your 12-digit join code"
          maxLength={12}
        />
      }
      actions={
        <>
          <Button
            type="submit"
            fullWidth
            disabled={isSubmitDisabled}
            loading={formState.isSubmitting}
            loadingPosition="start"
          >
            {formState.isSubmitting ? "Joining session..." : "Join session"}
          </Button>

          {email && (
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              fullWidth
              disabled={disableResendButton}
              onClick={() => resendConfirmationEmail({ email })}
              loading={isResendingConfirmationEmail}
              loadingPosition="start"
              startIcon={
                didResendConfirmationEmail ? (
                  <IconWrapper icon={CheckIcon} />
                ) : null
              }
            >
              {resendButtonLabel}
            </Button>
          )}

          <Button
            type="button"
            fullWidth
            variant="text"
            color="secondary"
            disabled={formState.isSubmitting}
            onClick={handleClickReturn}
            startIcon={<IconWrapper icon={ArrowLeftIcon} />}
          >
            Return to registration
          </Button>
        </>
      }
    />
  );
};

export default React.memo(AlreadyRegisteredView);
