import React from "react";
import { Virtuoso } from "react-virtuoso";

import { Poll, PollStatus, UserPoll } from "@src/providers/polls/PollProvider";
import { User } from "../../../contracts/user/user";
import { PollListItem } from "../PollListItem/PollListItem";
import { ParticipantNameOption } from "../../../contracts/enums/participant-name-options";
import {
  NoPollsText,
  Wrapper,
  ListContainer,
  StyledMuiDivider,
  PollWrapper,
} from "./PollList.styles";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The list of polls for this section
   */
  polls: Poll[];

  /**
   * Text to display when there are no polls
   */
  noPollsText: string;

  /**
   * The logged in user
   */
  user: User;

  /**
   * Flag indicating if the person is a host or circle owner for the current circle
   */
  isOrganizer: boolean;

  /**
   * Option for showing participant names
   *
   * @see {@link ParticipantNameOption}
   */
  showParticipantNames: ParticipantNameOption;

  /**
   * Callback for voting in the poll
   */
  handleVoteEventPollOption?: (data: { poll: string; option: string }) => void;

  /**
   * Callback for deleting a poll
   */
  handleDeleteEventPoll?: (data: { poll: string }) => void;

  /**
   * Callback for updating a poll status
   */
  handleUpdatePollStatus: (data: { poll: string; status: PollStatus }) => void;

  /**
   * Callback for publishing poll results
   */
  handlePublishPollResults: (data: { poll: string; publish?: boolean }) => void;

  /**
   * Navigate to the polls tab
   */
  navigateToTab: (tab: PollStatus) => void;
}

interface PropsWithHeader extends Props {
  /**
   * The section header
   */
  header: string;

  /**
   * The header click handler
   */
  onHeaderClick: () => void;

  /**
   * Whether the section is expanded or not
   */
  expanded: boolean;

  /**
   * Navigate to the polls tab
   */
  navigateToTab: (tab: PollStatus) => void;
}

const PollList = (props: Props | PropsWithHeader) => {
  const {
    polls,
    noPollsText,
    handleVoteEventPollOption,
    handleDeleteEventPoll,
    handleUpdatePollStatus,
    handlePublishPollResults,
    user,
    isOrganizer,
    showParticipantNames,
    navigateToTab,
  } = props;

  return (
    <Wrapper>
      {polls.length === 0 ? (
        <NoPollsText variant="caption">{noPollsText}</NoPollsText>
      ) : (
        <ListContainer>
          <Virtuoso
            style={{
              height: "100%",
              overflowX: "hidden",
            }}
            data={polls}
            itemContent={(index: number, poll: UserPoll) => (
              <PollWrapper key={poll.id}>
                {!!index && <StyledMuiDivider />}

                <PollListItem
                  user={user.uid}
                  isOrganizer={isOrganizer}
                  showParticipantNames={showParticipantNames}
                  navigateToTab={navigateToTab}
                  {...poll}
                  isUpdatePending={poll.isPending}
                  onVote={handleVoteEventPollOption}
                  onDelete={handleDeleteEventPoll}
                  onUpdatePollStatus={handleUpdatePollStatus}
                  onPublishPollResults={handlePublishPollResults}
                />
              </PollWrapper>
            )}
          />
        </ListContainer>
      )}
    </Wrapper>
  );
};

export default React.memo(PollList);
