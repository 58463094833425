import isString from "lodash/isString";
import { useMemo } from "react";
import { v4 as uuidV4 } from "uuid";
import { UserProps } from "../UserProvider";
import { UserRole } from "@src/contracts/user/user";
import { defaultUser } from "../common";
import { useConfigValue } from "@src/providers/config";
import { useAuthParams } from "@src/providers/QueryParamsProvider";

interface GetUserPropsOptions {
  user?: UserProps;
  isLoading: boolean;
  hasParamsUserInfo: boolean;
}
/**
 * for usage by the UserProvider only
 * if @param user is undefined (populated from useAuthenticatedUser) this returns UserProps based on query params or the default user
 * @param {UserProps} user- user from useAuthenticatedUser
 * @param {boolean} isLoading - if useAuthenticatedUser is currently validating via api
 * @param {hasParamsUserInfo} isLoading - does the queryParams contain all required user info
 * @returns {UserProps}
 */
export const useGetUserProps = ({
  user,
  isLoading,
  hasParamsUserInfo,
}: GetUserPropsOptions): UserProps | undefined => {
  const config = useConfigValue();
  const mode = isString(config.mode) ? config.mode : undefined;
  const { userId, userProfile, userName, userEmail } = useAuthParams();
  return useMemo(() => {
    // user from useAuthenticatedUser
    if (isLoading) return undefined;
    if (user) return { ...user, mode };

    // user from url parameters
    if (hasParamsUserInfo) {
      const originalId = (userId as string) || uuidV4();
      return {
        userId: `${originalId}|||${userEmail}`,
        originalId,
        userEmail: (userEmail as string) || `${originalId}@annon.user`,
        userName: userName as string,
        userRole: UserRole.Unregistered,
        userProfile:
          (userProfile as string) ||
          "https://res.cloudinary.com/introvoke/image/upload/v1612382461/Sample_User_Icon_ojbafq.png",
        mode,
      };
    }

    // unregistered
    return defaultUser;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isLoading]);
};
