import axios from "axios";

import { ApiConfig } from "./api-config";
import { Theme } from "../contracts/customization/theme";

/**
 * Gets the default sequel theme.
 *
 * @returns Theme
 */
export const GetDefaultTheme = async (): Promise<Theme> => {
  const configURL = `${ApiConfig.GetPrefix()}/api/customizations/events/default`;
  const response = await axios.get(configURL, {
    ...ApiConfig.GetHeaderWithToken(),
    timeout: 3000, // 3 second timeout to avoid waiting too long
  });
  return response.data;
};

/**
 * Gets the company's theme if available. If not, returns the default theme.
 *
 * Please note that themes are based on the root default theme with overrides applied.
 *
 * See the customization API for more information.
 */
export const GetTheme = async (companyId: string): Promise<Theme> => {
  const configURL = `${ApiConfig.GetPrefix()}/api/v3/companies/${companyId}/theme`;
  const response = await axios.get(configURL, {
    ...ApiConfig.GetHeaderWithToken(),
    timeout: 3000, // 3 second timeout to avoid waiting too long
  });
  return response.data;
};

export const CompanyApi = {
  GetTheme,
  GetDefaultTheme,
};
