import { User, UserRole } from "@src/contracts/user/user";
import { SocketClient } from "@src/graphql/SocketClient/SocketClient";

export interface UserQuestion extends Question {
  voted: boolean;
  isOwner?: boolean;
  isPending?: boolean;
}
export enum QuestionApprovedStatus {
  APPROVED = "approved",
  REJECTED = "rejected",
  PENDING = "pending",
}
export interface Question {
  id: string;
  eventId: string;
  circleId?: string;
  answered: boolean;
  createdAt: number;
  owner: Owner;
  // owner: SocketUser["meta"];
  name: string;
  votes: number;
  isAnonymous?: boolean;
  answer?: string;
  approved: QuestionApprovedStatus;
}

export interface Owner {
  userId: string;
  email: string;
  avatar: string;
  username: string;
  userRole?: UserRole;
}

export interface QuestionActionHookOptions {
  client: SocketClient;
  eventId?: string;
  circleId?: string;
  isApproveQnaEnabled?: boolean;
  user?: User;
}

export type QuestionActionRollbackFunction = () =>
  | UserQuestion[]
  | Question[]
  | undefined;

export type QuestionInput = Pick<Question, "id" | "name" | "owner">;
export type QuestionCreateInput = Pick<Question, "name"> &
  Partial<Pick<Question, "isAnonymous" | "approved">> & {
    id?: string;
  };
export type QuestionEventInput = { eventId: string; questionId: string };
export type QuestionEditEventInput = Pick<Question, "id" | "name" | "owner">;
export type QuestionVotesEventInput = Pick<Question, "id" | "votes">;
export type QuestionAnswerEventInput = Pick<Question, "id"> &
  Pick<Partial<UserQuestion>, "answered" | "answer" | "isPending">;
export type QuestionApproveEventInput = Pick<
  UserQuestion,
  "id" | "approved" | "isPending"
>;
