import React, { useCallback } from "react";
import { canSubmitForm } from "@src/hooks/useFormWithPersistentValidation";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "@src/hooks/useIsRoute";
import { useEventOrNetworkingHub } from "@src/hooks/useEventOrNetworkingHub";
import { JoinCodeFormValues, useJoinCodeForm } from "../hooks/useJoinCodeForm";

import { IconWrapper } from "@introvoke/react/components";
import PasscodeIcon from "@introvoke/react/icons/Passcode";
import ArrowLeftIcon from "@introvoke/react/icons/ArrowLeft";

import TextField from "@src/components/Forms/TextField";
import Button from "@src/components/buttons/Button";
import Form from "@src/components/Forms/Form";
import RegistrationSidebarView from "./RegistrationSidebarView";

const EnterJoinCodeView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: eventOrNetworkingHub, isEvent } = useEventOrNetworkingHub();

  const form = useJoinCodeForm();
  const { control, formState, onSubmit } = form;

  const isSubmitDisabled = !canSubmitForm({ formState });

  const handleClickReturn = useCallback(() => {
    navigate(
      {
        pathname: Routes.registration.register(
          eventOrNetworkingHub?.uid ?? "",
          isEvent,
        ),
        search: location.search,
      },
      { replace: true },
    );
  }, [eventOrNetworkingHub?.uid, isEvent, location.search, navigate]);

  return (
    <RegistrationSidebarView
      as={Form<JoinCodeFormValues>}
      form={form}
      onSubmit={onSubmit}
      icon={PasscodeIcon}
      iconColor="primary"
      title="Enter your Join Code"
      description="To continue, please enter your join code. If you can't find it, try registering again and we can send it to your email."
      content={
        <TextField
          control={control}
          name="joinCode"
          label="Join Code"
          placeholder="Enter your 12-digit join code"
          maxLength={12}
        />
      }
      actions={
        <>
          <Button
            type="submit"
            fullWidth
            disabled={isSubmitDisabled}
            loading={formState.isSubmitting}
            loadingPosition="start"
          >
            {formState.isSubmitting ? "Joining session..." : "Join session"}
          </Button>
          <Button
            type="button"
            fullWidth
            variant="text"
            color="secondary"
            disabled={formState.isSubmitting}
            onClick={handleClickReturn}
            startIcon={<IconWrapper icon={ArrowLeftIcon} />}
          >
            Return to registration
          </Button>
        </>
      }
    />
  );
};

export default React.memo(EnterJoinCodeView);
