import { emitAsPromise } from "@src/components/Presence/emitAsPromise";
import { User } from "@src/contracts/user/user";
import { Actions } from "@src/graphql/schemas/actions";
import { useCallback, useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  QuestionActionHookOptions,
  QuestionApprovedStatus,
  QuestionCreateInput,
  QuestionActionRollbackFunction,
  UserQuestion,
} from "../types";
import { createQuestionsQueryKey, sortQuestions } from "../helpers";

/**
 * Callback to add the newly created question to the supplied local questions array
 */
const insertCreatedQuestion =
  (data: UserQuestion) => (prev?: UserQuestion[] | null) => {
    const previousQuestions = Array.isArray(prev) ? prev : [];
    const questionIdx = previousQuestions.findIndex(
      (question) => question.id === data.id,
    );

    if (questionIdx >= 0) {
      // override local optimistic update question
      const updated = [...previousQuestions];
      updated[questionIdx] = data;
      return updated;
    }

    return sortQuestions([...previousQuestions, data]);
  };

/**
 * Function to create the basic question data to perform an optimistic create
 */
const createNewQuestionData = ({
  question,
  eventId,
  circleId,
  user,
  isApproveQnaEnabled,
}: {
  question: QuestionCreateInput;
  user: User;
  eventId?: string | null;
  circleId?: string;
  isApproveQnaEnabled?: boolean;
}) => {
  const newQuestion: UserQuestion = {
    id: question.id as string,
    eventId: eventId as string,
    circleId,
    answered: false,
    createdAt: Date.now(),
    isOwner: true,
    owner: question.isAnonymous
      ? {
          userId: question.id as string,
          username: "Anonymous",
          userRole: user.userRole,
          email: "anonymous@email.com",
          avatar:
            "https://res.cloudinary.com/introvoke/image/upload/v1612382461/Sample_User_Icon_ojbafq.png",
        }
      : {
          avatar: user.profilePicture,
          email: user.email,
          userId: user.originalId,
          username: user.name,
          userRole: user.userRole,
        },
    name: question.name as string,
    voted: false,
    votes: 0,
    isAnonymous: question.isAnonymous,
    approved: isApproveQnaEnabled
      ? QuestionApprovedStatus.PENDING
      : QuestionApprovedStatus.APPROVED,
    isPending: true,
  };
  return newQuestion;
};

export interface UseCreateEventQuestionOptions
  extends QuestionActionHookOptions {
  user: User;
  addLocalQuestion: (questionId: string) => void;
  isOwner: (question: UserQuestion) => boolean;
}

export const useCreateEventQuestion = ({
  client,
  eventId,
  circleId,
  addLocalQuestion,
  isOwner,
  isApproveQnaEnabled,
  user,
}: UseCreateEventQuestionOptions) => {
  const cache = useQueryClient();
  const queryKey = createQuestionsQueryKey(eventId, circleId);

  /**
   * Mutation to create a new question
   */
  const mutation = useMutation<
    UserQuestion,
    unknown,
    QuestionCreateInput,
    QuestionActionRollbackFunction
  >(
    (question: QuestionCreateInput) =>
      emitAsPromise<UserQuestion>(
        client,
        Actions.CREATE_EVENT_QUESTION,
        question,
      ),
    {
      onMutate: (question) => {
        const previousQuestions =
          cache.getQueryData<UserQuestion[]>(queryKey) || [];

        const newQuestion = createNewQuestionData({
          question,
          user,
          eventId,
          circleId,
          isApproveQnaEnabled,
        });

        cache.setQueryData<UserQuestion[]>(
          queryKey,
          insertCreatedQuestion(newQuestion),
        );

        return () => cache.setQueryData(queryKey, previousQuestions);
      },
      onSuccess: (data) => {
        // save question to localStorage if anonymous question
        if (data?.isAnonymous) {
          addLocalQuestion(data.id);
        }
      },
      onError: (err, variables, rollback) => {
        // rollback on error (success is handled by the handleQuestionCreated)
        rollback?.();
        toast.error("There was an error creating the question");
      },
    },
  );

  /**
   * Callback to run when a `question-created` event is received from the client server
   */
  const handleQuestionCreated = useCallback(
    (data: UserQuestion) => {
      data.isOwner = isOwner(data as UserQuestion);
      cache.setQueryData<UserQuestion[]>(
        createQuestionsQueryKey(data.eventId, data.circleId),
        insertCreatedQuestion(data),
      );
    },
    [cache, isOwner],
  );

  return useMemo(
    () => ({ mutation, handleQuestionCreated }),
    [handleQuestionCreated, mutation],
  );
};

export const __testable__ = {
  createNewQuestionData,
  insertCreatedQuestion,
};
