import { useCallback, useEffect, useRef } from "react";

interface LifecycleSafeCallback {
  (): void;
}

export const useLifecycleGuard = () => {
  const mounted = useRef(true);

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  return useCallback((callback: LifecycleSafeCallback) => {
    if (mounted.current) {
      return callback();
    }
  }, []);
};
