import React, { useMemo } from "react";
import PeopleIcon from "@mui/icons-material/PeopleOutline";
import ChatIcon from "@mui/icons-material/ChatBubbleOutline";
import QnAIcon from "@mui/icons-material/HelpOutline";
import PollsIcon from "@mui/icons-material/BarChart";
import ProfileIcon from "@mui/icons-material/AccountBox";
import SettingsIcon from "@mui/icons-material/Settings";
import { useEvent } from "@src/providers/EventProvider";
import useCommonController from "@src/components/SideBar/useCommonController";
import { useTheme } from "@mui/material/styles";
import { usePollContext } from "@src/providers/polls/PollProvider";
import { useQuestionContext } from "@src/providers/qAndA/QandAProvider";
import { UserRole } from "@src/contracts/user/user";
import { ParticipantNameOption } from "@src/contracts/enums/participant-name-options";
import { ChatTabs } from "@src/components/SideBar/ChatTabs";
import ParticipantsTabContent from "@src/components/SideBar/ParticipantsTabContent";
import { calculateQuestionsBadgeCount } from "@src/components/Q&A/helpers";
import { QandATabs } from "@src/components/Q&A/QandATabs";
import { PollsTabs } from "@src/components/Polls/PollsTabs/PollsTabs";
import ProfileTab from "@src/components/Profile/ProfileTab";
import SettingsTab from "@src/components/Settings/SettingsTab";
import { SideBarTabs } from "@src/components/SideBar/types";

export const useEventSideBarTabs = () => {
  const { data: event } = useEvent();

  const { user, participantCount, chatCount, theme, blockUser } =
    useCommonController();
  const appTheme = useTheme();

  const {
    isLoading: isPollsLoading,
    isError: isPollsError,
    pendingPolls,
    activePolls,
    closedPolls,
    archivedPolls,
    handleCreateEventPoll,
    handleVoteEventPollOption,
    handleHideEventPoll,
    handleUpdatePollStatus,
    handlePublishPollResults,
  } = usePollContext();
  const { questions, isApproveQnaEnabled } = useQuestionContext();

  const isOrganizer = user.userRole === UserRole.Organizer;
  const showParticipantNames = ParticipantNameOption.ALL;

  const showParticipantsTabFor =
    theme?.participantList?.showTabFor ?? UserRole.Viewer;
  const showParticipantsCountFor =
    theme?.participantList?.showCountFor ?? UserRole.Viewer;

  // Flags to hide tabs
  const hideParticipants =
    theme?.participantList?.enabled === false ||
    event?.hideParticipants ||
    user.userRole > showParticipantsTabFor;
  const showParticipantCount =
    participantCount > 0 && user.userRole <= showParticipantsCountFor;
  const hideChat = event?.whitelabel?.hideChat || !theme?.chat?.enabled;
  const hideQnA = !theme?.qna?.enabled;
  const hidePolls = !theme?.polls?.enabled;
  const hidePrivateChat = !theme?.privateMessages?.enabled;
  const hideSettings = !(user.userRole === UserRole.Organizer && event);

  const welcomeAvatar = useMemo(() => {
    return (
      event?.organizerProfile?.profilePicture ??
      `https://ui-avatars.com/api/?background=${appTheme.palette.primary.main.replace(
        "#",
        "",
      )}&size=36&color=fff&name=${
        event?.organizerProfile?.name ?? ""
      }&rounded=true`
    );
  }, [event?.organizerProfile, appTheme]);

  const tabs = useMemo(
    () =>
      !event?.uid
        ? null
        : [
            // Chat
            ...(hideChat
              ? []
              : [
                  {
                    key: SideBarTabs.CHAT,
                    label: "Chat",
                    icon: <ChatIcon />,
                    badgeCount: chatCount,
                    content: (
                      <ChatTabs
                        welcomeOwner={event.organizerProfile?.name || ""}
                        welcomeAvatar={welcomeAvatar}
                        extraTab={event.extraTab}
                        subjectId={event.uid}
                        hidePrivateChat={hidePrivateChat}
                      />
                    ),
                  },
                ]),

            // People
            ...(hideParticipants
              ? []
              : [
                  {
                    key: SideBarTabs.PEOPLE,
                    label: "People",
                    icon: <PeopleIcon />,
                    badgeCount: showParticipantCount ? participantCount : 0,
                    persistentBadgeCount: true,
                    content: (
                      <ParticipantsTabContent
                        {...{
                          user,
                          hidePrivateChat,
                          blockUser,
                        }}
                      />
                    ),
                  },
                ]),

            // Q & A
            ...(hideQnA
              ? []
              : [
                  {
                    key: SideBarTabs.QA,
                    label: "Q&A",
                    icon: <QnAIcon />,
                    badgeCount: calculateQuestionsBadgeCount({
                      questions,
                      isOrganizer,
                      isApproveQnaEnabled,
                    }),
                    content: (
                      <QandATabs user={user} isOrganizer={isOrganizer} />
                    ),
                  },
                ]),

            // Polls
            ...(hidePolls
              ? []
              : [
                  {
                    key: SideBarTabs.POLLS,
                    label: "Polls",
                    icon: <PollsIcon />,
                    badgeCount: activePolls.length,
                    content: (
                      <PollsTabs
                        {...{
                          user,
                          isOrganizer,
                          isLoading: isPollsLoading,
                          isError: isPollsError,
                          showParticipantNames,
                          pendingPolls,
                          activePolls,
                          closedPolls,
                          archivedPolls,
                          handleCreateEventPoll,
                          handleVoteEventPollOption,
                          handleDeleteEventPoll: handleHideEventPoll,
                          handleUpdatePollStatus,
                          handlePublishPollResults,
                        }}
                      />
                    ),
                  },
                ]),

            // Profile
            {
              key: SideBarTabs.PROFILE,
              label: "Profile",
              icon: <ProfileIcon />,
              content: (
                <ProfileTab entityId={event.uid} user={user} variant="live" />
              ),
            },

            // Settings
            ...(hideSettings
              ? []
              : [
                  {
                    key: SideBarTabs.SETTINGS,
                    label: "Settings",
                    icon: <SettingsIcon />,
                    content: <SettingsTab />,
                  },
                ]),
          ],
    [
      welcomeAvatar,
      hideParticipants,
      showParticipantCount,
      hideChat,
      hideQnA,
      hidePolls,
      isApproveQnaEnabled,
      isPollsLoading,
      isPollsError,
      hideSettings,
      participantCount,
      chatCount,
      event?.organizerProfile?.name,
      event?.extraTab,
      event?.uid,
      hidePrivateChat,
      blockUser,
      user,
      isOrganizer,
      showParticipantNames,
      questions,
      pendingPolls,
      activePolls,
      closedPolls,
      archivedPolls,
      handleCreateEventPoll,
      handleVoteEventPollOption,
      handleHideEventPoll,
      handleUpdatePollStatus,
      handlePublishPollResults,
    ],
  );

  return tabs;
};
