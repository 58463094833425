import React, { memo } from "react";
import VideoJs, { VideoJsTech } from "./VideoJs";
import { VideoJsPlayerProps } from "./types";
import useDeepMemo from "@src/hooks/useDeepMemo";

/**
 * A video player which uses the native HTML video player to playback videos
 */
const NativeVideoPlayer = ({ options = {}, ...rest }: VideoJsPlayerProps) => {
  const playerOptions = useDeepMemo(
    () => ({
      techOrder: [VideoJsTech.HTML],
      ...options,
    }),
    [options],
  );

  return <VideoJs options={playerOptions} {...rest} />;
};

export default memo(NativeVideoPlayer);
