import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export interface ErrorDialogProps {
  title?: string;
  message?: string;
  closeButtonText?: string;
  onClose?: () => void;
}

type ShowProp = {
  show: boolean;
};

type OpenProp = {
  open: boolean;
};

export const ErrorDialog = (
  props: ErrorDialogProps & (ShowProp | OpenProp),
) => {
  const {
    title = "Error",
    message = "Unable to complete the operation, an unexpected error has occurred.",
    closeButtonText = "Close",
    onClose,
  } = props;

  const open = "show" in props ? props.show : props.open;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      {onClose && (
        <DialogActions>
          <Button onClick={onClose} color="primary" autoFocus>
            {closeButtonText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
