import React from "react";
import { InputLabel, InputLabelProps } from "@src/components/inputs";
import type { ObjectKeyPaths, ObjectType } from "@introvoke/react/types";
import { Controller, type Control } from "react-hook-form";
import MuiCheckbox, {
  type CheckboxProps as MuiCheckboxProps,
} from "@mui/material/Checkbox";

export interface CheckboxProps<TFieldValues extends ObjectType = ObjectType>
  extends MuiCheckboxProps {
  control: Control<TFieldValues>;
  name: ObjectKeyPaths<TFieldValues>;
  label: InputLabelProps["label"];
}

const Checkbox = <TFieldValues extends ObjectType = ObjectType>({
  control,
  name,
  label,
  ...checkboxProps
}: CheckboxProps<TFieldValues>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState, formState }) => (
        <InputLabel
          label={label}
          direction="horizontal"
          sx={{
            "> div": { justifyContent: "flex-start", gap: 1 },
            "> div > div": { order: 2 },
          }}
          helperText={fieldState.error?.message}
          error={!!fieldState.error}
          labelStyles={{
            fontWeight: 400,
            color: "base.500",
          }}
        >
          <MuiCheckbox
            checked={
              typeof field.value === "string"
                ? field.value === "true"
                : !!field.value
            }
            disabled={formState.isLoading || formState.isSubmitting}
            disableRipple
            {...field}
            {...checkboxProps}
            sx={{
              p: 0,
              order: 1,
              "& .MuiSvgIcon-root": { fontSize: 20 },
              "&:hover .MuiSvgIcon-root": { color: "text.primary" },
              ...(checkboxProps.sx || {}),
            }}
          />
        </InputLabel>
      )}
    />
  );
};

export default React.memo(Checkbox) as typeof Checkbox;
