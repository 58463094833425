import React, { useEffect } from "react";
import {
  Outlet,
  Route,
  Routes as ReactRouterRoutes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { useEventOrNetworkingHub } from "@src/hooks/useEventOrNetworkingHub";
import { Routes, useIsRoute } from "@src/hooks/useIsRoute";
import { useHasEventEnded } from "@src/providers/EventProvider";
import { useIsReplayEnabled } from "@src/providers/EventStateProvider";
import { useUser } from "@src/providers/UserProvider";

import AlreadyRegisteredView from "./views/AlreadyRegisteredView";
import EnterJoinCodeView from "./views/EnterJoinCodeView";
import RegisterFormView from "./views/RegisterFormView";
import RegistrationSuccessView from "./views/RegistrationSuccessView";

import { UserRole } from "@src/contracts/user/user";

const useNavigateUsers = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userRole } = useUser();
  const { data: eventOrNetworkingHub, isEvent } = useEventOrNetworkingHub();

  const registrationPage = Routes.registration.register(
    eventOrNetworkingHub?.uid ?? "",
    isEvent,
  );
  const registrationSuccessPage = Routes.registration.success(
    eventOrNetworkingHub?.uid ?? "",
    isEvent,
  );

  const isOnRegistrationPage = useIsRoute(registrationPage);

  useEffect(() => {
    // if user is not registered, redirect to registration page
    if (userRole === UserRole.None || userRole === UserRole.Unregistered) {
      if (isOnRegistrationPage) return;
      return navigate(
        { pathname: registrationPage, search: location.search },
        { replace: true },
      );
    }
  }, [
    isOnRegistrationPage,
    location.search,
    navigate,
    registrationPage,
    registrationSuccessPage,
    userRole,
  ]);
};

const SequelRegistrationSideBar = () => {
  const eventHasEnded = useHasEventEnded();
  const replayEnabled = useIsReplayEnabled();

  useNavigateUsers();

  if (eventHasEnded && !replayEnabled) return null;

  return (
    <ReactRouterRoutes>
      <Route path="registration" element={<Outlet />}>
        <Route path="registered" element={<AlreadyRegisteredView />} />
        <Route path="enter-join-code" element={<EnterJoinCodeView />} />
        <Route path="event-not-started" element={<RegistrationSuccessView />} />
        <Route path="success" element={<RegistrationSuccessView />} />
        <Route index element={<RegisterFormView />} />
      </Route>
    </ReactRouterRoutes>
  );
};

export default React.memo(SequelRegistrationSideBar);
