import { nanoid } from "nanoid/non-secure";
import { DependencyList, useCallback, useEffect, useMemo } from "react";
import { useLatest } from "react-use";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";

import { getCurrentEnvironment, Environment } from "../EnvironmentProvider";
import {
  MessageCommand,
  MessageCommandDataShape,
  PostMessageEvent,
  PostMessageEventDataShape,
} from "./events";

export * from "./events";

interface SessionAtom {
  sessionId: string;
}

const sessionAtom = atom<SessionAtom>({
  key: "embedSessionAtom",
  default: {
    sessionId: nanoid(),
  },
});

type MessageListener<T = any> = (
  message: MessageEvent<T>,
) => void | Promise<void>;

interface MessageCommandEvent<TEvent extends MessageCommand> {
  /**
   * The command which has been sent on the message event data
   */
  command: TEvent;
  /**
   * Data which is related to the command
   */
  data: MessageCommandDataShape[TEvent];
}

const ALLOWED_ORIGINS = [
  // our many .io domains
  /https:\/\/.*\.*(sequel|getstellar)\.io/,
  // old introvoke if it still lives
  /https:\/\/.*\.*introvoke\.com/,
  // vercel introvoke apps/previews
  /https:\/\/.*introvoke.*\.vercel\.app/,
  // while in a development env allow localhost to embed this as an iframe for testing
  ...(getCurrentEnvironment() === Environment.DEV
    ? [/http:\/\/localhost:.*/]
    : []),
  // In unit tests allow for ourselves
  ...(process.env.NODE_ENV === "test" ? [/.{0}/] : []),
];

const SECURE_COMMANDS = new Set([MessageCommand.SET_USER_ROLE]);

/**
 * Hook which takes a callback to handle message events.
 *
 * Useful for cross-domain messaging, web workers, etc.
 *
 * For full information on message events, see: https://developer.mozilla.org/en-US/docs/Web/API/Window/message_event
 *
 * _NOTE_: You may provide a non-memoized callback as this hook internally handles reference updates:
 *
 * ```tsx
 * function MyComponent() => {
 *   // No need to create a function with `useCallback`
 *   useMessageListener((message) => {
 *     console.log(message.data);
 *   });
 * }
 * ```
 *
 * @param listener A callback which will be called when a new message is received
 */
export const useMessageListener = <TData>(listener: MessageListener<TData>) => {
  const latestListener = useLatest(listener);
  useEffect(() => {
    const onMessage = (message: MessageEvent<TData>) => {
      latestListener.current(message);
    };
    window.addEventListener("message", onMessage, false);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [latestListener]);
};

/**
 * Hook which takes a callback to handle command events which affect the internal state of the app.
 *
 * Useful for cross-domain messaging, web workers, etc.
 *
 * For full information on message events, see: https://developer.mozilla.org/en-US/docs/Web/API/Window/message_event
 *
 * _NOTE_: You may provide a non-memoized callback as this hook internally handles reference updates:
 *
 * ```tsx
 * function MyComponent() => {
 *   // No need to create a function with `useCallback`
 *   useCommandListener(MessageCommand.SET_USER_ROLE, (data) => {
 *     console.log(data); // -> { role: 2 }
 *   });
 * }
 * ```
 *
 * @param command The command to listen for
 * @param listener A callback which will be called when a new message is received which contains the command
 */
export const useCommandListener = <TEvent extends MessageCommand>(
  command: TEvent,
  listener: (data: MessageCommandDataShape[TEvent]) => void | Promise<void>,
) => {
  useMessageListener<MessageCommandEvent<TEvent>>((message) => {
    if (!message.data || typeof message?.data !== "object") return;
    if (message.data.command !== command) return;
    if (
      SECURE_COMMANDS.has(command) &&
      !ALLOWED_ORIGINS.some((regex) => regex.test(message.origin))
    ) {
      return;
    }
    listener(message.data.data);
  });
};

interface UsePostMessageOptions {
  /**
   * Optionally provide the origin to which the message should be sent, defaults to all "*"
   *
   * See: https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage#parameters
   */
  targetOrigin?: string;
  /**
   * The target window object to post the message to, defaults to self
   */
  target?: Window;
}

/**
 * Create a function to post a new event message to other window objects (iframe, popup) to be consumed.
 *
 * Optionally provide a target origin to restrict where the message is sent.
 *
 * See: https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
 *
 * ```tsx
 * function MyComponent() => {
 *   const postMessage = usePostMessage();
 *
 *   const handleButtonClick = () => {
 *     postMessage(PostMessageEvent.USER_UPDATED, { role: 1 });
 *   }
 * }
 * ```
 *
 * @param options Options for posting message such as a target origin
 * @returns A function to post messages
 */
export const usePostMessage = <TData = any>(
  options: UsePostMessageOptions = {},
) => {
  const latestOptions = useLatest(options);
  return useCallback(
    (data?: TData) => {
      const { targetOrigin = "*", target = window } = latestOptions.current;
      try {
        target?.postMessage(data, targetOrigin);
      } catch (err) {
        console.log(err);
        // ignore this means security or data issue preventing us from sending
        return false;
      }
      return true;
    },
    [latestOptions],
  );
};

/**
 * Returns a function to emit an event to the parent frame (when embedded).
 *
 * See: https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
 *
 * _NOTE_: WILL NOT send a post message if the target is the current window, if you wish
 * to dispatch messages within the current window please use event dispatching with custom events.
 *
 * @param windowTarget Optionally provide the target window object, defaults to current window parent
 * @returns A function used for emitting events to parent iframes
 */
export const useEmitEventCallback = (windowTarget?: Window) => {
  const { sessionId } = useRecoilValue(sessionAtom);

  const target = useMemo(() => {
    if (windowTarget) return windowTarget;
    // I don't trust browsers, so protect from throws
    try {
      return window.parent;
    } catch (e) {
      // we can't access for some unknown reason
      console.warn(e);
    }
  }, [windowTarget]);

  const postMessage = usePostMessage({ target });

  // IMPORTANT: The callback will trigger the last event for all active uses of this hook
  // when the session ID changes. This is expected as the session ID is used for establishing
  // a relation between a parent and child frame for 2-way communication, so it will bring the
  // parent frame up-to-date with current embed state and emitted events
  const emitEventCb = useCallback(
    <TEvent extends PostMessageEvent>(
      event: TEvent,
      data?: PostMessageEventDataShape[TEvent],
    ) => {
      // don't emit if we are the parent
      if (!target || target === window) return false;
      return postMessage({ event, data, meta: { sessionId } });
    },
    [postMessage, target, sessionId],
  );

  return emitEventCb;
};

type EmitShape<TEvent extends PostMessageEvent> = {
  /**
   * The event to emit
   */
  event: TEvent;
  /**
   * The data to emit with the event
   */
  data?: PostMessageEventDataShape[TEvent];
  /**
   * Optionally provide the target window object, defaults to current window parent
   */
  windowTarget?: Window;
};

/**
 * Emits the provided event and data when data changes within an effect callback.
 *
 * @param args.event The event to emit
 * @param args.data The data to emit with the event
 * @param deps Dependency list that will be used to determine whether to emit the data, will emit when/if event changes
 */
export const useEmitEvent = <TEvent extends PostMessageEvent>(
  { event, data, windowTarget }: EmitShape<TEvent>,
  deps: DependencyList,
) => {
  const emit = useEmitEventCallback(windowTarget);
  useEffect(() => {
    emit(event, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, event, emit]);
};

/**
 * Initiates common communication for parent/child iframe messaging between parent
 * frames and our child embed frame.
 *
 * _NOTE_: Should be called once on application start in `App.tsx`
 */
export const useEmbedProvider = () => {
  const setSession = useSetRecoilState(sessionAtom);

  // On initial load emit an initialized to indicate frame is ready to receive commands
  useEmitEvent(
    { event: PostMessageEvent.INITIALIZED, data: { initialized: true } },
    [],
  );

  // When the window closes/ends, emit a session ended event
  const emitMessage = useEmitEventCallback();
  useEffect(() => {
    const handleEnd = (event: PageTransitionEvent) => {
      // check if the browser is persisting the page in the background,
      // if so, do not signal an end of session
      // https://developer.chrome.com/blog/page-lifecycle-api/#the-unload-event
      if (event.persisted) return;
      emitMessage(PostMessageEvent.DISCONNECTED);
    };
    const terminationEvent = "onpagehide" in window ? "pagehide" : "unload";
    window.addEventListener(terminationEvent as "pagehide", handleEnd);
    return () => {
      window.removeEventListener(terminationEvent as "pagehide", handleEnd);
    };
  }, [emitMessage]);

  // Allow for setting a custom sessionId for frame communication
  useCommandListener(MessageCommand.SET_SESSION_ID, (data) => {
    setSession((s) => ({ ...s, sessionId: data.sessionId }));
  });
};
