import React, { useMemo } from "react";
import mixin from "mixin-deep";
import {
  ThemeProvider as EmotionThemeProvider,
  Global,
  css,
} from "@emotion/react";
// eslint-disable-next-line no-restricted-imports
import type { Color } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Theme as MuiTheme,
  ThemeProvider as MuiThemeProvider,
  createTheme,
  darken,
  lighten,
} from "@mui/material/styles";

import { getFontFormat, loadGoogleFont, loadFontFile } from "../helpers/font";
import { Theme as ApiTheme } from "../contracts/customization/theme";
import { FullPageLoader } from "../components/FullPageLoader";
import { useCustomTheme } from "./customization/useCustomTheme";
import {
  ThemeProvider as TailwindThemeProvider,
  ThemeProviderProps,
  DEFAULT_FONT_FAMILY_BODY,
  DEFAULT_FONT_FAMILY_DISPLAY,
  generateFontFamily,
} from "@introvoke/react/styles";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends MuiTheme {}
}

// https://material-ui.com/customization/palette/#adding-new-colors
declare module "@mui/material/styles/createPalette" {
  interface Palette {
    tertiary: Palette["primary"];
    danger: Palette["primary"];
    red: Partial<Color>;
    blue: Partial<Color>;
    orange: Partial<Color>;
    green: Partial<Color>;
    yellow: Partial<Color>;
    base: Partial<Color>;
  }
  interface PaletteOptions {
    tertiary: PaletteOptions["primary"];
    danger: PaletteOptions["primary"];
    red: Partial<Color>;
    blue: Partial<Color>;
    orange: Partial<Color>;
    green: Partial<Color>;
    yellow: Partial<Color>;
    base: Partial<Color>;
  }

  interface TypeBackground {
    /**
     * Default background color for the app. Defaults to theme.palette.common.white
     */
    default: string;
    /**
     * Background color for paper elements, such as cards. Defaults to theme.palette.base[50]
     */
    paper: string;
  }
}

declare module "@mui/material/styles/createTheme" {
  interface Theme {
    /**
     * Custom theme defined by the API
     */
    customTheme: ApiTheme | null | undefined;
  }
  interface DeprecatedThemeOptions {
    /**
     * Custom theme defined by the API
     */
    customTheme: ApiTheme | null | undefined;
  }
}

declare module "@mui/system/createTheme/shape" {
  interface Shape {
    /**
     * Defaults to `2px`
     */
    borderRadius: string | number;
  }
}

declare module "@emotion/react" {
  export interface Theme extends MuiTheme {}
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

export const ColorPalette = {
  Orange: {
    // TODO: Get others once ready in figma
    700: "#55281A",
    600: "#953519",
    400: "#FB5520",
    200: "#FFC6A7",
    100: "#FFEEE5",
  },
  Blue: {
    // TODO: Get others once ready in figma
    400: "#3155D2",
    200: "#D1E9FF",
  },
  Red: {
    // TODO: Get others once ready in figma
    400: "#EF5959",
    100: "#FFE7E7",
    50: "#FFF3F3",
  },
  Green: {
    700: "#203B2B",
    600: "#25633F",
    500: "#238C4E",
    400: "#30BF6C",
    300: "#62D993",
    200: "#ACE5C4",
    100: "#CCF5DD",
  },
  Yellow: {
    // TODO: Get others once ready in figma
    400: "#FFCA3A",
  },
  Base: {
    800: "#1F2023",
    700: "#27282B",
    600: "#2E3138",
    500: "#4F5567",
    400: "#878FAB",
    300: "#B6BCD1",
    200: "#D6DCE9",
    100: "#E8EBF5",
    50: "#F7F8FA",
  },
};

const TextColors = {
  primary: ColorPalette.Base[700],
  secondary: ColorPalette.Base[400],
  disabled: ColorPalette.Base[200],
};

const DEFAULT_FONT_SIZE_PX = 12;
const DEFAULT_FONT_WEIGHT = 600;

const DEFAULT_SEQUEL_THEME_NAME = "Sequel Platform Default Theme";

const MAX_THEME_WAIT_TIME_MS = 5000; // 5 seconds

function loadFont({ name, url }: { name: string; url: string }) {
  // We need to check if this is a CSS font file like a google one or if it's a legit font file
  const format = getFontFormat(url);
  if (format) {
    // We need to load the file itself
    loadFontFile(name, url);
  } else {
    loadGoogleFont(url);
  }
}

// Returns a fullscreen element as the root container for dialogs, popups, etc.
// if the component is fullscreen
export const getFullscreenContainerElement = (browserDocument = document) =>
  browserDocument.fullscreenElement ??
  // @ts-expect-error it's 2022 and safari can't add a prop to the document...
  browserDocument.webkitFullscreenElement ??
  browserDocument.body;

export const DEFAULT_THEME = createTheme({
  palette: {
    primary: {
      main: ColorPalette.Orange[400],
    },
    secondary: {
      main: ColorPalette.Blue[400],
    },
    error: {
      main: ColorPalette.Red[400],
    },
    warning: {
      main: ColorPalette.Yellow[400],
    },
    success: {
      main: ColorPalette.Green[400],
    },
    background: {
      default: ColorPalette.Base[50],
    },
    text: {
      ...TextColors,
      primary: TextColors.primary,
    },
    // Custom colors
    red: {
      ...ColorPalette.Red,
    },
    blue: {
      ...ColorPalette.Blue,
    },
    orange: {
      ...ColorPalette.Orange,
    },
    green: {
      ...ColorPalette.Green,
    },
    yellow: {
      ...ColorPalette.Yellow,
    },
    base: {
      ...ColorPalette.Base,
    },
    // V1 Compat colors
    tertiary: {
      main: TextColors.primary,
    },
    divider: ColorPalette.Base[100],
    danger: {
      main: ColorPalette.Red[400],
    },
    grey: { "100": ColorPalette.Base[50], "200": ColorPalette.Base[200] },
    action: {
      disabledBackground: "",
      disabled: "",
    },
  },
  shape: {
    borderRadius: 2,
  },
  typography: {
    fontFamily: generateFontFamily(undefined, DEFAULT_FONT_FAMILY_BODY),
    fontSize: DEFAULT_FONT_SIZE_PX,
    htmlFontSize: DEFAULT_FONT_SIZE_PX,
    body1: {
      fontSize: DEFAULT_FONT_SIZE_PX,
      lineHeight: 1.33,
      fontWeight: "normal",
    },
    body2: {
      fontSize: "1.1rem",
      lineHeight: 1.25,
      fontWeight: DEFAULT_FONT_WEIGHT,
    },
    h1: {
      fontSize: "2.833rem",
      lineHeight: 1.18,
      fontWeight: DEFAULT_FONT_WEIGHT,
    },
    h2: {
      fontSize: "2rem",
      lineHeight: 1.25,
      fontWeight: DEFAULT_FONT_WEIGHT,
    },
    h3: {
      fontSize: "1.667rem",
      lineHeight: 1.4,
      fontWeight: DEFAULT_FONT_WEIGHT,
    },
    h4: {
      fontSize: "1.25rem",
      lineHeight: 1.87,
      fontWeight: DEFAULT_FONT_WEIGHT,
    },
    caption: {
      color: TextColors.secondary,
      fontSize: "0.75rem",
      fontWeight: DEFAULT_FONT_WEIGHT,
    },
    button: {
      fontSize: DEFAULT_FONT_SIZE_PX,
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          // Prevent button text from wrapping to new line
          whiteSpace: "nowrap",
          fontWeight: DEFAULT_FONT_WEIGHT,
          borderRadius: 4,
          // Reset the text transform to allow any casing
          textTransform: "none",
          textDecoration: "none",

          "&.Mui-disabled": {
            opacity: 0.6,
          },
        },
        contained: {
          color: "#ffffff",
        },
        outlinedSecondary: {
          color: ColorPalette.Base[700],
          borderColor: ColorPalette.Base[300],
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        button: {
          textTransform: "none",
          fontWeight: 500,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          // Remove outlines added by Bottstrap
          outline: 0,
          "&:focus": {
            outline: 0,
          },
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        container: getFullscreenContainerElement,
      },
      styleOverrides: {
        paper: {
          borderRadius: 4,
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        container: getFullscreenContainerElement,
      },
    },
    MuiTooltip: {
      defaultProps: {
        PopperProps: {
          container: getFullscreenContainerElement,
        },
        placement: "top",
      },
      styleOverrides: {
        tooltip: {
          fontSize: DEFAULT_FONT_SIZE_PX,
          backgroundColor: ColorPalette.Base[500],
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        container: getFullscreenContainerElement,
      },
      styleOverrides: {
        container: {
          "& > .MuiPaper-root": {
            borderRadius: 20,
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingTop: 16,
          paddingBottom: 16,
          paddingRight: 24,
          paddingLeft: 24,
        },
      },
    },
  },
});

const generateGlobalStyle = ({
  bodyFontFamily,
  displayFontFamily,
}: {
  bodyFontFamily: string;
  displayFontFamily: string;
}) => css`
  body {
    background-color: #f2f2f2;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${displayFontFamily};
  }

  body,
  input,
  textarea,
  select,
  code {
    font-family: ${bodyFontFamily};
  }

  .emoji-mart {
    font-family: ${bodyFontFamily};
  }
`;

const CustomThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [isReady, setIsReady] = React.useState(false);
  const timeoutRef = React.useRef<NodeJS.Timeout>();

  const { data: customTheme, isFetched } = useCustomTheme();

  // TODO: hopefully there is only a single primary...
  const primaryFont = React.useMemo(
    () => [...(customTheme?.fonts || [])].find(({ isPrimary }) => isPrimary),
    [customTheme?.fonts],
  );

  const bodyFontFamily = useMemo(
    () => generateFontFamily(primaryFont?.fontName, DEFAULT_FONT_FAMILY_BODY),
    [primaryFont?.fontName],
  );

  const displayFontFamily = useMemo(
    () =>
      generateFontFamily(primaryFont?.fontName, DEFAULT_FONT_FAMILY_DISPLAY),
    [primaryFont?.fontName],
  );

  const overriddenTheme = React.useMemo(() => {
    if (!customTheme) {
      return DEFAULT_THEME;
    }

    const primaryColor =
      customTheme?.brandColors?.[0] || DEFAULT_THEME.palette.primary.main;
    const secondaryColor =
      customTheme?.brandColors?.[1] || DEFAULT_THEME.palette.secondary.main;
    const tertiaryColor =
      customTheme?.brandColors?.[2] || DEFAULT_THEME.palette.tertiary.main;
    const dangerColor =
      customTheme?.warningNotices?.buttonColor ||
      DEFAULT_THEME.palette.error.main;

    return createTheme(
      mixin(DEFAULT_THEME, {
        palette: {
          primary: {
            main: primaryColor,
            light: lighten(primaryColor, 0.5),
            dark: darken(primaryColor, 0.5),
          },
          secondary: {
            main: secondaryColor,
          },
          tertiary: {
            main: tertiaryColor,
          },
          text: {
            primary: tertiaryColor,
          },
          danger: {
            main: dangerColor,
          },
        },
        typography: {
          fontFamily: bodyFontFamily,
          ...Object.fromEntries(
            ["h1", "h2", "h3", "h4", "h5", "h6"].map((key) => [
              key,
              { fontFamily: displayFontFamily },
            ]),
          ),
          // Annoying but we need to override the variants for some reason
          // https://material-ui.com/customization/typography/#variants
          ...Object.fromEntries(
            [
              "subtitle1",
              "subtitle2",
              "body1",
              "body2",
              "button",
              "caption",
              "overline",
            ].map((key) => [key, { fontFamily: bodyFontFamily }]),
          ),
        },
        customTheme,
        components: {
          MuiButton: {
            styleOverrides: {
              outlinedPrimary: {
                boxShadow: "none",
              },
              outlinedSecondary: {
                boxShadow: "none",
              },
              containedPrimary: {
                boxShadow: "none",
                "&:hover": {
                  backgroundColor: primaryColor,
                },
                "&.Mui-disabled": {
                  backgroundColor: primaryColor,
                  opacity: 0.6,
                  color: "#ffffff",
                },
              },
              containedSecondary: {
                boxShadow: "none",
                "&:hover": {
                  backgroundColor: secondaryColor,
                },
                "&.Mui-disabled": {
                  backgroundColor: secondaryColor,
                  opacity: 0.6,
                  color: "#ffffff",
                },
              },
            },
          },
          MuiMobileStepper: {
            styleOverrides: {
              dot: {
                backgroundColor: ColorPalette.Base[100],
              },
              dotActive: {
                backgroundColor: primaryColor,
              },
            },
          },
        },
      }),
    );
  }, [customTheme, displayFontFamily, bodyFontFamily]);

  // Set a timeout to avoid waiting too long for the theme
  React.useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setIsReady(true);
    }, MAX_THEME_WAIT_TIME_MS);
  }, []);

  React.useEffect(() => {
    // If fonts change we need to download them before applying
    function getFont() {
      if (!primaryFont?.fontUrl) {
        setIsReady(true);
        return;
      }

      loadFont({
        name: primaryFont.fontName,
        url: primaryFont.fontUrl as string,
      });
      setIsReady(true);
    }

    if (isFetched && !isReady) {
      getFont();
    }
  }, [primaryFont, isFetched, isReady]);

  const tailwindTheme = useMemo<Omit<ThemeProviderProps, "children">>(
    () =>
      customTheme?.name === DEFAULT_SEQUEL_THEME_NAME
        ? {}
        : {
            colors: {
              primary: overriddenTheme.palette.primary.main,
            },
            fonts: {
              body: {
                fontFamily: displayFontFamily,
              },
              display: {
                fontFamily: displayFontFamily,
              },
            },
          },
    [
      customTheme?.name,
      displayFontFamily,
      overriddenTheme.palette.primary.main,
    ],
  );

  // If we are still loading, show the spinner
  if (!isReady) {
    return <FullPageLoader />;
  }

  return (
    <TailwindThemeProvider
      colors={tailwindTheme.colors}
      fonts={tailwindTheme.fonts}
    >
      <MuiThemeProvider theme={overriddenTheme}>
        <EmotionThemeProvider theme={overriddenTheme}>
          {/* Override the global font */}
          <Global
            styles={generateGlobalStyle({
              displayFontFamily,
              bodyFontFamily,
            })}
          />
          {children}
        </EmotionThemeProvider>
      </MuiThemeProvider>
    </TailwindThemeProvider>
  );
};

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return <CustomThemeProvider>{children}</CustomThemeProvider>;
};

/**
 * This is the base theme provider which should be at the root of the app.
 *
 * To override the default theme, create theme providers within the context of the base theme provider.
 */
export const BaseThemeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  // TODO: Need to get the default theme somehow or a company theme?
  return (
    <MuiThemeProvider theme={DEFAULT_THEME}>
      <EmotionThemeProvider theme={DEFAULT_THEME}>
        <CssBaseline />
        {/* Generate global styles to override the font-family for 3rd party libs and more */}
        <Global
          styles={generateGlobalStyle({
            displayFontFamily: generateFontFamily(
              undefined,
              DEFAULT_FONT_FAMILY_DISPLAY,
            ),
            bodyFontFamily: generateFontFamily(
              undefined,
              DEFAULT_FONT_FAMILY_BODY,
            ),
          })}
        />
        {children}
      </EmotionThemeProvider>
    </MuiThemeProvider>
  );
};
