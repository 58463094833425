import { useMemo } from "react";
import { z } from "zod";

export const getValidatedResult = <
  TSchema extends z.ZodTypeAny,
  TResult extends z.infer<TSchema> | undefined,
>(
  input: any,
  schema: TSchema,
  defaults?: TResult,
): z.infer<TSchema> | TResult => {
  const result = schema.safeParse(input);
  return result.success ? result.data : defaults;
};

export const useValidatedResult: typeof getValidatedResult = (
  input,
  schema,
  defaults,
) => {
  return useMemo(
    () => getValidatedResult(input, schema, defaults),
    [input, schema, defaults],
  );
};
