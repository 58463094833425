import React, { useMemo, useRef, useState } from "react";
import { ReactionImage } from "../ReactionPicker";
import { MessageAction } from "@src/providers/chat/ChatProvider";
import { ChatReaction, Reaction } from "@src/contracts/customization/reactions";
import { useChatReactions } from "@src/providers/chat/useChatReactions";
import {
  StyledReactionCountsContainer,
  StyledButton,
} from "./ReactionCounts.styles";
import { Grow, Tooltip } from "@mui/material";
import { useThrottleFn } from "react-use";
import { useLatestCallback } from "@src/hooks/useLatestCallback";

export interface ReactionCountItemProps {
  /**
   * The unique uuid of the user
   */
  userId: string;
  /**
   * The `ChatReaction` object which represents the reaction
   */
  reaction: ChatReaction;
  /**
   * The array of PubNub.MessageActions of type `"reactions"` added to a message.
   */
  reactions: {
    /**
     * The unique uuid of the user who add the reaction message action
     */
    uuid: string;
    /**
     * The unique timestamp of the action. Used to remove actions from messages.
     */
    actionTimetoken: string;
  }[];
  onClick: (reaction: Reaction) => void;
}

export const ReactionCountItem = ({
  reaction,
  reactions,
  userId,
  onClick,
}: ReactionCountItemProps) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const prevCountRef = useRef(reactions.length);

  const label = reaction.label || reaction.reaction;

  const hasReacted = useMemo(
    () =>
      reactions.some((reaction) => {
        return reaction.uuid === userId;
      }),
    [reactions, userId],
  );

  // throttle animations to avoid overkill
  // using throttle instead of debounce to invoke animation at the start instead of at the end
  useThrottleFn(
    (reactions) => {
      // if count didn't change or is already animating => skip
      if (reactions.length === prevCountRef.current || isAnimating) return;

      // start animation
      setIsAnimating(true);
    },
    250,
    [reactions],
  );

  const handleAnimationEnd = useLatestCallback(
    (event: React.AnimationEvent<HTMLSpanElement>) => {
      if (event.animationName === "count-item-animation") {
        setIsAnimating(false);
        prevCountRef.current = reactions.length;
      }
    },
  );

  return reactions?.length ? (
    <Grow in>
      <Tooltip title={label} disableInteractive>
        <div>
          <StyledButton
            variant="outlined"
            color="secondary"
            startIcon={<ReactionImage reaction={reaction} variant="count" />}
            onClick={onClick as any}
            hasReacted={hasReacted}
            data-testid={`reaction-count-item-button-${label}`}
          >
            {/* used for animation */}
            <span
              className={isAnimating ? "count-item-animate" : ""}
              onAnimationEnd={handleAnimationEnd}
            >
              {reactions.length}
            </span>
          </StyledButton>
        </div>
      </Tooltip>
    </Grow>
  ) : null;
};

export interface ReactionCountsProps {
  /**
   * The unique uuid of the user
   */
  userId: string;
  /**
   * The PubNub message action object containing all the actions with type `"reaction"` and value `Reaction`
   */
  reactions: MessageAction<Reaction>;
  onSelect: (reaction: Reaction) => void;
}

const ReactionCounts = ({
  userId,
  reactions,
  onSelect,
}: ReactionCountsProps) => {
  const reactionValues = Object.keys(reactions) as Reaction[];
  const { ReactionToChatReactionMap } = useChatReactions();

  return reactionValues.length ? (
    <StyledReactionCountsContainer data-testid="reaction-counts-container">
      {reactionValues.map((reaction) =>
        ReactionToChatReactionMap[reaction] && reactions[reaction] ? (
          <ReactionCountItem
            key={reaction}
            userId={userId}
            reaction={ReactionToChatReactionMap[reaction]}
            reactions={reactions[reaction] || []}
            onClick={() => onSelect(reaction)}
          />
        ) : null,
      )}
    </StyledReactionCountsContainer>
  ) : null;
};

export default ReactionCounts;
