import { useMemo } from "react";

import { useEventChatContext } from "./ChatProvider";

export const useChatChannelNewMessageCount = (
  channelId: string | null,
): number => {
  const { channels } = useEventChatContext();

  return useMemo(() => {
    if (!channelId) {
      return 0;
    }
    const channel = channels.find((entry) => entry.id === channelId);

    return channel?.newMessages ?? 0;
  }, [channelId, channels]);
};

type NewMessageCounts = { [channelId: string]: number };

// return message counts for all chat channels
export const useAllChatChannelNewMessageCounts = (
  channelIds: string[],
): NewMessageCounts => {
  const { channels } = useEventChatContext();

  return useMemo(() => {
    return channelIds.reduce<NewMessageCounts>(
      (newMessageCounts, currentChannelId, index) => {
        const currentChannel = channels.find(
          (entry) => entry.id === currentChannelId,
        );
        return {
          ...newMessageCounts,
          [currentChannelId]: currentChannel?.newMessages ?? 0,
        };
      },
      {},
    );
  }, [channelIds, channels]);
};
