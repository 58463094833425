import React, { memo } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Countdown } from "./Clock";

interface CounterBoxProps {
  label: string;
  value: number;
}

const CounterBox = ({ label, value }: CounterBoxProps) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ minWidth: { xs: "5wv", sm: 45, md: 50, lg: 55 } }}
    >
      <Typography
        fontWeight="600"
        lineHeight={1}
        fontSize={{ xs: "6vw", sm: "2.8rem", lg: "3.3rem" }}
        color="common.white"
      >
        {value}
      </Typography>
      <Typography
        fontSize={{ xs: "0.7rem", sm: "0.8rem", md: "0.9rem" }}
        color="common.white"
      >
        {label}
      </Typography>
    </Stack>
  );
};

function CountdownMessage({ days, hours, minutes, seconds }: Countdown) {
  return (
    <Stack
      display="flex"
      justifyContent="center"
      direction="row"
      spacing={2}
      sx={({ shape }) => ({
        border: 1,
        borderColor: "common.white",
        px: { xs: 2, lg: 3 },
        py: 1,
        borderRadius: shape.borderRadius,
        alignSelf: "center",
      })}
      data-testid="event-status-countdown-message"
    >
      <CounterBox label="days" value={days} />
      <CounterBox label="hours" value={hours} />
      <CounterBox label="minutes" value={minutes} />
      <CounterBox label="seconds" value={seconds} />
    </Stack>
  );
}

export default memo(CountdownMessage);
