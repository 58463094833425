import { z } from "zod";

export const CreateUserSchema = z.object({
  firstName: z.string().trim().nonempty("First name is required"),
  lastName: z.string().trim().nonempty("Last name is required"),
  email: z
    .string()
    .trim()
    .nonempty("Email cannot be empty")
    .email("Please enter a valid email"),
});

export const UpdateUserSchema = z.object({
  name: z.string().trim().nonempty("Display name is required"),
  title: z
    .string()
    .trim()
    .max(300, { message: "Title cannot be longer than 300 characters" }),
  companyName: z
    .string()
    .trim()
    .max(300, { message: "Company name cannot be longer than 300 characters" }),
  profilePicture: z.string().optional(),
});

export type CreateUserInput = z.infer<typeof CreateUserSchema>;
