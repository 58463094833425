import { useCallback, useEffect, useState } from "react";

export const useDialogControls = (value: boolean) => {
  const [show, setShow] = useState(value);

  useEffect(() => {
    if (value) {
      setShow(true);
    }
  }, [value]);

  return {
    show,
    onCancel: useCallback(() => setShow(false), []),
  };
};
