import type { GraphQlClient } from "@src/graphql/SocketClient/GraphQlClient";
import type { SocketClient } from "@src/graphql/SocketClient/SocketClient";
import { isSessionStorageEnabled } from "@src/helpers/browser";
import { isNetworkingHub } from "@src/helpers/utils";
import { useLatestCallback } from "@src/hooks/useLatestCallback";
import {
  FeatureFlag,
  useFeatureFlag,
} from "@src/providers/FeatureFlagsProvider";
import React, { useContext, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import invariant from "tiny-invariant";

import AlertDialog from "../dialogs/AlertDialog";
import { FullPageLoader } from "../FullPageLoader";
import { GQL_SESSION_STORAGE_KEY, gqlSessionQueryKey } from "./common";
import { useClientConnect } from "./hooks/useClientConnect";
import { useClientDisconnect } from "./hooks/useClientDisconnect";
import { useClientHeartbeat } from "./hooks/useClientHeartbeat";
import { useClientStartSession } from "./hooks/useClientStartSession";
import { useCreateClient } from "./hooks/useCreateClient";

interface SocketClientContextValue {
  client: SocketClient | GraphQlClient;
  isSessionActive: boolean;
  sessionId?: string | null;
}

const SocketClientContext =
  React.createContext<SocketClientContextValue | null>(null);

export const SocketClientProvider = ({
  fallback = <FullPageLoader />,
  children,
}: React.PropsWithChildren<{
  fallback?: React.ReactNode;
}>) => {
  // this is actually not good because of the provider ordering
  const isV2ChatServerEnabled = !!useFeatureFlag(FeatureFlag.CHAT_SERVER_V2);
  const queryClient = useQueryClient();
  const isHub = isNetworkingHub();
  const [showDialog, setShowDialog] = useState(false);

  // need to update the query data to retrigger the sync for
  // event, user and circle when sessionId is expired and changed
  const onSessionStarted = useLatestCallback((sessionId: string) => {
    isSessionStorageEnabled() &&
      window.sessionStorage.setItem(GQL_SESSION_STORAGE_KEY, sessionId);
  });

  const onDisconnect = useLatestCallback(() => {
    queryClient.setQueryData(gqlSessionQueryKey, null);
  });

  const client = useCreateClient({
    isV2ChatServerEnabled,
    onSessionStarted,
    onDisconnect,
  });

  // wait for session to be started/created
  const {
    data: sessionId,
    isLoading,
    isError,
    refetch: startSession,
  } = useClientStartSession(
    { client, isV2ChatServerEnabled },
    { onError: () => setShowDialog(true) },
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useClientConnect({ client, isV2ChatServerEnabled, startSession });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useClientDisconnect({ client, isV2ChatServerEnabled, onDisconnect });
  // emit heartbeats when the user is connected to keep session alive
  // TODO: We only want to make the heartbeat while on hubs
  // once we are ready to switch events to using this for presence remove
  // the following check
  const isHeartbeatEnabled = isHub && isV2ChatServerEnabled;
  useClientHeartbeat({ client, isV2ChatServerEnabled: isHeartbeatEnabled });

  const hasSessionId =
    !!sessionId &&
    (isV2ChatServerEnabled
      ? !!(client as GraphQlClient).getSessionId?.()
      : true);

  const isSessionActive = !isLoading && !isError && hasSessionId;

  const contextValue = useMemo<SocketClientContextValue>(
    () => ({ client, isSessionActive, sessionId }),
    [client, isSessionActive, sessionId],
  );

  if (isLoading) return <>{fallback}</>;

  return (
    <SocketClientContext.Provider value={contextValue}>
      {children}
      {/* Show error dialog when there are gql errors */}
      <AlertDialog
        hideAction
        open={isHub && showDialog}
        variant="warning"
        body="Firewalls, VPNs, and browser extensions such as ad blockers can interfere with your experience. Please disable these extensions and try again."
      />
    </SocketClientContext.Provider>
  );
};

/**
 * Hook that returns the value of the `SocketClientContext` Provider
 */
export const useSocketClientContext = () => {
  const ctx = useContext(SocketClientContext);
  invariant(ctx, "useSocketClient used outside of SocketClientContext");
  return ctx;
};

/**
 * Hook that returns the value of the `SocketClient` from the `SocketClientProvider`
 */
export const useSocketClient = () => {
  const ctx = useContext(SocketClientContext);
  invariant(ctx, "useSocketClient used outside of SocketClientContext");
  return ctx.client;
};

/**
 * Returns the current sessionId of the socket client
 */
export const useClientSessionId = () => {
  const ctx = useContext(SocketClientContext);
  invariant(ctx, "useSocketClient used outside of SocketClientContext");
  return ctx.sessionId;
};

export const useIsSessionActive = () => {
  const ctx = useContext(SocketClientContext);
  invariant(ctx, "useIsSessionActive used outside of SocketClientContext");
  return !!ctx.isSessionActive;
};
