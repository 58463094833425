import {
  useIsLiveReactionsFeatureEnabled,
  useLiveReactionsToggle,
} from "@src/providers/EventProvider/useLiveReactions";
import React, { useCallback } from "react";
import { User, UserRole } from "../../contracts/user/user";
import { InputLabel, Switch } from "../inputs";
import { SettingsGroup } from "../SideBar/SettingsSection";

export interface GeneralSettingsProps {
  user: User;
}
const GeneralSettings: React.FC<GeneralSettingsProps> = ({ user }) => {
  const { enabled: isLiveReactionsEnabled, enableLiveReactions } =
    useLiveReactionsToggle();

  const isLiveReactionsFlagEnabled = useIsLiveReactionsFeatureEnabled();

  const handleEnableLiveReactions = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      enableLiveReactions(e.target.checked);
    },
    [enableLiveReactions],
  );

  const isOrganizer = user.userRole === UserRole.Organizer;

  if (!isLiveReactionsFlagEnabled || !isOrganizer) {
    return null;
  }

  return (
    <SettingsGroup title="General Settings" data-testid="general-settings">
      <InputLabel
        label="Live Reactions"
        direction="horizontal"
        fullWidth
        description="Allow users to express themselves visually during an event"
      >
        <Switch
          name="EnableLiveReactions"
          checked={isLiveReactionsEnabled}
          onChange={handleEnableLiveReactions}
        />
      </InputLabel>
    </SettingsGroup>
  );
};

export default React.memo(GeneralSettings);
