import { atom, selectorFamily } from "recoil";

import { StageType } from "./types";

interface EventStateAtom {
  isEventLive: boolean;
  replayEnabled: boolean;
  stage: StageType;
}

// Creating a recoil atom for now to get current stage status of user for the sidebar
// TODO: Switch to recoil since this provider is not wrapping the app
export const eventStateAtom = atom<EventStateAtom>({
  key: "eventStateAtom",
  default: {
    isEventLive: false,
    replayEnabled: false,
    stage: StageType.Viewer,
  },
});

export const eventStateSelectorFamily = selectorFamily({
  key: "eventStateSelectorFamily",
  get:
    (property: keyof EventStateAtom) =>
    ({ get }) =>
      get(eventStateAtom)[property] as EventStateAtom[typeof property],
});
