import React, { memo, useRef, useState, useCallback } from "react";
import { useDeepCompareEffect, useLatest } from "react-use";
import VideoJs, { VideoJsTech } from "../VideoJs";
import { VideoJsPlayerProps, VideoPlayerInstance } from "../types";
import { MediaPlayer as IvsPlayer, VideoJSEvents } from "./ivsTypes";

interface IvsVideoJs extends VideoPlayerInstance {
  getIVSPlayer(): IvsPlayer;
  getIVSEvents(): VideoJSEvents;
}

const initializeIvs = ({
  player,
  onError,
}: {
  player: IvsVideoJs;
  onError: (error: MediaError) => void;
}) => {
  const ivsPlayer = player.getIVSPlayer();
  const events = player.getIVSEvents();

  // Typescript has some issues so we need to hold its hand here
  const isAutoplayTruthy = () => (player.autoplay() as boolean) !== false;

  ivsPlayer?.setAutoplay(isAutoplayTruthy());
  player?.enableIVSQualityPlugin();

  // On init set the muted and autoplay if parent has it set
  ivsPlayer?.addEventListener(events?.PlayerEventType?.INITIALIZED, () => {
    if (isAutoplayTruthy()) ivsPlayer?.play();
    ivsPlayer?.setMuted(player.muted());
  });

  // Handle errors for playing w/o interaction
  ivsPlayer?.addEventListener(events?.PlayerEventType?.AUDIO_BLOCKED, () => {
    if (isAutoplayTruthy()) {
      ivsPlayer?.setMuted(true);
      ivsPlayer?.play();
    }
  });
  ivsPlayer?.addEventListener(events?.PlayerEventType?.PLAYBACK_BLOCKED, () => {
    console.warn("Player blocked on playback");
    if (isAutoplayTruthy()) {
      ivsPlayer?.setMuted(true);
      ivsPlayer?.play();
    }
  });

  // TODO: Handle this somehow w/ an error?
  ivsPlayer?.addEventListener(events?.PlayerEventType?.ERROR, (error: any) => {
    const statusTooManyRequests = 429;
    if (
      error.type === events?.ErrorType?.NOT_AVAILABLE &&
      error.code === statusTooManyRequests
    ) {
      // This is one we should alert on
      console.error("Concurrent-viewer limit reached", error);
    } else {
      // This will be a 404 not available which we can handle later
      console.warn(error);
    }
    onError(error);
  });
};

/**
 * A video player that can be used to playback IVS live streams and related media.
 *
 * The `src` value must be a valid source for an IVS asset.
 */
const IvsVideoPlayer = ({ options, children, ...rest }: VideoJsPlayerProps) => {
  const onErrorRef = useLatest(rest?.onError);
  const onReadyRef = useLatest(rest?.onReady);
  const playerRef = useRef<VideoPlayerInstance>();
  const [playerOptions, setPlayerOptions] = useState({
    techOrder: [VideoJsTech.IVS as string],
    ...(options ?? {}),
    // loop will cause the IVS player to throw
    loop: undefined,
  });

  const onReady = useCallback(
    (player: VideoPlayerInstance) => {
      playerRef.current = player;
      const onError = (error: MediaError) => {
        onErrorRef.current?.(error, playerRef.current as VideoPlayerInstance);
      };
      initializeIvs({ player: player as IvsVideoJs, onError });
      onReadyRef.current?.(player);
    },
    [onReadyRef, onErrorRef],
  );

  useDeepCompareEffect(
    () => setPlayerOptions((o) => ({ ...o, ...options, loop: undefined })),
    [options],
  );

  return <VideoJs options={playerOptions} {...rest} onReady={onReady} />;
};

export default memo(IvsVideoPlayer);
