import { useCallback } from "react";
import { useQuery, useQueryClient, UseQueryOptions } from "react-query";
import throttle from "lodash/throttle";
import { useSetRecoilState } from "recoil";
import { useSocketClient } from "@src/components/Presence/SocketClientProvider";
import { HEARTBEAT_MS } from "@src/graphql/SocketClient/common";
import { roomUsersAtom } from "@src/components/Presence/common";
import { UserProfile } from "@src/components/Presence/useSyncSocketUser";
import { emitAsPromise } from "@src/components/Presence/emitAsPromise";
import { Actions } from "@src/graphql/schemas/actions";
import { FeatureFlag, useFeatureFlag } from "../FeatureFlagsProvider";

import { createTemporaryRoomsQueryKey, TemporaryRoom } from "./common";

/**
 * Creates a query for querying the temporary networking hub rooms.
 *
 * This returns only those rooms which have been created on-demand while in the networking hub
 * through the use of the create circle or random mingle functionality.
 */
export function useTemporaryRooms(
  networkingHubId?: string | null | undefined,
  options: UseQueryOptions<TemporaryRoom[], unknown> = {},
) {
  const isV2ChatServerEnabled = useFeatureFlag(FeatureFlag.CHAT_SERVER_V2);
  const client = useSocketClient();
  const setRoomUsers = useSetRecoilState(roomUsersAtom);

  const query = useQuery<TemporaryRoom[], unknown>(
    createTemporaryRoomsQueryKey(networkingHubId),
    async () => {
      const res = await emitAsPromise(client, Actions.LIST_EVENT_ROOMS);
      if (!isV2ChatServerEnabled) return res as TemporaryRoom[];

      const v2Res = res as {
        id: string;
        name: string;
        users: UserProfile[];
      }[];

      if (v2Res && v2Res.length) {
        v2Res.forEach((circle) => {
          setRoomUsers((prev) => ({
            ...prev,
            [circle.id]: circle.users.map((user) => ({
              ...user,
              meta: {
                ...user,
              },
            })),
          }));
        });
      }
      return v2Res as unknown as TemporaryRoom[];
    },
    {
      enabled: false, // need to trigger this manually after socket-event is synced
      retry: 2,
      ...options,
    },
  );

  // need to throttle this to avoid calling it on every presence update
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetch = useCallback(
    throttle(
      async () => {
        try {
          return await query.refetch();
        } catch (err) {
          // ignore
        }
      },
      HEARTBEAT_MS, // using an average of 5s delay before triggering refetch
      { leading: true, trailing: true },
    ),
    [],
  );

  return { query, refetch };
}

/**
 * Returns a setter used for setting the provided hub ID's temporary rooms
 */
export const useSetTemporaryRooms = (
  networkingHubId: string | null | undefined,
) => {
  const client = useQueryClient();
  return useCallback(
    (
      setter: TemporaryRoom[] | ((prev: TemporaryRoom[]) => TemporaryRoom[]),
    ) => {
      client.setQueryData<TemporaryRoom[]>(
        createTemporaryRoomsQueryKey(networkingHubId),
        (prev = []) => {
          if (Array.isArray(setter)) return setter;
          return setter(prev);
        },
      );
    },
    [networkingHubId, client],
  );
};
