import React, { memo } from "react";
import { isAfter } from "date-fns";
import { styled } from "@mui/styles";
import Box from "@mui/material/Box";
import { CountdownClock } from "@src/components/Event/EventStage/Clock";
import CountdownMessage from "@src/components/Event/EventStage/CountdownMessage";
import {
  EventScheduledTimelineStatus,
  useEventScheduledTimelineStatus,
  useEventState,
  useIsEventLive,
  useIsReplayEnabled,
} from "@src/providers/EventStateProvider";

interface Props {
  startDate: Date;
  startingSoon?: boolean;
}

const EventStatus = styled(Box)(({ theme }) => ({
  fontSize: "1.333rem",
  borderColor: theme.palette.common.white,
  borderStyle: "solid",
  borderWidth: 1,
  borderRadius: (theme.shape.borderRadius as number) * 2,
  color: theme.palette.common.white,
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  fontWeight: 500,
}));

/**
 * Used in the non-live event header to show either the countdown for pre-live or the current event status (live, pending-live or event event)
 */
const EventStatusContainer = memo(({ startDate, startingSoon }: Props) => {
  const isEventLive = useIsEventLive();
  const { eventLivestreamEndTime } = useEventState();
  const timelineStatus = useEventScheduledTimelineStatus();
  const replayEnabled = useIsReplayEnabled();

  const postEventMessage = replayEnabled
    ? "On-demand video available, register to watch"
    : "The event has ended";

  const eventStreamHasEnded =
    !!eventLivestreamEndTime && isAfter(eventLivestreamEndTime, startDate);

  if (isEventLive) {
    if (startingSoon) {
      return (
        <EventStatus data-testid="event-status-live">
          The event is starting soon!
        </EventStatus>
      );
    }
    return (
      <EventStatus data-testid="event-status-live">
        The event has started!
      </EventStatus>
    );
  }

  if (
    timelineStatus === EventScheduledTimelineStatus.AFTER ||
    eventStreamHasEnded
  ) {
    return (
      <EventStatus data-testid="event-status-ended">
        {postEventMessage}
      </EventStatus>
    );
  }

  if (timelineStatus === EventScheduledTimelineStatus.DURING) {
    return (
      <EventStatus data-testid="event-status-starting">
        The event is about to start, stay tuned!
      </EventStatus>
    );
  }

  return (
    <CountdownClock date={startDate} backwards>
      {(countdownProps) => <CountdownMessage {...countdownProps} />}
    </CountdownClock>
  );
});

export default EventStatusContainer;
