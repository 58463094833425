import React, { memo } from "react";
import { format } from "date-fns";
import { atcb_action } from "add-to-calendar-button";
import { utcToZonedTime } from "date-fns-tz";
import { useEvent } from "@src/providers/EventProvider";
import Button, { Props as ButtonProps } from "@src/components/buttons/Button";
import {
  EventScheduledTimelineStatus,
  useEventScheduledTimelineStatus,
} from "@src/providers/EventStateProvider";
import { IconWrapper } from "@introvoke/react/components";
import CalendarPlusIcon from "@introvoke/react/icons/CalendarPlus02";

interface AddToCalendarButtonProps extends ButtonProps {
  joinCode: string | undefined;
}

const AddToCalendarButton = ({
  joinCode,
  ...props
}: AddToCalendarButtonProps) => {
  const { data: event } = useEvent();
  const timelineStatus = useEventScheduledTimelineStatus();

  const isRegistrationRequired = !!event?.registration?.enabled;

  if (
    timelineStatus !== EventScheduledTimelineStatus.BEFORE ||
    !event ||
    !event.startDate ||
    !event.endDate ||
    (isRegistrationRequired && !joinCode)
  ) {
    return null;
  }

  const location =
    event.registration?.customUrl ||
    window.location.origin + window.location.pathname;
  const joinUrl = !joinCode ? location : `${location}?joinCode=${joinCode}`;
  const startDateToTimezone = utcToZonedTime(event.startDate, event.timezone);
  const endDateToTimezone = utcToZonedTime(event.endDate, event.timezone);

  return (
    <Button
      variant="outlined"
      color="secondary"
      onClick={(e) => {
        try {
          e.preventDefault();
          atcb_action({
            name: event.name,
            size: "4",
            description: `You are successfully registered for ${event.name}. We look forward to seeing you soon. <br>Join event here: [url]${joinUrl}[/url]`,
            startDate: format(startDateToTimezone, "yyyy-MM-dd"),
            endDate: format(endDateToTimezone, "yyyy-MM-dd"),
            startTime: format(startDateToTimezone, "HH:mm"),
            endTime: format(endDateToTimezone, "HH:mm"),
            location: joinUrl,
            options: [
              "Apple",
              "Google",
              "iCal",
              "Microsoft365",
              "Outlook.com",
              "Yahoo",
            ],
            timeZone: event.timezone,
            iCalFileName: "Reminder-Event",
          });
        } catch (error) {
          // ignore error, nothing we can do
        }
      }}
      {...props}
      startIcon={<IconWrapper icon={CalendarPlusIcon} className="mr-2" />}
    >
      Add event to calendar
    </Button>
  );
};

export default memo(AddToCalendarButton);
