import { useEventState } from "@src/providers/EventStateProvider";
import { useIntervalEffect } from "@react-hookz/web";
import { useEvent } from "@src/providers/EventProvider";
import { intervalToDuration, isAfter, isBefore } from "date-fns";
import { useCallback, useState } from "react";

export const getDateOffset = (dateString: Date | undefined | null) => {
  if (!dateString) {
    return "--:--:--";
  }

  const date = new Date(dateString);

  const { years, months, days, hours, minutes, seconds } = intervalToDuration({
    start: Date.now(),
    end: date,
  });

  if (years) {
    return `${years} year${years > 1 ? "s" : ""}`;
  }

  if (months) {
    return `${months} month${months > 1 ? "s" : ""}`;
  }

  if (days) {
    return `${days} day${days > 1 ? "s" : ""}`;
  }

  const time = `${String(hours || 0).padStart(2, "0")}:${String(
    minutes || 0,
  ).padStart(2, "0")}:${String(seconds || 0).padStart(2, "0")}`;

  return time;
};

export const useGetTimeInfo = (isStreamLive: boolean) => {
  const { data: event } = useEvent();
  const { eventLivestreamStartTime } = useEventState();
  const getTimeInfo = useCallback(() => {
    const currentDate = Date.now();

    if (isStreamLive) {
      return `Running time ${getDateOffset(eventLivestreamStartTime)}`;
    }

    // if after end time and not live
    if (event?.endDate && isAfter(currentDate, new Date(event.endDate))) {
      return `Event has ended`;
    }

    if (event?.startDate) {
      const startDate = new Date(event?.startDate);

      // if before start time and not live
      if (isBefore(currentDate, startDate)) {
        return `Scheduled to start in ${getDateOffset(event?.startDate)}`;
      }

      // if after start time and not live
      if (isAfter(currentDate, startDate)) {
        return "Event will start soon";
      }
    }

    return "";
  }, [
    eventLivestreamStartTime,
    isStreamLive,
    event?.endDate,
    event?.startDate,
  ]);

  const [timeInfo, setTimeInfo] = useState(getTimeInfo);

  useIntervalEffect(() => {
    setTimeInfo(getTimeInfo);
  }, 1000);

  return timeInfo;
};
