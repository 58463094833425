/** @jsxImportSource @emotion/react */

import React, { memo } from "react";

import { ClassNames } from "@emotion/react";
import Button from "@mui/material/Button";

export const ActionButton = memo(
  (props: {
    children?: React.ReactNode;
    disabled?: boolean;
    onClick?: (event: React.SyntheticEvent<any, Event>) => void;
    startIcon?: React.ReactNode;
  }) => {
    return (
      <ClassNames>
        {({ css }) => (
          <Button
            color="primary"
            variant="contained"
            css={css`
              border-radius: 10px;
              font-size: 12px;
            `}
            {...props}
          />
        )}
      </ClassNames>
    );
  },
);
