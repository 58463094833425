/** @jsxImportSource @emotion/react */
import React, { memo, MemoExoticComponent, ReactElement } from "react";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";

import { ClassNames } from "@emotion/react";
import { CardActions, CardContent, Popover } from "@mui/material";
import StopCircleOutlined from "@mui/icons-material/StopCircleOutlined";

import { DangerButton } from "../../buttons/DangerButton";
import { BaseButton, BaseButtonProps } from "../../buttons/BaseButton";
import { StageHeaderBaseButton } from "./StageHeaderBaseButton";

type EndNetworkingButtonProps = {
  buttonLocationId: string;
  Component?: MemoExoticComponent<
    (props: BaseButtonProps) => ReactElement<any, any> | null
  >;
  loading: boolean;
  onConfirm: (returnToEvent: boolean) => void;
  returnToEventId?: string;
};

const EndNetworkingButton = ({
  Component,
  loading,
  ...props
}: EndNetworkingButtonProps) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: `end-networking-menu-${props.buttonLocationId}`,
  });

  const ButtonTag = Component ? (
    <Component {...bindTrigger(popupState)} />
  ) : (
    <StageHeaderBaseButton
      loading={loading}
      disabled={loading}
      {...bindTrigger(popupState)}
      startIcon={<StopCircleOutlined />}
    >
      {"Stop Networking"}
    </StageHeaderBaseButton>
  );

  return (
    <ClassNames>
      {({ css }) => (
        <>
          {ButtonTag}

          <Popover
            classes={{
              root: css`
                margin-top: 20px;
              `,
              paper: css`
                padding: 8px;
              `,
            }}
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <CardContent
              sx={{
                fontWeight: 700,
              }}
            >
              Move all attendees back to virtual stage?
            </CardContent>
            <CardActions
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <BaseButton color="secondary" onClick={popupState.close}>
                Cancel
              </BaseButton>

              <DangerButton
                onClick={() => {
                  popupState.close();
                  props.onConfirm(false);
                }}
              >
                Stop Networking
              </DangerButton>

              {props.returnToEventId?.length && (
                <DangerButton
                  onClick={() => {
                    popupState.close();
                    props.onConfirm(true);
                  }}
                >
                  Stop Networking and Move Attendees to Virtual Stage
                </DangerButton>
              )}
            </CardActions>
          </Popover>
        </>
      )}
    </ClassNames>
  );
};

export default memo(EndNetworkingButton);
