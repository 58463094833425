import React, { useCallback, useMemo, useEffect } from "react";
import { ListenerParameters } from "pubnub";
import debounce from "lodash/debounce";
import omit from "lodash/omit";
import isEmpty from "lodash/isEmpty";

import type {
  PresentUser,
  PresentUsers,
  PresentUserIds,
  PubnubState,
} from "./PubNubProvider";
import { useSetParticipantCount } from "./usePubNubHereNow";
import {
  AnyPresenceEvent,
  LeavePresenceEvent,
  IntervalPresenceEvent,
} from "./pubnub";
import { useUser } from "../UserProvider";

declare module "pubnub" {
  interface MessageEvent {
    userMetadata?: any;
  }

  // NOTE: PubNub type doesn't actually match response
  interface MessageActionEvent {
    channel: string;
    // @ts-ignore PubNub type doesn't actually match response
    event: "added" | "removed";
    data: MessageAction;
  }
}

type TimeoutPresenceEvent = Omit<LeavePresenceEvent, "action"> & {
  action: "timeout";
};

type IntervalPresenceEventWithTimeout = IntervalPresenceEvent & {
  timeout: IntervalPresenceEvent["leave"];
};

// NOTE: timeout action missing in types https://www.pubnub.com/docs/presence/presence-events
type PresenceEvent =
  | AnyPresenceEvent
  | TimeoutPresenceEvent
  | IntervalPresenceEventWithTimeout;

export const usePresenceListener = ({
  userInfoRef,
  presentUserIdsRef,
}: {
  userInfoRef: React.MutableRefObject<PresentUsers>;
  presentUserIdsRef: React.MutableRefObject<PresentUserIds>;
}) => {
  const loggedInUser = useUser();
  const setParticipantCount = useSetParticipantCount();

  // NOTE: We need to keep track of the last occupancy ref. Occasionally there is
  // presence flickering with the current user. To avoid the flickering, we never
  // remove the current user from the list or count
  const lastOccupancyRef = React.useRef(0);

  // Create a debounced version of setParticipantCount to avoid over rendering
  const updateParticipantCount = useMemo(
    () => debounce(setParticipantCount, 250, { maxWait: 1000 }),
    [setParticipantCount],
  );

  useEffect(() => {
    // Cancel the debounced function when the view is destroyed
    return () => updateParticipantCount?.cancel();
  }, [updateParticipantCount]);

  const addParticipant = useCallback(
    (
      data: PresentUser | string[],
      /**
       * "state-change" events contain the user details and are triggered
       * regardless of whether that user is currently present. For
       * "state-change" events, avoid modifying a user's 'present' state
       */
      present?: boolean,
    ) => {
      if (data) {
        const usersData = Array.isArray(data) ? data : [data];

        usersData.forEach((unmappedData: PresentUser | string) => {
          const userInfo =
            typeof unmappedData === "string"
              ? {}
              : omit(unmappedData, "userId");
          const userId =
            typeof unmappedData === "string"
              ? unmappedData
              : unmappedData.userId;

          const shouldReadd =
            !isEmpty(userInfo) && presentUserIdsRef.current.contains(userId);
          if (shouldReadd) {
            // NOTE: If already present and we have new state data, we need
            // to delete user before modifying their properties so sort order
            // is handled correctly
            presentUserIdsRef.current.remove(userId);
          }

          userInfoRef.current[userId] = {
            ...(userInfoRef.current[userId] || {}), // preserve any data saved from a "state-change" event
            ...userInfo,
            userId,
          } as PubnubState;

          if (shouldReadd) {
            // Add them back to the collection
            presentUserIdsRef.current.add(userId);
          } else if (present) {
            // If present, add user to present Ids list
            presentUserIdsRef.current.add(userId);

            if (userId === loggedInUser?.uid) {
              // If current user is added ensure the count is accurate
              // in case our flickering workaround added 1
              updateParticipantCount(lastOccupancyRef.current);
            }
          }
        });
      }
    },
    [
      userInfoRef,
      presentUserIdsRef,
      loggedInUser?.uid,
      lastOccupancyRef,
      updateParticipantCount,
    ],
  );

  const removeParticipant = useCallback(
    (data: PresentUser | string[]) => {
      if (data) {
        // Removed is an array of removed userId's
        const removedIds = Array.isArray(data) ? data : [data.userId];

        // Remove all users from current list whose ID's are in the removed list
        removedIds.forEach((id) => {
          // "state-change" events contain the user details and may come just before channel 'join' events
          // so instead of deleting the user info, mark as no longer present.
          if (id !== loggedInUser?.uid) {
            presentUserIdsRef.current.remove(id);
          } else {
            // If we get a leave for the current user, ignore it and update the count with us in it.
            // This works since we always process the occupancy field first before the join and leave fields
            updateParticipantCount(lastOccupancyRef.current + 1);
          }
        });
      }
    },
    [
      loggedInUser?.uid,
      presentUserIdsRef,
      lastOccupancyRef,
      updateParticipantCount,
    ],
  );

  return useMemo(
    (): ListenerParameters => ({
      presence: function (event: PresenceEvent) {
        const occupantUUID = event.uuid;

        if (typeof event.occupancy === "number") {
          lastOccupancyRef.current = event.occupancy;
          updateParticipantCount(event.occupancy);
        }

        // In events with less occupants, 'join' and 'leave' events are triggered
        if (event.action === "join") {
          addParticipant({ userId: occupantUUID, ...event.state }, true);
        } else if (event.action === "leave" || event.action === "timeout") {
          removeParticipant({ userId: occupantUUID, ...event.state });
        } else if (event.action === "interval") {
          // In events with less occupants, 'interval' events are triggered every ~10 secs
          if (event.join?.length) {
            addParticipant(event.join, true);
          }
          if (event.leave?.length) {
            removeParticipant(event.leave);
          }
          if ("timeout" in event && event.timeout?.length) {
            removeParticipant(event.timeout);
          }
        } else if (event.action === "state-change") {
          // add user data, the user may not be currently present in the channel
          addParticipant({ userId: occupantUUID, ...event.state });
        }
      },
    }),
    [addParticipant, removeParticipant, updateParticipantCount],
  );
};
