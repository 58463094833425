import { useCallback } from "react";
import { useLocalStorage } from "react-use";

export const useLocalAnonymousQuestions = (eventId: string) => {
  const [anonymousQuestions, setAnonymousQuestions] = useLocalStorage<
    Record<string, string[]>
  >("anonymousQuestions", {
    [eventId]: [],
  });

  const existsLocalQuestion = useCallback(
    (questionId: string) =>
      !!anonymousQuestions?.[eventId]?.includes(questionId),
    [anonymousQuestions, eventId],
  );

  /**
   * save anonymous question in `localStorage`
   */
  const addLocalQuestion = useCallback(
    (questionId: string) => {
      setAnonymousQuestions({
        ...anonymousQuestions,
        [eventId]: [...(anonymousQuestions?.[eventId] || []), questionId],
      });
    },
    [anonymousQuestions, eventId, setAnonymousQuestions],
  );

  /**
   * remove anonymous question from `localStorage`
   */
  const removeLocalQuestion = useCallback(
    (questionId: string) => {
      setAnonymousQuestions({
        ...anonymousQuestions,
        [eventId]:
          anonymousQuestions?.[eventId]?.filter(
            (question) => question !== questionId,
          ) || [],
      });
    },
    [anonymousQuestions, eventId, setAnonymousQuestions],
  );

  /**
   * remove all questions for current event from `localStorage`
   */
  const removeAllLocalEventQuestions = useCallback(() => {
    setAnonymousQuestions({
      ...anonymousQuestions,
      [eventId]: [],
    });
  }, [anonymousQuestions, eventId, setAnonymousQuestions]);

  /**
   * remove all questions for all events from `localStorage`
   */
  const clearAllLocalQuestions = useCallback(() => {
    setAnonymousQuestions({});
  }, [setAnonymousQuestions]);

  return {
    anonymousQuestions,
    existsLocalQuestion,
    addLocalQuestion,
    removeLocalQuestion,
    removeAllLocalEventQuestions,
    clearAllLocalQuestions,
  };
};
