import React from "react";
import AlertDialogContent, {
  ActionsContainer,
  DEFAULT_ICON_SIZE,
} from "@src/components/dialogs/content/AlertDialogContent";
import Button from "@src/components/buttons/Button";
import { useDialog, DialogContentProps } from "@src/providers/DialogProvider";
import { UserPromotedIcon } from "@src/components/icons";
import { useIsEventLive } from "@src/providers/EventStateProvider";
import { UserRole, UserRoleMap } from "@src/contracts/user/user";

type HostOrPresenterType = UserRole.Organizer | UserRole.Presenter;
export const PromotionTextMapNotLive: Record<HostOrPresenterType, string> = {
  [UserRole.Organizer]: "You're now onstage. Everyone can see and hear you.", // TODO: update copy
  [UserRole.Presenter]: "You're now onstage. Everyone can see and hear you.", // TODO: update copy
};
export const PromotionTextMapLive: Record<HostOrPresenterType, string> = {
  [UserRole.Organizer]:
    "Don't worry, nobody in the audience can see or hear you as you're backstage.", // TODO: update copy
  [UserRole.Presenter]:
    "Don't worry, nobody in the audience can see or hear you as you're backstage. The host will invite you onstage when ready.",
};

export interface Props extends DialogContentProps {
  role: HostOrPresenterType;
  onCancel: () => void;
  onConfirm: () => void;
}

function PromotedConfirmation({ role, onCancel, onConfirm, dialogId }: Props) {
  const { hideDialog } = useDialog();
  const isEventLive = useIsEventLive();

  const handleClose = () => hideDialog(dialogId as string);

  return (
    <AlertDialogContent
      hideAction
      onClose={handleClose}
      title={`You've been promoted to a ${UserRoleMap[role]}.`}
      icon={
        <UserPromotedIcon
          data-testid="user-promoted-dialog-icon"
          width={DEFAULT_ICON_SIZE}
          height={DEFAULT_ICON_SIZE}
        />
      }
      body={
        isEventLive ? PromotionTextMapLive[role] : PromotionTextMapNotLive[role]
      }
      footer={
        <ActionsContainer>
          <Button
            variant="outlined"
            onClick={() => {
              onCancel();
              handleClose();
            }}
          >
            Decline
          </Button>
          <Button
            onClick={() => {
              onConfirm();
              handleClose();
            }}
          >
            Accept
          </Button>
        </ActionsContainer>
      }
    />
  );
}

export default React.memo(PromotedConfirmation);
