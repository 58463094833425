import React, { useMemo } from "react";

import { SettingsSectionProps } from "../SideBar/SettingsSection";
import { useNetworkingHub } from "../../providers/NetworkingHubProvider";
import { useEvent } from "../../providers/EventProvider";
import { useUser } from "../../providers/UserProvider";
import { EventSettings } from "./EventSettings";
import { NetworkingHubSettings } from "./networkingHub";

export const useSettingsTabController = () => {
  const user = useUser();
  const { data: networkingHub } = useNetworkingHub();
  const { data: event } = useEvent();

  const sections = useMemo<SettingsSectionProps[]>(
    () => [
      ...(!networkingHub
        ? []
        : [
            {
              title: "Hub Settings",
              content: (
                <NetworkingHubSettings
                  user={user}
                  networkingHub={networkingHub}
                  eventId={event?.uid}
                />
              ),
            },
          ]),
      // don't include networking hub yet since we don't have supported settings
      ...(!event || networkingHub
        ? []
        : [
            {
              title: "Event Settings",
              content: <EventSettings user={user} event={event} />,
            },
          ]),
    ],
    [event, networkingHub, user],
  );

  return { sections };
};
