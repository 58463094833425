import React, {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import invariant from "tiny-invariant";
import { toast } from "react-toastify";
import { useSocketClient } from "../../components/Presence/SocketClientProvider";
import { EventStatus } from "../../contracts/event/event";
import { extractUidFromUserId } from "../../helpers/utils";
import { useUser } from "../UserProvider";
import {
  QuestionAnswerEventInput,
  QuestionApproveEventInput,
  QuestionCreateInput,
  QuestionEditEventInput,
  UserQuestion,
} from "./types";
import { useCreateEventQuestion } from "./hooks/useCreateEventQuestion";
import { useEnableAnonymousQna } from "./useEnableAnonymousQna";
import { useEnableApproveQna } from "./useEnableApproveQna";
import { useLocalAnonymousQuestions } from "./useLocalAnonymousQuestions";
import { useDeleteEventQuestion } from "./hooks/useDeleteEventQuestion";
import { useVoteEventQuestion } from "./hooks/useVoteEventQuestion";
import { useEditEventQuestion } from "./hooks/useEditEventQuestion";
import { useAnswerEventQuestion } from "./hooks/useAnswerEventQuestion";
import { useApproveEventQuestion } from "./hooks/useApproveEventQuestion";
import { useListEventQuestions } from "./hooks/useListEventQuestions";
import { useUnmount } from "react-use";
import { createQuestionsQueryKey } from "./helpers";
import { useQueryClient } from "react-query";

export interface QuestionContextValue {
  handleCreateEventQuestion: (data: QuestionCreateInput) => Promise<unknown>;
  handleEditEventQuestion: (data: QuestionEditEventInput) => Promise<unknown>;
  handleListEventQuestions: () => Promise<UserQuestion[]>;
  /**
   * Leave answer and answered empty for toggling behavior
   */
  handleAnswerEventQuestion: (
    data: QuestionAnswerEventInput,
  ) => Promise<unknown>;
  handleDeleteEventQuestion: (questionId: string) => Promise<unknown>;
  handleVoteEventQuestion: (questionId: string) => Promise<unknown>;
  handleApproveEventQuestion: (
    data: QuestionApproveEventInput,
  ) => Promise<unknown>;
  handleEnableAnonymousQna: (e: any) => Promise<EventStatus>;
  handleEnableApproveQna: (e: any) => Promise<EventStatus>;
  questions: UserQuestion[];
  isLoading: boolean;
  isError: boolean;
  isAnonymousQnaEnabled: boolean;
  isApproveQnaEnabled: boolean;
}

export const QuestionContext = createContext<QuestionContextValue | null>(null);

export const useQuestionContext = () => {
  const ctx = useContext(QuestionContext);

  invariant(ctx, "useQuestionContext used outside of QuestionContext");

  return ctx;
};

interface Props {
  /**
   * The current Event or Networking Hub ID
   */
  eventId: string;

  /**
   * The current circle ID if in a circle
   */
  circleId?: string;

  /**
   * The current user ID
   */
  userId: string;
}

export const QandAProvider: React.FC<Props> = memo(
  ({ children, eventId, circleId, userId }) => {
    const client = useSocketClient();
    const user = useUser();
    const queryClient = useQueryClient();
    userId = extractUidFromUserId(userId);

    const { existsLocalQuestion, addLocalQuestion, removeLocalQuestion } =
      useLocalAnonymousQuestions(eventId);

    const isOwner = useCallback(
      (question: UserQuestion) =>
        question.owner.userId === userId || existsLocalQuestion(question.id),
      [existsLocalQuestion, userId],
    );

    const { isAnonymousQnaEnabled, handleEnableAnonymousQna } =
      useEnableAnonymousQna(eventId || "", {
        onSuccess: (status, enabled) => {
          const toastMessage = enabled
            ? "Attendees can now submit questions anonymously"
            : "Anonymous questions have now been turned off";
          toast.success(toastMessage);
        },
        onError: () => {
          toast.error("There was an error toggling anonymous questions");
        },
      });

    const { isApproveQnaEnabled, handleEnableApproveQna } = useEnableApproveQna(
      eventId || "",
      {
        onSuccess: (status, enabled) => {
          const toastMessage = enabled
            ? "All questions must now first be approved by hosts"
            : "Approving questions has now been turned off";
          toast.success(toastMessage);
        },
        onError: () => {
          toast.error("There was an error toggling approving questions");
        },
      },
    );

    const {
      mutation: { mutateAsync: onCreateEventQuestion },
      handleQuestionCreated,
    } = useCreateEventQuestion({
      client,
      isOwner,
      addLocalQuestion,
      eventId,
      circleId,
      user,
      isApproveQnaEnabled,
    });

    const {
      mutation: { mutateAsync: onEditEventQuestion },
      handleQuestionEdited,
    } = useEditEventQuestion({ client, eventId, circleId });

    const {
      mutation: { mutateAsync: onVoteEventQuestion },
      handleQuestionVotesChanged,
    } = useVoteEventQuestion({ client, eventId, circleId });

    const {
      mutation: { mutateAsync: onAnswerEventQuestion },
      handleQuestionAnswered,
    } = useAnswerEventQuestion({ client, eventId, circleId });

    const {
      mutation: { mutateAsync: onApproveEventQuestion },
      handleQuestionApproved,
    } = useApproveEventQuestion({ client, eventId, circleId });

    const {
      mutation: { mutateAsync: onDeleteEventQuestion },
      handleQuestionDeleted,
    } = useDeleteEventQuestion({
      client,
      eventId,
      circleId,
      removeLocalQuestion,
    });

    const {
      query: { data: questions, isError },
      mutation: { mutateAsync: onListEventQuestions },
    } = useListEventQuestions({ client, eventId, circleId, user, isOwner });

    useEffect(() => {
      client.on("question-answered", handleQuestionAnswered);
      client.on("question-created", handleQuestionCreated);
      client.on("question-deleted", handleQuestionDeleted);
      client.on("question-edited", handleQuestionEdited);
      client.on("question-votes-changed", handleQuestionVotesChanged);
      client.on("question-approved", handleQuestionApproved);

      return () => {
        client.off("question-answered", handleQuestionAnswered);
        client.off("question-created", handleQuestionCreated);
        client.off("question-deleted", handleQuestionDeleted);
        client.off("question-edited", handleQuestionEdited);
        client.off("question-votes-changed", handleQuestionVotesChanged);
        client.off("question-approved", handleQuestionApproved);
      };
    }, [
      client,
      handleQuestionAnswered,
      handleQuestionApproved,
      handleQuestionCreated,
      handleQuestionDeleted,
      handleQuestionEdited,
      handleQuestionVotesChanged,
    ]);

    useUnmount(() => {
      // reset queries when switching between hubs/events
      queryClient.resetQueries(createQuestionsQueryKey(eventId, circleId));
    });

    const contextValue = useMemo(
      () => ({
        handleDeleteEventQuestion: onDeleteEventQuestion,
        handleCreateEventQuestion: onCreateEventQuestion,
        handleEditEventQuestion: onEditEventQuestion,
        handleListEventQuestions: onListEventQuestions,
        handleAnswerEventQuestion: onAnswerEventQuestion,
        handleVoteEventQuestion: onVoteEventQuestion,
        handleApproveEventQuestion: onApproveEventQuestion,
        handleEnableApproveQna,
        handleEnableAnonymousQna,
        isApproveQnaEnabled: !!isApproveQnaEnabled,
        isAnonymousQnaEnabled: !!isAnonymousQnaEnabled,
        questions: questions || [],
        isLoading: !questions, // manually calculate isLoading since isFetching is not updating properly
        isError,
      }),
      [
        onAnswerEventQuestion,
        onCreateEventQuestion,
        onDeleteEventQuestion,
        onEditEventQuestion,
        onListEventQuestions,
        onVoteEventQuestion,
        onApproveEventQuestion,
        handleEnableApproveQna,
        handleEnableAnonymousQna,
        questions,
        isError,
        isApproveQnaEnabled,
        isAnonymousQnaEnabled,
      ],
    );

    return (
      <QuestionContext.Provider value={contextValue}>
        {children}
      </QuestionContext.Provider>
    );
  },
);

export const QuestionConsumer = ({
  children,
}: {
  children: (value: QuestionContextValue) => JSX.Element;
}) => {
  const ctx = useQuestionContext();
  return children(ctx);
};

export * from "./types";
export * from "./helpers";
