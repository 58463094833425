import React from "react";
import Button, { Props } from "./Button";

interface ContainedButtonProps extends Props {
  backgroundColor?: string;
  textColor?: string;
}

const ContainedButton = (props: ContainedButtonProps) => {
  const { backgroundColor, textColor, sx, children, ...buttonProps } = props;
  return (
    <Button
      {...buttonProps}
      variant="contained"
      loadingPosition="start"
      sx={{
        fontWeight: 500,
        color: textColor || "common.white",
        // this is so its the same height as the outline button - border-box didn't seem to fix :thinking:
        border: `1px solid ${backgroundColor}`,
        backgroundColor: backgroundColor || "primary.main",
        "&:hover": {
          color: textColor || "common.white",
          backgroundColor: backgroundColor || "primary.main",
          boxShadow: backgroundColor
            ? `0 8px 25px -8px ${backgroundColor}`
            : undefined,
        },
        "&:disabled": {
          color: textColor || "common.white",
          backgroundColor: backgroundColor || "primary.main",
        },
        ...props.sx,
      }}
    >
      {children}
    </Button>
  );
};
export default ContainedButton;
