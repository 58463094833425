/** @jsxImportSource @emotion/react */

import { useRouteParams } from "@src/hooks/useRouteParams";
import { useNetworkingHub } from "@src/providers/NetworkingHubProvider";
import React, { useEffect, useState } from "react";
import { UserRole } from "../../../contracts/user/user";

import { EventStreamingStatus } from "../../../providers/EventStateProvider";
import { EventStatusBadge } from "../../../providers/EventStatusBadge";
import { useUser } from "../../../providers/UserProvider";
import { BaseButton } from "../../buttons/BaseButton";
import EndNetworkingButton from "./EndNetworkingButton";
import { HeaderLayout } from "./HeaderLayout";

interface NetworkingHubHeaderProps {
  onStopNetworking?: (returnToEvent: boolean) => void;
  onReturnToEvent?: () => void;
  isNetworkingActive: boolean;
}

export const NetworkingHubHeader: React.FC<NetworkingHubHeaderProps> = ({
  onReturnToEvent,
  onStopNetworking,
  isNetworkingActive,
}) => {
  const { eventId } = useRouteParams();
  const { data: networkingHub } = useNetworkingHub();

  const handleStopNetworking = (returnToEvent: boolean) => {
    setLoading(true);
    onStopNetworking?.(returnToEvent);
  };

  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    if (!isNetworkingActive) {
      setLoading(false);
    }
  }, [isNetworkingActive]);

  const user = useUser();
  // TODO uid or originalId
  const isHost =
    networkingHub?.organizers?.includes(user.email) ||
    networkingHub?.organizers?.includes(user.uid) ||
    networkingHub?.organizers?.includes(user.originalId) ||
    user.userRole === UserRole.Organizer;

  const returnToEventButton = onReturnToEvent ? (
    <BaseButton color="secondary" onClick={onReturnToEvent}>
      Return to Virtual Stage
    </BaseButton>
  ) : null;

  if (isHost && isNetworkingActive && eventId) {
    return (
      <HeaderLayout
        sticky
        badge={<EventStatusBadge status={EventStreamingStatus.Networking} />}
        clock={null}
        name={networkingHub?.friendlyName as string}
        actions={
          <>
            {returnToEventButton}
            <EndNetworkingButton
              buttonLocationId="networking-hub-header"
              loading={isLoading}
              onConfirm={handleStopNetworking}
              returnToEventId={eventId}
            />
          </>
        }
      />
    );
  }

  if (!isNetworkingActive) {
    return (
      <HeaderLayout
        sticky
        badge={<EventStatusBadge status={EventStreamingStatus.Networking} />}
        clock={null}
        name={networkingHub?.friendlyName as string}
        actions={returnToEventButton}
      />
    );
  }

  return null;
};
