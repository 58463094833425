import { Box, Typography } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { PollResultsType } from "../../providers/polls/PollProvider";
import TotalChip from "./TotalChip";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";

const useLatest = <T,>(value: T) => {
  const ref = useRef(value);
  ref.current = value;
  return ref;
};

interface PollResultsProps {
  options: { name: string; votes: number }[];
  resultsType: PollResultsType;
}

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number },
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          sx={{ height: "8px", borderRadius: "4px" }}
          variant="determinate"
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35, display: "flex", justifyContent: "center" }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const mapOptionsToData = (options: { name: string; votes: number }[]) =>
  options.map((option) => ({
    name: option.name,
    value: option.votes,
  }));

export const PollListItemResults = ({
  options,
  resultsType,
}: PollResultsProps) => {
  const [data, setData] = useState(mapOptionsToData(options));
  const lastData = useLatest(data);

  const totalVotes = useMemo(
    () => options.reduce((total, option) => total + option.votes, 0),
    [options],
  );

  useEffect(() => {
    const changed = options.some((value, index) => {
      const prev = lastData.current[index];
      return value.name !== prev.name || value.votes !== prev.value;
    });
    if (changed) {
      setData(mapOptionsToData(options));
    }
  }, [lastData, options]);

  return (
    <>
      <Box sx={{ mb: 2, mt: 2 }}>
        {data.map((item) => (
          <Box key={item.name} sx={{ overflowWrap: "break-word", mb: 1 }}>
            <Typography sx={{ mb: 0.5 }}>{item.name}</Typography>
            <LinearProgressWithLabel
              value={totalVotes ? (item.value / totalVotes) * 100 : 0}
            />
          </Box>
        ))}
      </Box>
      {resultsType === PollResultsType.VOTES && (
        <TotalChip label={`${totalVotes} Total Votes`} />
      )}
    </>
  );
};
