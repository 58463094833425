import { useQuery } from "react-query";
import { Theme } from "@src/contracts/customization/theme";
import { CompanyApi } from "@src/api/company-api";
import { EventApi } from "@src/api/event-api";
import { useRouteParams } from "@src/hooks/useRouteParams";

import { useNetworkingHub } from "../NetworkingHubProvider";

import { createCompanyThemQueryKey, createEventThemQueryKey } from "./common";

/**
 * Queries for the companies custom theme if available.
 *
 * _NOTE_: The theme is found using the following:
 * - If on an event route, will use the event custom theme if available
 * - If on an a hub route with an event linked, will return event theme
 * - If on a hub route without a linked event, will return company theme
 * - If none of the above apply, will return the default theme
 */
export function useCustomTheme() {
  const { eventId } = useRouteParams();

  const eventThemeQuery = useQuery(
    createEventThemQueryKey(eventId),
    () => EventApi.GetTheme(eventId as string),
    {
      enabled: !!eventId,
      staleTime: 60 * 1000,
    },
  );

  // if we have an event take the event theme over the hub theme
  const networkingHubQuery = useNetworkingHub(null, { enabled: !eventId });

  const isFetchingEventOrHub =
    (!eventThemeQuery.data && eventThemeQuery.isLoading) ||
    (!networkingHubQuery.data && networkingHubQuery.isLoading);

  const companyThemeQuery = useQuery<Theme>(
    createCompanyThemQueryKey(networkingHubQuery?.data?.company),
    () => CompanyApi.GetTheme(networkingHubQuery.data?.company as string),
    {
      enabled:
        !isFetchingEventOrHub &&
        !eventThemeQuery.data &&
        !!networkingHubQuery.data?.company,
      staleTime: 60 * 1000,
    },
  );

  const isFetchingThemes =
    isFetchingEventOrHub ||
    (!companyThemeQuery.data && companyThemeQuery.isLoading);

  const defaultThemeQuery = useQuery<Theme>(
    ["DefaultTheme"],
    () => CompanyApi.GetDefaultTheme(),
    {
      enabled:
        !isFetchingThemes && !(eventThemeQuery.data || companyThemeQuery.data),
    },
  );

  // We can consider the theme to be done fetching when the following is true:
  // 1. event query is fetched and has data
  // 2. event query has fetched, no data, company query has fetched
  const isFetched =
    (eventThemeQuery.isFetched && eventThemeQuery.data) ||
    (companyThemeQuery.isFetched && companyThemeQuery.data) ||
    defaultThemeQuery.isFetched;
  const isLoading =
    eventThemeQuery.isLoading ||
    networkingHubQuery.isLoading ||
    companyThemeQuery.isLoading ||
    defaultThemeQuery.isLoading;

  const data: Theme = (eventThemeQuery.data ||
    companyThemeQuery.data ||
    defaultThemeQuery.data) as Theme;

  return {
    data,
    isLoading,
    isFetched,
  };
}
