import { DialogProps } from "@mui/material/Dialog";
import { ReactNode, createContext } from "react";

/**
 * - `low` - Used mainly for dialogs which don't affect the user experience
 * - `normal` - Used for most dialogs which could affect the user experience
 * - `high` - Used for dialogs which require the user's attention (creating, deleting, updating...)
 * - `critical` - Always prioritize and show first. Used mainly for critical errors and failures.
 */
export type DialogPriority = "low" | "normal" | "high" | "critical";

export const DialogPriorityMap: Record<DialogPriority, number> = {
  low: 0,
  normal: 1,
  high: 2,
  critical: 3,
};

export interface DialogQueueItem extends Partial<DialogProps> {
  /**
   * The id of the dialog. Used to hide specific dialogs
   */
  id?: string;
  /**
   * The unique id of each dialog used for ordering
   */
  internalId: number;
  /**
   * Used to determine the order of the dialogs. Dialogs with higher priority come first (Defaults to `normal`).
   */
  priority: DialogPriority;
  /**
   * The content to be rendered by the dialog
   */
  content: ReactNode;
  /**
   * Prevents the dialog from being dismissed by clicking outside of it or using the `Esc` key (Defaults to `false`).
   * Useful for cases where the dialog can be dismissed accidentally.
   */
  disableOutsideClick?: boolean;
  /**
   * hides the `x` close button at the top of the dialog.
   * Useful for cases where an action is required to close the dialog.
   */
  hideCloseIcon?: boolean;
}

/**
 * Options used to customize the look and behavior of the main dialog for each individual `DialogQueueItem`
 */
export interface OpenDialogOptions
  extends Partial<Omit<DialogQueueItem, "id" | "internalId" | "content">> {}

export interface ModalProviderProps {
  /**
   * Whether there is any dialog currently open
   */
  isOpen: boolean;
  /**
   * The dialog which is currently open and being displayed
   *
   * NOTE: The current dialog should never be modified directly.
   */
  currentDialog?: DialogQueueItem;
  /**
   * All the dialogs currently in queue including the dialog currently open.
   * Dialogs are sorted by increasing order of priority (Defaults to `999`).
   *
   * NOTE: The dialog queue should never be modified directly.
   */
  dialogQueue: DialogQueueItem[];
  /**
   * @param id The id of the dialog (required for closing the dialog)
   * @param content The content to be rendered by the dialog
   * @param options options to provide for the rendered dialog (optional)
   * @param [options.priority] The dialogs are sorted in increasing order of priority. Setting this to lower will prioritize showing the dialog.
   */
  openDialog: (
    id: string,
    content: ReactNode,
    options?: OpenDialogOptions,
  ) => string;
  hideDialog: (id: string) => void;
}

const ModalContext = createContext<ModalProviderProps>({
  isOpen: false,
  currentDialog: undefined,
  dialogQueue: [],
  openDialog: () => "",
  hideDialog: () => {},
});

export default ModalContext;
