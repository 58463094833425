import React, { memo } from "react";

import { Chip, styled } from "@mui/material";

const Container = styled("div")(({ theme: { spacing } }) => ({
  textAlign: "center",
  marginBottom: spacing(1),
}));

const ChipStyled = styled(Chip)(({ theme: { palette } }) => ({
  backgroundColor: palette.secondary.main,
  color: palette.common.white,
  fontSize: "0.7rem",
  fontWeight: 600,
}));

const TotalChip = ({ label }: { label: string }) => {
  return (
    <Container>
      <ChipStyled size="small" label={label} />
    </Container>
  );
};

export default memo(TotalChip);
