import React from "react";
import { styled, BoxProps, IconButton, Box } from "@mui/material";
import ArrowDownward from "@mui/icons-material/ArrowDownward";

import useAutoScroll from "@src/components/AutoScroll/useAutoScroll";

const Container = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  flex: 1,
  minHeight: 0,
  minWidth: 0,
}));

const ListContainer = styled(Box)(({ theme }) => ({
  overflow: "auto",
}));

const ScrollToBottomButton = styled(IconButton)(
  ({ theme: { spacing, palette, shadows } }) => ({
    position: "absolute",
    alignSelf: "center",
    backgroundColor: palette.common.white,
    color: palette.text.primary,
    boxShadow: shadows[1],
    bottom: spacing(1),
    ":hover": {
      backgroundColor: palette.common.white,
    },
  }),
);

interface Props extends BoxProps {
  /**
   * Styles to be applied to the list component
   */
  listSx?: BoxProps["sx"];
  /**
   * Classes to be applied to the list component
   */
  listClassName?: string;
  /**
   * Whether to show the scroll to end button, defaults to true
   */
  showScrollToEndButton?: boolean;
  /**
   * The distance in pixels from the bottom of the list used to
   * determine when auto scrolling is active, defaults to `50`
   */
  threshold?: number;
}

/**
 * Component which will auto-scroll its children when they update.
 *
 * Optionally provide props to control the threshold at which auto-scroll
 * is applied, the direction, whether to show a button to jump to bottom.
 */
function AutoScroll({
  children,
  listSx,
  threshold = 50,
  showScrollToEndButton = true,
  ...props
}: Props) {
  const {
    ref,
    scrollToEnd,
    isActive: isAutoScrollActive,
  } = useAutoScroll<HTMLDivElement>(children, { threshold });

  return (
    <Container data-testid="auto-scroll-container" {...props}>
      <ListContainer ref={ref} sx={listSx}>
        {children}
      </ListContainer>
      {!isAutoScrollActive && showScrollToEndButton && (
        <ScrollToBottomButton
          data-testid="scroll-to-end-btn"
          size="small"
          onClick={() => scrollToEnd()}
        >
          <ArrowDownward />
        </ScrollToBottomButton>
      )}
    </Container>
  );
}

export default React.memo(AutoScroll);
