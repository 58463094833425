import { ComponentType, lazy } from "react";
import { isSessionStorageEnabled } from "./browser";

/**
 * Lazy component loader which will attempt to lazy-load a component
 * and retry on errors.
 *
 * Example usage:
 * ```tsx
 * const LazyComponent = React.lazy(() => componentLoader(() => import("../some/lazy/Component")));
 *
 * const Component = () => {
 *   // If lazy loading throws catch w/ an error boundary
 *   return (
 *     <ErrorBoundary>
 *       <React.Suspense fallback={<Loading />}>
 *         <LazyComponent />
 *       </React.Suspense>
 *     </ErrorBoundary>
 *   )
 * }
 * ```
 *
 * Code from: https://medium.com/@botfather/react-loading-chunk-failed-error-88d0bb75b406
 *
 * @param lazyComponent A callback which returns a Promise that resolves to the component
 * @param retries The number of times to retry, defaults to `2`
 * @returns A Promise which resolves to the component if loaded, rejects with an error if retries exhausted
 */
function componentLoader<T extends ComponentType<any>>(
  lazyComponent: () => Promise<{ default: T }>,
  retries = 2,
) {
  return new Promise<{ default: T }>((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retries <= 0) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, retries - 1).then(resolve, reject);
        }, 1000);
      });
  });
}

/**
 * Lazily loads a component while handling errors w/ retries and page reloads.
 *
 * Will attempt to load the component multiple times on failure. If it has not
 * attempted to reload, will trigger a full page refresh to catch instances
 * where the user has an older version of the app cached locally.
 *
 * Example Usage:
 * ```tsx
 * const LazyComponent = lazyLoader(() => import("./MyComponent"), "MyComponent");
 *
 * const Component = () => {
 *   // If lazy loading throws catch w/ an error boundary
 *   return (
 *     <ErrorBoundary>
 *       <React.Suspense fallback={<Loading />}>
 *         <LazyComponent />
 *       </React.Suspense>
 *     </ErrorBoundary>
 *   )
 * }
 * ```
 *
 * Code from: https://www.codemzy.com/blog/fix-chunkloaderror-react
 *
 * @param componentImport A function which returns a Promise that resolves to a component
 * @param name A unique name for the loaded component, used for internally checking reload status
 * @param options Optional properties to disable page refresh and change retry count
 * @returns A Promise which resolves to a component, throws if retries exceeded
 */
export default function lazyLoader<T extends ComponentType<any>>(
  componentImport: () => Promise<{ default: T }>,
  name: string,
  options?: {
    disableRefresh?: boolean;
    retries?: number;
  },
) {
  // @ts-ignore
  return lazy<T>(async (): Promise<{ default: T }> => {
    const key = `sequel:lazy:${name}:refreshed`;
    const hasRefreshed =
      !!options?.disableRefresh ||
      !isSessionStorageEnabled() ||
      window.sessionStorage.getItem(key) === "true";

    try {
      // Attempt to import w/ retries
      const component = await componentLoader(
        componentImport,
        options?.retries,
      );
      isSessionStorageEnabled() && window.sessionStorage.setItem(key, "false");
      return component;
    } catch (err) {
      if (hasRefreshed) throw err;
      // Attempt to refresh the page as the the user could have outdated code
      window.sessionStorage.setItem(key, "true");
      window.location.reload();
      // Return a fake default component to avoid a TypeError since lazy attempts to create a component
      return { default: () => null } as any;
    }
  });
}
