export interface SocketClientOptions {
  /**
   * Flag indicating if connection should automatically be established on instantiation.
   * The default is `true` to match {@link https://socket.io/docs/v3/client-api/ Socket.io}
   */
  autoConnect?: boolean;
}

export abstract class SocketClient implements SocketIOClient.Socket {
  private eventListeners = new Map<string, Set<Function>>();

  /** @inheritdoc */
  connected: boolean = false;

  /*************************
   *** Inherited members ***
   *************************/

  /** @inheritdoc */
  get disconnected(): boolean {
    return !this.connected;
  }

  /** @inheritdoc */
  abstract connect(): SocketIOClient.Socket;

  /** @inheritdoc */
  abstract disconnect(): SocketIOClient.Socket;

  /** @inheritdoc */
  abstract close(): SocketIOClient.Socket;

  /** @inheritdoc */
  on(event: string, callback: Function): SocketIOClient.Emitter {
    const eventListeners = this.eventListeners.get(event);
    // if this event already has event listeners attached => append to set
    if (eventListeners) {
      eventListeners.add(callback);
    } else {
      this.eventListeners.set(event, new Set([callback]));
    }
    return this;
  }

  /** @inheritdoc */
  addEventListener(event: string, callback: Function): SocketIOClient.Emitter {
    return this.on(event, callback);
  }

  /** @inheritdoc */
  off(event: string, callback?: Function): SocketIOClient.Emitter {
    const eventListeners = this.eventListeners.get(event);

    if (callback && eventListeners) {
      eventListeners.delete(callback);
    }
    return this;
  }

  /** @inheritdoc */
  removeListener(event: string, callback?: Function): SocketIOClient.Emitter {
    return this.off(event, callback);
  }

  /** @inheritdoc */
  removeEventListener(
    event: string,
    callback?: Function,
  ): SocketIOClient.Emitter {
    return this.off(event, callback);
  }

  /** @inheritdoc */
  removeAllListeners(): SocketIOClient.Emitter {
    this.eventListeners.clear();
    return this;
  }

  /** @inheritdoc */
  listeners(event: string): Function[] {
    const eventListeners = this.eventListeners.get(event);
    return eventListeners ? Array.from(eventListeners) : [];
  }

  /** @inheritdoc */
  hasListeners(event: string): boolean {
    return this.listeners(event).length > 0;
  }

  /** @inheritdoc */
  abstract emit: (event: string, ...args: any[]) => SocketIOClient.Socket;

  /*****************************
   *** Unimplemented members ***
   *****************************/

  /** @inheritdoc */
  get io(): SocketIOClient.Manager {
    throw new Error("Not implemented.");
  }
  /** @inheritdoc */
  get nsp(): string {
    throw new Error("Not implemented.");
  }
  /** @inheritdoc */
  get id(): string {
    throw new Error("Not implemented.");
  }
  /** @inheritdoc */
  get binaryType(): "blob" | "arraybuffer" {
    throw new Error("Not implemented.");
  }
  /** @inheritdoc */
  once = (event: string, fn: Function): SocketIOClient.Emitter => {
    throw new Error("Method not implemented.");
  };
  /** @inheritdoc */
  open(): SocketIOClient.Socket {
    throw new Error("Method not implemented.");
  }
  /** @inheritdoc */
  send(...args: any[]): SocketIOClient.Socket {
    throw new Error("Method not implemented.");
  }
  /** @inheritdoc */
  compress(compress: boolean): SocketIOClient.Socket {
    throw new Error("Method not implemented.");
  }
}
