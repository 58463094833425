import React from "react";
import { Tooltip, IconButton, IconButtonProps, styled } from "@mui/material";

export interface MenuItemType extends IconButtonProps {
  /**
   * The name for the menu item
   */
  name: string;
  /**
   * The icon to display
   */
  icon: React.ReactNode;
  /**
   * The tooltip message
   */
  tooltip: string;
}

export interface Props {
  /**
   * The list of menu items
   */
  menuItems: MenuItemType[];
}

export const MenuItem = React.memo(function MenuItem({
  name,
  tooltip,
  icon,
  onClick,
  ...restProps
}: MenuItemType) {
  return (
    <Tooltip title={tooltip}>
      <span>
        <IconButton onClick={onClick} size="small" {...restProps}>
          {icon}
        </IconButton>
      </span>
    </Tooltip>
  );
});

const Container = styled("div")(({ theme: { palette, spacing, shape } }) => ({
  display: "flex",
  backgroundColor: palette.background.paper,
  borderRadius: 4,
  padding: spacing(0.5),
  gap: spacing(1),
  boxShadow: "2px 2px 5px 5px rgba(255, 255, 255, 0.80)",
}));

const Menu = (props: Props) => {
  const { menuItems } = props;

  if (!menuItems.length) {
    return null;
  }

  return (
    <Container>
      {menuItems.map((menuItemProps) => (
        <MenuItem key={menuItemProps.name} {...menuItemProps} />
      ))}
    </Container>
  );
};

export default React.memo(Menu);
