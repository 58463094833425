import React, { ReactNode } from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

import Button from "../buttons/Button";

export interface Props extends DialogProps {
  /**
   * The optional title of the dialog
   */
  title?: string;

  /**
   * The optional body of the dialog
   */
  body?: string | ReactNode;

  /**
   * Text for the confirm button, defaults to "Confirm"
   */
  confirmText?: string;

  /**
   * Text for the cancel button, defaults to "Cancel"
   */
  cancelText?: string;

  /**
   * Callback when the user selects the confirm option
   */
  onConfirm: () => void;
}

/**
 * Dialog used for confirmations
 */
const ConfirmationDialog = ({
  title,
  body,
  confirmText = "Confirm",
  cancelText = "Cancel",
  fullWidth = true,
  maxWidth = "xs",
  onConfirm,
  onClose,
  ...restProps
}: Props) => (
  <Dialog
    onClose={onClose}
    aria-labelledby="confirmation-dialog-title"
    aria-describedby="confirmation-dialog-description"
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    {...restProps}
  >
    {!!title && (
      <DialogTitle title={title} id="confirmation-dialog-title">
        {title}
      </DialogTitle>
    )}
    <DialogContent>
      {typeof body === "string" ? (
        <Typography id="confirmation-dialog-description">{body}</Typography>
      ) : (
        body
      )}
    </DialogContent>
    <DialogActions>
      <Button
        variant="text"
        data-testid="confirmation-dialog-cancel"
        onClick={onClose as React.MouseEventHandler<HTMLButtonElement>}
      >
        {cancelText}
      </Button>
      <Button
        data-testid="confirmation-dialog-confirm"
        autoFocus
        onClick={onConfirm}
      >
        {confirmText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default React.memo(ConfirmationDialog);
