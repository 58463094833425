import React from "react";
import WrappedTimeAgo from "react-timeago";

interface Props extends WrappedTimeAgo.ReactTimeagoProps {}

const aMomentAgoFormatter = function formatter(
  value: number,
  unit: WrappedTimeAgo.Unit,
  suffix: WrappedTimeAgo.Suffix,
  epochMilliseconds: number,
  defaultFormatter?: WrappedTimeAgo.Formatter,
) {
  if (unit === "second" && value < 15)
    return suffix === "ago" ? "just now" : "now";
  if (unit === "second")
    return suffix === "ago" ? "a moment ago" : "in a moment";
  return defaultFormatter?.(value, unit, suffix, epochMilliseconds);
};

const TimeAgo = (props: Props) => {
  return (
    <WrappedTimeAgo
      minPeriod={5}
      {...props}
      formatter={props.formatter ?? aMomentAgoFormatter}
    />
  );
};

export default React.memo(TimeAgo);
