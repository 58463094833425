import Pubnub from "pubnub";
import { AnnouncementMessage, PinnedMessage } from "./ChatProvider";

/**
 * Permanently deletes a message from the PubNub channel
 */
export const deleteNestedChannelMessage = async ({
  pubnub,
  channelId,
  message,
  options = {},
}: {
  pubnub: Pubnub;
  channelId: string | null;
  message: PinnedMessage | AnnouncementMessage;
  options?: {
    error?: string;
    onSuccess?: (args?: any) => void;
    onError?: (args?: any) => void;
  };
}) => {
  const { error, onSuccess, onError } = options;

  if (!channelId || !message.timetoken) {
    return;
  }

  try {
    // NOTE: Need to use soft delete so we can trigger subscription events to connected clients
    await pubnub.addMessageAction({
      channel: channelId,
      messageTimetoken: message.timetoken,
      action: {
        type: "deleted",
        value: ".",
      },
    });

    // Then we hard delete to not pollute the channel with unneccesary data
    // https://www.pubnub.com/docs/sdks/javascript/api-reference/storage-and-playback#delete-messages-from-history-with-timetoken
    await pubnub.deleteMessages({
      channel: channelId,
      start: +message.timetoken - 1,
      end: message.timetoken,
    });

    onSuccess?.();
  } catch (err) {
    console.error(error || "Error deleting message", err);
    onError?.();
  }
};
