import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ConfettiExplosion from "react-confetti-explosion";
import { JoinCodeSchema } from "../hooks/useJoinCodeForm";
import { getValidatedResult } from "@src/hooks/useValidatedResult";

import { useTheme } from "@mui/material/styles";

import Button from "@src/components/buttons/Button";
import AddToCalendarButton from "@src/components/Event/EventStage/AddToCalendarButton";
import CheckVerifiedIcon from "@introvoke/react/icons/CheckVerified01";
import RegistrationSidebarView from "./RegistrationSidebarView";
import { useJoinEvent } from "../hooks/useJoinEvent";
import { Routes } from "@src/hooks/useIsRoute";
import { useEventOrNetworkingHub } from "@src/hooks/useEventOrNetworkingHub";

const ContainerWithConfetti = ({
  children,
  ...props
}: React.PropsWithChildren<React.BaseHTMLAttributes<HTMLDivElement>>) => {
  const theme = useTheme();
  return (
    <div {...props}>
      <div className="absolute top-[-20vh] self-center">
        <ConfettiExplosion
          zIndex={10}
          width={Math.min(window.innerWidth, 800)}
          particleCount={100}
          colors={[
            theme.palette.primary.main,
            theme.palette.primary.light,
            theme.palette.primary.dark,
          ]}
        />
      </div>
      {children}
    </div>
  );
};

const RegistrationSuccessView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: eventOrNetworkingHub, isEvent } = useEventOrNetworkingHub();
  const entityName = isEvent ? "event" : "networking hub";
  const redirectUrl =
    eventOrNetworkingHub?.registration?.redirectAfterRegistrationUrl;

  const joinCode = getValidatedResult(
    (location.state as any)?.joinCode,
    JoinCodeSchema,
  );

  const { checkCanJoinEvent, handleJoinEvent } = useJoinEvent();

  useEffect(() => {
    // kick out if trying to access directly instead of being redirected from registration
    if (!joinCode) {
      navigate(
        {
          pathname: Routes.registration.register(
            eventOrNetworkingHub?.uid ?? "",
            isEvent,
          ),
          search: location.search,
        },
        { replace: true },
      );
    }
  }, [eventOrNetworkingHub?.uid, isEvent, joinCode, location.search, navigate]);

  return redirectUrl ? (
    <RegistrationSidebarView
      as={ContainerWithConfetti}
      icon={CheckVerifiedIcon}
      iconColor="success"
      title="Registration confirmed!"
      description={``}
    />
  ) : (
    <RegistrationSidebarView
      as={ContainerWithConfetti}
      icon={CheckVerifiedIcon}
      iconColor="success"
      title="Registration confirmed!"
      description={`Congratulations! You have successfully registered for this ${entityName}. Please check your email for a confirmation message or simply join the ${entityName} by clicking below.`}
      actions={
        <>
          {checkCanJoinEvent(joinCode) && (
            <Button fullWidth onClick={() => handleJoinEvent(joinCode)}>
              Join session
            </Button>
          )}
          <AddToCalendarButton joinCode={joinCode} />
        </>
      }
    />
  );
};

export default RegistrationSuccessView;
