import {
  QueryKey,
  useQueryClient,
  useQuery,
  UseQueryOptions,
} from "react-query";

import { QueryArgs, query as gqlQuery, QueryResponse } from "@src/graphql";
import { useLatestCallback } from "../../hooks/useLatestCallback";
import { logGqlError } from "../helpers";

export const REFETCH_INTERVAL_MS = 5 * 1000;

export interface UseGqlQueryArgs<TData>
  extends UseQueryOptions<unknown, unknown, TData> {
  /**
   * The query key for the query
   */
  queryKey: QueryKey;
  /**
   * The GQL Query to run for the initial data.
   *
   * Optionally, this can be set to an empty string in favor of the `apiFallbackFn`
   * for subscriptions which don't have queries yet.
   */
  query: QueryArgs["query"];
  /**
   *  The GQL Query variables
   */
  variables: QueryArgs["variables"];
  /**
   * The optional initial data.
   */
  initialData?: TData;
  /**
   * Whether to use polling (used in conjunction with `pollingInterval`).
   * Defaults to `false`
   */
  polling?: boolean;
  /**
   * The value in ms to refetch the query if `polling` is enabled (used in conjunction with `polling`).
   * Defaults to `5000ms`.
   */
  pollingInterval?: number;
  /**
   * Flag that indicates if the query should be enabled.
   * Defaults to `true`
   */
  enabled?: boolean;
  /**
   * Optional API fallback function if the query fails.
   *
   * Optionally, the `query` can be set to an empty string in favor of this `apiFallbackFn`
   * for subscriptions which don't have queries yet.
   */
  apiFallbackFn?: () => Promise<TData>;
  /**
   * A data updater function for modifying the cached data for the
   * provided `queryKey`. The default overrides existing data
   */
  dataUpdater?: (prevData: TData | undefined | null, newData: TData) => TData;
}

/**
 * A wrapper hook around react query that adds support for graphql queries and api polling
 */
export const useGqlQuery = <TData,>(options: UseGqlQueryArgs<TData>) => {
  const {
    queryKey = "",
    query,
    variables,
    enabled = true,
    polling = false,
    pollingInterval = REFETCH_INTERVAL_MS,
    initialData,
    onError: callerOnError,
    apiFallbackFn,
    dataUpdater,
    ...restOptions
  } = options;

  const queryClient = useQueryClient();

  const shouldUseApiFallback = typeof apiFallbackFn === "function";

  const onError = useLatestCallback(
    (error: any, tag: "api" | "gql" = "api") => {
      const source = tag === "gql" ? "GraphQL Query" : "API";

      logGqlError(`Received ${source} error for ${queryKey}`, error);

      callerOnError?.(error);
    },
  );

  const queryData = useQuery<unknown, unknown, TData>(
    queryKey,
    async () => {
      let data = null;

      if (!query) {
        // if no query but `apiFallbackFn` available, use `apiFallbackFn` or early return `null`
        return shouldUseApiFallback ? await apiFallbackFn() : null;
      }

      try {
        // note that this throws the response for most errors instead of returning them
        const res = await gqlQuery<TData>({
          query,
          variables,
        });

        data = res.data;
      } catch (err) {
        // the err here is not a JS `Error` but a `QueryResponse` object instead
        const { errors } = err as QueryResponse<TData>;
        onError(errors, "gql"); // manually call `onError` with the errors received from the GQL query response

        // If we have an error fetching the query => use api fallback
        // If the api fallback returns an error, it will be handled automatically by the `onError` passed to the `useQuery`
        data = shouldUseApiFallback ? await apiFallbackFn() : null;
      }

      const prevData = queryClient.getQueryData<TData | null>(queryKey);
      return dataUpdater && data ? dataUpdater(prevData, data as any) : data;
    },
    {
      enabled,
      initialData,
      // the gql query errors are manually handled inside the query
      // all other errors are likely API errors
      onError,
      refetchOnWindowFocus: false,
      refetchOnMount: enabled,
      refetchOnReconnect: enabled,
      refetchIntervalInBackground: enabled,
      refetchInterval: polling && pollingInterval,
      ...restOptions,
    },
  );

  return queryData;
};
