import { transformImageUrl } from "@src/helpers/image";
import React, { useMemo } from "react";
import { SocketUserMeta } from "../../Presence/IntrovokePresence";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  AskerWrapper,
  StyledMuiAvatar,
  StyledMuiIconButton,
  TimeText,
  Title,
  TitleContainer,
  UsernameText,
  Wrapper,
} from "./PollListItemHeader.styles";
import { Box, Menu, MenuItem, Tooltip } from "@mui/material";
import { useDialog } from "@src/providers/DialogProvider";
import { DeletePollDialog } from "../DeletePollDialog";
import { formatDistance } from "date-fns";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { PollStatus } from "@src/providers/polls/PollProvider";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

type PollListItemHeaderProps = {
  id: string;
  name: string;
  isOrganizer: boolean;
  isArchived: boolean;
  isOwner: boolean;
  isOpen: boolean;
  isPending: boolean;
  isClosed: boolean;
  hasVoted: boolean;
  showResults: boolean;
  hideUserInfo: boolean;
  resultsPublished: boolean;
  createdAt: number;
  updatedAt: number;
  owner: SocketUserMeta;
  navigateToTab: (tab: PollStatus) => void;
  onDelete?: (data: { poll: string }) => void;
  archivePoll: () => void;
  openPoll: () => void;
  closePoll: () => void;
  setShowResults: (showResults: boolean) => void;
};

export const PollListItemHeader = ({
  id,
  isOrganizer,
  isOwner,
  isOpen,
  isArchived,
  isPending,
  hasVoted,
  isClosed,
  name,
  owner,
  showResults,
  resultsPublished,
  navigateToTab,
  onDelete,
  archivePoll,
  openPoll,
  closePoll,
  hideUserInfo,
  updatedAt,
  createdAt,
  setShowResults,
}: PollListItemHeaderProps) => {
  const { openDialog } = useDialog();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePollDelete = () => {
    onDelete?.({ poll: id });
  };

  const openDeleteDialog = () => {
    handleClose();
    openDialog(
      "DeletePollDialog",
      <DeletePollDialog onSubmit={handlePollDelete} />,
    );
  };

  const handleShowVoting = () => {
    handleClose();
    setShowResults(false);
  };

  const handleShowResults = () => {
    handleClose();
    setShowResults(true);
  };

  const handleOpenPoll = () => {
    openPoll();
    handleClose();
    navigateToTab(PollStatus.ACTIVE);
  };

  const handleArchivePoll = () => {
    archivePoll();
    handleClose();
    navigateToTab(PollStatus.ARCHIVED);
  };

  const menu = (isOrganizer || isOwner) && (
    <>
      <StyledMuiIconButton
        data-testid="poll-view-header-menu"
        edge="end"
        aria-label="delete"
        onClick={handleClick}
        size="large"
      >
        <MoreHorizIcon />
      </StyledMuiIconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {isOpen && showResults && (isOwner || isOrganizer) && !hasVoted && (
          <MenuItem
            data-testid="poll-view-header-menu-vote"
            onClick={handleShowVoting}
          >
            Vote
          </MenuItem>
        )}
        {!showResults && (isOwner || isOrganizer) && isOpen && (
          <MenuItem
            data-testid="poll-view-header-menu-results"
            onClick={handleShowResults}
          >
            Results
          </MenuItem>
        )}
        {isArchived ? (
          <MenuItem
            data-testid="poll-view-header-menu-unarchive"
            onClick={handleOpenPoll}
          >
            Unarchive
          </MenuItem>
        ) : (
          <MenuItem
            data-testid="poll-view-header-menu-archive"
            onClick={handleArchivePoll}
          >
            Archive
          </MenuItem>
        )}
        {isOpen ? (
          <MenuItem
            data-testid="poll-view-header-menu-close"
            onClick={closePoll}
          >
            Close
          </MenuItem>
        ) : (
          <MenuItem
            data-testid="poll-view-header-menu-open"
            onClick={handleOpenPoll}
          >
            Open
          </MenuItem>
        )}
        <MenuItem
          data-testid="poll-view-header-menu-delete"
          onClick={openDeleteDialog}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );

  const relativeTime: string = useMemo(() => {
    return formatDistance(new Date(updatedAt || createdAt), new Date(), {
      addSuffix: true,
    });
  }, [updatedAt, createdAt]);

  return (
    <>
      {!hideUserInfo && (
        <Wrapper>
          <StyledMuiAvatar
            src={
              hideUserInfo
                ? undefined
                : transformImageUrl({
                    url: owner.avatar,
                    width: 72,
                    height: 72,
                  })
            }
          />
          <AskerWrapper>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <UsernameText noWrap>{owner.username}</UsernameText>
              {resultsPublished &&
                (isOwner || isOrganizer) &&
                (isOpen || isClosed) && (
                  <Tooltip
                    title={
                      "The results of this poll are published and visible to everyone"
                    }
                  >
                    <TaskAltIcon
                      fontSize="small"
                      sx={{ ml: 0.5, color: "success.main" }}
                    />
                  </Tooltip>
                )}
              {isClosed && (isOwner || isOrganizer) && (
                <Tooltip
                  title={
                    "This poll has been closed. Attendees are not able to vote anymore."
                  }
                >
                  <LockOutlinedIcon
                    fontSize="small"
                    sx={{ ml: 0.5, color: "text.secondary" }}
                  />
                </Tooltip>
              )}
            </Box>

            {isPending ? (
              <TimeText
                variant="caption"
                noWrap
                sx={{ display: "flex", alignItems: "center" }}
              >
                <VisibilityOffIcon fontSize="small" sx={{ mr: 0.5 }} />
                visible only to you
              </TimeText>
            ) : (
              <TimeText
                variant="caption"
                noWrap
                sx={{ display: "flex", alignItems: "center" }}
              >
                <AccessTimeIcon fontSize="small" sx={{ mr: 0.5 }} />
                {relativeTime}
              </TimeText>
            )}
          </AskerWrapper>
          {menu}
        </Wrapper>
      )}

      <TitleContainer>
        <Title align="left" variant="h4" sx={{ mb: 0 }}>
          {name}
        </Title>

        {hideUserInfo && menu}
      </TitleContainer>
    </>
  );
};
