import { useDeepCompareEffect, useLatest, usePrevious } from "react-use";
import React, {
  useRef,
  useEffect,
  useCallback,
  memo,
  MutableRefObject,
} from "react";
import videojs, { VideoJsPlayer } from "video.js";
import {
  VideoJsPlayerProps,
  VideoPlayerCallbacks,
  VideoPlayerInstance,
} from "./types";
import { StyledVideContainer, StyledVideo } from "./VideoJs.styles";
import { logError } from "@src/helpers/logging";
import {
  useIsEventLive,
  useIsReplayEnabled,
} from "@src/providers/EventStateProvider";
import { EventApi } from "@src/api/event-api";

const { registerIVSTech, registerIVSQualityPlugin } = window as any;
// Register IVS as a tech for videojs
registerIVSTech(videojs);
registerIVSQualityPlugin(videojs);

export enum VideoJsTech {
  /**
   * Amazon IVS tech for VideoJS
   */
  IVS = "AmazonIVS",
  /**
   * Native HTML video player tech
   */
  HTML = "html5",
}

const DEFAULT_WATCH_INTERVAL_MS = 10000; // 10 second ticks on watch interval

const initializePlayerEvents = ({
  player,
  callbackRefs,
  videoPlayerStateRef,
}: {
  player: VideoPlayerInstance;
  callbackRefs: MutableRefObject<VideoPlayerCallbacks | undefined>;
  videoPlayerStateRef: MutableRefObject<VideoPlayerInternalState>;
}) => {
  player.on(
    "error",
    () => callbackRefs.current?.onError?.(player.error() as MediaError, player),
  );
  player.on("ended", (event: EventTarget) => {
    videoPlayerStateRef.current.endedFired = true;
    callbackRefs.current?.onEnded?.(event, player);
  });
  player.on(
    "play",
    (event: EventTarget) =>
      callbackRefs.current?.onPlay?.(event, player, player.currentTime()),
  );
  player.on("pause", (event: EventTarget) => {
    const { timeout } = videoPlayerStateRef.current;

    if (timeout) {
      clearTimeout(timeout);
      videoPlayerStateRef.current.timeout = 0;
    }

    callbackRefs.current?.onPause?.(event, player, player.currentTime());
  });
  player.on(
    "waiting",
    (event: EventTarget) =>
      callbackRefs.current?.onWaiting?.(event, player, player.currentTime()),
  );
  player.on("playing", (event: EventTarget) => {
    callbackRefs.current?.onPlaying?.(event, player, player.currentTime());
    // Trigger a volume event to catch a muted state while playing muted
    callbackRefs.current?.onVolumeChange?.(
      event,
      player,
      player.volume(),
      player.muted(),
    );
  });
  player.on(
    "loadeddata",
    (event: EventTarget) => callbackRefs.current?.onLoadedData?.(event, player),
  );
  player.on(
    "loadedmetadata",
    (event: EventTarget) =>
      callbackRefs.current?.onLoadedMetadata?.(event, player),
  );
  player.on(
    "volumechange",
    (event: EventTarget) =>
      callbackRefs.current?.onVolumeChange?.(
        event,
        player,
        player.volume(),
        player.muted(),
      ),
  );

  // Keep track of start/stop times for seeking
  let currentTime = 0;
  let previousTime = 0;
  let startTime = 0;

  player.on("timeupdate", (event: EventTarget) => {
    let tempTime = player.currentTime();
    callbackRefs.current?.onTimeUpdate?.(event, player, tempTime);
    // Adjust our current timing to get correct seeked start/stop times
    previousTime = currentTime;
    currentTime = tempTime;
    if (previousTime < currentTime) {
      startTime = previousTime;
      previousTime = currentTime;
    }

    videoPlayerStateRef.current.timeout > 0 &&
      window.clearTimeout(videoPlayerStateRef.current.timeout);

    if (
      videoPlayerStateRef.current.isEventLive ||
      videoPlayerStateRef.current.replayEnabled ||
      player.paused()
    ) {
      return;
    }

    videoPlayerStateRef.current.timeout = window.setTimeout(() => {
      if (
        !videoPlayerStateRef.current.endedFired &&
        !videoPlayerStateRef.current.isEventLive &&
        !videoPlayerStateRef.current.replayEnabled
      ) {
        logError(
          "video.js -> ended did not fire. waited 4s after last timeupdate event.",
        );
        callbackRefs.current?.onEnded?.(event, player);
      }
    }, 4000);
  });
  player.on("seeking", (event: EventTarget) => {
    // Ignore these events as they can be fired when seeking, we only care about when
    // the seek ends, so we update the start/end times for the seeked
    player.off("timeupdate", () => {});
    player.one("seeked", () => {});
    callbackRefs.current?.onSeeking?.(event, player, player.currentTime());
  });
  player.on("seeked", (event: EventTarget) => {
    callbackRefs.current?.onSeeked?.(
      event,
      player,
      startTime,
      player.currentTime(),
    );
  });
  player.on("enterpictureinpicture", (event: EventTarget) => {
    callbackRefs.current?.onPictureInPictureChange?.(event, player, true);
  });
  player.on("leavepictureinpicture", (event: EventTarget) => {
    callbackRefs.current?.onPictureInPictureChange?.(event, player, false);
  });
  player.on("dispose", (event: EventTarget) => {
    callbackRefs.current?.onDestroy?.(event);
  });
};

const initializeWatchInterval = ({
  player,
  callbackRefs,
}: {
  player: VideoPlayerInstance;
  callbackRefs: MutableRefObject<VideoPlayerCallbacks | undefined>;
}) => {
  let previousTime: number;
  let secondsWatched: number;
  let totalTime = 0;
  player.on("seeked", () => {
    previousTime = player.currentTime();
  });
  return setInterval(() => {
    if (!player) return;
    if (!previousTime) {
      previousTime = player.currentTime();
    }

    if (!secondsWatched) {
      secondsWatched = previousTime;
    }

    secondsWatched += player.currentTime() - previousTime;
    previousTime = player.currentTime();
    if (secondsWatched > 60) {
      totalTime += secondsWatched;
      secondsWatched = secondsWatched % 60;
      callbackRefs.current?.onWatchInterval?.(player, totalTime);
    }
  }, DEFAULT_WATCH_INTERVAL_MS);
};

interface VideoPlayerInternalState {
  timeout: number;
  endedFired: boolean;
  isEventLive: boolean;
  replayEnabled: boolean;
}

/**
 * React component implementation of VideoJS.
 *
 * _DO NOT_ use this component directly, use the `VideoPlayer` component throughout the app.
 *
 * This component should only be used by player components who wish to implement specific tech.
 */
const VideoJs = ({
  watchIntervalSeconds,
  options,
  enableContextMenu,
  children,
  containerProps = {},
  ...callbacks
}: VideoJsPlayerProps) => {
  const isEventLive = useIsEventLive();
  const replayEnabled = useIsReplayEnabled();
  const previousOptions = usePrevious(options);
  const callbackRefs = useLatest(callbacks);
  const playerRef = useRef<VideoJsPlayer>();
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoPlayerStateRef = useRef<VideoPlayerInternalState>({
    timeout: -1,
    endedFired: false,
    isEventLive,
    replayEnabled: false,
  });

  useEffect(() => {
    videoPlayerStateRef.current.isEventLive = isEventLive;
    videoPlayerStateRef.current.replayEnabled = replayEnabled;
  }, [isEventLive, replayEnabled]);

  const watchIntervalRef = useRef<ReturnType<typeof setInterval> | undefined>();

  const initializePlayer = useCallback(() => {
    const player = playerRef.current as VideoPlayerInstance;

    videoPlayerStateRef.current.endedFired = false;
    videoPlayerStateRef.current.timeout = -1;

    initializePlayerEvents({
      player,
      callbackRefs,
      videoPlayerStateRef,
    });

    watchIntervalRef.current = initializeWatchInterval({
      player,
      callbackRefs,
    });

    callbackRefs.current?.onReady?.(player);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!videoRef.current) return;
    if (!playerRef.current) {
      playerRef.current = videojs(
        videoRef.current,
        {
          ...options,
          controlBar:
            typeof options.controlBar === "boolean"
              ? options.controlBar
              : {
                  ...(options.controlBar ?? {}),
                  volumePanel:
                    typeof options.controlBar?.volumePanel === "boolean"
                      ? options.controlBar.volumePanel
                      : {
                          inline: false,
                          ...(options.controlBar?.volumePanel ?? {}),
                        },
                },
        },
        initializePlayer,
      );
    }
  }, [initializePlayer, options]);

  useEffect(() => {
    return () => {
      playerRef.current?.dispose();
      playerRef.current = undefined;
      typeof watchIntervalRef.current !== "undefined" &&
        clearInterval(watchIntervalRef.current);
    };
  }, []);

  // Update player state
  useDeepCompareEffect(() => {
    const player = playerRef.current;
    if (!player) return;

    // Check the type of source, prefer the sources over src
    if (options?.sources && previousOptions?.sources !== options?.sources) {
      player.src(options.sources);
      player.load();
    } else if (options?.src && previousOptions?.src !== options?.src) {
      player.src(options.src);
      if (options.src?.endsWith(".mp4")) {
        const subtitleUrl = `${options.src}.vtt`;
        fetch(subtitleUrl, { method: "HEAD" })
          .then((response) => {
            if (response.ok) {
              player.addRemoteTextTrack(
                {
                  src: subtitleUrl,
                  kind: "subtitles",
                  srclang: "en",
                  label: "English",
                  default: true,
                },
                true,
              );
              player.textTracks()[0].mode = "hidden";
            } else {
              console.log("Subtitle file does not exist");
            }
          })
          .catch((error) => console.log("Error:", error));
      }
      if (
        options.src?.startsWith("https://replays.introvoke.com") &&
        options.src?.endsWith(".m3u8")
      ) {
        EventApi.GetVttUrl(options.src)
          .then((vttUrl) => {
            if (vttUrl) {
              player.addRemoteTextTrack(
                {
                  src: vttUrl,
                  kind: "subtitles",
                  srclang: "en",
                  label: "English",
                  default: true,
                },
                true,
              );
              player.textTracks()[0].mode = "hidden";
            } else {
              console.log("Subtitle file does not exist");
            }
          })
          .catch((error) => console.log("Error:", error));
      }
      player.load();
    }
    typeof options?.controls !== "undefined" &&
      player.controls(options.controls);
    typeof options?.loop !== "undefined" && player.loop(options?.loop);
    typeof options?.autoplay !== "undefined" &&
      player.autoplay(options.autoplay);
    typeof options?.muted !== "undefined" && player.muted(options.muted);
  }, [options ?? {}]);

  // Right click context menu
  useEffect(() => {
    const player = playerRef.current;
    const element = player?.contentEl();
    const handler = (event: Event) => event.preventDefault();
    const cleanup = () => {
      element?.removeEventListener("contextmenu", handler, false);
    };
    if (!element || enableContextMenu) return cleanup;
    // Disable right-click context menu
    element?.addEventListener?.("contextmenu", handler, false);
    return cleanup;
  });

  return (
    <StyledVideContainer {...containerProps} data-vjs-player>
      <StyledVideo
        playsInline
        className="video-js vjs-big-play-centered"
        ref={videoRef}
      />
      {children}
    </StyledVideContainer>
  );
};

export default memo(VideoJs);
