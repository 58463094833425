import orderBy from "lodash/orderBy";
import { Poll, PollStatus } from "./types";

export const getPollStatusRank = ({ status }: Poll) => {
  switch (status) {
    case PollStatus.ARCHIVED:
      return 3;
    case PollStatus.PENDING:
      return 2;
    case PollStatus.CLOSED:
      return 1;
    case PollStatus.ACTIVE:
    default:
      return 0;
  }
};

/**
 * Function that orders polls by status and then created time
 */
export const orderPolls = (polls: Poll[]) =>
  orderBy(polls, [getPollStatusRank, "updatedAt"], ["asc", "desc"]);

export function createPollsQueryKey(
  eventId: string | null,
  circleId: string | undefined,
) {
  return ["event-poll", eventId, circleId];
}
