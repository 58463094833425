import axios from "axios";
import queryString from "query-string";

import { ApiConfig } from "./api-config";

const FEATURE_FLAGS_API = "featureFlags";

interface GetFeatureFlagsOptions {
  flags: string[];
  eventId?: string;
  networkingHubId?: string;
  companyId?: string;
}

export const GetFeatureFlags = async (options: GetFeatureFlagsOptions) => {
  const url = `${ApiConfig.GetPrefix()}/api/${FEATURE_FLAGS_API}?${queryString.stringify(
    {
      ...options,
      flags: options.flags.join(","),
    },
  )}`;

  const response = await axios.get(url, ApiConfig.GetHeaderWithToken());

  if (response.status === 404 || response.status === 400) {
    throw new Error("Event not found");
  }

  const data = response.data as Record<string, boolean>;
  return data;
};
