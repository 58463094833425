import { zodResolver } from "@hookform/resolvers/zod";
import type {
  ObjectType,
  ObjectKeyPaths,
  TypeOfPathValue,
} from "@introvoke/react/types";
import { useUpdateEffect } from "@react-hookz/web";
import { useCallback, useMemo, useState } from "react";
import {
  get,
  useForm,
  UseFormReturn,
  type UseFormProps,
} from "react-hook-form";
import { z } from "zod";

// store all paths as first level strings
export type PersistedError<
  TFormValues extends ObjectType = ObjectType,
  TPath extends ObjectKeyPaths<TFormValues> = ObjectKeyPaths<TFormValues>,
> = {
  value: TypeOfPathValue<TFormValues, TPath>;
  message: string;
};

export type PersistedErrors<TFormValues extends ObjectType = ObjectType> = {
  [K in ObjectKeyPaths<TFormValues>]?: PersistedError<TFormValues, K>;
};

/**
 * Helper function to check if the form can be submitted.
 *
 * @param form react-hook-form form object to check
 * @param options The options to use when checking
 */
export const canSubmitForm = <
  TFormValues extends ObjectType,
  TForm extends Pick<
    UseFormWithPersistentValidationReturn<TFormValues>,
    "formState"
  >,
>(
  form: TForm,
  options: {
    /** Ignores whether the form is dirty @default true */
    ignoreDirty?: boolean;
    /** Ignores whether the form is invalid or has errors (ignored by default as our UX) @default true */
    ignoreErrors?: boolean;
  } = { ignoreDirty: true, ignoreErrors: true },
) => {
  const { formState } = form;

  const isDirty = options.ignoreDirty || formState.isDirty;
  const hasErrors = options.ignoreErrors
    ? false
    : Object.keys(formState.errors).length > 0 || !formState.isValid;

  return isDirty && !hasErrors && !formState.isSubmitting;
};

export type UseFormWithPersistentValidationReturn<
  TFormValues extends ObjectType = ObjectType,
  TContext = any,
  TTransformedValues extends ObjectType | undefined = undefined,
> = ReturnType<
  typeof useFormWithPersistentValidation<
    TFormValues,
    TContext,
    TTransformedValues
  >
>;

export type UseAnyFormReturn<
  TFormValues extends ObjectType = ObjectType,
  TContext = any,
  TTransformedValues extends ObjectType | undefined = undefined,
> =
  | UseFormReturn<TFormValues, TContext, TTransformedValues>
  | UseFormWithPersistentValidationReturn<
      TFormValues,
      TContext,
      TTransformedValues
    >;

export const useFormWithPersistentValidation = <
  TFormValues extends ObjectType = ObjectType,
  TContext = any,
  TTransformedValues extends ObjectType | undefined = undefined,
  TPath extends ObjectKeyPaths<TFormValues> = ObjectKeyPaths<TFormValues>,
>(
  { schema }: { schema: z.ZodTypeAny },
  options: UseFormProps<TFormValues, TContext> = {},
) => {
  const [persistentErrors, setPersistentErrors] = useState<
    PersistedErrors<TFormValues>
  >({});

  const [lastUpdatedPaths, setLastUpdatedPaths] = useState<TPath[]>([]);

  const schemaWithPersistedErrors = useMemo(() => {
    return schema.superRefine((values, ctx) => {
      // all paths are stored as first level strings
      Object.keys(persistentErrors).forEach((path) => {
        const value = get(values, path);
        const error = persistentErrors[path as TPath];

        if (error && value === error?.value) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: error.message,
            path: [path],
          });
        }
      });
    });
  }, [persistentErrors, schema]);

  const form = useForm<TFormValues, TContext, TTransformedValues>({
    ...options,
    resolver: zodResolver(schemaWithPersistedErrors),
  });

  useUpdateEffect(() => {
    // trigger validation when persistent errors are updated
    // validate all paths when cleared, otherwise validate the updated paths
    form.trigger(lastUpdatedPaths.length > 0 ? lastUpdatedPaths : undefined);
  }, [form, persistentErrors, lastUpdatedPaths]);

  const getPersistentError = useCallback(
    (path: TPath) => persistentErrors[path],
    [persistentErrors],
  );

  const setPersistentError = useCallback(
    (path: TPath, error: PersistedError<TFormValues, TPath>) => {
      setPersistentErrors((prev) => ({ ...prev, [path]: error }));
      setLastUpdatedPaths((prev) => [path]);
    },
    [],
  );

  const clearPersistentErrors = useCallback((path?: TPath | TPath[]) => {
    if (!path) {
      setPersistentErrors({});
      setLastUpdatedPaths([]);
      return;
    }

    const paths = Array.isArray(path) ? path : [path];

    setPersistentErrors((prev) => {
      paths.forEach((path) => delete prev[path]);
      return { ...prev }; // need to return a shallow copy to trigger a re-render
    });

    setLastUpdatedPaths(paths);
  }, []);

  // do not memoize this since the form reference is stable so it will not trigger a re-render
  return {
    ...form,
    persistentErrors,
    getPersistentError,
    setPersistentError,
    clearPersistentErrors,
  };
};
