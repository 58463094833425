import { useMemo } from "react";
import { ChannelConfig } from "../pubnub/PubNubProvider";

import { Message } from "./ChatProvider";
import { useChatChannel } from "./useChatChannel";

export const useChatChannelRecentMessage = (
  channelConfig: ChannelConfig | null,
): Message | null => {
  const { messagesAndPolls } = useChatChannel(channelConfig);

  const messages = messagesAndPolls.filter(
    (item) => "message" in item,
  ) as Message[];

  const channelId = channelConfig?.channel;
  return useMemo(() => {
    if (!channelId || !messages.length) {
      return null;
    }
    const recentMessage = messages[messages.length - 1];

    return recentMessage ?? null;
  }, [channelId, messages]);
};
