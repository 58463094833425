import { EventScheduledTimelineStatus } from "./../../../../../providers/EventStateProvider/index";
import { UserRole } from "@src/contracts/user/user";
import {
  useEventScheduledTimelineStatus,
  useEventState,
  useEventStreamingStatus,
  EventStreamingStatus,
} from "@src/providers/EventStateProvider";
import {
  FeatureFlag,
  useFeatureFlag,
} from "@src/providers/FeatureFlagsProvider";
import { useUserRole } from "@src/providers/UserProvider";

interface ShowHeaderActions {
  showJoinNetworkingHub: boolean;
  showJoinGreenroom: boolean;
  showActivatePreNetworking: boolean;
  showEndNetworking: boolean;
  showLeaveStage: boolean;
  showStartEvent: boolean;
  showEndEvent: boolean;
  showEditReplay: boolean;
  hasActions: boolean;
}

interface ShowHeaderActionParams {
  onEnterStage?: () => void;
  isStreamLive: boolean;
  isStage: boolean;
}

export const useShowHeaderActions = ({
  onEnterStage,
  isStreamLive,
  isStage,
}: ShowHeaderActionParams): ShowHeaderActions => {
  const userRole = useUserRole();
  const { networkingHub, eventLivestreamEndTime, isNetworkingLive } =
    useEventState();
  const eventTimelineStatus = useEventScheduledTimelineStatus();
  const routingRefactor = useFeatureFlag(FeatureFlag.ENABLE_ROUTING_REFACTOR);
  const eventStatus = useEventStreamingStatus();
  const eventOwnerOEM = useFeatureFlag(FeatureFlag.IS_OEM_CUSTOMER);
  const status = isStreamLive ? EventStreamingStatus.Live : eventStatus;

  const isRegistered = userRole < UserRole.Unregistered;
  const isHost = userRole <= UserRole.Organizer;
  const isHostOrPresenter = userRole <= UserRole.Presenter;
  const isLiveOrBeforeEndOfSchedule =
    isStreamLive || eventTimelineStatus !== EventScheduledTimelineStatus.AFTER;

  const hasNetworking = Boolean(networkingHub);

  const isOnStage = isHostOrPresenter && !onEnterStage;

  const showJoinNetworkingHub = isNetworkingLive && isRegistered;

  const showEditReplay =
    !isStreamLive && isHost && !!eventLivestreamEndTime && !!eventOwnerOEM;

  const showJoinGreenroom =
    !routingRefactor &&
    !!onEnterStage &&
    isHostOrPresenter &&
    isLiveOrBeforeEndOfSchedule;
  const showActivatePreNetworking =
    isStage && isOnStage && isHost && hasNetworking && !isNetworkingLive;
  const showEndNetworking =
    isStage && isOnStage && isHost && hasNetworking && isNetworkingLive;
  // adding or removing this button is in question according gto previous comments before refactor/redesign
  const showLeaveStage = false;
  const showStartEvent =
    isStage && isOnStage && isHost && status !== EventStreamingStatus.Live;
  const showEndEvent =
    isStage && isOnStage && isHost && status === EventStreamingStatus.Live;

  return {
    showJoinNetworkingHub,
    showJoinGreenroom,
    showActivatePreNetworking,
    showEndNetworking,
    showLeaveStage,
    showStartEvent,
    showEndEvent,
    showEditReplay,
    hasActions:
      showJoinNetworkingHub ||
      showJoinGreenroom ||
      showActivatePreNetworking ||
      showEndNetworking ||
      showLeaveStage ||
      showStartEvent ||
      showEndEvent ||
      showEditReplay,
  };
};
