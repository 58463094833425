import React from "react";
import { alpha, TextField, InputLabel, Box } from "@mui/material";
import { useField } from "formik";

export const CustomTextPlaceholder = () => {
  const [placeholderMessage, meta, helpers] = useField<string>(
    "streamPlaceholderMessage",
  );

  return (
    <Box flexGrow={1} sx={{ my: 2 }}>
      <InputLabel sx={{ padding: ".5em 0" }}>
        {"Placeholder Message:"}
      </InputLabel>
      <TextField
        aria-label="edit stream placeholder message"
        autoFocus
        error={!!meta.error}
        fullWidth
        helperText={meta.error}
        id="edit-stream-placeholder-message"
        InputProps={{
          sx: ({ palette, transitions, typography }) => ({
            border: "1px solid #FFFFFF",
            borderRadius: 2,
            backgroundColor: "#F2F2F2",
            fontSize: typography.body2.fontSize,
            overflow: "hidden",
            padding: "5px 1em",
            transition: transitions.create(["border-color", "box-shadow"]),
            "&:focus": {
              boxShadow: `${alpha(palette.primary.main, 0.25)} 0 0 0 2px`,
              borderColor: palette.primary.main,
            },
          }),
          disableUnderline: true,
          placeholder: "Enter message...",
        }}
        sx={({ palette, typography }) => ({
          fontSize: typography.body1.fontSize,
          paddingBottom: ".5em",
          "& .MuiInput-root": {
            "&.Mui-focused": {
              boxShadow: `${alpha(palette.primary.main, 0.25)} 0 0 0 2px`,
              borderColor: palette.primary.main,
            },
          },
        })}
        onChange={({ target }) => helpers.setValue(target.value)}
        name="streamPlaceholderMessage"
        required
        value={placeholderMessage.value}
      />
    </Box>
  );
};
