import { Api } from "@src/api/api";
import { RegistrantNew } from "@src/api/event-api";
import { ApiErrorWithMessage } from "@src/contracts/errors/errors";
import { RegisteredAttendeeUserResponse } from "@src/contracts/user/user";
import { useEventOrNetworkingHub } from "@src/hooks/useEventOrNetworkingHub";
import { ObjectKeyPaths } from "@introvoke/react/types/path";
import { AxiosError, AxiosResponse } from "axios";
import { UseMutationOptions, useMutation } from "react-query";

export const useRegisterUser = (
  options: UseMutationOptions<
    AxiosResponse<RegisteredAttendeeUserResponse>,
    AxiosError<ApiErrorWithMessage<ObjectKeyPaths<RegistrantNew>>>,
    RegistrantNew
  > = {},
) => {
  const { data: eventOrNetworkingHub, isEvent } = useEventOrNetworkingHub();

  const mutation = useMutation<
    AxiosResponse<RegisteredAttendeeUserResponse>,
    AxiosError<ApiErrorWithMessage<ObjectKeyPaths<RegistrantNew>>>,
    RegistrantNew
  >({
    mutationFn: async (registrant) => {
      const result = isEvent
        ? await Api.EventApi.RegisterForEvent(
            eventOrNetworkingHub?.uid ?? "",
            registrant,
          )
        : await Api.NetworkingHubApi.RegisterForNetworkingHub(
            eventOrNetworkingHub?.uid ?? "",
            registrant,
          );
      return result;
    },
    ...options,
  });

  return mutation;
};

// TODO: @JeffreyJoumjian check returned the type here
export const useResendRegistrationEmail = (
  options: UseMutationOptions<boolean, unknown, { email: string }> = {},
) => {
  const { data: eventOrNetworkingHub, isEvent } = useEventOrNetworkingHub();

  const mutation = useMutation<boolean, unknown, { email: string }>({
    mutationFn: ({ email }) => {
      if (!eventOrNetworkingHub) {
        throw new Error("Event or networking hub is not defined");
      }

      return isEvent
        ? Api.EventApi.ResendEmail(eventOrNetworkingHub.uid, email)
        : Api.NetworkingHubApi.ResendEmail(eventOrNetworkingHub.uid, email);
    },
    ...options,
  });

  return mutation;
};

// TODO: @JeffreyJoumjian Have better types here?
// Either return error or the user information
export const useGetUserJoinInformation = (
  options: UseMutationOptions<
    Awaited<ReturnType<typeof Api.EventApi.GetUserJoinInformation>>,
    Error | AxiosError<ApiErrorWithMessage>,
    { joinCode: string }
  > = {},
) => {
  const { data: eventOrNetworkingHub, isEvent } = useEventOrNetworkingHub();

  const mutation = useMutation<
    Awaited<ReturnType<typeof Api.EventApi.GetUserJoinInformation>>,
    Error | AxiosError<ApiErrorWithMessage>,
    { joinCode: string }
  >({
    mutationFn: ({ joinCode }) => {
      if (!eventOrNetworkingHub) {
        throw new Error("Event or networking hub is not defined");
      }

      return isEvent
        ? Api.EventApi.GetUserJoinInformation(
            eventOrNetworkingHub.uid,
            joinCode,
          )
        : Api.NetworkingHubApi.GetUserJoinInformation(
            eventOrNetworkingHub.uid,
            joinCode,
          );
    },
    ...options,
  });

  return mutation;
};

export const useValidateJoinCode = (
  options: UseMutationOptions<boolean, unknown, { joinCode: string }> = {},
) => {
  const { mutateAsync: getUserJoinInformation } = useGetUserJoinInformation();

  const mutation = useMutation<boolean, unknown, { joinCode: string }>({
    mutationFn: async ({ joinCode }) => {
      try {
        const result = await getUserJoinInformation({ joinCode });
        return !!(result?.privateUserInfo?.email || result.email);
      } catch (err) {
        return false;
      }
    },
    ...options,
  });

  return mutation;
};
