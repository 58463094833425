import React from "react";
import { Typography, styled, Stack } from "@mui/material";
import { useInterval } from "react-use";
import { intervalToDuration, isAfter, isBefore } from "date-fns";

interface Props {
  name: string;
  isLive: boolean;
  liveStart: Date | undefined | null;
  scheduledStart: Date | undefined | null;
  scheduledEnd: Date | undefined | null;
  actions?: React.ReactNode;
}

const RootContainer = styled("div")(({ theme: { palette, spacing } }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  flexWrap: "wrap",
  alignItems: "center",
  whiteSpace: "nowrap",
  background: palette.common.white,
  padding: spacing(1),
}));

const TitleContainer = styled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  minWidth: "150px",
  marginRight: spacing(1),
}));

const LiveBadge = styled(Typography)(({ theme: { spacing } }) => ({
  flexShrink: 0,
  fontSize: "0.7em",
  textTransform: "uppercase",
  padding: spacing(0.25),
  paddingLeft: spacing(1),
  paddingRight: spacing(1),
  borderWidth: 1,
  borderRadius: spacing(0.5),
  borderStyle: "solid",
}));

const TimeInfo = styled(Typography)(({ theme: { spacing } }) => ({
  marginLeft: spacing(1),
  // Next 2 styles are for ellipsis start
  direction: "rtl",
  textAlign: "left",
}));

const StatusContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

const getDateOffset = (dateString: Date | undefined | null) => {
  if (!dateString) {
    return "--:--:--";
  }

  const date = new Date(dateString);

  const { years, months, days, hours, minutes, seconds } = intervalToDuration({
    start: Date.now(),
    end: date,
  });

  if (years) {
    return `${years} year${years > 1 ? "s" : ""}`;
  }

  if (months) {
    return `${months} month${months > 1 ? "s" : ""}`;
  }

  if (days) {
    return `${days} day${days > 1 ? "s" : ""}`;
  }

  const time = `${String(hours || 0).padStart(2, "0")}:${String(
    minutes || 0,
  ).padStart(2, "0")}:${String(seconds || 0).padStart(2, "0")}`;

  return time;
};

const HeaderLegacy = (props: Props) => {
  const { name, isLive, liveStart, scheduledStart, scheduledEnd, actions } =
    props;

  const getTimeInfo = React.useCallback(() => {
    const currentDate = Date.now();

    if (isLive) {
      return `Running time ${getDateOffset(liveStart)}`;
    }

    // if after end time and not live
    if (scheduledEnd && isAfter(currentDate, new Date(scheduledEnd))) {
      return `Event has ended`;
    }

    if (scheduledStart) {
      const startDate = new Date(scheduledStart);

      // if before start time and not live
      if (isBefore(currentDate, startDate)) {
        return `Scheduled to start in ${getDateOffset(scheduledStart)}`;
      }

      // if after start time and not live
      if (isAfter(currentDate, startDate)) {
        return "Event will start soon";
      }
    }

    return "";
  }, [isLive, liveStart, scheduledEnd, scheduledStart]);

  const [timeInfo, setTimeInfo] = React.useState(getTimeInfo);

  useInterval(() => {
    setTimeInfo(getTimeInfo);
  }, 1000);

  return (
    <RootContainer>
      <TitleContainer>
        <Typography noWrap variant="h3">
          {name}
        </Typography>
        <StatusContainer>
          <LiveBadge
            sx={({ palette }) => ({
              borderColor: isLive ? palette.red?.[400] : palette.text.primary,
              color: isLive ? palette.common.white : palette.text.primary,
              backgroundColor: isLive ? palette.red?.[400] : "transparent",
            })}
          >
            {isLive ? "Live" : "Not Live"}
          </LiveBadge>
          <TimeInfo noWrap>{timeInfo}</TimeInfo>
        </StatusContainer>
      </TitleContainer>
      <Stack
        direction="row"
        flexWrap="wrap"
        sx={{
          marginTop: 1,
          marginLeft: 1,
          "> button": { marginRight: 1, marginBottom: 1 },
        }}
      >
        {actions}
      </Stack>
    </RootContainer>
  );
};

export default React.memo(HeaderLegacy);
