import { Divider, styled, Typography } from "@mui/material";

export const Wrapper = styled("div")({
  display: "flex",
  height: "100%",
});

export const NoPollsText = styled(Typography)(({ theme: { spacing } }) => ({
  textAlign: "center",
  padding: spacing(1),
}));

export const ListContainer = styled("div")(({ theme: { spacing } }) => ({
  display: "block",
  overflow: "auto",
  flexGrow: 1,
}));

export const StyledMuiDivider = styled(Divider)(({ theme: { spacing } }) => ({
  marginTop: spacing(2),
  marginBottom: spacing(2),
}));

export const PollWrapper = styled("div")({
  overflow: "visible",
});
