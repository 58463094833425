import { useCallback } from "react";
import invariant from "tiny-invariant";

import { Api } from "../../../api/api";
import { VideoType } from "../../../models/eventType";
import { useEvent } from "../../../providers/EventProvider";
import { useUser } from "../../../providers/UserProvider";

export const useUpdateVideoView = (videoType: VideoType) => {
  const user = useUser();
  const { data: event } = useEvent();
  invariant(event, "");

  return useCallback(() => {
    // TODO hook Api.AnalyticsApi.UpdateLengthOfWatch
    Api.AnalyticsApi.UpdateEventPageVideoView(
      event.uid,
      event.streamInfo.streamId,
      videoType,
      user.uid,
      user.name,
      user.userRole,
      user.profilePicture,
      user.location,
    );
  }, [
    event.streamInfo.streamId,
    event.uid,
    user.location,
    user.name,
    user.userRole,
    user.profilePicture,
    user.uid,
    videoType,
  ]);
};
