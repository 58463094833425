export enum SideBarTabs {
  CHAT = "CHAT",
  PEOPLE = "PEOPLE",
  QA = "QA",
  POLLS = "POLLS",
  VISUALS = "VISUALS",
  MEDIA = "MEDIA",
  PROFILE = "PROFILE",
  SETTINGS = "SETTINGS",
}
