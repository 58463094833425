import { useState } from "react";
import { toast } from "react-toastify";
import { useThrottleFn } from "react-use";

import { useLatestCallback } from "../../hooks/useLatestCallback";

import { logEvent } from "@introvoke/react/metrics";
import { MetricsEvents } from "@src/metrics";
import { Poll, usePollContext } from "../polls/PollProvider";
import { ChannelConfig } from "../pubnub/PubNubProvider";
import {
  Message,
  PinnedMessage,
  useEventChatContext,
  AnnouncementMessage,
  Announcement,
  MessageActionTypes,
  DeleteMessageActionValues,
} from "./ChatProvider";
import { deleteNestedChannelMessage } from "./deleteNestedChannelMessage";
import { PINS_CHANNEL_SUFFIX } from "./helpers";
import { useAnnouncementChannel } from "./useAnnouncementChannel";
import {
  usePubnubChatReactions,
  UsePubnubChatReactionsValue,
} from "./useChatReactions";
import { usePubNubHistory } from "./usePubNubHistory";

export enum ChatMode {
  DEFAULT = 500,
  SLOW = 1000,
  SLOWER = 3000,
  SLOWEST = 5000,
}

const EMPTY_LIST = Object.freeze([]);

const MAX_PINNED_MESSAGES = 1;

// get a date object for a Poll or Message
const getDate = (item: Message | Poll): number => {
  if ("message" in item) {
    const date = new Date(item.message.timestamp).valueOf();
    return date;
  }
  const date = new Date(item.updatedAt || item.createdAt).valueOf();
  return date;
};

// sort array of Polls and Messages
const sortMessageOrPoll = (a: Message | Poll, b: Message | Poll): number => {
  // ignore comparing messages, they should already be sorted
  if ("message" in a && "message" in b) {
    return 0;
  }
  return getDate(a) - getDate(b);
};

export type ChatChannel = {
  messagesAndPolls: (Message | Poll)[];
  sendMessage: (message: Message["message"]) => void;
  deleteMessage: (message: Message) => void;
  pinMessage: (message: Message) => void;
  unpinMessage: (pinnedMessage: PinnedMessage) => void;
  pinnedMessages: PinnedMessage[];
  sendAnnouncement: (announcement: Announcement) => void;
  deleteAnnouncement: (announcement: AnnouncementMessage) => void;
  /**
   * Whether chat reactions are enabled. Only true if chat reactions are enabled in the theme and via feature flag.
   */
  chatReactionsEnabled: UsePubnubChatReactionsValue["enabled"];
} & Pick<UsePubnubChatReactionsValue, "addReaction" | "removeReaction">;

export const useChatChannel = (
  channelConfig: ChannelConfig | null,
  hidePollPreviews: boolean = false,
  mode: ChatMode = ChatMode.DEFAULT,
): ChatChannel => {
  const [pendingMessages, setPendingMessages] = useState<Message[]>([]);
  const { pubnub, user } = useEventChatContext();
  const { activePolls } = usePollContext();
  const {
    enabled: chatReactionsEnabled,
    addReaction,
    removeReaction,
  } = usePubnubChatReactions({
    pubnub,
    user,
  });

  const channelId = channelConfig?.channel;
  const pinnedChannelId = channelConfig?.pinnable
    ? `${channelConfig?.channel}${PINS_CHANNEL_SUFFIX}`
    : null;

  const { announcementMessages, sendAnnouncement, deleteAnnouncement } =
    useAnnouncementChannel(channelConfig);

  // hide polls in certain channels (private chat)
  const polls = hidePollPreviews ? null : activePolls;

  const history = usePubNubHistory<Message>({
    channelId,
    mapMessageData: (message) => ({
      message,
      timestamp: message.timestamp,
    }),
  });

  const pinnedMessages = usePubNubHistory<PinnedMessage>({
    channelId: pinnedChannelId,
    mapMessageData: (message) => ({
      message: message.message,
      timestamp: message.pinTimestamp,
    }),
  });

  // Throttle the messages received to avoid UI overkill
  const messagesAndPollsThrottled =
    useThrottleFn(
      (currentHistory, currentPolls) => {
        // clear local messages, we'll have the live feed now.
        // which may or may not have the messages in depending on how fast chat is moving.
        setPendingMessages([]);
        // We need to check if we have both messages and polls
        // When there are no messages, polls should still be shown in the chat log
        // If no history, return the polls
        if (!currentHistory?.length) {
          return currentPolls;
          // If no polls, return history messages
        } else if (!currentPolls?.length) {
          return currentHistory;
        }

        // We combine the chat messages and polls together, sorting based on time
        let combinedChannelContent: (Message | Poll)[] = (
          currentHistory as any[]
        )
          .concat(currentPolls as any)
          .sort(sortMessageOrPoll);
        return combinedChannelContent;
      },
      mode,
      [history, polls],
    ) || (EMPTY_LIST as unknown as (Message | Poll)[]);

  const messagesAndPolls = [...messagesAndPollsThrottled, ...pendingMessages];

  const sendMessage = useLatestCallback((message: Message["message"]) => {
    if (!channelId) {
      return;
    }
    const userDetails = {
      uid: user.uid,
      profilePicture: user.profilePicture,
      name: user.name,
      role: user.role,
      email: user.email,
    };
    setPendingMessages((currVal) => [
      ...currVal,
      {
        pending: true,
        channel: channelId,
        message: message,
        user: userDetails,
        actions: {},
      },
    ]);
    pubnub.publish({
      channel: channelId,
      message,
      meta: {
        user: userDetails,
      },
    });
  });

  const deleteMessage = useLatestCallback(async (message: Message) => {
    if (!channelId || !message.timetoken) {
      return;
    }

    try {
      await pubnub.addMessageAction({
        channel: channelId,
        messageTimetoken: message.timetoken,
        action: {
          type: MessageActionTypes.DELETED,
          value: DeleteMessageActionValues.SINGLE,
        },
      });

      // Unpin the deleted message
      const pinnedMessage = pinnedMessages.find(
        (pinned) => pinned.message.timetoken === message.timetoken,
      );
      pinnedMessage && unpinMessage(pinnedMessage);

      // delete the announcement message
      const announcementMessage = announcementMessages.find(
        (announcement) => announcement.message.timetoken === message.timetoken,
      );
      announcementMessage && deleteAnnouncement(announcementMessage);
    } catch (err) {
      console.error("Error deleting chat message", err);
    }
  });

  const pinMessage = useLatestCallback((message: Message) => {
    if (!pinnedChannelId) {
      return;
    }

    if (pinnedMessages.length >= MAX_PINNED_MESSAGES) {
      toast.warn("Pinned message limit reached");
      return;
    }

    pubnub.publish({
      channel: pinnedChannelId,
      message: {
        type: "pin",
        message,
        pinTimestamp: new Date().toISOString(),
      },
      meta: {
        user: {
          uid: user.uid,
          profilePicture: user.profilePicture,
          name: user.name,
          role: user.role,
          email: user.email,
        },
      },
    });

    logEvent(MetricsEvents.Chat.PINNING);
  });

  const unpinMessage = useLatestCallback((message: PinnedMessage) =>
    deleteNestedChannelMessage({
      pubnub,
      channelId: pinnedChannelId,
      message,
      options: {
        error: "Error unpinning chat message",
      },
    }),
  );

  return {
    messagesAndPolls,
    sendMessage,
    deleteMessage,
    pinMessage,
    unpinMessage,
    pinnedMessages,
    sendAnnouncement,
    deleteAnnouncement,
    chatReactionsEnabled,
    addReaction,
    removeReaction,
  };
};
