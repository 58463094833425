import { NetworkingHub as ApiNetworkingHub } from "../../contracts/networking-hub/NetworkingHub";
import * as t from "io-ts";

export const TemporaryRoomType = t.strict({
  id: t.string,
  name: t.string,
  metadata: t.record(t.string, t.string),
  slots: t.number,
  createdAt: t.number,
  expiresAt: t.union([t.undefined, t.number]),
});

export interface TemporaryRoom extends t.TypeOf<typeof TemporaryRoomType> {}

export interface NetworkingHub extends ApiNetworkingHub {
  hubLocked: boolean;
}

export const createNetworkingHubQueryKey = (
  networkingHubId: string | undefined | null,
) => ["NetworkingHub", networkingHubId ?? null];

export const createTemporaryRoomsQueryKey = (
  networkingHubId: string | undefined | null,
) => ["NetworkingHubTemporaryRooms", networkingHubId ?? null];
