import React from "react";
import { css } from "@emotion/react";

import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";

import { ActionButton } from "./buttons/ActionButton";
import { BaseButton } from "./buttons/BaseButton";

export interface BlockUserDialogProps {
  show: boolean;
  username: string;
  onBlock: () => void;
  onClose: () => void;
}

export const BlockUserDialog: React.FC<BlockUserDialogProps> = ({
  username,
  show,
  onBlock,
  onClose,
}) => {
  return (
    <Dialog
      open={show}
      keepMounted={false}
      fullWidth
      css={(theme) => css`
        .MuiDialog-paper {
          max-width: 720px;
          border-radius: ${theme.spacing(4)};
        }
      `}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box marginBottom={3}>
          <Typography variant="h3" align="center" color="secondary">
            Are you sure you want to block {username} from the event?
          </Typography>
        </Box>
        <Box marginBottom={1} display="flex" justifyContent="center">
          <Box display="flex" justifyContent="center">
            <BaseButton
              css={(theme) => css`
                margin-right: ${theme.spacing(1)};
              `}
              onClick={onClose}
            >
              Cancel
            </BaseButton>
            <ActionButton data-testid="confirm-block-btn" onClick={onBlock}>
              Block
            </ActionButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
