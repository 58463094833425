import {
  atom,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { useCallback, useEffect } from "react";

let permissionStatusChangeListenerFired = false;

const getPermissionStateByBool = (accessGranted: boolean): PermissionState => {
  return accessGranted ? "granted" : "denied";
};

const getHasPermissionByError = (error: Error): boolean => {
  const { name } = error;

  if (
    name === "NotAllowedError" ||
    name === "PermissionDeniedError" ||
    name === "SecurityError"
  ) {
    return false;
  }

  return true;
};

const requestAccessByGetUserMedia = async (data: {
  video: boolean;
  audio: boolean;
}): Promise<boolean> => {
  try {
    const mediaStream = await navigator.mediaDevices.getUserMedia(data);

    mediaStream.getTracks().forEach((t) => t.stop());

    return true;
  } catch (error: any) {
    // sometimes it is not only permission error, camera can be locked by other application, etc.
    // so, check error code for permission denied error
    return getHasPermissionByError(error);
  }
};

// Recoil atoms
const cameraStatusState = atom<PermissionState>({
  key: "cameraStatusState",
  default: "prompt",
});

const microphoneStatusState = atom<PermissionState>({
  key: "microphoneStatusState",
  default: "prompt",
});

const initializedState = atom<boolean>({
  key: "initializedState",
  default: false,
});

// Hooks
export const useMediaPermissionsProvider = () => {
  // this state will change only once to true after awaiting queries
  const setInitialized = useSetRecoilState<boolean>(initializedState);
  const setCameraStatus = useSetRecoilState<PermissionState>(cameraStatusState);
  const setMicrophoneStatus = useSetRecoilState<PermissionState>(
    microphoneStatusState,
  );

  const onCameraPermissionStatusChange = useCallback(
    (event: Event) => {
      permissionStatusChangeListenerFired = true;
      setCameraStatus((event.currentTarget as PermissionStatus).state);
    },
    [setCameraStatus],
  );
  const onMicrophonePermissionStatusChange = useCallback(
    (event: Event) => {
      permissionStatusChangeListenerFired = true;
      setMicrophoneStatus((event.currentTarget as PermissionStatus).state);
    },
    [setMicrophoneStatus],
  );

  useEffect(() => {
    let mounted = true;
    let newCameraPermissionStatus: PermissionStatus | null = null;
    let newMicrophonePermissionStatus: PermissionStatus | null = null;
    const initPermissions = async () => {
      if ("permissions" in navigator) {
        // if we have permissions api, we will obtain permission information from status and add change listeners
        const cameraStatusInitializePromise = navigator.permissions
          .query({ name: "camera" as any })
          .catch(() => null);
        const microphoneStatusInitializePromise = navigator.permissions
          .query({ name: "microphone" as any })
          .catch(() => null);

        const [cameraStatus, microphoneStatus] = await Promise.all([
          cameraStatusInitializePromise,
          microphoneStatusInitializePromise,
        ]);

        if (!mounted) {
          return;
        }

        if (cameraStatus) {
          cameraStatus.addEventListener(
            "change",
            onCameraPermissionStatusChange,
          );
          setCameraStatus(cameraStatus.state);
        }

        if (microphoneStatus) {
          microphoneStatus.addEventListener(
            "change",
            onMicrophonePermissionStatusChange,
          );
          setMicrophoneStatus(microphoneStatus.state);
        }

        // check permission by enumerateDevices
        if (!cameraStatus || !microphoneStatus) {
          let devices: MediaDeviceInfo[] = [];

          try {
            devices = await navigator.mediaDevices.enumerateDevices();
          } catch (e) {
            // noop
          }

          if (!cameraStatus) {
            // not empty label means that we have access to device
            const camera = devices.find(
              (device) => device.kind === "videoinput" && device.label,
            );

            if (camera) {
              setCameraStatus("granted");
            }
          }

          if (!microphoneStatus) {
            const microphone = devices.find(
              (device) => device.kind === "audioinput" && device.label,
            );

            if (microphone) {
              setMicrophoneStatus("granted");
            }
          }
        }

        newCameraPermissionStatus = cameraStatus;
        newMicrophonePermissionStatus = microphoneStatus;
      } else {
        // we do not have permissions api, so check access by devices label
        let devices: MediaDeviceInfo[] = [];

        try {
          devices = await (
            navigator as Navigator
          ).mediaDevices.enumerateDevices();
        } catch (e) {
          // noop
        }

        // not empty label means that we have access to device
        const camera = devices.find(
          (device) => device.kind === "videoinput" && device.label,
        );
        const microphone = devices.find(
          (device) => device.kind === "audioinput" && device.label,
        );

        if (camera) {
          setCameraStatus("granted");
        }

        if (microphone) {
          setMicrophoneStatus("granted");
        }
      }

      setInitialized(true);
    };

    initPermissions();

    return () => {
      mounted = false;

      if (newCameraPermissionStatus) {
        newCameraPermissionStatus.removeEventListener(
          "change",
          onCameraPermissionStatusChange,
        );
      }

      if (newMicrophonePermissionStatus) {
        newMicrophonePermissionStatus.removeEventListener(
          "change",
          onMicrophonePermissionStatusChange,
        );
      }
    };
  }, [
    // this dependency should not be updated
    onCameraPermissionStatusChange,
    onMicrophonePermissionStatusChange,
    setCameraStatus,
    setMicrophoneStatus,
    setInitialized,
  ]);
};

export const useRequestMediaDevicePermissions =
  (): (() => Promise<boolean>) => {
    const [cameraStatus, setCameraStatus] =
      useRecoilState<PermissionState>(cameraStatusState);
    const [microphoneStatus, setMicrophoneStatus] =
      useRecoilState<PermissionState>(microphoneStatusState);

    const requestMediaDevicePermissions =
      useCallback(async (): Promise<boolean> => {
        // TODO in this method we should await for initialization first, but for now we guarantee
        // TODO that this provider will be initialized before using this method
        let requestCamera = cameraStatus === "prompt";
        let requestMicrophone = microphoneStatus === "prompt";

        try {
          const devices = await navigator.mediaDevices.enumerateDevices();

          requestCamera =
            requestCamera &&
            Boolean(devices.find((device) => device.kind === "videoinput"));
          requestMicrophone =
            requestMicrophone &&
            Boolean(devices.find((device) => device.kind === "audioinput"));
        } catch (e) {
          // noop
        }

        // TODO strange case add log
        if (!requestCamera && !requestMicrophone) {
          // if we have at least one permission, we consider it as we have permission to devices
          return cameraStatus === "granted" || microphoneStatus === "granted";
        }

        const hasAccess = await requestAccessByGetUserMedia({
          video: requestCamera,
          audio: requestMicrophone,
        });
        const permissionState = getPermissionStateByBool(hasAccess);

        if (!permissionStatusChangeListenerFired && requestCamera) {
          setCameraStatus(permissionState);
        }

        if (!permissionStatusChangeListenerFired && requestMicrophone) {
          setMicrophoneStatus(permissionState);
        }

        return hasAccess;
      }, [
        cameraStatus,
        microphoneStatus,
        setCameraStatus,
        setMicrophoneStatus,
      ]);

    return requestMediaDevicePermissions;
  };

export const useIsPermissionProviderInitialized = (): boolean =>
  useRecoilValue(initializedState);

export const useIsCameraGranted = () =>
  useRecoilValue(cameraStatusState) === "granted";

export const useIsCameraPrompt = () =>
  useRecoilValue(cameraStatusState) === "prompt";

export const useIsCameraDenied = () =>
  useRecoilValue(cameraStatusState) === "denied";

export const useIsMicrophoneGranted = () =>
  useRecoilValue(microphoneStatusState) === "granted";

export const useIsMicrophonePrompt = () =>
  useRecoilValue(microphoneStatusState) === "prompt";

export const useIsMicrophoneDenied = () =>
  useRecoilValue(microphoneStatusState) === "denied";
