import isObject from "lodash/isObject";

export const isObjectWithProps = <T extends string>(
  value: unknown,
  ...props: T[]
): value is {
  [key in T]: unknown;
} => {
  if (!isObject(value)) {
    return false;
  }
  return props.every((prop) => prop in value);
};
