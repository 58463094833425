import React from "react";
import { UserQuestion } from "../../providers/qAndA/QandAProvider";
import { Box } from "@mui/material";
import QuestionListCollection from "./QuestionListCollection";
import QuestionListItem from "./QuestionListItem";
import { MenuItemType } from "../Menu/Menu";
import { User } from "../../contracts/user/user";

interface QandAReviewContentProps {
  user: User;
  unapprovedQuestions: UserQuestion[];
  createMenuItems: (question: UserQuestion) => MenuItemType[];
}

export const QandAReviewContent = React.memo(
  ({
    user,
    unapprovedQuestions = [],
    createMenuItems,
  }: QandAReviewContentProps) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "100%",
        }}
      >
        <QuestionListCollection
          title="Unapproved"
          expanded={true}
          questions={unapprovedQuestions}
          renderItem={(question) => (
            <QuestionListItem
              key={question.id}
              id={question.id}
              user={user}
              question={question}
              menuItems={createMenuItems(question)}
              votes={question.votes}
            />
          )}
        />
      </Box>
    );
  },
);
