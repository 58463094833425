import { GraphQlClient } from "@src/graphql/SocketClient/GraphQlClient";
import { isSessionStorageEnabled } from "@src/helpers/browser";
import { useLatestCallback } from "@src/hooks/useLatestCallback";
import {
  Environment,
  useIsEnvironment,
} from "@src/providers/EnvironmentProvider";
import { useEffect, useMemo } from "react";
import { useQuery, UseQueryOptions } from "react-query";
import {
  ClientEffectArgs,
  gqlSessionQueryKey,
  GQL_SESSION_STORAGE_KEY,
} from "../common";

/**
 * Hook that is used to start the session for the user on the GQL socket server.
 */
export const useClientStartSessionQuery = (
  { client, isV2ChatServerEnabled }: ClientEffectArgs,
  options: UseQueryOptions<string | null> = {},
) => {
  const sessionId = isSessionStorageEnabled()
    ? window.sessionStorage.getItem(GQL_SESSION_STORAGE_KEY)
    : null;
  const isTestEnvironment = useIsEnvironment(Environment.TEST);

  const query = useQuery<string | null>(
    gqlSessionQueryKey,
    async () => {
      if (
        isV2ChatServerEnabled &&
        client.connected &&
        client instanceof GraphQlClient
      ) {
        return await (client as GraphQlClient).startSession(sessionId);
      }
      return `${Date.now()}`;
    },
    {
      // disable since we're using manual fetching
      enabled: false,
      retry: !isTestEnvironment ? 3 : false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      ...options,
    },
  );

  const refetch = useLatestCallback(async () => {
    return (await query.refetch()).data;
  });

  return useMemo(() => ({ ...query, refetch }), [query, refetch]);
};

/**
 * Hook that starts the client session and triggers updates if the client changes.
 *
 * It is important that the session is started before the user can load the component and
 * start emitting actions
 *
 * _NOTE_: Due to the differences in handling the connection/session flow between v1 and v2,
 * this code is really sensitive to change and must be properly handled
 *
 * 1. For chat v1, we have to mock a `sessionId` using the current date to retrigger sync events
 * when a new v1 client is created and connected. This is handled by the `useClientConnect`
 *
 * 2. For chat v2, we don't have an client.on("connect") event and we need to manually start the session
 * after connecting to the v2 server. Starting the session will update the sessionId if necessary
 * and retrigger the correct syncs
 */
export const useClientStartSession = (
  { client, isV2ChatServerEnabled }: ClientEffectArgs,
  options: UseQueryOptions<string | null> = {},
) => {
  const query = useClientStartSessionQuery(
    { client, isV2ChatServerEnabled },
    options,
  );

  useEffect(() => {
    if (
      isV2ChatServerEnabled &&
      client instanceof GraphQlClient &&
      client.connected
    ) {
      query.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, isV2ChatServerEnabled]);

  return query;
};
