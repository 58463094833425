import queryString from "query-string";
import words from "lodash/words";
import React, { isValidElement, cloneElement } from "react";
import { useNavigate } from "react-router-dom";
import { sanitize } from "dompurify";

export class Util {
  public static GetUTCDate() {
    const currentDate = new Date();
    return currentDate.toISOString();
  }
}

/**
 * helper function to be used ONLY in extractIdsFromUrl
 */
function getUidFromUrl(type: "event" | "networkingHub"): string {
  const pathArray = window.location.pathname.split("/");
  const eventIndex = pathArray.indexOf(type);
  if (eventIndex > 0) {
    return pathArray[eventIndex + 1] || "";
  } else {
    return "";
  }
}

/**
 * Removes the provided key from the query string of the url
 *
 * @param key The query key or keys to remove
 * @param navigate Navigate function
 */
export const removeQueryKeyFromUrl = (
  navigate: ReturnType<typeof useNavigate>,
  key: string | string[],
) => {
  const keys = typeof key === "string" ? [key] : key;

  const urlParams = new URLSearchParams(window.location.search);

  keys.forEach((key) => urlParams.delete(key));

  if (navigate) {
    return navigate({ search: urlParams.toString() });
  }
};

export const autocompleteMapping: { [key: string]: string } = {
  "phone number": "tel",
  phone: "tel",
  country: "country-name",
  state: "address-level1",
  city: "address-level2",
  "postal code": "postal-code",
  zip: "postal-code",
  address: "street-address",
  "job title": "organization-title",
  title: "organization-title",
  role: "organization-title",
  company: "organization",
  "company name": "organization",
  "organization name": "organization",
  organization: "organization",
  website: "url",
  linkedin: "linkedin",
};

export const extractTextFromLabel = (label: any) => {
  // Assuming label is a React element with a dangerouslySetInnerHTML structure
  if (label && label.props && label.props.dangerouslySetInnerHTML) {
    return label.props.dangerouslySetInnerHTML.__html
      .replace(/<[^>]+>/g, "")
      .replace(/\*/g, "")
      .trim()
      .toLowerCase();
  }
  return "";
};

export function isNetworkingHub(): boolean {
  return window.location.pathname.indexOf("networkingHub") >= 0;
}

export function isEvent(): boolean {
  return !isNetworkingHub();
}

export function extractUidFromUserId(userId: string): string {
  return userId.includes("|||") ? userId.split("|||")[0] : userId;
}

export function shortenLargeNumber(num: number, digits: number) {
  var units = ["k", "M", "G", "T", "P", "E", "Z", "Y"],
    decimal;

  for (var i = units.length - 1; i >= 0; i--) {
    decimal = Math.pow(1000, i + 1);

    if (num <= -decimal || num >= decimal) {
      return +(num / decimal).toFixed(digits) + units[i];
    }
  }

  return num;
}

/**
 * Extract the event ID and networking hub ID from the current window location
 */
export const extractIdsFromUrl = (): {
  eventId?: string;
  networkingHubId?: string;
} => {
  if (isNetworkingHub()) {
    return {
      networkingHubId: getUidFromUrl("networkingHub"),
      eventId: getReturnToEventId(window.location.search),
    };
  }

  return {
    eventId: getUidFromUrl("event"),
  };
};

export const getReturnToEventId = (
  searchString: string,
): string | undefined => {
  const searchParam = queryString.parse(searchString).returnToEventId;
  if (searchParam && typeof searchParam === "string") {
    return searchParam;
  }
  return undefined;
};

export const appendReturnToEvent = (
  searchString: string,
  eventId: string,
): string => {
  if (eventId.length) {
    const parsed = queryString.parse(searchString);
    parsed.returnToEventId = eventId;
    return queryString.stringify(parsed);
  }
  return searchString;
};

/**
 * Returns a promise which resolves after the provided milliseconds
 */
export function sleep<TResolved = undefined, TRejected = undefined>(
  ms: number,
  resolved?: TResolved,
  rejected?: TRejected,
) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      typeof rejected !== "undefined"
        ? reject(rejected as TRejected)
        : resolve(resolved as TResolved);
    }, ms);
  }) as TRejected extends undefined ? Promise<TResolved> : never;
}
/**
 * Returns the initials for a string.
 *
 * Can be used with the names or email addresses.
 */
export const getInitials = (s: string | undefined | null) => {
  if (!s) return "";
  return words(s.split("@")[0])
    .slice(0, 2)
    .reduce((acc, w) => (acc += w.substr(0, 1)), "")
    .toUpperCase();
};

/**
 * Forwards props to a single component
 */
export function forwardProps<T>(
  component: React.ReactNode,
  props?: T & React.Attributes,
) {
  return isValidElement(component) ? cloneElement(component, props) : null;
}

/**
 * returns a properly formatted first and last name based on a full naem stirn
 */
export const splitFullName = (fullName?: string) => {
  const names = (fullName ?? "").trim().replace(/\s+/g, " ").split(" ");
  return {
    firstName: names[0] ?? "",
    lastName: names?.slice(1)?.join(" ") ?? "",
  };
};

/**
 * Sanitizes HTML output from the editor to remove any tags that are not allowed
 * so that we can display the output in a safe way using `dangerouslySetInnerHTML`
 *
 * Technically we don't need to do this since we don't support dangerous HTML in the editor
 * (img, link, style, iframe, etc...) but just in case
 */
export const sanitizeHTML = (
  html: string,
  overrides?: { textOnly?: boolean },
) => {
  try {
    // we don't want to render <p> tags as we're most likely rendering in a <p> tag
    return html
      ? sanitize(html.replace(/<p>/gi, "").replace(/<\/p>/gi, ""), {
          ADD_ATTR: ["target"],
          ...(overrides?.textOnly ? { ALLOWED_TAGS: [] } : {}),
        })
      : "";
  } catch (err) {
    console.log("Failed to sanitize HTML:", err);
    return null;
  }
};
