import { atom } from "recoil";

import { UserRole } from "../../contracts/user/user";

interface UserRoleAtom {
  /**
   * The user's current role for the application
   */
  role: UserRole;
  /**
   * The user's role as returned by the API
   */
  apiRole: UserRole;
  /**
   * Runtime temporary role which is not saved or persisted
   * but will take precedence over `role` if available
   */
  temporaryRole: UserRole | null;
}

/**
 * Recoil atom which stores the current role of the user
 */
export const userRoleAtom = atom<UserRoleAtom>({
  key: "userRoleAtom",
  default: {
    role: UserRole.Unregistered,
    apiRole: UserRole.Unregistered,
    temporaryRole: null,
  },
});
