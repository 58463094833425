import React, { useCallback } from "react";

import { useCanJoin } from "./useCanJoin";
import { useIsEventLive } from "@src/providers/EventStateProvider";
import { useSetUserRole } from "../../providers/UserProvider";
import { UserRole } from "@src/contracts/user/user";

import AlertDialog from "../dialogs/AlertDialog";
import { toast } from "react-toastify";
import ToastContent from "../ToastContent";

/**
 * Guards against users trying to join as host when the host limit has been reached
 */
const HostGuard = ({ eventId }: { eventId: string }) => {
  const isEventLive = useIsEventLive();
  const setUserRole = useSetUserRole();
  const { showHostGuard, setShowHostGuard, checkCanJoin } = useCanJoin({
    eventId,
  });
  const canJoinAsHost = checkCanJoin?.data?.host?.canJoin !== false;

  const handleCloseDialog = useCallback(() => {
    // downgrade to attendee
    setUserRole(UserRole.Viewer);

    // hide modal
    setShowHostGuard((prev) => ({ ...prev, show: false }));
    // show toast after closing
    toast.warn(
      <ToastContent
        title={
          isEventLive ? "You've joined as an attendee." : "You're an attendee."
        }
        message={
          isEventLive
            ? "A host will need to promote you onstage if you would like to speak."
            : "Please wait for the event to start."
        }
      />,
      { toastId: "HostGuard" },
    );
  }, [isEventLive, setShowHostGuard, setUserRole]);

  return (
    <AlertDialog
      open={!canJoinAsHost && showHostGuard.show}
      maxWidth="xs"
      variant="warning"
      title="Oops! Looks like the host limit has been reached."
      body="You're joining the event as an attendee and we've notified the active host."
      onAction={handleCloseDialog}
      onClose={handleCloseDialog}
    />
  );
};

export default React.memo(HostGuard);
