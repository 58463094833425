import { RefObject, useEffect, useState } from "react";
import fscreen from "fscreen";
import { useSyncedRef, useUnmountEffect } from "@react-hookz/web";

interface UseFullscreenOptions {
  /**
   * Called when fullscreen opens
   */
  onOpen?: () => void;
  /**
   * Called when fullscreen closes
   */
  onClose?: () => void;
}

export default function useFullscreen(
  ref: RefObject<Element>,
  enabled: boolean,
  options: UseFullscreenOptions = {},
) {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const latestOptions = useSyncedRef(options);
  useEffect(() => {
    const element = ref.current;
    // fullscreen not available in browser
    if (!fscreen.fullscreenEnabled || !element) return;

    const handler = () => {
      if (fscreen.fullscreenElement === element) {
        setIsFullscreen(true);
        latestOptions.current.onOpen?.();
      } else {
        setIsFullscreen(false);
        latestOptions.current.onClose?.();
      }
    };

    fscreen.addEventListener("fullscreenchange", handler, { passive: true });

    if (enabled) {
      fscreen.requestFullscreen(element);
    } else {
      !!fscreen.fullscreenElement && fscreen.exitFullscreen();
    }

    return () => {
      fscreen.removeEventListener("fullscreenchange", handler);
    };
  }, [enabled, ref, latestOptions]);

  useUnmountEffect(() => {
    !!fscreen.fullscreenElement && fscreen.exitFullscreen();
  });

  return isFullscreen;
}
