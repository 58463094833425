import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { useEventManagement } from "../EventProvider";
import { EventStatus } from "../../contracts/event/event";
import { QueryKeys } from "../../api/QueryKeys";
import { useCallback } from "react";
import { Api } from "../../api/api";

interface UseEnableAnonymousQnaOptions
  extends UseMutationOptions<
    EventStatus,
    unknown,
    boolean,
    {
      previousEventStatus?: EventStatus;
    }
  > {}
export interface UseEnableAnonymousQnaValue {
  isAnonymousQnaEnabled: boolean;
  handleEnableAnonymousQna: (e: any) => Promise<EventStatus>;
}

export const useEnableAnonymousQna = (
  eventId: string,
  options: UseEnableAnonymousQnaOptions,
): UseEnableAnonymousQnaValue => {
  const { status } = useEventManagement();
  const isAnonymousQnaEnabled = !!status?.isAnonymousQnaOn;

  const { onSuccess, onError, ...rest } = options;
  const queryClient = useQueryClient();

  const mutation = useMutation<
    EventStatus,
    unknown,
    boolean,
    { previousEventStatus?: EventStatus }
  >(
    async (enabled: boolean) =>
      Api.EventApi.EnableAnonymousQna(eventId, enabled),
    {
      onMutate: (enabled: boolean) => {
        const eventStatusQueryKey = QueryKeys.eventStatus(eventId);
        queryClient.cancelQueries(eventStatusQueryKey);

        const previousEventStatus =
          queryClient.getQueryData<EventStatus>(eventStatusQueryKey);

        const updatedEventStatus = {
          ...previousEventStatus,
          isAnonymousQnaOn: enabled,
        } as EventStatus;

        // optimistically update local data
        queryClient.setQueryData(eventStatusQueryKey, updatedEventStatus);

        return { previousEventStatus };
      },
      onSuccess: (data, variables, context) => {
        // invalidate the event status query
        queryClient.invalidateQueries(QueryKeys.eventStatus(eventId));
        onSuccess?.(data, variables, context);
      },
      onError: (err, variables, context) => {
        // reset optimistic update
        queryClient.setQueryData(
          QueryKeys.eventStatus(eventId),
          context?.previousEventStatus,
        );
        onError?.(err, variables, context);
      },
      ...rest,
    },
  );

  const handleEnableAnonymousQna = useCallback(
    (e) => mutation.mutateAsync(e.target.checked),
    [mutation],
  );
  return {
    handleEnableAnonymousQna,
    isAnonymousQnaEnabled,
  };
};
