import { Api } from "../../api/api";
import { useRouteParams } from "@src/hooks/useRouteParams";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import cloneDeep from "lodash/cloneDeep";
import mergeWith from "lodash/mergeWith";
import orderBy from "lodash/orderBy";
import { RequiredKeys } from "@src/types/RequiredKeys";
import { NetworkingHubRoom } from "../../contracts/networking-hub/NetworkingHubRoom";

import { NetworkingHub as ApiNetworkingHub } from "../../contracts/networking-hub/NetworkingHub";
import { Environment, useIsEnvironment } from "../EnvironmentProvider";

import {
  createNetworkingHubQueryKey,
  NetworkingHub,
  TemporaryRoom,
} from "./common";
import { useTemporaryRooms } from "./useTemporaryRooms";
import useDeepMemo from "@src/hooks/useDeepMemo";

type RoomsRecord = Record<string, RequiredKeys<NetworkingHubRoom, "id">>;

/**
 * Merge customizer that overwrites arrays
 */
const mergeCustomizer = (objValue: unknown, srcValue: unknown) =>
  Array.isArray(objValue) ? srcValue : undefined;

interface ParseNetworkingHubRoomsArgs {
  initialNetworkingRooms: NetworkingHubRoom[];
  temporaryRooms: TemporaryRoom[];
  hubId: string | null | undefined;
}

/**
 * Parses and formats both standard networking hub rooms and temporary networking hub rooms,
 * returning a single list of rooms for the hub.
 */
export const formatNetworkingHubRooms = ({
  initialNetworkingRooms,
  temporaryRooms,
  hubId,
}: ParseNetworkingHubRoomsArgs) => {
  const initialRooms: RoomsRecord = Object.fromEntries(
    initialNetworkingRooms.map((room, idx) => {
      // There is some bad data in the DB which leads to missing
      // circle ID's, in this case we need to generate an ID for
      // the circle until we fix the DB
      // The old fix used circle name however our circle webrtc solution
      // breaks due to characters in the name and we cannot guarantee
      // the name will be unique within the hub, so we will prefix
      // with the hub ID and a normalized short string based on the name
      const roomId =
        room.id ||
        // defend against no name just in case the data is really bad
        `${hubId}-${(room.name ?? `${idx}`)
          .toLowerCase()
          .replace(/\s+/g, "-")
          .replace(/[^a-z0-9-]/gm, "")
          .slice(0, 16)}`;
      return [
        roomId,
        {
          ...room,
          id: roomId,
          isFixed: true,
          metadata: room.metadata || {},
        },
      ];
    }),
  );

  // This should fix the disappearing circles (keep an eye on it @JeffreyJoumjian)
  const tempRooms: RoomsRecord = Object.fromEntries(
    orderBy(temporaryRooms, "createdAt", "asc").map(
      ({ slots, metadata, ...entry }) => {
        const initialRoom = initialRooms[entry.id] || {};
        return [
          entry.id,
          {
            ...entry,
            ...initialRoom,
            maxSlots:
              initialRoom.maxSlots ??
              (typeof slots === "string" ? parseInt(slots, 10) : slots),
            metadata: initialRoom.metadata ?? (metadata || {}),
          } as unknown as NetworkingHubRoom,
        ];
      },
    ),
  );

  // create rooms hash map with new objects
  const rooms = mergeWith(cloneDeep(initialRooms), tempRooms, mergeCustomizer);

  return (
    Object.values(rooms).map(({ ownerEmail, ...room }) => ({
      ...room,
      ownerEmail: ownerEmail || [],
    })) ?? []
  );
};

/**
 * Returns a query which queries for the provided hub ID or uses the current
 * route ID if `null` or `undefined` provided.
 *
 * _NOTE_: This will return the room list as returned from the API AND will include
 * any runtime temporary rooms that were created with random mingle or create circle.
 *
 * Example:
 *
 * ```tsx
 * // get a hub by ID
 * useNetworkingHub("hub1");
 *
 * // get the current route hub ID (if on a hub route)
 * useNetworkingHub();
 * ```
 */
export function useNetworkingHub(
  id?: string | null | undefined,
  options: UseQueryOptions<
    ApiNetworkingHub | null,
    unknown,
    NetworkingHub | null
  > = {},
) {
  const { networkingHubId: routeHubId } = useRouteParams();
  const hubId = id ?? routeHubId;
  const isTestEnvironment = useIsEnvironment(Environment.TEST);

  const enabled =
    typeof options.enabled === "boolean" ? options.enabled && !!hubId : !!hubId;

  const {
    query: { data: temporaryRooms },
  } = useTemporaryRooms(hubId);

  const { data, ...query } = useQuery<
    ApiNetworkingHub | null,
    unknown,
    NetworkingHub | null
  >(
    createNetworkingHubQueryKey(hubId),
    () => Api.NetworkingHubApi.GetNetworkingHubV2(hubId as string),
    {
      retry: !isTestEnvironment ? 2 : false,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
      select: (hub) =>
        hub
          ? {
              ...hub,
              hubLocked:
                typeof hub?.eventInfo?.planFeatures?.canUseNetworkingHub ===
                "boolean"
                  ? !hub?.eventInfo?.planFeatures?.canUseNetworkingHub
                  : false,
              networkingHubRooms: hub.networkingHubRooms ?? [],
            }
          : null,
      ...options,
      enabled,
    },
  );

  const rooms = useDeepMemo<NetworkingHubRoom[]>(
    () =>
      formatNetworkingHubRooms({
        initialNetworkingRooms: data?.networkingHubRooms ?? [],
        temporaryRooms: temporaryRooms ?? [],
        hubId: id,
      }),
    [data?.networkingHubRooms, temporaryRooms, id],
  );

  return {
    ...query,
    data: !data
      ? null // parts of the app are expecting this to be null
      : {
          ...data,
          networkingHubRooms: rooms,
        },
  } as UseQueryResult<NetworkingHub | null>;
}
