import { styled, Typography } from "@mui/material";
import Button from "@src/components/buttons/Button";

export const StyledContainer = styled("div")<{ isPending?: boolean }>(
  ({ isPending, theme }) => ({
    position: "relative",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    opacity: isPending ? 0.5 : 1,
    transition: "opacity 150ms ease-in-out",
    "& *": {
      pointerEvents: isPending ? "none" : "auto",
      userSelect: isPending ? "none" : "auto",
    },
  }),
);

export const StyledDescription = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const StyledInfoText = styled(Typography)({
  overflow: "auto",
});

export const ButtonsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: theme.spacing(1),
}));

export const ActionButton = styled(Button)(({ theme }) => ({
  width: "40%",
  marginBottom: theme.spacing(1),
}));
