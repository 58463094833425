import { styled } from "@mui/material/styles";
import MuiDialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";

export const StyledMuiDialog = styled(MuiDialog)(({ theme }) => ({
  ".MuiPaper-root": {
    borderRadius: theme.spacing(3),
  },
}));

export const Wrapper = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(7),
  position: "relative",
}));

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 20px;
`;
