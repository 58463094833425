/***** QUERIES *****/
/**
 * General query used to request the gql socket server to perform socket actions/events
 */
export const RUN_ACTION_QUERY = /* GraphQL */ `
  query RunAction($name: String!, $input: String!) {
    runAction(name: $name, input: $input)
  }
`;

/**
 * Query used to start/restart a user session on the gql socket server.
 * A session is required to execute `runAction` queries.
 */
export const START_SESSION_QUERY = /* GraphQL */ `
  query StartSession($session: String) {
    startSession(session: $session)
  }
`;

/***** MUTATIONS *****/

/***** SUBSCRIPTIONS *****/
/**
 * General subscriptions used to listen to events/updates on the provided room
 */
export const ON_PUBLISH_SUBSCRIPTION = /* GraphQL */ `
  subscription OnPublishTo($room: String!) {
    onPublishTo(room: $room) {
      room
      input
    }
  }
`;

/***** TYPES/UTILS *****/
/**
 * All the actions/events supported by the our graphQl chat-v2-socket-server
 */
export enum Actions {
  CHAT_INVITE = "chat-invite",
  MOVE_USER = "move-user", // possibly deprecated
  PROMOTE_USER = "promote-user",
  CREATE_ROOM = "create-room",
  /** Used to fetch the current users in circles when first joining a Networking Hub */
  LIST_EVENT_ROOMS = "list-event-rooms",
  CREATE_EVENT_POLL = "create-event-poll",
  /** Used to fetch the current list of polls when joining an Event or Networking Hub */
  LIST_EVENT_POLLS = "list-event-polls",
  VOTE_EVENT_POLL_OPTION = "vote-event-poll-option",
  DELETE_EVENT_POLL = "delete-event-poll",
  /** Archiving a poll */
  HIDE_EVENT_POLL = "hide-event-poll",
  UPDATE_EVENT_POLL_STATUS = "update-event-poll-status",
  PUBLISH_EVENT_POLL_RESULTS = "publish-event-poll-results",
  CREATE_EVENT_QUESTION = "create-event-question",
  DELETE_EVENT_QUESTION = "delete-event-question",
  EDIT_EVENT_QUESTION = "edit-event-question",
  /** Used to fetch the current list of questions when joining an Event or Networking Hub */
  LIST_EVENT_QUESTIONS = "list-event-questions",
  ANSWER_EVENT_QUESTION = "answer-event-question",
  VOTE_EVENT_QUESTION = "vote-event-question",
  APPROVE_EVENT_QUESTION = "approve-event-question",
  CIRCLE_INVITE_USER = "circle-invite-user",
  CIRCLE_INVITE_RANDOM = "circle-invite-random",
  CIRCLE_INVITE_RESPONSE = "circle-invite-response",

  /** Sets the eventId in the user's session on the socket server
   * when the user first connects to an Event or Networking Hub
   */
  SET_EVENT = "set-event",
  /** Sets the circleId in the user's session on the socket server when the user joins/leaves a circle */
  SET_CIRCLE = "set-room",
  /** Sets/Updates the userProfile details in the user's session on the socket server */
  SET_USER = "set-user",
  HEARTBEAT = "heartbeat",
  DISCONNECT = "disconnect",
}

export interface OnPublishSubscriptionData {
  /**
   * The room name of the subscription on which the message/update was received
   */
  name: string;
  /**
   * The serialized JSON data of the message
   */
  input: string;
}

/**
 * Transformer functions are used to modify the data before sending them to the socket server
 */
export type ActionTransformer<T = any> = (data: T) => {
  event: string | null;
  data?: T;
};

/**
 * A map of message event names to transformation functions.
 * This is useful to change the event name or event payload before making the GraphQL request.
 *
 * Setting the event to `null` will bypass sending the request.
 */
export const messageTransformers: Record<string, ActionTransformer> = {
  [Actions.SET_EVENT]: (eventId) => ({
    event: Actions.SET_EVENT,
    data: { eventId },
  }),
  [Actions.SET_CIRCLE]: (circle) => ({
    event: Actions.SET_CIRCLE,
    data:
      circle === null
        ? null
        : {
            id: circle?.id,
            name: circle?.name,
          },
  }),
  [Actions.SET_USER]: (data) => ({ event: Actions.SET_USER, data }),
  [Actions.VOTE_EVENT_QUESTION]: (data) => ({
    event: Actions.VOTE_EVENT_QUESTION,
    data: { id: data },
  }),
  [Actions.DELETE_EVENT_QUESTION]: (data) => ({
    event: Actions.DELETE_EVENT_QUESTION,
    data: { id: data },
  }),
  [Actions.PROMOTE_USER]: (data) => ({
    event: Actions.PROMOTE_USER,
    data: { userId: data?.userId },
  }),
};
