/** @jsxImportSource @emotion/react */
import React from "react";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";

import { ClassNames } from "@emotion/react";
import { useTheme } from "@mui/material/styles";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Popover from "@mui/material/Popover";

import ContainedButton from "@src/components/buttons/ContainedButton";
import OutlineButton from "@src/components/buttons/OutlineButton";

export const EndEventButton = (props: {
  hasNetworking?: boolean;
  onConfirm?: (startNetworking: boolean) => void;
  loading: boolean;
  disabled: boolean;
  isNetworkingLive: boolean;
}) => {
  const theme = useTheme();
  const popupState = usePopupState({
    variant: "popover",
    popupId: `mingle-menu`,
  });

  return (
    <ClassNames>
      {({ css }) => (
        <>
          <ContainedButton
            loading={props.loading}
            disabled={props.disabled}
            {...bindTrigger(popupState)}
            backgroundColor={theme.palette.danger.main}
            size="medium"
          >
            End Session
          </ContainedButton>

          <Popover
            classes={{
              root: css`
                margin-top: 8px;
              `,
              paper: css`
                padding: 8px;
              `,
            }}
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <CardContent
              classes={{
                root: css`
                  font-weight: 700;
                `,
              }}
            >
              Are you sure you want to end this session? You can't undo this
              action.
            </CardContent>
            <CardActions
              css={css`
                display: flex;
                justify-content: flex-end;
              `}
            >
              <OutlineButton
                backgroundColor={theme.palette.common.white}
                textColor={theme.palette.common.black}
                onClick={popupState.close}
              >
                Cancel
              </OutlineButton>

              <ContainedButton
                onClick={() => {
                  popupState.close();
                  props.onConfirm?.(false);
                }}
                backgroundColor={theme.palette.danger.main}
                size="medium"
              >
                End Session
              </ContainedButton>

              {props.hasNetworking && !props.isNetworkingLive && (
                <ContainedButton
                  onClick={() => {
                    popupState.close();
                    props.onConfirm?.(true);
                  }}
                  backgroundColor={theme.palette.danger.main}
                  size="medium"
                >
                  End Session and Start Networking
                </ContainedButton>
              )}
            </CardActions>
          </Popover>
        </>
      )}
    </ClassNames>
  );
};
