import { useEffect, useState } from "react";
import { v4 as uuidV4 } from "uuid";
import { ViewerLocation } from "@src/contracts/analytics/event-analytics-contracts";
import useDeepMemo from "@src/hooks/useDeepMemo";
import type { UserContextValue, UserProps } from "../UserProvider";
import { UserQueryResponse } from "./useUserQuery";
import { getUserBaseAvatar } from "@src/helpers/getUserBaseAvatar";
import { useLifecycleGuard } from "@src/hooks/useLifecycleGuard";

interface UseAdaptContextValueOptions {
  userProps?: UserProps;
  userQuery: UserQueryResponse;
}

export interface UserOverrides {
  profilePicture?: string;
}

/**
 * for usage by the UserProvider only
 * this take all of the user data collected from queryParams or API and adapts it into the applications expected format.
 * It will also validate and override some user properties if they are invalid.
 * @returns UserContextValue for the UserProvider
 */
export const useAdaptContextValue = ({
  userProps,
  userQuery,
}: UseAdaptContextValueOptions): UserContextValue | undefined => {
  const [sessionId] = useState(() => uuidV4());
  const [userOverrides, setUserOverrides] = useState<UserOverrides>();
  const withLifecycleGuard = useLifecycleGuard();

  const contextValue: UserContextValue | undefined = useDeepMemo<
    UserContextValue | undefined
  >(() => {
    if (!userProps) return;

    const {
      userId,
      userRole,
      originalId,
      userName,
      userEmail,
      userProfile,
      mode,
    } = userProps as UserProps;

    if (userEmail !== null && userQuery.status === "success") {
      return {
        user: {
          uid: userId,
          originalId,
          email: userEmail,
          name: userName,
          jwtToken: userQuery.data?.jwtToken,
          profilePicture: userProfile,
          sessionId,
          location: {} as ViewerLocation,
          userRole,
          ...userQuery.data,
          ...userOverrides,
          mode,
        },
      };
    }
    if (userQuery.status === "success") {
      return {
        user: {
          // Default to "Unregistered" user
          uid: userId,
          originalId,
          email: userEmail || "",
          name: userName,
          profilePicture: userProfile,
          sessionId,
          location: {} as ViewerLocation,
          userRole,
        },
      };
    }
  }, [userQuery.data, userQuery.status, userProps, sessionId, userOverrides]);

  useEffect(() => {
    const userId = contextValue?.user?.uid;
    const currentUserName = contextValue?.user?.name;
    const currentProfilePicture = contextValue?.user?.profilePicture;

    if (userId && currentUserName && currentProfilePicture) {
      const tester = new Image();
      tester.src = currentProfilePicture;
      tester.onerror = () => {
        const profilePicture = getUserBaseAvatar(userId, currentUserName);

        withLifecycleGuard(() => {
          setUserOverrides((prev) => ({ ...(prev || {}), profilePicture }));
        });
      };
    }
  }, [
    contextValue?.user?.uid,
    contextValue?.user?.name,
    contextValue?.user?.profilePicture,
    withLifecycleGuard,
  ]);

  return contextValue;
};
