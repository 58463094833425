import React, { useMemo } from "react";

import PeopleIcon from "@mui/icons-material/PeopleOutline";
import ChatIcon from "@mui/icons-material/ChatBubbleOutline";
import QnAIcon from "@mui/icons-material/HelpOutline";
import PollsIcon from "@mui/icons-material/BarChart";
import ProfileIcon from "@mui/icons-material/AccountBox";
import SettingsIcon from "@mui/icons-material/Settings";
import useCommonController from "@src/components/SideBar/useCommonController";
import { useNetworkingHub } from "../NetworkingHubProvider";
import { useCircle } from "@src/components/Presence/IntrovokePresence";
import {
  QuestionApprovedStatus,
  useQuestionContext,
} from "../qAndA/QandAProvider";
import { usePollContext } from "../polls/PollProvider";
import { ParticipantNameOption } from "@src/contracts/enums/participant-name-options";
import { UserRole } from "@src/contracts/user/user";
import { FeatureFlag, useFeatureFlag } from "../FeatureFlagsProvider";
import { SideBarTabs } from "@src/components/SideBar/types";
import { ChatTabs } from "@src/components/SideBar/ChatTabs";
import ParticipantsTabContent from "@src/components/SideBar/ParticipantsTabContent";
import { PollsTabs } from "@src/components/Polls/PollsTabs/PollsTabs";
import { QandATabs } from "@src/components/Q&A/QandATabs";
import ProfileTab from "@src/components/Profile/ProfileTab";
import SettingsTab from "@src/components/Settings/SettingsTab";
import { useShouldShowNetworkingHubParticipantNames } from "@src/components/SideBar/hooks/useShouldShowParticipantNames";

export const useNetworkingHubSideBarTabs = () => {
  const { user, participantCount, chatCount, theme, blockUser } =
    useCommonController();
  const { data: networkingHub } = useNetworkingHub();
  const circle = useCircle();

  const { questions, isApproveQnaEnabled } = useQuestionContext();

  const {
    isLoading: isPollsLoading,
    isError: isPollsError,
    pendingPolls,
    activePolls,
    closedPolls,
    archivedPolls,
    handleCreateEventPoll,
    handleVoteEventPollOption,
    handleHideEventPoll,
    handleUpdatePollStatus,
    handlePublishPollResults,
  } = usePollContext();

  const showParticipantNames = useShouldShowNetworkingHubParticipantNames();

  const isQaAndPollsEnabled = useFeatureFlag(FeatureFlag.HUB_QA_AND_POLLS);
  const currentCircle = networkingHub?.networkingHubRooms.find(
    ({ id }) => id === circle?.id,
  );

  const isOrganizer =
    user.userRole === UserRole.Organizer ||
    !!currentCircle?.ownerEmail?.includes(user.email);

  const hideParticipants =
    showParticipantNames === ParticipantNameOption.HOSTS_AND_OWNERS &&
    user.userRole !== UserRole.Organizer
      ? // If were in host and owner mode and the user isn't a host, then hide the chat
        true
      : showParticipantNames === ParticipantNameOption.NONE;
  const hideChat = networkingHub?.whitelabel?.hideChat || !theme?.chat?.enabled;

  // Hide Q&A and Polls when: Feature Flag disable, Q&A Disabled via customization, Current circle doesn't have owners to moderate
  const hideQnA =
    !isQaAndPollsEnabled ||
    !theme?.qna?.enabled ||
    !currentCircle?.ownerEmail?.length;
  const hidePolls =
    !isQaAndPollsEnabled ||
    !theme?.polls?.enabled ||
    !currentCircle?.ownerEmail?.length;
  const hidePrivateChat = !theme?.privateMessages?.enabled;
  const hideSettings = !(
    // if no networking hub
    // if user is not organizer or user does not own at least 1 circle
    (
      networkingHub &&
      (user.userRole === UserRole.Organizer ||
        networkingHub?.networkingHubRooms?.some(
          (room) => room.ownerEmail?.includes(user.email),
        ))
    )
  );

  const tabs = useMemo(
    () =>
      !networkingHub?.uid
        ? null
        : [
            // Chat
            ...(hideChat && !circle?.id
              ? []
              : [
                  {
                    key: SideBarTabs.CHAT,
                    label: "Chat",
                    icon: <ChatIcon />,
                    badgeCount: chatCount,
                    content: (
                      <ChatTabs
                        welcomeOwner={networkingHub.friendlyName}
                        hideMainChat={hideChat}
                        hidePrivateChat={hidePrivateChat}
                        subjectId={networkingHub.uid}
                        showParticipantNames={showParticipantNames}
                      />
                    ),
                  },
                ]),

            // People
            ...(hideParticipants
              ? []
              : [
                  {
                    key: SideBarTabs.PEOPLE,
                    label: "People",
                    icon: <PeopleIcon />,
                    badgeCount: participantCount,
                    persistentBadgeCount: true,
                    content: (
                      <ParticipantsTabContent
                        {...{
                          user,
                          hidePrivateChat,
                          blockUser,
                        }}
                      />
                    ),
                  },
                ]),

            // Q & A
            ...(hideQnA
              ? []
              : [
                  {
                    key: SideBarTabs.QA,
                    label: "Q&A",
                    icon: <QnAIcon />,
                    badgeCount: isOrganizer
                      ? questions.filter(
                          (q) =>
                            !q.answered &&
                            q.approved !== QuestionApprovedStatus.REJECTED,
                        ).length
                      : questions.filter((q) =>
                          isApproveQnaEnabled
                            ? q.approved === QuestionApprovedStatus.APPROVED &&
                              !q.answered
                            : !q.answered,
                        ).length,
                    content: (
                      <QandATabs
                        user={user}
                        isOrganizer={isOrganizer}
                        showParticipantNames={showParticipantNames}
                      />
                    ),
                  },
                ]),

            // Polls
            ...(hidePolls
              ? []
              : [
                  {
                    key: SideBarTabs.POLLS,
                    label: "Polls",
                    icon: <PollsIcon />,
                    badgeCount: activePolls.length,
                    content: (
                      <PollsTabs
                        {...{
                          user,
                          isOrganizer,
                          isLoading: isPollsLoading,
                          isError: isPollsError,
                          showParticipantNames,
                          pendingPolls,
                          activePolls,
                          closedPolls,
                          archivedPolls,
                          handleCreateEventPoll,
                          handleVoteEventPollOption,
                          handleDeleteEventPoll: handleHideEventPoll,
                          handleUpdatePollStatus,
                          handlePublishPollResults,
                        }}
                      />
                    ),
                  },
                ]),

            // Profile
            {
              key: SideBarTabs.PROFILE,
              label: "Profile",
              icon: <ProfileIcon />,
              content: (
                <ProfileTab
                  entityId={networkingHub.uid}
                  user={user}
                  variant="networking"
                />
              ),
            },

            // Settings
            ...(hideSettings
              ? []
              : [
                  {
                    key: SideBarTabs.SETTINGS,
                    label: "Settings",
                    icon: <SettingsIcon />,
                    content: <SettingsTab />,
                  },
                ]),
          ],
    [
      hideParticipants,
      isApproveQnaEnabled,
      isPollsLoading,
      isPollsError,
      hideChat,
      hideQnA,
      hidePolls,
      participantCount,
      chatCount,
      hidePrivateChat,
      hideSettings,
      networkingHub?.friendlyName,
      networkingHub?.uid,
      circle?.id,
      user,
      isOrganizer,
      blockUser,
      showParticipantNames,
      questions,
      pendingPolls,
      activePolls,
      closedPolls,
      archivedPolls,
      handleCreateEventPoll,
      handleVoteEventPollOption,
      handleHideEventPoll,
      handleUpdatePollStatus,
      handlePublishPollResults,
    ],
  );

  return tabs;
};
