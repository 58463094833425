/** @jsxImportSource @emotion/react */

import React, { memo } from "react";

import { ClassNames } from "@emotion/react";
import Button, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

export interface BaseButtonProps extends ButtonProps {
  loading?: boolean;
}

export const BaseButton = memo(
  ({ children, loading, sx, ...props }: BaseButtonProps) => {
    return (
      <ClassNames>
        {({ css }) => (
          <>
            <Button
              variant="outlined"
              sx={{
                position: "relative",
                borderRadius: "10px",
                fontSize: "12px",
                ...(sx || {}),
              }}
              {...props}
            >
              {children}
              {loading && (
                <div
                  css={css`
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: auto;
                    top: 0;
                    bottom: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  `}
                >
                  <CircularProgress size={24} />
                </div>
              )}
            </Button>
          </>
        )}
      </ClassNames>
    );
  },
);
