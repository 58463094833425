import React, { memo } from "react";
import { useField } from "formik";
import { Box, Button, Typography, useTheme } from "@mui/material";
import PlayCircleOutlineRounded from "@mui/icons-material/PlayCircleOutlineRounded";
import { ReplayUrl } from "../../contracts/replay/replay-url";
import { isMatchingUrl } from "../../providers/EventReplaysProvider";
import { useEventState } from "../../providers/EventStateProvider";

type Props = { urlInfo: ReplayUrl; index: number };

const ReplayListItem = ({ urlInfo, index }: Props) => {
  const { url, isCustomReplay } = urlInfo;

  const eventState = useEventState();
  const theme = useTheme();
  const [selectedReplay, , helpers] = useField<string>("replayUrl");
  const selectReplay = (streamId: string) => helpers.setValue(streamId);

  const isSelected = isMatchingUrl(selectedReplay.value, url);
  const currentlyPublished = isMatchingUrl(eventState.replayUrl, url);
  const color = isSelected ? "white" : theme.palette.primary.main;

  return (
    <Button
      color="primary"
      sx={{
        marginBottom: ".5em",
        textAlign: "start",
        width: "100%",
      }}
      key={`${url}_${index}`}
      onClick={() => selectReplay(url)}
      variant={isSelected ? "contained" : "outlined"}
    >
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography>{`${isCustomReplay ? "CUSTOM" : "REPLAY"} #${
          index + 1
        }`}</Typography>
        {currentlyPublished ? (
          <PlayCircleOutlineRounded
            sx={{
              color: color,
            }}
          />
        ) : null}
      </Box>
    </Button>
  );
};

export default memo(ReplayListItem);
