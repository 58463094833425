import { useMemo } from "react";
import { Route, useIsRoute } from "@src/hooks/useIsRoute";
import { useEvent } from "@src/providers/EventProvider";
import { useNetworkingHub } from "@src/providers/NetworkingHubProvider";

type UseEventOrNetworkingHubResult =
  | ({
      isEvent: true;
      isNetworkingHub: false;
    } & ReturnType<typeof useEvent>)
  | ({
      isEvent: false;
      isNetworkingHub: true;
    } & ReturnType<typeof useNetworkingHub>);

export const useEventOrNetworkingHub = (): UseEventOrNetworkingHubResult => {
  const isEvent = useIsRoute(Route.EVENT);

  const event = useEvent();
  const networkingHub = useNetworkingHub();

  return useMemo<UseEventOrNetworkingHubResult>(
    () =>
      isEvent
        ? {
            isEvent: true,
            isNetworkingHub: false,
            ...event,
          }
        : { isEvent: false, isNetworkingHub: true, ...networkingHub },
    [event, isEvent, networkingHub],
  );
};
