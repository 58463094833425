import {
  QuestionApprovedStatus,
  UserQuestion,
} from "@src/providers/qAndA/QandAProvider";

interface calculateQuestionBadgeCountArgs {
  isOrganizer?: boolean;
  isApproveQnaEnabled?: boolean;
  questions: UserQuestion[];
}

export const calculateQuestionsBadgeCount = ({
  questions,
  isOrganizer,
  isApproveQnaEnabled,
}: calculateQuestionBadgeCountArgs) => {
  return isOrganizer
    ? /**
       * if host count all questions:
       * - which are not marked as answered and
       * - which are not rejected if approve QnA disabled or
       * - which are approved if approve QnA enabled
       */
      questions.filter(
        (q) =>
          !q.answered &&
          (isApproveQnaEnabled
            ? q.approved !== QuestionApprovedStatus.REJECTED
            : q.approved === QuestionApprovedStatus.APPROVED),
      ).length
    : // for all other users => count all questions which are approved and not answered
      // (questions are approved by default when created in non-approve mode)
      questions.filter(
        (q) => q.approved === QuestionApprovedStatus.APPROVED && !q.answered,
      ).length;
};
