import { emitAsPromise } from "@src/components/Presence/emitAsPromise";
import { Actions } from "@src/graphql/schemas/actions";
import { useCallback, useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  Question,
  QuestionActionHookOptions,
  QuestionAnswerEventInput,
  QuestionActionRollbackFunction,
  UserQuestion,
} from "../types";
import { createQuestionsQueryKey, sortQuestions } from "../helpers";

/**
 * Callback to update the answered question in the supplied local questions array
 */
const updateAnsweredQuestion =
  (data: QuestionAnswerEventInput) => (prev?: UserQuestion[] | null) => {
    const previousQuestions = Array.isArray(prev) ? prev : [];
    return sortQuestions(
      previousQuestions.map((question) => {
        if (question.id !== data.id) {
          return question;
        }

        return {
          ...question,
          answered: !!data.answered,
          answer: data.answer,
          isPending: !!data.isPending,
        };
      }),
    );
  };

export interface UseAnswerEventQuestionOptions
  extends QuestionActionHookOptions {}

export const useAnswerEventQuestion = ({
  client,
  eventId,
  circleId,
}: UseAnswerEventQuestionOptions) => {
  const cache = useQueryClient();
  const queryKey = createQuestionsQueryKey(eventId, circleId);

  /**
   * Mutation to mark a question as answered or add an answer to the question
   */
  const mutation = useMutation<
    Question,
    unknown,
    QuestionAnswerEventInput,
    QuestionActionRollbackFunction
  >(
    (question) =>
      emitAsPromise<Question>(client, Actions.ANSWER_EVENT_QUESTION, question),
    {
      onMutate: (question) => {
        const previousQuestions =
          cache.getQueryData<UserQuestion[]>(queryKey) || [];

        cache.setQueryData(
          queryKey,
          updateAnsweredQuestion({ ...question, isPending: true }),
        );

        return () => cache.setQueryData(queryKey, previousQuestions);
      },
      onError: (err, variables, rollback) => {
        // rollback on error (success is handled by the handleQuestionAnswered)
        rollback?.();
        toast.error("There was an error answering the question");
      },
    },
  );

  /**
   * Callback to run when a `question-answered` event is received from the client server
   */
  const handleQuestionAnswered = useCallback(
    (data: Question) => {
      cache.setQueryData<UserQuestion[]>(
        createQuestionsQueryKey(data.eventId, data.circleId),
        updateAnsweredQuestion(data),
      );
    },
    [cache],
  );

  return useMemo(
    () => ({
      mutation,
      handleQuestionAnswered,
    }),
    [handleQuestionAnswered, mutation],
  );
};

export const __testable__ = {
  updateAnsweredQuestion,
};
