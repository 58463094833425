import React from "react";
import { Button } from "@mui/material";

import {
  PollResultsType,
  PollStatus,
} from "../../providers/polls/PollProvider";
import { useDialog } from "@src/providers/DialogProvider";
import { CreatePollForm } from "./CreatePollForm/CreatePollForm";
import { nanoid } from "nanoid";

export interface PollFormValues {
  name: string;
  options: { name: string }[];
  queuePoll: boolean;
  delayResults: boolean;
  resultsType: PollResultsType;
}

export interface CreatePollButtonProps {
  onSubmit?: (
    poll: Omit<PollFormValues, "queuePoll" | "resultsAsPercent"> & {
      id?: string;
      status: PollStatus;
    },
  ) => void;
  navigateToTab: (tab: PollStatus) => void;
}

export const CreatePollButton: React.FC<CreatePollButtonProps> = ({
  onSubmit,
  navigateToTab,
}) => {
  const { openDialog } = useDialog();

  const handleSubmit = (
    poll: Omit<PollFormValues, "resultsAsPercent"> & {
      status: PollStatus;
    },
  ) => {
    navigateToTab(
      poll.status === PollStatus.PENDING
        ? PollStatus.PENDING
        : PollStatus.ACTIVE,
    );
    onSubmit?.({
      ...poll,
      id: nanoid(),
      status: poll.status ?? PollStatus.PENDING,
    });
  };

  const handleOpen = () => {
    openDialog("CreatePollDialog", <CreatePollForm onSubmit={handleSubmit} />);
  };

  return (
    <Button
      data-testid="create-poll-button"
      onClick={handleOpen}
      variant="contained"
      color="primary"
      fullWidth
      size="large"
    >
      Create New Poll
    </Button>
  );
};
