import React, { ReactElement, ReactNode } from "react";
import { Box, BoxProps, Typography } from "@mui/material";

export interface SettingsGroupProps extends BoxProps {
  title: string;
  description?: string;
  content?: ReactElement | ReactNode | null;
}

export const SettingsGroup: React.FC<SettingsGroupProps> = React.memo(
  ({ title, description, content, children, ...restProps }) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mb: 4,
        "&:last-of-type": {
          mb: 0,
        },
      }}
      {...restProps}
    >
      {/* group header */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
        <Typography variant="h4">{title}</Typography>
        {/* description */}
        {description && (
          <Typography variant="caption">{description}</Typography>
        )}
      </Box>
      {/* content */}
      {content || children}
    </Box>
  ),
);

export interface SettingsSectionProps {
  title: string;
  content?: ReactElement | ReactNode | null;
}
export const SettingsSection: React.FC<SettingsSectionProps> = React.memo(
  ({ title, content, children }) => {
    return (
      <Box
        sx={({ palette }) => ({
          pb: 5,
          mb: 4,
          borderBottom: `1px solid ${palette.divider}`,
          "&:last-of-type": {
            pb: 0,
            borderBottom: "unset",
          },
        })}
      >
        {/* section header */}
        <Typography
          variant="h4"
          sx={({ palette }) => ({
            fontSize: 18,
            mb: 1.5,
          })}
        >
          {title}
        </Typography>

        {/* section content */}
        <div>{content || children}</div>
      </Box>
    );
  },
);
