import { useRef, useEffect } from "react";
import isEqual from "fast-deep-equal/es6";

/**
 * Helper function to check for dependency changes
 */
const hasDepsChanged = (
  deps1 = [] as any[],
  deps2 = [] as any[],
  deepCompare = false,
) => {
  return deepCompare
    ? !isEqual(deps1, deps2)
    : !(
        Array.isArray(deps1) &&
        Array.isArray(deps2) &&
        deps1.length === deps2.length &&
        deps1.every((dep, idx) => Object.is(dep, deps2[idx]))
      );
};

/**
 * A hook that immediately runs the effect within the render
 * function instead of after the render is committed to the screen
 * like the traditional `useEffect` hook
 *
 * @param effect The effect function
 * @param deps The dependency array
 * @param deepCompare Whether the effect should use deep comparison before running again (Defaults to false)
 */
export default function useImmediateEffect(
  effect: Function,
  deps: any[],
  deepCompare = false,
) {
  const isInitialMount = useRef(true);
  const depsRef = useRef<any>();
  const cleanupRef = useRef<undefined | Function>();

  if (
    isInitialMount.current ||
    hasDepsChanged(depsRef.current, deps, deepCompare)
  ) {
    isInitialMount.current = false;
    depsRef.current = deps;

    // Cleanup last effect
    cleanupRef.current?.();

    // Run new effect
    cleanupRef.current = effect();
  }

  useEffect(() => {
    return () => {
      cleanupRef.current?.();
    };
  }, []);
}
