import gql from "graphql-tag";

const EVENT_STATUS_INFO = gql`
  fragment EventStatusInfo on EventStatus {
    eventId
    isEventLive
    isNetworkingLive
    eventLivestreamStartTime
    eventLivestreamEndTime
    replayEnabled
    returnToEvent
    replayUrl
    streamPlaceholderMessage
    isAnonymousQnaOn
    isApproveQnaOn
    isChatReactionsOn
    isLiveReactionsOn
    blockedEmails
  }
`;

export const GET_EVENT_STATUS = gql`
  query GetEventStatus($eventId: String!) {
    getEventStatus(eventId: $eventId) {
      ...EventStatusInfo
    }
  }
  ${EVENT_STATUS_INFO}
`;

export const SUBSCRIBE_EVENT_STATUS = gql`
  subscription OnEventStatusChange($eventId: String!) {
    onEventStatusChange(eventId: $eventId) {
      ...EventStatusInfo
    }
  }
  ${EVENT_STATUS_INFO}
`;
