/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";
import invariant from "tiny-invariant";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";

import { UserRole } from "../../../contracts/user/user";
import { useEvent } from "../../../providers/EventProvider";
import {
  EventStreamingStatus,
  StageType,
  useEventState,
  useEventStreamingStatus,
} from "../../../providers/EventStateProvider";
import { ActionButton } from "../../buttons/ActionButton";
import { NetworkingHubIcon } from "../../icons";
import { EndEventButton } from "./EndEventButton";
import HeaderLegacy from "./HeaderLegacy";
import { StartEventButton } from "./StartEventButton";
import EditReplayButton from "../../EventReplayManagement/EditReplayButton";
import { EditReplayModal } from "../../EventReplayManagement/EditReplayModal";
import { useHandleJoinNetworking } from "./useHandleJoinNetworking";
import EndNetworkingButton from "./EndNetworkingButton";
import EnterPasscodeModalButton from "../../buttons/EnterPasscodeModalButton";
import { LeaveStageButton } from "./LeaveStageButton";
import { useUserRole } from "../../../providers/UserProvider";
import { isOptimalBrowser } from "../../../helpers/browser";
import { useDialog } from "@src/providers/DialogProvider";
import { StageHeaderBaseButton } from "./StageHeaderBaseButton";

interface StageHeaderProps {
  onEventStart?: () => void;
  onEventEnd?: () => void;
  onNetworkingStart?: () => void;
  onNetworkingEnd?: (returnToEvent: boolean) => void;
  isStreamLive?: boolean;
  isGoLiveDisabled?: boolean;
}

const ViewerStageActions = () => {
  const { data: event } = useEvent();
  const { enterStage, isNetworkingLive, isEventLive } = useEventState();
  const userRole = useUserRole();
  const handleNetworkingJoin = useHandleJoinNetworking();

  const optimalBrowser = isOptimalBrowser();

  const joinNetworkingButton = isNetworkingLive && (
    <StageHeaderBaseButton
      onClick={handleNetworkingJoin}
      startIcon={
        <SvgIcon>
          <NetworkingHubIcon />
        </SvgIcon>
      }
    >
      Join Networking
    </StageHeaderBaseButton>
  );

  if ([UserRole.Presenter, UserRole.Organizer].includes(userRole)) {
    return (
      <>
        {event?.isRegistrationModeEnabled && <EnterPasscodeModalButton />}
        <EditReplayButton danger label="End Session and Edit Placeholder" />
        <Tooltip
          title={
            !optimalBrowser ? (
              <>
                {
                  "Your browser is not compatible. If you wish to join virtually, please download the "
                }
                <a
                  href="https://www.google.com/chrome/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  latest version of Chrome.
                </a>
              </>
            ) : (
              ""
            )
          }
        >
          <span style={{ display: "block" }}>
            <ActionButton disabled={!optimalBrowser} onClick={enterStage}>
              {isEventLive ? "Join Backstage" : "Enter Greenroom"}
            </ActionButton>
          </span>
        </Tooltip>
        {joinNetworkingButton}
      </>
    );
  }

  return (
    <>
      {event?.isRegistrationModeEnabled && <EnterPasscodeModalButton />}
      {joinNetworkingButton}
    </>
  );
};

const StageActions: React.FC<
  StageHeaderProps & { showLeaveStage?: boolean }
> = ({
  onEventStart: handleEventStart,
  onEventEnd: handleEventEnd,
  onNetworkingStart,
  onNetworkingEnd,
  isStreamLive,
  showLeaveStage = false,
  isGoLiveDisabled,
}) => {
  const { isNetworkingLive, leaveStage, ...eventState } = useEventState();
  const userRole = useUserRole();
  const eventStatus = useEventStreamingStatus();
  const status = isStreamLive ? EventStreamingStatus.Live : eventStatus;
  const { data: event } = useEvent();
  invariant(event, "Event is required");
  const { openDialog, hideDialog } = useDialog();
  const handleNetworkingJoin = useHandleJoinNetworking();

  const hasNetworking = Boolean(event.networkingHub);

  const handleNetworkingStart = () => {
    setLoading(true);
    onNetworkingStart?.();
  };

  const handleNetworkingEnd = (returnToEvent: boolean) => {
    setLoading(true);
    onNetworkingEnd?.(returnToEvent);
  };

  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(false);
  }, [isNetworkingLive]);

  if (userRole === UserRole.Organizer) {
    return (
      <>
        {event?.isRegistrationModeEnabled && <EnterPasscodeModalButton />}
        {hasNetworking && !isNetworkingLive && (
          <StageHeaderBaseButton
            loading={isLoading}
            disabled={isNetworkingLive || isLoading}
            onClick={handleNetworkingStart}
            startIcon={
              <SvgIcon>
                <NetworkingHubIcon />
              </SvgIcon>
            }
          >
            Activate Pre-Networking
          </StageHeaderBaseButton>
        )}
        {hasNetworking && isNetworkingLive && (
          <>
            <StageHeaderBaseButton
              disabled={isLoading}
              onClick={handleNetworkingJoin}
              startIcon={
                <SvgIcon>
                  <NetworkingHubIcon />
                </SvgIcon>
              }
            >
              Join Networking
            </StageHeaderBaseButton>
            <EndNetworkingButton
              buttonLocationId="stage-header"
              loading={isLoading}
              onConfirm={handleNetworkingEnd}
              returnToEventId={event.uid}
            />
          </>
        )}
        {/* TODO: possiblt remove LeaveStageButton completely once feedback is finalized */}
        {showLeaveStage && <LeaveStageButton onConfirm={leaveStage} />}
        {status === EventStreamingStatus.NotLive && (
          <StartEventButton
            onConfirm={handleEventStart}
            goLiveLocked={event.goLiveLocked}
            disabled={isGoLiveDisabled}
          />
        )}
        {status === EventStreamingStatus.Live && (
          <EndEventButton
            onConfirm={(startNetworking: boolean) => {
              handleEventEnd?.();
              if (startNetworking) {
                handleNetworkingStart?.();
              }
              // if event is "auto-selecting" the replay, skip replay modal
              if (!event?.autoSelectLatestReplay) {
                // allow organizers to edit the replay or placeholder text after event
                openDialog(
                  "edit-replay-dialog",
                  <EditReplayModal
                    replayConfig={{
                      replayEnabled: eventState?.replayEnabled,
                      replayUrl: eventState?.replayUrl,
                      streamPlaceholderMessage:
                        eventState?.streamPlaceholderMessage,
                    }}
                    onClose={() => {
                      hideDialog("edit-replay-dialog");
                    }}
                  />,
                );
              }
            }}
            hasNetworking={hasNetworking}
          />
        )}
      </>
    );
  } else if (userRole === UserRole.Presenter && showLeaveStage) {
    return <LeaveStageButton onConfirm={leaveStage} />;
  }

  return null;
};

export const StageHeaderLegacy: React.FC<StageHeaderProps> = ({
  onEventStart,
  onEventEnd,
  onNetworkingStart,
  onNetworkingEnd,
  isStreamLive,
  isGoLiveDisabled,
}) => {
  const { stage, eventLivestreamStartTime } = useEventState();
  const { data: event } = useEvent();
  invariant(event, "Event is required");

  return (
    <HeaderLegacy
      name={event.name}
      isLive={!!isStreamLive}
      liveStart={eventLivestreamStartTime}
      scheduledStart={event.startDate}
      scheduledEnd={event.endDate}
      actions={
        stage === StageType.Viewer ? (
          <ViewerStageActions />
        ) : (
          <StageActions
            onEventStart={onEventStart}
            onEventEnd={onEventEnd}
            onNetworkingStart={onNetworkingStart}
            onNetworkingEnd={onNetworkingEnd}
            isGoLiveDisabled={isGoLiveDisabled}
          />
        )
      }
    />
  );
};
