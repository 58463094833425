// File adapted from https://github.com/aws-samples/amazon-ivs-auto-captions-web-demo/blob/main/web-ui/player-app/src/helpers/iosCaption.js
import { CloseCaption } from "@src/types/graphQl";
import { MediaPlayer } from "../IvsVideoPlayer/ivsTypes";

const DEFAULT_PLAYER_POSITION_S = 10;
const LONG_STREAM_DIFFERENCE_S = 22;
const SHORT_STREAM_DIFFERENT_S = 16;
const PARTIAL_END_TIME_S = 6;
const NON_PARTIAL_END_TIME_S = 0;

/**
 * Attempts to obtain the streams manifest from the given stream URL.
 *
 * If successful, will attempt to read the stream start time from
 * the manifest and correlate the correct starting time offset in seconds.
 *
 * @param streamUrl The URL for the stream to pull manifest data from
 * @param ivsPlayer The IVS player instance used for getting the current play position
 * @returns The starting offset if found, undefined if not
 */
export const getManifestStreamTime = async (
  streamUrl: string,
  ivsPlayer: MediaPlayer,
) => {
  try {
    const response = await fetch(streamUrl);
    if (!response.ok) {
      throw new Error(
        `Failed to fetch stream manifest, invalid response code: ${response.status}, ${response.statusText}`,
      );
    }

    const data = await response.text();
    const manifest = data.split("\n");
    const manifestMap: Record<string, string | null> = {};

    manifest.forEach((dataPair) => {
      const pair = dataPair.split(",");
      if (pair[0]) {
        manifestMap[pair[0]] = pair[1] ?? null;
      }
    });

    const value =
      manifestMap['#EXT-X-SESSION-DATA:DATA-ID="STREAM-TIME"']?.split('"')[1];

    if (!value) {
      throw new Error("Manifest does not contain stream starting time");
    }

    // The position varies if the stream just started or not. Depending on that the difference changes
    const difference =
      ivsPlayer.getPosition() > DEFAULT_PLAYER_POSITION_S
        ? LONG_STREAM_DIFFERENCE_S
        : SHORT_STREAM_DIFFERENT_S;

    return Number(value) - ivsPlayer.getPosition() - difference;
  } catch (error) {
    console.error(
      "Unable to get information about stream time. Error details: ",
      error,
    );
  }
};

/**
 * Determines whether a caption should be shown based on start/end times, player position
 * and a starting offset.
 *
 * @param startOffset The starting offset for the stream, can be obtained via `getManifestStreamTime` function
 * @param data The caption data to attempt showing
 * @param player The IVS player instance to get player positioning
 * @param showCaptions A function that takes caption start/end times and caption text to display in a track
 * @param shiftCaptions A function that is called when the supplied caption should be considered invalid
 */
export const showIOSCaption = (
  startOffset: number | undefined,
  data: CloseCaption,
  player: MediaPlayer,
  showCaptions: (start: number, end: number, text: string) => void,
  shiftCaptions: () => void,
) => {
  if (typeof startOffset === "undefined") {
    return;
  }

  const playerPosition = player.getPosition();
  const addEndTime =
    data.partial === true ? PARTIAL_END_TIME_S : NON_PARTIAL_END_TIME_S;
  const startTime = data.startTime - startOffset;
  const endTime = data.endTime - startOffset + addEndTime;

  if (startTime > 0) {
    if (startTime <= playerPosition && endTime >= playerPosition) {
      showCaptions(startTime, endTime, data.text);
    } else if (endTime <= playerPosition) {
      shiftCaptions();
    }
  } else {
    shiftCaptions();
  }
};
