import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import AbsoluteCenterPage from "@src/components/AbsoluteCenterPage";

export const FullPageLoader = () => (
  <AbsoluteCenterPage>
    {/* TODO: This is shown before we obtain the custom theme, need to show something generic until we fix ordering */}
    <CircularProgress sx={{ color: "#777" }} />
  </AbsoluteCenterPage>
);
