import { emitAsPromise } from "@src/components/Presence/emitAsPromise";
import { Actions } from "@src/graphql/schemas/actions";
import { useCallback, useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { createPollsQueryKey } from "../helpers";
import {
  Poll,
  PollActionHookOptions,
  PollActionRollbackFunction,
  SelectEventPollInput,
} from "../types";

/**
 * Callback to remove the hidden poll from the supplied local polls array
 */
export const removeHiddenPoll =
  (data: Poll) =>
  (prev?: Poll[] | null): Poll[] => {
    const previousPolls = Array.isArray(prev) ? prev : [];
    return previousPolls.filter((poll) => poll.id !== data.id);
  };

export interface UseHideEventPollOptions extends PollActionHookOptions {}

/**
 * Hook that soft-deletes (hides) an existing poll.
 * Hidden polls are not included when fetching from the client server
 */
export const useHideEventPoll = ({
  client,
  eventId,
  circleId,
}: UseHideEventPollOptions) => {
  const cache = useQueryClient();
  const queryKey = createPollsQueryKey(eventId, circleId);

  /**
   * Mutation that soft-delete an existing poll
   */
  const mutation = useMutation<
    Poll,
    unknown,
    SelectEventPollInput,
    PollActionRollbackFunction
  >(
    (input: SelectEventPollInput) =>
      emitAsPromise<Poll>(client, Actions.HIDE_EVENT_POLL, input),
    {
      onMutate: (poll) => {
        const previousPolls = cache.getQueryData<Poll[]>(queryKey);

        // optimistically remove deleted poll from cache
        cache.setQueryData<Poll[]>(
          queryKey,
          removeHiddenPoll({ id: poll.poll } as Poll),
        );

        return () => cache.setQueryData(queryKey, previousPolls);
      },
      onError: (err, variables, rollback) => {
        // rollback on error (success is handled by the handlePollHidden)
        rollback?.();
        toast.error("There was an error deleting the poll");
      },
    },
  );

  /**
   * Callback to run when a `poll-hidden` event is received from the client server
   */
  const handlePollHidden = useCallback(
    (data: Poll) => {
      cache.setQueryData<Poll[]>(
        createPollsQueryKey(data.eventId, data.circleId),
        removeHiddenPoll(data),
      );
    },
    [cache],
  );

  return useMemo(
    () => ({ mutation, handlePollHidden }),
    [handlePollHidden, mutation],
  );
};

export const __testable__ = {
  removeHiddenPoll,
};
