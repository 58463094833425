/** @jsxImportSource @emotion/react */

import React, { memo } from "react";
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";

import { ClassNames } from "@emotion/react";
import { CardActions, CardContent, Popover } from "@mui/material";
import { BaseButton } from "../../buttons/BaseButton";
import { DangerButton } from "../../buttons/DangerButton";

export const LeaveStageButton = memo((props: { onConfirm?: () => void }) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: `mingle-menu`,
  });

  return (
    <ClassNames>
      {({ css }) => (
        <>
          <DangerButton {...bindTrigger(popupState)}>
            Return to Audience
          </DangerButton>
          <Popover
            classes={{
              root: css`
                margin-top: 20px;
              `,
              paper: css`
                padding: 8px;
              `,
            }}
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <CardContent
              classes={{
                root: css`
                  font-weight: 700;
                `,
              }}
            >
              Are you sure you want to return to the audience?
            </CardContent>
            <CardActions
              css={css`
                display: flex;
                justify-content: flex-end;
              `}
            >
              <BaseButton color="secondary" onClick={popupState.close}>
                Cancel
              </BaseButton>

              <DangerButton
                onClick={() => {
                  popupState.close();
                  props.onConfirm?.();
                }}
              >
                Yes, Return to Audience
              </DangerButton>
            </CardActions>
          </Popover>
        </>
      )}
    </ClassNames>
  );
});
