import React, { ReactNode } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DialogProps } from "@mui/material/Dialog";

import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

import Button from "@src/components/buttons/Button";

export const DEFAULT_ICON_SIZE = 80;
export const SMALL_ICON_SIZE = 40;

export interface AlertDialogContentProps
  extends Pick<DialogProps, "onClose" | "sx"> {
  /**
   * The alert variant, this will affect the icon that is shown (defaults to info)
   */
  variant?: "error" | "warning" | "info" | "success";
  /**
   * Optional custom icon to override the default variant icon
   */
  icon?: ReactNode;
  iconSize?: "default" | "small";
  /**
   * Custom text for the OK button, defaults to `"OK"`
   */
  buttonText?: string;
  /**
   * The title of the alert, should be either a string or a `ReactNode`
   */
  title?: ReactNode;
  /**
   * The body of the alert, should be either a string or a `ReactNode`
   */
  body: ReactNode;
  /**
   * The info shown at the bottom of the alert, should be either a string or a `ReactNode`
   */
  footer?: ReactNode;
  /**
   * Set to `true` to hide the variant icon
   */
  hideIcon?: boolean;
  /**
   * Set to `true` to hide the action button
   */
  hideAction?: boolean;
  /**
   * The optional callback to trigger when the action button is clicked.
   */
  onAction?: () => void;
}

const getIconVariant = (variant: AlertDialogContentProps["variant"]) => {
  switch (variant) {
    case "warning":
      return WarningAmberRoundedIcon;
    case "info":
      return InfoOutlinedIcon;
    case "success":
      return CheckCircleOutlineRoundedIcon;
    default:
      return ErrorOutlineRoundedIcon;
  }
};

const IconVariant = React.memo(function IconVariant({
  variant = "info",
  iconSize = "default",
}: Pick<AlertDialogContentProps, "variant" | "iconSize">) {
  const IconComponent = getIconVariant(variant);
  const size = iconSize === "default" ? DEFAULT_ICON_SIZE : SMALL_ICON_SIZE;
  return (
    <Box
      p={1}
      borderRadius="100%"
      bgcolor={({ palette }) => palette[variant].light}
      width={size}
      height={size}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <IconComponent
        data-testid={`${variant}-alert-dialog-icon`}
        sx={{
          fontSize: "3rem",
          color: ({ palette }) => palette[variant].dark,
          width: size / 1.25,
          height: size / 1.25,
          mb: variant === "warning" ? 0.75 : 0, // offset MUI icon spacing
        }}
      />
    </Box>
  );
});

export const ActionsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  gap: theme.spacing(3),
  "& .MuiButton-root": {
    flex: 1,
  },
}));

const AlertDialogContent = ({
  icon,
  variant = "info",
  buttonText = "Done",
  title = "Oops! Looks Like There's a Problem",
  body,
  footer,
  onAction,
  onClose,
  hideIcon = false,
  hideAction = false,
  iconSize,
  sx = {},
}: AlertDialogContentProps) => (
  <Box
    id="alert-dialog-description"
    data-testid={`${variant}-alert-dialog-content`}
    sx={sx}
  >
    <Box display="flex" flexDirection="column" alignItems="center">
      {!hideIcon &&
        (icon || <IconVariant variant={variant} iconSize={iconSize} />)}
      {typeof title === "string" ? (
        <Typography
          mt={hideIcon ? 0 : 2}
          id="alert-dialog-title"
          variant="h2"
          textAlign="center"
        >
          {title}
        </Typography>
      ) : (
        title
      )}
      <Box mt={2} mb={3}>
        {typeof body === "string" ? (
          <Typography textAlign="center" variant="body2" fontWeight="normal">
            {body}
          </Typography>
        ) : (
          body
        )}
      </Box>

      {!hideAction && (
        <Button
          data-testid={`${variant}-alert-dialog-action`}
          variant="contained"
          color="primary"
          // @ts-ignore
          onClick={(event, reason) => {
            onAction?.();
            onClose?.(event, reason);
          }}
          sx={{ width: "75%" }}
        >
          {buttonText}
        </Button>
      )}

      {footer && (
        <Box mt={2} width="100%">
          {typeof footer === "string" ? (
            <Typography textAlign="center">{footer}</Typography>
          ) : (
            footer
          )}
        </Box>
      )}
    </Box>
  </Box>
);
export default React.memo(AlertDialogContent);
