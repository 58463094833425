import React, { memo } from "react";
import Typography from "@mui/material/Typography";
import MuiTooltip, {
  TooltipProps as MuiTooltipProps,
} from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { Theme } from "@mui/material";

export interface TooltipProps extends Omit<MuiTooltipProps, "title"> {
  description: string;
  title?: string;
}

const componentsProps = {
  popper: {
    sx: ({ spacing, palette }: Theme) => ({
      "& .MuiTooltip-tooltip": {
        backgroundColor: palette.primary.main,
        padding: spacing(2.5),
        borderRadius: "8px",
      },
    }),
  },
  arrow: {
    sx: ({ palette }: Theme) => ({
      color: palette.primary.main,
    }),
  },
} as TooltipProps["componentsProps"];

export const Tooltip = memo(
  ({ title, description, children, ...restProps }: TooltipProps) => {
    return (
      <MuiTooltip
        {...restProps}
        arrow
        componentsProps={componentsProps}
        title={
          <Box>
            {title && (
              <Typography variant="h2" sx={{ marginBottom: 1.5 }}>
                {title}
              </Typography>
            )}

            <Typography>{description}</Typography>
          </Box>
        }
      >
        {children}
      </MuiTooltip>
    );
  },
);
