import { UserRole } from "../contracts/user/user";

export const QueryKeys = {
  userToken: ({
    userId,
    userRole,
    eventId,
    betaOptIn,
  }: {
    userId: string;
    userRole: UserRole;
    eventId?: string | null | undefined;
    betaOptIn: boolean;
  }) => ["UserEventToken", { userId, userRole, eventId, betaOptIn }],
  userRole: ({
    userId,
    userEmail,
    eventId,
    networkingHubId,
  }: {
    userId: string;
    userEmail: string;
    eventId?: string;
    networkingHubId?: string;
  }) => ["userRole", { userId, userEmail, eventId, networkingHubId }],
  event: (eventId?: string | null) => ["Event", eventId],
  eventStatus: (eventId?: string) => ["EventStatus", eventId],
  networkingStatus: (eventId?: string) => ["NetworkingStatus", eventId],
  privateChats: () => ["private-chats"],
  profiles: () => ["profiles"],
  replays: (eventId?: string) => ["replays", { eventId }],
  user: (userId: string) => ["user", userId],
  userFromToken: (authToken: string, eventId?: string) => [
    "userFromToken",
    authToken,
  ],
  hasGeneralAccess: (eventId?: string) => ["hasGeneralAccess", eventId],
  checkCanJoin: (eventId?: string) => ["checkCanJoin", eventId],
  featureFlags: (args: {
    userId: string;
    email?: string;
    userRole?: UserRole;
    eventId?: string;
    networkingHubId?: string;
    companyId?: string;
  }) => [
    "FeatureFlags",
    args.userId,
    args.email,
    args.userRole,
    args.eventId,
    args.networkingHubId,
    args.companyId,
  ],
};
