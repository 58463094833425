import React from "react";
import { Box, Typography } from "@mui/material";
import AlertDialog, { AlertDialogProps } from "../AlertDialog";
import { UserRole } from "../../../contracts/user/user";
import { FeatureLockedIcon } from "../../icons";
import { useUserRole } from "../../../providers/UserProvider";

interface Props
  extends Pick<
    AlertDialogProps,
    "open" | "onClose" | "title" | "onAction" | "hideAction"
  > {}

function FeatureLockedDialog({ open, onClose, ...restProps }: Props) {
  const userRole = useUserRole();

  return (
    <AlertDialog
      open={open}
      onClose={onClose}
      variant="error"
      maxWidth="xs"
      icon={
        <FeatureLockedIcon
          data-testid="error-alert-dialog-icon"
          width={52}
          height={52}
        />
      }
      body={
        <Box textAlign="center">
          <Typography variant="body2" fontWeight="normal">
            {"This feature has been "}
            <Typography
              component="span"
              color="primary.main"
              variant="body2"
              fontWeight="normal"
            >
              locked
            </Typography>
            .
          </Typography>

          {userRole === UserRole.Organizer && (
            <Typography variant="body2" fontWeight="normal">
              Please contact your administrator for more details.
            </Typography>
          )}
        </Box>
      }
      {...restProps}
    />
  );
}

export default React.memo(FeatureLockedDialog);
