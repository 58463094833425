import React, { memo, useEffect } from "react";
import { useField } from "formik";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ServerIcon from "@introvoke/react/icons/Server03";

import {
  isRecentlyUploadedReplayUrl,
  useEventReplaysContext,
} from "../../providers/EventReplaysProvider";
import ReplaysPreviewList from "./ReplaysPreviewList";
import {
  FeatureFlag,
  useFeatureFlag,
} from "@src/providers/FeatureFlagsProvider";
import { SubmitButton } from "@src/components/EventReplayManagement/ReplayFormActions";
import { useEvent } from "@src/providers/EventProvider";
import { Event } from "@src/contracts/event/event";
import { TabTitle } from "@src/components/TabTitle";
import { IconWrapper } from "@introvoke/react/components/IconWrapper";

export const LoadingMessage = memo(() => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1"
      textAlign="center"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h4">Loading available replays...</Typography>
      <CircularProgress sx={{ mt: 1 }} />
    </Box>
  );
});

const VideoReplaysTab = ({ selectedTab }: { selectedTab: number }) => {
  const { data: event } = useEvent();
  const countdownRefactorEnabled = useFeatureFlag(
    FeatureFlag.COUNTDOWN_REFACTOR,
  );
  const { loadingReplays, replayUrls, updatedAt, refetch } =
    useEventReplaysContext();
  const [{ value: currentVodPath }, , helpers] = useField<string>("replayUrl");

  useEffect(() => {
    const recentlyUploadedReplay = replayUrls.find((urlInfo) =>
      isRecentlyUploadedReplayUrl(urlInfo.url),
    )?.url;

    // if we have a recently uploaded replay that isn't currently selected
    if (
      recentlyUploadedReplay?.length &&
      !isRecentlyUploadedReplayUrl(currentVodPath)
    ) {
      // select the recently uploaded replay
      helpers.setTouched(true);
      helpers.setValue(recentlyUploadedReplay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedAt]);

  useEffect(() => {
    if (!countdownRefactorEnabled || loadingReplays || replayUrls.length > 0)
      return;

    const timer = window.setTimeout(() => {
      refetch();
    }, 3000);

    return () => {
      window.clearTimeout(timer);
    };
  }, [countdownRefactorEnabled, loadingReplays, replayUrls, refetch]);

  if (countdownRefactorEnabled && replayUrls.length === 0) {
    return (
      <Stack
        flexGrow={1}
        textAlign="center"
        spacing={1}
        alignItems="center"
        justifyContent="center"
      >
        <IconWrapper icon={ServerIcon} size="xl" className="mb-3 block" />
        <Typography fontSize="1.2rem" fontWeight="bold">
          Please wait while we save your event recordings
        </Typography>
        <Typography fontSize="1.2rem" color="grey.600">
          <CircularProgress size={14} sx={{ marginRight: 1 }} />
          This will take a few seconds.
        </Typography>
      </Stack>
    );
  }

  return (
    <>
      {countdownRefactorEnabled && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <TabTitle>Manage Replay</TabTitle>
        </Box>
      )}
      <Box flexGrow={1}>
        {loadingReplays && !replayUrls.length && <LoadingMessage />}
        {!loadingReplays && !replayUrls.length ? (
          <Box margin={4}>
            <Typography>
              No on-demand replays available. After you end your event, the
              streams will be available to select here.
            </Typography>
          </Box>
        ) : (
          <ReplaysPreviewList />
        )}
      </Box>
      {countdownRefactorEnabled && (
        <SubmitButton event={event as Event} selectedTab={selectedTab} />
      )}
    </>
  );
};

export default memo(VideoReplaysTab);
