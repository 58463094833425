import React from "react";
import AlertDialogContent, {
  ActionsContainer,
  DEFAULT_ICON_SIZE,
} from "@src/components/dialogs/content/AlertDialogContent";
import { DialogContentProps, useDialog } from "@src/providers/DialogProvider";
import { PromoteUserIcon } from "@src/components/icons";
import Button from "@src/components/buttons/Button";
import {
  useIsEventLive,
  usePromoteViewer,
} from "@src/providers/EventStateProvider";
import { UserRole, UserRoleMap } from "@src/contracts/user/user";
import { ParticipantType } from "@src/components/ParticipantList/Participant";
import { useSocketClient } from "@src/components/Presence/SocketClientProvider";
import { extractIdsFromUrl } from "@src/helpers/utils";
import { toast } from "react-toastify";

type HostOrPresenterType = UserRole.Organizer | UserRole.Presenter;
export const PromotionTextMapNotLive: Record<HostOrPresenterType, string> = {
  [UserRole.Organizer]: "control the stage.", // TODO: update copy
  [UserRole.Presenter]: "go onstage and speak with the audience.", // TODO: update copy
};
export const PromotionTextMapLive: Record<HostOrPresenterType, string> = {
  [UserRole.Organizer]: "control the stage.", // TODO: update copy
  [UserRole.Presenter]: "go onstage and speak with the audience.", // TODO: update copy
};

export interface Props extends DialogContentProps {
  role: HostOrPresenterType;
  pendingPromoteViewer: ParticipantType | null;
  onClose?: () => void;
}

function PromoteUser({ role, onClose, dialogId, pendingPromoteViewer }: Props) {
  const { hideDialog } = useDialog();
  const client = useSocketClient();
  const { eventId } = extractIdsFromUrl();
  const isEventLive = useIsEventLive();

  const mappedRole = UserRoleMap[role];

  const userName = pendingPromoteViewer?.username;

  const handleClose = () => {
    onClose?.();
    hideDialog(dialogId as string);
  };

  const {
    mutation: { mutate, isLoading },
  } = usePromoteViewer(
    { client, eventId, viewer: pendingPromoteViewer as ParticipantType },
    {
      onSuccess: handleClose,
      onError: () => {
        toast.error(`There was an error promoting ${userName}`);
      },
    },
  );

  return (
    <AlertDialogContent
      hideAction
      onClose={!isLoading ? handleClose : undefined}
      title={`Are you sure you want to promote ${userName} to a ${mappedRole}?`}
      icon={
        <PromoteUserIcon
          data-testid="user-promote-dialog-icon"
          width={DEFAULT_ICON_SIZE}
          height={DEFAULT_ICON_SIZE}
        />
      }
      body={`As a ${mappedRole}, ${userName} will be able to ${
        isEventLive ? PromotionTextMapLive[role] : PromotionTextMapNotLive[role]
      }`}
      footer={
        <ActionsContainer>
          <Button variant="outlined" onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            onClick={mutate as any}
            disabled={isLoading}
            loading={isLoading}
          >
            Send an invite
          </Button>
        </ActionsContainer>
      }
    />
  );
}

export default React.memo(PromoteUser);
