import { AnalyticsApi } from "./analytics-api";
import { ApiConfig } from "./api-config";
import * as ChatApi from "./chat.api";
import { CompanyApi } from "./company-api";
import { EventApi } from "./event-api";
import * as NetworkingHubApi from "./networking-hub-api";
import { UserApi } from "./user-api";
import * as FeatureFlagsApi from "./feature-flags-api";
import * as MetricsApi from "./metrics";
import { DailyApi } from "./daily";

export const Api = {
  CompanyApi,
  EventApi,
  UserApi,
  DailyApi,
  ApiConfig,
  AnalyticsApi,
  NetworkingHubApi,
  ChatApi,
  FeatureFlagsApi,
  MetricsApi,
};
