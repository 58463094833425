import { DependencyList } from "react";
import Pubnub, {
  FileEvent,
  ListenerParameters,
  MessageActionEvent,
  MessageEvent,
  ObjectsEvent,
  PresenceEvent,
  SignalEvent,
  StatusEvent,
} from "pubnub";
import { useDeepCompareEffect } from "react-use";
import useDeepMemo from "@src/hooks/useDeepMemo";

import { usePubNubContext } from "./PubNubProvider";

type NonStatusEvent =
  | MessageEvent
  | PresenceEvent
  | MessageActionEvent
  | SignalEvent
  | ObjectsEvent
  | FileEvent;

interface UseChannelListenersParams {
  /**
   * The channel to scope the listeners on
   */
  channel: string;
  /**
   * Listeners to add for the channel subscriptions, these listeners
   * will only be called for those events which include the provided
   * channels
   */
  listeners?: ListenerParameters;
  /**
   * Flag to disable subscriptions
   */
  disabled?: boolean;
}

interface CreateScopedListenersMemoArgs {
  channel: string;
  listeners: ListenerParameters;
}

/**
 * Returns an array which can be used for memoizing channel listeners
 * within a `useMemo` hook.
 *
 * Example usage:
 * ```typescript
 * const useChannelListeners = ({ listeners, channel }) => {
 *   const memoizedChannelListeners = useMemo(...createScopedListenersMemo({
 *     channel,
 *     listeners,
 *   }));
 * }
 * ```
 *
 * The listeners which are returned in the above example are wrapped with a function
 * that will only call the provided listener callbacks when the channel matches.
 *
 * If the channel we would like to setup listeners for is called `my-channel`, we can
 * be assured that our listeners in the next example are only triggered for this channel
 * and no other listeners are called:
 *
 * ```typescript
 * const MyComponent = () => {
 *   const { pubnub } = usePubNubContext();
 *   const channelListeners = useMemo(() => {
 *     return {
 *       message: (e: MessageEvent) => console.log(e),
 *     };
 *   }, []);
 *
 *   useChannelListeners({
 *     channel: "my-channel",
 *     listeners: channelListeners,
 *   });
 *
 *   useEffect(() => {
 *     // After this publish our listener for the message type will
 *     // be called and printed to the console
 *     pubnubInstance.publish({
 *       channel: "my-channel",
 *       message: "hello world"
 *     });
 *
 *     // After this publish our listener for the message type
 *     // WILL NOT be called since we did not setup a listener for this channel
 *     pubnubInstance.publish({
 *       channel: "my-other-channel",
 *       message: "hello world"
 *     });
 *    }, [pubnubInstance]);
 * }
 * ```
 */
const createScopedListenersMemo = (
  args: CreateScopedListenersMemoArgs,
): [() => ListenerParameters, DependencyList] => [
  () =>
    // For every pubnub specific listener we will wrap with a
    // function that first checks if the provided channel belongs
    // to the message, and if so, will call the provided listener
    Object.entries(args.listeners).reduce(
      (acc, [pubnubListenerKey, providedListenerCallback]) => {
        // Let's first check if this is a status listener
        // which has a different structure than other listeners
        if (pubnubListenerKey === "status") {
          const wrappedStatusHandler = (event: StatusEvent) => {
            // Status events can affect multiple channels, so we
            // need to ensure the channel that is provided is affected
            if (!event.affectedChannels.includes(args.channel)) return;
            providedListenerCallback(event);
          };
          return {
            ...acc,
            status: wrappedStatusHandler,
          };
        }

        // This is a normal message/presence handler which only
        // relates to a single channel
        const wrappedListenerHandler = (event: NonStatusEvent) => {
          // All event types which are not status events contain
          // a channel parameter which we can use to ensure our
          // provided channel matches
          if (args.channel !== event.channel) return;
          providedListenerCallback(event);
        };
        return {
          ...acc,
          [pubnubListenerKey]: wrappedListenerHandler,
        };
      },
      {} as ListenerParameters,
    ),
  Object.values(args),
];

interface CreateListenerEffect
  extends Pick<UseChannelListenersParams, "disabled" | "listeners"> {
  pubnub: Pubnub | null;
}

/**
 * Creates a function which returns an array to be used for creating
 * an effect which adds/removes Pubnub listeners.
 *
 * Below is an example of usage:
 * ```typescript
 * const useChannelListeners = ({ listeners, channel }) => {
 *   useDeepCompareEffect(...createListenerEffect({
 *     channel,
 *     listeners,
 *   }));
 * }
 * ```
 *
 * This effect will internally handle adding the listeners to pubnub
 * when they change. When the provided arguments are updated,
 * it will remove the listeners from pubnub.
 */
const createListenerEffect = (
  args: CreateListenerEffect,
): [() => void, DependencyList] => [
  () => {
    const { disabled, listeners, pubnub } = args;
    if (disabled) return;
    listeners && pubnub?.addListener(listeners);
    return () => {
      listeners && pubnub?.removeListener(listeners);
    };
  },
  Object.values(args),
];

/**
 * Hook which allows for adding listeners on a specific pubnub channel.
 * The provided listeners will only be called when an event affects the channel provided.
 *
 * This hook internally handles adding and removing the provided listeners in
 * the Pubnub instance.
 *
 * _NOTE_: This hook WILL NOT subscribe to the channel, it only adds listeners
 * that will be called if the channel receives messages.
 *
 * Example usage:
 * ```typescript
 * const channel = "my-channel";
 *
 * const MyComponent = () => {
 *   const [currentMessage, setCurrentMessage] = useState<string>(null)
 *   const listeners = useMemo(() => {
 *     return {
 *       status: (e: StatusEvent) => console.log(e),
 *       message: (e: MessageEvent) => setCurrentMessage(e.message),
 *     }
 *   }, []);
 *
 *   // When a new message or status event comes from the subscription on
 *   // "my-channel", our listeners defined above will be called.
 *   // If a message event comes on a different channel, our listeners
 *   // above will not be called.
 *   useChannelListeners({
 *     channel: "my-channel",
 *     listeners,
 *   });
 *
 *   return (
 *     <p>{currentMessage}</p>
 *   );
 * }
 * ```
 *
 * _NOTE_: Below describes how Pubnub itself internally handles listeners.
 *
 * It is important to note that unlike subscriptions, Pubnub listeners follow a
 * pattern similar to event listeners in that they are additive and WILL NOT
 * overwrite any existing listeners. This means that in order to remove
 * listeners from Pubnub you must call the remove function with the same instance
 * that was used to add the listeners.
 *
 * For clarity, please take a look at the Pubnub implementation for their
 * `addListeners` and `removeListeners` functions:
 * https://github.com/pubnub/javascript/blob/master/src/core/components/listener_manager.js#L10
 */
export const useChannelListeners = ({
  listeners = {},
  channel,
  disabled,
}: UseChannelListenersParams) => {
  const { pubnub } = usePubNubContext();

  // Scope the channel listeners to the channel which is currently
  // being subscribed to, ie channel-1 should only receive a callback for its channel's message
  const scopedListeners = useDeepMemo(
    ...createScopedListenersMemo({
      channel,
      listeners,
    }),
  );
  useDeepCompareEffect(
    ...createListenerEffect({
      disabled,
      pubnub,
      listeners: scopedListeners,
    }),
  );
};

export const __testable__ = {
  createListenerEffect,
  createScopedListenersMemo,
};

export default useChannelListeners;
