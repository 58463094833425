import React from "react";

import { useUser } from "../../providers/UserProvider";
import { FullPageLoader } from "../FullPageLoader";
import { useEvent } from "../../providers/EventProvider";
import { RootLayout } from "../RootLayout";
import SideBar, { SideBarType } from "../SideBar";
import { Outlet } from "react-router-dom";
import { EventStateProvider } from "@src/providers/EventStateProvider";
import { useNavigateUsers } from "@src/components/Event/hooks/useNavigateUsers";

export const EventPageContent = () => {
  const { data: event } = useEvent();
  const user = useUser();

  useNavigateUsers();

  return (
    <RootLayout
      content={<Outlet />}
      sidebar={
        <SideBar
          type={SideBarType.LIVE_EVENT}
          registration={event?.registration}
          user={user}
        />
      }
    />
  );
};

const EventPage = () => {
  const { data: event } = useEvent();
  const user = useUser();

  if (!event || !user) {
    return <FullPageLoader />;
  }

  return (
    <EventStateProvider>
      <EventPageContent />
    </EventStateProvider>
  );
};
export default EventPage;
