/** @jsxImportSource @emotion/react */
import { FormikErrors, useFormik } from "formik";
import isString from "lodash/isString";
import mapValues from "lodash/mapValues";
import pickBy from "lodash/pickBy";
import React, { useCallback } from "react";

import { css } from "@emotion/react";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import Button from "@src/components/buttons/Button";

import { CloseIcon } from "../icons";

export interface CreateNetworkingCircleDialogProps {
  show: boolean;
  handleClose?: () => void;
  onSubmit: (data: {
    name: string;
    slots: number;
    metadata: Record<string, string>;
  }) => Promise<void>;
}

export interface CreateNetworkingCircleFormValues {
  name: string;
  slots: number;
  metadata: Record<string, string | null>;
}

export const CreateNetworkingCircleDialog: React.FC<
  CreateNetworkingCircleDialogProps
> = ({ show, handleClose, onSubmit }) => {
  const { resetForm, ...form } = useFormik<CreateNetworkingCircleFormValues>({
    enableReinitialize: true,
    onSubmit: async ({ name, slots, metadata }, actions) => {
      await onSubmit({
        name,
        slots,
        metadata: pickBy(
          // This mapValues is required to exclude empty strings
          mapValues(metadata, (x) => x || null),
          isString,
        ),
      });
      handleClose?.();
      actions.resetForm();
    },
    initialValues: {
      name: "",
      slots: 6,
      metadata: {},
    },
    validate: (values) => {
      const errors: FormikErrors<CreateNetworkingCircleFormValues> = {};

      if (!values.name) {
        errors.name = "Please choose a circle name";
      }

      if (!values.slots) {
        errors.slots = "Please choose a circle slot count";
      } else if (values.slots < 2 || values.slots > 10) {
        errors.slots = "Between 2 to 10";
      }

      return errors;
    },
  });

  const onClose = useCallback(() => {
    handleClose?.();
    resetForm();
  }, [handleClose, resetForm]);

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="xs"
      open={show}
      onClose={!form.isSubmitting ? onClose : undefined}
      aria-labelledby="create-poll-dialog-title"
      aria-describedby="create-poll-dialog-description"
      css={(theme) => css`
        .MuiDialog-paper {
          border-radius: ${theme.spacing(2)};
        }
      `}
    >
      <form className="account-settings-form" onSubmit={form.handleSubmit}>
        <DialogTitle
          css={(theme) => css`
            background-color: ${theme.palette.secondary.main};
            color: white;
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <Typography
            id="create-poll-dialog-title"
            variant="h4"
            variantMapping={{ h4: "h2" }}
          >
            Create New Circle
          </Typography>
          <IconButton
            css={(theme) => css`
              color: white;
              padding: ${theme.spacing(1)};
            `}
            onClick={onClose}
            size="large"
          >
            <CloseIcon
              css={css`
                width: 14px;
                height: 14px;
              `}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="center" id="create-poll-dialog-description">
            Create your own circle and start a new conversation. The circle will
            disappear when it's empty.
          </DialogContentText>
          <Box marginY={2}>
            <TextField
              name="name"
              value={form.values.name}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              label="Circle name *"
              error={Boolean(form.errors.name)}
              helperText={form.errors?.name}
              fullWidth
            />
          </Box>
          <Box marginY={2}>
            <TextField
              name="slots"
              value={form.values.slots}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              label="Circle slot count *"
              type="number"
              error={Boolean(form.errors.slots)}
              helperText={form.errors?.slots || "Between 2 to 10"}
              fullWidth
            />
          </Box>
          <Box
            marginTop={4}
            marginBottom={2}
            css={css`
              display: flex;
              justify-content: space-between;
            `}
          >
            <Button
              onClick={onClose}
              color="primary"
              variant="text"
              disabled={form.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={!form.isValid || form.isSubmitting}
              loading={form.isSubmitting}
            >
              Create circle
            </Button>
          </Box>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default CreateNetworkingCircleDialog;
