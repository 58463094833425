import React, { useCallback, useMemo, useState } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";

import { TabHeader } from "../SideBar/TabHeader";
import {
  useQuestionContext,
  UserQuestion,
} from "../../providers/qAndA/QandAProvider";
import { User } from "../../contracts/user/user";
import { QandATabContent } from "./QandATabContent";
import { CreateQuestionGroup } from "./CreateQuestionGroup";
import { QandAReviewContent } from "./QandAReviewContent";
import { useCreateMenuItems } from "./hooks/useCreateMenuItems";
import { QuestionAnswerDialog } from "./Dialogs/QuestionAnswerDialog";
import { QuestionDeleteDialog } from "./Dialogs/QuestionDeleteDialog";
import { useQuestionGroups } from "./hooks/useQuestionGroups";
import { ParticipantNameOption } from "../../contracts/enums/participant-name-options";
import { useIsSessionActive } from "../Presence/SocketClientProvider";
import AlertDialogContent from "@src/components/dialogs/content/AlertDialogContent";

type QnaTabsType = "live" | "review";

interface QnaTab {
  title: string;
  content?: React.ReactNode;
}

type QnaTabsRecord<T> = {
  [tab in QnaTabsType]: T;
};

const isEntryValueDefined = <T extends any>(
  param: [string, T | null | undefined],
): param is [string, T] => {
  return param[1] !== null && param[1] !== undefined;
};

const StyledWrapper = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  display: "flex",
  flexDirection: "column",
  flex: 1,
  minHeight: 0,
  alignItems: "stretch",
  height: "100%",
}));

const ErrorFallback = () => {
  return (
    <AlertDialogContent
      sx={{ p: 2 }}
      hideAction
      variant="warning"
      iconSize="small"
      title={
        <Typography
          mt={2}
          id="alert-dialog-title"
          variant="h4"
          textAlign="center"
        >
          Oops! Looks Like There's a Problem
        </Typography>
      }
      body={
        <Typography textAlign="center" variant="body1" fontWeight="normal">
          Please make sure that websockets are enabled in order to engage with
          chat, polls and Q&A.
        </Typography>
      }
    />
  );
};

interface QandATabsProps {
  user: User;
  isOrganizer: boolean;
  showParticipantNames?: ParticipantNameOption;
}
export const QandATabs = React.memo(
  ({ user, isOrganizer, showParticipantNames }: QandATabsProps) => {
    const [tab, setTab] = useState<QnaTabsType>("live");

    const isSessionActive = useIsSessionActive();
    const {
      questions,
      isLoading,
      isError,
      isAnonymousQnaEnabled,
      isApproveQnaEnabled,
      handleCreateEventQuestion,
      handleDeleteEventQuestion,
      handleAnswerEventQuestion,
      handleVoteEventQuestion,
      handleApproveEventQuestion,
    } = useQuestionContext();
    const { answeredQuestions, unansweredQuestions, unapprovedQuestions } =
      useQuestionGroups({
        questions,
        isApproveQnaEnabled,
        isOrganizer,
      });

    // question answer dialog
    const [questionToAnswer, setQuestionToAnswer] = useState<
      UserQuestion | undefined
    >(undefined);
    const showQuestionAnswerDialog = useCallback(
      (question?: UserQuestion) => setQuestionToAnswer(question),
      [setQuestionToAnswer],
    );
    // question delete dialog
    const [questionToDelete, setQuestionToDelete] = useState<string>("");
    const showQuestionDeleteDialog = useCallback(
      (questionId: string) => setQuestionToDelete(questionId),
      [setQuestionToDelete],
    );

    const createMenuItems = useCreateMenuItems({
      isOrganizer,
      isApproveQnaEnabled,
      showQuestionAnswerDialog,
      showQuestionDeleteDialog,
      handleCreateEventQuestion,
      handleAnswerEventQuestion,
      handleApproveEventQuestion,
    });

    const [liveContent, reviewContent] = useMemo(
      () => [
        <QandATabContent
          {...{
            user,
            answeredQuestions,
            unansweredQuestions,
            createMenuItems,
            handleVoteEventQuestion,
            showParticipantNames,
          }}
        />,
        <QandAReviewContent
          {...{
            user,
            unapprovedQuestions,
            createMenuItems,
          }}
        />,
      ],
      [
        user,
        answeredQuestions,
        unansweredQuestions,
        unapprovedQuestions,
        showParticipantNames,
        handleVoteEventQuestion,
        createMenuItems,
      ],
    );

    const tabs: QnaTabsRecord<QnaTab> = useMemo(() => {
      const allTabs: QnaTabsRecord<QnaTab | null> = {
        live: isApproveQnaEnabled
          ? {
              title: "Live",
              content: liveContent,
            }
          : null,
        review: isApproveQnaEnabled
          ? {
              title: "Pending Approval",
              content: reviewContent,
            }
          : null,
      };

      // Switch up order for attendees and hosts for now as requested
      const sortedTabs = isOrganizer
        ? Object.entries(allTabs).reverse()
        : Object.entries(allTabs);

      return sortedTabs.reduce(
        (t, curr) =>
          isEntryValueDefined(curr) ? { ...t, [curr[0]]: curr[1] } : t,
        {} as QnaTabsRecord<QnaTab>,
      );
    }, [isApproveQnaEnabled, liveContent, reviewContent, isOrganizer]);

    const currentTab = tabs[tab] ? tab : "live";

    const onChangeTabHandler = useCallback(
      (event: React.SyntheticEvent<Element, Event>, value: QnaTabsType) =>
        setTab(value),
      [],
    );

    if (!isSessionActive || isError) {
      return (
        <StyledWrapper>
          <ErrorFallback />
        </StyledWrapper>
      );
    }

    if (isLoading) {
      return (
        <StyledWrapper>
          <CircularProgress sx={{ alignSelf: "center", mt: 8 }} />
        </StyledWrapper>
      );
    }

    return (
      <StyledWrapper>
        {isApproveQnaEnabled && currentTab && tabs[currentTab] ? (
          <>
            <Tabs
              value={currentTab}
              onChange={onChangeTabHandler}
              indicatorColor="primary"
              textColor="inherit"
              aria-label="Q&A tabs"
              sx={{
                "&.MuiTabs-root": {
                  borderBottom: "1px solid",
                  borderBottomColor: "divider",
                  marginBottom: 0.5,
                },
                "& .MuiTabs-flexContainer": {
                  backgroundColor: "common.white",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                },
                "& .MuiTabs-indicator": {
                  display: "none",
                },
              }}
            >
              {Object.entries(tabs).map(([key, { title }]) => {
                const badgeContent =
                  key === "live"
                    ? isOrganizer
                      ? unansweredQuestions.length
                      : 0
                    : unapprovedQuestions.length;

                const hideBadge = badgeContent < 1;

                return (
                  <Tab
                    key={key}
                    label={
                      <TabHeader
                        badge={!hideBadge}
                        count={badgeContent}
                        text={title}
                      />
                    }
                    value={key}
                    sx={({ spacing }) => ({
                      "&.MuiTab-root": {
                        borderRadius: spacing(4),
                        minHeight: 16,
                        minWidth: 48,
                        opacity: 1,
                        padding: 1,
                        textTransform: "none",
                      },
                      "&.Mui-selected": {
                        backgroundColor: "secondary.main",
                        color: "common.white",
                      },
                    })}
                  />
                );
              })}
            </Tabs>

            {/*  SUGGESTION we could potentially only have this in the live tab or the review tab based on `isApproveQnaEnabled` */}
            <CreateQuestionGroup
              isAnonymousQnaEnabled={isAnonymousQnaEnabled}
              isApproveQnaEnabled={isApproveQnaEnabled}
              submitQuestion={handleCreateEventQuestion}
            />

            {Object.entries(tabs).map(([key, { content }]) => (
              <Box
                key={key}
                sx={{
                  display: currentTab !== key ? "none" : "flex",
                  height: "100%",
                }}
              >
                {content}
              </Box>
            ))}
          </>
        ) : (
          <>
            <CreateQuestionGroup
              isAnonymousQnaEnabled={isAnonymousQnaEnabled}
              isApproveQnaEnabled={isApproveQnaEnabled}
              submitQuestion={handleCreateEventQuestion}
            />
            {liveContent}
          </>
        )}

        <QuestionAnswerDialog
          show={!!questionToAnswer}
          question={questionToAnswer as UserQuestion}
          onSubmit={handleAnswerEventQuestion}
          handleClose={() => setQuestionToAnswer(undefined)}
        />

        <QuestionDeleteDialog
          show={!!questionToDelete}
          questionId={questionToDelete as string}
          onDelete={handleDeleteEventQuestion}
          handleClose={() => setQuestionToDelete("")}
        />
      </StyledWrapper>
    );
  },
);
