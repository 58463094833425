import React, { memo, useMemo } from "react";
import Edit from "@mui/icons-material/Edit";

import { useUser } from "../../providers/UserProvider";
import { UserRole } from "../../contracts/user/user";
import { EditReplayModal } from "./EditReplayModal";
import { DangerButton } from "../buttons/DangerButton";
import { BaseButton } from "../buttons/BaseButton";
import { useEventState } from "../../providers/EventStateProvider";
import { useEvent } from "../../providers/EventProvider";
import { useDialog } from "@src/providers/DialogProvider";
import { SxProps } from "@mui/material";

type Props = {
  danger?: boolean;
  label?: string;
  sx?: SxProps;
};

const EditReplayButton = ({ danger, label, sx = {} }: Props) => {
  const { openDialog, hideDialog } = useDialog();
  const { userRole } = useUser();
  const { data: event } = useEvent();
  const eventState = useEventState();

  const replayInProgress =
    eventState.replayEnabled && event?.endDate && event?.endDate < new Date();
  const editingEnabled = userRole === UserRole.Organizer;
  const ButtonElement = danger && !replayInProgress ? DangerButton : BaseButton;
  const buttonLabel = replayInProgress
    ? "Edit Replay"
    : label || `Edit Placeholder`;

  return useMemo(
    () =>
      editingEnabled ? (
        <>
          <ButtonElement
            sx={
              danger
                ? { marginRight: "1em", ...sx }
                : { marginTop: "1em", ...sx }
            }
            color="secondary"
            onClick={() =>
              openDialog(
                "edit-replay-dialog",
                <EditReplayModal
                  replayConfig={{
                    replayEnabled: eventState?.replayEnabled,
                    replayUrl: eventState?.replayUrl,
                    streamPlaceholderMessage:
                      eventState?.streamPlaceholderMessage,
                  }}
                  onClose={() => hideDialog("edit-replay-dialog")}
                />,
              )
            }
          >
            {buttonLabel.match(/edit/gi)?.length ? (
              <Edit sx={{ pr: 1, mr: 1 }} />
            ) : null}
            {buttonLabel}
          </ButtonElement>
        </>
      ) : null,
    [editingEnabled, ButtonElement, buttonLabel, openDialog, sx, danger],
  );
};

export default memo(EditReplayButton);
