import { UserRole } from "@src/contracts/user/user";
import type { GraphQlClient } from "@src/graphql/SocketClient/GraphQlClient";
import type { SocketClient } from "@src/graphql/SocketClient/SocketClient";
import { atom, selector } from "recoil";

export const GQL_SESSION_STORAGE_KEY = "sequel:gql-sessionId";
export const gqlSessionQueryKey = ["GraphQlClient", "sessionId"];

export interface ClientEffectArgs {
  client: SocketClient | GraphQlClient;
  isV2ChatServerEnabled?: boolean;
}

export interface SocketUserMeta {
  userId: string;
  username: string;
  userRole: UserRole;
  email: string;
  avatar: string;
  room?: string | null;
  circle?: {
    id: string;
    name: string;
  };
}

export interface SocketUser {
  meta: SocketUserMeta;
  when?: number;
}

export const usersMapAtom = atom<Record<string, SocketUser>>({
  key: "presenceUsersMap",
  default: {},
});

export const roomUsersAtom = atom<Record<string, SocketUser[]>>({
  key: "presenceRoomUsers",
  default: {},
});

export const usersAtom = selector({
  key: "presenceUsers",
  get: ({ get }) => Object.values(get(usersMapAtom)),
});

export const participantsAtom = selector({
  key: "presenceParticipants",
  get: ({ get }) =>
    get(usersAtom).map((user) => ({
      userId: user.meta.userId,
      username: user.meta.username,
      userRole: user.meta.userRole,
      avatar: user.meta.avatar,
    })),
});
