/**
 * Factory method used for testing and initializing the helper
 */
export const authSessionFactory = () => {
  let authToken = null as string | null;

  return {
    /**
     * Method that sets the auth session token
     * @param token The auth token or `null`
     */
    setAuthToken: (token: string | null) => {
      authToken = token;
    },
    /**
     * Method that gets the current auth session
     */
    getAuthToken: () => authToken,
  };
};

export const { setAuthToken, getAuthToken } = authSessionFactory();

export const addAuthKeyToUrl = (key: string, value: string | undefined) => {
  if (typeof value === "string") {
    // update url
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set(key, value as string);
    window.location.search = urlParams.toString();
  } else {
    console.warn(`invalid user auth key received`);
  }
};
