import { useMemo } from "react";
import {
  QuestionApprovedStatus,
  UserQuestion,
} from "../../../providers/qAndA/QandAProvider";

export interface UseQuestionGroupsProps {
  questions: UserQuestion[];
  isOrganizer?: boolean;
  isApproveQnaEnabled?: boolean;
}

export const useQuestionGroups = ({
  questions = [],
  isOrganizer,
  isApproveQnaEnabled,
}: UseQuestionGroupsProps) => {
  return useMemo(() => {
    return questions.reduce(
      (q, curr) => {
        if (curr.approved === QuestionApprovedStatus.APPROVED) {
          // if questions are approved or were created in approve mode turned off
          if (curr.answered) {
            q.answeredQuestions.push(curr);
          } else {
            q.unansweredQuestions.push(curr);
          }
        } else if (curr.approved === QuestionApprovedStatus.PENDING) {
          // if questions are not approved
          if (isOrganizer || curr?.isOwner) q.unapprovedQuestions.push(curr);
        }

        // hide rejected questions

        return q;
      },
      {
        answeredQuestions: [] as UserQuestion[],
        unansweredQuestions: [] as UserQuestion[],
        unapprovedQuestions: [] as UserQuestion[],
      },
    );
  }, [isOrganizer, questions]);
};
