import { Box, Typography } from "@mui/material";
import React, { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { breadcrumb } from "../helpers/logging";

interface HomeProps {
  invalidUrl?: boolean;
}

export const Home: FC<HomeProps> = ({ invalidUrl = false, ...props }) => {
  const location = useLocation();
  useEffect(() => {
    breadcrumb("Rendering Home route", "routing", {
      pathname: location.pathname,
      search: location.search,
    });
  }, [invalidUrl, location]);

  const message = invalidUrl
    ? `Oh no! Event not found`
    : `Welcome to embed page!`;

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h1">{message}</Typography>
    </Box>
  );
};
