const FONT_CACHE: Record<string, boolean> = {};

const isServer = typeof window === "undefined";

/**
 * Load Google hosted font
 */
export const loadGoogleFont = (url: string | undefined) => {
  if (!url || isServer || FONT_CACHE[url]) {
    return;
  }

  const head = document.head || document.getElementsByTagName("head")[0];
  const style = document.createElement("style");
  style.type = "text/css";
  const css = `@import url('${url}');`;
  style.appendChild(document.createTextNode(css));
  head.appendChild(style);

  FONT_CACHE[url] = true;
};

export const getFontFormat = (url: string) => {
  const extension = url.substring(url.lastIndexOf(".") + 1);
  switch (extension) {
    case "woff":
    case "woff2":
      return extension;
    case "ttf":
      return "truetype";
    case "otf":
      return "opentype";
    case "eot":
      return "embedded-opentype";
    case "otc":
    case "ttc":
      return "collection";
    default:
      return null;
  }
};

/**
 * Load font from custom hosted font file
 */
export const loadFontFile = (
  fontFamily: string | undefined,
  url: string | undefined,
) => {
  if (!fontFamily || !url || isServer || FONT_CACHE[url]) {
    return;
  }

  // TODO: Overwrite existing font families instead of checking cache

  const fontFormat = getFontFormat(url);

  const head = document.head || document.getElementsByTagName("head")[0];
  const style = document.createElement("style");
  style.type = "text/css";
  const css = `
    @font-face {
      font-family: '${fontFamily}';
      src: url(${url})${fontFormat ? ` format('${fontFormat}')` : ""};
    }
  `;
  style.appendChild(document.createTextNode(css));
  head.appendChild(style);

  FONT_CACHE[url] = true;
};
