/** @jsxImportSource @emotion/react */

import React from "react";

import { ClassNames, css } from "@emotion/react";
import Button from "@mui/material/Button";

import { mapClasses } from "../Event/EventStage/mapClasses";

const confirmButtonClasses = {
  contained: css`
    border-radius: 10px;
    color: white;
    box-shadow: none;
    background-color: #28c345;

    :hover {
      background-color: #20ac3a;
      box-shadow: 0 8px 25px -8px #20ac3a;
    }

    :disabled {
      background-color: #20ac3a;
    }
  `,

  label: css`
    white-space: nowrap;
  `,
};

export const ConfirmButton = (props: {
  children?: React.ReactNode;
  disabled?: boolean;
  onClick?: (event: React.SyntheticEvent<any, Event>) => void;
  startIcon?: React.ReactNode;
}) => {
  return (
    <ClassNames>
      {({ css }) => (
        <Button
          sx={{
            borderRadius: "10px",
            backgroundColor: "#20ac3a",
            color: "white",
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "#20ac3a",
              boxShadow: "0 8px 25px -8px #20ac3a",
            },
            "&:disabled": {
              backgroundColor: "#20ac3a",
              color: "white",
              opacity: 0.6,
            },
          }}
          classes={mapClasses(confirmButtonClasses, css)}
          variant="contained"
          color="primary"
          {...props}
        />
      )}
    </ClassNames>
  );
};
