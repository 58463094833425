import React, { useCallback } from "react";
import invariant from "tiny-invariant";
import { useHandleJoinNetworking } from "@src/components/Event/EventStage/useHandleJoinNetworking";
import { UserRole } from "@src/contracts/user/user";
import { useDialogControls } from "@src/hooks/useDialogControls";
import { useEvent } from "@src/providers/EventProvider";
import { useEventState } from "@src/providers/EventStateProvider";
import { useUserRole } from "@src/providers/UserProvider";
import { Api } from "@src/api/api";
import { NetworkingModal as NetworkingStartedModal } from "../NetworkingStartedModal";

export const NetworkingDialog = () => {
  const userRole = useUserRole();
  const { isNetworkingLive } = useEventState();
  const { onCancel, show } = useDialogControls(
    isNetworkingLive && userRole < UserRole.Unregistered,
  );

  const { data: event } = useEvent();
  invariant(event, "Event is required");
  const onJoinNetworking = useHandleJoinNetworking();

  const stopNetworking = useCallback(
    (returnToEvent: boolean) =>
      Api.EventApi.StopNetworking(event.uid, returnToEvent),
    [event.uid],
  );

  const isOrganizer = userRole === UserRole.Organizer;

  return (
    <NetworkingStartedModal
      isOrganizer={isOrganizer}
      show={show && isNetworkingLive}
      joinNetworking={onJoinNetworking}
      stopNetworking={stopNetworking}
      handleClose={onCancel}
      image={event?.whitelabel?.networkingStarted?.image}
      buttonText={event?.whitelabel?.networkingStarted?.buttonText}
    />
  );
};
