import { emitAsPromise } from "@src/components/Presence/emitAsPromise";
import { useIntrovokePresenceContext } from "@src/components/Presence/IntrovokePresence";
import { User } from "@src/contracts/user/user";
import { Actions } from "@src/graphql/schemas/actions";
import filter from "lodash/filter";
import { useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { createPollsQueryKey } from "../helpers";
import { Poll, PollActionHookOptions, PollStatus } from "../types";

export interface UseListEventPollsOptions extends PollActionHookOptions {
  user: User; // userId is required for listing questions
}

export const useListEventPolls = ({
  client,
  eventId,
  circleId,
  user,
}: UseListEventPollsOptions) => {
  const queryKey = createPollsQueryKey(eventId, circleId);
  const queryClient = useQueryClient();
  // use isReady to only wait for event and user to be set and avoid refetches when jumping between circles
  // circleId is already sent when listing polls to always get the polls for the current circle and avoid waits
  const { isReady } = useIntrovokePresenceContext();

  /**
   * Mutation that retrieves the existing polls from the client server
   */
  const mutation = useMutation(
    () =>
      emitAsPromise<Poll[]>(client, Actions.LIST_EVENT_POLLS, {
        eventId,
        userId: user?.originalId, // socket server user originalId
        circleId: circleId || null,
      }),
    {
      onMutate: () => {
        // set to null to manually indicate loading state since isFetching is not updating properly
        queryClient.setQueryData(queryKey, null);
      },
      onError: () => {
        toast.error("There was an error loading the polls");
      },
    },
  );

  const query = useQuery<Poll[] | null>(
    queryKey,
    async () => {
      const polls = await mutation.mutateAsync();
      return polls || [];
    },
    {
      // remove env check to enable polls
      enabled: Boolean(eventId) && isReady,
      refetchOnWindowFocus: false,
      initialData: null,
      retry: false,
    },
  );

  const allPolls = useMemo(() => {
    const polls = query.data || [];
    return {
      polls,
      pendingPolls: filter(polls || [], { status: PollStatus.PENDING }),
      activePolls: filter(polls || [], { status: PollStatus.ACTIVE }),
      closedPolls: filter(polls || [], { status: PollStatus.CLOSED }),
      archivedPolls: filter(polls || [], {
        status: PollStatus.ARCHIVED,
      }),
    };
  }, [query.data]);

  return useMemo(() => {
    return {
      query,
      mutation,
      allPolls,
    };
  }, [query, mutation, allPolls]);
};
