/** @jsxImportSource @emotion/react */
import React, { Suspense, useEffect } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import { useEventState } from "../../../providers/EventStateProvider";
import { NetworkingDialog } from "@src/components/Event/EventStage/NetworkingDialog";
import { StageDevTools } from "@src/components/Event/EventStage/StageDevTools";
import lazyLoader from "@src/helpers/lazyLoader";

const SpeakerContent = lazyLoader(
  () => import("./blocks/SpeakerContent/SpeakerContent"),
  "SpeakerContent",
);

export const EventGreenRoom = () => {
  const { enterStage, hasEnteredStage, userToken } = useEventState();

  useEffect(() => {
    enterStage();
  }, [enterStage]);

  const shouldShowStage = hasEnteredStage && !!userToken;

  return (
    <>
      {process.env.NODE_ENV === "development" && <StageDevTools />}
      <NetworkingDialog />
      <Box
        data-testid="event-content-container"
        sx={{
          display: "flex",
          position: "relative",
          flexDirection: "column",
          justifyContent: "space-between",
          overflow: "auto",
          flex: 1,
          minHeight: 0,
          minWidth: 0,
        }}
      >
        {shouldShowStage && (
          <Suspense
            fallback={
              <Box
                display="flex"
                flex="1"
                alignItems="center"
                justifyContent="center"
                bgcolor="common.white"
              >
                <CircularProgress />
              </Box>
            }
          >
            <SpeakerContent />
          </Suspense>
        )}
      </Box>
    </>
  );
};
