import { useEffect, useState } from "react";

/**
 * Hook to listen to screen size changes. This is based off of the
 * [`window.innerHeight`](https://developer.mozilla.org/en-US/docs/Web/API/Window/innerHeight) property
 */
export default function useScreenHeight(): number {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    if (window === undefined) {
      return;
    }

    const handleChange = () => setWindowHeight(window.innerHeight);
    window.addEventListener("resize", handleChange);
    return () => {
      window.removeEventListener("resize", handleChange);
    };
  }, []);

  return windowHeight;
}
