/** @jsxImportSource @emotion/react */
import React, { memo } from "react";

import { css } from "@emotion/react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  SvgIcon,
  Typography,
  styled,
} from "@mui/material";

import defaultImage from "../../static/networking-image.svg";
import { CloseIcon, NetworkingHubIcon } from "../icons";
import EndNetworkingButton from "./EventStage/EndNetworkingButton";
import { BaseButtonProps } from "../buttons/BaseButton";
import { useEvent } from "../../providers/EventProvider";

const LinkSpan = styled("span")(() => ({
  textDecoration: "underline",
  cursor: "pointer",
  "&:hover": {
    color: "blue",
  },
}));

const EndNetworkingLink = memo((props: BaseButtonProps) => (
  <LinkSpan {...props}>clicking here</LinkSpan>
));

interface NetworkingModalProps {
  isOrganizer: boolean;
  show: boolean;
  handleClose: () => void;
  joinNetworking: () => void;
  stopNetworking: (returnToEvent: boolean) => void;
  image?: string;
  buttonText?: string;
}

export const NetworkingModal: React.FC<NetworkingModalProps> = ({
  show,
  isOrganizer,
  handleClose,
  joinNetworking,
  stopNetworking,
  image,
  buttonText,
}) => {
  const { data: event } = useEvent();

  return (
    <Dialog
      open={show}
      fullWidth
      css={css`
        .MuiDialog-paper {
          max-width: 720px;
        }
      `}
    >
      <DialogContent
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <Box display="flex" justifyContent="flex-end" marginBottom={3}>
          <IconButton color="secondary" onClick={handleClose} size="large">
            <SvgIcon>
              <CloseIcon />
            </SvgIcon>
          </IconButton>
        </Box>

        <Box
          marginBottom={3}
          display="flex"
          justifyContent="center"
          css={({ customTheme }) => css`
            background-image: url(${customTheme?.networkingHub?.returnToStage
              ?.imageUrl ||
            image ||
            defaultImage});
            background-repeat: no-repeat;
            background-position: center;
            flex: 1 1 200px;
          `}
        />

        <Box marginBottom={3}>
          <Typography variant="h3" align="center" color="secondary">
            The organizer started the networking event
          </Typography>
        </Box>

        <Box marginBottom={3} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={joinNetworking}
            startIcon={
              <SvgIcon>
                <NetworkingHubIcon />
              </SvgIcon>
            }
          >
            {buttonText || "Join Networking"}
          </Button>
        </Box>
        {isOrganizer && (
          <Box marginBottom={3} display="flex" justifyContent="center">
            <Typography align="center" color="textSecondary">
              As an organizer, you can stop the networking event by{" "}
              <EndNetworkingButton
                buttonLocationId="networking-started-modal"
                Component={EndNetworkingLink}
                loading={false}
                onConfirm={stopNetworking}
                returnToEventId={event?.uid}
              />
            </Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
