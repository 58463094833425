import React, { memo, useEffect, useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import * as Sentry from "@sentry/react";
import { useConfigValue } from "../config";
import { Api } from "../../api/api";
import { FullPageLoader } from "../../components/FullPageLoader";
import { UserRole } from "../../contracts/user/user";
import { FeatureFlag, useFeatureFlag } from "../FeatureFlagsProvider";
import {
  atom,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { useUnmount } from "react-use";
import { useUser } from "../UserProvider";
import ErrorPage from "@src/components/ErrorPage";
import { useSetConfig } from "../config/useConfig";
import { useSetMetricsUser } from "@src/metrics";
import { useSocketClient } from "@src/components/Presence/SocketClientProvider";

import { useNetworkingHub } from "./useNetworkingHub";
import { TemporaryRoom } from "./common";
import { useSetTemporaryRooms } from "./useTemporaryRooms";

// TODO: Should move some more state to the atom
const networkingHubAtom = atom<{ circleId: string | null }>({
  key: "networkingHubAtom",
  default: {
    circleId: null,
  },
});

export const NetworkingHubProvider: React.FC<{
  userId: string;
  networkingHubId: string;
  circleId?: string;
  returnToEventId?: string;
}> = memo(
  ({ children, userId, networkingHubId, circleId, returnToEventId }) => {
    const cache = useQueryClient();
    const client = useSocketClient();
    const setNetworkingHubState = useSetRecoilState(networkingHubAtom);
    const resetNetworkingHubState = useResetRecoilState(networkingHubAtom);

    useEffect(() => {
      setNetworkingHubState((s) => ({
        ...s,
        circleId: circleId ?? null,
      }));
    }, [circleId, setNetworkingHubState]);

    useUnmount(() => {
      resetNetworkingHubState();
    });

    const networkingHubQuery = useNetworkingHub();
    useSetMetricsUser({ company_id: networkingHubQuery?.data?.company });

    const setTempRooms = useSetTemporaryRooms(networkingHubQuery.data?.uid);

    useEffect(() => {
      const handleRoomCreated = (room: TemporaryRoom) => {
        setTempRooms((prev) => [...prev, room]);
      };
      client.on("room-created", handleRoomCreated);
      return () => {
        client.off("room-created", handleRoomCreated);
      };
    }, [cache, client, setTempRooms]);

    const tagsRef = React.useRef<{
      returnToEventId: string | undefined;
      networkingHubId: string | undefined;
    }>({ returnToEventId: undefined, networkingHubId: undefined });
    if (
      tagsRef.current.returnToEventId !== returnToEventId ||
      tagsRef.current.networkingHubId !== networkingHubId
    ) {
      // Set tags in render instead of effect to catch errors during first render
      Sentry.setTag("event_id", returnToEventId || null);
      Sentry.setTag("networking_hub_id", networkingHubId || null);
      tagsRef.current = {
        returnToEventId,
        networkingHubId,
      };
    }

    return networkingHubQuery.isSuccess ? <>{children}</> : <FullPageLoader />;
  },
);

/**
 * Returns the circle for the given circles ID within the networking hub
 * @param circleId The ID of the circle
 * @returns The circle object, `undefined` if not found
 */
export const useCircle = (circleId: string | null | undefined) => {
  const { data: hub } = useNetworkingHub();
  const circles = hub?.networkingHubRooms;
  return useMemo(
    () => circles?.find(({ id }) => id === circleId),
    [circleId, circles],
  );
};

/**
 * Hook used to determine if the current user is an owner of the given circle id
 * @param circleId
 * @returns `true` if the current user is an owner, `false` if not
 */
export const useIsCircleOwner = (circleId: string | null | undefined) => {
  const user = useUser();
  const { data: hub } = useNetworkingHub();

  const circles = hub?.networkingHubRooms;
  const userEmail = user?.email;
  return useMemo(() => {
    const circle = circles?.find(({ id }) => id === circleId);
    return !!circle?.ownerEmail?.includes(userEmail);
  }, [userEmail, circles, circleId]);
};

/**
 * Returns an access token for the circle conference call
 *
 * @param circleId The ID of the circle to get the access token for
 * @returns An object containing the access token and flags indicating loading/error states
 */
export const useCircleToken = (
  hubId: string,
  circleId: string | null | undefined,
) => {
  const user = useUser();
  const hubV2Enabled = useFeatureFlag(FeatureFlag.HUB_CIRCLE_STAGE_V2);
  const isCircleOwner = useIsCircleOwner(circleId);

  const { data, isLoading, isError } = useQuery(
    [
      "CircleToken",
      {
        userId: user?.uid,
        userRole: user?.userRole,
        circleId,
        betaOptIn: hubV2Enabled,
      },
    ],
    () =>
      Api.UserApi.getCircleToken({
        userId: user?.uid as string,
        hubId,
        circleId: circleId as string,
        moderator: user?.userRole === UserRole.Organizer || isCircleOwner,
        // Please look at the API for more information
        // Production: In the production API this flag will switch between two different AVStack's, V2 (true) or V1 (false)
        // Staging/Dev: In staging, this will switch between our own stack (true) or AVStack V1 Hub (false)
        beta: !!hubV2Enabled,
      }),
    {
      retry: true,
      // Keep cache time at 0 to avoid calling this hook again w/ old token data
      // once fully unmounted
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: !!hubId && !!user?.uid,
    },
  );

  return {
    isLoading,
    isError,
    accessToken: data?.accessToken,
    dailyToken: data?.dailyToken,
    configJitsiServer: data?.configJitsiServer,
    configRoomServer: data?.configRoomServer,
  };
};

/**
 * Hook which returns whether the current user is in a circle
 * @returns `true` if in a circle, `false` if not
 */
export const useIsUserInCircle = () => {
  const { circleId } = useRecoilValue(networkingHubAtom);
  return !!circleId;
};

export const NetworkingHubAccess: React.FC<{ fallback: React.ReactNode }> = ({
  children,
  fallback,
}) => {
  const user = useUser();
  const { data: networkingHub } = useNetworkingHub();
  const { meeting } = useConfigValue();
  const setConfig = useSetConfig();

  const enableAdminRegistrationV2 = useFeatureFlag(
    FeatureFlag.ADMIN_REGISTRATION_V2,
  );

  const isUnregistered = !!(user?.userRole === UserRole.Unregistered);

  // only block entry when
  const shouldBlockEntry =
    // when user is unregistered
    isUnregistered &&
    // registration v2 is disabled
    !enableAdminRegistrationV2 &&
    // and none of the access modes are enabled
    !networkingHub?.isRegistrationModeEnabled &&
    !networkingHub?.registration?.enabled &&
    !networkingHub?.registration?.outsideOfAppEnabled &&
    !meeting;

  useEffect(() => {
    if (networkingHub?.registration?.outsideOfAppEnabled && isUnregistered) {
      setConfig({ cleanUI: true, hybridMode: false });
    }
  }, [
    networkingHub?.registration?.outsideOfAppEnabled,
    isUnregistered,
    setConfig,
  ]);

  if (!networkingHub) {
    return <>{fallback}</>;
  }

  if (shouldBlockEntry) {
    return (
      <ErrorPage
        message="You are not registered for this networking hub. Contact the event organizer for more information."
        showRefresh={false}
      />
    );
  }

  return <>{children}</>;
};
