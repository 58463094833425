import { Box, Typography } from "@mui/material";
import { useField } from "formik";
import React, { CSSProperties, useCallback, useState } from "react";
import * as Yup from "yup";
import ReactCodeInput from "react-code-input";

import { Api } from "../../api/api";
import { UserRole } from "../../contracts/user/user";
import { RegistrationType } from "../../contracts/event/event";
import { InputLabelStyled } from "./TextInputLiveFeedback";

interface CodeInputProps {
  label: string;
  id: string;
  name: string;
  /**
   * The type of registration
   * Defaults to Event
   */
  type: RegistrationType;
  placeholder?: string;
  style?: CSSProperties;
}

const inputStyle: CSSProperties = {
  borderRadius: "3em",
  border: "1px solid",
  borderColor: "rgba(205, 205, 227,.50)",
  boxSizing: "border-box",
  fontFamily: "Poppins",
  fontSize: "18px",
  height: "3.2em",
  margin: ".3em",
  paddingLeft: 0,
  paddingRight: 0,
  textAlign: "center",
  width: "2.2em",
};
const inputStyleInvalid: CSSProperties = {
  ...inputStyle,
  borderColor: "rgba(229, 42, 42, 0.8)",
};

export const CodeInput = ({
  label,
  style,
  id,
  type,
  ...props
}: CodeInputProps) => {
  const [field, meta, helpers] = useField(id);

  const codeCorrect = field.value.length === 6 && !meta.error;

  return (
    <Box id="passcode" style={style ? style : undefined} sx={{ my: 3 }}>
      <InputLabelStyled
        sx={{
          display: "flex",
          my: 1,
          justifyContent: "center",
          fontWeight: "bold",
        }}
        htmlFor={id}
      >
        {label}
      </InputLabelStyled>
      <ReactCodeInput
        inputMode="numeric"
        type="number"
        fields={6}
        {...props}
        {...field}
        value={field.value}
        onChange={(val) => {
          helpers.setTouched(true);
          helpers.setValue(val);
        }}
        inputStyle={inputStyle}
        inputStyleInvalid={inputStyleInvalid}
        isValid={codeCorrect}
      />
      {meta.error ? (
        <Typography color="error.main" sx={{ mb: 1 }}>
          {meta.error}
        </Typography>
      ) : codeCorrect ? (
        <Typography
          color="success.main"
          aria-live="polite"
          id={`${id}-feedback`}
          sx={{ mb: 1 }}
        >
          {"✓  valid passcode"}
        </Typography>
      ) : null}
    </Box>
  );
};

export const useValidateCode = (
  type: RegistrationType = RegistrationType.EVENT,
): {
  validateCode: (
    context: Yup.TestContext<Record<string, any>>,
    eventUid?: string,
    code?: string,
  ) => Promise<boolean | Yup.ValidationError>;
  validatedUserRole: UserRole;
  clearValidatedRole: () => void;
} => {
  const [validatedUserRole, setValidatedUserRole] = useState(
    UserRole.Unregistered,
  );

  return {
    clearValidatedRole: () => setValidatedUserRole(UserRole.Unregistered),
    validateCode: useCallback(
      (
        context: Yup.TestContext<Record<string, any>>,
        eventUid?: string,
        code?: string,
      ) =>
        new Promise<boolean | Yup.ValidationError>(async (resolve, reject) => {
          if (!code || !eventUid || code.length < 6) {
            resolve(context.createError({ message: `6 digit code required` }));
          } else {
            try {
              const validatedRole =
                type === RegistrationType.EVENT
                  ? await Api.EventApi.VerifyPasscode(eventUid, code as string)
                  : await Api.NetworkingHubApi.VerifyPasscode(
                      eventUid,
                      code as string,
                    );

              // Save the user role locally so the user can refresh the page
              const isValid = validatedRole < UserRole.Unregistered;
              if (isValid) {
                // TODO: fix this query so we don't have to rely on the cookie
                resolve(isValid);
              } else {
                resolve(context.createError({ message: `Invalid code` }));
              }
              setValidatedUserRole(validatedRole);
            } catch (e) {
              console.error(e);
              resolve(context.createError({ message: `Invalid code` }));
            }
          }
        }),
      [type],
    ),
    validatedUserRole,
  };
};
