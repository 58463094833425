import React, { memo } from "react";

import { Box, Card, Typography } from "@mui/material";
import { toast } from "react-toastify";

import { NetworkingHubRoom } from "../../contracts/networking-hub/NetworkingHubRoom";
import { useLatestCallback } from "../../hooks/useLatestCallback";
import { RequiredKeys } from "../../types/RequiredKeys";
import { SocketUser } from "../Presence/IntrovokePresence";
import { NetworkingCircleSlots } from "./NetworkingCircleSlots";
import { ParticipantNameOption } from "../../contracts/enums/participant-name-options";

export interface NetworkingCircleProps {
  active?: boolean;
  className?: string;
  userEmail: string;
  room: RequiredKeys<NetworkingHubRoom, "id">;
  users?: SocketUser[];
  onJoinCircle: (room: RequiredKeys<NetworkingHubRoom, "id">) => void;
  showParticipantNames?: ParticipantNameOption;
}

export const NetworkingCircle = memo(
  ({ active = false, users = [], ...props }: NetworkingCircleProps) => {
    const isFull = users.length >= props.room.maxSlots;

    const profiles = users.map(({ meta }) => meta);

    const handleJoinCircle = useLatestCallback(() => {
      if (
        !props.room.ownerEmail?.includes(props.userEmail) &&
        users.length >= props.room.maxSlots
      ) {
        toast.error("This room is full, try another one");
      } else {
        props.onJoinCircle(props.room);
      }
    });

    return (
      <Card
        aria-label={`Networking Circle ${props.room.name}`}
        component="li"
        onClick={handleJoinCircle}
        sx={({ customTheme, palette }) => ({
          listStyle: "none",
          borderRadius: 12,
          border: `1px solid #efefef`,
          padding: 2.5,
          boxShadow: "none",
          cursor: "pointer",
          ...(active && {
            borderColor:
              customTheme?.networkingHub?.circleGraphics?.backgroundColor ||
              palette.primary.main,
          }),
        })}
      >
        <Box>
          <Box marginY={1}>
            <Typography
              sx={({ palette, customTheme }) => ({
                fontWeight: 700,
                fontSize: "16px",
                ...(active && {
                  color:
                    customTheme?.networkingHub?.circleGraphics
                      ?.backgroundColor || palette.primary.main,
                }),
              })}
              variant="h4"
              color="secondary"
              align="center"
            >
              {props.room.name}
            </Typography>
          </Box>
          <Box marginY={1}>
            <Typography
              align="center"
              sx={{
                fontSize: 12,
                color: "#84809B",
              }}
            >
              {users.length === 1 ? `1 person` : `${users.length} people`}
              <Typography
                component="span"
                sx={{
                  color: "#84809B",
                  fontSize: 12,
                  fontWeight: 700,
                }}
              >
                {isFull ? " | Circle full" : ` | ${props.room.maxSlots} seats`}
              </Typography>
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              my: 5,
              mx: 0,
            }}
          >
            <NetworkingCircleSlots
              active={active}
              onJoin={handleJoinCircle}
              roomIndex={props.room.id}
              profiles={profiles}
              maxCount={props.room.maxSlots}
              logo={props.room.logo}
              showParticipantNames={props.showParticipantNames}
              circleOwnerEmail={props.room.ownerEmail}
            />
          </Box>
        </Box>
      </Card>
    );
  },
);
