import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { isOptimalBrowser } from "@src/helpers/browser";
import ContainedButton from "@src/components/buttons/ContainedButton";

interface Props {
  onEnterStage: () => void;
  isStreamLive: boolean;
}
const JoinGreenroomButton = ({ onEnterStage, isStreamLive }: Props) => {
  const optimalBrowser = isOptimalBrowser();
  return (
    <Tooltip
      title={
        !optimalBrowser ? (
          <>
            {
              "Your browser is not compatible. If you wish to join virtually, please download the "
            }
            <a
              href="https://www.google.com/chrome/"
              target="_blank"
              rel="noopener noreferrer"
            >
              latest version of Chrome.
            </a>
          </>
        ) : (
          ""
        )
      }
    >
      <div>
        <ContainedButton
          data-testid="join-green-room-btn"
          onClick={onEnterStage}
          disabled={!optimalBrowser}
        >
          {isStreamLive ? "Join Backstage" : "Enter Greenroom"}
        </ContainedButton>
      </div>
    </Tooltip>
  );
};
export default JoinGreenroomButton;
